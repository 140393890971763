import React, { Component } from 'react'
import './rxDragdroptree.css'

class RxDragdroptree extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      data: this.props.data,
      server: this.props.server === true
    }

    if (typeof window !== 'undefined') {
      this.placeholderParent = {}
      this.placeholder = document.createElement('div')
      this.placeholder.className = 'rxdragdroptree-placeholder'
    }
  }

  componentWillReceiveProps (newProps) {
    this.setState({ data: newProps.data })
  }

  preventDefault (event) {
    event.preventDefault()
  }

  dragStart (e) {
    e.stopPropagation()
    this.dragged = e.currentTarget
    e.dataTransfer.effectAllowed = 'move'

    // Firefox requires dataTransfer data to be set
    e.dataTransfer.setData('text/html', e.currentTarget)
  }

  dragEnd (e) {
    e.stopPropagation()
    e.preventDefault()
    this.dragged.style.opacity = '1'
    if (this.placeholder.parentNode === this.placeholderParent) {
      this.placeholderParent.removeChild(this.placeholder)
    }

    this.helpAddDrop(this.dragged, this.over)
  }

  dragOver (e) {
    this.dragged.style.opacity = '0.3'
    if (this.dragged.contains(e.target)) return
    if (e.target.className === 'rxdragdroptree-placeholder') return
    if (e.target.className === 'rxdragdroptree-item' || e.target.className === 'rxdragdroptree-item-inner') {
      this.over = e.target

      // Inside the dragOver method
      let relY = e.clientY - this.over.offsetTop
      let height = this.over.offsetHeight / 2
      let parent = e.target.parentNode

      // bellow
      if (relY > height) {
        this.nodePlacement = 'after'
        parent.insertBefore(this.placeholder, e.target.nextElementSibling)
      } else if (relY < height) {
        this.nodePlacement = 'before'
        parent.insertBefore(this.placeholder, e.target)
      }

      this.placeholderParent = parent
    }
  }

  helpAddDrop (drag, drop) {
    // If outer && null
    if (!drop || !drop.dataset) {
      return
    }

    // If inner
    if (!drop.dataset.id || !drop.dataset.parentid) {
      this.nodePlacement = 'inner'
      drop = drop.parentNode
    }

    // Get data set
    let dragdata = drag.dataset
    let dropdata = drop.dataset

    if (!this.state.server) {
      // Delete element first
      let tempdata = this.state.data
      let tempcurrentData = {}
      let tempparentDrag = this.state.data[drag.dataset.parentid]

      let tempparentDragNew = []
      for (let index in tempparentDrag) {
        if (tempparentDrag[index]._id === dragdata.id) {
          tempcurrentData = tempparentDrag[index]
          continue
        }
        tempparentDragNew.push(tempparentDrag[index])
      }

      tempdata[drag.dataset.parentid] = tempparentDragNew

      // Insert new place
      switch (this.nodePlacement) {
        case 'before':
          tempdata[dropdata.parentid] = tempdata[dropdata.parentid] || []
          console.log(tempdata[dropdata.parentid])
          tempdata[dropdata.parentid].splice(dropdata.index, 0, tempcurrentData)
          break

        case 'after':
          tempdata[dropdata.parentid] = tempdata[dropdata.parentid] || []
          console.log(tempdata[dropdata.parentid])
          tempdata[dropdata.parentid].splice(1 + parseInt(dropdata.index, 10), 0, tempcurrentData)
          break

        case 'inner':
          tempdata[dropdata.id] = tempdata[dropdata.id] || []
          tempdata[dropdata.id].splice(0, 0, tempcurrentData)
          break

        default:
          tempdata[dropdata.id] = tempdata[dropdata.id] || []
          tempdata[dropdata.id].splice(0, 0, tempcurrentData)
          break
      }

      this.setState({ data: tempdata })
    }
  }

  helpShowTree (items, parentId) {
    if (items && Object.keys(items).length) {
      return Object.keys(items).map((index) =>
        <div key={items[index]._id} className='rxdragdroptree-item' data-index={index} data-id={items[index]._id} data-parentid={parentId} draggable='true' onDragEnd={(e) => this.dragEnd(e)} onDragStart={(e) => this.dragStart(e)}>
          <div className='rxdragdroptree-item-' />{this.props.render(items[index])}
          <div className='rxdragdroptree-item-inner' />
          {this.helpShowTree(this.state.data[items[index]._id], items[index]._id)}
        </div>)
    } else {

    }
  }

  render () {
    return (
      <div className='rxdragdroptree-wrap' onDragOver={(e) => this.dragOver(e)}>
        {this.helpShowTree(this.state.data[0], 0)}
      </div>
    )
  }
}

RxDragdroptree.defaultProps = {
  render: (ele) => (<div className='rxdragdroptree-item-content'>{ele.text}</div>),
  data: {}
}
// { '0': [{key: '1', text: 'mot'}, {key: '2', text: 'hai'}], '1': [{key: '3', text: 'ba'}, {key: '4', text: 'bon'}], '3': [{key: '5', text: 'nam'}] }
export default RxDragdroptree

/* let temp = ({<div className='rxdragdroptree-over rxdragdroptree-folderstyle'>
              <RxDragdroptree data={this.state.catetree} render={(ele) =>
              (<div className='rxdragdroptree-item-content' onClick={(e) => this.onCateClickTree(e)}>{ele.name}
                <div style={{float: 'right'}}>
                  <span className='rx-action-edit' onClick={(e) => this.onClickCateEditTree(e)}>Sửa</span>
                  <span className='rx-action-delete'>Xoá</span>
                </div></div>)}/>
            </div>}) */
