/* global t */
import React from 'react'
// import Select from 'react-select';

global.isclient && require('jsoneditor/dist/jsoneditor.css')
let JSONEditor = global.isclient ? require('jsoneditor') : {}

const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const rxu = global.rxu

// T A B L E
export const renderTable = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead')}</thead><tbody>{vm.run('renderTableBody')}</tbody></MainTable>

export const renderTableHead = (vm) =>
  <tr>
    <th className='rxwidth--100'>Pic</th>
    <th className={vm.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => vm.onClickSort(e, 'name')}>Name</th>
    <th className={vm.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => vm.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (vm) => global.rxu.array(vm.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {vm.renderTableBodyBtns(vm, perdata)}
  </tr>)

export const renderTableBodyBtns = (context, perdata, vm) => {
  return <td  className={context.props.name === 'cate rxsticky' ? 'align__right rxsticky' : 'rxsticky'}>
    { context.state.paging.search_is_deleted !== 1 &&
    <div style={{float: 'right'}}> {(perdata.is_hot) ? <span className='betable__icohot'><span className='icon-fire' />Hot</span> : <span className='betable__iconothot' />}
      {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
      <span className='betable__btnedit' onClick={(e) => context.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
      {context.props.name !== 'cate__' && <span className='betable__btndelete' onClick={(e) => context.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>}</div> }
    { context.state.paging.search_is_deleted === 1 &&
    <div> <span className='betable__restore' onClick={(e) => context.onClickDataRestore(e, perdata)}>Restore</span></div>}
  </td>
}

// P A G I N
export const renderPagin = (vm) => {
  let result = []
  let paging = vm.state.paging
  let listData = global.rxu.get(vm.state.extra, 'count', paging.pg_size)
  let total = 0
  if ((listData / paging.pg_size) > parseInt(listData / paging.pg_size,10)) {
    total = parseInt(listData / paging.pg_size,10) + 1
  } else {
    total = parseInt(listData / paging.pg_size,10)
  }
  if (paging.pg_page === 1) {
  } else {
    result.push(<div key={'first'} className='rxpage adtable__paginback adtable__paginfirst icomoon-Start' onClick={(e) => vm.onclickFirst(e)}></div>)
    result.push(<div key={'back'} className='rxpage adtable__paginback adtable__paginnext__active' onClick={(e) => vm.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>)
  }
  if (total > 5) {
    if (paging.pg_page === 1) {
      for (let i = 1; i <= (total); i++) {
        if (i < 4) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === 4) {
          result.push(<div key={i} className='adtable__pagingMore' >...</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
        }
      }
    } else {
      for (let i = 1; i <= (total); i++) {
        if (i === paging.pg_page - 1) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        } else if ((i === paging.pg_page) || (i === paging.pg_page + 1)) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === paging.pg_page + 2) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        }
      }
    }
  } else if (total === 0) {
    result.push(<div key={'none'} className='rxpage'>{paging.pg_page}</div>)
  } else {
    for (let i = 1; i <= (total); i++) {
      result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
    }
  }
  if (paging.pg_page < total) {
    result.push(<div key={'next'} className='rxpage adtable__paginnext adtable__paginnext__active' onClick={(e) => vm.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>)
    result.push(<div key={'last'} className='rxpage adtable__paginnext adtable__paginlast icomoon-End' onClick={(e) => vm.onclickLast(e)}></div>)
  }
  let start = (Number(paging.pg_page) - 1)*Number(paging.pg_size) + 1
  let end = Number(paging.pg_page) * Number(paging.pg_size)
  if(end > global.rxu.get(vm.state.extra, 'count', 0)) { end = global.rxu.get(vm.state.extra, 'count', 0) }

  return (<div className='rxpagingwrap'>
    <div className='rxpagingcount'>{start} - {end} trong tổng số <b>{global.rxu.get(vm.state.extra, 'count', 0)}</b></div>
    <div className='rxpaging'>
      <div className='rxpagesize'>
        <span>{t('Pagesize')}</span>
        <select value={paging.pg_size} onChange={e => vm.onChangePageSize(e)}>
          {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
        </select>
      </div>
      {result}
    </div>
  </div>)
}

export const renderToast = (vm) =>
  <div className='toast-container toast-top-right'>
    <div className='toast'>
      <div className='toast-content'>
        <div className='toast-title'>{vm.state.msg.msg}</div>
        <div className='toast-message'>
          <div className=''>{vm.state.msg.msg}</div>
        </div>
      </div>
    </div>
  </div>

export const renderSearch = (vm) =>
  <div className='rxcol-100'>
    <div className='betable adblock__inner'>
      <span className='betable__filter'>
        <div className='betable__btns'>
          <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
          <input className='betable__findinput' type='text' placeholder='Tìm ' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
          <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
          <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
        </div>
      </span>
    </div>
  </div>

// F I L T E R
export const renderFilter = (vm) =>
  <div>
    <div className='betable__filter'>
      {['note', 'province', 'sync'].indexOf(vm.props.name) === -1 && <div className='betable__btns'>
        {['media'].indexOf(vm.props.name) !== -1 && ['viewer'].indexOf(rxu.get(vm.props.auth, ['user', 'user', 'role'])) === -1 && <span className='betable__btnadd btn' onClick={(e) => vm.onClickDataNew(e)}><i className='icon-plus betable__addbtn' />Tải ảnh</span>}
        {['cate','Member','media'].indexOf(vm.props.name) === -1 && ['viewer'].indexOf(rxu.get(vm.props.auth, ['user', 'user', 'role'])) === -1 && <span className='betable__btnadd btn' onClick={(e) => vm.onClickDataNew(e)}><i className='icon-plus betable__addbtn' />Thêm mới</span>}
        {['note', 'cate', 'Member', 'config','User','media'].indexOf(vm.props.name) === -1 && <span className="betable__btnexport btn" style={{width: '125px', height: '42px', marginLeft: '10px'}} onClick={() => vm.InDanhSachCoQuanBaoChi()}><i className="iconcustom-export2"></i>Xuất file</span>}
        {['Member'].indexOf(vm.props.name) !== -1 && <span className="betable__btnexport btn" style={{width: '125px', height: '42px', marginLeft: '10px'}} onClick={() => vm.InDanhSachMember()}><i className="iconcustom-export2"></i>Xuất file</span>}
        {['User'].indexOf(vm.props.name) !== -1 && <span className="betable__btnexport btn" style={{width: '125px', height: '42px', marginLeft: '10px'}} onClick={() => vm.printListUser()}><i className="iconcustom-export2"></i>Xuất file</span>}
        {/* {['note', 'cate', 'Member', 'config'].indexOf(vm.props.name) === -1 && <span className='betable__btnimport btn' onClick={(e) => vm .onClickExcelUpload('checkFile')}><i className='iconcustom-import' />Import dữ liệu</span>}
        { vm.flag('checkFile') &&
            <span className='adtable__exportcsv tooltip'>
              <input onChange={(e) => vm .onClickDataCheck(e)} className='' id='import-excel' type='file' accept='.xlsx, .xls' />
            </span>
        } */}
        { vm.state.paging.search_is_deleted !== 1
          ? <span className='btn--default' onClick={(e) => vm.onClickDataTrash(e, 1)} ><i className='icon-trash betable__recycle' /></span>
          : <span className='btn--default' onClick={(e) => vm.onClickDataTrash(e, 0)} ><i className='icon-list betable__recycle' /></span> }
      </div>}
      {(vm.props.name === 'newspaperOffice') ?
        <div>
          <select className='betable__findinput col-md-3' value={vm.state.paging['search_Type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'Type')}>
            <option value={-1}>--Chọn loại tài khoản cục --</option>
            {global.Types && global.Types.map((type, index) => <option key={index} value={type.id}>{type.name}</option>)}
          </select>
          <select className='betable__findinput col-md-3' value={vm.state.paging['search_LoaiCoquan'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'LoaiCoquan')}>
            <option value="-1">--Loại cơ quan báo chí--</option>
            <option value="3">Cơ quan báo chí trực thuộc Cục phát thanh truyền hình</option>
            <option value="2">Cơ quan báo chí trực thuộc Phòng Quản lý Báo chí</option>
            <option value="1">Cơ quan báo chí trực thuộc Phòng Kinh tế Báo chí</option>
            <option value="0">Cơ quan báo chí chưa cập nhật</option>
          </select>
          <select className='betable__findinput col-md-3' value={vm.state.paging['search_LoaiCuc'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'LoaiCuc')}>
            <option value="-1">--Loại Ban, Bộ, Hội--</option>
            <option value="1">Trực thuộc Hội</option>
            <option value="2">Trực thuộc Bộ</option>
            <option value="3">Trực thuộc Ban</option>
          </select>
          <input className='betable__findinput col-md-3' type='text' placeholder='Tên cơ quan ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'Cat_Name')} />
          <input className='betable__findinput col-md-3' type='text' placeholder='ID ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'Cat_ID')} />
          {/*<span className='betable__btnimport btn' onClick={(e) => vm .onClickExcelUpload('checkFile')}><i className='iconcustom-import' />Import dữ liệu</span>
          { vm .flag('checkFile') &&
            <span className='adtable__exportcsv tooltip'>
              <input onChange={(e) => vm .onClickDataCheck(e)} className='' id='import-excel' type='file' accept='.xlsx, .xls' />
            </span>
          }*/}
        </div>
        :
        (['position', 'permission', 'office', 'newspaperOffice', 'note', 'cate', 'province', 'title', 'config'].indexOf(vm.props.name) !== -1) && <div>
          <select className='betable__findinput col-md-3' value={vm.state.paging['search_Type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'Type')}>
            <option value={-1}>--Chọn loại tài khoản cục --</option>
            {global.Types && global.Types.map((type, index) => <option key={index} value={type.id}>{type.name}</option>)}
          </select>
          <div className='col-md-2'>
            <RxSelectbox name='cate' options={vm.state.cate} results={vm.state.paging['search_CoQuanBaoChiID'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'CoQuanBaoChiID', 1) }} api='api_newspaper_office' optionDefault='--Chọn cơ quan báo chí--' loader='load' />
          </div>
          <div className=''>
            <select className='betable__findinput col-md-3' value={vm.state.paging['search_submit'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'submit')}>
              <option value="-1">--Tình trạng--</option>
              <option value={0}>Chưa trình </option>
              <option value={1}>Đã trình</option>
            </select>
          </div>

          <div className=''>
            <select className='betable__findinput col-md-3' value={vm.state.paging['search_status'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'status')}>
              <option value="-1">--Trạng thái--</option>
              <option value={0}>CQ trình hồ sơ</option>
              <option value={1}>CV Hoàn thành thẩm định </option>
              <option value={2}>LDP Hoàn thành thẩm định </option>
              <option value={3}>LDC Hoàn thành thẩm định</option>
              <option value={4}>LDB Hoàn thành thẩm định </option>
            </select>
          </div>

          <input className='betable__findinput col-md-3' type='text' placeholder='Ten ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'Ten')} />
          <input className='betable__findinput col-md-3' type='text' placeholder='ChucVuID ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'ChucVuID')} />

        </div>

      }
      {(['position', 'permission', 'office', 'newspaperOffice', 'note', 'cate', 'province', 'title', 'config', 'sync','media'].indexOf(vm.props.name) !== -1) ? '' :
        <div>
          <div className='rxcol-100'>
            <span className='betable__filter rx-filter rx-flex col-md-12'>
              <div className='rx--box__title'>TÌM KIẾM</div>
              <div className='col-md-12'>

                <select className='betable__findinput col-md-3' value={vm.state.paging['search_Type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'Type')}>
                  <option value={-1}>--Chọn loại tài khoản cục --</option>
                  {global.Types && global.Types.map((type, index) => <option key={index} value={type.id}>{type.name}</option>)}
                </select>
                <select className='betable__findinput col-md-3' value={vm.state.paging['search_LoaiCuc'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'IsUpdateProfile')}>
                  <option value="-1">--Trạng thái--</option>
                  <option value={0}>Chưa duyệt</option>
                  <option value={1}>Đã duyệt</option>
                </select>
                <select className='betable__findinput col-md-3' value={vm.state.paging['search_LoaiCoquan'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'LoaiCoquan')}>
                  <option value="-1">--Loại cơ quan báo chí--</option>
                  <option value="3">Cơ quan báo chí trực thuộc Cục phát thanh truyền hình</option>
                  <option value="2">Cơ quan báo chí trực thuộc Phòng Kinh tế Báo chí</option>
                  <option value="1">Cơ quan báo chí trực thuộc Phòng Quản lý Báo chí</option>
                  <option value="0">Cơ quan báo chí chưa cập nhật</option>
                </select>

                {vm.props.name !== 'Member' &&
                <select className='betable__findinput col-md-3' value={vm.state.paging['search_role'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'role')}>
                  <option value=''>--Chọn phân quyền--</option>
                  {vm.props.roles && vm.props.roles.map((role, index) => <option key={index} value={role.name}>{role.desc}</option>)}
                </select>
                }

                <input className='betable__findinput col-md-3' type='text' placeholder='Tên cơ quan báo chí ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'CoQuanBaoChi')} />
                <input className='betable__findinput col-md-3' type='text' placeholder='Họ và tên ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'fullname')} />
                <input className='betable__findinput col-md-3' type='text' placeholder='Tên đăng nhập' onKeyUp={(e) => vm.onBlurDatafilter(e, 'username')} />
                <input className='betable__findinput col-md-3' type='text' placeholder='Mã đồng bộ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'code')} />
              </div>
            </span>
          </div>
        </div>
      }
      {vm.props.name === 'sync' && vm.props.type === 1 && vm.run('renderFilterSyncLeft')}
      {vm.props.name === 'sync' && vm.props.type === 2 && vm.run('renderFilterSyncRight')}
      {(vm.props.name === 'note') &&
        <div>
          <div className='rxcol-100'>
            <span className='betable__filter rx-filter rx-flex col-md-12'>
              <div className='rx--box__title'>TÌM KIẾM</div>
              <div className='col-md-12'>
                <select className='betable__findinput col-md-6' value={vm.state.paging['search_Type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'Type')}>
                  <option value={-1}>--Chọn loại tài khoản cục --</option>
                  {global.Types && global.Types.map((type, index) => <option key={index} value={type.id}>{type.name}</option>)}
                </select>
                <select className='betable__findinput col-md-6' value={vm.state.paging['search_role'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'role')}>
                  <option value={-1}>--Chọn phân quyền--</option>
                  {vm.props.roles && vm.props.roles.map((role, index) => <option key={index} value={role.name}>{role.desc}</option>)}
                </select>
                <select className='betable__findinput col-md-3' value={vm.state.paging['search_Type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'Type')}>
                  <option value={-1}>--Chọn loại tài khoản cục --</option>
                  {global.Types && global.Types.map((type, index) => <option key={index} value={type.id}>{type.name}</option>)}
                </select>
                <input className='betable__findinput col-md-6' type='text' placeholder='Họ và tên ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'fullname')} />
                <input className='betable__findinput col-md-6' type='text' placeholder='Tên đăng nhập' onKeyUp={(e) => vm.onBlurDatafilter(e, 'username')} />
              </div>
            </span>
          </div>
        </div>
      }
      {['province', 'config'].indexOf(vm.props.name) !== -1 && <div>
        <div className='rxcol-100'>
          <span className='betable__filter rx-filter rx-flex col-md-12'>
            <div className='rx--box__title'>TÌM KIẾM</div>
            <div className='col-md-12'>
              {vm.props.name === 'province' && <input className='betable__findinput col-md-6' type='text' placeholder='Email ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'email')} />}
              {vm.props.name === 'config' &&
                <select className='betable__findinput col-md-6' value={vm.state.paging['search_type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'type')}>
                  <option value={-1}>--Chọn loại --</option>
                  {global.configType && global.configType.map((type, index) => <option key={index} value={type.id}>{type.Ten}</option>)}
                </select>
              }
              <input className='betable__findinput col-md-6' type='text' placeholder='Tên' onKeyUp={(e) => vm.onBlurDatafilter(e, 'Ten')} />
            </div>
          </span>
        </div>
      </div>}
    </div>
    {/*vm.props.name.indexOf('province') !== -1 && <span className='betable__btnadd btn' onClick={(e) => vm.onClickImportProvince()}>Import Province</span>*/}
    {vm.props.name.indexOf('permission') !== -1 && <div className='adblock__btngen' onClick={(e) => vm.onClickGenPermission()}>Generate permissions</div>}
  </div>

// F I L T E R S Y N C'
export const renderFilterSyncLeft = (vm) =>
  <div className='rxcol-100 rx__filter--sync'>
    <span className='betable__filter rx-filter rx-flex col-md-12'>
      <div className='rx--box__title'>TÌM KIẾM</div>
      <div className='col-md-12'>
        <select className='betable__findinput col-md-6' value={vm.state.paging['search_Type'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'Type')}>
          <option value={-1}>--Chọn loại tài khoản cục --</option>
          {global.Types && global.Types.map((type, index) => <option key={index} value={type.id}>{type.name}</option>)}
        </select>
        <select className='betable__findinput col-md-6' value={vm.state.paging['search_is_synced'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'is_synced')}>
          <option value={-1}>--Tình trạng--</option>
          <option value={0}>Đã đồng bộ</option>
          <option value={1}>Chưa đồng bộ</option>
        </select>
        <input className='betable__findinput col-md-6' type='text' placeholder='Tên cơ quan báo chí' onKeyUp={(e) => vm.onBlurDatafilter(e, 'Cat_Name')} />
      </div>
    </span>
  </div>
export const renderFilterSyncRight = (vm) =>
  <div className='rxcol-100 rx__filter--sync'>
    <span className='betable__filter rx-filter rx-flex col-md-12'>
      <div className='rx--box__title'>TÌM KIẾM</div>
      <div className='col-md-12'>
        <input className='betable__findinput col-md-3' type='text' placeholder='Họ và tên ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'HoTenKhaiSinh')} />
        <input className='betable__findinput col-md-3' type='text' placeholder='Mã đồng bộ' onKeyUp={(e) => vm.onBlurDatafilter(e, 'code')} />

      </div>
    </span>
  </div>

// F I N D  E X P A N D
export const renderFindExpand = (vm) => {
  let filterFields = rxu.get(vm.state, ['temp', 'filterFields'])
  let filterEles = []
  for (let i in filterFields) {
    filterEles.push(
      <div className='col-md-6' key={i}>
        <div className='betable__findlabel'>Find by {i}</div>
        <input className='betable__findinput betable__findinput-full' type='text' placeholder={'vd: ' + filterFields[i]} onKeyUp={(e) => vm.onBlurDatafilter(e, i)} />
      </div>)
  }
  return <div className='rxfull'>
    {filterEles}
  </div>
}

// F O R M
export const renderForm = (vm) => {
  if (vm.state.editingData.created_at || vm.state.editingData.created_at === 0 || vm.props.name === 'media') {
    global.scrollfire()
    return <div className={'adform'  + (vm.props.name === 'media' ? ' top-0' : '')}>
      {vm.props.name !== 'media' && <div className='adform__header'>
        {vm.run('renderFormHead')}
      </div>}
      <div className='adform__body'>
        {vm.run('renderFormBody')}
        {/*vm.run('renderFormRaw')*/}
      </div>
      {vm.props.name !== 'media' && <div className='adform__footer'>
        {vm.run('renderFormBtns')}
      </div>}
    </div>
  }
}

let renderFormBodyDefault = (vm) =>
  [ { name: 'Picture', func: () => (<RxUpload callback={(e) => vm.callbackUpload(e)} images={vm.state.editingData.img_landscape} />) },
    { type: 'devide' },
    { name: 'Name', func: () => (<input tabIndex='1' type='text' value={vm.state.editingData.name} onChange={(e) => vm.onBlurData(e, 'name')} className='fullwidth-input' />) },
    { name: 'Desc', func: () => (<input tabIndex='2' type='text' value={vm.state.editingData.desc} onChange={(e) => vm.onBlurData(e, 'desc')} className='fullwidth-input' />) },
    { type: 'devide' },
    { name: 'Status', func: () => (<RxToggle value={vm.state.editingData.is_active} onToggle={(newValue) => vm.onBlurDataValue(newValue, 'is_active')} />) } ]

export const renderFormBody = (vm) => {
  let view = vm.state.view
  let tempform = []
  if(view) { tempform = vm.props.view } else { tempform = vm.props.form || renderFormBodyDefault(vm) }

  if(vm.props.name === 'User') { tempform = vm.state.activeTab === 'label2' ? vm.props.form2 : vm.props.form }

  if ((vm.state.editingData.created_at || vm.state.editingData.created_at === 0 || vm.props.name === 'media') && tempform) {
    let form = tempform.map((perdata, index) => {
      perdata.type = perdata.type || 'input'
      let result = (<div />)
      switch (perdata.type) {
        case 'input':
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'fullinput':
          result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'devide':
          result = <div key={index} className='fullwidth-formdevide cleafix' />
          break
        case 'header':
          result = <div className='form-header' key={index}>{perdata.func(vm)}</div>
          break
        case 'form':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{perdata.func(vm)}</div></div>
          break
        case 'table':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{vm.run('renderTableForm')}</div></div>
          break
        case 'flex':
          result = <div key={index} className='rx-flex'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        default:
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
      }
      return result
    })
    return form
  }
}

export const renderTableForm = (vm) => {
  let QuaTrinhHoatDong = global.rxu.get(vm.state, ['editingData', 'QuaTrinhHoatDongUpdate'])
  return (<div className='rx-table__form'>
      <table>
        <tbody>
          <tr>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>Thời hạn (từ năm n&agrave;o đến năm n&agrave;o)</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>Chức danh b&aacute;o ch&iacute;</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>C&ocirc;ng t&aacute;c tại cơ quan b&aacute;o ch&iacute; n&agrave;o</strong></p></td>
            <td className='rx-table__form-header' colSpan="2"><p><strong>Lương</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>&nbsp;Khen thưởng kỷ luật (thời gian cụ thể)</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>M&atilde; thẻ</strong></p></td>
            {!vm.state.view && <td className='rx-table__form-header adbtn__add' rowSpan="2" onClick={(e) => vm.onClickAddRowTable()}><p><strong>Thêm dòng mới</strong></p></td>}
          </tr>
          <tr>
            <td className='rx-table__form-header'><p><strong>Ngạch lương</strong></p></td>
            <td className='rx-table__form-header'><p><strong>Bậc lương</strong></p></td>
          </tr>
          {QuaTrinhHoatDong && QuaTrinhHoatDong.map((obj, index) => (
            !vm.state.view ? <tr key={index}>
              <td><input tabIndex='1' type='text' value={obj.time || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'time', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='2' type='text' value={obj.ChucDanhBaoChi ||''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'ChucDanhBaoChi', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='3' type='text' value={obj.CoQuanBaoChi || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'CoQuanBaoChi', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='4' type='text' value={obj.NgachLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'NgachLuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='5' type='text' value={obj.BacLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'BacLuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='6' type='text' value={obj.KhenThuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'KhenThuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='7' type='text' value={obj.MaThe || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'MaThe', index)} className='fullwidth-input' /></td>
              <td className='adbtn__clear' onClick={(e) => vm.onClickRowDel(index)}>Xoá hàng</td>
            </tr> : <tr key={index}>
              <td>{obj.time || ''}</td>
              <td>{obj.ChucDanhBaoChi ||''}</td>
              <td>{obj.CoQuanBaoChi || ''}</td>
              <td>{obj.NgachLuong || ''}</td>
              <td>{obj.BacLuong || ''}</td>
              <td>{obj.KhenThuong || ''}</td>
              <td>{obj.MaThe || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>)
}

export const renderFormHead = (vm) => {
  let editingData = vm.state.editingData
  return (<div className='adform__name'>{vm.props.name !== 'User' ? 'Thông tin' : ''}
    <div className='adform__close'>
      {(editingData.submit === 0 && editingData['reception'] && editingData['method_result']) ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e, '', 1)}>Trình hồ sơ</span>:<span/>}
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
      {vm.state.view && vm.props.name === 'cate' && <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu 1</span>}

      {!vm.state.view && (vm.state.editingData.created_at !== 1 ? (vm.props.name === 'cate') ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmitCate(e)}>Lưu 1.5</span> : <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu1.6</span>
      : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu 2</span>)}
    </div>
    {vm.props.name ==='User' && <div className='adform__name-tab'>
      <div>
        <div className={vm.state.activeTab === 'label1' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={(e) => vm.onClickTabItem(e,'label1')}>Thông tin</div>
        <div className={vm.state.activeTab === 'label2' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={(e) => vm.onClickTabItem(e,'label2')}>Quyền cơ quan báo chí</div>
      </div>
    </div>}
  </div>)
}

export const renderFormRaw = (vm) => {
  if (vm.state.editingData._id) {
    if (vm.state.flags.rawedit) {
      setTimeout(() => {
        vm.jsoneditor = new JSONEditor(vm.jsonEditor, { mode: 'tree' })
        vm.jsoneditor.set(vm.state.editingData)
      }, 500)
    }

    return (
      <div className='clearfix adform__rawedit'>
        <div onClick={evt => vm.toggle('rawedit')} className='adform__raweditbtn'>RawEdit</div>
        { vm.state.flags.rawedit ? <div>
          <div className='adform__raweditcontent' ref={e => { vm.jsonEditor = e }} />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateSubmitRaw(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmitRaw(e)}>RawUpdate</div>
        </div> : <div /> }
      </div>
    )
  }
}

export const renderFormBtns = (vm) =>
  <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
    <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, {})} onKeyPress={(e) => vm.onClickDataEdit(e, {})}>{t('return')}</div>
    {vm.props.name === 'cate' && vm.state.view && vm.state.editingData.StatusPhanLoai !== 6 && <span className="adform__btncancel betable__btnexport btn" onClick={(e) => vm.onClickDataExport3(e, 3)}><i className="iconcustom-export2"></i>Xuất mẫu 03</span>}
    {vm.props.name === 'cate' && vm.state.view && vm.state.editingData.StatusPhanLoai === 6 && <span className="adform__btncancel betable__btnexport btn" onClick={(e) => vm.onClickDataExport3(e, 4)}><i className="iconcustom-export2"></i>Xuất mẫu 04</span>}
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
      {!vm.state.view && (vm.state.editingData.created_at !== 1 ? (vm.props.name === 'cate') ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmitCate(e)}>Lưu1.7</span> : <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu 3</span>
      : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu 4</span>)}
    </div>
    {/*{vm.state.editingData.created_at !== 1 && <div tabIndex='11' className='adform__btnedit' onClick={(e) => vm.onClickDataUpdateSubmit(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmit(e)}>{t('Update')}</div>}
    <div tabIndex='12' className='adform__btnclone' onClick={(e) => vm.onClickDataCreateSubmit(e)} onKeyPress={(e) => vm.onClickDataCreateSubmit(e)}>{t('Create new')}</div>*/}
  </div>

export const renderComponent = (vm) =>
  <div>
    {vm.state.msg && vm.state.msg.success && vm.run('renderToast')}
    {vm.props.name !== 'media' && <div className='betable adblock__inner'>
      {vm.run('renderFilter')}
      <div className='adform__toggle'>
        {vm.run('renderPagin')}
        {vm.run('renderTable')}
      </div>
    </div>}
    {vm.run('renderForm')}
  </div>
