/*eslint-disable */
/* global rxu */
import React, { Component } from 'react'
import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const QRCode = require('qrcode.react')
global.isclient && require('./rxToggle.css')
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
var print = true
// const Loader = global.rootRequire('components/shares/loader').default
class RxPrintCard extends Component {
  constructor (props) {
    super(props)
    this.state = { editingData: this.props.editingData || {}, loading: false }
  }

  componentDidMount () {
    if (this.props.typePrint === 'save') {
      global.showProgress()
      this.setState({ loading: true }, () => this.fetchIMG())
    }
    if (this.props.typePrint === 'print') {
      // window.print()
      print = true
    }
    console.log(this.props)
    let seft = this
    window.onafterprint = function () { }
  }

  UNSAFE_componentWillReceiveProps (nexProps) {
    if (nexProps.editingData && this.state.editingData.length > 0 && nexProps.editingData.length > 0) {
      this.setState({ editingData: nexProps.editingData, typePrint: nexProps.typePrint })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let el = document.getElementsByClassName('text__print--coquan')
    let tmp = 0
    if(this.state.editingData && this.state.editingData.length > 0 && el && el.length > 0 && print === true && this.props.typePrint === 'print') {
      for(let i in this.state.editingData) {
        let ele = document.querySelector('#coquanEle'+Number(i))

        if(ele && ele.childNodes[1] && ele.childNodes[1].childNodes[0]) {
          let arrLine = this.getLineBreaks(ele.childNodes[1].childNodes[0])

          if(arrLine && arrLine.length > 0) {
            if(arrLine.length === 1) {
              ele.childNodes[1].className = 'textOrganization___print--nowrap'
            }
            else if(arrLine[1] && arrLine[1].length < 5 && ele.childNodes[1].style) {
              ele.childNodes[1].className = 'textOrganization___print--coquan'
            }
            else if(arrLine.length > 2) {
              ele.className = 'text__print--coquan1'
            }
          }
          if(tmp === this.state.editingData.length - 1 && print === true) {
            // window.print()
            print = false
          }
          tmp ++
        }
      }
    }
  }

  async fetchIMG () {
    const id = this.state.editingData['codeGen']
    const editingData = this.state.editingData
    // let scope = this
    // // console.log(id, 'dhvghfydt')
    if (editingData && editingData._id) {
      if (document.getElementById('getIMG')) {
        htmlToImage.toBlob(document.getElementById('getIMG'))
          .then(function (blob) {
            let data = new FormData()
            data.append('uploadFile', blob, id)
            fetch(global.rxu.config.base_api + '/upload/savecard', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
              if (json.success === 1) {
                document.getElementById('save_card').style.display = 'none'
                global.hideProgress()
              }
            })
          })
      }
    }
    if(editingData && editingData.length > 0) {
      let data = new FormData()
      var tmp = 0
      for(let i = 0; i < editingData.length; i++) {
        if(document.getElementById('getMulti'+i)) {
          htmlToImage.toBlob(document.getElementById('getMulti'+i))
            .then(function (blob) {
              data.append(('uploadFile' + i), blob, editingData[i]['_id'])
              tmp ++
              if(tmp === editingData.length) { //i === (editingData.length - 1) &&
                fetch(global.rxu.config.base_api + '/upload/savecardmulti', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
                  if (json.success === 1) {
                    // document.getElementById('save_card').style.display = 'none'
                    global.hideProgress()
                  }
                })
              }
            })
        }
      }
    }
  }

  getYear (timeOld, timeNew) {
    var u = Date.now()
    let tempstr = ''
    if (timeOld) {
      u = new Date(parseInt(timeOld.substr(6), 10))
      tempstr = u.getUTCFullYear()
    } else if (timeNew) {
      u = new Date(timeNew * 1000)
      tempstr = u.getUTCFullYear()
    } else {
      u = new Date()
      tempstr = u.getUTCFullYear()
    }
    return tempstr
  }

  getLineBreaks (node) {
    // we only deal with TextNodes
    if (!node || !node.parentNode) {
      return false
    }
    // our Range object form which we'll get the characters positions
    const range = document.createRange()
    // here we'll store all our lines
    const lines = []
    // begin at the first char
    range.setStart(node, 0)
    // initial position
    let prevBottom = range.getBoundingClientRect().bottom
    let str = node.textContent
    let current = 1 // we already got index 0
    let lastFound = 0
    let bottom = 0
    // iterate over all characters
    while (current <= str.length) {
      // move our cursor
      range.setStart(node, current)
      if (current < str.length - 1)
        range.setEnd(node, current + 1)
      bottom = range.getBoundingClientRect().bottom
      if (bottom > prevBottom) { // line break
        lines.push(
          str.substr(lastFound, current - lastFound) // text content
        )
        prevBottom = bottom
        lastFound = current
      }
      current++;
    }
    // push the last line
    lines.push(str.substr(lastFound));

    return lines;
  }

  render () {
    let CoQuan = this.state.editingData.TenCoQuanBaoChi + ' ' + rxu.get(this.props, ['fontPrint','BanChuyenDoiTuong'], 0) //'Tạp chí Nghiên cứu Công nghiệp và Thương mại (trước đây là Tạp chí Nghiên cứu Thương mại)' //
    let HoTen = rxu.get(this.state.editingData, ['HoTenKhaiSinh'], rxu.get(this.state.editingData,[0,'HoTenKhaiSinh']))  //'NGUYỄN THỊ HỒNG MINH' //
    let MaSoThe = rxu.get(this.state.editingData, ['MaSoThe'], rxu.get(this.state.editingData,[0,'MaSoThe']))

    return (<div>
      {this.state.editingData && this.state.editingData['_id'] && this.props.typePrint === 'print' && <div className='rxpopup-addnew-cus-- printer'>
        <div className='adblock--new'>
          <div className='adblock__label--new'>
            <div className='form-header-qrcode'>
              <QRCode id={this.state.editingData.MaSoThe} value={rxu.config.base_qrcode + this.state.editingData.MaSoThe || '1'} size={46} level={"H"} includeMargin={false} />
            </div>
            <div className='form-header--new'>
              <div className='avatar_print--new'>
                <img src={rxu.config.base_api + '/upload/image/' + this.state.editingData.img_landscape} alt='' />
              </div>
              <div className='textCenter__print--new'>
                <div className='text__print--new'><span>Họ và tên (Full name):&nbsp;</span> <span className='textName___print--new' style={{fontSize: (rxu.get(this.props, 'fontPrint.HoTenKhaiSinh') +'px')}}>{HoTen}</span></div>
                <div className='text__print--new'><span>Bút danh (Pseudonym):&nbsp;</span> <span >{rxu.get(this.state, 'editingData.ButDanh')}</span></div>
                <div className='text__print--new'><span>Năm sinh (Year of birth):&nbsp;</span> <span>{this.getYear(this.state.editingData.NgaySinh, this.state.editingData.NgaySinhConvert)}</span></div>
                {/*CoQuan.length <= 56 && <div className='text__print--new'><span>Cơ quan (Organization):&nbsp;</span> <span className='textOrganization___print--new'>{CoQuan}</span></div>}
                {CoQuan.length > 56 && <div className='text__print--pre' id='coquanEle'>Cơ quan (Organization):&nbsp;<span className='textOrganization___print--new'>{CoQuan}</span></div>*/}
                <div className='text__print--coquan' id='coquanEle'>Cơ quan (Organization):&nbsp;<span className='textOrganization___print--new' style={{fontSize: (rxu.get(this.props, 'fontPrint.TenCoQuanBaoChi') + 'px') }}>{CoQuan}</span></div>
              </div>
            </div>
            <div>
              <div className='rxprint__info--new'>
                <p className='text__info'>Số (No):&nbsp;<span>{rxu.get(this.state, 'editingData.MaSoThe').replace('No:', '')}</span></p>
                <p className='text__info 1'>Ngày cấp: <span>{rxu.getDate(rxu.get(this.props, 'fontPrint.NgayCap', 1609459200))}</span></p>
                <p className='text__info'>(Date of issue)</p>
                <p className='text__info'>Có giá trị đến: <span>{rxu.getDate(rxu.get(this.props, 'fontPrint.NgayHetHan', 1767225599))}</span></p>
                <p className='text__info'>(Date of expiry)</p>
              </div>
              <div className='rxprint__sign--new'>
                <div className='text__sign'>Bộ trưởng Bộ Thông tin và Truyền thông</div>
                <div className='text__sign' style={{fontWeight: 'normal'}}>Minister of Information and Communications</div>
                <div className='img__sign iconcustom-sign'>
                  {/*<img src='/images/static/chuky_BT.png' alt='' />*/}
                </div>
                <div className='text__sign'>Nguyễn Mạnh Hùng</div>
              </div>
            </div>
          </div>
        </div>
      </div> }
      {this.state.editingData && this.state.editingData.length > 0 && this.props.typePrint === 'print' && <div style={{position: 'relative', zIndex: 999999, maxWidth: '500px'}} >
        {this.state.editingData.map((obj, index) => {
          let HoTen = obj.HoTenKhaiSinh.indexOf('(') === -1 ? obj.HoTenKhaiSinh.replace(/\s+$/, '') : obj.HoTenKhaiSinh.split('(')[0].replace(/\s+$/, '')
          let NgayBatDauThe = this.state.editingData.length !== 1 ? rxu.get(obj, ['NgayBatDauThe']) : this.props.fontPrint?.NgayCap ? this.props.fontPrint?.NgayCap : rxu.get(obj, ['NgayBatDauThe'])
          let NgayKetThucThe = this.state.editingData.length !== 1 ? rxu.get(obj, ['NgayKetThucThe']) : this.props.fontPrint?.NgayHetHan ? this.props.fontPrint?.NgayHetHan : rxu.get(obj, ['NgayKetThucThe'])
          // obj.TenCoQuanBaoChi = 'Tạp chí Nghiên cứu Công nghiệp và Thương mại (trước đây là Tạp chí Nghiên cứu Thương mại)'
          return <div className='rxpopup-addnew-cus-- printer--all' key={index}>
            <div className='adblock--new'>
              <div className='adblock__label--new'>
                {/*<div className='rxprint__title'>
                  <div className='rxprint__title-main'>THẺ NHÀ BÁO</div>
                  <div className='rxprint__title-sub'>PRESS CARD</div>
                </div>*/}
                <div className='form-header-qrcode'>
                  <QRCode id={obj.MaSoThe || '1'} value={rxu.config.base_qrcode + obj.MaSoThe || '1'} size={46} level={"H"} includeMargin={false} />
                </div>
                <div className='form-header--new'>
                  <div className='avatar_print--new'>
                    <img src={rxu.config.base_api + '/upload/image/' + obj.img_landscape} alt='' />
                  </div>
                  <div className='textCenter__print--new'>
                    <div className='text__print--new' style={{alignItems: 'center'}}><span>Họ và tên (Full name):&nbsp;</span> <span className='textName___print--new' style={{fontSize: (rxu.get(this.props, 'fontPrint.HoTenKhaiSinh') +'px')}}>{HoTen}</span></div>
                    <div className='text__print--new'><span>Bút danh (Pseudonym):&nbsp;</span> <span className='text__subname'>{rxu.get(obj, 'ButDanh')}</span></div>
                    <div className='text__print--new'><span>Năm sinh (Year of birth):&nbsp;</span> <span>{this.getYear(obj.NgaySinh, obj.NgaySinhConvert)}</span></div>
                    {obj.TenCoQuanBaoChi.length <= 56 && <div className='text__print--coquan' id={'coquanEle'+index} ><span>Cơ quan (Organization):&nbsp;</span><span className='textOrganization___print--new' style={{fontSize: (rxu.get(this.props, 'fontPrint.TenCoQuanBaoChi') +'px')}}>{obj.TenCoQuanBaoChi + ' ' + rxu.get(this.props, ['fontPrint','BanChuyenDoiTuong'], '')}</span></div>}
                    {obj.TenCoQuanBaoChi.length > 56 && <div className='text__print--coquan1' id={'coquanEle'+index} ><span>Cơ quan (Organization):&nbsp;</span><span className='textOrganization___print--new' style={{fontSize: (rxu.get(this.props, 'fontPrint.TenCoQuanBaoChi') +'px')}}>{obj.TenCoQuanBaoChi + ' ' + rxu.get(this.props, ['fontPrint','BanChuyenDoiTuong'], '')}</span></div>}
                    {/*<div className='text__print--coquan' id={'coquanEle'+index} ><span>Cơ quan (Organization):&nbsp;</span><span className='textOrganization___print--new' style={{fontSize: (rxu.get(this.props, 'fontPrint.TenCoQuanBaoChi') +'px')}}>{'Tạp chí Nghiên cứu Công nghiệp và Thương mại (trước đây là Tạp chí Nghiên cứu Thương mại)'}</span></div>*/}
                  </div>
                </div>
                <div>
                  <div className='rxprint__info--new'>
                    <p className='text__info'>Số (No):&nbsp;<span>{rxu.get(obj, 'MaSoThe').replace('No:', '')}</span></p>
                    <p className='text__info 2'>Ngày cấp: <span>{rxu.getDate(NgayBatDauThe)}</span></p>
                    <p className='text__info'>(Date of issue)</p>
                    <p className='text__info'>Có giá trị đến: <span>{rxu.getDate(NgayKetThucThe || 1767225599)}</span></p>
                    <p className='text__info'>(Date of expiry)</p>
                  </div>
                  <div className='rxprint__sign--new'>
                    <div className='text__sign'>Bộ trưởng Bộ Thông tin và Truyền thông</div>
                    <div className='text__sign' style={{fontWeight: 'normal'}}>Minister of Information and Communications</div>
                    <div className='img__sign iconcustom-sign'>
                      {/*<img src='/images/static/chuky_BT.png' alt='' />*/}
                    </div>
                    <div className='text__sign'>Nguyễn Mạnh Hùng</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      })}
      </div>}
      {this.props.typePrint === 'save' && <div className='rxpopup-addnew-cus__ print' id='save_card'>
        <div className='adblock--print'>
          {this.state.editingData && this.state.editingData['_id'] && <div className='adblock__image' style={{position:'relative', overflow: 'hidden'}}  id='getIMG'>
            <div className='rxprint__title'>
              <div className='rxprint__title-main'>THẺ NHÀ BÁO</div>
              <div className='rxprint__title-sub'>PRESS CARD</div>
            </div>
            <div className='form-header-right barcode__print' id='qrcode'>
              <QRCode id={this.state.editingData.MaSoThe || '1'} value={rxu.config.base_qrcode + this.state.editingData.MaSoThe} size={69} level={"H"} includeMargin={false} />{/*this.state.editingData.codeGen || '1'*/}
            </div>
            <div className='avatar_print'>
              <img src={rxu.config.base_api + '/upload/image/' + this.state.editingData.img_landscape} className='avatar__print--img' alt=''/>
            </div>
            <div className='form-header'>
              <div>
                <div className='textCenter__print' >
                  <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Họ và tên (Full name):&nbsp;</span> <span style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>{this.state.editingData.HoTenKhaiSinh || ' '}</span></p>
                  <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Bút danh (Pseudonym):&nbsp;</span> <span style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>{this.state.editingData.ButDanh || ' '}</span></p>
                  <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Năm sinh (Year of birth):&nbsp;</span> <span style={{fontWeight: 'bold'}}>{this.getYear(this.state.editingData.NgaySinh, this.state.editingData.NgaySinhConvert)}</span></p>
                  <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Cơ quan (Organization):&nbsp;</span> <span className='textOrganization___print' style={{fontWeight: 'bold'}}>{this.state.editingData.TenCoQuanBaoChi + ' ' + rxu.get(this.props, ['fontPrint','BanChuyenDoiTuong'], '')}</span></p>
                </div>

              </div>
            </div>
            <div className='rxprint__info'>
              <p className='text__print--info'>Số (No):&nbsp;<span>{this.state.editingData.MaSoThe || ''}</span></p>
              {/*}<p className='text__print--info 3'>Ngày cấp: <span>{rxu.getDate(rxu.get(this.props, 'fontPrint.NgayCap', 1609459200))}</span></p>*/}
              <p className='text__print--info 3'>Ngày cấp: <span>{rxu.getDate(rxu.get(this.state.editingData, 'NgayBatDauThe', 1609459200))}</span></p>

              <p className='text__print--info'>(Date of issue)</p>
              {/*}<p className='text__print--info'>Có giá trị đến: <span>{rxu.getDate(rxu.get(this.props, 'fontPrint.NgayHetHan', 1767225599))}</span></p>*/}
              <p className='text__print--info'>Có giá trị đến: <span>{rxu.getDate(rxu.get(this.state.editingData, 'NgayKetThucThe', 1767225599))}</span></p>

              <p className='text__print--info'>(Date of expiry)</p>
            </div>
            <div className='rxprint__sign'>
              <div className='text__print--sign'>Bộ trưởng Bộ Thông tin và Truyền thông</div>
              <div className='text__print--sign' style={{fontWeight: 'normal'}}>Minister of Information and Communications</div>
              <div className='sign__img iconcustom-sign'>
                {/*<img src='/images/static/chuky_BT.png' alt='' />*/}
              </div>
              <div className='text__print--sign'>Nguyễn Mạnh Hùng</div>
            </div>
          </div>}
          {this.state.editingData && this.state.editingData.length > 0 && this.state.editingData.map((obj, index) => (
            <div className='adblock__image' style={{position:'relative', overflow: 'hidden'}} key={index} id={'getMulti'+index}>
              <div className='rxprint__title'>
                <div className='rxprint__title-main'>THẺ NHÀ BÁO</div>
                <div className='rxprint__title-sub'>PRESS CARD</div>
              </div>
              <div className='form-header-right barcode__print' id='qrcode'>
                <QRCode id={obj.MaSoThe || '1'} value={rxu.config.base_qrcode + obj.MaSoThe} size={69} level={"H"} includeMargin={false} />{/*this.state.editingData.codeGen || '1'*/}
              </div>
              <div className='avatar_print'>
                <img src={rxu.config.base_api + '/upload/image/' + obj.img_landscape} className='avatar__print--img img--avatar' alt='' onError={() => rxu.imgError(index, 'img--avatar')}/>
              </div>
              <div className='form-header'>
                <div>
                  <div className='textCenter__print' >
                    <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Họ và tên (Full name):&nbsp;</span> <span style={{fontWeight: 'bold', whiteSpace: 'nowrap'}} className='text__name'>{obj.HoTenKhaiSinh || ' '}</span></p>
                    <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Bút danh (Pseudonym):&nbsp;</span> <span style={{fontWeight: 'bold', whiteSpace: 'nowrap'}} className='text__subname'>{obj.ButDanh || ' '}</span></p>
                    <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Năm sinh (Year of birth):&nbsp;</span> <span style={{fontWeight: 'bold'}}>{this.getYear(this.state.editingData.NgaySinh, obj.NgaySinhConvert)}</span></p>
                    <p className='text__print'><span style={{whiteSpace: 'nowrap'}}>Cơ quan (Organization):&nbsp;</span> <span className='textOrganization___print' style={{fontWeight: 'bold', fontSize: (rxu.get(this.props, 'fontPrint.TenCoQuanBaoChi') +'px')}}>{obj.TenCoQuanBaoChi + ' ' + rxu.get(this.props, ['fontPrint','BanChuyenDoiTuong'], '')}</span></p>
                  </div>

                </div>
              </div>
              <div className='rxprint__info'>
                <p className='text__print--info'>Số (No):&nbsp;<span>{obj.MaSoThe}</span></p>
                {/*}<p className='text__print--info 4'>Ngày cấp: <span>{rxu.getDate(rxu.get(this.props, 'fontPrint.NgayCap', 1609459200))}</span></p>*/}
                <p className='text__print--info 4'>Ngày cấp: <span>{rxu.getDate( (this.state.editingData.length !== 1 ? rxu.get(obj, ['NgayBatDauThe']) : this.props.fontPrint?.NgayCap ? this.props.fontPrint?.NgayCap : rxu.get(obj, ['NgayBatDauThe'])) )}</span></p>

                <p className='text__print--info'>(Date of issue)</p>
                {/*}<p className='text__print--info'>Có giá trị đến: <span>{rxu.getDate(rxu.get(this.props, 'fontPrint.NgayHetHan', 1767225599))}</span></p>*/}
                <p className='text__print--info'>Có giá trị đến: <span>{rxu.getDate((this.state.editingData.length !== 1 ? rxu.get(obj, ['NgayKetThucThe']) : this.props.fontPrint?.NgayHetHan ? this.props.fontPrint?.NgayHetHan : rxu.get(obj, ['NgayKetThucThe'])))}</span></p>

                <p className='text__print--info'>(Date of expiry)</p>
              </div>
              <div className='rxprint__sign'>
                <div className='text__print--sign'>Bộ trưởng Bộ Thông tin và Truyền thông</div>
                <div className='text__print--sign' style={{ fontWeight: 'normal' }}>Minister of Information and Communications</div>
                <div className='sign__img iconcustom-sign'> </div>
                <div className='text__print--sign'>Nguyễn Mạnh Hùng</div>
              </div>

            </div>
          )) }
        </div>
      </div>}
    </div>)
  }
}

RxPrintCard.defaultProps = { onToggle: () => {}, value: 0 }

export default RxPrintCard
