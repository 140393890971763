import React, { Component } from 'react'
import { connect } from 'react-redux'

// const { rxgetLocal } = global.rootRequire('classes/request')
const { rxnavToggle, rxnavClose, rxnavChange, logoutAction } = global.rootRequire('redux')
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
const { apiget,apipost } = global.rootRequire('classes/request')
// const rxu = global.rxu


class changeInfoComponent extends Component {

    constructor (props, context) {
        super(props, context)
        this.state = {
            status: '',
            data: {},
            editingData: {}
        }
        this.fetchData()
    }

    componentDidMount () {

    }


    fetchData () {
        if(this.props.auth.user.user){
            apiget(global.rxu.config.api_user_find, {_id: this.props.auth.user.user._id}, { '1': (json) => { this.setState({ data: json.data, editingData: {
                 _id: json.data._id,
                 fullname: json.data.fullname,
                 email: json.data.email,
                 phone: json.data.phone,
                 address: json.data.address
                } }, () => {}) } })
        }
    }

    onUpdateData() {
        apipost(global.rxu.config.api_user_edit, this.state.editingData, {
            '1': (json) => {
                this.fetchData(true)
                this.setState({status: json.success})
            }
          })
    }

    // B L U R   E V E N T
    onBlurData(e, name) {
        let editingData = this.state.editingData
        editingData[name] = e.target.value
        this.setState({
            editingData: editingData
        })
    }

    onClickCancel() {
      let clear = {};
      if(this.state.editingData._id){
        clear = this.state.data
      }

      this.setState({
        editingData: clear
      })
    }

    render () {
        return(
            <div className="adblock">
                <div className="adblock__body">
                    <div className="rxcol-100">
                        <div className="betable adblock__inner">
                            <div className="adform__header">
                                <div className="adform__name">Thay đổi thông tin</div>
                                {this.state.status &&
                                    <div style={{color: 'red', margin: '5px'}}>{this.state.status === 1 ? "Cập nhật thông tin thành công" : "Cập nhật thông tin không thành công"}</div>
                                }
                            </div>
                            <div className="adform__body">
                                <div>
                                    <div className="fullwidth-label">Họ và tên</div>
                                    <input type="text" value={this.state.editingData.fullname ? this.state.editingData.fullname : ''} onChange={(e) => {this.onBlurData(e, 'fullname')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Email</div>
                                    <input type="text" value={this.state.editingData.email ? this.state.editingData.email : ''} onChange={(e) => {this.onBlurData(e, 'email')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Số điện thoại</div>
                                    <input type="text" value={this.state.editingData.phone ? this.state.editingData.phone : ''} onChange={(e) => {this.onBlurData(e, 'phone')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Địa chỉ</div>
                                    <input type="text" value={this.state.editingData.address ? this.state.editingData.address : ''} onChange={(e) => {this.onBlurData(e, 'address')}}/>
                                </div>
                                {/* <div>
                                    <div className="fullwidth-label">Nhập mật khẩu cũ</div>
                                    <input type="password" value={this.state.editingData.passwordOld ? this.state.editingData.passwordOld : ''} onChange={(e) => {this.onBlurData(e, 'passwordOld')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Nhập mật khẩu mới</div>
                                    <input type="password" className="fullwidth-input" value={this.state.editingData.passwordNew ? this.state.editingData.passwordNew : ''} onChange={(e) => {this.onBlurData(e, 'passwordNew')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Nhập lại mật khẩu</div>
                                    <input type="password" className="fullwidth-input" value={this.state.editingData.repassword ? this.state.editingData.repassword : ''} onChange={(e) => {this.onBlurData(e, 'repassword')}} />
                                </div> */}
                            </div>
                            <div className="adform__footer">
                                <div className="adform__btns clearfix">
                                    <div className="adform__btncancel" onClick={() => {this.onClickCancel()}}>Hủy thay đổi</div>
                                    <div className="adform__close">
                                        <span className="adform__btn--save" onClick={() => {this.onUpdateData()}}>Lưu</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    rxnav: state.rxnav,
    auth: state.auth
})

const mapDispatchToProps = {
rxnavToggle,
rxnavClose,
rxnavChange,
logoutAction
}

const changeInfoComponent_ = connect(
mapStateToProps,
mapDispatchToProps
)(changeInfoComponent)


export default changeInfoComponent_
