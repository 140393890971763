import React, { Component } from 'react'
import { connect } from 'react-redux'
const { rxnavToggle, rxmenuToggle } = global.rootRequire('redux')
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const { apiget } = global.rootRequire('classes/request')

class manualBarComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { flagUpdateCat: false, curCat: 0, cate: [],  activeNavs: true }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    this.fetchData()
  }


  componentDidMount () {
  }

  fetchData () {
    apiget(global.rxu.config.api_office, {}, { '1': (json) => { this.setState({ cate: json.data }, () => {}) } })
  }

  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat (e) {
    let paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  //HƯỚNG DẪN TẠO HỒ SƠ VÀ THẨM ĐỊNH
  renderTableBody1 () {
    return (
      <div className="body_manual">
          <div className="manual_content">
            <img className="manual__img" alt="bar1" src="/images/static/step1.jpg" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step2.jpg" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step3.jpg" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step4.jpg" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step5.jpg" /><br/>
          </div>
      </div>
    )
  }
  renderTableBody2 () {
    return (
      <div className="body_manual">
          <div className="manual_content">
            <img className="manual__img" alt="bar1" src="/images/static/step11.png" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step12.png" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step13.png" /><br/>
            <img className="manual__img" alt="bar1" src="/images/static/step14.png" /><br/>
          </div>
      </div>
    )
  }

  // R E N D E R S
  render () {

    return (
      <AdminBlock>
        {<div className={this.props.rxnav.menu['help_register_system'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_register_system')}>
              <span>HƯỚNG DẪN TẠO HỒ SƠ VÀ TRÌNH THẨM ĐỊNH</span></div>}
        {this.props.rxnav.menu['help_register_system'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_register_system')} /> &&
        <div className={this.props.rxnav.menu['help_register_system'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody1()}
          </div>
        </div>}

        {<div className={this.props.rxnav.menu['help_register_system_1'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_register_system_1')}>
              <span>HƯỚNG DẪN QUY TRÌNH CẤP ĐỔI DO CHUYỂN CƠ QUAN/CẤP LẠI THẺ NHÀ BÁO</span></div>}
        {this.props.rxnav.menu['help_register_system_1'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_register_system_1')} /> &&
        <div className={this.props.rxnav.menu['help_register_system_1'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody2()}
          </div>
        </div>}


        {/* {<div className={this.props.rxnav.menu['help_login_system'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_login_system')}>
              <span>HƯỚNG DẪN ĐĂNG NHẬP </span></div>}
        {this.props.rxnav.menu['help_login_system'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_login_system')} /> &&
        <div className={this.props.rxnav.menu['help_login_system'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody1()}
          </div>
        </div>} */}
      </AdminBlock>
   )}
}

const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav
})

const mapDispatchToProps = {
  rxnavToggle,
  rxmenuToggle
}

const manualBarComponent_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(manualBarComponent)


export default manualBarComponent_
