import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

const rxType = ['', 'Cục Báo Chí', 'Cục Phát Thanh']
const statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']
const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Cấp đổi theo kỳ hạn', 'Cấp mới', 'Cấp đổi do chuyển cơ quan'] //Hồ sơ cấp theo kỳ hạn mới

export const renderCatHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className={inthis.helpSortClass('name', ['betable-th'])} onClick={(e) => inthis.onClickSort(e, 'Cat_Name')} >Tên cơ quan</th>
    <th className={inthis.helpSortClass('action', ['betable-th'])} >Đồng bộ</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  let tempData = global.rxu.array(inthis.state.data).slice(0)
  let rxactive = vm.state.rxactive || {}
  return tempData.map((perdata, index) => (
    <tr className={rxactive[perdata.Cat_ID] ? 'rx-cursor rxactive' : ''} key={perdata._id} >
      <td onClick={(e) => vm.onClickCatItem(e, perdata.Cat_ID)} className='rx-cursor'>{perdata.Cat_Name}</td>
      {perdata.is_synced !== 1 ? <td onClick={(e) => inthis.sendProfileSync(perdata)} className='rx-cursor'><div className='rx__sync red'>Đồng bộ</div></td> : <td onClick={(e) => inthis.sendProfileSync(perdata)}><div className='rx__sync green'>Đã đồng bộ</div></td>}
    </tr>))
}

export const renderTableHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className={inthis.helpSortClass('is_synced', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'is_synced')} >Trạng thái</th>
    <th className='rxwidth--100'>Mã đồng bộ</th>
    <th className={inthis.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'name')} >Họ và tên</th>
    <th className='rxwidth--100'>Trạng thái</th>
    <th className='rxwidth--100'>Loại hồ sơ</th>
    <th className='rxwidth--100'>Cơ quan</th>
    <th className={inthis.helpSortClass('created_at', ['betable-th'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
    {perdata.is_synced !== 1 ? <td onClick={(e) => inthis.sendProfileSyncOne(perdata)} className='rx-cursor'><div className='rx__sync red'>Đồng bộ</div></td> : <td onClick={(e) => inthis.sendProfileSyncUpdate(perdata)}><div className='rx__sync green update'>Cập nhật đồng bộ</div></td>}
    <td>{perdata.code}</td>
    <td>{(perdata.HoTenKhaiSinh).toUpperCase()}</td>
    <td>{statusTinhtrang[perdata.IsStatus]}</td>
    <td>{statusPhanloai[perdata.StatusPhanLoai]}</td>
    <td>{perdata.TenCoQuanBaoChi}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
  </tr>))

export const renderComponent = (vm) =>
  <AdminBlock name='ĐỒNG BỘ HỒ SƠ' desc=''>
    <div className='row'>
      <div className='col-md-4'>
        <RxCrud renderTableHead={vm.renderCatHead} renderTableBody={vm.renderCatBody} onClickCatItem={vm.onClickCatItem} name='sync' type={1} api={'api_newspaper_office'} form={[
          { name: 'Cate name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
        ]} />
      </div>

      <div className='col-md-8'>
        <RxCrud renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='sync' type={2} api={'api_card'} form={[
          { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { name: 'Desc', func: (inthis) => (<input tabIndex='2' type='text' value={inthis.state.editingData.desc} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },
          { name: 'Cate name', func: (inthis) => (<RxSelectbox
                                                      name='cate'
                                                      options={inthis.state.cate}
                                                      results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                                                      onChange={(result) => { inthis.onBlurDataValue(result, 'TenCoQuanBaoChi') }}
                                                      api='api_newspaper_office'
                                                      paging={inthis.state.pagingCat}
                                                      optionDefault='--Chọn cơ quan báo chí--'
                                                  />) },
          { type: 'devide' },
          { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
        ]} />
      </div>
    </div>
  </AdminBlock>
