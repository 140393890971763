/* global FormData, fetch */
import React, { Component } from 'react'
global.isclient && require('./rxUpload.css')

class RxUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
      images: props.images || [],
      path: props.path || '',
      callback: props.callback,
      single: props.single || 1,
      disable: props.disabled ? 1 : 0
    }
  }

  componentDidMount () {
  }

  onClickDataUpload (e) {
    this.refs.data_image.click()
  }

  onChangeDataImageFile (e) {
    e.stopPropagation()
    e.preventDefault()
    if (typeof (e.target.files[0]) !== 'undefined') {

      let file = e.target.files[0]

      const allowedExtensions = global.allowedExtensions,  // ['jpg','png','pdf','PDF','docx','doc'],
            sizeLimit = global.sizeLimit //  50971520;

      const { name:fileName, size:fileSize } = file;
      const fileExtension = fileName.split(".").pop();

      if(!allowedExtensions.includes(fileExtension.toLowerCase())){
        alert("Định dạng file không cho phép! Vui lòng chọn đúng định dạng file tải lên : 'jpg','png','pdf','PDF' ");
        file = ''
      }
      else if(fileSize > sizeLimit) {
        alert("Dung lượng file quá lớn! Vui lòng chọn dung lượng file < 20 Mb")
        file = ''
      }
      else {
        global.showProgress()
        let data = new FormData()
        data.append('uploadFile', file, file.name)
        fetch(global.rxu.config.base_api + '/upload', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
          if (json.success === 1) {
            this.onBlurData(json)
          }
        })
        .finally(() => { global.hideProgress() })
        .catch(error => console.log(error))
      }
    }
  }

  onBlurData (json) {
    this.setState({ images: json.data.file, path: json.data.path }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }

  // Array
  onClickDataUploadArr (e, index) {
    this.refs['data_image_' + index].click()
  }

  onChangeDataImageFileArr (e, index) {
    e.stopPropagation()
    e.preventDefault()

    if (typeof (e.target.files[0]) !== 'undefined') {

      let file = e.target.files[0]

      const allowedExtensions = global.allowedExtensions,  // ['jpg','png','pdf','PDF','docx','doc'],
            sizeLimit = global.sizeLimit //  50971520;

      const { name:fileName, size:fileSize } = file;
      const fileExtension = fileName.split(".").pop();

      if(!allowedExtensions.includes(fileExtension.toLowerCase())){
        alert("Định dạng file không cho phép! Vui lòng chọn đúng định dạng file tải lên : 'jpg','png','pdf','PDF' ");
        file = ''
      }
      else if(fileSize > sizeLimit) {
        alert("Dung lượng file quá lớn! Vui lòng chọn dung lượng file < 20 Mb")
        file = ''
      }
      else {
        global.showProgress()
        let data = new FormData()
        data.append('uploadFile', file, file.name)
        fetch(global.rxu.config.base_api + '/upload', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
          if (json.success === 1) {
            this.onBlurDataArr(json, index)
          }
        })
        .finally(() => { global.hideProgress() })
        .catch(error => console.log(error))
      }
    }
  }

  onBlurDataArr (json, index) {
    let tempImages = this.state.images
    tempImages[index] = json.data.file
    this.setState({ images: tempImages }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }

  onClickImgRemove (e, index) {
    let tempImages = this.state.images
    tempImages.splice(index, 1)
    this.setState({ images: tempImages }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }

  getFileTypeIcon(fileType) {
		switch (fileType) {
			case 'pdf':
				return 'assets/img/pdf-file.png';

			case 'doc':
			case 'docx':
				return 'assets/img/word-file.png';

			case 'xlsx':
				return 'assets/img/excel-file.png';
      default:
      return 'ico_app_default.jpg'
		}
	}

  render () {
    let img_default = 'ico_app_default.jpg'
    let tmpImgStr
    let tmpImgStrAdd
    let path = '' // (typeof (this.state.path) === 'string' && this.state.path !== undefined) ? this.state.path : ''
    if (this.state.single === 1) {
      let tempImages = (typeof (this.state.images) === 'string') ? this.state.images : img_default
      tmpImgStr =
        <div>
          { this.state.disable ?
            <input type='file' id='file' ref='data_image' style={{ display: 'none' }} disabled/>
            :
            <input type='file' id='file' ref='data_image' style={{ display: 'none' }} onChange={(e) => this.onChangeDataImageFile(e)} />
          }
          <img className={this.props.type && this.props.type === 'avatar' ? 'admin-avatar' : 'admin-product-img-upload'} alt='ico_default' src={global.rxu.config.base_api + '/upload/image/' + path + tempImages} onClick={(e) => this.onClickDataUpload(e)} />
        </div>
    } else {
      let tempLastIndex = 0
      tmpImgStr = this.state.images.map((perdata, index) => {
        // let x = getFileTypeIcon(perdata.split('.')[1].toLowerCase())
        let tempRef = 'data_image_' + index
        tempLastIndex = index
        return (
          <div key={index} className='admin-product-img-multi'>
            <div className='admin-product-img-remove' onClick={(e) => this.onClickImgRemove(e, index)}>x</div>
            <input type='file' id='file' ref={tempRef} style={{ display: 'none' }} onChange={(e) => this.onChangeDataImageFileArr(e, index)} />
            <img className={this.props.type && this.props.type === 'avatar' ? 'admin-avatar' : 'admin-product-img-upload'} alt='ico_default' src={global.rxu.config.base_api + '/upload/image/' + path + (perdata || img_default)} onClick={(e) => this.onClickDataUploadArr(e, index)} />
          </div>
        )
      })

      tempLastIndex += 1; let tempLastRef = 'data_image_' + tempLastIndex
      tmpImgStrAdd =
        <div key={tempLastIndex} className='admin-product-img-multi'>
          <input type='file' id='file' ref={tempLastRef} style={{ display: 'none' }} onChange={(e) => this.onChangeDataImageFileArr(e, tempLastIndex)} />
          <img className={this.props.type && this.props.type === 'avatar' ? 'admin-avatar' : 'admin-product-img-upload'} alt='ico_default' src={global.rxu.config.base_api + '/upload/image/' + path + (this.state.images[tempLastIndex] || img_default)} onClick={(e) => this.onClickDataUploadArr(e, tempLastIndex)} />
        </div>
    }

    return (
      <div className='clearfix' style={{margin: 'auto'}}>{tmpImgStr}{tmpImgStrAdd}</div>
    )
  }
}

export default RxUpload
