import * as Jsx from './rxCrudSta.jsx'
import * as xlsx from 'xlsx'
import { connect } from 'react-redux'
// import SaveAs from 'file-saver'

global.isclient && require('./rxCrud.css')
global.isclient && require('jsoneditor/dist/jsoneditor.css')

const { apiget, apipost, rxgetLocal, rxJson } = global.rootRequire('classes/request')
const RxComponent = global.rootRequire('components/shares/rxComponent').default

const rxu = global.rxu
const WAIT_INTERVAL = 500

class RxCrudSta extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this._mounted = false
    this.state = {
      flagUpdate: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      pagingCat: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      editingData: {},
      importData: [],
      extra: {},
      data: {},
      dataCard: {},
      flags: {},
      cate: [],
      position: [],
      view: false,
      temp: this.props.temp,
      optionChecked: [
        {id:'Avatar', name: 'Ảnh thẻ'},
        {id:'TenCoQuanBaoChi', name: 'Tên cơ quan báo chí đề nghị cấp thẻ'},
        {id:'LoaiCoquan', name: 'Tên đơn vị công tác (Áp dụng với TH công tác tại Đài TT-TH cấp huyện)'},
        {id:'codeGen', name: 'Số thẻ'},
        {id:'created_at', name: 'Ngày cấp'},
        {id:'ChuKy', name: 'Thẻ trích xuất'},
        {id:'IsStatus', name: 'Tình trạng thẻ'},
        {id:'CMND', name: 'Số thẻ CCCD/CMND'},
        {id:'Mathecu', name: 'Số thẻ cũ'},
        {id:'codeGen', name: 'QR code phục vụ kiểm tra số liệu'}
      ]
    }
    this.onChangeContentCKE = this.onChangeContentCKE.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    this.fetchAlldata()
  }

  componentWillUnmount () {
    this._mounted = false
    super.componentWillUnmount()
    if (this.jsoneditor) { this.jsoneditor.destroy() }
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  componentDidUpdate (prevProps, nextState) {
    this._mounted = true
    if ([false, true].indexOf(prevProps.flagUpdate) !== -1 && prevProps.flagUpdate !== this.state.flagUpdate) {
      this.setStateRx({ flagUpdate: prevProps.flagUpdate, temp: nextState.temp, dataCard: nextState.dataCard, view: nextState.view})
      this.run('parentUpdate', {})
    }
  }

  // D A T A
  fetchAlldata () { this.fetchDataCard(); } //this.fetchCate(); this.fetchPosition() }
  fetchDataCard (runCallback = false) {
    apiget(rxu.get(rxu.config, this.props.api), this.state.paging, {
      '1': (json) => {
        this.setStateRx({ data: json.data, extra: json.extra }, () => {
          // if (!rxu.get(this.state, ['temp', 'filterFields'])) {
          //   let temp = this.state.temp; temp.filterFields = rxu.get(this.state, ['data', 0])
          //   this.setStateRx({ temp })
          // }
        })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchData (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_card'), this.state.paging, {
      '1': (json) => {
        this.setState({ dataCard: json.data, extra: json.extra, view: true}, () => { })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }


  fetchCate (runCallback = false) {
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    let pagingCat = this.state.pagingCat
    pagingCat['search_Type'] = global.rxu.get(user, 'user.user.Type', 1)
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), pagingCat, {
      '1': (json) => {
        this.setStateRx({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchPosition (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_card'), this.state.pagingCat, {
      '1': (json) => {
        this.setStateRx({ position: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    let flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setStateRx({ flags: flags })
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  // C L I C K   E V E N T
  onClickData (e, perdata) {}
  onClickSort (e, stcol) {
    let paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setStateRx({ paging: paging }, () => { this.fetchData() })
  }

  onClickExcelUpload(flagname) {
    let flags = this.state.flags
    if(!flags[flagname]) { flags[flagname] = false }
    flags[flagname] = true
    this.setStateRx({flagname: flagname})
  }

  onClickDataImport(e) {
    let scope = this
    let reader = new FileReader()
    let fileDom = e.target || e.srcElement

    if(fileDom.value !==  '') {
      let file = fileDom.files[0]
      if(typeof file !== 'undefined') {
        reader.onload = function(e) {
          let datatmp = new Uint8Array(e.target.result);
          let workbook = xlsx.read(datatmp, {type: 'array'});
          workbook.SheetNames.forEach((sheetName) => {
            var data = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName])
            if(data && data[0]['But danh'] && data[0]['Ma the'] && data[0]['Ten co quan'] && data[0]['Ngay het han'] && data[0]['Ngay cap'] && data[0]['Nguoi ky']
              && data[0]['Nam sinh'] && data[0]['Chuc vu'] && data[0]['Chu ky'] ) {
              scope.setStateRx({importData: data})
            } else {
              alert('File sai định dạng')
            }
          })
        }
        reader.readAsArrayBuffer(file)
      }
    }
    else {}
  }
  onClickSubmitExcelUpload() {
    apipost(rxu.get(rxu.config, this.props.api) + '/importExcel', this.state.importData, {
      '1': (json) => {
        this.setStateRx({importData: [] }, () => this.fetchData(true))
        // if(json.data && json.data.length > 0){
        //   console.log('có '+json.data.length+' sản phẩm đã tồn tại')
        // }
      }
    })
  }

  onClickDataExport (e) {
    apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
      '1': (json) => { global.JSONToCSVConvertor(json.data, '') }
    })
  }

  onClickDataExportConfig (e) {
    // apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
    //   '1': (json) => { global.JSONToCSVConfig(json.data, '') }
    // })
    apiget(rxu.get(rxu.config, 'api_card'), { ...this.state.paging, pg_size: 10000 }, {
      '1': (json) => { global.JSONToCSVConfig(json.data, '') }
    })
  }

  onClickDataDeleteAll (e) {
    let r = window.confirm('Bạn muốn xoá tất cả dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, 'api_card_delete_all'), {}, {
        '1': (json) => {   this.fetchData(true) }
      })
    } else {}

  }

  onClickDataNew (e) {
    global.scrolltop()
    let timeStr = Date.now().toString()
    let clone = { name: this.props.name + '_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0, optionParams: [] }
    if(this.props.name === 'Card') { clone.QuaTrinhHoatDongUpdate = [{time:'', ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''}] }
    if(this.props.name === 'User') { clone.CoQuanBaoChi = []; clone.CoQuanBaoChiID = [] }
    this.setStateRx({ editingData: clone })
  }

  onClickDataEdit (e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    this.setStateRx({ editingData: clone, flags: {}, view: false })
  }

  onClickViewData(e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    let flags = this.state.flags
    flags.view = 1
    this.setStateRx({ editingData: clone, flags: flags })
  }

  onClickViewTable(e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    let flags = this.state.flags
    let optionParams = clone.optionParams || []
    let paging = {...this.state.paging}
    paging.select = optionParams
    flags.view = 1
    this.setStateRx({ paging: paging, flags: flags }, () => this.fetchData())
  }

  onClickDataDelete (e, perdata) {
    let r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, this.props.api) + '/delete', {_id: perdata._id}, {
        '1': (json) => { this.fetchData(true) }
      })
    } else {}
  }

  onClickDataRestore (e, perdata) {
    apiget(rxu.get(rxu.config, this.props.api) + '/restore', {_id: perdata._id}, {
      '1': (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit (e, perdata) {
    global.scrolltop()
    let editingData = this.state.editingData
    if (this.props.name === 'Analytics') {
      editingData.optionParams = this.state.idDataEdit
    }
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', editingData, {
      '1': (json) => { this.fetchDataCard(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataUpdateSubmitRaw (e, perdata) {
    global.scrolltop()
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
      '1': (json) => { this.fetchData(true) },
      'default': () => {}
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit (e, perdata) {
    global.scrolltop()

    this.setStateRx({loading: true}, () => {
      let editingData = {...this.state.editingData, hideProgress: true}
      if (this.props.name === 'Analytics') {
        editingData.optionParams = this.state.idDataEdit
      }
      apipost(rxu.get(rxu.config, this.props.api), editingData, {
        '1': (json) => { this.setStateRx({loading: false}, () => this.fetchDataCard(true)) },
        'default': (json) => { this.setStateRx({msg: json});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {}}) }, 3000)},
      })
      this.onClickDataEdit({}, {})
    })
  }

  onClickDataTrash (e, isdeleted) {
    let paging = this.state.paging
    paging['search_is_deleted'] = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickGenPermission () {
    apiget(rxu.config.api_permission_genpermission, {}, { '1': (json) => {
      this.fetchData()
    } })
  }

  onClickAddRowTable() {
    let editingData = this.state.editingData
    if(editingData['QuaTrinhHoatDongUpdate']) {
      editingData['QuaTrinhHoatDongUpdate'].push({time:'', ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''})
    } else {
      editingData['QuaTrinhHoatDongUpdate'] = [{time:'', ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''}]
    }
    this.setStateRx({editingData: editingData})
  }
  onClickRowDel(index) {
    let editingData = this.state.editingData
    if(editingData['QuaTrinhHoatDongUpdate'] && editingData['QuaTrinhHoatDongUpdate'].length > 0) {
      let tmp = editingData['QuaTrinhHoatDongUpdate'][index]['time']
      editingData['QuaTrinhHoatDongUpdate'] = editingData['QuaTrinhHoatDongUpdate'].filter(o => o.time !== tmp)
    }
    this.setStateRx({editingData: editingData})
  }

  // B L U R   E V E N T
  onBlurData (e, name, options, name1, index) {
    options = options || {}
    let editingData = this.state.editingData
    if (name === 'role_id') {
      if (this.props.roles && e.target.value) {
        let ob = this.props.roles.find(o => o.name === e.target.value)
        editingData.role_id = ob ? ob._id : ''
        editingData.role    = ob ? ob.name : ''
      }
    } else if(name ==='QuaTrinhHoatDongUpdate') {
      editingData['QuaTrinhHoatDongUpdate'][index][name1] = e.target.value
    } else {
      if (options.strim) {
        editingData[name] = e.target.value.toString().replace(/(,)/g, '')
      } else {
        editingData[name] = e.target.value
      }
    }
    this.setState({ editingData: editingData })
  }

  onBlurDataValue (value, name) {
    let editingData = this.state.editingData
    // console.log(value, name, 'ooo')
    if(name === 'TenChucVu' && value) {
      editingData['TenChucVu'] = value.Ten
      editingData['ChucVuID'] = value.ChucVuID
    } else if (name === 'TenCoQuanBaoChi') {
      editingData['TenCoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiID'] = value.Cat_ID
    } else if(name === 'adduser') {
      editingData['CoQuanBaoChi']= value.Cat_Name
      editingData['id'] = value.Cat_ID
      editingData['CoQuanBaoChiID']= value.Cat_ID
    }else {
      editingData[name] = value
    }
    this.setState({ editingData: editingData })
  }

  onBlurDatafilter (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      if(e === -1) {
        switch (name) {
          case 'TenCoQuanBaoChi':
             delete paging['searche_' + name]
            break;
          case 'TenChucVu':
             delete paging['searche_' + name]
            break;
          default:
             delete paging['search_' + name]
        }
      } else {
        switch (name) {
          case 'TenCoQuanBaoChi':
            paging['searche_' + name] = e.Cat_Name
            break;
          case 'TenChucVu':
            paging['searche_' + name] = e.Ten
            break;
          default:
            paging['search_' + name] = e
        }
      }

      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else if(name === 'search_cate') {
      let paging = this.state.pagingCat
      paging['search_Cat_Name'] = e
      this.setState({ pagingCat: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchCate()
      }, WAIT_INTERVAL)
    } else if(name === 'Status') {
      let paging = this.state.paging
      paging['search_Status'] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else {
      let paging = this.state.paging
      paging['search_' + name] = e.target.value
      if(paging['search_' + name] === '-1' || paging['search_' + name === -1] || e.target.value === '') { delete paging['search_' + name] }
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
  }

  onBlurDataReview (e, name, type) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    if(name === 'phase') {
      if(e === -1) {
        delete paging['searchid_phase_id']
        delete paging['phase']
      } else {
        paging['searchid_phase_id'] = e._id
        paging['phase'] = e.name
      }
      if(e !== -1 && e.submit !== 0) {
        this.setState({ isSubmit: false })
      }
    } else {
      if(name === 'Type' && paging['search_LoaiCoquan']) {
        delete paging['search_LoaiCoquan']
      }
      if(name === 'LoaiCoquan' && paging['search_Type']) {
        delete paging['search_Type']
      }
      paging['search_' + name] = e
    }
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }

  onBlurDataFilterReview (e, name, type) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    if(name === 'IsStatus') {
      if(e === -1) {
        delete paging['searcharr_IsStatus']
      } else {
        paging['searcharr_IsStatus'] = e //.split(',').map(o => Number(o))
      }
    } else {
      paging['searchid_' + name] = e.Cat_Name
    }
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }

  onBlurDatafilterOld (e, name, type) {
    // clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    let today = new Date()
    let priorDate = new Date().setFullYear(today.getFullYear()-Number(e.target.value))
    // console.log(priorDate, 'priorDate')
    if(name === 'TuTuoi') {
      if(e.target.value === '' || !e.target.value || e.target.value < 1) {
        delete paging['searchNgaySinh_min']
      } else {
        paging['searchNgaySinh_min'] = priorDate / 1000
      }
    } if(name === 'DenTuoi') {
      if(e.target.value === '' || !e.target.value || e.target.value < 1) {
        delete paging['searchNgaySinh_max']
      } else {
        paging['searchNgaySinh_max'] = priorDate  / 1000
      }
    }

    this.setState({ paging: paging })
  }

  clearFilter() {
    let paging = this.state.paging
    let arr = ['LoaiCoquan', 'Status', 'TenCoQuanBaoChi', 'TenChucVu', 'StatusPhanLoai', 'MaTheCu', 'HoTenKhaiSinh']
    for(let i in arr) {
      if(paging['search_' + arr[i]]) { delete paging['search_' + arr[i]] }
    }

    if(paging['IsStatus']) { delete paging['IsStatus'] }
    if(paging['TuTuoi']) { delete paging['TuTuoi'] }
    if(paging['DenTuoi']) { delete paging['DenTuoi'] }

    // let paging = { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    this.setState({paging: paging}, () => this.fetchData())
  }

  // C H A N G E  E V E N T
  onChangeContentCKE (evt) {
    let editingData = this.state.editingData
    let newContent = evt.editor.getData()
    editingData.content = newContent
    this.setState({ editingData: editingData })
  }

  // P A G I N  E V E N T
  onClickPaging (page) {
    let paging = this.state.paging
    let count = rxu.get(this.state.extra, 'count', paging.pg_size)
    let maxpage = Math.ceil(count / paging.pg_size)

    if (page < 1) { page = 1 }
    if (page > maxpage) { page = maxpage }

    paging.pg_page = page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  checkShow(name) {
    let optionChecked = rxu.get(this.state,['dataCard', 0], []) // this.state.optionChecked
    let show = Object.keys(optionChecked).indexOf(name) !== -1 ? true : false
    return show
  }

  onChangePageSize (evt) {
    clearTimeout(this.timerPageSize)
    let pagesize = parseInt(evt.target.value, 10)
    let paging = this.state.paging
    paging.pg_size = pagesize
    paging.pg_page = 1
    if (pagesize >= 1 && pagesize <= 1000) {
      this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
    }
  }

  onclickFirst(e) {
    let paging = this.state.paging
    paging.pg_page = 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onclickLast(e) {
    let paging = this.state.paging
    let countAll = global.rxu.get(this.state.extra, 'count', paging.pg_size) / this.state.paging.pg_size
    if (countAll > parseInt(countAll,10)) {
      paging.pg_page = parseInt(countAll,10) + 1
    } else {
      paging.pg_page = countAll
    }
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginBack (e) {
    let paging = this.state.paging
    paging.pg_page = (paging.pg_page > 1) ? (paging.pg_page - 1) : paging.pg_page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginNext (e) {
    let paging = this.state.paging
    paging.pg_page += 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginCur(e) {
    let paging = this.state.paging
    paging.pg_page = e
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  // U P L O A D  E V E N T
  callbackUpload (e, name) {
    this.onBlurData({ target: { value: e.images } }, name)
  }

  callbackUploadDetail (e) {
    this.onBlurData({ target: { value: e.images } }, 'img_detail')
  }

  // H E L P E R S
  helpProductcat (cats) {
    let result = ''
    if (cats) {
      for (let i = 0; i < cats.length; i++) { result += cats[i].name + ', ' }
      return result
    }
  }

  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  async checkObject(e) {
    var checkedValue = null;
    var listId = []
    var inputElements = document.getElementsByClassName('css-checkboxShow');
    for(var i=0; inputElements[i]; ++i){
      if(inputElements[i].checked){
          checkedValue = inputElements[i].value;
          await listId.push(checkedValue)
      }
    }
    this.setState({idDataEdit: listId})
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

RxCrudSta.defaultProps = { name: '', api: '', form: [], dataCallback: () => {}, renderPage: () => {}, data: {} }

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth
})

const mapDispatchToProps = {}

const RxCrudSta_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(RxCrudSta)
export default RxCrudSta_
