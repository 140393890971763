import React, { Component } from 'react'
import { connect } from 'react-redux'

// const { rxgetLocal } = global.rootRequire('classes/request')
const { rxnavToggle, rxnavClose, rxnavChange, logoutAction } = global.rootRequire('redux')
const { apiget, apipost } = global.rootRequire('classes/request')
// const rxu = global.rxu

class changePasswordComponent extends Component {

    constructor (props, context) {
        super(props, context)
        this.state = {
            data: {},
            editingData: {},
            status: '',
        }
        this.fetchData()
    }

    componentDidMount () {

    }


    fetchData () {
        if(this.props.auth.user.user){
            apiget(global.rxu.config.api_user_find, {_id: this.props.auth.user.user._id}, { '1': (json) => { this.setState({ data: json.data, editingData: { _id: json.data._id} }, () => {}) } })
        }
    }

    onUpdateData() {
        let editingData = this.state.editingData;

        if(this.state.editingData.passwordNew === this.state.editingData.repassword){
            apipost(global.rxu.config.api_user_changepassword, editingData, {
                '1': (json) => {
                    this.fetchData(true)
                    this.setState({status: json.success}, (e) => this.props.history.push('/admin/card'))
                },
                'default': (json) => {
                  this.setState({status: json.msg})
                }
            })
        }else {
        }
    }

    run(name, params) {
        if (params) {
            params.inthis = this
        } else {
            params = this
        }
        if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
            return this.props[name](params)
        } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
            return this[name]()
        }
    }

    // B L U R   E V E N T
    onBlurData(e, name) {
        let editingData = this.state.editingData
        editingData[name] = e.target.value
        this.setState({
            editingData: editingData
        })
    }

    onClickCancel() {
      let clear = {};
      if(this.state.editingData._id){
        clear._id = this.state.editingData._id
      }

      this.setState({
        editingData: clear
      })
    }

    render () {
        return(
            <div className="adblock">
                {this.state.status &&
                  <div className='toast-container toast-top-right'>
                    <div className='toast'>
                      <div className='toast-content'>
                        <div className='toast-title'>{this.state.status}</div>
                        <div className='toast-message'>
                          <div className=''>{this.state.status}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="adblock__body">
                    <div className="rxcol-100">
                        <div className="betable adblock__inner">
                            <div className="adform__header">
                                <div className="adform__name">Thay đổi mật khẩu</div>
                                {this.state.status &&
                                    <div style={{color: 'red', margin: '5px'}}>{this.state.status === 1 ? "Cập nhật thông tin thành công" : "Cập nhật thông tin không thành công"}</div>
                                }
                            </div>

                            <div className="adform__body">
                                <div>
                                    <div className="fullwidth-label">Nhập mật khẩu cũ</div>
                                    <input type="password" className="fullwidth-input"  value={this.state.editingData.passwordOld ? this.state.editingData.passwordOld : ''} onChange={(e) => {this.onBlurData(e, 'passwordOld')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Nhập mật khẩu mới</div>
                                    <input type="password" className="fullwidth-input" value={this.state.editingData.passwordNew ? this.state.editingData.passwordNew : ''} onChange={(e) => {this.onBlurData(e, 'passwordNew')}}/>
                                </div>
                                <div>
                                    <div className="fullwidth-label">Nhập lại mật khẩu</div>
                                    <input type="password" className="fullwidth-input" value={this.state.editingData.repassword ? this.state.editingData.repassword : ''} onChange={(e) => {this.onBlurData(e, 'repassword')}} />
                                </div>
                            </div>

                            <div className="adform__footer">
                                <div className="adform__btns clearfix">
                                    <div className="adform__btncancel" onClick={() => {this.onClickCancel()}}>Hủy thay đổi</div>
                                    <div className="adform__close">
                                        <span className="adform__btn--save" onClick={() => {this.onUpdateData()}} >Lưu</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav,
  auth: state.auth
})

const mapDispatchToProps = {
  rxnavToggle,
  rxnavClose,
  rxnavChange,
  logoutAction
}

const changePasswordComponent_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(changePasswordComponent)

export default changePasswordComponent_
