import * as Jsx from './rxCrudLogAction.jsx'
import * as xlsx from 'xlsx'
// import SaveAs from 'file-saver'

global.isclient && require('./rxCrud.css')
global.isclient && require('jsoneditor/dist/jsoneditor.css')

const { apiget, apipost } = global.rootRequire('classes/request')
const RxComponent = global.rootRequire('components/shares/rxComponent').default

const rxu = global.rxu
const WAIT_INTERVAL = 500
class RxCrud extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this._mounted = false
    this.state = {
      flagUpdate: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      pagingCat: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      editingData: {},
      importData: [],
      extra: {},
      data: {},
      flags: {},
      temp: {},
      cate: [],
      position: [],
      filter: {
        Name: false,
        Code: false,
        CoQuanBaoChi: false,
        LoaiHoSo: false,
        ChucVu: false,
        Tuoi: false,
        LoaiThe: false
      }
    }
    this.onChangeContentCKE = this.onChangeContentCKE.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    this.fetchAlldata()
  }

  componentWillUnmount () {
    this._mounted = false
    super.componentWillUnmount()
    if (this.jsoneditor) { this.jsoneditor.destroy() }
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  componentDidUpdate (prevProps, nextState) {
    this._mounted = true
    if ([false, true].indexOf(prevProps.flagUpdate) !== -1 && prevProps.flagUpdate !== this.state.flagUpdate) {
      this.setStateRx({ flagUpdate: prevProps.flagUpdate })
      this.run('parentUpdate', {})
    }
  }

  // D A T A
  fetchAlldata () { this.fetchData() } //; this.fetchCate(); this.fetchPosition()
  fetchData (runCallback = false) {
    apiget(rxu.get(rxu.config, this.props.api), this.state.paging, {
      '1': (json) => {
        this.setStateRx({ data: json.data, extra: json.extra })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  fetchCate (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), this.state.pagingCat, {
      '1': (json) => {
        this.setStateRx({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchPosition (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_position'), this.state.pagingCat, {
      '1': (json) => {
        this.setStateRx({ position: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    let flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setStateRx({ flags: flags })
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  // C L I C K   E V E N T
  onClickData (e, perdata) {}
  onClickSort (e, stcol) {
    let paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setStateRx({ paging: paging }, () => { this.fetchData() })
  }

  onClickExcelUpload(flagname) {
    let flags = this.state.flags
    if(!flags[flagname]) { flags[flagname] = false }
    flags[flagname] = true
    this.setStateRx({flagname: flagname})
  }

  onClickDataImport(e) {
    let scope = this
    let reader = new FileReader()
    let fileDom = e.target || e.srcElement

    if(fileDom.value !==  '') {
      let file = fileDom.files[0]
      if(typeof file !== 'undefined') {
        reader.onload = function(e) {
          let datatmp = new Uint8Array(e.target.result);
          let workbook = xlsx.read(datatmp, {type: 'array'});
          workbook.SheetNames.forEach((sheetName) => {
            var data = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName])
            if(data && data[0]['But danh'] && data[0]['Ma the'] && data[0]['Ten co quan'] && data[0]['Ngay het han'] && data[0]['Ngay cap'] && data[0]['Nguoi ky']
              && data[0]['Nam sinh'] && data[0]['Chuc vu'] && data[0]['Chu ky'] ) {
              scope.setStateRx({importData: data})
            } else {
              alert('File sai định dạng')
            }
          })
        }
        reader.readAsArrayBuffer(file)
      }
    }
    else {}
  }
  onClickSubmitExcelUpload() {
    apipost(rxu.get(rxu.config, this.props.api) + '/importExcel', this.state.importData, {
      '1': (json) => {
        this.setStateRx({importData: [] }, () => this.fetchData(true))
        // if(json.data && json.data.length > 0){
        //   console.log('có '+json.data.length+' sản phẩm đã tồn tại')
        // }
      }
    })
  }

  onClickDataExport (e) {
    apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
      '1': (json) => { global.JSONToCSVConvertor(json.data, '') }
    })
  }

  onClickDataDeleteAll (e) {
    let r = window.confirm('Bạn muốn xoá tất cả dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, 'api_card_delete_all'), {}, {
        '1': (json) => {   this.fetchData(true) }
      })
    } else {}

  }

  onClickDataNew (e) {
    global.scrolltop()
    let timeStr = Date.now().toString()
    let clone = { name: this.props.name + '_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0 }
    this.setStateRx({ editingData: clone })
  }

  onClickDataEdit (e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    this.setStateRx({ editingData: clone, flags: {} }, () => {
      console.log(this.state.editingData, 'dfhi')
      if(this.state.editingData && this.state.editingData.data && this.state.editingData.data._id) {
        // let name = rxu.get(this.state, ['editingData', 'name'])
        // let api_name = name ? ('api_' + name.split('-')[1].replace(' ', '')) : ''
        // console.log(api_name, 'api_name')
        apiget(rxu.get(rxu.config, 'api_logs_detail'), {_id: this.state.editingData.data._id, pg_size: 1, searchlt_created_at: this.state.editingData.created_at}, {
          '1': (json) => {
            let editingData = this.state.editingData
            if(json.data && json.data.length === 0) {
              editingData['data_old'] = {}
              this.setStateRx({editingData: editingData})
            }
            // let editingData = this.state.editingData
            if(json.data && json.data.length > 0) {
              editingData['data_old'] = (json.data && json.data[0]) ? json.data[0].data : {}
              this.setStateRx({ editingData: editingData })
            }
            // console.log(this.state.editingData, 'editingData')
          }
        })
      }
    })
  }

  onClickDataDelete (e, perdata) {
    let r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, this.props.api) + '/delete', {_id: perdata._id}, {
        '1': (json) => { this.fetchData(true) }
      })
    } else {}
  }

  onClickDataRestore (e, perdata) {
    apiget(rxu.get(rxu.config, this.props.api) + '/restore', {_id: perdata._id}, {
      '1': (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit (e, perdata) {
    global.scrolltop()
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', this.state.editingData, {
      '1': (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataUpdateSubmitRaw (e, perdata) {
    global.scrolltop()
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
      '1': (json) => { this.fetchData(true) },
      'default': () => {}
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit (e, perdata) {
    global.scrolltop()
    this.setStateRx({loading: true}, () => {
      let editingData = {...this.state.editingData, hideProgress: true}
      apipost(rxu.get(rxu.config, this.props.api), editingData, {
        '1': (json) => { this.setStateRx({loading: false}, () => this.fetchData(true)) },
        'default': (json) => { this.setStateRx({msg: json});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {}}) }, 3000)},
      })
      this.onClickDataEdit({}, {})
    })
  }

  onClickDataTrash (e, isdeleted) {
    let paging = this.state.paging
    paging['search_is_deleted'] = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickGenPermission () {
    apiget(rxu.config.api_permission_genpermission, {}, { '1': (json) => {
      this.fetchData()
    } })
  }

  // B L U R   E V E N T
  onBlurData (e, name, options) {
    options = options || {}
    let editingData = this.state.editingData
    if (name === 'role_id') {
      if (this.props.roles && e.target.value) {
        let ob = this.props.roles[e.target.value]
        editingData.role_id = ob._id
        editingData.role    = ob.name
      }
    } else {
      if (options.strim) {
        editingData[name] = e.target.value.toString().replace(/(,)/g, '')
      } else {
        editingData[name] = e.target.value
      }
    }

    this.setState({ editingData: editingData })
  }

  onBlurDataValue (value, name) {
    let editingData = this.state.editingData

    if(name === 'TenChucVu' && value) {
      editingData['TenChucVu'] = value.Ten
      editingData['ChucVuID'] = value.ChucVuID
    } else if (name === 'TenCoQuanBaoChi') {
      editingData['TenCoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiID'] = value.Cat_ID
    } else {
      editingData[name] = value
    }
    this.setState({ editingData: editingData })
  }

  onBlurDatafilter (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      paging['search_' + name] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else if(name === 'search_cate') {
      let paging = this.state.pagingCat
      paging['search_Cat_Name'] = e
      this.setState({ pagingCat: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchCate()
      }, WAIT_INTERVAL)
    } else if(name === 'Status') {
      let paging = this.state.paging
      paging['search_Status'] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else {
      let paging = this.state.paging
      if (name === 'username') {
        paging['searchid_username'] = e.target.value
      } else {
        paging['search_' + name] = e.target.value
      }

      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
  }

  onChangeFilter(e){
    if(e){
      if(e.length === 0){
        this.setState({filter: {
          Name: false,
          Code: false,
          CoQuanBaoChi: false,
          LoaiHoSo: false,
          ChucVu: false,
          Tuoi: false,
          LoaiThe: false
        }})
      }
      else{
        let filterTmp = this.state.filter;
        e.map( evt => filterTmp[evt.value] = true )
        this.setState({filter: filterTmp})
      }
    }else{
      this.setState({filter: {
        Name: false,
        Code: false,
        CoQuanBaoChi: false,
        LoaiHoSo: false,
        ChucVu: false,
        Tuoi: false,
        LoaiThe: false
      }})
    }
  }

  // C H A N G E  E V E N T
  onChangeContentCKE (evt) {
    let editingData = this.state.editingData
    let newContent = evt.editor.getData()
    editingData.content = newContent
    this.setState({ editingData: editingData })
  }

  // P A G I N  E V E N T
  onClickPaging (page) {
    let paging = this.state.paging
    let count = rxu.get(this.state.extra, 'count', paging.pg_size)
    let maxpage = Math.ceil(count / paging.pg_size)

    if (page < 1) { page = 1 }
    if (page > maxpage) { page = maxpage }

    paging.pg_page = page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onChangePageSize (evt) {
    clearTimeout(this.timerPageSize)
    let pagesize = parseInt(evt.target.value, 10)
    let paging = this.state.paging
    paging.pg_size = pagesize
    paging.pg_page = 1
    if (pagesize >= 1 && pagesize <= 1000) {
      this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
    }
  }

  onclickFirst(e) {
    let paging = this.state.paging
    paging.pg_page = 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onclickLast(e) {
    let paging = this.state.paging
    let countAll = global.rxu.get(this.state.extra, 'count', paging.pg_size) / this.state.paging.pg_size
    if (countAll > parseInt(countAll,10)) {
      paging.pg_page = parseInt(countAll,10) + 1
    } else {
      paging.pg_page = countAll
    }
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginBack (e) {
    let paging = this.state.paging
    paging.pg_page = (paging.pg_page > 1) ? (paging.pg_page - 1) : paging.pg_page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginNext (e) {
    let paging = this.state.paging
    paging.pg_page += 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginCur(e) {
    let paging = this.state.paging
    console.log('ajsbfuydsg', e)
    paging.pg_page = e
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  // U P L O A D  E V E N T
  callbackUpload (e, name) {
    this.onBlurData({ target: { value: e.images } }, name)
  }

  callbackUploadDetail (e) {
    this.onBlurData({ target: { value: e.images } }, 'img_detail')
  }

  // H E L P E R S
  helpProductcat (cats) {
    let result = ''
    if (cats) {
      for (let i = 0; i < cats.length; i++) { result += cats[i].name + ', ' }
      return result
    }
  }

  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

RxCrud.defaultProps = { name: '', api: '', form: [], dataCallback: () => {}, renderPage: () => {}, data: {} }
export default RxCrud
