import React, { Component } from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
// const RxToggle = global.rootRequire('components/shares/rxToggle').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default

class configComponent extends Component {

  componentDidMount () {
  }

  // R E N D E R S
  renderTableHead (inthis) {
    return (<tr>
      <th className={inthis.helpSortClass('STT', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'STT')}>STT</th>
      <th className={inthis.helpSortClass('Ten', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'Ten')}>Ten</th>
      <th className={inthis.helpSortClass('type', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'type')}>Loại</th>
      <th className={inthis.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td>{perdata.STT}</td>
      <td>{perdata.Ten}</td>
      <td>{global.rxu.rxparseTen('configType', perdata.type)}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      {inthis.renderTableBodyBtns(inthis, perdata)}
    </tr>))
  }

  render () {
    return (
      <AdminBlock name='CONFIG' desc=''>
        <div className='rxcol-100'>
          <RxCrud renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} name={'config'} api={'api_config'} form={[
            { type: 'devide' },
            { name: 'STT', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.STT || ''} onChange={(e) => context.onBlurData(e, 'STT')} className='fullwidth-input' />) },
            // { name: 'ID chức vụ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.ChucVuID} onChange={(e) => context.onBlurData(e, 'ChucVuID')} className='fullwidth-input' />) },
            { name: 'Loại', type: 'flex', func: (context) => (<RxSelectbox
                name='configType'
                options={global.configType }
                results={context.state.editingData.type}
                onChange={(result) => {
                    context.onBlurDataValue(result, 'type')
                    // inthis.onValidateField(result,'GioiTinh')
             }}
            />) },
            { name: 'Tên', func: (context) => (<input tabIndex='2' type='text' value={context.state.editingData.Ten || ''} onChange={(e) => context.onBlurData(e, 'Ten')} className='fullwidth-input' />) },
            
            // { type: 'devide' },
            // { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
          ]} />
        </div>
      </AdminBlock>
    )
  }
}

export default configComponent
