import * as Jsx from './printlist.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class productComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
      idDataEdit: [],
    }
  }

  async checkAll(e) {
    const allCheckBox = document.getElementById('HoSoID-All').checked
    var listId = []
    if(allCheckBox === true){
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = true
                await listId.push(check[i].value)
            }
        }
        this.setState({idDataEdit: listId})
    }else{
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = false
            }
        }
        this.setState({idDataEdit: [] })
    }
  }


  async checkObject(e) {
    var checkedValue = null;
    var listId = []
    var inputElements = document.getElementsByClassName('css-checkboxShow');
    for(var i=0; inputElements[i]; ++i){
      if(inputElements[i].checked){
          checkedValue = inputElements[i].value;
          listId.push(checkedValue)
      }
    }

    this.setState({idDataEdit: listId})
  }

  render () { return this.renderComponent() }
}

export default productComponent
