/* global rxu */
import React, { Component } from 'react'
// import DateRangePicker from 'react-bootstrap-daterangepicker'
// import 'bootstrap-daterangepicker/daterangepicker.css'
// import moment from 'moment'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const { apiget, rxgetLocal, rxJson } = global.rootRequire('classes/request')
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const Chart = require('chart.js')

let colorArray = ['rgba(165, 105, 189 )', 'rgba(84, 153, 199)', 'rgba(72, 201, 176)', 'rgba(82, 190, 128)', 'rgba(244, 208, 63)', 'rgba(170, 183, 184)', 'rgba(236, 112, 99)', 'rgba(242, 97, 118)', 'rgba(255, 159, 64)', 'rgba(17, 196, 209)']
const WAIT_INTERVAL = 500

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);


class DashboardComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {chart1: [], chart2: [], chart3: [], chart4: [], cate: []},
      paging: { st_col: 'created_at', st_type: -1, type: 'week', startDate: firstDay , endDate: new Date() },
      pagingCat: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      analytics: [],
      dataHead: {},
      result: [],
      oldTime: [],
      genderTime:[],
      dataPrint: [],
      gender: [],
      old: [],
      top: [],
      loaiCoQuan: '',
      filterLoaiCoQuan: ''
    }
    this.onchangeDate = this.onchangeDate.bind(this)
  }
  componentDidMount () {
    this.fetchData()
  }
  fetchAllData() {
    this.fetchData()
    this.fetchDataCard()
    this.fetchAnalytics()
    
  }
  fetchData () {
    let startDate = global.rxu.get(this.state, ['paging', 'startDate'], new Date()) / 1000
    let endDate = global.rxu.get(this.state, ['paging', 'endDate'], new Date()) / 1000
    apiget(global.rxu.get(global.rxu.config, 'api_dashboard'), {...this.state.paging, searchmin_created_at: startDate, searchmax_created_at: endDate}, {
      '1': (json) => {
        if(json.data) {
          this.setState({
            dataHead: json.data.HeadData,
            dataTop: json.data.TopData,
            dataPrint: json.data.PrintData,
            old: json.data.OldData,
            gender: json.data.GenderData,
          }, 
          // () => this.initChar()
          )
        }
      }
    })
  }
  fetchInfoReview () {
    apiget(global.rxu.get(global.rxu.config, 'api_dashboard_info', ), {loaiCoQuan: this.state.loaiCoQuan}, {
      '1': (json) => {
        if(json.data) {
          this.setState({
            filterLoaiCoQuan: json.data,
          })
        }
      }
    })
  }
  fetchDataCard () {
    global.scrolltop()
    let paging = {...this.state.paging}
    // let name   = this.props.name
    let type = this.props.type
    if (type && type !== undefined && type.indexOf('?type=') !== -1)  {
      type = type.replace('?type=','')
      paging.search_StatusPhanLoai = type
    } else { delete paging.search_StatusPhanLoai }
    let data = this.state.data
    apiget(global.rxu.get(global.rxu.config, 'api_dashboard_review'), paging, {
      '1': (json) => {
        data.chart1 = json.data.data[0]
        data.chart2 = json.data.data[1]
        data.chart3 = json.data.data[2]
        data.chart4 = json.data.data[3]
        this.setState({ data: data }, () => { this.initChar(data)})
      }
    })
  }
  fetchCate() {
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    let pagingCat = this.state.pagingCat
    pagingCat['search_Type'] = global.rxu.get(user, 'user.user.Type', 1)
    apiget(global.rxu.get(global.rxu.config, 'api_newspaper_office'), pagingCat, {
      '1': (json) => {
        this.setState({ cate: json.data })
      }
    })
  }
  fetchAnalytics() {
    let paging = this.state.paging
    apiget(global.rxu.get(global.rxu.config, 'api_dashboard_analytics'), paging, {
      '1': (json) => {
        this.setState({ analytics: json.data })
      }
    })
  }
  initChar (data) {
    this.initChart4__(this.state.dataPrint)
    this.initChart2__(this.state.dataTop)
    this.initChart1(this.state.old)
    this.initChart2(this.state.gender)
  }
  initCharBlock (item, index, color) {
    return {
      lineTension: 0,
      data: item,
      label: index,
      backgroundColor: global.rxu.strRgbaAddAlpha(color, 0.5),
      borderColor: color,
      pointBackgroundColor: 'white',
      pointBorderWidth: 2,
      pointStyle: 'circle',
      borderWidth: 0
    }
  }
  initCharOption () {
    return {
      title: { display: false },
      legend: { display: true, labels: { pointBorderWidth: 0, boxWidth: 10, marginBottom: 20, fontSize: 10, usePointStyle: true } }
    }
  }

  initChart2__ (data) {
    let labelArray = global.rxu.get(data, 'label', [])
    let chart1Map = global.rxu.get(data, 'data', {})
    // Makeup dataset
    let colorArrayIndex = 0; let chart1Dataset = []
    Object.keys(chart1Map).forEach((index) => {
      let item = chart1Map[index]
      let currentColor = colorArray[colorArrayIndex]
      chart1Dataset.push({
        data: item,
        label: index,
        backgroundColor: global.rxu.strRgbaAddAlpha(currentColor, 0.5),
        // fill: false,
        // borderColor: currentColor,
        // pointRadius: 0,
        // pointBorderWidth: 0,
        pointStyle: 'circle',
        borderWidth: 0
      })
      colorArrayIndex = colorArrayIndex < colorArray.length ? colorArrayIndex + 1 : 0
    })
    if (typeof (this.CharLineTacNhan) !== 'undefined') { this.CharLineTacNhan.destroy() }
    let ctx = document.getElementById('Char_lineTacNhan').getContext('2d')
    let configCharLineTacNhan = { type: 'bar', options: this.initCharOption, data: { labels: labelArray, datasets: chart1Dataset } }
    this.CharLineTacNhan = new global.Chart(ctx, configCharLineTacNhan)
  }

  initChart4__ (data) {
    let dataChart = [data.dain, data.chuain]
    let percent = (data['dain'] * 100 / (data['chuain'] + data['dain'])).toFixed(0)
    if (typeof (this.Char_pieUngDung) !== 'undefined') { this.CharPieUngDung.destroy() }
    let ctx = document.getElementById('Char_pieUngDung').getContext('2d')
    let configCharPieUngDung = {
      type: 'doughnut',
      data: {
        labels: ['Thẻ đã in', 'Thẻ chưa in'],
        datasets: [{
          label: 'POSTS',
          backgroundColor: ['#f1556c', '#edeff1'],
          data: dataChart
        }]
      },
      options: {
        title: { display: false },
        elements: {
          center: {
            text: 'Thẻ đã in \n' + percent + '%',
            color: '#31373e',
            sidePadding: 20,
            minFontSize: 16,
            lineHeight: 25
          }
        },
        responsive: true,
        cutoutPercentage: 80,
      }
    }
    Chart.pluginService.register({
      beforeDraw: function(chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          var ctx = chart.chart.ctx;
          // Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var maxFontSize = centerConfig.maxFontSize || 75;
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;
          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = (chart.innerRadius * 2);
          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          var minFontSize = centerConfig.minFontSize;
          var lineHeight = centerConfig.lineHeight || 25;
          var wrapText = false;
          if (minFontSize === undefined) {
            minFontSize = 20;
          }
          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }
          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;
          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }
          var words = txt.split(' ');
          var line = '';
          var lines = [];
          // Break words up into multiple lines if necessary
          for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }
          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;
          for (var m = 0; m < lines.length; m++) {
            ctx.fillText(lines[m], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          ctx.fillText(line, centerX, centerY);
        }
      }
    });
    this.CharPieUngDung = new global.Chart(ctx, configCharPieUngDung)
  }

  initChart1 (data) {
    if (typeof (this.CharBarNenTang_1) !== 'undefined') { this.CharBarNenTang_1.destroy() }
    let ctx = document.getElementById('Char_barNenTang_1').getContext('2d')
    let configCharBarNenTang = {
      type: 'bar',
      options: {
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
        legend: { display: false },
        title: { display: false } ,
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true,
              }
          }]
        }
      },
      data: {
        labels: [' <30 ', '30-4', '40-50', '>50'],
        datasets: [{
          label: 'Số lượng',
          backgroundColor: ['#00acc1', '#4b88e4', '#fd7e14', '#e3eaef'],
          data: data
        }]
      },
    }
      this.CharBarNenTang_1 = new global.Chart(ctx, configCharBarNenTang)
  }

  initChart2 (data) {
    let gender = [data[0], data[1]]
    if (typeof (this.CharPieNenTang_2) !== 'undefined') { this.CharPieNenTang_2.destroy() }
    let ctx = document.getElementById('Char_pieNenTang_2').getContext('2d')
    Chart.defaults.doughnutLabels = Chart.helpers.clone(Chart.defaults.doughnut);
      var helpers = Chart.helpers;
      Chart.controllers.doughnutLabels = Chart.controllers.doughnut.extend({
        updateElement: function(arc, index, reset) {
          var _this = this;
          var chart = _this.chart,
              chartArea = chart.chartArea,
              opts = chart.options,
              animationOpts = opts.animation,
              arcOpts = opts.elements.arc,
              centerX = (chartArea.left + chartArea.right) / 2,
              centerY = (chartArea.top + chartArea.bottom) / 2,
              startAngle = opts.rotation, // non reset case handled later
              endAngle = opts.rotation, // non reset case handled later
              dataset = _this.getDataset(),
              circumference = reset && animationOpts.animateRotate ? 0 : arc.hidden ? 0 : _this.calculateCircumference(dataset.data[index]) * (opts.circumference / (2.0 * Math.PI)),
              innerRadius = reset && animationOpts.animateScale ? 0 : _this.innerRadius,
              outerRadius = reset && animationOpts.animateScale ? 0 : _this.outerRadius,
              custom = arc.custom || {},
              valueAtIndexOrDefault = helpers.getValueAtIndexOrDefault;

          helpers.extend(arc, {
            // Utility
            _datasetIndex: _this.index,
            _index: index,

            // Desired view properties
            _model: {
              x: centerX + chart.offsetX,
              y: centerY + chart.offsetY,
              startAngle: startAngle,
              endAngle: endAngle,
              circumference: circumference,
              outerRadius: outerRadius,
              innerRadius: innerRadius,
              label: valueAtIndexOrDefault(dataset.label, index, chart.data.labels[index])
            },

            draw: function () {
              var ctx = this._chart.ctx,
                  vm = this._view,
                  sA = vm.startAngle,
                  eA = vm.endAngle,
                  opts = this._chart.config.options;

                var labelPos = this.tooltipPosition();
                var segmentLabel = vm.circumference / opts.circumference * 100;

                ctx.beginPath();

                ctx.arc(vm.x, vm.y, vm.outerRadius, sA, eA);
                ctx.arc(vm.x, vm.y, vm.innerRadius, eA, sA, true);

                ctx.closePath();
                ctx.strokeStyle = vm.borderColor;
                ctx.lineWidth = vm.borderWidth;

                ctx.fillStyle = vm.backgroundColor;

                ctx.fill();
                ctx.lineJoin = 'bevel';

                if (vm.borderWidth) {
                  ctx.stroke();
                }

                if (vm.circumference > 0.15) { // Trying to hide label when it doesn't fit in segment
                  ctx.beginPath();
                  ctx.font = helpers.fontString(opts.defaultFontSize, opts.defaultFontStyle, opts.defaultFontFamily);
                  ctx.fillStyle = "#fff";
                  ctx.textBaseline = "top";
                  ctx.textAlign = "center";

                  // Round percentage in a way that it always adds up to 100%
                  ctx.fillText(segmentLabel.toFixed(0) + "%", labelPos.x, labelPos.y);
                }
            }
          });

          var model = arc._model;
          model.backgroundColor = custom.backgroundColor ? custom.backgroundColor : valueAtIndexOrDefault(dataset.backgroundColor, index, arcOpts.backgroundColor);
          model.hoverBackgroundColor = custom.hoverBackgroundColor ? custom.hoverBackgroundColor : valueAtIndexOrDefault(dataset.hoverBackgroundColor, index, arcOpts.hoverBackgroundColor);
          model.borderWidth = custom.borderWidth ? custom.borderWidth : valueAtIndexOrDefault(dataset.borderWidth, index, arcOpts.borderWidth);
          model.borderColor = custom.borderColor ? custom.borderColor : valueAtIndexOrDefault(dataset.borderColor, index, arcOpts.borderColor);

          // Set correct angles if not resetting
          if (!reset || !animationOpts.animateRotate) {
            if (index === 0) {
              model.startAngle = opts.rotation;
            } else {
              model.startAngle = _this.getMeta().data[index - 1]._model.endAngle;
            }

            model.endAngle = model.startAngle + model.circumference;
          }

          arc.pivot();
        }
      });

    let configCharPieUngDung = {
      //type: 'pie',
       type: 'doughnutLabels',
      // options: { title: { display: false } },
        data: {
          labels: ['Nam', 'Nữ'],
          datasets: [{
            label: 'Số lượng',
            backgroundColor: ['#1E90FF', '#f1556c'],
            data: gender,
            weight: 1,
          }]
        },
        options: {
          responsive: true,
          legend: {
            position: 'top',
          },
          title: {
            display: true,
          },
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }

      }
      this.CharPieNenTang_2 = new global.Chart(ctx, configCharPieUngDung)
    }

  async clearFilter() {
    let paging = { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 }
    this.setState({pagingCat: paging})
    await this.fetchAllData()
  }

  onBlurDatafilter (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      if(e === -1) {
        switch (name) {
          case 'TenCoQuanBaoChi':
             delete paging['searche_' + name]
            break;
          case 'TenChucVu':
             delete paging['searche_' + name]
            break;
          default:
             delete paging['search_' + name]
        }
      } else {
        switch (name) {
          case 'TenCoQuanBaoChi':
            paging['searche_' + name] = e.Cat_Name
            break;
          case 'TenChucVu':
            paging['searche_' + name] = e.Ten
            break;
          case 'LoaiCoQuan':
            paging['search_' + name] = e.id
          default:
            paging['search_' + name] = e
        }
      }
      this.setState({ paging: paging })
      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchAllData()
      }, WAIT_INTERVAL)
    }  else {
      let paging = this.state.paging
      paging['search_' + name] = e.target.value
      if(e.target.value === '') { delete paging['search_' + name] }
      this.setState({ paging: paging })
      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchAllData()
      }, WAIT_INTERVAL)
    }
  }
  onBlurDatafilterOld (e, name, type) {
    let paging = this.state.paging
    let today = new Date()
    let priorDate = new Date().setFullYear(today.getFullYear()-Number(e.target.value))
    // console.log(priorDate, 'priorDate')
    if(name === 'TuTuoi') {
      if(e.target.value === '' || !e.target.value || e.target.value < 1) {
        delete paging['searchNgaySinh_min']
      } else {
        paging['searchNgaySinh_min'] = priorDate / 1000
      }
    } if(name === 'DenTuoi') {
      if(e.target.value === '' || !e.target.value || e.target.value < 1) {
        delete paging['searchNgaySinh_max']
      } else {
        paging['searchNgaySinh_max'] = priorDate  / 1000
      }
    }
    this.setState({ paging: paging })
  }

  onBlurValue (e, name, pagingName) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    if(e === -1) {
      switch (name) {
        case 'TenCoQuanBaoChi':
           delete paging['searche_' + name]
          break;
        default:
           delete paging['search_' + name]
      }
    } else {
      switch (name) {
        case 'TenCoQuanBaoChi':
          paging['searche_' + name] = e.Cat_Name
          break;
        default:
          paging['search_' + name] = e
      }
    }
    this.setState({ paging: paging })
    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }
  onChangeFilterTop(e, name) {
    let paging = this.state.paging
    paging['type'] = name
    this.setState({ paging: paging }, () => this.fetchData())
  }

  onchangeDate = (event, picker) => {
    let paging = this.state.paging
    if((paging.startDate / 1000) !== (picker.startDate._d / 1000) || (paging.endDate / 1000) !== (picker.endDate._d / 1000)) {
      paging['startDate'] = picker.startDate._d
      paging['endDate'] = picker.endDate._d
      this.setState({paging: paging}, () => { this.fetchData()})
    }
  }
  onBlurValueLoaiCoQuan (e) {
    if(e == -1){
      this.setState({filterLoaiCoQuan: ''})
    }else{
      this.setState({loaiCoQuan: e},() => {
        this.fetchInfoReview()
      })
    }
    
  }
   // R E N D E R S
  renderDaterangeSelect (func, name) {
    return (
      {/*}
      <DateRangePicker ranges={{
        'Hôm nay': [moment(), moment()],
        'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '7 Ngày trước': [moment().subtract(6, 'days'), moment()],
        '30 Ngày trước': [moment().subtract(29, 'days'), moment()],
        'Tháng này': [moment().startOf('month'), moment().endOf('month')],
        'Tháng trước': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }}
          alwaysShowCalendars={true}
          separator={' - '}
          startDate={this.state.paging.startDate}
          endDate={this.state.paging.endDate}
          maxDate={moment()}
          mode={"enabled"}
          locale={{
              format: 'DD/MM/YYYY',
              applyLabel: 'Tìm kiếm',
              fromLabel: 'Từ',
              toLabel: 'Đến',
              customRangeLabel: 'Tuỳ chọn ngày',
              daysOfWeek: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
              monthNames: ['Tháng 1/', 'Tháng 2/', 'Tháng 3/', 'Tháng 4/', 'Tháng 5/', 'Tháng 6/', 'Tháng 7/', 'Tháng 8/', 'Tháng 9/', 'Tháng 10/', 'Tháng 11/', 'Tháng 12/'],
              firstDay: 1,
          }}
          opens={'right'}
          buttonClasses={['btn-danger','btn']}
          onApply= {this.onchangeDate}
          >
          <span className='icon icon-calendar'></span>
          <span className='textbox__input daterange'>Từ <strong>{rxu.getDate(this.state.paging.startDate /1000)}</strong> Đến <strong>{rxu.getDate(this.state.paging.endDate /1000)}</strong></span>
        </DateRangePicker>
        */}
    )
  }
  tongHoSo = () => {
    if(this.state.filterLoaiCoQuan){
      let info = this.state.filterLoaiCoQuan.fillterStatus
      return info[0] + info[1] + info[2] + info[3] + info[4] + info[5]
    }
    return 0
  }
  render () {
    let typeLoaiBC = this.state?.dataHead?.typeNewsPaperOffice
    return (
      <AdminBlock name='TỔNG QUÁT' desc=''>

      {/* <div className='row betable__filter'>
        <div className='findexpand_body clearfix filter__dashboard'>
          <div className='col-md-12 rx-flex'>
            <div className="rx--box__title">BỘ LỌC</div>
            <div className='col-md-12'>
              <div className='col-md-2'>
                <RxSelectbox name='cate' options={this.state.cate} results={this.state.paging['search_TenCoQuanBaoChi'] || ''} onChange={(result) => { this.onBlurDatafilter(result, 'TenCoQuanBaoChi', 1) }} api='api_newspaper_office' optionDefault='--Chọn cơ quan báo chí--' loader='load' />
              </div>
              <div className='col-md-2'>
                <RxSelectbox name='cate' options={this.state.position} results={this.state.paging['search_TenChucVu'] || ''} onChange={(result) => { this.onBlurDatafilter(result, 'TenChucVu', 1) }} api='api_position' optionDefault='--Nhóm chức vụ--' loader='load' />
              </div>
              <div className='rx--btn__clearFilter'><span className="clearFilter"  onClick={() => {this.clearFilter()}}>Xóa bộ lọc</span></div>
            </div>
          </div>
          <div className="rx--box__search">
            <div className="col-md-12 rx-flex" style={{height: '80px', paddingTop: '18px'}}>
              <div className="rx--box__title">TÌM KIẾM</div>
              <div className='col-md-2 custom-search'>
                <input tabIndex='1' type='text' value={this.state.paging['search_TuTuoi']} onChange={(e) => this.onBlurDatafilterOld(e, 'TuTuoi')} className='fullwidth-input' placeholder='Từ tuổi' />
              </div>
              <div className='col-md-2 custom-search'>
                <input tabIndex='2' type='text' value={this.state.paging['search_DenTuoi']} onChange={(e) => this.onBlurDatafilterOld(e, 'DenTuoi')} className='fullwidth-input' placeholder='Đến tuổi' />
              </div>
              <div className='col-md-2 rx--btn__search' onClick={(e) => this.fetchAllData()}><span className='search_now'>TÌM KIẾM NGAY</span></div>
            </div>
          </div>
        </div>
      </div> */}
      <div className='row rx-flex findexpand_body rx-filter__dashboard'>
        <div className="rx--box__title">BỘ LỌC</div>
        <div className="chart__filter" >
          <div  className='chart__filter-left'>
            <RxSelectbox
              name='LoaiCoquan'
              options={global.LoaiCoquan}
              // results={this.state.TenCoQuanBaoChi || ''}
              onChange={(result) => { this.onBlurValueLoaiCoQuan(result) }}
              optionDefault='Chọn loại cơ quan' loader='load'
            />
          </div>
          {/*<div className='chart__filter-right'>{this.renderDaterangeSelect()}</div>*/}
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon4'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{this.tongHoSo()}</div></div>
              <div className='meter__name'>Tổng số thẻ</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon5'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{this.state.filterLoaiCoQuan?this.state.filterLoaiCoQuan?.fillterStatus[5]: 0}</div></div>
              <div className='meter__name'>Tổng số thẻ đã in</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon4'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{this.state.filterLoaiCoQuan? this.state.filterLoaiCoQuan?.fillterStatus[4]: 0}</div></div>
              <div className='meter__name'>Tổng số thẻ chưa in</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon1'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{/*715*/}{rxu.get(this.state,['dataHead','newsPaperOffice'], 0)}</div></div>
              <div className='meter__name'>Số cơ quan báo chí đăng ký</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon2'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{rxu.get(this.state,['dataHead','card'], 0)}</div></div>
              <div className='meter__name'>Số lượng hồ sơ nhận được</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon3'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{rxu.get(this.state,['dataHead','hasCard'], 0)}</div></div>
              <div className='meter__name'>Số nhà báo đã cấp</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon4'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{rxu.get(this.state,['dataHead','nonCard'], 0)}</div></div>
              <div className='meter__name'>Số thẻ nhà báo chưa cấp</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon5'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{rxu.get(this.state,['dataHead','tongHoSoSangBo'], 0)}</div></div>
              <div className='meter__name'>Số hồ sơ đã trình sang bộ</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon4'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{rxu.get(this.state,['dataHead','tongSoCoQuanDuocIn'], 0)}</div></div>
              <div className='meter__name'>Tổng số cơ quan được in</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon4'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{typeLoaiBC? typeLoaiBC[1]: 0}</div></div>
              <div className='meter__name'>Báo chí trung ương</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon5'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{typeLoaiBC? typeLoaiBC[2]: 0}</div></div>
              <div className='meter__name'>Báo chí địa phương</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='meter__body__'>
            <div className='meter__icon icon4'><i className='iconcustom-card nav-icon' /></div>
            <div className='meter__content'>
              <div className='meter__footer'><div>{typeLoaiBC? typeLoaiBC[3]: 0}</div></div>
              <div className='meter__name'>Phát thanh truyền hình</div>
              <i className='icofarm-total nav-icon' />
            </div>
          </div>
        </div>
      </div>
      {/*}
      <div className='row' style={{marginTop: '20px'}}>
        <div className='betable__main' style={{padding: '0 5px'}}>
          <table className='betable__inner betable__report'>
            <thead>
              <tr>
                <td />
                <td className='text-center' colSpan='4'>Hồ sơ chưa duyệt</td>
                <td className='text-center' colSpan='4'>Hồ sơ đã duyệt</td>
                <td className='text-center' colSpan='4'>Hồ sơ bị trả lại</td>
                <td className='text-center' colSpan='4'>Hồ sơ chưa đủ điều kiện</td>
              </tr>
              <tr>
                <td />
                <td className='text-center'>Hồ sơ cấp mới</td>
                <td className='text-center'>Hồ sơ cấp lại</td>
                <td className='text-center'>Hồ sơ cấp đổi</td>
                <td className='text-center'>Hồ sơ cấp lại theo kỳ hạn mới</td>
                <td className='text-center'>Hồ sơ cấp mới</td>
                <td className='text-center'>Hồ sơ cấp lại</td>
                <td className='text-center'>Hồ sơ cấp đổi</td>
                <td className='text-center'>Hồ sơ cấp lại theo kỳ hạn mới</td>
                <td className='text-center'>Hồ sơ cấp mới</td>
                <td className='text-center'>Hồ sơ cấp lại</td>
                <td className='text-center'>Hồ sơ cấp đổi</td>
                <td className='text-center'>Hồ sơ cấp lại theo kỳ hạn mới</td>
                <td className='text-center'>Hồ sơ cấp mới</td>
                <td className='text-center'>Hồ sơ cấp lại</td>
                <td className='text-center'>Hồ sơ cấp đổi</td>
                <td className='text-center'>Hồ sơ cấp lại theo kỳ hạn mới</td>
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td className='text-center'>Total</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status1', 'hscapmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status1', 'hscaplai', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status1', 'hscapdoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status1', 'hscapkyhanmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status2', 'hscapmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status2', 'hscaplai', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status2', 'hscapdoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status2', 'hscapkyhanmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status3', 'hscapmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status3', 'hscaplai', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status3', 'hscapdoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status3', 'hscapkyhanmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status4', 'hscapmoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status4', 'hscaplai', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status4', 'hscapdoi', 'total'])}</td>
                  <td className='text-center'>{global.rxu.get(this.state, ['analytics', 'status4', 'hscapkyhanmoi', 'total'])}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      */}
      {/* <div className='row rx-flex findexpand_body rx-filter__dashboard'>
        <div className="rx--box__title">BỘ LỌC</div>
        <div className="chart__filter" > */}
          {/* <div  className='chart__filter-left'>
            <RxSelectbox
              name='cate'
              options={this.state.cate}
              results={this.state.paging['search_TenCoQuanBaoChi'] || ''}
              onChange={(result) => { this.onBlurValue(result, 'TenCoQuanBaoChi', 'paging') }}
              api='api_newspaper_office'
              optionDefault='Chọn cơ quan báo chí' loader='load'
            />
          </div> */}
          {/* <div  className='chart__filter-left'>
            <RxSelectbox
              name='LoaiCoquan'
              options={global.LoaiCoquan}
              // results={this.state.TenCoQuanBaoChi || ''}
              onChange={(result) => { this.onBlurValueLoaiCoQuan(result) }}
              optionDefault='Chọn loại cơ quan' loader='load'
            />
          </div>
          <div>
            <div className="meter__name">Tổng số thẻ: {this.tongHoSo()}</div>
            <div className="meter__name">Tổng số thẻ đã in: {this.state.filterLoaiCoQuan?this.state.filterLoaiCoQuan?.fillterStatus[5]: 0}</div>
            <div className="meter__name">Tổng số thẻ chưa in: {this.state.filterLoaiCoQuan? this.state.filterLoaiCoQuan?.fillterStatus[4]: 0}</div>
          </div> */}
          {/*<div className='chart__filter-right'>{this.renderDaterangeSelect()}</div>*/}
        {/* </div>
      </div> */}
      {/* <div className='row'>
        <div className='col-md-4'>
          <div className='chart__item'>
            <div className='row'>
              <div style={{float: 'left'}}>
                <div className='chart__title'> Thẻ đã in </div>
              </div>
            </div>
            <div className='chart__content'>
              <canvas id='Char_pieUngDung' width='800' height='450' />
              <div className='chart__total'>Tổng số thẻ đã in</div>
              <div className='chart__total-number'>{global.rxu.get(this.state, ['dataPrint', 'dain'], 0)}</div>
            </div>
          </div>
        </div>

        <div className='col-md-8'>
          <div className='chart__item'>
            <div className = 'row'>
              <div className="" style={{float: 'left'}}>
                <div className='chart__title'> Thống kê top 10 cơ quan nhà báo yêu cầu nhiều nhất </div>
              </div>
              <div className="chart__filter" >
                <div className={'chart__filter-item ' + (this.state.paging['2'] === 'week' ? 'active' : '')} onClick={(e) => this.onChangeFilterTop(e, 'week')}>Tuần này</div>
                <div className={'chart__filter-item ' + (this.state.paging['type'] === 'thismonth' ? 'active' : '')} onClick={(e) => this.onChangeFilterTop(e, 'thismonth')}>Tháng này</div>
                <div className={'chart__filter-item ' + (this.state.paging['type'] === 'lastmonth' ? 'active' : '')} onClick={(e) => this.onChangeFilterTop(e, 'lastmonth')}>Tháng trước</div>
              </div>
            </div>
            <div className='chart__content'>
              <canvas id='Char_lineTacNhan' width='800' height='450' />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <div className='chart__item'>
            <div className='row'>
              <div className="" style={{float: 'left'}}>
                <div className='chart__title'> Thống kê độ tuổi </div>
              </div>
            </div>
          <div className='chart__content'><canvas id='Char_barNenTang_1' width='800' height='450' /></div>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='chart__item'>
          <div className = 'row'>
            <div className="" style={{float: 'left'}}>
              <div className='chart__title'> Giới Tính </div>
            </div>
            </div>
            <div className='chart__content'><canvas id='Char_pieNenTang_2' width='800' height='450'/></div>

            <div className='chart__total'>NAM&emsp;&emsp;&emsp;&ensp;NỮ</div>
            <div className='chart__total-number'>{global.rxu.get(this.state, ['gender', '0'], 0)} &nbsp; | &nbsp; {global.rxu.get(this.state, ['gender', '1'], 0)}</div>
          </div>
        </div>
      </div> */}
    </AdminBlock>
    )
  }
}
export default DashboardComponent
