/* global t, rxu */
import React, { Component } from 'react'
import { renderRoutes } from 'react-router-config'
import { Link, Switch } from 'react-router-dom'
import Parser from 'html-react-parser'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'


const { apiget, rxgetLocal, rxJson } = global.rootRequire('classes/request')
const { rxnavToggle, rxnavClose, rxnavChange, logoutAction, rxthemeToggle, rxmenuToggle } = global.rootRequire('redux')
const RxDetailCard = global.rootRequire('components/shares/rxDetailCard').default
// const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default

let adminCSS = '<style>.main-container{ width: 100% !important; padding: 0px !important; } .homenav, .footer{ display: none !important; }</style>'
let widthWindow = (typeof window === 'undefined') ? 0 : window.innerWidth

function checkpermission(strcheck) {
  let strper = rxgetLocal('arrper')

  let arrper = []
  if (strper && strper.indexOf(',') !== -1) {
    arrper = strper.split(',')
  }
  if (arrper.length !== 0) {
    if (arrper.indexOf(strcheck) !== -1) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

class Admin extends Component {
  constructor (props) {
    super(props)
    this._mounted = false
    let user = rxgetLocal('rxuser')
    if (user) {
      user = rxJson(user)
      this.role = global.rxu.get(user,'user.user.role', 'member') || 'member'
    } else {
      this.role = 'member'
    }
    this.state = {
      isAuthenticated: true,
      activeNavs: {},
      data1: {},data2: {}, data3: {}, data4: {},
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      editingData: {},
      showDetails: false,
      // paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
    }
  }

  componentDidMount () {
    this._mounted = true
    this.checkAuth()
    // this.fetchData()
    // this.props.rxnavToggle('ex_manager')
  }

  componentWillUnmount () {
    this._mounted = false
    clearTimeout(this.intervalID);
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }
  fetchData() {
    apiget(global.rxu.get(global.rxu.config, 'api_dashboard_getnoti'), this.state.paging, {
      '1': (json) => {
        this.setState({ data1: json.data.ob1.data || {}, count1: json.data.ob1.count || 0, data2: json.data.ob2.data || {}, count2: json.data.ob2.count || 0,data3: json.data.ob3.data || {}, count3: json.data.ob3.count || 0, data4: json.data.ob4.data || {}, count4: json.data.ob4.count || 0})
        this.intervalID = setTimeout(this.fetchData.bind(this), 10000);
      }
    })

  }

  checkAuth () {
    if (typeof (document) !== 'undefined') {
      let match = document.cookie.match(new RegExp('authorize=([^;]+)'))
      if (!match) {
        // Disable authenticate check
        this.setState({ isAuthenticated: false })
      }
    }
  }

  toggleNavs (navName) {
    let activeNavs = this.state.activeNavs
    if (typeof activeNavs[navName] === 'undefined') { activeNavs[navName] = 0 }
    activeNavs[navName] = !activeNavs[navName]
    this.setState({ activeNavs: activeNavs })
  }

  renderActiveNavClass (navpath, addclass = ' rxactive ') {
    const { pathname, search } = this.props.location
    let path = search && search !== undefined ? (pathname+search) : pathname

    if (path === navpath || (Array.isArray(navpath) && navpath.indexOf(pathname) !== -1)) {
      return addclass
    } else {
      return ''
    }
  }

  onClickLogout () {
    apiget(global.rxu.get(global.rxu.config, 'api_revoke_sso'),{}, {
      '1': (json) => {
        let user = { status: false, user: {}, arrper: [] }
        this.setState({user: user})
        this.props.history.push('/login')
      }
    })
    this.props.logoutAction()
  }

  onClickViewDetail(e, perdata) {
    if(perdata && perdata._id) {
      this.setState({showDetails: false}, () => {
        apiget(global.rxu.get(global.rxu.config, 'api_card_details'), {_id: perdata._id}, {
          '1': (json) => {
            this.setState({ editingData: json.data }, () => {
              this.props.rxmenuToggle('ex_noti')
              this.props.rxmenuToggle('ex_manager_detail')
            })
          }
        })
      })
    }
  }

  toggleDetail() {
    this._ismounted = true
    this.setStateRx({ showDetails: false, editingData: {} })
  }

  onBlurDataReview (e, name, type) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    if(name === 'phase') {
      if(e === -1) {
        delete paging['searchid_phase_id']
        delete paging['phase']
      } else {
        paging['searchid_phase_id'] = e._id
        paging['phase'] = e.name
      }
      if(e !== -1 && e.submit !== 0) {
        this.setState({ isSubmit: false })
      }
    }
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, 500)
  }
  onClickOpenSidebar() {
    // if(widthWindow <= 1024 && document.getElementById('bemain')) {
    // if(document.getElementById('rxsideactive')) {
    //   if(!global.rxu.get(this.props.rxnav, 'menu.righttoggle')) {
    //     document.getElementById('rxsideactive').style.display = 'none'
    //   } else {
    //     document.getElementById('rxsideactive').style.display = 'block'
    //   }
    //   this.props.rxmenuToggle('righttoggle')
    // }
  }
  onClickOpenSidebarLeft() {
    // if(widthWindow <= 1024 && document.getElementById('bemain')) {
    if(document.getElementById('rxsideactive')) {
      if(!global.rxu.get(this.props.rxnav, 'menu.righttoggle')) {
        document.getElementById('rxsideactive').style.display = 'none'
      } else {
        document.getElementById('rxsideactive').style.display = 'block'
      }
      this.props.rxmenuToggle('righttoggle')
    }
  }

  render () {
    return (
      <div>
        {this.state.isAuthenticated && <div>
          {Parser(adminCSS)}
          <div className='adtopbar'>
            {<div className={global.rxu.get(this.props.rxnav, 'menu.righttoggle') ? 'adtopbar__togglesidebar rxsideactive' : 'adtopbar__togglesidebar'} onClick={(e) => this.onClickOpenSidebarLeft()}><i className='icon-grid topnav-icon' /></div>}
            <div className='adtopbar__left'>
              <img src='/images/static/logo.png' alt='Hệ thống quản lý Hồ sơ thẻ nhà báo' />
              <div>
                <div className='adtopbar__logo--sub'>BỘ THÔNG TIN VÀ TRUYỀN THÔNG</div>
                {/*}<div className='adtopbar__logo--title'>CỤC BÁO CHÍ</div>*/}
              </div>
            </div>
            <div className='adtopbar__center'><div className='adtopbar__title'>Hệ thống quản lý Hồ sơ thẻ nhà báo</div></div>
            <div className='adtopbar__right'>
              <div className='adtopbar__addcard hotline'>
                {/*}<a className='hotline' href="tel:0912030757"><p className='p1'>Hotline & zalo:</p> <p className='p2'>0912 030 757</p></a>*/}
                <a className='hotline' href="mailto:thenhabao@mic.gov.vn"><p className='p1'>Email:</p> &nbsp; <p className='p2'> thenhabao@mic.gov.vn</p></a>
              </div>
              <div>
                <div className='adminnav__notify' onClick={ele => this.props.rxmenuToggle('ex_noti')}>
                  <i className='icon-bell topnav-icon' />
                  <div>
                    <span className={(this.state.count1 + this.state.count2 + this.state.count3 + this.state.count4) === 0 ? 'icon-bellnum unconfirm' : 'icon-bellnum'}>{this.state.count1 + this.state.count2 + this.state.count3 + this.state.count4}</span>
                    {/*<span className={this.props.rxnav.navs['ex_noti'] ? 'adminnav__avatar-icon iconcustom-up' : 'adminnav__avatar-icon iconcustom-down'} />*/}
                  </div>
                </div>
                {this.props.rxnav.menu['ex_noti'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('ex_noti')} />}
                <div className={this.props.rxnav.menu['ex_noti'] ? 'adminnav__menunoti rxactive' : 'adminnav__menunoti'} style={{padding: '0 10px'}}>
                  <div className='noti__menu' style={{padding: '10px 0'}}>
                    <div className='noti__menu__name'>Hồ sơ cần xử lý</div>
                  </div>
                  <table style={{width: '100%'}} className='rx-noti'>
                    <tbody>
                        <tr className="rx-title__table">
                          <td className='rx-table__form-header'>Đợt TĐ</td>
                          <td className='rx-table__form-header'>CQBC</td>
                          <td className='rx-table__form-header'>SL</td>
                          <td className='rx-table__form-header'>Thời gian</td>
                        </tr>
                        <tr className='rx-noti__header'>
                          <td colSpan='3'><Link to="/admin/review_1"><div className='title'>Hồ sơ mới cập nhật </div></Link></td>
                          <td className='noti__menu__count'><span className='noti__menu__count'>{this.state.count1}</span></td>
                        </tr>
                        {global.rxu.array(this.state.data1).map((perdata, index) => (<tr key={index} className='dropitem rx-noti__body' onClick={(e) => this.onClickViewDetail(e, perdata)}>
                          <td>{rxu.get(perdata, ['data', '0', 'phase'], '')}</td>
                          <td>{rxu.get(perdata, ['data', '0', 'TenCoQuanBaoChi'], '')}</td>
                          <td className='text-center'>{perdata.total}</td>
                          <td className='text-right'>{rxu.inDays(rxu.get(perdata, ['data', '0', 'updated_at'], 0))}</td>
                        </tr>))}
                        <tr className='rx-noti__header'>
                          <td colSpan='3'><Link to="/admin/review_1"><div className='title'>Hồ sơ sắp quá hạn </div></Link></td>
                          <td className='noti__menu__count'><span className='noti__menu__count'>{this.state.count2}</span></td>
                        </tr>
                        {global.rxu.array(this.state.data2).map((perdata, index) => (<tr key={index} className='dropitem rx-noti__body' onClick={(e) => this.onClickViewDetail(e, perdata)}>
                          <td>{rxu.get(perdata, ['data', '0', 'phase'], '')}</td>
                          <td>{rxu.get(perdata, ['data', '0', 'TenCoQuanBaoChi'], '')}</td>
                          <td className='text-center'>{perdata.total}</td>
                          <td className='text-right'>{rxu.inDays(rxu.get(perdata, ['data', '0', 'updated_at'], 0))}</td>
                        </tr>))}
                        <tr className='rx-noti__header'>
                          <td colSpan='3'><Link to="/admin/review_1"><div className='title'>Hồ sơ quá hạn chưa xử lý </div></Link></td>
                          <td className='noti__menu__count'><span className='noti__menu__count'>{this.state.count3}</span></td>
                        </tr>
                        {global.rxu.array(this.state.data3).map((perdata, index) => (<tr key={index} className='dropitem rx-noti__body' onClick={(e) => this.onClickViewDetail(e, perdata)}>
                          <td>{rxu.get(perdata, ['data', '0', 'phase'], '')}</td>
                          <td>{rxu.get(perdata, ['data', '0', 'TenCoQuanBaoChi'], '')}</td>
                          <td className='text-center'>{perdata.total}</td>
                          <td className='text-right'>{rxu.inDays(rxu.get(perdata, ['data', '0', 'updated_at'], 0))}</td>
                        </tr>))}
                        {['super','admin','chuyenviendp','chuyenvientw','chuyenvienkql'].indexOf(this.role.toLowerCase())  !== -1 && <tr className='rx-noti__header'>
                          <td colSpan='3'><Link to="/admin/review_1"><div className='title'>Hồ sơ trả về đơn vị </div></Link></td>
                          <td className='noti__menu__count'><span className='noti__menu__count'>{this.state.count4}</span></td>
                        </tr>}
                        {['super','admin','chuyenviendp','chuyenvientw','chuyenvienkql'].indexOf(this.role.toLowerCase())  !== -1 && global.rxu.array(this.state.data4).map((perdata, index) => (<tr key={index} className='dropitem rx-noti__body' onClick={(e) => this.onClickViewDetail(e, perdata)}>
                          <td>{rxu.get(perdata, ['data', '0', 'phase'], '')}</td>
                          <td>{rxu.get(perdata, ['data', '0', 'TenCoQuanBaoChi'], '')}</td>
                          <td className='text-center'>{perdata.total}</td>
                          <td className='text-right'>{rxu.inDays(rxu.get(perdata, ['data', '0', 'updated_at'], 0))}</td>
                        </tr>))}
                    </tbody>
                  </table>

                </div>
              </div>
              <div className='adminnav__avatar'>
                {widthWindow > 767 ?<div onClick={ele => this.props.rxmenuToggle('ex_avatar')}>
                  <span>{global.rxu.get(this.props.auth, 'user.user.fullname', '').length > 20 ? (global.rxu.get(this.props.auth, 'user.user.fullname', '').slice(0, 17) + '...') : global.rxu.get(this.props.auth, 'user.user.fullname', '')}</span>
                  <span className={this.props.rxnav.menu['ex_avatar'] ? 'adminnav__avatar-icon iconcustom-up' : 'adminnav__avatar-icon iconcustom-down'} />
                </div> : <span className='icon-user' onClick={ele => this.props.rxmenuToggle('ex_avatar')} />}
                {this.props.rxnav.menu['ex_avatar'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('ex_avatar')} />}
                <div className={this.props.rxnav.menu['ex_avatar'] ? 'adminnav__avatarmenu rxactive' : 'adminnav__avatarmenu'}>
                  <div className='avatarmenu__fullname'>
                    <div>{global.rxu.get(this.props, 'auth.user.user.fullname')}</div>
                    <div className='avatarmenu__username'>{global.rxu.get(this.props, 'auth.user.user.username')}</div>
                  </div>
                  <div className='avatarmenu__fullname'>
                    <Link to="/rbac/changeInfo"><div >Thay đổi thông tin</div></Link>
                  </div>
                  <div className='avatarmenu__fullname'>
                    <Link to="/rbac/changePassword"><div>Thay đổi mật khẩu</div></Link>
                  </div>
                  <div className='avatarmenu__logout' onClick={() => this.onClickLogout()}>
                    <span><i className='icon-power nav-icon' />Đăng xuất</span>
                  </div>
                </div>
              </div>
              <div className='adminnav__adname'>{global.rxu.get(this.props, 'auth.user.fullname')}</div>
              <div className='adminnav__language'><i className='icon-globe topnav-icon' /></div>
            </div>
          </div>
          {widthWindow < 1025 && <div className={(global.rxu.get(this.props.rxnav, 'menu.righttoggle') ? 'rx-fixed-sidebar' : '')} onClick={(e) => this.onClickOpenSidebar()}></div>}
          <div id='rxsideactive' className={(global.rxu.get(this.props.rxnav, 'menu.righttoggle') ? 'beside rxsideactive' : 'beside')}> {/* + ' ' + global.rxu.get(this.props.rxtheme, 'color', '')*/}
            <div className='beside__nav'>
              <div className='beside__navitems'>
                <div className='beside__navitemsinner rxwindowheight'>
                  {['viewer', 'member','lanhdaobo','printer'].indexOf(this.role.toLowerCase()) === -1 && <Link to='/admin/dashboard' onClick={(e) => this.onClickOpenSidebar()}>
                    <div className={'adnav__groupname ' + this.renderActiveNavClass(['/admin/dashboard', '/admin'])}><span><i className='iconcustom-home nav-icon' />{t('dashboard')}</span></div>
                  </Link>}

                  {/* [[RX_NAVLINK_START]] */}
                  {/*['super','admin','member','lanhdaocuc01','lanhdaobo','lanhdaodp','lanhdaotw'].indexOf(this.role.toLowerCase())  !== -1 &&
                  <div className={this.props.rxnav.navs['ex_manager_card'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_manager_card')}><span><i className='iconcustom-card nav-icon' />Quản lý thẻ</span></div>}
                  {this.props.rxnav.navs['ex_manager_card'] && <div className='adnav__groupwrap--card'>
                    {['super','admin','member','lanhdaocuc01','lanhdaobo','lanhdaodp','lanhdaotw'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card')} ><Link to='/admin/card'><i className='iconcustom-Group-2 nav-icon' />Danh sách thẻ</Link></div> }
                    {['super','admin','member','lanhdaocuc01','lanhdaobo','lanhdaodp','lanhdaotw'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=1')} ><Link to='/admin/card?type=1'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp mới</Link></div> }
                    {['super','admin','member','lanhdaocuc01','lanhdaobo','lanhdaodp','lanhdaotw'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=2')} ><Link to='/admin/card?type=2'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp lại</Link></div> }
                    {['super','admin','member','lanhdaocuc01','lanhdaobo','lanhdaodp','lanhdaotw'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=3')} ><Link to='/admin/card?type=3'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp đổi</Link></div> }
                    {['super','admin','member','lanhdaocuc01','lanhdaobo','lanhdaodp','lanhdaotw'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=4')} ><Link to='/admin/card?type=4'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp theo kỳ hạn mới</Link></div> }

                  </div>*/}

                  {(['viewer','member'].indexOf(this.role.toLowerCase()) !== -1)?
                      <div className='adnav__groupwrap--card'>
                        <div className={this.props.rxnav.navs['ex_manager_card'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_manager_card')}><span><i className='iconcustom-card nav-icon' />Nộp hồ sơ</span></div>

                        <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card'><i className='iconcustom-Group-2 nav-icon' />Danh sách hồ sơ</Link></div>
                        {/*}<div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=1')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=1'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp mới</Link></div>*/}
                        <div className='adnav__itemname'><span><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp theo kỳ hạn</span></div>
                        <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=4')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=4'><i className='iconcustom-Group-2 nav-icon' />Cấp đổi theo kỳ hạn </Link></div>
                        <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=5')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=5'><i className='iconcustom-Group-2 nav-icon' />Cấp mới</Link></div>
                        <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=6')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=6'><i className='iconcustom-Group-2 nav-icon' />Cấp đổi do chuyển cơ quan</Link></div>
                        <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=2')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=2'><i className='iconcustom-Group-2 nav-icon' />Cấp lại</Link></div>
                        {/* <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/note')} ><Link to='/admin/note'><i className='iconcustom-Group-2 nav-icon' />Tổng hợp</Link></div>   */}
                        {(['viewer'].indexOf(this.role.toLowerCase()) === -1) && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/cate')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/cate'><i className='iconcustom-Group-2 nav-icon' />Quản lý danh sách đề nghị</Link></div>}
                      </div>
                      :
                      ['admin','lanhdaobo','printer'].indexOf(this.role.toLowerCase()) === -1 ? <div className={this.props.rxnav.navs['ex_manager_card'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_manager_card')}><span><i className='iconcustom-card nav-icon' />Nộp hồ sơ</span>
                      </div> : <div></div>
                  }
                    {this.props.rxnav.navs['ex_manager_card'] && ['viewer','member','lanhdaobo'].indexOf(this.role.toLowerCase()) === -1 && <div className='adnav__groupwrap--card'>
                      <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card'><i className='iconcustom-Group-2 nav-icon' />Danh sách hồ sơ</Link></div>
                      {/*<div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=1')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=1'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp mới</Link></div>
                      <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=3')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=3'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp đổi</Link></div>
                      <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=2')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=2'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp lại</Link></div>*/}
                      {/*<div className={'adnav__itemname' + this.renderActiveNavClass('/admin/card?type=4')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=4'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp theo kỳ hạn mới</Link></div>*/}
                      <div className='adnav__itemname'><span><i className='iconcustom-Group-2 nav-icon' />Hồ sơ cấp theo kỳ hạn</span></div>
                      <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=4')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=4'><i className='iconcustom-Group-2 nav-icon' />Cấp đổi theo kỳ hạn </Link></div>
                      <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=5')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=5'><i className='iconcustom-Group-2 nav-icon' />Cấp mới</Link></div>
                      <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=6')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=6'><i className='iconcustom-Group-2 nav-icon' />Cấp đổi do chuyển cơ quan</Link></div>
                      <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/card?type=2')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/card?type=2'><i className='iconcustom-Group-2 nav-icon' />Cấp lại</Link></div>
                      {(['viewer'].indexOf(this.role.toLowerCase()) === -1) && <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/cate')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/cate'><i className='iconcustom-Group-2 nav-icon' />Quản lý danh sách đề nghị</Link></div>}
                    </div>}

                    {checkpermission('index') && ['viewer','member','printer','profileedit'].indexOf(this.role.toLowerCase())  === -1 && <div className={this.props.rxnav.navs['ex_manager'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_manager')}><span><i className='iconcustom-record nav-icon' />Thẩm định hồ sơ</span></div>}
                    {checkpermission('index') && <div className={this.props.rxnav.navs['ex_manager'] ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                        {['viewer', 'member','printer'].indexOf(this.role.toLowerCase()) === -1 && <div className='adnav__itemname'><span><i className='iconcustom-Group-2 nav-icon' />Hồ sơ thẩm định</span></div>}
                        {['super','admin','lanhdaodonvi','lanhdaotw','lanhdaodp','lanhdaopt','chuyenviendp','chuyenvientw','chuyenvienpt','chuyenvienkql','lanhdaokql'].indexOf(this.role.toLowerCase())  !== -1 && <div className='adnav__itemname adnav__itemname_lv2' ><span><i className='iconcustom-Group-2 nav-icon' />Cấp phòng</span></div> }
                          {['super','admin','chuyenviendp','chuyenvientw','chuyenvienpt','chuyenvienkql'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv3' + this.renderActiveNavClass('/admin/review_1')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_1'><i className='iconcustom-Group-2 nav-icon' />Chuyên viên</Link></div> }
                          {['super','admin','lanhdaodp','lanhdaotw','lanhdaopt','lanhdaokql'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv3' + this.renderActiveNavClass('/admin/review_2')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_2'><i className='iconcustom-Group-2 nav-icon' />Lãnh đạo phòng</Link></div> }
                        {['super','admin','lanhdaocuc01','lanhdaocucbcdp','lanhdaocucbctw','lanhdaocucptth','lanhdaocuckql'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/review_3')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_3'><i className='iconcustom-Group-2 nav-icon' />Cấp Cục</Link></div> }
                        {/*['super','admin','lanhdaobo'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/review_4')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_4'><i className='iconcustom-Group-2 nav-icon' />Cấp Bộ</Link></div> */}
                        {['admin','lanhdaobo'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/review_8')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_8'><i className='iconcustom-Group-2 nav-icon' />DS tổng hợp cấp thẻ</Link></div> }
                        {['super','printer'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/printlist')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/printlist'><i className='iconcustom-Group-2 nav-icon' />DS tổng hợp in thẻ</Link></div> }
                        {['super','admin','printer'].indexOf(this.role.toLowerCase())  !== -1 && <div className={'adnav__itemname adnav__itemname_lv2' + this.renderActiveNavClass('/admin/print')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/print'><i className='iconcustom-Group-2 nav-icon' />Hồ sơ đã duyệt in</Link></div> }
                      {['admin'].indexOf(this.role.toLowerCase())  !== -1 &&<div className={'adnav__itemname' + this.renderActiveNavClass('/admin/analytics')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/analytics'><i className='iconcustom-Group-2 nav-icon' />Biểu mẫu thống kê</Link></div> }
                      {['member','lanhdaobo'].indexOf(this.role.toLowerCase())  === -1 &&<div className={'adnav__itemname' + this.renderActiveNavClass('/admin/review_7')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_7'><i className='iconcustom-Group-2 nav-icon' />Trả kết quả hồ sơ</Link></div>}
                      {['super','admin','lanhdaocuc01','lanhdaocucbcdp','lanhdaocucbctw','lanhdaocucptth','lanhdaocuckql'].indexOf(this.role.toLowerCase())  !== -1 &&<div className={'adnav__itemname' + this.renderActiveNavClass('/admin/review_8')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/review_8'><i className='iconcustom-Group-2 nav-icon' />DS tổng hợp cấp thẻ</Link></div>}

                    </div>}


                  {checkpermission('site') && <div className={this.props.rxnav.navs['ex_system'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_system')}><span><i className='iconcustom-system nav-icon' />Cấu hình hệ thống</span></div>}
                  {checkpermission('site') && <div className={this.props.rxnav.navs['ex_system'] ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/office')} onClick={(e) => this.onClickOpenSidebar()}><Link to='/admin/office'><i className='iconcustom-Group-2 nav-icon' />Tên Cục</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/newspaper_office')} onClick={(e) => this.onClickOpenSidebar()}><Link to='/admin/newspaper_office'><i className='iconcustom-Group-2 nav-icon' />Cơ quan báo chí</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/position')} onClick={(e) => this.onClickOpenSidebar()}><Link to='/admin/position'><i className='iconcustom-Group-2 nav-icon' />Danh sách chức vụ</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/title')} onClick={(e) => this.onClickOpenSidebar()}><Link to='/admin/title'><i className='iconcustom-Group-2 nav-icon' />Danh sách chức danh</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/province')} onClick={(e) => this.onClickOpenSidebar()}><Link to='/admin/province'><i className='iconcustom-Group-2 nav-icon' />Danh sách Tỉnh/Thành phố</Link></div>
                    <div className={'adnav__itemname' + this.renderActiveNavClass('/admin/config')} onClick={(e) => this.onClickOpenSidebar()}><Link to='/admin/config'><i className='iconcustom-Group-2 nav-icon' />Config</Link></div>
                  </div>
                  }

                  {/* [[RX_NAVLINK_END]] */}

                  {/* [[RX_NAVLINK_START]] */}
                  {['super','admin'].indexOf(global.rxu.get(this.props, 'auth.user.role')) === -1 && this.role.toLowerCase() !== 'viewer' && <div>
                  {checkpermission('site') && <div className={this.props.rxnav.navs['ex_/rbac'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_/rbac')}><span><i className='iconcustom-setting nav-icon' />Tuỳ chỉnh khác </span></div>}
                  {checkpermission('site') && <div className={this.props.rxnav.navs['ex_/rbac'] ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                  <div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/role')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/admin/sync'><i className='iconcustom-Group-2 nav-icon'></i>Đồng bộ hồ sơ</Link></div>
                  <div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/member')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/rbac/member'><i className='iconcustom-acc nav-icon'></i>Cơ quan đăng ký</Link></div>
                  <div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/user')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/rbac/user'><i className='iconcustom-acc nav-icon'></i>Quản lý tài khoản</Link></div>
                  <div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/role')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/rbac/role'><i className='iconcustom-Group-2 nav-icon'></i>Phân quyền</Link></div>
                  {/*}<div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/permission')} ><Link to='/rbac/permission'><i className='iconcustom-Group-2 nav-icon'></i>Permission</Link></div>*/}
                  <div className={'adnav__itemname' + this.renderActiveNavClass('/rbac/logs')} onClick={(e) => this.onClickOpenSidebar()} ><Link to='/rbac/logs'><i className='iconcustom-Group-2 nav-icon'></i>Lịch sử hoạt động</Link></div>

                  </div>},
                  <div className={this.props.rxnav.navs['ex_/login'] ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                  </div><div className={this.props.rxnav.navs['ex_/register'] ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                  </div>{/* [[RX_NAVLINK_END]] */}
                  </div>}

                  {!checkpermission('site') &&
                  ['lanhdaobo','printer','viewer'].indexOf(this.role.toLowerCase())  === -1 &&
                   <Link to='/admin/manualBar'>
                      <div className={'adnav__groupname ' + this.renderActiveNavClass(['/admin/manualBar', '/admin'])}><span><i className='iconcustom-system nav-icon' />Hướng dẫn sử dụng</span></div>
                  </Link>}


                  {['super','admin','superedit','chuyenvientw','chuyenviendp','chuyenvienkql'].indexOf(this.role.toLowerCase())  !== -1 &&
                   <Link to='/admin/media'>
                      <div className={'adnav__groupname ' + this.renderActiveNavClass(['/admin/media', '/admin'])}><span><i className='iconcustom-system nav-icon' />Sao lưu ảnh</span></div>
                  </Link>}
                  {['super','admin','chuyenvienpt','chuyenvientw','chuyenviendp','chuyenvienkql'].indexOf(this.role.toLowerCase())  !== -1 &&
                   <Link to='/admin/medialist'>
                      <div className={'adnav__groupname ' + this.renderActiveNavClass(['/admin/medialist', '/admin'])}><span><i className='iconcustom-system nav-icon' />Danh sách ảnh</span></div>
                  </Link>}


                  {/*checkpermission('site') && <div className={this.props.rxnav.navs['ex_theme'] ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => this.props.rxnavToggle('ex_theme')}><span><i className='iconcustom-system nav-icon' />Đổi màu giao diện</span></div>}
                  {checkpermission('site') && <div className={this.props.rxnav.navs['ex_theme'] ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                    <div className={'adnav__theme ' + global.rxu.get(this.props.rxtheme, 'color', '')}>
                      <div className='adnav__itemname black' onClick={(e) => this.props.rxthemeToggle('black')}></div>
                      <div className='adnav__itemname white' onClick={(e) => this.props.rxthemeToggle('white')}></div>
                    </div>
                  </div>
                  */}

                  {/* [[RX_NAVLINK_END]] */}
                  <div className={'adnav__groupname adnav__groupname--last adnav__groupname--link' + this.renderActiveNavClass('/login')} onClick={() => this.onClickLogout()}>
                    <span><i className='icon-power nav-icon' />Đăng xuất</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* global.rxu.get(this.props.rxnav, 'navs.righttoggle') && <div className='rxsideactive_back' onClick={(e) => this.props.rxnavClose('righttoggle')} /> */}

          <div className={global.rxu.get(this.props.rxnav, 'menu.righttoggle') ? 'bemain rxsideactive' : 'bemain'} id='bemain'>
            <div className=''>
              { this.props.rxnav.menu['ex_manager_detail'] && this.state.editingData && this.state.editingData._id && <RxDetailCard editingData={this.state.editingData} user={global.rxu.get(this.props, 'auth.user.user')} api='api_card_details' /> }
              <Switch>{renderRoutes(this.props.route.routes)}</Switch>
            </div>
          </div>
        </div>}
        {!this.state.isAuthenticated && <Redirect to='/login' />}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav,
  auth: state.auth,
  rxtheme: state.rxtheme
})

const mapDispatchToProps = {
  rxnavToggle,
  rxnavClose,
  rxnavChange,
  logoutAction,
  rxthemeToggle,
  rxmenuToggle
}

const Admin_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)

export default Admin_
