import * as Jsx from './rxCrud.jsx'
import * as xlsx from 'xlsx'
import { connect } from 'react-redux'
// import SaveAs from 'file-saver'

global.isclient && require('./rxCrud.css')
global.isclient && require('jsoneditor/dist/jsoneditor.css')

const { apiget, apipost, rxgetLocal, rxJson } = global.rootRequire('classes/request')
const RxComponent = global.rootRequire('components/shares/rxComponent').default

const rxu = global.rxu
const WAIT_INTERVAL = 500
class RxCrud extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this._mounted = false
    this.state = {
      flagUpdate: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      pagingCat: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50, search_is_active: 1 },
      editingData: {},
      importData: [],
      extra: {},
      data: {},
      flags: {},
      temp: {},
      msg: {},
      cate: [],
      position: [],
      newspaperOffice: [],
      view: false,
      activeTab: 'label1',
      type: this.props.type || 0,
    }
    this.onChangeContentCKE = this.onChangeContentCKE.bind(this)
    this.onchangeDate = this.onchangeDate.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    this.fetchAlldata()
  }

  componentWillUnmount () {
    this._mounted = false
    super.componentWillUnmount()
    if (this.jsoneditor) { this.jsoneditor.destroy() }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  onClickTabItem(e,tab) {
    this.setState({ activeTab: tab })
  }

  componentDidUpdate (prevProps, nextState) {
    this._mounted = true
    if ([false, true].indexOf(prevProps.flagUpdate) !== -1 && prevProps.flagUpdate !== this.props.flagUpdate) {
      this.setStateRx({ flagUpdate: prevProps.flagUpdate })
      this.run('parentUpdate', {})
    }
  }

  // D A T A
  fetchAlldata () {
    this.fetchData();
    if(this.props.name === 'User') {
      this.fetchCate();
      this.fetchPosition() ;
      this.fetchNewspaperOffice()
    }
    if(this.props.name === 'cate' || this.props.name === 'Member') {
      this.fetchCate();
    }
  }
  fetchData (runCallback = false) {
    let paging = this.state.paging
    if (this.props.name === 'User') { delete paging.search_is_active }
    if (this.props.name === 'sync') { paging['st_col'] = 'is_synced' }

    apiget(rxu.get(rxu.config, this.props.api), paging, {
      '1': (json) => {
        this.setStateRx({ data: json.data, extra: json.extra }, () => {
          if (!rxu.get(this.state, ['temp', 'filterFields'])) {
            let temp = this.state.temp; temp.filterFields = rxu.get(this.state, ['data', 0])
            this.setStateRx({ temp })
          }
        })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  fetchCate (runCallback = false) {
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    let pagingCat = this.state.pagingCat
    pagingCat['search_Type'] = global.rxu.get(user, 'user.user.Type', 1)
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), pagingCat, {
      '1': (json) => {
        this.setStateRx({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchPosition (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_position'), this.state.pagingCat, {
      '1': (json) => {
        this.setStateRx({ position: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchNewspaperOffice (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), this.state.paging, {
      '1': (json) => {
        this.setStateRx({ position: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  InDanhSachCoQuanBaoChi(){
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), { ...this.state.paging, pg_size: 2000,st_col:'Cat_Name', st_type: 1  }, {
      '1': (json) => {
        global.JSONToCSVConvertorDanhSachCoQuanBaoChi(json.data, this.state.paging)
      }
    })
  }

  InDanhSachMember(){
    apiget(rxu.get(rxu.config, 'api_member_export'), { ...this.state.paging, pg_size: 1600}, {
      '1': (json) => {
        global.JSONToCSVConvertorMember(json.data, this.state.paging)
      }
    })
  }

  printListUser(){
    apiget(rxu.get(rxu.config, 'api_user'), { ...this.state.paging, pg_size: 2000, st_col:'CoQuanBaoChi', st_type: 1, search_is_active: 1 }, {
      '1': (json) => {
        global.JSONToCSVConvertorMember(json.data, this.state.paging)
      }
    })
  }


  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    let flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setStateRx({ flags: flags })
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  // C L I C K   E V E N T
  onClickData (e, perdata) {}
  onClickSort (e, stcol) {
    let paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setStateRx({ paging: paging }, () => { this.fetchData() })
  }

  onClickExcelUpload(flagname) {
    let flags = this.state.flags
    if(!flags[flagname]) { flags[flagname] = false }
    flags[flagname] = true
    this.setStateRx({flagname: flagname})
  }

  onClickDataImport(e) {
    let scope = this
    let reader = new FileReader()
    let fileDom = e.target || e.srcElement

    if(fileDom.value !==  '') {
      let file = fileDom.files[0]
      if(typeof file !== 'undefined') {
        reader.onload = function(e) {
          let datatmp = new Uint8Array(e.target.result);
          let workbook = xlsx.read(datatmp, {type: 'array'});
          workbook.SheetNames.forEach((sheetName) => {
            var data = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName])
            if(data && data[0]['But danh'] && data[0]['Ma the'] && data[0]['Ten co quan'] && data[0]['Ngay het han'] && data[0]['Ngay cap'] && data[0]['Nguoi ky']
              && data[0]['Nam sinh'] && data[0]['Chuc vu'] && data[0]['Chu ky'] ) {
              scope.setStateRx({importData: data})
            } else {
              alert('File sai định dạng')
            }
          })
        }
        reader.readAsArrayBuffer(file)
      }
    }
    else {}
  }

  onClickDataCheck(e) {
    let selectedFile = e.target.files[0]
    let reader = new FileReader()
    reader.onload = (event) => {
      let data = event.target.result
      let workbook = xlsx.read(data, { type: 'binary' })

      workbook.SheetNames.forEach((sheetName) => {
          if(sheetName === 'Dai') {
            let CateArr = []
            let xlRowObjectTemp = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
            if(xlRowObjectTemp.length > 0) {
              console.log(xlRowObjectTemp, 'xlRowObjectTemp')
              for(let i = 4; i < xlRowObjectTemp.length; i++) {
                if(xlRowObjectTemp[i]['__EMPTY'] && CateArr.indexOf(xlRowObjectTemp[i]['__EMPTY']) === -1) {
                  // CateArr.push(xlRowObjectTemp[i]['__EMPTY'])
                  CateArr.push({Cat_Name: xlRowObjectTemp[i]['__EMPTY'], DiaChi: xlRowObjectTemp[i]['__EMPTY_3'] || ''})
                } else if(xlRowObjectTemp[i]['__EMPTY'] && CateArr.indexOf(xlRowObjectTemp[i]['__EMPTY']) !== -1) {
                  console.log(i, 'du lieu file bi trung',xlRowObjectTemp[i]['__EMPTY'], 'eee', CateArr.indexOf(xlRowObjectTemp[i]['__EMPTY']))
                }
              }
            }
            console.log(CateArr, 'CateArr')
            let params = JSON.stringify(CateArr)

            apipost(rxu.get(rxu.config, 'api_newspaper_office_check'), {data: params}, {
              '1': (json) => {
              }
            })
          }
      })
    }
    reader.onerror = function (event) { console.error('File could not be read! Code ' + event.target.error.code) }
    reader.readAsBinaryString(selectedFile)
  }

  onClickSubmitExcelUpload() {
    apipost(rxu.get(rxu.config, this.props.api) + '/importExcel', this.state.importData, {
      '1': (json) => {
        this.setStateRx({importData: [] }, () => this.fetchData(true))
        // if(json.data && json.data.length > 0){
        //   console.log('có '+json.data.length+' sản phẩm đã tồn tại')
        // }
      }
    })
  }

  onClickImportProvince() {
    let data = JSON.stringify(global.province)
    apipost(rxu.get(rxu.config, 'api_province_import'), { data: data }, {
      '1': (json) => {  }
    })
  }

  onClickDataExport (e) {
    apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
      '1': (json) => { global.JSONToCSVConvertor(json.data, '') }
    })
  }
  onClickDataExport3(e, num) {
    apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), {searchid_phase_id: this.state.editingData._id, search_IsUpdateProfile: 1, paging: 10000}, {
      '1': (json) => {
        let JsonData = {CoQuanBaoChiObj: this.state.editingData['CoQuanBaoChiObj'], data: json.data}
        num === 4 ? global.JSONToCSVConvertorMau4(JsonData) : global.JSONToCSVConvertorMau3(JsonData)
      }
    })
  }

  onClickDataDeleteAll (e) {
    let r = window.confirm('Bạn muốn xoá tất cả dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, 'api_card_delete_all'), {}, {
        '1': (json) => {   this.fetchData(true) }
      })
    } else {}

  }

  onClickDataNew (e) {
    global.scrolltop()
    let timeStr = Date.now().toString()
    let clone = { name: this.props.name + '_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0, type: 0 }
    if(this.props.name === 'Card') { clone.QuaTrinhHoatDongUpdate = [{time:'', ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''}] }
    if(this.props.name === 'User') { clone.CoQuanBaoChi = []; clone.CoQuanBaoChiID = [] }
    if(this.props.name === 'cate') {
      let user = global.rxu.get(this.props, ['auth', 'user', 'user'], {})
      if(user.role === 'member' && user.CoQuanBaoChi) {
        clone['TenCoQuanBaoChi']    = global.rxu.get(user, ['CoQuanBaoChi', 0], '') // user.CoQuanBaoChi
        clone['CoQuanBaoChiID']  = user.id
        clone['LoaiCoquan']      = user.Type === 2 ? 3 : 2
      }
      clone.name = 'Thẩm định đợt ' + timeStr.substr(timeStr.length - 5)
    }
    this.setStateRx({ editingData: clone })
  }

  onClickDataEdit (e, perdata) {

    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    if(this.props.name === 'cate' && clone && clone._id) {
        apiget(rxu.get(rxu.config, this.props.api) + '/detail', { searchid_phase_id: clone._id, search_IsUpdateProfile: 1 }, {
          '1': (json) => {
            // clone.total = global.rxu.get(json.extra, 'count', 0)
            clone.card = json.data || []
            this.setStateRx({ editingData: clone, flags: {}, view: false, msg: {} })
          }
        })
      }
    // this.setStateRx({ editingData: clone, flags: {}, view: false })
    this.setStateRx({ editingData: clone, flags: {}, view: false, msg: {} })
  }

  onClickViewData(e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    if(this.props.name === 'cate' && clone && clone._id) {
      apiget(rxu.get(rxu.config, this.props.api) + '/detail', { searchid_phase_id: clone._id, search_IsUpdateProfile: 1, Cat_ID: clone.CoQuanBaoChiID }, {
        '1': (json) => {
          // clone.total = global.rxu.get(json.extra, 'count', 0)
          clone.card = json.data || []
          clone.CoQuanBaoChiObj = json.extra.office || {}
          this.setStateRx({ editingData: clone, flags: {}, view: true, msg: {} })
        }
      })
    }
    // this.setStateRx({ editingData: clone, view: true })
  }

  onClickViewDataImg(e, perdata, name) {
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})
    if (['viewer'].indexOf(role) === -1 ) {
      global.scrolltop()
      let activeStep = true
      if(name && (name === 'print' || name === 'return' || name.indexOf(('review') !== -1))) {
        if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
        let clone = rxu.clone(perdata)
        this.setStateRx({ editingDataReview: clone, view: true, activeStep: activeStep })
      } else {
        if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
        let clone = rxu.clone(perdata)
        this.setStateRx({ editingData: clone, view: true, activeStep: activeStep })
      }
    }
  }
  onClickDataDetail(e, perdata) {
    apiget(rxu.get(rxu.config, this.props.api) + '/details', {_id: perdata._id}, {
      '1': (json) => { this.setStateRx({ editingData: json.data }) }
    })
  }

  onClickDataDelete (e, perdata) {
    let r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, this.props.api) + '/delete', {_id: perdata._id}, {
        '1': (json) => { this.fetchData(true) }
      })
    } else {}
  }

  onClickDataRestore (e, perdata) {
    apiget(rxu.get(rxu.config, this.props.api) + '/restore', {_id: perdata._id}, {
      '1': (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit (e, perdata, type) {
    global.scrolltop()
    let editingData = this.state.editingData
    if(type && type === 1) {
      if (editingData['method_result'] && editingData['reception'] && editingData['submit'] === 0 && editingData['card'].length > 0) {

        let r = window.confirm('Bạn có chắc chắn muốn trình danh sách này !')
        if (r === true) {
          // let dataId = []
          // editingData['card'].forEach(o => dataId.push(o._id))
          // let params = {dataId: dataId, _id: editingData._id, submit: 0}
          let params = {
            reception: editingData['reception'],
            method_result: editingData['method_result'],
            _id: editingData['_id']
          }

          apipost(rxu.get(rxu.config, 'api_card_submit'), params, {
            '1': (json) => { this.fetchData(true) }
          })
          this.onClickDataEdit({}, {})
        }
      } else {
        window.confirm('Vui lòng nhập đầy đủ thông tin !')
      }
    } else {
      if(editingData.password && editingData.repassword && editingData.password === editingData.repassword ) { editingData.IsUpdateProfile = 1}
      apipost(rxu.get(rxu.config, this.props.api) + '/edit', editingData, {
        '1': (json) => { this.fetchData(true) }
      })
      this.onClickDataEdit({}, {})
    }
  }

  onClickDataUpdateSubmitCate (e) {
    global.scrolltop()
    let editingData = this.state.editingData
    let params = {}
    if (editingData && [0].indexOf(editingData.submit) !== -1 && [0,1].indexOf(editingData.status) !== -1) {
      params.submit = editingData.submit
      params.status = editingData.status
      params._id    = editingData._id
      apipost(rxu.get(rxu.config, this.props.api) + '/edit', params, {
        '1': (json) => { this.fetchData(true); this.onClickDataEdit({}, {}) }
      })
    } else {
      params._id    = editingData._id
      params.changename = 1
      apipost(rxu.get(rxu.config, this.props.api) + '/edit', params, {
        '1': (json) => { this.fetchData(true); this.onClickDataEdit({}, {}) }
      })
    }
  }

  onClickDataUpdateSubmitRaw (e, perdata) {
    global.scrolltop()
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
      '1': (json) => { this.fetchData(true) },
      'default': () => {}
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit (e, perdata) {
    global.scrolltop()
    this.setStateRx({loading: true}, () => {
      let editingData = {...this.state.editingData, hideProgress: true}
      apipost(rxu.get(rxu.config, this.props.api), editingData, {
        '1': (json) => { this.setStateRx({loading: false}, () => this.fetchData(true)) },
        'default': (json) => { this.setStateRx({msg: json});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {}}) }, 3000)},
      })
      this.onClickDataEdit({}, {})
    })
  }

  onClickDataTrash (e, isdeleted) {
    let paging = this.state.paging
    paging['search_is_deleted'] = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickSyncSSO (e, data) {
    data = JSON.stringify(data)
    let ob = {data: data}
    apipost(rxu.get(rxu.config, 'api_user_syncSSO'), ob, {
    // apipost(global.rxu.config.api_user_syncSSO, ob, {
      '1': (json) => { this.setState({msg: json}, () => { this.fetchData(); this.onClickDataEdit({}, {})}) },
      'default': (json) => { this.setState({msg: json})}
    })
  }

  onClickSendEmail(e, data) {
    let obj = {
      email: data['email'],
      username: data['username'],
      CoQuanBaoChi: data['CoQuanBaoChi'],
      fullname: data['fullname'],
      _id: data['_id'],
      LoaiCoquan: data['LoaiCoquan']
    }
    let params = { data: JSON.stringify(obj) }
    apipost(global.rxu.config.api_user_sendinfo, params, {
      '1': (json) => { let msg = {msg: 'Đã gửi thông tin!', success: 1}; this.setState({msg: msg}); this.fetchData(); this.onClickDataEdit({}, {}) },
      'default': (json) => {this.setState({msg: json}) }
    })
  }

  onClickGenPermission () {
    apiget(rxu.config.api_permission_genpermission, {}, { '1': (json) => {
      this.fetchData()
    } })
  }

  onClickAddRowTable() {
    let editingData = this.state.editingData
    if(editingData['QuaTrinhHoatDongUpdate']) {
      editingData['QuaTrinhHoatDongUpdate'].push({time:'', ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''})
    } else {
      editingData['QuaTrinhHoatDongUpdate'] = [{time:'', ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''}]
    }
    this.setStateRx({editingData: editingData})
  }
  onClickRowDel(index) {
    let editingData = this.state.editingData
    if(editingData['QuaTrinhHoatDongUpdate'] && editingData['QuaTrinhHoatDongUpdate'].length > 0) {
      let tmp = editingData['QuaTrinhHoatDongUpdate'][index]['time']
      editingData['QuaTrinhHoatDongUpdate'] = editingData['QuaTrinhHoatDongUpdate'].filter(o => o.time !== tmp)
    }
    this.setStateRx({editingData: editingData})
  }

  // B L U R   E V E N T
  onBlurData (e, name, options, name1, index) {
    options = options || {}
    let editingData = this.state.editingData
    if (name === 'role_id') {
      if (this.props.roles && e.target.value) {
        let ob = this.props.roles.find(o => o.name === e.target.value)
        editingData.role_id = ob ? ob._id : ''
        editingData.role    = ob ? ob.name : ''
      }
    } else if(name ==='QuaTrinhHoatDongUpdate') {
      editingData['QuaTrinhHoatDongUpdate'][index][name1] = e.target.value
    } else {
      if (options.strim) {
        editingData[name] = e.target.value.toString().replace(/(,)/g, '')
      } else {
        editingData[name] = e.target.value
      }
    }
    this.setState({ editingData: editingData })
  }

  onBlurDataValue (value, name) {
    let editingData = this.state.editingData
    if(name === 'TenChucVu' && value) {
      editingData['TenChucVu'] = value.Ten
      editingData['ChucVuID'] = value.ChucVuID
    } else if (name === 'TenCoQuanBaoChi') {
      editingData['TenCoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiID'] = value.Cat_ID
    } else if(name === 'adduser') {
      editingData['CoQuanBaoChi']= value.Cat_Name
      editingData['CoQuanBaoChiID']= value.Cat_ID
    } else if (name === 'CoQuanBaoChi') {
      editingData['CoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiID'] = value.Cat_ID
    } else if(name === 'CoQuanBaoChi_media') {
      editingData['CoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiObj'] = value.Cat_ID
    } else {
      editingData[name] = value
    }
    // console.log(editingData)
    this.setState({ editingData: editingData })
  }

  onBlurDatafilter (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      if(e === -1) {
        switch (name) {
          case 'TenCoQuanBaoChi':
             delete paging['searche_' + name]
            break;
          case 'TenChucVu':
             delete paging['searche_' + name]
            break;
          default:
             delete paging['search_' + name]
        }
      } else {
        switch (name) {
          case 'CoQuanBaoChiID':
            paging['search_' + name] = e.Cat_ID
            break;
          case 'TenChucVu':
            paging['searche_' + name] = e.Ten
            break;
          default:
            paging['search_' + name] = e
        }
      }

      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else if(name === 'search_cate') {
      let paging = this.state.pagingCat
      paging['search_Cat_Name'] = e
      this.setState({ pagingCat: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchCate()
      }, WAIT_INTERVAL)
    } else if(name === 'Status') {
      let paging = this.state.paging
      paging['search_Status'] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else {
      let paging = this.state.paging
      paging['search_' + name] = e.target.value
      if(e.target.value === '' || Number(e.target.value) === -1) { delete paging['search_' + name] }
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
  }
  onBlurDatafilterOld (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      paging['search_' + name] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else if(name === 'search_cate') {
      let paging = this.state.pagingCat
      paging['search_Cat_Name'] = e
      this.setState({ pagingCat: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchCate()
      }, WAIT_INTERVAL)
    } else if(name === 'Status') {
      let paging = this.state.paging
      paging['search_Status'] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }else if(name === 'newspaperOffice') {
      let paging = this.state.paging
      paging['search_Type'] = e.target.value
      if(e.target.value === -1){
        paging = { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
      }

      this.setState({ paging: paging })
      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
     else {
      let paging = this.state.paging
      paging['search_' + name] = e.target.value
      if(paging['search_' + name] === '-1' || paging['search_' + name === -1]) { delete paging['search_' + name] }
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
  }

  onBlurDataOffice(e, name, err) {
    let editingData = this.state.editingData
    // console.log(e, 'e')
    // console.log(role)
    if(e.length > 0) {
      editingData['CoQuanBaoChi'] =  editingData['CoQuanBaoChi'] && editingData['CoQuanBaoChi'].constructor === Array ? editingData['CoQuanBaoChi'] : []
      editingData['CoQuanBaoChiID'] = editingData['CoQuanBaoChiID'] && editingData['CoQuanBaoChiID'].constructor === Array ? editingData['CoQuanBaoChiID'] : []
      for(let i in e) {
        let index1 = editingData['CoQuanBaoChi'].findIndex(o => o === e[i].Cat_Name)
        let index2 = editingData['CoQuanBaoChiID'].findIndex(o => o === e[i].Cat_ID)
        if((index1 === -1 && index2 === -1) || (index1 !== -1 && index2 === -1) || (index1 === -1 && index2 !== -1)) {
          editingData['CoQuanBaoChi'].push(e[i].Cat_Name)
          editingData['CoQuanBaoChiID'].push(e[i].Cat_ID)
        } else {
          console.log(e[i], 'sdbvgh', i, editingData['CoQuanBaoChi'].findIndex(o => o === e[i].Cat_Name), '=====', editingData['CoQuanBaoChiID'].findIndex(o => o === e[i].Cat_ID))
        }
      }
      if(err && err.length > 0) {
        editingData['CoQuanBaoChiErr'] = err
      }
    } else {
      if(editingData.role !== 'member') {
        editingData['CoQuanBaoChi'] =  editingData['CoQuanBaoChi'] && editingData['CoQuanBaoChi'].constructor === Array ? editingData['CoQuanBaoChi'] : []
        // editingData['id'] = editingData['id']
        editingData['CoQuanBaoChiID'] = editingData['CoQuanBaoChiID'] && editingData['CoQuanBaoChiID'].constructor === Array ? editingData['CoQuanBaoChiID'] : []

        let index = editingData['CoQuanBaoChiID'].findIndex(o => o === e.Cat_ID)
        let index_ = editingData['CoQuanBaoChi'].findIndex(o => o === e.Cat_Name)
        // console.log(index, 'index')
        if(index !== -1) {
          editingData['CoQuanBaoChiID'] = index_ !== -1 ? editingData['CoQuanBaoChiID'].filter(o => o !== e.Cat_ID) : editingData['CoQuanBaoChiID']
        } else {
          editingData['CoQuanBaoChiID'].push(e.Cat_ID)
        }
        if(index_ !== -1) {
          editingData['CoQuanBaoChi'] = index !== -1 ? editingData['CoQuanBaoChi'].filter(o => o !== e.Cat_Name) : editingData['CoQuanBaoChi']
        } else {
          editingData['CoQuanBaoChi'].push(e.Cat_Name)
        }
        // editingData['id'] = e.Cat_ID
      } else {
        // console.log(333)
        editingData['CoQuanBaoChi']= e.Cat_Name
        // editingData['id'] = e.Cat_ID
        editingData['CoQuanBaoChiID']= e.Cat_ID
      }
    }
    // if(editingData.role !== 'member' && editingData['CoQuanBaoChiErr'] && editingData['CoQuanBaoChiErr'].length > 0) {
    //   let tmp = []
    //   for(let i = 0; i < editingData['CoQuanBaoChiErr'].length; i++) {
    //     let index = editingData['CoQuanBaoChi'].findIndex( o => editingData['CoQuanBaoChiErr'][i].toLowerCase() === o.toLowerCase() )
    //     if(index === -1) {
    //       tmp.push(editingData['CoQuanBaoChiErr'][i])
    //     } else {
    //       console.log(editingData['CoQuanBaoChiErr'][i], 'da co trong arr CoQuanBaoChi', index)
    //     }
    //   }
    //   editingData['CoQuanBaoChiErr'] = tmp
    // }
    // console.log(editingData, 'editingData')
    this.setState({editingData: editingData})
  }

  resetDataOffice() {
    let editingData = this.state.editingData
    editingData['CoQuanBaoChi'] = []
    editingData['CoQuanBaoChiID'] = []
    editingData['CoQuanBaoChiErr'] = []
    this.setState({ editingData: editingData })
  }

  // C H A N G E  E V E N T
  onChangeContentCKE (evt) {
    let editingData = this.state.editingData
    let newContent = evt.editor.getData()
    editingData.content = newContent
    this.setState({ editingData: editingData })
  }

  onchangeDate = (event, picker) => {
    let editingData = this.state.editingData
    if((editingData.start_date) !== (picker.startDate._d / 1000) || (editingData.end_date) !== (picker.endDate._d / 1000)) {
      editingData['start_date'] = picker.startDate._d / 1000
      editingData['end_date'] = picker.endDate._d / 1000
      this.setState({editingData: editingData})
    }
  }

  sendProfileSync(perdata) {
    if (perdata._id) {
      let editingData = {
        search_CoQuanBaoChiID: perdata.Cat_ID ,
        search_Type: perdata.Type ,
        search_IsUpdateProfile: 1,
        // searchnin_is_synced: 1,
        _id: perdata._id,
        pg_size: 1000
      }
      apiget(rxu.config.api_sendProfileSync, editingData, {
        '1': (json) => {
          this.fetchData()
        }
      })
    }
  }

  sendProfileSyncOne(perdata) {
    perdata = perdata || {}
    if (perdata.IsUpdateProfile !== 0) {
      let editingData = {
        search_CoQuanBaoChiID: perdata.CoQuanBaoChiID ,
        search_Type: perdata.Type ,
        search_IsUpdateProfile: 1,
        searchnin_is_synced: 1,
        searchid__id: perdata._id,
        pg_size: 1
      }
      apiget(rxu.config.api_sendProfileSync, editingData, {
        '1': (json) => {
          this.fetchData()
        }
      })
    }
  }

  sendProfileSyncUpdate(perdata) {
    perdata = perdata || {}
    if (perdata.IsUpdateProfile !== 0) {
      let editingData = {
        search_CoQuanBaoChiID: perdata.CoQuanBaoChiID ,
        search_Type: perdata.Type ,
        search_IsUpdateProfile: 1,
        searchnin_is_synced: 1,
        searchid__id: perdata._id,
        pg_size: 1
      }
      apiget(rxu.config.api_sendProfileSyncUpdate, editingData, {
        '1': (json) => {
          this.fetchData()
        }
      })
    }
  }

  // P A G I N  E V E N T
  onClickPaging (page) {
    let paging = this.state.paging
    let count = rxu.get(this.state.extra, 'count', paging.pg_size)
    let maxpage = Math.ceil(count / paging.pg_size)

    if (page < 1) { page = 1 }
    if (page > maxpage) { page = maxpage }

    paging.pg_page = page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onChangePageSize (evt) {
    clearTimeout(this.timerPageSize)
    let pagesize = parseInt(evt.target.value, 10)
    let paging = this.state.paging
    paging.pg_size = pagesize
    paging.pg_page = 1
    if (pagesize >= 1 && pagesize <= 1000) {
      this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
    }
  }
  onclickFirst(e) {
    let paging = this.state.paging
    paging.pg_page = 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onclickLast(e) {
    let paging = this.state.paging
    let countAll = global.rxu.get(this.state.extra, 'count', paging.pg_size) / this.state.paging.pg_size
    if (countAll > parseInt(countAll,10)) {
      paging.pg_page = parseInt(countAll,10) + 1
    } else {
      paging.pg_page = countAll
    }
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginBack (e) {
    let paging = this.state.paging
    paging.pg_page = (paging.pg_page > 1) ? (paging.pg_page - 1) : paging.pg_page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginNext (e) {
    let paging = this.state.paging
    paging.pg_page += 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginCur(e) {
    let paging = this.state.paging
    paging.pg_page = e
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  // U P L O A D  E V E N T
  callbackUpload (e, name) {
    this.onBlurData({ target: { value: e.images } }, name)
  }

  callbackUploadDetail (e) {
    this.onBlurDataDetail({ target: { value: e.images } }, 'img_detail')
  }

  onBlurDataDetail (e, name, options, name1, index) {
    options = options || {}
    let editingData = this.state.editingData
    editingData[name] = e.target.value.join(',')
    this.setState({ editingData: editingData })
  }
  // H E L P E R S
  helpProductcat (cats) {
    let result = ''
    if (cats) {
      for (let i = 0; i < cats.length; i++) { result += cats[i].name + ', ' }
      return result
    }
  }

  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

RxCrud.defaultProps = { name: '', api: '', form: [], dataCallback: () => {}, renderPage: () => {}, data: {} }

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth
})

const mapDispatchToProps = {}

const RxCrud_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(RxCrud)
export default RxCrud_
