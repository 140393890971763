import * as Jsx from './review_2.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class review2Component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {}
  }

  render () { return this.renderComponent() }
}

export default review2Component
