// const Home = global.rootRequiretheme('home').default
// const About = global.rootRequiretheme('about').default
// const Payment = global.rootRequiretheme('payment').default

// const Login = global.rootRequiretheme('login').default
const Login = global.rootRequire('components/authenticate/login').default
const Manual = global.rootRequire('components/admin/manual').default
const FAQs = global.rootRequiretheme('app/faqs').default
const Term = global.rootRequiretheme('app/term').default

// const ProductAll = global.rootRequiretheme('product/all').default
// const ProductSingle = global.rootRequiretheme('product/single').default

const NotFound = global.rootRequiretheme('notFound').default

const Card = global.rootRequiretheme('card').default

let Themeroutes = [
  { path: '/',
    exact: true,
    component: Login,
    loadData: 'api_home'
  },
  { path: '/card',
    component: Card,
    loadData: 'api_card'
  },
  { path: '/manual',
    component: Manual
  },
  { path: '/app/faqs',
    component: FAQs
  },
  { path: '/app/term',
    component: Term
  },
  // { path: '/about',
  //   component: About
  // },
  // { path: '/payment',
  //   component: Payment
  // },
  // { path: '/cate/',
  //   exact: true,
  //   component: ProductAll,
  //   loadData: 'api_site_productcate'
  // },
  // { path: '/cate/:slug',
  //   component: ProductAll,
  //   loadData: 'api_site_productcate'
  // },
  // { path: '/product/',
  //   exact: true,
  //   component: ProductSingle,
  //   loadData: 'api_site_product'
  // },
  // { path: '/product/:slug',
  //   component: ProductSingle,
  //   loadData: 'api_site_product'
  // },
  { path: '*',
    component: NotFound
  }]

export default Themeroutes
