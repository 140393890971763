/* global FormData, fetch */
import React, { Component } from 'react'
global.isclient && require('./rxUpload.css')
// var fs = require('fs')
// var request = require('request');

class RxUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
      images: (props.single && props.single === 1) ? props.images : (typeof(props.images) !== 'string')? props.images : props.images.split(',').filter(v => v !== ''),
      path: props.path || '',
      callback: props.callback,
      single: props.single || 0,
      disable: props.disabled ? 1 : 0,
      showFull: false,
      viewImg: false,
      index: 0,
      rotation: 0
    }
    this.state.images = this.state.images ? this.state.images : []
  }
  componentDidMount () {
    document.addEventListener('keydown', event => {
      let imagesArr = this.state.images ? ((typeof(this.state.images) !== 'string')? this.state.images : this.state.images.split(',').filter(v => v !== '')) : []
      if(this.state.viewImg === true && event.keyCode === 27) {
        this.onClickViewImg()
      }
      if(this.state.viewImg === true && imagesArr && imagesArr.length > 1 && event.keyCode === 37) {
        this.onClickArrowLeft()
      }
      if(this.state.viewImg === true && imagesArr && imagesArr.length > 1 && event.keyCode === 39) {
        this.onClickArrowRight()
      }
    });
  }
  onClickDataUpload (e) {
    this.refs.data_image.click()
  }
  onChangeDataImageFile (e) {
    e.stopPropagation()
    e.preventDefault()
    if (typeof (e.target.files[0]) !== 'undefined') {

      let file = e.target.files[0]

      const allowedExtensions = global.allowedExtensions,  // ['jpg','png','pdf','PDF','docx','doc'],
            sizeLimit = global.sizeLimit //  50971520;

      const { name:fileName, size:fileSize } = file;
      const fileExtension = fileName.split(".").pop();

      if(!allowedExtensions.includes(fileExtension.toLowerCase())) {
        alert("Định dạng file không cho phép! Vui lòng chọn đúng định dạng file tải lên : 'jpg','png','pdf','PDF' ");
        file = ''
      }
      else if(fileSize > sizeLimit) {
        alert("Dung lượng file quá lớn! Vui lòng chọn dung lượng file < 20 Mb")
        file = ''
      }
      else {
        global.showProgress()
        let data = new FormData()
        data.append('uploadFile', file, file.name)
        fetch(global.rxu.config.base_api + '/upload', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
          if (json.success === 1) {
            this.onBlurData(json)
          }
        })
        .finally(() => { global.hideProgress() })
        .catch(error => console.log(error))
      }
    }
  }
  onBlurData (json) {
    this.setState({ images: json.data.file, path: json.data.path }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }
  // Array
  onClickDataUploadArr (e, index) {
    if(index){
      this.refs['data_image_' + index].click()
    }
  }
  onChangeDataImageFileArr (e, index) {
    e.stopPropagation()
    e.preventDefault()
    if (typeof (e.target.files[0]) !== 'undefined') {

      let file = e.target.files[0]

      const allowedExtensions = global.allowedExtensions,  // ['jpg','png','pdf','PDF','docx','doc'],
            sizeLimit = global.sizeLimit //  50971520;

      const { name:fileName, size:fileSize } = file;
      const fileExtension = fileName.split(".").pop();

      if(!allowedExtensions.includes(fileExtension.toLowerCase())){
        alert("Định dạng file không cho phép! Vui lòng chọn đúng định dạng file tải lên : 'jpg','png','pdf','PDF'");
        file = ''
      }
      else if(fileSize > sizeLimit) {
        alert("Dung lượng file quá lớn! Vui lòng chọn dung lượng file < 20 Mb")
        file = ''
      }
      else {
        global.showProgress()
        let data = new FormData()
        data.append('uploadFile', file, file.name)
        fetch(global.rxu.config.base_api + '/upload', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
          if (json.success === 1) {
            this.onBlurDataArr(json, index)
          }
        })
        .finally(() => { global.hideProgress() })
        .catch(error => console.log(error))
      }
    }
  }

  onBlurDataArr (json, index) {
    let tempImages = this.state.images
    tempImages[index] = json.data.file
    this.setState({ images: tempImages, path: json.data.path }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }
  onClickImgRemove (e, index) {
    let tempImages = this.state.images
    tempImages.splice(index, 1)
    this.setState({ images: tempImages }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }
  showFullImage(e) {

    if (this.state.disable) {
      let showFull = this.state.showFull
      this.setState({showFull: !showFull})
    }
  }
  onClickViewImg(e, id) {
    let index = this.state.index
    if(id) {
      index = id
    } else {
      index = 0
    }
    this.setState({viewImg: !this.state.viewImg, index: index})
  }
  onClickViewImgIndex(e, id) {
    let index = this.state.index
    if(id) {
      index = id
    } else {
      index = 0
    }
    document.getElementById('rx-image').style['transition-duration'] = '500ms'
    this.setState({index: index})
  }
  rotate(){
     let newRotation = this.state.rotation + 90;
     if(newRotation >= 360){
       newRotation =- 360;
     }
     this.setState({
       rotation: newRotation,
     })
   }

   rotateleft(){
     let newRotation = this.state.rotation - 90;
     if(newRotation >= 360){
       newRotation =- 360;
     }
     this.setState({
       rotation: newRotation,
     })
   }
  onClickArrowRight() {
    let divimage = document.getElementById('rx-image').style.marginLeft
    let number = 0
    if (divimage) {
      number = Number(divimage.replace('px','').replace('%', ''))
      if (number < 0 && (number*(-1)) < ((this.state.images.length - 1)*100)) {
        number = number - 100;
        this.setState({index: Number(this.state.index + 1)})
      } else if((number*(-1)) >= ((this.state.images.length - 1)*100)) {
        return;
      } else {
        number = -100;
        this.setState({index: 1})
      }
    }
    if (divimage === '') {
      document.getElementById('rx-image').style.marginLeft = '0px'
    } else {
      document.getElementById('rx-image').style.marginLeft = number+'%'
      document.getElementById('rx-image').style['transition-duration'] = '500ms'
    }
  }
  onClickArrowLeft() {
    let divimage = document.getElementById('rx-image').style.marginLeft
    let number = 0
    if (divimage) {
      number = Number(divimage.replace('px','').replace('%', ''))
      if (number < 0) {
        number = number + 100
        this.setState({index: Number(this.state.index - 1)})
      } else if(number >= 0) { return; }
    }
    if (divimage === '') {
      document.getElementById('rx-image').style.marginLeft = '0px'
    } else {
      document.getElementById('rx-image').style.marginLeft = number+'%'
      document.getElementById('rx-image').style['transition-duration'] = '500ms'
    }
  }
  getFileTypeIcon(fileType) {
    switch (fileType) {
      case 'pdf':
        return 'assets/img/pdf-file.png';
      case 'zip':
        return 'assets/img/file-zip-format.svg';
      case 'doc':
      case 'docx':
        return 'assets/img/word-file.png';
      case 'xlsx':
        return 'assets/img/excel-file.png';
      default:
      return 'ico_app_default.jpg'
    }
  }
  render () {
    let tmpImgStr
    let tmpImgStrAdd
    let tempImages = (typeof (this.state.images) === 'string') ? this.state.images : ''
    let imagesArr = this.state.images ? ((typeof(this.state.images) !== 'string')? this.state.images : this.state.images.split(',').filter(v => v !== '')) : []
    // let path = (typeof (this.state.path) === 'string') ? this.state.path : ''
    let path = '' // (typeof (this.state.path) === 'string' && this.state.path !== undefined) ? this.state.path : ''

    if (this.state.single === 1) {
      tmpImgStr =
        <div className='rx-upload_cmnd'>
          <div onClick={(e) => this.showFullImage(e)} className={this.state.showFull ? 'fullsize' : ''} style={{margin: 'auto', height: '100%'}}>
          {/* <input type='file' id='file' ref='data_image' style={{ display: 'none' }} onChange={(e) => this.onChangeDataImageFile(e)} /> */}
          { this.state.disable ?
              <input type='file' id='file' ref='data_image' style={{ display: 'none' }} disabled/>
              :
              <input type='file' id='file' ref='data_image' style={{ display: 'none' }} onChange={(e) => this.onChangeDataImageFile(e)} />
          }
          {tempImages && this.state.disable === 0 && <img className={this.props.type && this.props.type === 'avatar' ? 'admin-avatar' : 'admin-product-img-upload'} alt='thenhabao' src={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') +path+ tempImages} onClick={(e) => this.onClickDataUpload(e)} />}
          {tempImages && this.state.disable === 1 && <img className={this.props.type && this.props.type === 'avatar' ? 'admin-avatar' : 'admin-product-img-upload'} alt='thenhabao' src={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') +path+ tempImages } onClick={(e) => this.onClickViewImg(e)} />}
          {!tempImages && !this.state.disable && <div style={{width: '100%', height: '100%'}} onClick={(e) => this.onClickDataUpload(e)}>
            <span className='iconcustom-cmnd icon-cmnd'/>
            <span className='rx-upload_cmnd-title'>{this.props.title}</span>
          </div>}
          {!tempImages && this.state.disable === 1 && <div style={{width: '100%', height: '100%'}}>
            <span className='iconcustom-cmnd icon-cmnd'/>
            <span className='rx-upload_cmnd-title'>{this.props.title}</span>
          </div>}
        </div>
      </div>
    } else if(this.props.type === 'img_multi') {
      tmpImgStr = imagesArr && this.state.disable === 1 && <img className='admin-product-img-upload' alt='thenhabao' src={global.rxu.config.base_api + '/upload/image/' + path + (imagesArr[0] || 'ico_app_default.png')} onClick={(e) => this.onClickViewImg(e, 0)} />
    } else {
      let tempLastIndex = 0
      tmpImgStr = imagesArr && imagesArr.map((perdata, index) => {
        let tempRef = 'data_image_' + index
        let tempHref = perdata
        let timeString = perdata.slice(0, 10)
        let key = false
        if (perdata.indexOf('.pdf') !== -1 ) {
          key = true
          if (timeString && timeString < 1628035200) {
              perdata = 'ico_app_default.png'
          } else {
              perdata = 'pdf-file.png'
          }
        }
        if (perdata.indexOf('.jpeg') !== -1 && timeString && timeString < 1628035200) {
          key = false
          perdata = 'ico_app_default.png'
        }
        tempLastIndex = index
        return (
          <div key={index} className='admin-product-img-multi'>
            {!this.state.disable && <div className='admin-product-img-remove' onClick={(e) => this.onClickImgRemove(e, index)}>x</div>}
            { this.state.disable ?
              <input type='file' id='file' ref={tempRef} style={{ display: 'none' }} disabled/>
              :
              <input type="file" id="file" ref={tempRef} style={{display: "none"}} onChange={(e) => this.onChangeDataImageFileArr(e, index)}/>
            }
            {perdata && this.state.disable === 0 && <img className='admin-image-upload 1' alt='thenhabao' src={global.rxu.config.base_api + '/upload/image/' + path + perdata} onClick={(e) => this.onClickDataUploadArr(e)} />}
            {perdata && this.state.disable === 1 && key &&
              <a href={global.rxu.config.base_api +'/upload/image/'+ path + tempHref} target="_blank" rel="noopener noreferrer"><i className="fas fa-arrow-alt-circle-down file-item-download-button" title="Tải xuống"></i><img className='admin-image-upload 2' alt='thenhabao' src={global.rxu.config.base_api + '/upload/image/' + perdata}  /></a>}
            {perdata && this.state.disable === 1 && !key &&<img className='admin-image-upload 2' alt='thenhabao' src={global.rxu.config.base_api + '/upload/image/' + path + perdata} onClick={(e) => this.onClickViewImg(e, index)} />}
            {!perdata && <span className='iconcustom-cmnd icon-cmnd'/>}
          </div>
        )
      })
      if((!imagesArr || imagesArr.length === 0) && this.state.disable === 1) {
        tmpImgStrAdd = <div className='admin-product-img-multi'><span className='iconcustom-cmnd icon-cmnd'/></div>
      }

      if(!this.state.disable || this.state.disable === 0) {
        tempLastIndex += 1; let tempLastRef = 'data_image_' + tempLastIndex
        tmpImgStrAdd =
          <div key={tempLastIndex} className='admin-product-img-multi'>
            <input type="file" id="file" ref={tempLastRef} style={{display: "none"}} onChange={(e) => this.onChangeDataImageFileArr(e, tempLastIndex)}/>
            {imagesArr[tempLastIndex] && <img className='admin-image-upload' alt='ico_default' src={global.rxu.config.base_api + '/upload/image/' + path + (imagesArr[tempLastIndex]  || 'ico_app_default.png')} onClick={(e) => this.onClickDataUploadArr(e, tempLastIndex) }/>}
            {!imagesArr[tempLastIndex] && <div className='admin-image-add' onClick={(e) => this.onClickDataUploadArr(e, tempLastIndex) }><span className='icon-addImg'>+</span></div>}
          </div>
      }
    }

    return (
      <div>
        <div className='clearfix' style={{margin: 'auto'}}>{tmpImgStr}{tmpImgStrAdd}</div>
        {this.state.viewImg === true && (this.state.single === 1 || (this.state.single === 0 && imagesArr.length < 2)) && <div className='rx-fixed rx-image__full'>
          <div className='rx-image__close iconcustom-Mask-Group-2' onClick={(e) => this.onClickViewImg()}></div>
          {this.state.single === 1 && <div className='rx-image__content img__multi--content'>
            <a href={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') + path + tempImages } target='_blank' rel="noopener noreferrer" download><i className='icon-arrow-down-circle icon__download'/></a>
            <img src={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') + path + tempImages} style={{ transform: `rotate(${this.state.rotation}deg)` }} alt=''/>
          </div>}
          {this.state.single === 0 && imagesArr.length < 2 && <div className='rx-image__content img__multi--content'>
            <a href={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') + path + imagesArr[0]} target='_blank'  rel="noopener noreferrer" download><i className='icon-arrow-down-circle icon__download'/></a>
            <img src={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') + path + imagesArr[0]} style={{ transform: `rotate(${this.state.rotation}deg)` }} alt=''/>
          </div>}

          <div className='rx-image__list'>
            <div style={{justifyContent: 'center'}}>
              <div className='imageRotate'><button onClick={(e) => this.rotate(e)} >Xoay hình</button></div>
              <div className='imageRotate'><button><a href={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') + path + tempImages } style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" download>Tải hình</a></button></div>
            </div>
          </div>

        </div>}
        {this.state.viewImg === true && this.state.single === 0 && imagesArr.length > 1 && <div className='rx-fixed rx-image__full'>
          <div className='rx-image__close iconcustom-Mask-Group-2' onClick={(e) => this.onClickViewImg()}></div>
          <div style={{width: '100%', height: '100%'}}>
            <div className='rx-image__content-full' style={{width: 100*imagesArr.length+'%', marginLeft: Number(this.state.index)*(-100)+'%'}} id='rx-image'>
              {imagesArr && imagesArr.map((perdata, index) => (
                <div key={index} className='img__multi--content'>
                  <img src={global.rxu.config.base_api + '/upload/image/' + path + perdata} className='' style={{ transform: `rotate(${this.state.rotation}deg)` }} alt=''/>
                  {/*<span className='icon-arrow-down-circle icon__download' onClick={(e) => this.onClickDownloadImg('https://www.google.com/images/srpr/logo3w.png', 'google.png', this.done())}/>*/}
                  {<a href={global.rxu.config.base_api + '/upload/image/' + path + perdata } target='_blank' rel="noopener noreferrer" download><i className='icon-arrow-down-circle icon__download'/></a>}
                </div>
              ))}
              {/*<div key={index}>
                <img style={{transform: `rotate(${this.state.rotation}deg)`}} src={global.rxu.config.base_api + '/upload/image/' + perdata} />
              </div>*/}

            </div>
            <div className='rx-image__list'>
              <div style={{justifyContent: 'center'}}>
                <div className='imageRotate'><button onClick={(e) => this.rotate(e)} >Xoay hình</button></div>
                <div className='imageRotate'><button><a href={global.rxu.config.base_api + '/upload/' + (this.props.type === 'img_card' ? 'card/' : 'image/') + path + tempImages } style={{color: '#fff'}} target='_blank' rel="noopener noreferrer" download>Tải hình</a></button></div>
                {imagesArr && imagesArr.map((perdata, index) => (
                  <img key={index} className={this.state.index === index ? '' : 'opacity-img'} src={ global.rxu.config.base_api + '/upload/image/' + path + perdata } alt='' onClick={(e) => this.onClickViewImgIndex(e, index)} />
                ))}
              </div>
            </div>
          </div>
          {imagesArr && imagesArr.length > 0 && <div>
            <span className='rx-image__back icon-arrow-left' onClick={(e) => this.onClickArrowLeft()}/>
            <span className='rx-image__next icon-arrow-right' onClick={(e) => this.onClickArrowRight()}/>
          </div>}
        </div>}
      </div>
    )

  }
}
export default RxUpload
