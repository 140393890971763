import React, { Component } from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
global.isclient && require('./_app.css')


let adminCSS = '<style>.main-container{ width: 100% !important; padding: 0px !important; } .adtopbar{ display: none !important; }</style>'

class Component_ extends Component {
  constructor (props) {
    super(props)
    let d = new Date().getFullYear()
    this.state = {
      copy: `Copyright © ${d} TNB`,
    }
  }

  componentDidMount () {
    
  }

  fetchData () {
    
  }

  onClickItem(id) {
    let item = document.getElementsByClassName("item__content")
    var img = document.getElementById('item-'+id)
    if(item && item.length > 0) {
      for (var i = 0; i <item.length; i++) {
        if(item[i] && i === id && item[i].style.display !== 'block') {
          img.src='images/static/app/up.svg'
          item[id].style.display = 'block'
        } else {
          img.src='images/static/app/down.svg'
          item[i].style.display = 'none'
        }
      }
    }
  }

  render () {
    return (<div>
      {Parser(adminCSS)}
      <div className="content__faq">
        <div className="content__3--body">
          <div className="content__3--title">Những câu hỏi thường gặp</div>
          <hr/>
          <div className="content__3--desc">
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(0)}>Đăng xuất/nhập trong ứng dụng Thẻ nhà báo như thế nào?<img src="images/static/app/up.svg" id="item-0" alt=""/></div>
              <div className="item__content" style={{display: 'block'}}>
                Sau khi mở app ứng dụng Thẻ nhà báo, để đăng nhập, bạn hãy nhập số điện thoại của mình, sau đó bấm chọn “Tiếp tục”. Một tin nhắn chứa mã OTP sẽ được gửi đến số điện thoại của bạn. Trong trường hợp không nhận được tin nhắn này, bạn hãy bấm chọn “Gửi lại” phía bên trái màn hình. Sau khi nhận được và xác thực thành công mã OTP, bạn có thể bắt đầu sử dụng ứng dụng Thẻ nhà báo bằng cách bấm chọn “Bắt đầu chat”.

                Nếu muốn đăng xuất, bạn hãy bấm nút “Tài khoản” <img className="icon-content" src="images/static/app/account.svg" alt=''/> ở góc phải phía dưới màn hình điện thoại. Lúc này, khung thông tin chi tiết tài khoản của bạn sẽ xuất hiện, bạn hãy vuốt xuống dưới đáy màn hình và bấm chọn “Đăng xuất”.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(1)}>Xác thực số điện thoại của bạn như thế nào?<img src="images/static/app/down.svg" id="item-1" alt=""/>
              </div>
              <div className="item__content">
                ứng dụng Thẻ nhà báo xác thực số điện thoại của bạn thông qua mã OTP. Sau khi nhập số điện thoại, một tin nhắn chứa mã OTP sẽ được gửi đến số điện thoại của bạn. Trong trường hợp không nhận được tin nhắn này, bạn hãy bấm chọn “Gửi lại” phía bên trái màn hình. Sau khi nhận được và xác thực thành công mã OTP, bạn có thể bắt đầu sử dụng ứng dụng Thẻ nhà báo bằng cách bấm chọn “Bắt đầu chat”.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(2)}>Làm sao để tạo một nhóm chat?<img src="images/static/app/down.svg" id="item-2" alt=""/></div>
              <div className="item__content">
                Tại trang chính, bấm vào nút “Tin nhắn” <img className="icon-content" src="images/static/app/mesage.svg" alt=''/> tại góc phải trên cùng của màn hình điện thoại. Sau khi tab mới xuất hiện, bấm chọn “Tạo nhóm trò chuyện”. Lúc này, danh bạ của bạn sẽ xuất hiện. Bạn hãy chọn những người mà bạn muốn mời tham gia nhóm chat, sau đó bấm chọn “Xong”.

                Bạn cũng có thể tạo nhóm chat bằng cách bấm chọn một tin nhắn giữa bạn và một người bất kỳ tại trang chính. Khi khung chat xuất hiện, hãy bấm chọn Tên của người đó. Trên tab thông tin chi tiết của người đó, hãy bấm “Tạo nhóm” <img className="icon-content" src="images/static/app/creategroup.svg" alt=''/>. Sau đó, chọn từ danh bạ của bạn người bạn muốn mời tham gia nhóm chat, sau đó bấm chọn “Xong”.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(3)}>Làm sao để thêm một thành viên khỏi danh sách nhóm chat?<img src="images/static/app/down.svg" id="item-3" alt=""/></div>
              <div className="item__content">
                Tại trang chính, chọn một nhóm chat bất kỳ. Khi khung tin nhắn của nhóm chat này hiển thị, hãy bấm vào phần Tên của nhóm chat ở phía trên cùng màn hình điện thoại. Lúc này, một tab hiển thị thông tin chi tiết của nhóm chat sẽ xuất hiện. Bạn hãy bấm chọn “Thêm thành viên” <img className="icon-content" src="images/static/app/Addmember.svg" alt=''/>. Sau khi danh bạ của bạn hiện ra, hãy chọn những người bạn muốn mời tham gia nhóm chat, sau đó bấm chọn “Xong”.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(4)}>Làm sao để xóa một thành viên ra khỏi danh sách nhóm chat?<img src="images/static/app/down.svg" id="item-4" alt=""/></div>
              <div className="item__content">
                ứng dụng Thẻ nhà báo hiện không hỗ trợ tính năng này.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(5)}>Rời khỏi một nhóm chat như thế nào?<img src="images/static/app/down.svg" id="item-5" alt=""/></div>
              <div className="item__content">
                Tại trang chính, chọn một nhóm chat bất kỳ. Khi khung tin nhắn của nhóm chat này hiển thị, hãy bấm vào phần Tên của nhóm chat ở phía trên cùng màn hình điện thoại. Lúc này, một tab hiển thị thông tin chi tiết của nhóm chat sẽ xuất hiện. Bạn hãy bấm chọn “Rời nhóm” để rời khỏi nhóm chat.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(6)}>Làm sao để xem danh sách thành viên trong một nhóm chat?<img src="images/static/app/down.svg" id="item-6" alt=""/></div>
              <div className="item__content">
                Tại trang chính, chọn một nhóm chat bất kỳ. Khi khung tin nhắn của nhóm chat này hiển thị, hãy bấm vào phần Tên của nhóm chat ở phía trên cùng màn hình điện thoại. Lúc này, một tab hiển thị thông tin chi tiết của nhóm chat sẽ xuất hiện. Bạn hãy kéo xuống phía dưới cùng của màn hình để xem danh sách thành viên nhóm chat.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(7)}>Cách khởi tạo một cuộc gọi trên ứng dụng Thẻ nhà báo như thế nào?<img src="images/static/app/down.svg" id="item-7" alt=""/></div>
              <div className="item__content">
                Tại trang chính, bấm vào nút “Cuộc gọi” <img className="icon-content" src="images/static/app/PhoneCall.svg" alt=''/> (nút thứ 2 từ trái qua, nằm phía dưới màn hình điện thoại). Khi tab mới xuất hiện, bấm chọn “Bắt đầu cuộc gọi mới”. Lúc này bạn sẽ được đưa tới trang “Danh bạ”. Sau khi chọn người để gọi, một tab tin nhắn giữa bạn và người đó sẽ xuất hiện. Lúc này, bạn bấm vào nút “Gọi điện” ở phía trên bên phải màn hình điện thoại để thực hiện cuộc gọi.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(8)}>Các cuộc gọi từ ứng dụng Thẻ nhà báo có miễn phí không?<img src="images/static/app/down.svg" id="item-8" alt=""/></div>
              <div className="item__content">
                Các cuộc gọi thông qua ứng dụng Thẻ nhà báo là hoàn toàn miễn phí. Điện thoại của bạn chỉ cần có kết nối Internet hoặc wi-fi là có thể sử dụng được chức năng này.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(9)}>ứng dụng Thẻ nhà báo có hỗ trợ chức năng gọi điện theo nhóm không?<img src="images/static/app/down.svg" id="item-9" alt=""/></div>
              <div className="item__content">
                Hiện tại ứng dụng Thẻ nhà báo chưa hỗ trợ tính năng này.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(10)}>Chia sẻ hình ảnh trên ứng dụng Thẻ nhà báo như thế nào?<img src="images/static/app/down.svg" id="item-10" alt=""/></div>
              <div className="item__content">
                Trong khung tin nhắn giữa bạn với một người bất kỳ, hãy bấm vào nút “Hình ảnh” <img className="icon-content" src="images/static/app/Media.svg" alt=''/> ở góc trái phía dưới màn hình điện thoại. Sau đó, chọn bất kỳ hình ảnh hoặc video nào bạn muốn gửi đi, và bấm nút “Gửi”. ứng dụng Thẻ nhà báo không giới hạn số lượng hình ảnh và video mà bạn muốn gửi đi.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(11)}>Làm thế nào để chỉnh sửa thông tin tài khoản?<img src="images/static/app/down.svg" id="item-11" alt=""/></div>
              <div className="item__content">
                Tại trang chính, bấm vào nút “Tài khoản” <img className="icon-content" src="images/static/app/account.svg" alt=''/> ở góc phải phía dưới màn hình điện thoại của bạn. Lúc này, trang thông tin chi tiết của tài khoản của bạn sẽ xuất hiện để bạn có thể tùy ý chỉnh sửa.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(12)}>Tùy chỉnh thông báo như thế nào?<img src="images/static/app/down.svg" id="item-12" alt=""/></div>
              <div className="item__content">
                Nếu bạn muốn tắt thông báo của một tin nhắn bất kỳ, hãy bấm chọn tin nhắn đó tại trang chính. Sau khi khung tin nhắn hiện ra, hãy bấm vào Tên người bạn đang nhắn tin cùng. Lúc này, một tab hiển thị thông tin chi tiết của người đó sẽ xuất hiện. Bạn hãy bấm nút “Tắt thông báo” <img className="icon-content" src="images/static/app/stop_notification.svg" alt=''/> ở phía dưới màn hình điện thoại.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(13)}>Gửi tin nhắn trên ứng dụng Thẻ nhà báo như thế nào?<img src="images/static/app/down.svg" id="item-13" alt=""/></div>
              <div className="item__content">
                Tại trang chính, bấm vào nút “Tin nhắn” <img className="icon-content" src="images/static/app/mesage.svg" alt=''/> tại góc phải trên cùng trên màn hình điện thoại. Trang “Danh bạ” <img className="icon-content" src="images/static/app/Contact.svg" alt=''/> của bạn sau đó sẽ xuất hiện. Lúc này, chọn một đối tượng từ trong danh sách danh bạ và bắt đầu gửi tin nhắn.

                Ngoài ra bạn có thể trực tiếp bấm vào nút “Danh bạ” <img className="icon-content" src="images/static/app/Contact.svg" alt=''/> (nút thứ 3 từ trái qua, nằm ở phía dưới màn hình điện thoại), sau đó chọn một đối tượng và bắt đầu gửi tin nhắn.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(14)}>Tôi có thể thử nghe tin nhắn giọng nói của mình trước khi gửi đi không?<img src="images/static/app/down.svg" id="item-14" alt=""/></div>
              <div className="item__content">
                Chức năng này hiện vẫn đang được đội ngũ ứng dụng Thẻ nhà báo phát triển.
              </div>
            </div>
            <div className="content__3--item">
              <div className="item__title" onClick={(e) => this.onClickItem(15)}>Gửi một tin nhắn giọng nói như thế nào?<img src="images/static/app/down.svg" id="item-15" alt=""/></div>
              <div className="item__content">
                Tại trang chính, chọn một tin nhắn bất kỳ của bạn. Lúc này, tab tin nhắn giữa bạn và người đã chat với bạn sẽ xuất hiện. Hãy bấm vào nút “Ghi âm” <img className="icon-content" src="images/static/app/Microphone.svg" alt=''/> nằm ở góc trái phía dưới màn hình điện thoại của bạn và bắt đầu ghi âm. Sau khi ghi âm xong, bấm nút “Dừng ghi âm”, sau đó bấm “Gửi” nếu bạn quyết định gửi đi tin nhắn thoại này.
              </div>
            </div>
          </div>
          <div className="footer" style={{position: 'relative', padding: '15px 0'}}>
            <img className="footer__logo" src="/images/static/app/TNB_logo.png" alt=''/>
            <div className="rx-mobile"></div>
            <div className="footer__right">
              <span id="current_year">{this.state.copy}</span>
              <a href="/app/term"><div className="footer__term">Điều khoản sử dụng</div></a>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

const ComponentFaqs = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component_)

export default ComponentFaqs
