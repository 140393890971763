let crypto = require('crypto')
let validate = require('./rxValidate.js')
let rxu = {}

// U L T I L I T Y //
rxu.json = function (str) {
  let result = {}
  try {
    result = JSON.parse(str)
  } catch (e) {}

  return result
}

rxu.clone = function (obj) {
  let result = {}
  try { result = JSON.parse(JSON.stringify(obj)) } catch (e) {}
  return result
}

rxu.filter = function (params, filter) {
  for (let index in params) {
    if (filter.indexOf(index) !== -1) { params.set(index, undefined, { strict: false }) }
  }
  return params
}

rxu.validate = function (params, rules) {
  let returnSum = true
  let returnData = {}
  try {
    for (let index in rules) {
      let tempResult = validate.value(params[index], rules[index])
      if (!tempResult['approved']) {
        returnData[index] = []
        tempResult.each(function (err) { returnData[index].push(err) })
        returnSum = false
      }
    }
    returnData['rxresult'] = returnSum
  } catch (e) { console.log(e) }
  return returnData
}

rxu.validatex = function (params, rules) {
  let returnSum = true
  let returnData = {}

  for (let index in rules) {
    let tempResult = validate.value(params[index], rules[index])
    if (!tempResult['approved']) {
      returnData[index] = []
      tempResult.each(function (err) { returnData[index].push(err) })
      returnSum = false
    }
  }

  return !returnSum ? returnData : null
}

rxu.get = function (variable, keys, defaultVal) {
  defaultVal = defaultVal || ''
  let resultVal = defaultVal

  try {
    // Keys is array of index
    if (Array.isArray(keys)) {
      let tempResult = variable
      for (let i in keys) {
        tempResult = tempResult[keys[i]]
      }
      resultVal = tempResult

    // Keys is a string
    } else {
      keys = keys.split('.')
      let tempResult = variable
      for (let i in keys) {
        tempResult = tempResult[keys[i]]
      }
      resultVal = tempResult
    }

  // Case exception, access undefined variable
  } catch (e) { resultVal = defaultVal }

  // Case undefined after all
  if (resultVal === undefined) { resultVal = defaultVal }

  // HAPPYENDING
  return resultVal
}

// S T R I N G //
rxu.strCapitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

rxu.strToMoney = function (x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
}

rxu.strGen = function (length, source) {
  length = length || 8
  source = source || '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

  let result = ''
  for (let i = length; i > 0; --i) result += source[Math.floor(Math.random() * source.length)]

  return result
}

rxu.strToSlug = function (str) {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  let from = 'âấầậẫẩăằắặẵẳàáäâẩạãêềếệễèéëêẽểẻẹìíïîỉịòóöôỏọơờớợỡởôồốộỗổùúüûủụưừứựữửñçđ·/_,:;'
  let to = 'aaaaaaaaaaaaaaaaaaaeeeeeeeeeeeeeiiiiiioooooooooooooooooouuuuuuuuuuuuncd------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

rxu.strRgbaAddAlpha = function (color, alpha) {
  return color.slice(0, color.length - 1) + ', ' + alpha + ')'
}

// D A T E T I M E //
rxu.now = function (unit) {
  if (typeof process.hrtime !== 'undefined') {
    const hrTime = typeof process.hrtime !== 'undefined' ? process.hrtime() : console.time()
    switch (unit) {
      case 'milli': return hrTime[0] * 1000 + hrTime[1] / 1000000
      case 'micro': return hrTime[0] * 1000000 + hrTime[1] / 1000
      case 'nano': return hrTime[0] * 1000000000 + hrTime[1]
      default: return hrTime[0] * 1000 + hrTime[1] / 1000000
    }
  } else {
    return console.time('debug')
  }
}

rxu.date = function (timestamp, format) {
  format = format || 'yyyy/mm/dd hh:mm:ss'

  let dateObj = timestamp ? new Date(timestamp * 1000) : new Date()
  let year = dateObj.getFullYear()
  let month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  let date = ('0' + dateObj.getDate()).slice(-2)
  let hour = ('0' + dateObj.getHours()).slice(-2)
  let minute = ('0' + dateObj.getMinutes()).slice(-2)
  let second = ('0' + dateObj.getSeconds()).slice(-2)

  let formats = format.split(' ')
  formats[0] = rxu.get(formats, [0]).split('dd').join(date).split('mm').join(month).split('yyyy').join(year)
  formats[1] = rxu.get(formats, [1]).split('ss').join(second).split('mm').join(minute).split('hh').join(hour)

  return `${formats[0]} ${formats[1]}`.trim()
}

rxu.dateViaObj = function (dateobj, format) {
  format = format || 'yyyy/mm/dd hh:mm:ss'

  let dateObj = dateobj || new Date()

  let year = dateObj.getFullYear()
  let month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  let date = ('0' + dateObj.getDate()).slice(-2)
  let hour = ('0' + dateObj.getHours()).slice(-2)
  let minute = ('0' + dateObj.getMinutes()).slice(-2)
  let second = ('0' + dateObj.getSeconds()).slice(-2)

  let formats = format.split(' ')
  formats[0] = rxu.get(formats, [0]).split('dd').join(date).split('mm').join(month).split('yyyy').join(year)
  formats[1] = rxu.get(formats, [1]).split('ss').join(second).split('mm').join(minute).split('hh').join(hour)

  return `${formats[0]} ${formats[1]}`.trim()
}

rxu.convertTimestamp = function (timestamp) {
  let d = new Date(timestamp * 1000)
  let yyyy = d.getFullYear()
  let mm = ('0' + (d.getMonth() + 1)).slice(-2)
  let dd = ('0' + d.getDate()).slice(-2)
  let hh = d.getHours()
  let h = hh

  let min = ('0' + d.getMinutes()).slice(-2)
  let ampm = 'AM'

  let time

  if (hh > 12) {
    h = hh - 12
    ampm = 'PM'
  } else if (hh === 12) {
    h = 12
    ampm = 'PM'
  } else if (hh === 0) {
    h = 12
  }

  // ie: 2013-02-18, 8:35 AM
  time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm

  return time
}

rxu.timeFromTimestamp = function (timestamp = 0, timezone = 7, showSecond = 0) {
  timestamp += timezone * 3600
  timestamp = timestamp % 86400

  // Get hours
  let hours = Math.floor(timestamp / 3600)

  // Get minutes
  let minutes = Math.floor((timestamp - (hours * 3600)) / 60)

  // Get seconds
  let seconds = timestamp - (hours * 3600) - (minutes * 60)
  let returnSecond = showSecond ? ':' + seconds.toString().padStart(2, '0') : ''

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${returnSecond}`
}

rxu.durationFromTimestamp = function (timestamp = 0, showSecond = 0) {
  timestamp = timestamp >= 0 ? timestamp : (24 * 3600) + timestamp

  // Get hours
  let hours = Math.floor(timestamp / 3600)

  // Get minutes
  let minutes = Math.floor((timestamp - (hours * 3600)) / 60)

  // Get seconds
  let seconds = timestamp - (hours * 3600) - (minutes * 60)
  let returnSecond = showSecond ? seconds.toString().padStart(2, '0') + 's' : ''

  return `${hours.toString()}h${minutes.toString()}'${returnSecond}`
}

// A S Y N C //
rxu.to = function (promise) {
  return promise.then(data => {
    return [null, data]
  }).catch(err => [err])
}

rxu.toex = function (obj, func, ...params) {
  return rxu.to(new Promise((resolve, reject) => {
    obj[func](...params, (result) => {
      resolve(result)
    })
  }))
}

rxu.runAsync = function (orm, func, ...params) {
  return new Promise((resolve, reject) => {
    orm[func](...params, (err, result) => {
      if (err) { reject(err) } else { resolve(result) }
    })
  })
}

rxu.runAsyncTo = function (orm, func, ...params) {
  return rxu.to(rxu.runAsync(orm, func, ...params))
}

// H A S H //
rxu.md5 = function (strsource) {
  let passHash = crypto.createHash('md5').update(strsource).digest('hex')
  return passHash
}

rxu.genhex = function () {
  let newToken = crypto.randomBytes(64).toString('hex')
  return newToken
}

// E N C R Y P T  -  D E C R Y P T //
// S Y S T E M //
rxu.log = function (msg) {
  console.log(msg)
}

rxu.emit = function (reqObj, e) {
  e = e || {}
  setImmediate(() => { reqObj.res.inthis.emit('error', reqObj.req, reqObj.res, {}, e) })
}

// A R R A Y
rxu.arrayFromTo = function (rxfrom, rxto) {
  let result = []
  for (let i = rxfrom; i <= rxto; i++) {
    result.push(i)
  }

  return result
}

rxu.array = function (rxarray) {
  let result = Array.isArray(rxarray) ? rxarray : []
  return result
}

// B U I L D I N //
module.exports = rxu
