import React, { Component } from 'react'
global.isclient && require('./rxshowPopup.css')

class RxToggle extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      value: this.props.value
    }
  }

  onCancel (e) {
    this.props.onCancel()
    e.stopPropagation()
    try {
      this.props.onCancel()
    } catch (e) {}
  }

  render () {
    return (
      <div className='popup__main'>
        <div className='popups' onClick={(e) => this.onCancel(e)}></div>
        <div className='popup'>
          <div className='popup__body'>
            <div className='popup__main'>
              {this.state.value}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RxToggle.defaultProps = { onCancel: () => {}, value: '' }

export default RxToggle
