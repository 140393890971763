/* global rxu */
import * as Jsx from './print.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class productComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
    	idDataEdit: [],
    }
    console.log();
  }
  helperSortData(data) {
    for(let i in data) {
       let item = data[i]
       if (item.TenCoQuanBaoChi[0].indexOf('Tạp chí Bóng đá') !== -1) {
         data.splice(i, 1);
         data.unshift(item)

       }
    }
    return data
  }
  countNumber(perdata) {
    let countNumber = 0
    let data = rxu.get(perdata,'data', [])
    // eslint-disable-next-line
    data.map(i => {countNumber += i.number })
    return countNumber
  }

  render () { return this.renderComponent() }
}

export default productComponent
