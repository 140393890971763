/* global t */
import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderTableHead = (context, vm) =>
  <tr className='rx-title__table'>
    <th className={context.helpSortClass('Name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Name')}>Tên cục</th>
    <th className={context.helpSortClass('Adress', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Adress')}>Địa chỉ</th>
    <th className={context.helpSortClass('Phone', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Phone')}>Số điện thoại</th>
    <th className={context.helpSortClass('Fax', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Fax')}>Số fax</th>
    <th className={context.helpSortClass('is_active', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'is_active')}>Trạng thái</th>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && <th className='rxwidth--100'>{t('Action')}</th>}
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td>{perdata.Name}</td>
    <td>{perdata.Adress}</td>
    <td>{perdata.Phone}</td>
    <td>{perdata.Fax}</td>
    <td>{global.rxStatus[perdata.is_active]}</td>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='QUẢN LÝ CỤC' desc=''>
    <div className='rxcol-100'>
      <RxCrud renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name={'office'} api={'api_office'} form={[
        { type: 'devide' },
        { name: 'Tên cục', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Name} onChange={(e) => context.onBlurData(e, 'Name')} className='fullwidth-input' />) },
        { name: 'Địa chỉ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Adress} onChange={(e) => context.onBlurData(e, 'Adress')} className='fullwidth-input' />) },
        { name: 'Số điện thoại', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Phone} onChange={(e) => context.onBlurData(e, 'Phone')} className='fullwidth-input' />) },
        { name: 'Số fax', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Fax} onChange={(e) => context.onBlurData(e, 'Fax')} className='fullwidth-input' />) },
        { name: 'Trưởng/Phó phòng BCĐP', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.TruongPhoPhongBCDP} onChange={(e) => context.onBlurData(e, 'TruongPhoPhongBCDP')} className='fullwidth-input' />) },
        { name: 'Lãnh đạo cục', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.LanhDaoCuc} onChange={(e) => context.onBlurData(e, 'LanhDaoCuc')} className='fullwidth-input' />) },
        { name: 'Lãnh đạo bộ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.LanhDaoBoTTTT} onChange={(e) => context.onBlurData(e, 'LanhDaoBoTTTT')} className='fullwidth-input' />) },
        // [[RX_WEB_DATA_CUSTOM]] //
        // [[RX_WEB_DATA_CUSTOM_END]] //
        { type: 'devide' },
        { name: 'Trạng thái', func: (context) => (<RxToggle value={context.state.editingData.is_active} onToggle={(newValue) => context.onBlurDataValue(newValue, 'is_active')} />) }
      ]} />
    </div>
  </AdminBlock>
