/*eslint-disable */
import * as Jsx from './rxCrudCard.jsx'
import * as xlsx from 'xlsx'
import { connect } from 'react-redux'
// import SaveAs from 'file-saver'

global.isclient && require('./rxCrud.css')
global.isclient && require('jsoneditor/dist/jsoneditor.css')

const { apiget, apipost, rxgetLocal, rxJson } = global.rootRequire('classes/request')
const RxComponent = global.rootRequire('components/shares/rxComponent').default
const rxu = global.rxu
const WAIT_INTERVAL = 500

const SYMB = [',',';','(','-','.','và']

class RxCrudCard extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this._mounted = false
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    // console.log(user,'----')
    this.state = {
      filterName: '',
      showPopup: false,
      flagUpdate: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      pagingCat: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      pagingPahse: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      pagingPos: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      pagingProvince: { st_col: 'code', st_type: 1, pg_page: 1, pg_size: 63 },
      editingData: {},
      editingDataPhase: {},
      editingDataPosition: {},
      editingDataPrint:[],
      editingDataReview: {},
      editData:  {},
      idDataEdit: [],
      importData: [],
      extra: {},
      data: {},
      member: {},
      flags: {},
      temp: {},
      cate: [],
      position: [],
      HinhThucDaoTao: [],
      TruongHoc: [],
      NganhHoc: [],
      TrinhDoChuyenMon: [],
      title: [],
      province: [],
      view: false,
      activeTab: 'label1',
      action: '', // duyet, khongduyet
      renderForm: false,
      hasPer: false,
      checkAll: false,
      printCard: false,
      activeStep: false,
      showInput: false,
      editUpload: false,
      type: this.props.type || 0,
      isSubmit: true, showPhase: false, showPosition: false, typePos: '',
      // role : global.rxu.get(props.auth, ['user','user','role'], 'member'),
      role : global.rxu.get(user, ['user','role'], 'member'),
      temparr: [{IsCheck: false},{IsCheck: false},{IsCheck: false},{IsCheck: false},{IsCheck: false},{IsCheck: false},{IsCheck: false},{IsCheck: false}],
      errMsg: {},
      ERR_MSG: [],
      msg: '',
      disableButton: true,
      loading: false,
      rxshowPopup: false,
      numCheck: {},
      showHistory: false, dataLichSuDuyet: [], activeHistory: '',
      dataCheck: {}, dataNonCheck: {},
      typePrint: '',
      // fontPrintName: 9
      fontPrint: {HoTenKhaiSinh: 9, TenCoQuanBaoChi: 9, NgayCap: 1609459200, NgayHetHan: 1767200399},
      showPopupClone: false,
      statusPhanLoaiClone: '',
      message: '',
      ngNhanDoiThe: '',
      showPopupNewDataCapLai: '',
      showPopupNewDataCapDoi: '',
      maTheTimKiem: '',
      showBtnTaoMoiHS: '',
      showBtnCreateNew: '',
      checkHadCard: false
    }
    this.onChangeContentCKE = this.onChangeContentCKE.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }
  componentDidMount () {
    this._mounted = true
    this.fetchAlldata()
    this.checkpermission()
    // let scope = this
    // window.onafterprint = function(event) {
    //   event.preventDefault()
    //   scope.setState({printCard: false, typePrint: ''})
    // }
  }
  componentWillUnmount () {
    this._mounted = false
    super.componentWillUnmount()
    if (this.jsoneditor) { this.jsoneditor.destroy() }
  }
  checkpermission() {
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    let role = global.rxu.get(user, 'user.user.role', 'member')
    let hasPer = ['member'].indexOf(role) !== -1 ? false : true
    this._mounted = true
    this.setStateRx({hasPer: hasPer})
  }
  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }
  async checkAllPrint(e) {
    const allCheckBox = document.getElementById('HoSoID-All').checked
    var listId = []
    if(allCheckBox === true){
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = true
                // await listId.push(JSON.parse(check[i].value))
            }
        }
        listId = this.state.data
        this.setState({editingDataPrint: listId})
    }else{
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = false
            }
        }
        this.setState({editingDataPrint: [] })
    }
  }

  async checkObjectPrint(e, obj) {
    var listId = []
    let editingDataPrint = this.state.editingDataPrint

    if (editingDataPrint && editingDataPrint.constructor === Array) {
      let index = editingDataPrint.findIndex(o => o._id.toString().indexOf(obj._id.toString()) !== -1)
      if(index === -1) {
        editingDataPrint.push(obj)
      } else {
        editingDataPrint = editingDataPrint.filter(o => o._id.toString().indexOf(obj._id.toString()) === -1)
      }
    }

    this.setState({editingDataPrint: editingDataPrint})
  }
  componentDidUpdate (prevProps, nextState) {
    this._mounted = true
    if ([false, true].indexOf(prevProps.flagUpdate) !== -1 && prevProps.flagUpdate !== this.state.flagUpdate) {
      this.setStateRx({ flagUpdate: prevProps.flagUpdate, showPopup: nextState.showPopup, checkAll: nextState.checkAll, temparr: nextState.temparr, type: nextState.type })
      this.run('parentUpdate', {})
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.type !== this.state.type){
      this._mounted = true
      let type = nextProps.type
      this.setStateRx({type: type, view: false, editingData: {} }, () => this.fetchData() )
    }
  }
  async clearFilter() {
    let paging = { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    this.setState({paging: paging});
    await this.fetchData()
  }
  // D A T A
  fetchAlldata () {
    this.fetchData();
    if(this.props.name === 'Card') {this.fetchCate(); this.fetchPosition(); this.fetchPhase(); this.fetchProvince() }
  }
  fetchData (runCallback = false) {
    this._mounted = true
    global.scrolltop()
    let paging = this.state.paging
    let name   = this.props.name
    let type = this.props.type

    if (type && type !== undefined && type.indexOf('?type=') !== -1)  {
      type = type.replace('?type=','')
      paging.search_StatusPhanLoai = type
    } else {
      delete paging.search_StatusPhanLoai
      if (paging.search_StatusPhanLoai && paging.search_StatusPhanLoai === -1) {
        delete paging.search_StatusPhanLoai
      }
    }

    switch (name) {
      case 'review_1':
        paging.search_IsStatus = 1
        break;
      case 'review_2':
        paging.search_IsStatus = 2
        break;
      case 'review_3':
        paging.search_IsStatus = 3
        break;
      case 'review_4':
        paging.status_phase = 3
        break;
      case 'print':
        paging.status_phase = 4
        // paging.search_IsCheck5 = 1
        break;
      case 'return':
        paging.status_phase = 4
        break;
      case 'unconfirm':
        paging.status_phase = 5
        break;
      default:

    }

    this.setStateRx({loading: false}, () => {
      apiget(rxu.get(rxu.config, this.props.api), paging, {
        '1': (json) => {
          // let data = (name === 'return' || name === 'print') ? json.data.dbarr1 : json.data
          this.setStateRx({ data: json.data, extra: json.extra, loading: false }, () => {
            if (!rxu.get(this.state, ['temp', 'filterFields'])) {
              let temp = this.state.temp; temp.filterFields = rxu.get(this.state, ['data', 0])
              this.setStateRx({ temp })
            }
          })
        }
      })
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  fetchCate (runCallback = false) {
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    let pagingCat = this.state.pagingCat
    pagingCat['search_Type'] = global.rxu.get(user, 'user.user.Type', 1)
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), pagingCat, {
      '1': (json) => {
        this.setStateRx({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  fetchPhase (runCallback = false) {
    let pagingPahse = this.state.pagingPahse
    pagingPahse['search_submit'] = 0
    apiget(rxu.get(rxu.config, 'api_cate'), pagingPahse, {
      '1': (json) => {
        this.setStateRx({ phase: json.data })
      }
    })
  }
  fetchPosition (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_position_getcard'), this.state.pagingPos, {
      '1': (json) => {
        this.setStateRx({
          position: json.data.position,
          title: json.data.title,
          HinhThucDaoTao: json.data.HinhThucDaoTao,
          TruongHoc: json.data.TruongHoc,
          NganhHoc: json.data.NganhHoc,
          TrinhDoChuyenMon: json.data.TrinhDoChuyenMon
        })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  fetchProvince (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_province'), this.state.pagingProvince, {
      '1': (json) => {
        this.setStateRx({ province: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }
  onClickTabItem(e,tab) {
    this.setStateRx({ activeTab: tab })
  }
  toggle (flagname) {
    let flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setStateRx({ flags: flags })
  }
  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }
  // C L I C K   E V E N T
  onClickData (e, perdata) {}
  onClickSort (e, stcol) {
    let paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setStateRx({ paging: paging }, () => { this.fetchData() })
  }
  onClickExcelUpload(flagname) {
    let flags = this.state.flags
    if(!flags[flagname]) { flags[flagname] = false }
    flags[flagname] = true
    this.setStateRx({flagname: flagname})
  }
  onClickDeleteData(e, dataDelete) {
    let params = {_id: dataDelete._id, phase_id: dataDelete.phase_id || ''}
    apiget(rxu.get(rxu.config, 'api_card_delete'), params, {
      '1': (json) => { this.setStateRx({loading: false}, () => {this.fetchData(true); this.onClickDataEdit({}, {}) } ) },
      'default': (json) => {
        let errMsg = [json.success.toString()]
        this.setStateRx({loading: false, errMsg: errMsg, msg: {success: true }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
      },
    })
  }
  onClickDataImport(e) {
    let scope = this
    let reader = new FileReader()
    let fileDom = e.target || e.srcElement

    if(fileDom.value !==  '') {
      let file = fileDom.files[0]
      if(typeof file !== 'undefined') {
        reader.onload = function(e) {
          let datatmp = new Uint8Array(e.target.result);
          let workbook = xlsx.read(datatmp, {type: 'array'});
          workbook.SheetNames.forEach((sheetName) => {
            console.log(sheetName)
            if (['TCMDBO11','TCMDHOI111','TCMD1','CT'].indexOf(sheetName) !== -1) {
              console.log(1111)
              var data = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName])
              console.log(data)
              if(data && data[0]) {
                console.log(data.length)
                scope.setStateRx({importData: data})
              } else {
                alert('File sai định dạng')
              }
            } else {
              console.log(2)
              var data_ = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName])
              console.log(data_)
              if(data_ && data_[0]) {
                scope.setStateRx({importData: data_})
              } else {
                alert('File sai định dạng')
              }
            }

          })
        }
        reader.readAsArrayBuffer(file)
      }
    }
    else {}
  }
  onClickSubmitExcelUpload() {
    let importData = this.state.importData
    importData = JSON.stringify(importData)
    let ob = {data: importData}
    apipost(rxu.get(rxu.config, this.props.api) + '/importExcel', ob, {
      '1': (json) => {
        this.setStateRx({importData: [] }, () => this.fetchData(true))
        if(json.data && json.data.length > 0){
          console.log('có '+json.data.length+' sản phẩm đã tồn tại')
        }
      }
    })
  }
  onClickDataExport (e) {
    apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
      '1': (json) => {
        if(['print', 'statistics'].indexOf(this.props.name) === -1) { global.JSONToCSVConvertor(json.data, '') }
          if(this.props.name === 'statistics') { global.ExportReport(json.data, '') }
      }
    })
  }
  onClickDataExport6 (e) {
    global.PrintMauDanhSachTaoThe(e)
  }
  onClickDataExport3(e) {
    let paging = {searchid_phase_id: this.state.editingData._id, search_IsUpdateProfile: 1, paging: 1000}
    if (this.state.editingData.id) {
      paging = {searcharr_phase_id: this.state.editingData.id, search_IsUpdateProfile: 1, paging: 1000, st_type: 1, st_col: 'MaSoThe'}
    }
    apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), paging, {
      '1': (json) => {
        let sortData = json.data
        if(this.props.name === 'review_8') {
          let JsonData = {CoQuanBaoChiObj: global.rxu.get(this.state, 'editingData.CoQuanBaoChiObj.0'), total_data: global.rxu.get(json, 'extra.count')}
          global.JSONToCSVConvertor8(JsonData, this.state.numCheck, this.state.dataCheck, this.state.dataNonCheck)
        } else if(this.props.name === 'return') {
          sortData = sortData.filter(x => x.IsCheck4 === 1)
          let JsonData = {CoQuanBaoChiObj: global.rxu.get(this.state.editingData, ['CoQuanBaoChiObj',0], ''), data: sortData, total_data: global.rxu.get(this.state.dataCheck, 'check').length}
          global.ExportReturn(JsonData)
        } else {
          let JsonData = {CoQuanBaoChiObj: global.rxu.get(this.state.editingData, ['CoQuanBaoChiObj'], ''), data: sortData}
          global.JSONToCSVConvertor3(JsonData)
        }
      }
    })
  }
  onClickDataExport2(e) {
    global.JSONToCSVConvertor2((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : this.state.editingData)
  }
  onClickDataExport1(e, num) {
    let data = this.state.editingData
    data.HoTenKhaiSinh = global.rxu.get(data, 'HoTenKhaiSinh','').toUpperCase()
    num === 5 ? global.JSONToCSVConvertorMau5((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : this.state.editingData) : global.JSONToCSVConvertor1((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : data)
  }
  onClickDataExport4(e) {
    // global.PrintDocMauCapLaiThe((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : this.state.editingData)
    if (this.state.editingDataReview['LyDoCapLai'] == 2 || this.state.editingData['LyDoCapLai'] == 2) {
      global.JSONToCSVConvertorMau7((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : this.state.editingData)
    } else {
      global.JSONToCSVConvertorMau6((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : this.state.editingData)
    }
  }
  onClickDataExport5(e) {
    global.PrintDocMauDoiThe((this.props.name === 'print' || this.props.name.indexOf('review') !== -1) ? this.state.editingDataReview : this.state.editingData)
  }
  onClickSaveInfo (e) {
    global.PrintInfo({}, '');
  }
  onClickDataDeleteAll (e) {
    let r = window.confirm('Bạn muốn xoá tất cả dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, 'api_card_delete_all'), {}, {
        '1': (json) => {   this.fetchData(true) }
      })
    } else {}

  }
  onClickDataNew (e) {
    global.scrolltop()
    let timeStr = Date.now().toString()
    let clone = { name: this.props.name + '_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1,TrinhDoChuyenMon: [], TrinhDoChuyenMonID: [], TruongHocID: [], TruongHoc: [],
                  NganhHoc:[],NganhHocID:[],HinhThucDaoTao:[],HinhThucDaoTaoID: [] }
    if(this.props.name === 'Card' ) {
      let type = this.props.type
      type = (type && type !== undefined && type.indexOf('?type=') !== -1) ? type.replace('?type=','') : 1
      let info = rxJson(rxgetLocal('rxuser'))
      let user = global.rxu.get(info, ['user', 'user'], {})

      if(user.role === 'member' && user.CoQuanBaoChi) {
        clone['TenCoQuanBaoChi'] = user.CoQuanBaoChi
        clone['CoQuanBaoChiID']  = user.CoQuanBaoChiID[0] || user.CoQuanBaoChiID
        clone['LoaiCoquan']      = user.Type === 2 ? 3 : user.LoaiCoquan || 1
        clone['Type']            = user.Type
      }

      clone['StatusPhanLoai'] = Number(type)
      clone.GioiTinh = '1'
      clone.DangVien = 'Đoàn Viên'
      clone.NgayCapCMND = new Date() / 1000
      clone.QuaTrinhHoatDongUpdate = []
      for(let i = 0; i < 1; i++) {
        clone.QuaTrinhHoatDongUpdate.push({key: i, time_start:new Date() / 1000,time_end:new Date() / 1000, ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''})
      }
      clone.DanhSachTacPham = [{key: 0, Ten: '', LoaiHinhBC: '', TacGia: '', NgayDang: '', CoQuanDang: ''}]
      clone.reception_date = new Date() / 1000
      clone.appointment_date = new Date() / 1000

      let start = new Date().setHours(0, 0, 0, 0) * 1000
      clone.NgaySinh = start.toString()

    }
    this.setStateRx({ editingData: clone ,activeStep: false })
  }
  onClickBtnNew (e) {
    if(this.props.type == "?type=6" || this.props.type == "?type=2"){
      // this.setState({idCoQuan: this.props.auth?.user?.user?.CoQuanBaoChiID[0] || ''})
      if(this.props.type == "?type=6"){
        this.setState({showPopupNewDataCapDoi: true})
      }else if(this.props.type == "?type=2"){
        this.setState({showPopupNewDataCapLai: true})
      }

    }else{
      this.onClickDataNew(e)
    }
  }
  checkActiveStep(step,index) {
    const { ischeck } = 1
    step = Number(step)
    if (ischeck === step) {
      this.temparr[index].IsCheck = true
      return this.temparr
    } else {
      this.temparr[index].IsCheck = false
      return this.temparr
    }
  }

  onClickDataEdit (e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    let renderForm = false
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})

    if((['print', 'return'].indexOf(this.props.name) !== -1 || this.props.name.indexOf('review') !== -1) && perdata && perdata._id) { renderForm = true } else { renderForm = false }
    if((this.props.name.indexOf('review') !== -1 || ['print', 'return'].indexOf(this.props.name) !== -1) && clone._id && !this.state.view) {
      let paging = {searchid_phase_id: this.props.name === 'review_8' ? clone.id : clone._id, search_IsUpdateProfile: 1}
      if (this.props.name === 'print') { paging.search_IsStatus = 4; paging.IsCheck4 = 1 }
      if (this.props.name === 'return') { paging.search_IsStatus = 4; }
      apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), paging, {
        '1': (json) => {
          let numCheck = this.state.numCheck || {}
          let dataCheck = this.state.dataCheck
          let dataNonCheck = this.state.dataNonCheck

          if (json.data && json.data.constructor === Array) {
            let sortData = json.data

            switch (role) {
              case 'viewer':
              case 'chuyenviendp':
              case 'chuyenvientw':
              case 'chuyenvienpt':
              case 'chuyenvienkql':
                numCheck.check     = sortData.filter(x => x.IsCheck1 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck1 === 0)
                dataNonCheck.check = []

              break;

              case 'lanhdaodp':
              case 'lanhdaotw':
              case 'lanhdaopt':
              case 'lanhdaodonvi':
              case 'lanhdaokql':
                numCheck.check     = sortData.filter(x => x.IsCheck1 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck1 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck1 === -1)
              break;
              case 'Super':
              case 'lanhdaocucbcdp':
              case 'lanhdaocucbctw':
              case 'lanhdaocucptth':
              case 'lanhdaocuc01':
              case 'lanhdaocuckql':
                sortData           = sortData.filter(x => x.is_done !== 3)
                numCheck.check     = sortData.filter(x => x.IsCheck3 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck3 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck3 === -1)

              break;

              case 'lanhdaobo':
                numCheck.check     = sortData.filter(x => x.IsCheck3 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck3 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck3 === -1)
              break;

              default:

            }
            numCheck.check1 = json.data.filter(x => x.IsCheck1 !== 0).length
            numCheck.check2 = json.data.filter(x => x.IsCheck2 !== 0).length
            numCheck.check3 = json.data.filter(x => x.IsCheck3 !== 0).length
            numCheck.check4 = json.data.filter(x => x.IsCheck4 !== 0).length
            numCheck.check5 = json.data.filter(x => x.IsCheck5 !== 0).length

            clone['dataCard'] = sortData
          }
          console.log(clone);
          this.setState({editingData: clone, numCheck: numCheck, dataCheck: dataCheck, dataNonCheck: dataNonCheck}) }
      })
    }
    this.setStateRx({ editingData: clone,editUpload: false, flags: {},activeStep: false, view: false, renderForm: renderForm, printCard: false, editingDataPrint: [], editingDataReview:{}, errMsg: {}, ERR_MSG: []})
  }

  onClickDataEditUpload (e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    let renderForm = false
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})
    this.setStateRx({ editingData: clone,editUpload: true, flags: {}})
  }

  onClickGroupEdit (e, perdata) {
    global.scrolltop()
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    let renderForm = false
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})

    if((['print', 'return'].indexOf(this.props.name) !== -1 || this.props.name.indexOf('review') !== -1) && perdata && perdata._id) { renderForm = true } else { renderForm = false }
    if((this.props.name.indexOf('review') !== -1 || ['print', 'return'].indexOf(this.props.name) !== -1) && clone._id && !this.state.view) {
      let paging = {searcharr_phase_id: clone.id , search_IsUpdateProfile: 1}
      if (this.props.name === 'print') { paging.search_IsStatus = 4; paging.IsCheck4 = 1 }
      if (this.props.name === 'return') { paging.search_IsStatus = 4; }

      apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), paging, {
        '1': (json) => {
          let numCheck = this.state.numCheck || {}
          let dataCheck = this.state.dataCheck
          let dataNonCheck = this.state.dataNonCheck
          let sortData = json.data
          if (json.data && json.data.constructor === Array) {

            switch (role) {
              case 'viewer':
              case 'chuyenviendp':
              case 'chuyenvientw':
              case 'chuyenvienpt':
              case 'chuyenvienkql':
                numCheck.check     = sortData.filter(x => x.IsCheck1 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck1 === 0)
                dataNonCheck.check = []
              break;
              case 'lanhdaodp':
              case 'lanhdaotw':
              case 'lanhdaopt':
              case 'lanhdaodonvi':
              case 'lanhdaokql':
                numCheck.check     = sortData.filter(x => x.IsCheck1 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck1 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck1 === -1)
              break;
              case 'Super':
              case 'lanhdaocucbcdp':
              case 'lanhdaocucbctw':
              case 'lanhdaocucptth':
              case 'lanhdaocuc01':
              case 'lanhdaocuckql':
                numCheck.check     = sortData.filter(x => x.IsCheck3 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck3 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck3 === -1)
              break;
              case 'lanhdaobo':
                numCheck.check     = sortData.filter(x => x.IsCheck3 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck3 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck3 === -1)
              break;

              default:

            }
            numCheck.check1 = json.data.filter(x => x.IsCheck1 !== 0).length
            numCheck.check2 = json.data.filter(x => x.IsCheck2 !== 0).length
            numCheck.check3 = json.data.filter(x => x.IsCheck3 !== 0).length
            numCheck.check4 = json.data.filter(x => x.IsCheck4 !== 0).length
            numCheck.check5 = json.data.filter(x => x.IsCheck5 !== 0).length

          }
          clone['dataCard'] = sortData; this.setState({editingData: clone, numCheck: numCheck, dataCheck: dataCheck, dataNonCheck: dataNonCheck}) }
      })

    }
    this.setStateRx({ editingData: clone, flags: {},activeStep: false, view: false, renderForm: renderForm, printCard: false, editingDataPrint: [], editingDataReview:{}, errMsg: {}, ERR_MSG: []})
  }

  onClickViewData(e, perdata, name) {
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})
    if (['viewer'].indexOf(role) === -1 ) {
      global.scrolltop()
      let activeStep = true
      if(name && (name === 'print' || name === 'return' || name.indexOf(('review') !== -1))) {
        if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
        let clone = rxu.clone(perdata)
        this.setStateRx({ editingDataReview: clone, view: true, activeStep: activeStep })
      } else {
        if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
        let clone = rxu.clone(perdata)
        this.setStateRx({ editingData: clone, view: true, activeStep: activeStep })
      }
    }
  }
  onBlurDataFilterName(e, name) {
    this.setStateRx({filterName: e.target.value})
  }
  onBlurDataFilterNameSubmit(e) {
    let name = e.target.value
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})

    let paging = {searcharr_phase_id: this.state.editingData._id , search_IsUpdateProfile: 1, search_HoTenKhaiSinh:  name}
    clearTimeout(this.timerDatafilter);
    this.timerDatafilter = setTimeout((e, name) => {
      apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), paging, {
        '1': (json) => {
          let numCheck = this.state.numCheck || {}
          let dataCheck = this.state.dataCheck
          let dataNonCheck = this.state.dataNonCheck
          let sortData = json.data
          if (json.data && json.data.constructor === Array) {

            for(let i in sortData) {
              // let x = sortData[i].TenChucVu.toLowerCase()
              let butdanh = sortData[i].ButDanh
              let MaTheCu = sortData[i].MaTheCu
              let symb = [',',';','-','.','và']
              for(let k in symb) {
                if (butdanh.indexOf(symb[k]) !== -1) {
                    butdanh = butdanh.split(symb[k])
                    sortData[i].ButDanh = butdanh[0]
                }
                if (MaTheCu.indexOf(symb[k]) !== -1) {
                    MaTheCu = MaTheCu.split(symb[k])
                    sortData[i].MaTheCu = MaTheCu[0]
                }
              }

            }

            switch (role) {
              case 'viewer':
              case 'chuyenviendp':
              case 'chuyenvientw':
              case 'chuyenvienpt':
              case 'chuyenvienkql':
                numCheck.check     = sortData.filter(x => x.IsCheck1 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck1 === 0)
                dataNonCheck.check = []

              break;

              case 'lanhdaodp':
              case 'lanhdaotw':
              case 'lanhdaopt':
              case 'lanhdaodonvi':
              case 'lanhdaokql':
                numCheck.check     = sortData.filter(x => x.IsCheck1 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck1 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck1 === -1)
              break;
              case 'Super':
              case 'lanhdaocucbcdp':
              case 'lanhdaocucbctw':
              case 'lanhdaocucptth':
              case 'lanhdaocuc01':
              case 'lanhdaocuckql':
                numCheck.check     = sortData.filter(x => x.IsCheck3 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck3 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck3 === -1)
              break;

              case 'lanhdaobo':
                numCheck.check     = sortData.filter(x => x.IsCheck3 !== 0).length
                dataCheck.check    = sortData.filter(x => x.IsCheck3 === 1)
                dataNonCheck.check = sortData.filter(x => x.IsCheck3 === -1)
              break;

              default:

            }
            numCheck.check1 = json.data.filter(x => x.IsCheck1 !== 0).length
            numCheck.check2 = json.data.filter(x => x.IsCheck2 !== 0).length
            numCheck.check3 = json.data.filter(x => x.IsCheck3 !== 0).length
            numCheck.check4 = json.data.filter(x => x.IsCheck4 !== 0).length
            numCheck.check5 = json.data.filter(x => x.IsCheck5 !== 0).length

          }
          this.state.editingData['dataCard'] = sortData; this.setState({editingData: this.state.editingData, numCheck: numCheck, dataCheck: dataCheck, dataNonCheck: dataNonCheck}) }
      })
    }, WAIT_INTERVAL)

  }
  onBlurDataFilterNoncheck(type) {
    type = type || 'uncheck'
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})
    let editingData = {...this.state.editingData}
    let paging = {searchid_phase_id: this.state.editingData._id, search_IsUpdateProfile: 1, paging: 1000}
    if (this.state.editingData.id) {
      paging = {searcharr_phase_id: this.state.editingData.id, search_IsUpdateProfile: 1, paging: 1000}
    }

    let isCheck ='IsCheck1'
    let is_return = 0

    switch (role) {
      case 'viewer':
      case 'chuyenviendp':
      case 'chuyenvientw':
      case 'chuyenvienpt':
      case 'chuyenvienkql':
        isCheck   = 'IsCheck1'
        is_return = 1
      break;
      case 'lanhdaodp':
      case 'lanhdaotw':
      case 'lanhdaopt':
      case 'lanhdaodonvi':
      case 'lanhdaokql':
        isCheck = 'IsCheck2'
        is_return = 3
      break;
      case 'Super':
      case 'lanhdaocucbcdp':
      case 'lanhdaocucbctw':
      case 'lanhdaocucptth':
      case 'lanhdaocuc01':
      case 'lanhdaocuckql':
        isCheck = 'IsCheck3'
        is_return = 4
      break;
      case 'lanhdaobo':
        isCheck = 'IsCheck4'
        is_return = 4
      break;
      default:
        isCheck = 'IsCheck1'
    }

    if (type === 'return') {
      paging['search_is_return'] = is_return
    } else if (type === 'uncheck') {
      paging['search_'+isCheck] = 0
    } else {
      paging['searchnin_'+isCheck] = [0]
    }

    apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), paging, {
        '1': (json) => {
          let numCheck = this.state.numCheck || {}
          let dataCheck = this.state.dataCheck
          let dataNonCheck = this.state.dataNonCheck

          if (json.data && json.data.constructor === Array) {
            let sortData = json.data

            numCheck.check1 = json.data.filter(x => x.IsCheck1 !== 0).length
            numCheck.check2 = json.data.filter(x => x.IsCheck2 !== 0).length
            numCheck.check3 = json.data.filter(x => x.IsCheck3 !== 0).length
            numCheck.check4 = json.data.filter(x => x.IsCheck4 !== 0).length
            numCheck.check5 = json.data.filter(x => x.IsCheck5 !== 0).length

            editingData['dataCard'] = sortData
          }

          this.setState({editingData: editingData, numCheck: numCheck, dataCheck: dataCheck, dataNonCheck: dataNonCheck})
        }

    })

  }
  onClickDataDelete (e, perdata) {
    let r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, this.props.api) + '/delete', {_id: perdata._id}, {
        '1': (json) => { this.setStateRx({loading: false}, () => {this.fetchData(true); this.onClickDataEdit({}, {}) } ) },
        'default': (json) => {
          this.setStateRx({loading: false, msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
        }
      })
    } else {}
  }
  onClickDataRestore (e, perdata) {
    apiget(rxu.get(rxu.config, this.props.api) + '/restore', {_id: perdata._id}, {
      '1': (json) => { this.fetchData(true) }
    })
  }
  onClickDataUpdateSubmit (e, perdata, type, name) {
    global.scrolltop()
    this._mounted = true
    let editingData = {...this.state.editingData, hideProgress: true}

    if(perdata && perdata === 1) {
      if (editingData && editingData.IsUpdateProfile === 0) {
        let valid = this.checkValidField(editingData['StatusPhanLoai'],perdata)
        if ( valid ) {
          this.setStateRx({loading: true}, () => {
            let params = {...this.state.editingData}
            params.IsUpdateProfile = 1
            delete params['data']
            apipost(rxu.get(rxu.config, this.props.api) + '/edit', params, {
              '1': (json) => {
                this.setStateRx({loading: false}, () => {
                  this.sendProfileSyncOne(json.data)
                  this.fetchData(true); this.onClickDataEdit({}, {})
                } ) },
              'default': (json) => {
                this.setStateRx({loading: false,  msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
              },
            })
          })
        } else {
          this.setStateRx({loading: false, msg: {success: true }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
        }
      }
    } else {
      this.setStateRx({loading: true}, () => {
        editingData.IsUpdateProfile = 0
        apipost(rxu.get(rxu.config, this.props.api) + '/edit', editingData, {
          '1': (json) => { this.setStateRx({loading: false}, () => {this.fetchData(true); this.onClickDataEdit({}, {}) } ) },
          'default': (json) => {
            this.setStateRx({msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
          },
        })
      })
    }
  }
  onClickDataForceUpdate (e, perdata) {
    global.scrolltop()
    this._mounted = true
    let is_active = e || 1
    let editingData = {}
    let editData = perdata
    editData.is_active = is_active
    this.setStateRx({loading: true, editData: editData}, () => {
      editingData.is_active = e
      editingData._id = perdata._id
      editingData.HoTenKhaiSinh = perdata.HoTenKhaiSinh
      editingData.hideProgress = true

      apipost(rxu.get(rxu.config, 'api_card_image_edit'), editingData, {
        '1': (json) => { this.setStateRx({loading: false}, () => {this.fetchData(true); this.onClickDataEdit({}, {}) } ) },
        'default': (json) => {
          this.setStateRx({msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
        },
      })
    })

  }
  onClickDataUpdateSubmitCate (e,perdata, type, name) {
    let totalRequired = global.rxu.get(this.state.editingData, ['number'], 0)
    let totalCurrent  = this.countCardCurrent(global.rxu.get(this.state.editingData, ['dataCard'], []))
    global.scrolltop()
    if (perdata === 1) {
      console.log(totalRequired, ' !==', totalCurrent);
      if (totalRequired === totalCurrent) {
        let r = window.confirm('Bạn có chắc chắn đã hoàn thành dữ liệu này !')
        if (r === true) {
          let params = {...this.state.editingData}
          delete params['data']
          params['status'] = Number(global.rxu.get(this.state.editingData, 'status')) + 1
          apipost(rxu.get(rxu.config, 'api_cate_confirm'), params, {
            '1': (json) => {
              this.fetchData(true); this.onClickDataEdit({}, {}) }
          })
        }
      } else {
        window.confirm('Số lượng hồ sơ chưa hoàn thành đủ ! Vui lòng duyệt hết hồ sơ !')
      }

    } else {
      console.log(totalRequired, ' !==', totalCurrent);
      if (totalRequired !== totalCurrent) {
        let r = window.confirm('Số lượng hồ sơ chưa hoàn thành đủ ! Bạn có chắc chắn duyệt nhanh trường hợp này !')
        if (r === true) {
          let params = {...this.state.editingData}
          let dataCard = global.rxu.get(this.state.editingData, 'dataCard', [])
          let cardId = []
          params['status'] = Number(global.rxu.get(this.state.editingData, 'status')) + 1

          for (let i in dataCard) {
            if (dataCard[i].IsCheck3 !== 0) {
              cardId.push(dataCard[i]._id)
            }
          }

          let editData =  {_id: params._id, cardId: cardId, is_done: 3, is_fast: true}
          apipost(rxu.get(rxu.config, 'api_cate_confirm'), editData, {
            '1': (json) => {
              this.fetchData(true); this.onClickDataEdit({}, {}) }
          })
        }
      }
    }
  }
  countCardCurrent(perdata) {
    let countNumber = 0
    let role = global.rxu.get(this.props, ['auth', 'user', 'user','role'], {})

    let isCheck ='IsCheck1'

    switch (role) {
      case 'viewer':
      case 'chuyenviendp':
      case 'chuyenvientw':
      case 'chuyenvienpt':
      case 'chuyenvienkql':
        isCheck   = 'IsCheck1'
      break;
      case 'lanhdaodp':
      case 'lanhdaotw':
      case 'lanhdaopt':
      case 'lanhdaodonvi':
      case 'lanhdaokql':
        isCheck   = 'IsCheck2'
      break;
      case 'Super':
      case 'lanhdaocucbcdp':
      case 'lanhdaocucbctw':
      case 'lanhdaocucptth':
      case 'lanhdaocuc01':
      case 'lanhdaocuckql':
        isCheck = 'IsCheck3'
      break;
      case 'lanhdaobo':
        isCheck = 'IsCheck4'
      break;
      default:
        isCheck = 'IsCheck1'
    }

    for (let i in perdata) {
      if (perdata[i][isCheck] !== 0) {
        countNumber += 1
      }
    }
    return countNumber
  }
  countNumber(perdata) {
    let countNumber = 0
    let data = rxu.get(perdata,'data', [])
    // eslint-disable-next-line
    data.map(i => {countNumber += i.number })
    return countNumber
  }
  onClickDataUpdateSubmitFinal (e, perdata, data, name) {
    global.scrolltop()
    let count = this.countNumber(this.state.editingData)
      let r = window.confirm('Bạn có chắc chắn trình danh sách hồ sơ này lên Cấp Bộ !')
      if (r === true) {
        let params = {}
        params.id = this.state.editingData.id
        apipost(rxu.get(rxu.config, 'api_cate_confirm_submit'), params, {
          '1': (json) => {
            this.fetchData(true); this.onClickDataEdit({}, {}) }
        })
      }

  }
  onClickDataUpdateSubmitRollback (e, perdata, data, name) {
    global.scrolltop()
    let r = window.confirm('Bạn có chắc chắn hoàn lại danh sách hồ sơ này về Cấp Cục !')
    if (r === true) {
      let params = {}
      params.id = this.state.editingData.id
      console.log(params.id);
      apipost(rxu.get(rxu.config, 'api_cate_confirm_submit_rollback'), params, {
        '1': (json) => {
          this.fetchData(true); this.onClickDataEdit({}, {}) }
      })
    }
  }
  onClickDataUpdateCard (e, val) {
    global.scrolltop()
    let r = window.confirm('Bạn có chắc chắn muốn hoàn thành dữ liệu này !')
    if (r === true) {
      this.setStateRx({loading: true}, () => {
        let editingData = {hideProgress: true}
        editingData.IsStatus = Number(this.state.editingDataReview.IsStatus)
        editingData.is_done = 1
        editingData._id = this.state.editingDataReview._id
        editingData.GhiChu = this.state.editingData.GhiChu || ''
        editingData.LichSuDuyet = this.state.editingDataReview.LichSuDuyet || []
        console.log('000042')
        apipost(rxu.get(rxu.config, 'api_card') + '/edit',editingData, {
          '1': (json) => { this.fetchData(true) }
        })
        this.onClickDataEdit({}, {})
      })
    } else {}
  }
  onClickShowClone () {
    this.setState({showPopupClone: true})
  }
  onClickDataCloneCard (e, val) {
    global.scrolltop()
    //show popup chon

    //sau khi chon set StatusPhanLoai
    // let r = window.confirm('Bạn có chắc chắn muốn clone dữ liệu này !')
    // if (r === true) {
      this.setStateRx({loading: true}, () => {
        let clone = {...this.state.editingData}
        clone._idHoSoClone = clone._id
        delete clone._id
        clone.LichSuDuyet = [{username: '', desc: '', action: '', created_at: 0}]
        clone.IsCheck1 = 0
        clone.IsCheck2 = 0
        clone.IsCheck3 = 0
        clone.IsCheck4 = 0
        clone.IsCheck5 = 0
        clone.IsStatus = 0
        clone.IsUpdateProfile = 0
        clone.phase = ''
        clone.phase_id = null
        clone.trace = ''
        clone.trace_name = ''
        clone.appdist = {fullname: '', _id: null}
        clone.ThamVan = [{email: '', file: '', content: '', subject: ''}]
        clone.phase_done = 0
        clone.code = 0
        clone.MaSoThe = null

        if(this.state.statusPhanLoaiClone === -1 || this.state.statusPhanLoaiClone === ""){
          this.alertMessage('Vui lòng chọn loại hồ sơ!')
        }else{
          if(this.state.statusPhanLoaiClone === 2 && (this.state.ngNhanDoiThe === -1 || this.state.ngNhanDoiThe === "")){
            this.alertMessage("Vui lòng chọn lý do xin cấp lại thẻ!")
          }else if(this.state.statusPhanLoaiClone === 2 && ((this.state.ngNhanDoiThe === 1 && (this.state.editingData.DonXacNhanCapLaiThe === "" || this.state.editingData.AnhScanTheHong === "")) || (this.state.ngNhanDoiThe === 2 && this.state.editingData.DonXacNhanCapLaiThe === "" )) ){
            this.alertMessage("Vui lòng cung cấp đủ thông tin!")
          }else if(this.state.statusPhanLoaiClone === 3 && (this.state.editingData.VanBanTiepNhanCTacMoi === "VanBanTiepNhanCTacMoi" || this.state.editingData.DonXacNhanCQCu === "DonXacNhanCQMoi" || this.state.editingData.DonXacNhanCQMoi === "" || this.state.editingData.AnhScanTheCu === "")){
            this.alertMessage("Vui lòng cung cấp đủ thông tin!")
          }
          else if(this.state.statusPhanLoaiClone === 2 && this.state.editingData.IsCheck5 <= 0 && this.state.editingData.IsStatus < 5) {
            this.alertMessage("Hồ sơ không đủ điều kiện")
          }
          else{
            clone.StatusPhanLoai = this.state.statusPhanLoaiClone

            apipost(rxu.get(rxu.config, 'api_card'), clone, {
              '1': (json) => { this.fetchData(true); this.setState({showPopupClone: false}) }
            })
            this.onClickDataEdit({}, {})
            this.setState({
              ngNhanDoiThe: ''
            })
          }
        }
      })
    // } else {}
  }
  onClickDataUpdateTrace (e, perdata) {
    global.scrolltop()
    if(perdata === 'review_8') {

    } else {
      let editData = {...this.state.editData, hideProgress: true}
      apipost(rxu.get(rxu.config, 'api_card_edittrace') , editData, {
        '1': (json) => { this.fetchData(true) }
      })
      this.onClickDataEdit({}, {})
    }
  }
  onClickDataUpdateSubmitRaw (e, perdata) {
    global.scrolltop()
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
      '1': (json) => { this.fetchData(true) },
      'default': () => {}
    })
    this.onClickDataEdit({}, {})
  }
  onClickDataCreateSubmit (e, perdata) {
    this._mounted = true
    if (!this.state.loading) {
      if(perdata === 'phase') {
        this.setStateRx({loading: true}, () => {
          let editingData = {...this.state.editingDataPhase, hideProgress: true}
          apipost(rxu.get(rxu.config, 'api_cate'), editingData, {
            '1': (json) => { this.setStateRx({loading: false}, () => this.fetchPhase(true)) }
          })
          this.onClickShowCreatePhase()
        })
      } else {
        global.scrolltop()
        this.setStateRx({loading: true}, () => {
          let editingData = {...this.state.editingData, hideProgress: true}
          editingData.IsUpdateProfile = perdata || 0
          let valid = this.checkValidField(editingData['StatusPhanLoai'], perdata)
          if ( valid ) {
            apipost(rxu.get(rxu.config, this.props.api), editingData, {
              '1': (json) => {
                this.setStateRx({loading: false, msg: {success: false }}, () => {
                this.sendProfileSyncOne(json.data)
                this.fetchData(true); this.onClickDataEdit({}, {})
              } ) },
              'default': (json) => {
                let errMsg = [json.success.toString()]
                this.setStateRx({loading: false, errMsg: errMsg, msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
              },
            })
          } else {
            this.setStateRx({loading: false, msg: {success: true }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
          }
        })

      }

    }
  }
  onClickDataUpdateImage (e, perdata) {
    this._mounted = true
    // if (!this.state.loading) {
        global.scrolltop()
        this.setStateRx({loading: true}, () => {
          let editingData = {
            _id: this.state.editingData._id,
            Avatar: this.state.editingData.Avatar,
            img_landscape: this.state.editingData.img_landscape,
            cmnd_truoc: this.state.editingData.cmnd_truoc,
            cmnd_sau: this.state.editingData.cmnd_sau,
            NgayKyHDLD: this.state.editingData.NgayKyHDLD,
            BanSaoBangDHCD: this.state.editingData.BanSaoBangDHCD,
            BanSaoHDLD: this.state.editingData.BanSaoHDLD,
            profileAttachments: this.state.editingData.profileAttachments,
            DonXacNhanCQCu: this.state.editingData.DonXacNhanCQCu,
            XacNhanCQCu: this.state.editingData.XacNhanCQCu,
            DonXacNhanCQMoi: this.state.editingData.DonXacNhanCQMoi,
            AnhScanTheCu: this.state.editingData.AnhScanTheCu,
            DonXacNhanCapLaiThe: this.state.editingData.DonXacNhanCapLaiThe,
            AnhScanTheHong: this.state.editingData.AnhScanTheHong,
            VanBanTiepNhanCTacMoi: this.state.editingData.VanBanTiepNhanCTacMoi,
            LyDoCapLai: this.state.editingData.LyDoCapLai,
            DonXacNhanMatThe: this.state.editingData.DonXacNhanMatThe,
            DonXacNhanCADoMatThe: this.state.editingData.DonXacNhanCADoMatThe,
            hideProgress: true
          }

            apipost(rxu.get(rxu.config, 'api_card_image_edit'), editingData, {
              '1': (json) => {
                this.setStateRx({loading: false,editUpload: false, msg: {success: false }}, () => {
                this.fetchData(true); this.onClickDataEdit({}, {})
              } ) },
              'default': (json) => {
                let errMsg = [json.success.toString()]
                this.setStateRx({loading: false, errMsg: errMsg, msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
              },
            })
        })

      // }

  }
  
  sendProfileSyncOne(perdata) {
    perdata = perdata || {}
    if (perdata.IsUpdateProfile !== 0) {
      let editingData = {
        search_CoQuanBaoChiID: perdata.CoQuanBaoChiID ,
        search_Type: perdata.Type ,
        search_IsUpdateProfile: 1,
        searchnin_is_synced: 1,
        searchid__id: perdata._id,
        pg_size: 1
      }
      apiget(rxu.config.api_sendProfileSync, editingData, {
        '1': (json) => {
          this.fetchData()
        }
      })

    }

  }

  sendProfileSyncUpdate(perdata) {
    perdata = perdata || {}
    if (perdata.IsUpdateProfile !== 0) {
      let editingData = {
        search_CoQuanBaoChiID: perdata.CoQuanBaoChiID ,
        search_Type: perdata.Type ,
        search_IsUpdateProfile: 1,
        searchnin_is_synced: 1,
        searchid__id: perdata._id,
        pg_size: 1
      }
      apiget(rxu.config.api_sendProfileSyncUpdate, editingData, {
        '1': (json) => {
          this.fetchData()
        }
      })
    }
  }

  onClickDataCreatePosition (e, perdata) {
    if(this.state.editingDataPosition && this.state.editingDataPosition['Ten'] && this.state.editingDataPosition['Ten'] !== '') {
      this.setStateRx({loading: true}, () => {
        let editingDataPosition = {...this.state.editingDataPosition, hideProgress: true}
        let editingData = this.state.editingData
        let api = '', name = ''
        switch (this.state.typePos) {
          case 'ChucDanhBaoChi':
            api = 'api_title'
            break;
          case 'TenChucVu':
            api = 'api_position'
            break;
          case 'HinhThucDaoTao':
            api = 'api_config'
            name = 'HinhThucDaoTao'
            break;
          case 'TruongHoc':
            api = 'api_config'
            name = 'TruongHocID'
            break;
          case 'NganhHoc':
            api = 'api_config'
            name = 'NganhHocID'
            break;
          case 'TrinhDoChuyenMon':
            api = 'api_config'
            name = 'TrinhDoChuyenMonID'
            break;
          default:
        }

        apipost(rxu.get(rxu.config, api), editingDataPosition, {
          '1': (json) => {
            if(json.data && json.data.Ten && this.state.typePos && this.state.typePos !== '') {
              if(['TenChucVu', 'ChucDanhBaoChi'].indexOf(this.state.typePos) !== -1) {
                editingData[this.state.typePos] = json.data.Ten
                editingData['ChucVuID']         = this.state.typePos === 'TenChucVu' ? json.data.ChucVuID : editingData['ChucVuID']
              } else {
                editingData[this.state.typePos].push(json.data.Ten)
                editingData[name].push(json.data.id)
              }
              this.setStateRx({loading: false, editingData: editingData}, () => {this.fetchPosition(true); this.onClickShowCreatePosition()})
            }
          }
        })
      })
    }
  }
  checkValidField(type, perdata) {

    let valid = false
    let failed = [], success = []
    let editingData = this.state.editingData
    let validField = {}

    if (perdata && perdata === 1) {
      validField['validCapDoi'] = [ 'img_landscape','TenCoQuanBaoChi','HoTenKhaiSinh' ,'DanToc', 'QueQuan', 'NoiO' , 'province', 'TenChucVu','NgaySinhConvert',
                                    'TrinhDoVanHoa','TrinhDoChuyenMon', 'TruongHoc', 'NganhHoc', 'HinhThucDaoTao', 'NamTotNghiep', 'ChinhTri', 'NgoaiNgu',
                                    'ChucDanhBaoChi', 'MaTheCu', 'QuaTrinhHoatDongUpdate', 'NgayCapCMND', 'cmnd_truoc', 'cmnd_sau','ThongTinHDLD'
                                  ]
      validField['validCapMoi'] = [ 'img_landscape','TenCoQuanBaoChi','HoTenKhaiSinh' ,'DanToc', 'QueQuan', 'NoiO' , 'province', 'TenChucVu','NgaySinhConvert',
                                    'TrinhDoVanHoa','TrinhDoChuyenMon', 'TruongHoc', 'NganhHoc', 'HinhThucDaoTao', 'NamTotNghiep', 'ChinhTri', 'NgoaiNgu',
                                    'ChucDanhBaoChi', 'QuaTrinhHoatDongUpdate', 'NgayCapCMND', 'cmnd_truoc', 'cmnd_sau','ThongTinHDLD'
                                  ]
      validField['validCapLai'] = [ 'img_landscape','TenCoQuanBaoChi','HoTenKhaiSinh'
                                  ]
      // validField['validCapLai'] = [ 'img_landscape','TenCoQuanBaoChi','HoTenKhaiSinh' ,'DanToc', 'QueQuan', 'NoiO' , 'province', 'TenChucVu','NgaySinhConvert',
      //                               'TrinhDoVanHoa','TrinhDoChuyenMon', 'TruongHoc', 'NganhHoc', 'HinhThucDaoTao', 'NamTotNghiep', 'ChinhTri', 'NgoaiNgu',
      //                               'ChucDanhBaoChi', 'MaTheCu', 'QuaTrinhHoatDongUpdate', 'NgayCapCMND', 'cmnd_truoc', 'cmnd_sau','ThongTinHDLD'
      //                             ]


      validField['QuaTrinhHoatDongUpdate'] = ['time','ChucDanhBaoChi', 'CoQuanBaoChi','NgachLuong','BacLuong','KhenThuong']

    } else {
      validField['validCapMoi'] = ['HoTenKhaiSinh' ]
      validField['validCapLai'] = ['HoTenKhaiSinh' ]
      validField['validCapDoi'] = ['HoTenKhaiSinh' ] // CMNN
      validField['QuaTrinhHoatDongUpdate'] = []
    }

    let name = 'validCapMoi'
    if([1,6].indexOf(type) !== -1) { // cap moi, cap theo ky han
      name = 'validCapDoi'
    } else if(type === 5) { // cap doi
      name ='validCapMoi'
    } else if(type === 2) { //cap lai
      name = 'validCapLai'
    }
    validField[name].forEach(val => {
      if(val === 'QuaTrinhHoatDongUpdate')
      {
        let arr = editingData[val]
        for (let i = 0; i < arr.length ; i++){
          validField[val].forEach(val2 => {
            if(editingData[val][i][val2] === '') {
              failed.push(val2)
              //valid = false
            } else {
              //valid = true
              success.push(val2)
            }
          })
        }
      }
      if(!editingData[val] || editingData[val].length === 0) {
        failed.push(val)
        //valid = false
      } else {
        //valid = true
        success.push(val)
      }
    })
    if (failed.length === 0){
      valid = true
    } else {
      valid = false
    }

    this.setState({errMsg: failed})
    // console.log(valid, failed, 'failed')
    // console.log(success, 'success')
    return valid
  }
  onClickDataConfirmAll(e, index) {
    global.scrolltop()
    let r = index === 1 ?
    window.confirm('Nhất trí với ý kiến của lãnh đạo Cục!') :
    (index === 2 ? window.confirm('Không đồng ý duyệt cấp thẻ nhà báo') :
    window.confirm('Ý kiến khác!'))
    if (r === true) {
      this.setStateRx({loading: true}, () => {
        let editingData = {}
        editingData['dataId'] = []
        editingData['IsCheck4'] = []
        editingData['dataCheck'] = []
        editingData['dataCateId'] = []
        editingData['IsCheck'] = index === 1 ? true : false

        this.state.editingData['dataCard'].forEach(o => editingData['dataId'].push(o._id))
        this.state.editingData['data'].forEach(o => editingData['dataCateId'].push(o._id))
        this.state.editingData['dataCard'].forEach(o => editingData[o._id] = o.IsCheck3)
        global.rxu.get(this.state.dataCheck, ['check'], []).forEach(o => editingData['dataCheck'].push(o._id))


        if (index === 1) {
          apipost(rxu.get(rxu.config, 'api_card_confirm_all'), editingData, {
            '1': (json) => { this.setStateRx({loading: false}, () => this.fetchData(true)) },
            'default': (json) => { this.setStateRx({msg: json});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {}}) }, 3000)},
          })
        } else if (index === 2) {
          apipost(rxu.get(rxu.config, 'api_card_un_confirm_all'), editingData, {
            '1': (json) => { this.setStateRx({loading: false}, () => this.fetchData(true)) },
            'default': (json) => { this.setStateRx({msg: json});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {}}) }, 3000)},
          })
        } else {
          editingData['GhiChu'] = this.state.editingData.GhiChu
          apipost(rxu.get(rxu.config, 'api_card_confirm_rollback_all'), editingData, {
            '1': (json) => { this.setStateRx({loading: false, showPopup: false}, () => this.fetchData(true)) },
            'default': (json) => { this.setStateRx({msg: json});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {}}) }, 3000)},
          })
        }

        this.onClickDataEdit({}, {})
      })
    }

  }
  showForm(e, type, done) {
    let editingData = this.state.editingData
    editingData.GhiChu = ''
    let action = type ? 'duyet' : 'khongduyet'
    editingData.is_done = done
    this.setState({showPopup: true, editingData: editingData, action: action})
  }
  showFormConfirmAll(e) {
    this.setState({showPopup: true})
  }
  onClickShowHistory(data) {
    let clone = data || []
    this.setState({showHistory: !this.state.showHistory, dataLichSuDuyet: clone, editUpload: false})
  }
  onClickToggleHistory(action) {
    let tmp = this.state.activeHistory
    if(tmp && tmp === action) {
      tmp = ''
    } else {
      tmp = action
    }
    this.setState({activeHistory: tmp})
  }
  onClickShowCreatePhase(name) {
    if(name === 'create') {
      let timeStr = Date.now().toString()
      let clone = { desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0 }
      let user = global.rxu.get(this.props, ['auth', 'user', 'user'], {})
      if(user.role === 'member' && user.CoQuanBaoChi) {
        clone['TenCoQuanBaoChi']    = global.rxu.get(user, ['CoQuanBaoChi', 0], '') // user.CoQuanBaoChi
        clone['CoQuanBaoChiID']  = user.id
        clone['LoaiCoquan']      = user.Type === 2 ? 3 : 2
      }
      clone.name = 'Thẩm định đợt ' + timeStr.substr(timeStr.length - 5)

      this.setStateRx({ editingDataPhase: clone })
    }
    this.setState({ showPhase: !this.state.showPhase })
  }
  onClickShowCreatePosition(name, pos) {
    if(name === 'create') {
      let clone = { desc: '', created_at: 1, is_deleted: 0, is_active: 0, Ten: '', ChucVuID: 0 }
      if(pos === 'HinhThucDaoTao') { clone.type = 1 }
      if(pos === 'TruongHoc') { clone.type = 2 }
      if(pos === 'NganhHoc') { clone.type = 3 }
      if(pos === 'TrinhDoChuyenMon') { clone.type = 4 }
      this.setStateRx({ editingDataPosition: clone, showPosition: !this.state.showPosition, typePos: pos })
    } else {
      this.setState({editingDataPosition: {}, showPosition: !this.state.showPosition, typePos: ''})
    }
  }
  onClickDataPrint(e, perdataorder, name) {
    if(e === 'save') {
      this.setState({ printCard: true, typePrint: 'save' })
    } else {
      this.setState({ printCard: true, typePrint: 'print' }, () => window.print())
    }
  }
  onChangeFontPrint(e, name) {
    e.preventDefault()
    let fontPrint = this.state.fontPrint
    if(e.target.value === '') {
      e.target.value = ''
    }
    fontPrint[name] = e.target.value
    this.setState({fontPrint: fontPrint})
  }
  onChangeDatePrint(e, name) {
    // e.preventDefault()
    let fontPrint = this.state.fontPrint
    fontPrint[name] = e
    this.setState({fontPrint: fontPrint})
  }
  onClickDataPrintAll(e, dataId, type) {
    dataId = dataId || []
    let editingDataPrint = this.state.editingDataPrint
    let fontPrint = this.state.fontPrint

    fontPrint.NgayCap = fontPrint.NgayCap !== 1609459200 ? fontPrint.NgayCap : editingDataPrint[0].NgayBatDauThe

    if(type === 'save') {
      for(let i in editingDataPrint) {
        if(editingDataPrint[i] && editingDataPrint[i]['img_card'] && editingDataPrint[i]['img_card'].length > 0) {
          editingDataPrint = editingDataPrint.filter(o => o._id.toString().indexOf(editingDataPrint[i]['_id'].toString()) !== -1)
        }
      }
      this.setState({ typePrint: 'save', editingDataPrint: editingDataPrint,fontPrint: fontPrint, view: false, printCard: true })
    } else if (type === 'print') {
      let action = 'IN THẺ'
      let r = window.confirm('Bạn có chắc chắn muốn ' + action + ' dữ liệu này !')
      if (r === true) {
        this.setStateRx({ printCard: true, editingDataPrint: editingDataPrint,fontPrint: fontPrint, view: false, typePrint: 'print' }, () => {
          window.print()
        })

        this.setStateRx({loading: true}, () => {
          let editingData = {hideProgress: true}
          editingDataPrint.map(x => { return dataId.push(x._id)})
          editingData.dataId = dataId
          apipost(rxu.get(rxu.config, 'api_card_printAll') ,editingData, {
            '1': (json) => { this.setState({ printCard: true, typePrint: 'save'}, (e) => {this.fetchData(true)});},
            'default': (json) => {
              let errMsg = [json.success.toString()]
              this.setStateRx({loading: false, errMsg: errMsg, msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
            }
          })

        })
      } else { }
    }
  }
  onResultPrintCard(e) {
    if(e && this.state.printCard && this.state.typePrint === 'print') {
      this.setState({printCard: false, typePrint: ''})
    }
  }
  onClickDataApprove(e,val) {
    let isStatus = rxu.get(this.state.editingDataReview, 'IsStatus', 0)
    let step  =  isStatus + 1
    let value = 'IsCheck'+step
    let action = this.state.action === 'duyet' ? ('DUYỆT '+ step) : ('KHÔNG DUYỆT '+ step)

    if(this.state.action && this.state.action !== 'duyet' && this.state.editingData.GhiChu === '' ) {
      window.confirm('Ghi chú thẩm định là bắt buộc !')
    } else {
      global.scrolltop()
      let r = window.confirm('Bạn có chắc chắn muốn ' + action + ' dữ liệu này !')
      if (r === true) {
        this.setStateRx({loading: true, activeStep: false}, () => {
          let editingData = {hideProgress: true}
          editingData[value] = this.state.action === 'duyet' ? 1 : -1
          editingData.IsCheck = this.state.action === 'duyet' ? 1 : -1
          editingData.IsStatus = Number(this.state.editingDataReview.IsStatus) || 0
          editingData._id = this.state.editingDataReview._id
          editingData.action = action
          editingData.GhiChu = this.state.editingData.GhiChu || ''
          editingData.LichSuDuyet = this.state.editingDataReview.LichSuDuyet || []
          editingData.is_done = this.state.editingData.is_done || 0
          editingData.phase_id = this.state.editingData._id
          apipost(rxu.get(rxu.config, 'api_card_confirm'),editingData, {
            '1': (json) => {
              this.sendProfileSyncUpdate(json.data)
              this.onClickDataReset({}, this.state.editingData)
            },
            'default': (json) => {
              this.setStateRx({loading: false, msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}, activeStep: false}) }, 10000);
            }
          })
        })
      } else {}
    }

  }
  onClickDataReset(e, perdata) {
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = rxu.clone(perdata)
    if (clone.id) { clone._id = clone.id }
    let paging = {searchid_phase_id: rxu.get(clone, '_id', rxu.get(clone, 'id')), search_IsUpdateProfile: 1}
    if (this.props.name === 'print') { paging.search_IsStatus = 4 }
    apiget(rxu.get(rxu.config, 'api_card_reviewDetail'), paging, {
      '1': (json) => {
        let numCheck = this.state.numCheck || {}
        if (json.data && json.data.constructor === Array) {
          numCheck.check1 = json.data.filter(x => x.IsCheck1 !== 0).length
          numCheck.check2 = json.data.filter(x => x.IsCheck2 !== 0).length
          numCheck.check3 = json.data.filter(x => x.IsCheck3 !== 0).length
          numCheck.check4 = json.data.filter(x => x.IsCheck4 !== 0).length
          numCheck.check5 = json.data.filter(x => x.IsCheck5 !== 0).length
        }
        clone['dataCard'] = json.data; this.setState({editingData: clone, numCheck: numCheck}) }
    })
    this.setStateRx({ editingData: clone, flags: {}, view: false, editingDataPrint: [], editingDataReview:{}, errMsg: {}, ERR_MSG: []})
    this.onClickClosePopup()
  }
  onClickConsultation(e,val) {
    this.setStateRx({loading: true}, () => {
      let editingData = this.props.name.indexOf('review') !== -1 ? this.state.editingDataReview : this.state.editingData
      let params = {}
      params.codeGen = editingData.codeGen || ''
      params._id = editingData._id || ''
      params.email = this.state.editingData.EmailThamVan || ''
      params.content = this.state.editingData.ContentThamVan || ''
      params.subject = this.state.editingData.SubjectThamVan || ''
      apipost(rxu.get(rxu.config, 'api_sentmail'), params, {
        '1': (json) => { alert('Đã gửi thành công') }
      })
      this.onClickShowInput()
    })
  }
  async checkAll(e) {
    // const allCheckBox = document.getElementById('HoSoID-All').checked
    // var listId = []
    // if(allCheckBox === true){
    //     let check = document.getElementsByClassName('css-checkboxShow')
    //     if(check &&check.length > 0) {
    //         for(let i = 0; i < check.length; i++) {
    //             check[i].checked = true
    //             await listId.push(check[i].value)
    //         }
    //     }
    //     this.setState({idDataEdit: listId, checkAll: true})
    // }else{
    //     let check = document.getElementsByClassName('css-checkboxShow')
    //     if(check &&check.length > 0) {
    //         for(let i = 0; i < check.length; i++) {
    //             check[i].checked = false
    //         }
    //     }
    //     this.setState({idDataEdit: [], checkAll: false })
    // }

    const allCheckBox = document.getElementById('HoSoID-All').checked
    var listId = []
    if(allCheckBox === true){
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = true
                await listId.push(check[i].value)
            }
        }
        this.setState({editingDataPrint: listId, checkAll: true})
    }else{
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = false
            }
        }
        this.setState({editingDataPrint: [], checkAll: false })
    }
  }
  async checkObject(e, obj) {
    // var checkedValue = null;
    // var listId = []
    // var inputElements = document.getElementsByClassName('css-checkboxShow');
    // for(var i=0; inputElements[i]; ++i){
    //   if(inputElements[i].checked){
    //       checkedValue = inputElements[i].value;
    //       await listId.push(checkedValue)
    //   }
    // }
    /*let listId = this.state.idDataEdit
    listId.push(obj._id)

    this.setState({idDataEdit: listId, checkAll: true})*/
    let editingDataPrint = this.state.editingDataPrint
    editingDataPrint.push(obj)

    this.setState({editingDataPrint: editingDataPrint, checkAll: true})
  }
  onClickClosePopup() {
    this.setState({showPopup: false, action: ''})
  }
  onClickShowInput() {
    if(this.state.showInput) {
      this.setState({showInput: !this.state.showInput})
    } else {
      let editingData = this.state.editingData
      apiget(rxu.config.api_province, {search_Ten: global.rxu.get(this.state, 'editingDataReview.province')}, {
        '1': (json) => {
          if(json.data && json.data[0]) {
            editingData['EmailThamVan'] = json.data[0].email || ''
            this.setState({showInput: !this.state.showInput})
          }
        }
      })
    }
  }
  onClickDataTrash (e, isdeleted) {
    // console.log(this.state)
    let paging = this.state.paging
    paging['search_is_deleted'] = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }
  onClickGenPermission () {
    apiget(rxu.config.api_permission_genpermission, {}, { '1': (json) => {
      this.fetchData()
    } })
  }
  onClickAddRowTable(name) {
    let editingData = this.state.editingData
    if(name === 'QuaTrinhHoatDongUpdate') {
      if(editingData[name]) {
        editingData[name].push({key: editingData[name].length,time_start:new Date() / 1000,time_end:new Date() / 1000, ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''})
      } else {
        editingData[name] = [{key: 0,time_start:new Date() / 1000,time_end:new Date() / 1000, ChucDanhBC: '', CoQuanBaoChi: '', NgachLuong: '', BacLuong: '', KhenThuong: '', MaThe: ''}]
      }
    }
    if(name === 'DanhSachTacPham') {
      if(editingData[name]) {
        editingData[name].push({key: editingData[name].length, Ten: '', LoaiHinhBC: '', TacGia: '', NgayDang: '', CoQuanDang: ''})
      } else {
        editingData[name] = [{key: 0, Ten: '', LoaiHinhBC: '', TacGia: '', NgayDang: '', CoQuanDang: ''}]
      }
    }
    this.setStateRx({editingData: editingData})
  }
  onClickRowDel(index, key, name) {
    let editingData = this.state.editingData
    if(editingData[name] && editingData[name].length > 0) {
      editingData[name] = editingData[name].filter(o => o.key !== key)
    }
    this.setStateRx({editingData: editingData})
  }
  onClickReset(perdata) {
    global.scrolltop()
    this._mounted = true
    let id = perdata._id
    if (id) {
      let editingData = {hideProgress: true, _id: id}
      this.setStateRx({loading: true}, () => {
          apipost(rxu.get(rxu.config, 'api_card_resetConfirm'), editingData, {
          '1': (json) => { this.setStateRx({loading: false}, () => {this.fetchData(true); this.onClickDataEdit({}, {}) } ) },
          'default': (json) => {
            this.setStateRx({msg: {success: true, msg: json.msg }});clearTimeout(this.timerDatafilter); this.timerDatafilter = setTimeout((e, name) => { this.setStateRx({msg: {success: false}}) }, 10000);
          },
        })
      })
    }

  }
  rxshowPopup() {
    this._mounted = true
    this.setStateRx({rxshowPopup: !this.state.showPopup})
  }
  onCancel () {
    this.setState({rxshowPopup: false})
    this.setState({showPopupClone: false})
    this.setState({
      ngNhanDoiThe: '',
      statusPhanLoaiClone: '',
      showPopupNewDataCapLai: '',
      showPopupNewDataCapDoi: '',
      checkHadCard: false,
      showBtnCreateNew: '',
      maTheTimKiem: '',
      message: ''
    })
  }
  onValidateField(e, name) {
    let errMsg = this.state.errMsg
    let ERR_MSG = this.state.ERR_MSG
    let editingData = this.state.editingData
    if(name === 'cmnd'){
      if(!(editingData['cmnd_truoc'] && editingData['cmnd_sau']) || (editingData['cmnd_truoc'] === '' &&  editingData['cmnd_sau'] === '')){
        errMsg['cmnd'] = 'Không được để trống'
        this.setState({errMsg: errMsg})
        }
    } else if (name === 'BanSaoBangDHCD' || name === 'BanSaoHDLD' || name === 'SoYeuLyLich') {
        if( !editingData[name] || editingData[name] === '' ){
        errMsg[name] = 'Không được để trống'
        this.setState({errMsg: errMsg})
        }
    } else {
       if(!editingData[name] || editingData[name] === '') {
         errMsg[name] = 'Không được để trống'
         this.setState({errMsg: errMsg})
       } else {
        delete errMsg[name]
        ERR_MSG.splice(0,1)
        this.setState({errMsg: errMsg, ERR_MSG: ERR_MSG})
       }
     }
  }
  // B L U R   E V E N T
  onBlurData (e, name, options, name1, index) {
    options = options || {}
    let editingData = this.state.editingData
    let editingDataReview = this.state.editingDataReview
    if (name === 'role_id') {
      if (this.props.roles && e.target.value) {
        let ob = this.props.roles[e.target.value]
        editingData.role_id = ob._id
        editingData.role    = ob.name
      }
    } else if(name ==='QuaTrinhHoatDongUpdate' || name === 'DanhSachTacPham') {
      if(['time_start', 'time_end'].indexOf(name1) !== -1) {
        editingData[name][index][name1] = e
      } else {
        editingData[name][index][name1] = e.target.value
      }
    } else if(name ==='LichSuDuyet' ){
      editingDataReview[name][index][name1] = e.target.value
    }
     else {
      if (options.strim) {
        editingData[name] = e.target.value.toString().replace(/(,)/g, '')
      } else {
        editingData[name] = e.target.value
      }
    }
    this.setState({ editingData: editingData , editingDataReview: editingDataReview })
  }
  onBlurDataConfirm(e, name) {
    let editingData = this.state.editingData
    editingData.GhiChu = e.target.value
    this.setState({editingData: editingData})
  }
  onBlurDanhSachTacPham(value, name, index) {
    let editingData = this.state.editingData
    editingData['DanhSachTacPham'][index][name] = value
    // console.log(editingData, 'editingData')
    this.setState({editingData: editingData})
  }
  onBlurDataPhase(e, name) {
    let editingDataPhase = this.state.editingDataPhase
    editingDataPhase[name] = e.target.value
    this.setState({editingDataPhase: editingDataPhase})
  }
  onBlurDataPosition(e, name) {
    let editingDataPosition = this.state.editingDataPosition
    editingDataPosition[name] = e.target.value
    this.setState({editingDataPosition: editingDataPosition})
  }
  onBlurDataDetail (e, name, options, name1, index) {
    options = options || {}
    let editingData = this.state.editingData
    editingData[name] = e.target.value.join(',')
    this.setState({ editingData: editingData })
  }
  onBlurDataValue (value, name, type) {
    let editingData = (type && type === 'phase') ? this.state.editingDataPhase : this.state.editingData
    if(name === 'TenChucVu' && value) {
      editingData['TenChucVu'] = value.Ten
      editingData['ChucVuID']  = value.ChucVuID
      editingData['is_stt']    = value.STT
    } else if(value && ['HinhThucDaoTao', 'TruongHoc', 'NganhHoc', 'TrinhDoChuyenMon'].indexOf(name) !== -1 ) {
      editingData[name] = value.Ten
      editingData[type] = value.id
    } else if (name === 'TenCoQuanBaoChi') {
      editingData['TenCoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiID'] = value.Cat_ID
      editingData['Code'] = value.Code
      editingData['LoaiCoquan']      = value.LoaiCoquan
    } else if(name === 'CoQuanChuQuan' || name === 'CoQuanBaoChiCu') {
      editingData['CoQuanChuQuan'] = value.Cat_Name
    } else if(name === 'member_trace') {
      editingData['trace'] = value._id
      editingData['trace_name'] = value.fullname
    } else if(name === 'phase') {
      editingData['phase'] = value.name
      editingData['phase_id'] = value._id
    } else if(name === 'province') {
      editingData['province'] = value.Ten
      editingData['province_email'] = value.email || ''
    } else if(name === 'ChucDanhBaoChi') {
      editingData['ChucDanhBaoChi'] = value.Ten
    } else {
      editingData[name] = value
    }

    if(type && type === 'phase') { this.setState({ editingDataPhase: editingData }) } else { this.setState({ editingData: editingData }) }
  }
  onBlurDataTrace (value, name, type) {
    let editData = type === 'review_8' ? this.state.editingData : this.state.editData
    editData['user_trace'] = value._id
    editData['trace_name'] = value.fullname
    editData._id = this.state.editingDataReview._id
    editData.trace = this.state.editingDataReview.trace
    if(type === 'review_8') { this.setState({ editingData: editData }) } else { this.setState({ editData: editData }) }
  }
  onBlurDataReview (e, name, type) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    if(name === 'phase') {
      if(e === -1) {
        delete paging['searchid_phase_id']
        delete paging['phase']
      } else {
        paging['searchid_phase_id'] = e._id
        paging['phase'] = e.name
      }
      if(e !== -1 && e.submit !== 0) {
        this.setState({ isSubmit: false })
      }
    } else {
      if(name === 'Type' && paging['search_LoaiCoquan']) {
        delete paging['search_LoaiCoquan']
      }
      if(name === 'LoaiCoquan' && paging['search_Type']) {
        delete paging['search_Type']
      }
      paging['search_' + name] = e
    }
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }
  onBlurDataFilterReview (e, name, type) {
    clearTimeout(this.timerDatafilter)

    let paging = this.state.paging
    if(['status','is_return'].indexOf(name) !== -1) {
      if(e.target.value === -1) {
        delete paging['search_'+name]
      } else {
        paging['search_' + name] = e.target.value //.split(',').map(o => Number(o))
      }
    } else if (name === 'StatusPhanLoai') {
      paging['search_StatusPhanLoai'] = e
    } else {
      paging['searchid_' + name] = e.Cat_Name
    }

    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }
  onBlurDatafilter (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      if(e === -1) {
        switch (name) {
          case 'TenCoQuanBaoChi':
             delete paging['searche_' + name]
            break;
          case 'TenChucVu':
             delete paging['searche_' + name]
            break;
          default:
             delete paging['search_' + name]
        }
      } else {
        switch (name) {
          case 'CoQuanBaoChiID':
            paging['search_' + name] = e.Cat_ID
            break;
          case 'TenChucVu':
            paging['searche_' + name] = e.Ten
            break;
          default:
            paging['search_' + name] = e
        }
      }

      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else if(name === 'search_cate') {
      let paging = this.state.pagingCat
      paging['search_Cat_Name'] = e
      this.setState({ pagingCat: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchCate()
      }, WAIT_INTERVAL)
    } else if(name === 'Status') {
      let paging = this.state.paging
      paging['search_Status'] = e
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else if(name === 'typeDaiTH') {
      let paging = this.state.paging
      paging['search_typeDaiTH'] = e.target.value

      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else {
      let paging = this.state.paging
      paging['search_' + name] = e.target.value
      if(e.target.value === '') { delete paging['search_' + name] }
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
  }
  onBlurDatafilterOld (e, name, type) {
    // clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    let today = new Date()
    let priorDate = new Date().setFullYear(today.getFullYear()-Number(e.target.value))
    // console.log(priorDate, 'priorDate')
    if(name === 'TuTuoi') {
      if(e.target.value === '' || !e.target.value || e.target.value < 1) {
        delete paging['searchNgaySinh_min']
      } else {
        paging['searchNgaySinh_min'] = priorDate / 1000
      }
    } if(name === 'DenTuoi') {
      if(e.target.value === '' || !e.target.value || e.target.value < 1) {
        delete paging['searchNgaySinh_max']
      } else {
        paging['searchNgaySinh_max'] = priorDate  / 1000
      }
    }

    this.setState({ paging: paging })
  }
  fetchDataFix (runCallback = false) {
    let paging =  { st_type: -1, pg_page: 1, pg_size: 10 }
    apiget(rxu.get(rxu.config, this.props.api), paging, {
      '1': (json) => {
        this.setStateRx({ data: json.data, extra: json.extra }, () => {
          if (!rxu.get(this.state, ['temp', 'filterFields'])) {
            let temp = this.state.temp; temp.filterFields = rxu.get(this.state, ['data', 0])
            this.setStateRx({ temp })
          }
        })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }
  // C H A N G E  E V E N T
  onChangeContentCKE (evt) {
    let editingData = this.state.editingData
    let newContent = evt.editor.getData()
    if(this.state.showInput) { editingData['ContentThamVan'] = newContent } else { editingData.content = newContent }
    this.setState({ editingData: editingData })
  }
  // P A G I N  E V E N T
  onClickPaging (page) {
    let paging = this.state.paging
    let count = rxu.get(this.state.extra, 'count', paging.pg_size)
    let maxpage = Math.ceil(count / paging.pg_size)

    if (page < 1) { page = 1 }
    if (page > maxpage) { page = maxpage }

    paging.pg_page = page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onChangePageSize (evt) {
    clearTimeout(this.timerPageSize)
    let pagesize = parseInt(evt.target.value, 10)
    let paging = this.state.paging
    paging.pg_size = pagesize
    paging.pg_page = 1
    if (pagesize >= 1 && pagesize <= 1000) {
      this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
    }
  }
  onclickFirst(e) {
    let paging = this.state.paging
    paging.pg_page = 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onclickLast(e) {
    let paging = this.state.paging
    let countAll = global.rxu.get(this.state.extra, 'count', paging.pg_size) / this.state.paging.pg_size
    if (countAll > parseInt(countAll,10)) {
      paging.pg_page = parseInt(countAll,10) + 1
    } else {
      paging.pg_page = countAll
    }
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginBack (e) {
    let paging = this.state.paging
    paging.pg_page = (paging.pg_page > 1) ? (paging.pg_page - 1) : paging.pg_page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginNext (e) {
    let paging = this.state.paging
    paging.pg_page += 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  onClickPaginCur(e) {
    let paging = this.state.paging
    paging.pg_page = e
    this.setState({ paging: paging }, () => { this.fetchData() })
  }
  // U P L O A D  E V E N T
  callbackUpload (e, name) {
    this.onBlurData({ target: { value: e.path } }, 'path')
    this.onBlurData({ target: { value: e.images } }, name)
  }
  callbackUploadDetail (e, name) {
    this.onBlurDataDetail({ target: { value: e.images } }, name)
  }
  // H E L P E R S
  helpProductcat (cats) {
    let result = ''
    if (cats) {
      for (let i = 0; i < cats.length; i++) { result += cats[i].name + ', ' }
      return result
    }
  }
  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }
  onBlurStatusReviewClone(e, name) {
    if(name == "phanLoai"){
      this.setState({message: ''})
      this.setState({statusPhanLoaiClone: e})

      let editingData = this.state.editingData

      editingData['DonXacNhanCQCu'] = ""
      editingData['DonXacNhanCQMoi'] = ""
      editingData['AnhScanTheCu'] = ""
      editingData['DonXacNhanCapLaiThe'] = ""
      editingData['AnhScanTheHong'] = ""
      editingData['VanBanTiepNhanCTacMoi'] = ""
      editingData['LyDoCapLai'] = ""
      editingData['DonXacNhanMatThe'] = ""
      editingData['DonXacNhanCADoMatThe'] = ""

      this.setState({
        editingData: editingData,
        ngNhanDoiThe: ''
      })
    }else if(name == "doiThe"){
      this.setState({message: ''})
      this.setState({ngNhanDoiThe: e})

      let editingData = this.state.editingData

      editingData['DonXacNhanCQCu'] = ""
      editingData['DonXacNhanCQMoi'] = ""
      editingData['AnhScanTheCu'] = ""
      editingData['DonXacNhanCapLaiThe'] = ""
      editingData['AnhScanTheHong'] = ""
      editingData['VanBanTiepNhanCTacMoi'] = ""
      editingData['DonXacNhanMatThe'] = ""
      editingData['DonXacNhanCADoMatThe'] = ""

      e > 0 ? editingData['LyDoCapLai'] = e : editingData['LyDoCapLai'] = ''

      this.setState({
        editingData: editingData,
      })
    }else if(name == "maThe"){
      this.setState({maTheTimKiem: e.target.value})
      this.setState({showBtnCreateNew: ''})
    }else if(name == "checkCoThe"){
      this.setState({checkHadCard: !this.state.checkHadCard}, () => this.fetchData())
    }
  }
  alertMessage(alert) {
    this.setState({message: alert})
    setTimeout(() => {
      this.setState({message: ''})
    }, 3000)
  }
  findCheckCardExits(e){
    let find = {
      MaSoThe: this.state.maTheTimKiem,
      CoQuanBaoChiID: global.rxu.get(this.state.editingData, ['CoQuanBaoChiID'])
    }

    if(this.state.maTheTimKiem != "" && this.state.maTheTimKiem.length === 6) {
      apiget(rxu.get(rxu.config, 'api_card_checkCardExits'), find, {
        '1': (json) => {
          this.setState({ editingData: json.data }, () =>{
            let edit = this.state.editingData
            if(this.props.type == "?type=6"){
              edit["StatusPhanLoai"] = 6
            }else if(this.props.type == "?type=2"){
              edit["StatusPhanLoai"] = 2
            }

            edit._idHoSoClone = edit._id
            delete edit._id
            edit.LichSuDuyet = [{username: '', desc: '', action: '', created_at: 0}]
            edit.IsCheck1 = 0
            edit.IsCheck2 = 0
            edit.IsCheck3 = 0
            edit.IsCheck4 = 0
            edit.IsCheck5 = 0
            edit.IsStatus = 0
            edit.IsUpdateProfile = 0
            edit.phase = ''
            edit.phase_id = null
            edit.trace = ''
            edit.trace_name = ''
            edit.appdist = {fullname: '', _id: null}
            edit.ThamVan = [{email: '', file: '', content: '', subject: ''}]
            edit.phase_done = 0
            edit.code = 0
            edit.created_at = 1
            edit.MaTheCu = edit.MaSoThe
            edit.MaSoThe = null
            edit.checkHadCard = true
            // edit.updated_at = 0
            this.setStateRx({editingData: edit ,activeStep: false}, () => {
              this.onCancel(e)
              this.setState({
                maTheTimKiem: ''
              })
            })
          })
        },
        '-113': () =>{
          this.alertMessage('Không tìm thấy hồ sơ')
          this.setState({showBtnCreateNew: true})
        }
      })
    } else {
      this.alertMessage('Mã số thẻ nhà báo không đúng định dạng! Bao gồm 6 ký tự! ')
    }
  }

  // R E N D E R S
  render () {
    return this.renderComponent()
  }
}

RxCrudCard.defaultProps = { name: '', api: '', form: [],form2: [], dataCallback: () => {}, renderPage: () => {}, data: {} }

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth
})

const mapDispatchToProps = {}

const RxCrudCard_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(RxCrudCard)
export default RxCrudCard_
