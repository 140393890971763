/* global rxu */
import * as Jsx from './analytics.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget } = global.rootRequire('classes/request')

class cardComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this._mounted = false
    this.state = {
      flagUpdateCat: false,
      curCat: 0,
      cate: [],
      idDataEdit: [],
      type: global.rxu.get(this.props, 'location.search', 0),
      name: 'Danh sách biểu mẫu thống kê',
      temp: {}
    }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    this.fetchData()
    // this.fetchCate()
    // this.fetchPosition()
  }
  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.location.search !== this.props.location.search){
      let type = nextProps.location.search
      let name = type === '?type=1' ? 'HỒ SƠ CẤP MỚI' : (type === '?type=2' ? 'HỒ SƠ CẤP LẠI' : (type === '?type=3' ? 'HỒ SƠ CẤP ĐỔI' : (type === '?type=4' ? 'HỒ SƠ CẤP THEO KỲ HẠN' : 'TỔNG HỢP DANH SÁCH HỒ SƠ')))
      this.setState({type: type, name: name });
    }
  }
  // static getDerivedStateFromProps(nextProps, prevState){
  //    if(nextProps.location.search !== prevState.type){
  //      let type = nextProps.location.search
  //      // this.setState({type: type})
  //      return { type: type};
  //   }
  //   else return null;
  // }

  async checkAll(e) {
    const allCheckBox = document.getElementById('HoSoID-All').checked
    var listId = []
    if(allCheckBox === true){
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = true
                await listId.push(check[i].value)
            }
        }
        this.setState({idDataEdit: listId})
    }else{
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = false
            }
        }
        this.setState({idDataEdit: [] })
    }
  }

  async checkObject(e) {
    var checkedValue = null;
    var listId = []
    var inputElements = document.getElementsByClassName('css-checkboxShow');
    for(var i=0; inputElements[i]; ++i){
      if(inputElements[i].checked){
          checkedValue = inputElements[i].value;
          await listId.push(checkedValue)
      }
    }
    this.setState({idDataEdit: listId})
  }

  fetchData () {
    apiget(global.rxu.config.api_card, {}, { '1': (json) => {
      if (!rxu.get(this.state, ['temp', 'filterFields'])) {
        let temp = this.state.temp; temp.filterFields = rxu.get(json, ['data', 0])
        this.setStateRx({ temp })
      }
    } })
  }

  fetchCate (runCallback = false) {
    apiget(global.rxu.get(global.rxu.config, 'api_newspaper_office'), {}, {
      '1': (json) => {
        this.setState({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchPosition (runCallback = false) {
    apiget(global.rxu.get(global.rxu.config, 'api_position'), {}, {
      '1': (json) => {
        this.setState({ position: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat (e) {
    let paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

export default cardComponent
