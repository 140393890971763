/* global rxu, t */
import React from 'react'
// import Select from 'react-select';

global.isclient && require('jsoneditor/dist/jsoneditor.css')
let JSONEditor = global.isclient ? require('jsoneditor') : {}

const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const QRCode = require('qrcode.react')
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']
// const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Hồ sơ cấp theo kỳ hạn mới']

const optionCard  = global.optionCard
// T A B L E
export const renderTable = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead')}</thead><tbody>{vm.run('renderTableBody')}</tbody></MainTable>

export const renderTable1 = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead1')}</thead><tbody>{vm.run('renderTableBody1')}</tbody></MainTable>

export const renderTableHead1 = (vm) =>
  <tr>
    <th className='rxwidth--100'>Pic</th>
    <th className={vm.helpSortClass('name', ['rxwidth--220'])}>Name</th>
    <th className={vm.helpSortClass('created_at', ['rxwidth--120'])}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody1 = (vm) =>
  global.rxu.array(vm.state.data).map(perdata =>
    <tr key={perdata._id}>
      <td onClick={(e) => vm.onClickViewTable(e, perdata, 'a')}>{perdata.name}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      {vm.renderTableBodyBtns(vm, perdata)}
    </tr>)

export const renderTableHead = (vm) =>
  <tr className="rx-title__table">
    <th className='rxwidth--100'>STT</th>
    {vm.checkShow('Avatar')                 && <th className='rxwidth--100'>Ảnh thẻ</th>}
    {vm.checkShow('TenCoQuanBaoChi')        && <th className='rxwidth--100 table--name_office' onClick={(e) => vm.onClickSort(e, 'TenCoQuanBaoChi')}>Tên CQ báo chí đề nghị cấp thẻ</th>}
    {vm.checkShow('LoaiCoquan')             && <th className='rxwidth--100 table--name_office' onClick={(e) => vm.onClickSort(e, 'TenCoQuanBaoChi')}>Tên đơn vị công tác (Áp dụng với trường hợp công tác tại Đài TT - TH cấp huyện)</th>}
    {vm.checkShow('HoTenKhaiSinh')          && <th className='rxwidth--100 table--name_office' onClick={(e) => vm.onClickSort(e, 'HoTenKhaiSinh')}>Họ và tên</th>}
    {vm.checkShow('QuaTrinhHoatDongUpdate') && <th className='rxwidth--100 table--name_office'>Quá trình công tác</th>}
    {vm.checkShow('codeGen')                && <th className='rxwidth--100'>Số thẻ</th>}
    {vm.checkShow('IsStatus')               && <th className='rxwidth--100 table--name_small'>Tình trạng thẻ</th>}
    {vm.checkShow('CardType')               && <th className='rxwidth--100 table--name_small'>Loại hồ sơ</th>}
    {vm.checkShow('LoaiCoquan')             && <th className='rxwidth--100 table--name_small'>Loại cơ quan</th>}
    {vm.checkShow('GioiTinh')               && <th className='rxwidth--100 table--name_small'>Giới tính</th>}
    {vm.checkShow('NgaySinh')               && <th className='rxwidth--100 table--name_small'>Ngày tháng năm sinh</th>}
    {vm.checkShow('DanToc')                 && <th className='rxwidth--100 table--name_small'>Dân tộc</th>}
    {vm.checkShow('QueQuan')                && <th className='rxwidth--100 table--name_small'>Quê quán</th>}
    {vm.checkShow('NoiO')                   && <th className='rxwidth--100 table--name_small'>Nơi ở hiện nay</th>}
    {vm.checkShow('TenChucVu')              && <th className='rxwidth--100 table--name_small'>Chức vụ</th>}
    {vm.checkShow('ButDanh')                && <th className='rxwidth--100 table--name_small'>Bút danh thường dùng</th>}
    {vm.checkShow('TenDaoTao')              && <th className='rxwidth--100 table--name_small'>Giáo dục phổ thông</th>}
    {vm.checkShow('TruongHoc')              && <th className='rxwidth--100 table--name_small'>Trường học</th>}
    {vm.checkShow('NganhHoc')               && <th className='rxwidth--100 table--name_small'>Ngành học</th>}
    {vm.checkShow('HinhThucDaoTao')         && <th className='rxwidth--100 table--name_small'>Hình thức đào tạo</th>}
    {vm.checkShow('NamTotNghiep')           && <th className='rxwidth--100 table--name_small'>Năm tốt nghiệp</th>}
    {vm.checkShow('LyLuanChinhTri')         && <th className='rxwidth--100 table--name_small'>Lý luận chính trị</th>}
    {vm.checkShow('NgoaiNgu')               && <th className='rxwidth--100 table--name_small'>Ngoại ngữ</th>}
    {vm.checkShow('ChungChi')               && <th className='rxwidth--100 table--name_small'>Chứng chỉ tạo tạo, bồi dưỡng nghiệp vụ báo chí (nếu có)</th>}
    {vm.checkShow('DangVien')               && <th className='rxwidth--100 table--name_small'>Đảng viên</th>}
    {vm.checkShow('ChucDanhBaoChi')         && <th className='rxwidth--100 table--name_small'>Chức danh báo chí hiện nay</th>}
    {vm.checkShow('MatheCu')                && <th className='rxwidth--100 table--name_small'>Số thẻ nhà báo và kỳ hạn cũ</th>}
    {vm.checkShow('NgayKyHDLD')             && <th className='rxwidth--100 table--name_small'>Ngày tháng năm ký HDLD có đóng BHXH/Số sổ BHXH</th>}
    {vm.checkShow('cmnd_truoc')             && <th className='rxwidth--100 table--name_small'>Ảnh CMND/CCCD</th>}
    {vm.checkShow('SoYeuLyLich')            && <th className='rxwidth--100 table--name_small'>Sơ yếu lý lịch</th>}
    {vm.checkShow('BanSaoBangDHCD')         && <th className='rxwidth--100 table--name_small'>Bản sao bằng ĐH/CĐ</th>}
    {vm.checkShow('BanSaoHDLD')             && <th className='rxwidth--100 table--name_small'>Bản sao quyết định tuyển dụng</th>}
    {vm.checkShow('DanhSachTacPham')        && <th className='rxwidth--100 table--name_small'>Danh sách tác phẩm báo chí đã đăng phát</th>}
    {vm.checkShow('CMND')                   && <th className='rxwidth--100 table--name_small'>Số thẻ CCCD/CMND</th>}
    {vm.checkShow('img_card')               && <th className='rxwidth--100'>Thẻ trích xuất</th>}
    {vm.checkShow('created_at')             && <th className='rxwidth--100'>Ngày cấp</th>}
    {vm.checkShow('codeGen')                && <th className='rxwidth--100 table--name_small'>QRcode phục vụ kiểm tra số liệu</th>}
  </tr>

export const renderTableBody = (vm) =>
    global.rxu.array(vm.state.dataCard).map((perdata,index) => (<tr key={perdata._id}>
      <td className='text-center'>{index + 1}</td>
      {vm.checkShow('Avatar')                 && <td className='text-center'><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>}
      {vm.checkShow('TenCoQuanBaoChi')        && <td>{perdata.TenCoQuanBaoChi}</td>}
      {vm.checkShow('LoaiCoquan')             && <td>{rxu.rxparseTen('LoaiCoquan', perdata.LoaiCoquan)}</td>}
      {vm.checkShow('HoTenKhaiSinh')          && <td>{perdata.HoTenKhaiSinh}</td>}
      {vm.checkShow('QuaTrinhHoatDongUpdate') && <td>
          {
              global.rxu.array(perdata.QuaTrinhHoatDongUpdate).map((hoatDong, index) => (
                  hoatDong.time ? <p key={index}>{index+1}) {hoatDong.time} công tác tại {hoatDong.CoQuanBaoChi} </p> : ''
              ))
          }
      </td>}
      {vm.checkShow('codeGen')                && <td className='text-center'>{perdata.codeGen}</td>}
      {vm.checkShow('IsStatus')               && <td className='text-center'><span className={'betable__status betable__status_' +perdata.IsStatus}>{statusTinhtrang[perdata.IsStatus]}</span></td>}
      {vm.checkShow('CardType')               && <td>{rxu.rxparseTen('typeHS', perdata.CardType)}</td>}
      {vm.checkShow('LoaiCoquan')             && <td>{rxu.rxparseTen('LoaiCoquan', perdata.LoaiCoquan)}</td>}
      {vm.checkShow('GioiTinh')               && <td>{rxu.rxparseTen('GioiTinh', perdata.GioiTinh)}</td>}
      {vm.checkShow('NgaySinh')               && <td>{rxu.parseTime(perdata.NgaySinh, perdata.NgaySinhConvert)}</td>}
      {vm.checkShow('DanToc')                 && <td>{perdata.DanToc}</td>}
      {vm.checkShow('QueQuan')                && <td>{perdata.QueQuan}</td>}
      {vm.checkShow('NoiO')                   && <td>{perdata.NoiO}</td>}
      {vm.checkShow('TenChucVu')              && <td>{perdata.TenChucVu}</td>}
      {vm.checkShow('ButDanh')                && <td>{perdata.ButDanh}</td>}
      {vm.checkShow('TenDaoTao')              && <td>{perdata.TenDaoTao}</td>}
      {vm.checkShow('TruongHoc')              && <td>{perdata.TruongHoc}</td>}
      {vm.checkShow('NganhHoc')               && <td>{perdata.NganhHoc}</td>}
      {vm.checkShow('HinhThucDaoTao')         && <td>{perdata.HinhThucDaoTao}</td>}
      {vm.checkShow('NamTotNghiep')           && <td>{perdata.NamTotNghiep}</td>}
      {vm.checkShow('LyLuanChinhTri')         && <td>{perdata.LyLuanChinhTri}</td>}
      {vm.checkShow('NgoaiNgu')               && <td>{perdata.NgoaiNgu}</td>}
      {vm.checkShow('ChungChi')               && <td>{perdata.ChungChi}</td>}
      {vm.checkShow('DangVien')               && <td>{perdata.DangVien}</td>}
      {vm.checkShow('ChucDanhBaoChi')         && <td>{perdata.TenChucVu}</td>}
      {vm.checkShow('MaTheCu')                && <td className='text-center'>{perdata.MaTheCu}</td>}
      {vm.checkShow('NgayKyHDLD')             && <td>{perdata.NgayKyHDLD}</td>}
      {vm.checkShow('cmnd_truoc')             && <td className='text-center img_card'><RxUploadCMND images={perdata.cmnd_truoc} title='' disabled={1} single={1} /></td>}
      {vm.checkShow('SoYeuLyLich')            && <td className='text-center img_card'><RxUploadCMND images={perdata.SoYeuLyLich} title='' disabled={1} type='img_multi' /></td>}
      {vm.checkShow('BanSaoBangDHCD')         && <td className='text-center img_card'><RxUploadCMND images={perdata.BanSaoBangDHCD} title='' disabled={1} type='img_multi'/></td>}
      {vm.checkShow('BanSaoHDLD')             && <td className='text-center img_card'><RxUploadCMND images={perdata.BanSaoHDLD} title='' disabled={1} type='img_multi' /></td>}
      {vm.checkShow('DanhSachTacPham')        && <td>{
          global.rxu.array(perdata.DanhSachTacPham).map((hoatDong, index) => (
              hoatDong.Ten ? <p key={index}>{index+1}) {hoatDong.Ten}</p> : ''
          ))
      }</td>}
      {vm.checkShow('CMND')                   && <td className='text-center'>{perdata.CMND}</td>}
      {vm.checkShow('img_card')               && <td className='text-center img_card'><RxUploadCMND images={perdata.img_card} title='' single={1} disabled={1} type='img_card'/></td>}
      {vm.checkShow('created_at')             && <td>{rxu.parseTime(null, perdata.created_at)}</td>}
      {vm.checkShow('codeGen')                && <td className='text-center'><QRCode id={perdata.codeGen || '1'} value={perdata.codeGen || '1'} size={50} level={"H"} includeMargin={true} /></td>}
    </tr>))

export const renderTableBodyBtns = (context, perdata, vm) => {
  return <td>
    { context.state.paging.search_is_deleted !== 1 &&
    <div style={{float: 'right'}}> {(perdata.is_hot) ? <span className='betable__icohot'><span className='icon-fire' />Hot</span> : <span className='betable__iconothot' />}
      {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
      <span className='betable__btnedit' onClick={(e) => context.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
      <span className='betable__btndelete' onClick={(e) => context.onClickDataDelete(e, perdata)}><i className='icon-close' /></span> </div> }
    { context.state.paging.search_is_deleted === 1 &&
    <div> <span className='betable__restore' onClick={(e) => context.onClickDataRestore(e, perdata)}>Restore</span></div>}
  </td>
}

// P A G I N
export const renderPagin = (vm) => {
  let result = []
  let paging = vm.state.paging
  let listData = global.rxu.get(vm.state.extra, 'count', paging.pg_size)
  let total = 0
  if ((listData / paging.pg_size) > parseInt(listData / paging.pg_size,10)) {
    total = parseInt(listData / paging.pg_size,10) + 1
  } else {
    total = parseInt(listData / paging.pg_size,10)
  }
  if (paging.pg_page === 1) {
  } else {
    result.push(<div key={'first'} className='rxpage adtable__paginback adtable__paginfirst icomoon-Start' onClick={(e) => vm.onclickFirst(e)}></div>)
    result.push(<div key={'back'} className='rxpage adtable__paginback adtable__paginnext__active' onClick={(e) => vm.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>)
  }
  if (total > 5) {
    if (paging.pg_page === 1) {
      for (let i = 1; i <= (total); i++) {
        if (i < 4) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === 4) {
          result.push(<div key={i} className='adtable__pagingMore' >...</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
        }
      }
    } else {
      for (let i = 1; i <= (total); i++) {
        if (i === paging.pg_page - 1) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        } else if ((i === paging.pg_page) || (i === paging.pg_page + 1)) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === paging.pg_page + 2) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        }
      }
    }
  } else if (total === 0) {
    result.push(<div key={'none'} className='rxpage'>{paging.pg_page}</div>)
  } else {
    for (let i = 1; i <= (total); i++) {
      result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
    }
  }
  if (paging.pg_page < total) {
    result.push(<div key={'next'} className='rxpage adtable__paginnext adtable__paginnext__active' onClick={(e) => vm.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>)
    result.push(<div key={'last'} className='rxpage adtable__paginnext adtable__paginlast icomoon-End' onClick={(e) => vm.onclickLast(e)}></div>)
  }
  let start = (Number(paging.pg_page) - 1)*Number(paging.pg_size) + 1
  let end = Number(paging.pg_page) * Number(paging.pg_size)
  if(end > global.rxu.get(vm.state.extra, 'count', 0)) { end = global.rxu.get(vm.state.extra, 'count', 0) }

  return (<div className='rxpagingwrap'>
    <div className='rxpagingcount'>{start} - {end} trong tổng số <b>{global.rxu.get(vm.state.extra, 'count', 0)}</b></div>
    <div className='rxpaging'>
      <div className='rxpagesize'>
        <span>{t('Pagesize')}</span>
        <select value={paging.pg_size} onChange={e => vm.onChangePageSize(e)}>
          {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
        </select>
      </div>
      {result}
    </div>
  </div>)
}

export const renderSearch = (vm) =>
  <div className='rxcol-100'>
    <div className='betable adblock__inner'>
      <span className='betable__filter'>
        <div className='betable__btns'>
          <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
          <input className='betable__findinput' type='text' placeholder='Tìm ' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
          <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
          <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
        </div>
      </span>
    </div>
  </div>

// F I L T E R
export const renderFilter = (vm) =>
  <div>
    <div className='betable__filter'>
      <div className='betable__btns'>
        {(vm.flag('view') === 1) && vm.flag('view') === 1 && <span className='betable__btnexport btn' onClick={e => vm.toggle('view')}><i className='icon-logout betable__addbtn' />Quay lại</span> }
        {['viewer'].indexOf(global.rxu.get(vm.props.auth, ['user', 'user', 'role'], '')) === -1 && <span className='betable__btnadd btn' onClick={(e) => vm.onClickDataNew(e)}><i className='iconcustom-create betable__addbtn' />Thêm mới</span>}
        {vm.props.name === 'Analytics' && vm.flag('view') === 1 && <span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExportConfig(e)}><i className='iconcustom-export2' />Xuất file</span>}
        {/*}<span className='betable__btnexport btn' onClick={(e) => vm.toggle('renderExpand')}><i className='iconcustom-export2' />Chọn thuộc tính</span>*/}
      </div>
      { (vm.flag('renderExpand') ) && vm.flag('view') ? <div className='findexpand_body clearfix'>{vm.run('renderExpandComponent')}</div> : <div /> }
      {(vm.flag('view') === 1) && <div className='searchbox__toggle'>
        <input placeholder='Tìm kiếm nhanh' onChange={(e) => vm.onBlurDatafilter(e, 'text')}/>
        <span className='icon-magnifier searchbox__toggle-icon'/>
        <span onClick={e => vm.toggle('findExpand')}>Tìm kiếm nâng cao</span>
      </div>}
      {(vm.props.name === 'Analytics' && vm.flag('view') === 1) &&
        <div className='betable__btns'>
          <span className={'betable__btnimport btn' + (vm.state.paging['search_Type'] === 1 ? ' active' : '')}  onClick={(e) => vm.onBlurDataReview(1, 'Type')}>Cục báo chí</span>
          <span className={'betable__btnimport btn' + (vm.state.paging['search_Type'] === 2 ? ' active' : '')} onClick={(e) => vm.onBlurDataReview(2, 'Type')}>Cục PTTH&TTDT</span>
          <span className={'betable__btnimport btn' + (vm.state.paging['search_LoaiCoquan'] === 1 ? ' active' : '')} onClick={(e) => vm.onBlurDataReview(1, 'LoaiCoquan')}>Phòng Quản lý Báo chí</span>
          <span className={'betable__btnimport btn' + (vm.state.paging['search_LoaiCoquan'] === 2 ? ' active' : '')} onClick={(e) => vm.onBlurDataReview(2, 'LoaiCoquan')}>Phòng Kinh tế Báo chí</span>
          <div className='betable__typecard--select'>
            <RxSelectbox
              name='phase'
              options={vm.state.phase}
              results={vm.state.paging.phase || ''}
              onChange={(result) => { vm.onBlurDataReview(result, 'phase') }}
              api='api_cate'
              paging={vm.state.pagingPhase}
              optionDefault='--Chọn đợt thẩm định--'
              loader='load'
            />
          </div>
        </div>
      }
      { (vm.flag('findExpand')) ? <div className='findexpand_body clearfix'>{vm.run('renderFindExpand')}</div> : <div /> }
    </div>

  </div>

// F I N D  E X P A N D
export const renderFindExpand = (vm) => {
  return (
    <div>
      <span className='findexpand_close' onClick={e => vm.toggle('findExpand')}><i className='iconcustom-close'/>Đóng</span>
      <div className='col-md-12 rx-flex'>
        <div className="rx--box__title">BỘ LỌC</div>
        <div className='col-md-12' style={{paddingRight: '60px'}}>
          <div className='col-md-2'>
            <RxSelectbox name='LoaiCoquan' options={global.LoaiCoquan} results={vm.state.paging['search_LoaiCoquan'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'LoaiCoquan', 1) } } optionDefault='--Cơ quan trực thuộc--' />
          </div>
          {/*<div className='col-md-3'>
            <RxSelectbox name='theDaCap' options={global.theDaCap} results={vm.state.paging['search_Status'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'Status', 1) } } optionDefault='--Trạng thái hồ sơ--' />
          </div>*/}
          <div className='col-md-2'>
            <RxSelectbox name='cate' options={vm.state.cate} results={vm.state.paging['search_TenCoQuanBaoChi'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'TenCoQuanBaoChi', 1) }} api='api_newspaper_office' optionDefault='--Chọn cơ quan báo chí--' loader='load' />
          </div>
          <div className='col-md-2'>
            <RxSelectbox name='cate' options={vm.state.position} results={vm.state.paging['search_TenChucVu'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'TenChucVu', 1) }} api='api_position' optionDefault='--Nhóm chức vụ--' loader='load' />
          </div>
          <div className='col-md-2'>
            <RxSelectbox name='typeHS' options={global.typeHS} results={vm.state.paging['search_StatusPhanLoai'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'StatusPhanLoai', 1) }} optionDefault='--Chọn loại hồ sơ--' />
          </div>
          <div className='col-md-2'>
            <RxSelectbox name='IsStatus_Card' options={global.IsStatus_Card} results={vm.state.paging['searcharr_IsStatus'] || ''} onChange={(result) => { vm.onBlurDataFilterReview(result, 'IsStatus', 1) }} optionDefault='--Chọn trạng thái thẻ--' />
          </div>
          <div className='rx--btn__clearFilter'><span className="clearFilter" onClick={() => {vm.clearFilter()}}>Xóa bộ lọc</span></div>
        </div>
      </div>
      <div className="rx--box__search">
        <div className="col-md-12 rx-flex" style={{marginTop: '30px'}}>
          <div className="rx--box__title">TÌM KIẾM</div>
          <div className='col-md-12'>
            <div className='col-md-2 custom-search'>
              <input tabIndex='3' type='text' value={vm.state.paging['search_MaTheCu']} onChange={(e) => vm.onBlurDatafilter(e, 'MaTheCu')} className='fullwidth-input' placeholder='Mã thẻ' />
            </div>
            <div className='col-md-2 custom-search'>
              <input tabIndex='4' type='text' value={vm.state.paging['search_HoTenKhaiSinh']} onChange={(e) => vm.onBlurDatafilter(e, 'HoTenKhaiSinh')} className='fullwidth-input' placeholder='Nhập họ tên' />
            </div>

            <div className='col-md-2 custom-search'>
              <input tabIndex='1' type='text' value={vm.state.paging['search_TuTuoi']} onChange={(e) => vm.onBlurDatafilterOld(e, 'TuTuoi')} className='fullwidth-input' placeholder='Từ tuổi' />
            </div>
            <div className='col-md-2 custom-search'>
              <input tabIndex='2' type='text' value={vm.state.paging['search_DenTuoi']} onChange={(e) => vm.onBlurDatafilterOld(e, 'DenTuoi')} className='fullwidth-input' placeholder='Đến tuổi' />
            </div>
            <div className='col-md-2 rx--btn__search' onClick={(e) => vm.fetchData()}><span className='search_now'>TÌM KIẾM NGAY</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const renderExpandComponent = (vm) => {
  let filterFields = optionCard // rxu.get(vm.state, ['temp', 'filterFields'])
  let filterEles = []
  let optionParams = rxu.get(vm.state.editingData, 'optionParams', [])
  for (let i in filterFields) {
    let option = filterFields[i]
    filterEles.push(
      <div className='col-md-4' key={option.name}>
        <span className="news_inputcheck_radio">
            <input defaultChecked={optionParams.indexOf(option.id) !== -1 ? true : false} type="checkbox" className="css-checkboxShow" dataid={option.id} id="" value={option.id} onClick={(e) => vm.checkObject(e)} />
        </span>{option.name}
      </div>)
  }
  return <div className='rxfull'>
    {filterEles}
  </div>
}

// F O R M
export const renderForm = (vm) => {
  if (vm.state.editingData.created_at || vm.state.editingData.created_at === 0) {
    global.scrollfire()
    return <div className='adform'>
      <div className='adform__header'>
        {vm.run('renderFormHead')}
      </div>
      <div className='adform__body'>
        {vm.run('renderFormBody')}
        {/*vm.run('renderFormRaw')*/}
      </div>
      <div className='adform__footer'>
        {vm.run('renderFormBtns')}
      </div>
    </div>
  }
}

let renderFormBodyDefault = (vm) =>
  [ { name: 'Picture', func: () => (<RxUpload callback={(e) => vm.callbackUpload(e)} images={vm.state.editingData.img_landscape} />) },
    { type: 'devide' },
    { name: 'Name', func: () => (<input tabIndex='1' type='text' value={vm.state.editingData.name} onChange={(e) => vm.onBlurData(e, 'name')} className='fullwidth-input' />) },
    { name: 'Desc', func: () => (<input tabIndex='2' type='text' value={vm.state.editingData.desc} onChange={(e) => vm.onBlurData(e, 'desc')} className='fullwidth-input' />) },
    { type: 'devide' },
    { name: 'Status', func: () => (<RxToggle value={vm.state.editingData.is_active} onToggle={(newValue) => vm.onBlurDataValue(newValue, 'is_active')} />) } ]

export const renderFormBody = (vm) => {
  let view = global.rxu.get(vm.state,['flags','view'], 0)
  let tempform = []
  if(view) { tempform = vm.props.view } else { tempform = vm.props.form || renderFormBodyDefault(vm) }

  if ((vm.state.editingData.created_at || vm.state.editingData.created_at === 0) && tempform) {
    let form = tempform.map((perdata, index) => {
      perdata.type = perdata.type || 'input'
      let result = (<div />)
      switch (perdata.type) {
        case 'input':
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'fullinput':
          result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'devide':
          result = <div key={index} className='fullwidth-formdevide cleafix' />
          break
        case 'header':
          result = <div className='form-header' key={index}>{perdata.func(vm)}</div>
          break
        case 'form':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{perdata.func(vm)}</div></div>
          break
        case 'checkbox':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{vm.run('renderExpandComponent')}</div></div>
          break
        case 'table':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{vm.run('renderTableForm')}</div></div>
          break
        default:
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
      }
      return result
    })
    return form
  }
}

export const renderTableForm = (vm) => {
  let QuaTrinhHoatDong = global.rxu.get(vm.state, ['editingData', 'QuaTrinhHoatDongUpdate'])
  return (<div className='rx-table__form'>
      <table>
        <tbody>
          <tr>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>Thời hạn (từ năm n&agrave;o đến năm n&agrave;o)</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>Chức danh b&aacute;o ch&iacute;</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>C&ocirc;ng t&aacute;c tại cơ quan b&aacute;o ch&iacute; n&agrave;o</strong></p></td>
            <td className='rx-table__form-header' colSpan="2"><p><strong>Lương</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>&nbsp;Khen thưởng kỷ luật (thời gian cụ thể)</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>M&atilde; thẻ</strong></p></td>
            {!vm.state.view && <td className='rx-table__form-header adbtn__add' rowSpan="2" onClick={(e) => vm.onClickAddRowTable()}><p><strong>Thêm dòng mới</strong></p></td>}
          </tr>
          <tr>
            <td className='rx-table__form-header'><p><strong>Ngạch lương</strong></p></td>
            <td className='rx-table__form-header'><p><strong>Bậc lương</strong></p></td>
          </tr>
          {QuaTrinhHoatDong && QuaTrinhHoatDong.map((obj, index) => (
            !vm.state.view ? <tr key={index}>
              <td><input tabIndex='1' type='text' value={obj.time || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'time', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='2' type='text' value={obj.ChucDanhBaoChi ||''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'ChucDanhBaoChi', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='3' type='text' value={obj.CoQuanBaoChi || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'CoQuanBaoChi', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='4' type='text' value={obj.NgachLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'NgachLuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='5' type='text' value={obj.BacLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'BacLuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='6' type='text' value={obj.KhenThuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'KhenThuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='7' type='text' value={obj.MaThe || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'MaThe', index)} className='fullwidth-input' /></td>
              <td className='adbtn__clear' onClick={(e) => vm.onClickRowDel(index)}>Xoá hàng</td>
            </tr> : <tr key={index}>
              <td>{obj.time || ''}</td>
              <td>{obj.ChucDanhBaoChi ||''}</td>
              <td>{obj.CoQuanBaoChi || ''}</td>
              <td>{obj.NgachLuong || ''}</td>
              <td>{obj.BacLuong || ''}</td>
              <td>{obj.KhenThuong || ''}</td>
              <td>{obj.MaThe || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>)
}

export const renderFormHead = (vm) =>
  <div className='adform__name'>Thông tin
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
      {(vm.state.editingData.created_at !== 1 ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu</span>
      : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu</span>)}
    </div>
  </div>

export const renderFormRaw = (vm) => {
  if (vm.state.editingData._id) {
    if (vm.state.flags.rawedit) {
      setTimeout(() => {
        vm.jsoneditor = new JSONEditor(vm.jsonEditor, { mode: 'tree' })
        vm.jsoneditor.set(vm.state.editingData)
      }, 500)
    }

    return (
      <div className='clearfix adform__rawedit'>
        <div onClick={evt => vm.toggle('rawedit')} className='adform__raweditbtn'>RawEdit</div>
        { vm.state.flags.rawedit ? <div>
          <div className='adform__raweditcontent' ref={e => { vm.jsonEditor = e }} />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateSubmitRaw(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmitRaw(e)}>RawUpdate</div>
        </div> : <div /> }
      </div>
    )
  }
}

export const renderFormBtns = (vm) =>
  <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
    <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, {})} onKeyPress={(e) => vm.onClickDataEdit(e, {})}>{t('return')}</div>
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
      {!vm.state.view && (vm.state.editingData.created_at !== 1 ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu</span>
      : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu</span>)}
    </div>
    {/*{vm.state.editingData.created_at !== 1 && <div tabIndex='11' className='adform__btnedit' onClick={(e) => vm.onClickDataUpdateSubmit(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmit(e)}>{t('Update')}</div>}
    <div tabIndex='12' className='adform__btnclone' onClick={(e) => vm.onClickDataCreateSubmit(e)} onKeyPress={(e) => vm.onClickDataCreateSubmit(e)}>{t('Create new')}</div>*/}
  </div>

export const renderFormCustom = (vm) => {

}
export const renderComponent = (vm) =>
  <div>
    <div className='betable adblock__inner'>
      {vm.run('renderFilter')}
      <div className='adform__toggle'>
        {vm.run('renderPagin')}
        {(!vm.flag('view') || vm.flag('view') === 0) ? vm.run('renderTable1') :  vm.run('renderTable') }
      </div>
    </div>
    {vm.run('renderForm')}
  </div>
