import { combineReducers, createStore } from 'redux'
import {} from './global'

const { rxsetLocal, rxgetLocal, rxsetCookie } = global.rootRequire('classes/request')

let initialState = {
  cart: { status: false, carts: {} },
  favorite: { status: false, favorites: {} },
  auth: { status: false, user: {} },
  rxnav: { status: false, navs: {}, menu: {} },
  rxtheme: { status: false, color: '' },
  rxSearch: { params: {} },
  category: { status: false, categories: [] },
  viewed: { status: false, products: {} }
}

let initialStateLate = {
  cart: global.rxu.json(rxgetLocal('rxcart'), { status: false, carts: {} }),
  favorite: global.rxu.json(rxgetLocal('rxfavorite'), { status: false, favorites: {} }),
  auth: global.rxu.json(rxgetLocal('rxuser'), { status: false, user: {} }),
  rxnav: { status: false, navs: {}, menu: {} },
  rxtheme: global.rxu.json(rxgetLocal('rxtheme'), { status: false, color: '' }),
  rxSearch: global.rxu.json(rxgetLocal('rxsearch'), { params: {} }),
  category: global.rxu.json(rxgetLocal('rxcategory'), { status: false, categories: [] }),
  viewed: global.rxu.json(rxgetLocal('rxviewed'), { status: false, products: {} })
}

// A C T I O N S //
// Demo
export const activateGeod = geod => ({
  type: 'ACTIVATE_GEOD',
  geod
})

export const closeGeod = () => ({
  type: 'CLOSE_GEOD'
})

export const cartAdd = (id, payload) => ({
  type: 'CART_ADD',
  id: id,
  cart: payload
})

export const cartSub = (id, payload) => ({
  type: 'CART_SUB',
  id: id,
  cart: payload
})

export const cartDelete = (id) => ({
  type: 'CART_DELETE',
  id: id
})

export const cartClear = () => ({
  type: 'CART_CLEAR'
})

// Login
export const loginAction = (user) => ({
  type: 'LOGIN',
  user: user
})

export const logoutAction = () => ({
  type: 'LOGOUT'
})

// Status
export const rxnavToggle = (id, payload) => ({
  type: 'NAV_TOGGLE',
  id: id,
  cart: cart
})
export const rxmenuToggle = (id, payload) => ({
  type: 'MENU_TOGGLE',
  id: id,
  cart: cart
})

export const rxnavChange = (id, payload) => ({
  type: 'NAV_CHANGE',
  id: id,
  cart: cart
})

export const rxnavClose = (id, payload) => ({
  type: 'NAV_CLOSE',
  id: id,
  payload: payload
})

// Theme Color
export const rxthemeToggle = (id, payload) => ({
  type: 'THEME_TOGGLE',
  id: id
})

// Favorite
export const favoriteAdd = (id, payload) => ({
  type: 'FAVORITE_ADD',
  id: id,
  favorite: payload
})

export const favoriteDelete = (id) => ({
  type: 'FAVORITE_DELETE',
  id: id
})

export const favoriteClear = () => ({
  type: 'FAVORITE_CLEAR'
})

// Search params
export const rxsearchChange = (id, payload) => ({
  type: 'SEARCH_CHANGE',
  id: id,
  params: payload
})

export const rxsearchClear = () => ({
  type: 'SEARCH_CLEAR'
})

// Category
export const categoryAdd = (category) => ({
  type: 'CATEGORY_ADD',
  category: category
})

// Viewed Product
export const viewedAdd = (id, payload) => ({
  type: 'VIEWED_ADD',
  id: id,
  product: payload
})

let calCart = function (cart) {
  let count = 0
  for (let i in cart) {
    count += cart[i].amount
  }

  return count
}

/// //////////////////
// R E D U C E R S //
/// //////////////////
export const geod = (state = {}, action) => {
  switch (action.type) {
    case 'ACTIVATE_GEOD':
      return action.geod

    case 'CLOSE_GEOD':
      return {}

    default:
      return state
  }
}

export const cart = (state = initialState.cart, action) => {
  switch (action.type) {
    case 'CART_ADD': {
      if (!state) { state = { status: false, carts: {} } }
      if (typeof (state.carts) === 'undefined') { state.carts = {} }
      state.carts[action.id] = state.carts[action.id] || { id: action.id, amount: 0 }
      state.carts[action.id].amount += action.cart.amount
      state.carts[action.id].option = action.cart
      if (typeof (action.cart) !== 'undefined' && typeof (action.cart.data) !== 'undefined' && action.cart.data.img_landscape) {
        state.carts[action.id].data = action.cart.data
      }

      let newState = { status: !state.status, carts: state.carts, total: calCart(state.carts) }
      rxsetLocal('rxcart', JSON.stringify(newState))

      return newState
    }

    case 'CART_SUB': {
      state.carts[action.id] = state.carts[action.id] || { id: action.id, amount: 0 }

      // Subtract
      if ((state.carts[action.id].amount - action.cart.amount) >= 1) {
        state.carts[action.id].amount -= action.cart.amount
      }

      if (typeof (action.cart) !== 'undefined' && typeof (action.cart.data) !== 'undefined' && action.cart.data.img_landscape) {
        state.carts[action.id].data = action.cart.data
      }

      let newState = { status: !state.status, carts: state.carts, total: calCart(state.carts) }
      rxsetLocal('rxcart', JSON.stringify(newState))

      return newState
    }

    case 'CART_DELETE': {
      delete state.carts[action.id]

      let newState = { status: !state.status, carts: state.carts, total: calCart(state.carts) }
      rxsetLocal('rxcart', JSON.stringify(newState))

      return newState
    }

    case 'CART_CLEAR': {
      let newState = { status: !state.status, carts: {}, total: 0 }
      rxsetLocal('rxcart', JSON.stringify(newState))

      return newState
    }

    default: {
      return state
    }
  }
}

export const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case 'LOGIN': {
      if (!state) { state = { status: false, user: {} } }
      if (typeof (state.user) === 'undefined') { state.user = {} }

      if (typeof (action.user) !== 'undefined') {
        state.user = action.user
      }

      let newState = { status: !state.status, user: state.user }
      rxsetLocal('rxuser', JSON.stringify(newState))
      return newState
    }

    case 'LOGOUT': {
      let newState = { status: !state.status, user: {} }
      rxsetLocal('rxuser', JSON.stringify(newState))
      rxsetCookie('authorize', '', 120)
      return newState
    }

    default: {
      return state
    }
  }
}

export const rxnav = (state = initialState.rxnav, action) => {
  switch (action.type) {
    case 'NAV_TOGGLE': {
      state.navs[action.id] = typeof (state.navs[action.id]) === 'undefined' ? 0 : Boolean(state.navs[action.id])
      if (state.navs.constructor === Object) {
        Object.keys(state.navs).forEach(v => {return  state.navs[v] = false })
      }
      state.navs[action.id] = !state.navs[action.id]
      return {
        status: !state.status,
        navs: state.navs,
        menu: state.menu
      }
    }

    case 'MENU_TOGGLE': {
      state.menu[action.id] = typeof (state.menu[action.id]) === 'undefined' ? 0 : Boolean(state.menu[action.id])
      state.menu[action.id] = !state.menu[action.id]
      return {
        status: !state.status,
        navs: state.navs,
        menu: state.menu
      }
    }

    case 'NAV_CHANGE': {
      state.navs[action.id] = true
      return {
        status: true,
        navs: state.navs
      }
    }

    case 'NAV_CLOSE': {
      state.navs[action.id] = 0
      return {
        status: !state.status,
        navs: state.navs
      }
    }

    default: {
      return state
    }
  }
}

export const rxtheme = (state = initialState.rxtheme, action) => {
  switch (action.type) {
    case 'THEME_TOGGLE': {
      state.color = typeof (state.color) === 'undefined' ? 0 : state.color
      state.color = action.id

      let newState = {
        status: !state.status,
        color: state.color
      }
      rxsetLocal('rxtheme', JSON.stringify(newState))
      return newState
    }

    default: {
      return state
    }
  }
}

export const favorite = (state = initialState.favorite, action) => {
  switch (action.type) {
    case 'FAVORITE_ADD': {
      if (!state) { state = { status: false, favorites: {} } }
      if (typeof (state.favorites) === 'undefined') { state.favorites = {} }
      state.favorites[action.id] = state.favorites[action.id] || { id: action.id }

      if (typeof (action.favorite) !== 'undefined' && typeof (action.favorite.data) !== 'undefined' && action.favorite.data.img_landscape) {
        state.favorites[action.id].data = action.favorite.data
      }

      let newState = { status: !state.status, favorites: state.favorites }
      rxsetLocal('rxfavorite', JSON.stringify(newState))

      return newState
    }

    case 'FAVORITE_DELETE': {
      delete state.favorites[action.id]

      let newState = { status: !state.status, favorites: state.favorites }
      rxsetLocal('rxfavorite', JSON.stringify(newState))

      return newState
    }

    case 'FAVORITE_CLEAR': {
      let newState = { status: !state.status, favorites: {} }
      rxsetLocal('rxfavorite', JSON.stringify(newState))

      return newState
    }

    default: {
      return state
    }
  }
}

export const rxSearch = (state = initialState.rxSearch, action) => {
  switch (action.type) {
    case 'SEARCH_CHANGE': {
      let newState = { params: action.params }
      rxsetLocal('rxsearch', JSON.stringify(newState))
      return newState
    }

    case 'SEARCH_CLEAR': {
      let newState = { params: { st_full: 'created_at:desc', st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 12, price: { min: 100, max: 10000 } } }
      rxsetLocal('rxsearch', JSON.stringify(newState))
      return newState
    }

    default: {
      return state
    }
  }
}

export const category = (state = initialState.category, action) => {
  switch (action.type) {
    case 'CATEGORY_ADD': {
      if (!state) { state = { status: true, categories: [] } }
      if (typeof (action.category) === 'undefined') { action.category = [] }
      let newState = { status: state.status, categories: action.category }
      rxsetLocal('rxcategory', JSON.stringify(newState))
      return newState
    }

    default: {
      return state
    }
  }
}

export const viewed = (state = initialState.viewed, action) => {
  switch (action.type) {
    case 'VIEWED_ADD': {
      if (!state) { state = { status: false, products: {} } }
      if (typeof (state.products) === 'undefined') { state.products = {} }
      state.products[action.id] = state.products[action.id] || { id: action.id }

      if (typeof (action.product) !== 'undefined' && typeof (action.product.data) !== 'undefined' && action.product.data.img_landscape) {
        state.products[action.id]['data'] = action.product.data
      }

      let newState = { status: !state.status, products: state.products }
      if (Object.keys(newState.products).length > 10) {
        delete (newState.products[Object.keys(newState.products)[0]])
      }

      rxsetLocal('rxviewed', JSON.stringify(newState))
      return newState
    }

    default: {
      return state
    }
  }
}

export const reset = (state = initialState, action) => {
  switch (action.type) {
    case 'LOG_OUT': {
      let newState = initialState
      return newState
    }

    case 'RESET_STORE': {
      let newState = initialStateLate
      return newState
    }

    default: {
      return state
    }
  }
}

export const reducers = combineReducers({
  geod, cart, auth, rxnav, favorite, rxSearch, category, viewed, rxtheme
})

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return reducers(initialStateLate, action)
  } else {
    return reducers(state, action)
  }
}

// S T O R E S //
export function configureStore (initialState = {}) {
  const store = createStore(rootReducer, initialState)
  store.subscribe(() => {})

  if (typeof window !== 'undefined') {
    window.store = store
  }

  return store
}

// Set store
if (typeof window !== 'undefined') {
  window.storeReset = () => {
    initialState = initialStateLate
    window.store.dispatch({ type: 'RESET_STORE' })
  }
}

export const store = configureStore()
