import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxUploadMulti = global.rootRequire('components/shares/rxUploadMulti').default
// const RxToggle = global.rootRequire('components/shares/rxToggle').default

const ROLES = ['Super','chuyenvienpt','chuyenvientw','chuyenviendp','chuyenvienkql','printer','profileedit']

export const renderCatHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className={inthis.helpSortClass('name', ['betable-th'])} onClick={(e) => inthis.onClickSort(e, 'name')} >Họ và tên</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  let tempData = global.rxu.array(inthis.state.data).slice(0)
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)} className='rx-cursor' ><td>{perdata.fullname}</td>
    </tr>))
}

export const renderTableHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className='rxwidth--100'>Ảnh</th>
    <th className={inthis.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'name')} >Tên </th>
    <th className='rxwidth--100'>Thuộc cơ quan báo chí</th>
    <th className={inthis.helpSortClass('created_at', ['betable-th'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>
    <th className='rxwidth--100 table--name_small'></th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td onClick={(e) =>inthis.onClickViewDataImg(e, perdata)}>{perdata.name}</td>
    <td>{perdata.CoQuanBaoChi}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {inthis.renderTableBodyBtns(inthis, perdata)}
  </tr>))

export const renderComponent = (vm) =>
  <AdminBlock name='SAO LƯU ẢNH' desc='Danh sách thư viện hình ảnh'>
    <div className='row'>
      <div className='col-md-12'>
        <RxCrud renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='media_list' api={'api_media'}
        form={[
          {type: 'header', func: (inthis) => (<div>
            <div style={{width: '100%'}}>
              <progress id="progressBar" value="0" max="100" style={{width: '50%'}}></progress>
              <span className='proges-text' id="status"></span>
              <div className='proges-total' id="loaded_n_total"></div>
            </div>
          </div>)},
          { type: 'header', func: (inthis) => {
            if(ROLES.indexOf(global.rxu.get(inthis.props.auth, ['user', 'user', 'role'])) !== -1) {
              return <div>
                <div className='rx-form__body--title'>Chọn cơ quan báo chí</div>
                <RxSelectbox name='cate' options={inthis.state.cate}
                  results={inthis.state.editingData['CoQuanBaoChi'] || ''}
                  onChange={(result) => { inthis.onBlurDataValue(result, 'CoQuanBaoChi_media') }}
                  api='api_newspaper_office'
                  optionDefault='--Chọn cơ quan báo chí--'
                  loader='load'
                />
              </div>
            } else {
              return <div/>
            }
          }},
          { type: 'form', name: 'Tải hình ảnh sao lưu', func: (inthis) => (<div>
              <div className='rx-form__item'>
                  <div>
                      <span>Hình ảnh (cho phép gửi dạng ảnh chưa nén, file pdf và dạng  nén zip, rar, 7z) </span>
                      <RxUploadMulti
                        callback={(e) => inthis.callbackUploadDetail(e, 'img_detail')}
                        images={inthis.state.editingData.img_detail}
                        editingData={inthis.state.editingData}
                        auth={global.rxu.get(inthis.props.auth, ['user', 'user'])}
                        title='Ảnh bản sao bằng đại học, cao đẳng ....'
                      />
                  </div>
              </div>


          </div>)},
        ]}
        view={[
          { type: 'form', name: 'Thành phần hồ sơ khác', func: (inthis) => (<div>
              <div className='rx-form__item'>
                  <div>
                      <span>Hình ảnh (cho phép gửi dạng ảnh chưa nén, file pdf và dạng  nén zip, rar, 7z)</span>
                      <RxUploadMulti images={inthis.state.editingData.img_detail} disabled='1' />
                  </div>
              </div>


          </div>)}
        ]}
        />
      </div>
    </div>
  </AdminBlock>
