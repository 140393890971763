import React, { Component } from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
global.isclient && require('./_app.css')


let adminCSS = '<style>.main-container{ width: 100% !important; padding: 0px !important; } .adtopbar{ display: none !important; }</style>'

class Component_ extends Component {
  constructor (props) {
    super(props)
    let d = new Date().getFullYear()
    this.state = {
      copy: `Copyright © ${d} TNB`,
    }
  }

  componentDidMount () {
    
  }

  fetchData () {
    
  }


  render () {
    return (<div>
      {Parser(adminCSS)}
      <div className="content__term">
      <div className="content__term--body">
        <div>
          <div className="content__1--title" style={{textAlign: 'center'}}>THỎA THUẬN CUNG CẤP VÀ SỬ DỤNG DỊCH VỤ ứng dụng Thẻ nhà báo</div>
          <hr/>
          <div className="content__term--desc">
            <div className="content__term--header">ĐIỀU 1. NGUYÊN TẮC CHUNG</div>
            <li>Trước khi đăng ký tài khoản để sử dụng dịch vụ trên ứng dụng Thẻ nhà báo (“Dịch vụ”), bạn xác nhận đã đọc, hiểu và đồng ý với tất cả các quy định trong Thỏa Thuận Cung Cấp Và Sử Dụng Dịch Vụ ứng dụng Thẻ nhà báo này (sau đây gọi tắt là “Thỏa thuận”) thông qua việc hoàn thành việc đăng ký tài khoản ứng dụng Thẻ nhà báo. Bạn chấp nhận không có bất kỳ giới hạn nào về và/hoặc liên quan tới tất cả các điều khoản và điều kiện dưới đây, kể từ thời điểm bạn sử dụng Dịch vụ lần đầu tiên.</li>
            <li>Khi xem xét việc sử dụng Dịch vụ cung cấp bởi Công ty Cổ phần Technology Convergence Corporation (sau đây gọi tắt là “chúng tôi” hoặc ứng dụng Thẻ nhà báo), bạn cam kết rằng bạn có đủ tuổi theo luật định để xác lập thỏa thuận có tính ràng buộc, và không phải là người bị ngăn cấm tiếp nhận Dịch vụ theo pháp luật Việt Nam, hoặc bạn đã có sự chấp thuận trước của cha mẹ hoặc người giám hộ hợp pháp của bạn để sử dụng Dịch vụ theo quy định và phù hợp với Thỏa thuận này.</li>
            <li>
              Đồng thời, khi đăng ký tài khoản và sử dụng Dịch vụ, bạn cũng cam kết và chấp nhận rằng:<br/>
              <ul className="ul">Nếu bạn muốn định danh tài khoản, bạn sẽ cung cấp đầy đủ, chính xác thông tin về họ tên; ngày, tháng, năm sinh; số chứng minh nhân dân/hộ chiếu/căn cước công dân (“Giấy tờ tùy thân”), ngày cấp, nơi cấp của Giấy tờ tùy thân, và chịu trách nhiệm về các thông tin mà bạn cung cấp khi thực hiện việc đăng ký cũng như sửa đổi, bổ sung các thông tin này;</ul><br/>
              <ul className="ul">Tất cả các giao tiếp, dữ liệu, văn bản, phần mềm, âm nhạc, âm thanh, hình chụp, hình họa, video, thư hoặc các tài liệu khác (sau đây gọi chung là "Thông tin"), cho dù được đăng công khai hoặc được truyền đưa riêng, trước tiên thuộc về trách nhiệm của người tạo ra Thông tin đó. Chúng tôi có hệ thống kiểm duyệt nội dung Thông tin được chia sẻ cũng như có cơ chể phát hiện và xử lý vi phạm đối với những Thông tin do những người sử dụng khác nhau cung cấp trên ứng dụng Thẻ nhà báo theo quy định của pháp luật Việt Nam;</ul><br/>
              <ul className="ul">Bạn có thể chia sẻ Thông tin hợp lệ và hợp pháp dưới các định dạng chúng tôi mặc định tại các khu vực cho phép. Là người sử dụng, bạn cần có trách nhiệm với các giao tiếp của riêng bạn và phải tự chịu trách nhiệm với kết quả của việc chia sẻ. Chúng tôi không đại diện, xác nhận hay cam đoan tính trung thực, chính xác của các Thông tin được chia sẻ.</ul><br/>
            </li>
            <li>Đối với Thông tin được bảo vệ theo quyền sở hữu trí tuệ như ảnh và video (“Thông tin SHTT”), bạn cấp riêng cho chúng tôi quyền sau: bạn cấp cho chúng tôi một giấy phép toàn cầu, không độc quyền, có thể chuyển nhượng, có thể cấp phép lại và miễn phí để sử dụng mọi Thông tin SHTT mà bạn đăng lên (“Giấy phép SHTT”). Giấy phép SHTT này hết hiệu lực khi bạn xóa Thông tin SHTT hoặc tài khoản, trừ khi Thông tin của bạn đã được chính bạn chia sẻ với người khác và những người này chưa xóa Thông tin đó trên ứng dụng Thẻ nhà báo.</li>
            <li>Khi chia sẻ Thông tin lên ứng dụng Thẻ nhà báo, bạn đồng ý cấp cho chúng tôi quyền sử dụng và chia sẻ Thông tin đó lên các nền tảng khác của hệ sinh thái ứng dụng Thẻ nhà báo thuộc quyền quản lý của chúng tôi hoặc các phương tiện thông tin đại chúng.</li><br/>

            <div className="content__term--header">ĐIỀU 2. QUY ĐỊNH VỀ ĐẶT TÊN TÀI KHOẢN</div>
            <li>Không được đặt tên tài khoản, nhân vật theo tên của danh nhân, tên của các vị lãnh đạo, tên của trùm khủng bố, phát xít, tội phạm, và tên của những cá nhân, tổ chức chống lại Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam, mà gây phương hại đến an ninh quốc gia, trật tự an toàn xã hội.</li>
            <li>Không được đặt tên tài khoản, nhân vật trùng hoặc tương tự gây nhầm lẫn với tên viết tắt, tên đầy đủ của cơ quan nhà nước, tổ chức chính trị, tổ chức chính trị - xã hội, tổ chức chính trị xã hội - nghề nghiệp, tổ chức xã hội, tổ chức xã hội - nghề nghiệp của Việt Nam và tổ chức quốc tế, nếu không được cơ quan, tổ chức đó cho phép.</li>
            <li>Không được đặt tên tài khoản, nhân vật trùng hoặc gây nhầm lẫn để giả mạo các cá nhân, tổ chức khác nhằm mục đích đưa thông tin sai sự thật, xuyên tạc, vu khống, xúc phạm uy tín của tổ chức, danh dự và nhân phẩm của cá nhân khác.</li>
            <li>Không được đặt tên tài khoản, nhân vật vi phạm các quyền sở hữu trí tuệ.</li>
            <li>Không được đặt tên có chứa các từ/cụm từ gây nhầm lẫn với các nền tảng trong hệ sinh thái của ứng dụng Thẻ nhà báo thuộc quyền quản lý của ứng dụng Thẻ nhà báo khi chưa có sự đồng ý bằng văn bản của ứng dụng Thẻ nhà báo.</li>
            <li>Tài khoản vi phạm quy định về đặt tên sẽ bị khoá và/hoặc xóa vĩnh viễn mà không cần thông báo.</li><br/>

            <div className="content__term--header">ĐIỀU 3. QUY ĐỊNH VỀ HÌNH ĐẠI DIỆN</div>
            <li>Không sử dụng hình ảnh có hàm ý kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong, mỹ tục của dân tộc.</li>
            <li>Không sử dụng hình ảnh hoặc hình ảnh mô tả có tính xúc phạm các danh nhân, anh hùng dân tộc, lãnh đạo Đảng và Nhà nước của Việt Nam và lãnh đạo của các cơ quan tổ chức quốc tế.</li>
            <li>Không sử dụng hình ảnh có chứa dấu hiệu trùng hoặc tương tự đến mức gây nhầm lẫn với biểu tượng, cờ, huy hiệu, tên viết tắt, tên đầy đủ của cơ quan nhà nước, tổ chức chính trị, tổ chức chính trị - xã hội, tổ chức chính trị xã hội - nghề nghiệp, tổ chức xã hội, tổ chức xã hội - nghề nghiệp của Việt Nam và tổ chức quốc tế mà xúc phạm đến uy tín của các tổ chức này.</li>
            <li>Không sử dụng các hình ảnh liên quan tới tôn giáo mà gây kích động, chia rẽ khối đại đoàn kết dân tộc, đi ngược lại chính sách tôn giáo của Việt Nam.</li>
            <li>Không sử dụng ảnh của tội phạm, khủng bố, phát xít, và ảnh hoặc hình ảnh mô tả các cá nhân, tổ chức chống lại Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam, gây phương hại đến an ninh quốc gia, trật tự an toàn xã hội.</li>
            <li>Không sử dụng ảnh xúc phạm uy tín của tổ chức, danh dự và nhân phẩm của cá nhân khác.</li>
            <li>Không sử dụng hình ảnh có chứa các từ/cụm từ, logo, dấu hiệu trùng hoặc tương tự gây nhầm lẫn với các nền tảng trong hệ sinh thái ứng dụng Thẻ nhà báo thuộc quyền quản lý của ứng dụng Thẻ nhà báo khi chưa có sự đồng ý bằng văn bản của ứng dụng Thẻ nhà báo.</li>
            <li>Không sử dụng hình ảnh vi phạm các quyền sở hữu trí tuệ.</li>
            <li>Tài khoản vi phạm quy định về hình đại diện sẽ bị khoá và/hoặc xóa vĩnh viễn mà không cần thông báo.</li><br/>

            <div className="content__term--header">ĐIỀU 4. CÁC THÔNG TIN CẤM CHIA SẺ, TRAO ĐỔI, CHIA SẺ TRÊN ứng dụng Thẻ nhà báo</div>
            <li>Thông tin chống lại Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam; gây phương hại đến an ninh quốc gia, trật tự an toàn xã hội; tổ chức, hoạt động, câu kết, xúi giục, mua chuộc, lừa gạt, lôi kéo, đào tạo, huấn luyện người chống Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam.</li>
            <li>Tuyên truyền chiến tranh, khủng bố.</li>
            <li>Xúi giục, lôi kéo, kích động người khác phạm tội.</li>
            <li>Xuyên tạc lịch sử, phủ nhận thành tựu cách mạng, phá hoại khối đại đoàn kết toàn dân tộc, xúc phạm tôn giáo, phân biệt đối xử về giới, phân biệt chủng tộc, tôn giáo; gây hận thù, mâu thuẫn giữa các dân tộc, sắc tộc, tôn giáo.</li>
            <li>Thông tin kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong, mỹ tục của dân tộc.</li>
            <li>Tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối ngoại và những bí mật khác do pháp luật Việt Nam quy định.</li>
            <li>Thông tin xuyên tạc, vu khống, xúc phạm uy tín của tổ chức, danh dự và nhân phẩm của cá nhân.</li>
            <li>Quảng cáo, tuyên truyền, mua bán hàng hóa, dịch vụ bị cấm; truyền bá tác phẩm báo chí, văn học, nghệ thuật, xuất bản phẩm bị cấm.</li>
            <li>Giả mạo tổ chức, cá nhân và phát tán thông tin giả mạo, thông tin sai sự thật xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân.</li>
            <li>Thông tin vi phạm quyền sở hữu trí tuệ.</li>
            <li>Các Thông tin khác vi phạm tới quyền và lợi ích hợp pháp của cá nhân, tổ chức.</li><br/>

            <div className="content__term--header">ĐIỀU 5. CÁC HÀNH VI BỊ CẤM KHÁC</div>
            <li>Cản trở trái phép hoạt động của hệ thống máy chủ tên miền quốc gia Việt Nam ".vn", hoạt động hợp pháp của hệ thống thiết bị cung cấp dịch vụ Internet và thông tin trên mạng.</li>
            <li>Cản trở trái phép việc cung cấp và truy cập thông tin hợp pháp, việc cung cấp và sử dụng các dịch vụ hợp pháp trên Internet của tổ chức, cá nhân.</li>
            <li>Sử dụng trái phép mật khẩu, khoá mật mã của các tổ chức, cá nhân; thông tin riêng, thông tin cá nhân và tài nguyên Internet.</li>
            <li>Tạo đường dẫn trái phép đối với tên miền hợp pháp của tổ chức, cá nhân. Tạo, cài đặt, phát tán các phần mềm độc hại, vi rút máy tính; xâm nhập trái phép, chiếm quyền điều khiển hệ thống thông tin, tạo lập công cụ tấn công trên Internet.</li>
            <li>Sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào dịch vụ của ứng dụng Thẻ nhà báo.</li>
            <li>Phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của dịch vụ cung cấp hoặc hệ thống máy chủ.</li>
            <li>Đăng nhập trái phép hoặc tìm cách đăng nhập trái phép hoặc gây thiệt hại cho hệ thống máy chủ.</li>
            <li>Quấy rối, chửi bới, làm phiền hay có bất kỳ hành vi thiếu văn hoá nào đối với người sử dụng khác.</li>
            <li>Hành vi, thái độ làm tổn hại đến uy tín của ứng dụng Thẻ nhà báo và/hoặc các dịch vụ của ứng dụng Thẻ nhà báo dưới bất kỳ hình thức hoặc phương thức nào.</li>
            <li>Quảng bá bất kỳ sản phẩm/dịch vụ dưới bất kỳ hình thức nào mà không tuân thủ theo thỏa thuận cung cấp và sử dụng dịch vụ và chính sách quảng cáo của ứng dụng Thẻ nhà báo.</li>
            <li>Đánh bạc và tổ chức đánh bạc trên ứng dụng Thẻ nhà báo.</li>
            <li>Các hành vi cấm khác theo quy định của pháp luật Việt Nam trong từng lĩnh vực.</li><br/>

            <div className="content__term--header">ĐIỀU 6. QUYỀN CỦA NGƯỜI SỬ DỤNG ứng dụng Thẻ nhà báo</div>
            <li>Bạn được quyền thay đổi, bổ sung thông tin cá nhân, mật khẩu đã đăng ký.</li>
            <li>Bạn được hướng dẫn cách đặt mật khẩu an toàn; nhập các thông tin quan trọng để bảo vệ tài khoản; sử dụng tài khoản liên kết để đăng nhập tài khoản.</li>
            <li>Bạn được quyền tặng cho tài khoản ứng dụng Thẻ nhà báo của mình cho người khác. Quyền được tặng cho tài khoản chỉ được áp dụng đối với tài khoản đã đăng ký đầy đủ và chính xác các thông tin tài khoản theo quy định Thỏa thuận này.</li>
            <li>Được bảo đảm bí mật thông tin cá nhân theo quy định của Thỏa thuận này và Chính sách Bảo mật Thông tin cá nhân trên ứng dụng Thẻ nhà báo. Theo đó, những thông tin cá nhân mà bạn cung cấp sẽ chỉ được ứng dụng Thẻ nhà báo sử dụng để kiểm soát các hoạt động trên ứng dụng Thẻ nhà báo và sẽ không được cung cấp cho bất kỳ bên thứ ba nào khác khi chưa có sự đồng ý của bạn, trừ trường hợp có sự yêu cầu từ cơ quan Nhà nước có thẩm quyền theo đúng quy định của pháp luật Việt Nam.</li>
            <li>Được quyền yêu cầu ứng dụng Thẻ nhà báo cung cấp thông tin cần thiết liên quan đến việc sử dụng Dịch vụ.</li><br/>
            
            <div className="content__term--header">ĐIỀU 7. NGHĨA VỤ CỦA NGƯỜI SỬ DỤNG ứng dụng Thẻ nhà báo</div>
            <li>Để nhận được sự hỗ trợ từ ứng dụng Thẻ nhà báo, tài khoản của bạn phải đăng ký đầy đủ các thông tin trung thực, chính xác.</li>
            <li>Bạn có trách nhiệm tự bảo mật thông tin tài khoản của bạn trên ứng dụng Thẻ nhà báo bao gồm: Mật khẩu, số điện thoại bảo vệ tài khoản, thông tin Giấy tờ tùy thân, email bảo vệ tài khoản. Nếu những thông tin trên bị tiết lộ do sự bất cẩn hay bất kỳ lỗi bảo mật nào khác của chính cá nhân bạn, thì bạn phải chấp nhận những rủi ro phát sinh. Chúng tôi sẽ căn cứ vào những thông tin hiện có trong tài khoản để làm căn cứ quyết định chủ sở hữu tài khoản nếu có tranh chấp và chúng tôi sẽ không chịu trách nhiệm về mọi tổn thất phát sinh. Thông tin Giấy tờ tùy thân đăng ký trong tài khoản là thông tin quan trọng nhất để chứng minh chủ sở hữu tài khoản.</li>
            <li>Bạn đồng ý sẽ thông báo ngay cho ứng dụng Thẻ nhà báo về bất kỳ trường hợp nào sử dụng trái phép tài khoản và mật khẩu của bạn hoặc bất kỳ các hành động phá vỡ hệ thống bảo mật nào bằng cách gửi yêu cầu hỗ trợ tại địa chỉ <a href="https://www.facebook.com/NetaloSocial" target="_blank" rel="noopener noreferrer">https://www.facebook.com/NetaloSocial.</a></li>
            <li>Chúng tôi quan tâm tới sự an toàn và riêng tư của tất cả thành viên đăng ký tài khoản và sử dụng Dịch vụ trên ứng dụng Thẻ nhà báo, đặc biệt là người chưa thành niên và người được giám hộ. Vì vậy, nếu bạn là cha mẹ hoặc người giám hộ hợp pháp, bạn có trách nhiệm xác định xem Thông tin nào trên ứng dụng Thẻ nhà báo thích hợp cho con của bạn hoặc người được bạn giám hộ. Tương tự, nếu bạn là người chưa thành niên hoặc người được giám hộ thì phải hỏi ý kiến cha mẹ hoặc người giám hộ hợp pháp của bạn để xác định xem liệu rằng Thông tin mà bạn sử dụng/truy cập trên ứng dụng Thẻ nhà báo có phù hợp với bạn hay không.</li>
            <li>Bạn phải chịu hoàn toàn trách nhiệm trước pháp luật Việt Nam về Thông tin do bạn chia sẻ, truyền, đưa, lưu trữ trên ứng dụng Thẻ nhà báo, mạng Internet, mạng viễn thông.</li>
            <li>Bạn phải bồi thường toàn bộ thiệt hại cho ứng dụng Thẻ nhà báo trong trường hợp bạn có hành vi vi phạm bất kỳ quy định nào tại Thỏa thuận này và/hoặc hành vi vi phạm pháp luật dẫn đến tổn thất, thiệt hại về tài sản và uy tín của ứng dụng Thẻ nhà báo.</li>
            <li>Tuân thủ các quy định về bảo đảm an toàn thông tin, an ninh thông tin và các quy định khác có liên quan theo quy định của pháp luật Việt Nam và Thỏa thuận này.</li>
            <li>Tuân thủ các trách nhiệm, nghĩa vụ khác của người sử dụng mạng xã hội, Internet, mạng viễn thông theo quy định của pháp luật Việt Nam.</li><br/>

            <div className="content__term--header">ĐIỀU 8. QUYỀN CỦA ứng dụng Thẻ nhà báo</div>
            <li>Nếu bạn cung cấp bất kỳ thông tin nào không trung thực, không chính xác, hoặc nếu ứng dụng Thẻ nhà báo có cơ sở để nghi ngờ rằng thông tin đó không chính xác hoặc nếu bạn vi phạm bất cứ điều khoản nào trong Thỏa thuận này, ứng dụng Thẻ nhà báo có toàn quyền chấm dứt, xóa bỏ tài khoản của bạn mà không cần sự đồng ý của bạn và không phải chịu bất cứ trách nhiệm nào đối với bạn. Đồng thời, ứng dụng Thẻ nhà báo có quyền từ chối hỗ trợ và giải quyết các yêu cầu đối với tài khoản của bạn.</li>
            <li>Nếu bạn có những bình luận, bài đăng vô nghĩa và/hoặc bình luận, bài đăng liên tục gây phiền toái tới người sử dụng khác thì ứng dụng Thẻ nhà báo có quyền tắt tính năng bình luận và/hoặc đăng tải của bạn trong 01(một) ngày, 03(ba) ngày, hoặc lâu hơn, tùy thuộc vào mức độ vi phạm. Trong trường hợp bạn vi phạm nhiều lần, ứng dụng Thẻ nhà báo sẽ khóa tài khoản của bạn ít nhất 30 (ba mươi) ngày, hoặc hơn tuỳ thuộc vào tính chất và mức độ vi phạm</li>
            <li>Khi ứng dụng Thẻ nhà báo phát hiện những vi phạm của bạn trong quá trình sử dụng Dịch vụ như truy cập trái phép, chia sẻ, truyền đưa các Thông tin bị cấm theo Thỏa thuận này và/hoặc theo quy định của pháp luật Việt Nam, hoặc những lỗi khác gây ảnh hưởng tới quyền và lợi ích hợp pháp của ứng dụng Thẻ nhà báo và/hoặc cá nhân, tổ chức có liên quan, ứng dụng Thẻ nhà báo có quyền: (i) tước bỏ mọi quyền lợi của bạn trong Thỏa thuận này, bao gồm chấm dứt, xóa bỏ tài khoản của bạn mà không cần sự đồng ý của bạn và không phải chịu bất cứ trách nhiệm nào với bạn; và/hoặc (ii) sử dụng những thông tin mà bạn cung cấp khi đăng ký tài khoản để chuyển cho Cơ quan chức năng giải quyết các vi phạm này theo quy định của pháp luật Việt Nam.</li><br/>

            <div className="content__term--header">ĐIỀU 9. TRÁCH NHIỆM CỦA ứng dụng Thẻ nhà báo</div>
            <li>Có trách nhiệm hỗ trợ bạn trong quá trình sử dụng Dịch vụ.</li>
            <li>Nhận và giải quyết các khiếu nại, tranh chấp của bạn phát sinh trong quá trình sử dụng Dịch vụ trong thẩm quyền của ứng dụng Thẻ nhà báo theo quy định của pháp luật Việt Nam. Tuy nhiên chúng tôi chỉ hỗ trợ, nhận và giải quyết các khiếu nại, tranh chấp đối với tài khoản đăng ký đầy đủ thông tin, trung thực và chính xác.</li>
            <li>Có trách nhiệm bảo mật thông tin cá nhân của bạn theo quy định của Thỏa thuận này, Chính sách Bảo mật Thông tin cá nhân trên ứng dụng Thẻ nhà báo và quy định của pháp luật Việt Nam. Chúng tôi không bán hoặc trao đổi những thông tin cá nhân của bạn với bên thứ ba, trừ trường hợp theo yêu cầu của cơ quan Nhà nước có thẩm quyền theo quy định của pháp luật Việt Nam và/hoặc các trường hợp theo quy định tại Thỏa thuận này.</li><br/>
            
            <div className="content__term--header">ĐIỀU 10. CƠ CHẾ XỬ LÝ VI PHẠM</div>
            <li>Nếu bạn vi phạm Thỏa thuận này, tùy thuộc vào mức độ vi phạm, chúng tôi sẽ khóa tài khoản của bạn tạm thời hoặc vĩnh viễn hoặc xóa tài khoản vĩnh viễn, tước bỏ mọi quyền lợi của bạn đối các Dịch vụ và sẽ yêu cầu cơ quan chức năng xử lý hành vi vi phạm theo quy định của pháp luật Việt Nam.</li>
            <li>Trường hợp hành vi vi phạm của bạn chưa được quy định trong Thỏa thuận này thì tùy vào tính chất, mức độ của hành vi vi phạm, ứng dụng Thẻ nhà báo sẽ đơn phương, toàn quyền quyết định mức xử phạt mà ứng dụng Thẻ nhà báo cho là hợp lý.</li>
            <li>Nếu có khiếu nại về Thông tin bị xem là vi phạm và/hoặc bất kỳ vấn đề nào khác liên quan đến cơ chế xử lý vi phạm quy định tại Thỏa thuận này, vui lòng gửi yêu cầu hỗ trợ tại địa chỉ <a href="https://www.facebook.com/NetaloSocial" target="_blank" rel="noopener noreferrer">https://www.facebook.com/NetaloSocial</a></li><br/>
            
            <div className="content__term--header">ĐIỀU 11. CẢNH BÁO VỀ RỦI RO KHI LƯU TRỮ, TRAO ĐỔI VÀ CHIA SẺ THÔNG TIN TRÊN MẠNG</div>
            <li>Khi bạn đăng ký, sử dụng tính năng đăng nhập liên quan đến các nền tảng và ứng dụng của bên thứ ba, điều đó đồng nghĩa với việc các thông tin của bạn sẽ được chia sẻ cho bên thứ ba, dựa trên sự chấp thuận của bạn. Bằng việc chấp nhận sự chia sẻ này, bạn cũng sẽ chấp nhận những rủi ro kèm theo. Trong trường hợp này, bạn đồng ý và chấp nhận loại trừ trách nhiệm của ứng dụng Thẻ nhà báo liên quan tới việc thông tin, dữ liệu của bạn bị chia sẻ cho bên thứ ba.</li>
            <li>Nếu phát sinh rủi ro, thiệt hại trong trường hợp bất khả kháng bao gồm nhưng không giới hạn như chập điện, hư hỏng phần cứng, phần mềm, sự cố đường truyền Internet hoặc do thiên tai, hỏa hoạn, đình công, sự thay đổi của luật pháp, người sử dụng phải chấp nhận những rủi ro, thiệt hại đó. ứng dụng Thẻ nhà báo cam kết sẽ nỗ lực giảm thiểu những rủi ro, thiệt hại phát sinh tuy nhiên chúng tôi sẽ không chịu bất cứ trách nhiệm nào phát sinh trong các trường hợp này.</li><br/>
            
            <div className="content__term--header">ĐIỀU 12. LUẬT ÁP DỤNG VÀ CƠ QUAN GIẢI QUYẾT TRANH CHẤP</div>
            Thỏa thuận này được điều chỉnh bởi pháp luật Việt Nam. Bất kỳ tranh chấp nào phát sinh trong quá trình sử dụng Dịch vụ của bạn sẽ được giải quyết tại tòa án có thẩm quyền của Việt Nam, theo pháp luật hiện hành của Việt Nam.<br/>

            <div className="content__term--header">ĐIỀU 13. THỜI HẠN GIẢI QUYẾT KHIẾU NẠI, TRANH CHẤP</div>
            Bất kỳ khiếu nại, tranh chấp nào phát sinh từ Thỏa thuận này, thuộc thẩm quyền giải quyết của ứng dụng Thẻ nhà báo, phải được gửi tới ứng dụng Thẻ nhà báo trong vòng 01 (một) tháng kể từ ngày xảy ra hành vi dẫn đến khiếu nại, tranh chấp đó.<br/>

            <div className="content__term--header">ĐIỀU 14. ĐIỀU KIỆN VÀ PHƯƠNG THỨC GIẢI QUYẾT KHIẾU NẠI, TRANH CHẤP</div>
            <li>Chúng tôi chỉ hỗ trợ, giải quyết khiếu nại, tranh chấp của bạn trong thẩm quyền của ứng dụng Thẻ nhà báo theo quy định của pháp luật Việt Nam, với điều kiện bạn đã ghi đầy đủ, trung thực và chính xác thông tin khi đăng ký tài khoản.</li>
            <li>Đối với tranh chấp giữa người sử dụng với nhau hoặc giữa người sử dụng với ứng dụng Thẻ nhà báo, chúng tôi sẽ căn cứ ghi chép của hệ thống để giải quyết. Theo đó, chúng tôi sẽ bảo vệ quyền lợi tối đa cho người sử dụng đăng ký đầy đủ thông tin theo quy định.</li>
            <li>Khi có khiếu nại, tranh chấp giữa bạn và người sử dụng khác trên ứng dụng Thẻ nhà báo hoặc giữa bạn với ứng dụng Thẻ nhà báo, bạn có thể gửi yêu cầu giải quyết khiếu nại, tranh chấp tới ứng dụng Thẻ nhà báo tại địa chỉ <a href="https://www.facebook.com/NetaloSocial" target="_blank" rel="noopener noreferrer">https://www.facebook.com/NetaloSocial</a></li>
            <li>Trừ trường hợp pháp luật Việt Nam có quy định khác, quyết định giải quyết khiếu nại, tranh chấp của ứng dụng Thẻ nhà báo là quyết định cuối cùng và có hiệu lực.</li><br/>
            
            <div className="content__term--header">ĐIỀU 15. LOẠI TRỪ NGHĨA VỤ VÀ BỒI THƯỜNG</div>
            <li>Bạn đồng ý loại trừ ứng dụng Thẻ nhà báo khỏi tất cả các trách nhiệm, nghĩa vụ pháp lý, tố tụng phát sinh từ sự vi phạm của bạn trong quá trình sử dụng Dịch vụ.</li>
            <li>Bạn phải bồi thường toàn bộ thiệt hại cho ứng dụng Thẻ nhà báo, trong trường hợp bạn có hành vi vi phạm Thỏa thuận này và/hoặc hành vi vi phạm pháp luật dẫn đến tổn thất, thiệt hại về tài sản và uy tín của ứng dụng Thẻ nhà báo, bao gồm cả chi phí liên quan đến việc giải quyết như án phí, phí luật sư, phí thuê chuyên gia tư vấn và các chi phí khác có liên quan đến việc giải quyết vụ việc.</li><br/>
            
            <div className="content__term--header">ĐIỀU 16. THU THẬP, XỬ LÝ DỮ LIỆU CÁ NHÂN</div>
            <li>Quy trình đăng ký tài khoản sử dụng ứng dụng Thẻ nhà báo yêu cầu bạn cung cấp các thông tin cá nhân và mật khẩu. Việc cung cấp những thông tin khác cho chúng tôi hay không tùy thuộc vào quyết định của bạn.</li>
            <li>
              Chúng tôi không sử dụng thông tin của bạn vào mục đích bất hợp pháp. Chúng tôi được quyền cung cấp thông tin của bạn cho bên thứ ba trong các trường hợp, bao gồm nhưng không giới hạn:
              <ul className="ul">Chúng tôi được bạn chấp thuận;</ul><br/>
              <ul className="ul">Khi bạn đăng ký, sử dụng tính năng đăng nhập liên quan đến các nền tảng và ứng dụng của bên thứ ba và bạn đồng ý để ứng dụng Thẻ nhà báo cung cấp thông tin của bạn cho bên thứ ba đó;</ul><br/>
              <ul className="ul">Theo yêu cầu của cơ quan Nhà nước có thẩm quyền;</ul><br/>
            </li>
            <li>Trong quá trình sử dụng Dịch vụ, bạn đồng ý nhận tất cả thông báo, quảng cáo từ ứng dụng Thẻ nhà báo liên quan tới Dịch vụ qua thư điện tử, thư bưu chính hoặc điện thoại của bạn. Trong trường hợp bạn đăng ký sử dụng dịch vụ do bên thứ ba cung cấp thì những thông tin của bạn sẽ được chia sẻ với bên thứ ba, dựa trên sự chấp thuận của bạn. Bạn cũng đồng ý nhận tất cả thông báo từ bên thứ ba liên quan tới dịch vụ qua thư điện tử, thư bưu chính hoặc điện thoại của bạn.</li>
            <li>Chúng tôi có thể dùng thông tin của bạn để thực hiện các hoạt động nghiên cứu của ứng dụng Thẻ nhà báo để phát triển các dịch vụ nhằm phục vụ bạn tốt hơn.</li><br/>
            
            <div className="content__term--header">ĐIỀU 17. CHÍNH SÁCH BẢO MẬT THÔNG TIN CÁ NHÂN TRÊN ứng dụng Thẻ nhà báo</div>
            Chúng tôi luôn cố gắng đáp ứng các yêu cầu của pháp luật Việt Nam về bảo vệ thông tin cá nhân như được quy định trong Chính sách Bảo mật thông tin cá nhân trên ứng dụng Thẻ nhà báo nằm trong khả năng của chúng tôi. Trong trường hợp bất khả kháng và/hoặc có sự tác động từ các yếu tố nằm ngoài khả năng kiểm soát của chúng tôi, chúng tôi sẽ không chịu trách nhiệm cho việc thông tin cá nhân của bạn bị tiết lộ.<br/>

            <div className="content__term--header">ĐIỀU 18. QUYỀN SỞ HỮU TRÍ TUỆ</div>
            <li>Chúng tôi sở hữu quyền sở hữu trí tuệ, bao gồm nhưng không giới hạn các quyền tác giả, quyền liên quan, nhãn hiệu, quyền chống cạnh tranh không lành mạnh, bí mật kinh doanh, và các quyền sở hữu trí tuệ khác trong tất cả các dịch vụ của ứng dụng Thẻ nhà báo. Việc sử dụng bất kỳ đối tượng quyền sở hữu trí tuệ nào của ứng dụng Thẻ nhà báo cần phải được chúng tôi cho phép trước bằng văn bản.</li>
            <li>Ngoài việc được cấp phép bằng văn bản, chúng tôi không cấp phép dưới bất kỳ hình thức nào khác cho dù đó là hình thức công bố hay hàm ý để bạn thực hiện các quyền trên. Và do vậy, bạn không có quyền sử dụng dịch vụ của ứng dụng Thẻ nhà báo vào bất kỳ mục đích nào mà không có sự cho phép bằng văn bản của chúng tôi.</li><br/>
            
            <div className="content__term--header">ĐIỀU 19. BẢO LƯU QUYỀN XỬ LÝ THÔNG TIN</div>
            <li>Tại các khu vực được phép chia sẻ Thông tin, bạn có thể chia sẻ Thông tin được phép dưới các định dạng chúng tôi mặc định và bạn phải tự chịu trách nhiệm đối với các Thông tin chia sẻ, giao tiếp về tính hợp pháp, cũng như các trách nhiệm pháp lý đối với Thông tin chia sẻ của bạn với cá nhân người sử dụng hoặc nhóm người sử dụng.</li>
            <li>Chúng tôi đóng vai trò như người hướng dẫn thụ động cho sự trình bày và công khai trực tuyến của Thông tin do người sử dụng chia sẻ nên trong mọi trường hợp, chúng tôi được bảo lưu quyền xử lý các Thông tin chia sẻ cho phù hợp với Thỏa thuận này và các quy định pháp luật Việt Nam. Nếu những Thông tin do người sử dụng chia sẻ không phù hợp với những thỏa thuận và điều kiện của Thỏa thuận này, chúng tôi có thể chỉnh sửa hoặc ngay lập tức loại bỏ (xóa) những Thông tin đó đồng thời báo với cơ quan chức năng nếu cần thiết.</li>
            <li>Việc xử lý Thông tin chia sẻ của chúng tôi tuân thủ theo các quy trình, chính sách dựa trên các quy định của pháp luật Việt Nam để đảm bảo quyền và lợi ích của bạn cũng như các cá nhân, tổ chức khác. Chúng tôi có nghĩa vụ và chịu trách nhiệm xử lý Thông tin chia sẻ theo yêu cầu của cơ quan nhà nước có thẩm quyền và/hoặc theo quy định của pháp luật Việt Nam.</li><br/>
            <div className="content__term--header">ĐIỀU 20. SỬA ĐỔI, BỔ SUNG</div>
            Các điều khoản tại Thỏa thuận này có thể được chúng tôi sửa đổi, bổ sung bất cứ lúc nào mà không cần phải thông báo trước tới bạn. Những Thông tin sửa đổi, bổ sung sẽ được chúng tôi công bố trên ứng dụng Thẻ nhà báo.<br/>

            <div className="content__term--header">ĐIỀU 21. HIỆU LỰC</div>
            <li>Thỏa thuận này có giá trị ràng buộc với bạn kể từ thời điểm hoàn thành việc đăng ký tài khoản ứng dụng Thẻ nhà báo.</li>
            <li>Trong trường hợp một hoặc một số điều khoản Thỏa thuận này xung đột với các quy định của luật pháp và bị tòa án có thẩm quyền của Việt Nam tuyên là vô hiệu theo quy định của pháp luật Việt Nam, điều khoản bị tuyên vô hiệu đó sẽ được chỉnh sửa cho phù hợp với quy định của pháp luật Việt Nam, và các điều khoản còn lại của Thỏa thuận này vẫn có hiệu lực.</li><br/>

            <div className="content__term--header">ĐIỀU 22. Kiểm duyệt người dùng đăng nội dung xấu</div>
            <li>Người dùng đăng nội dung được đánh dấu không phù hợp hoặc gây hại đến các tiêu chuẩn cộng đồng sẽ tự động bị chặn bởi đội quản trị ứng dụng Thẻ nhà báo để bảo vệ quyền lợi của người khác trên ứng dụng Thẻ nhà báo. Nội dung bị báo cáo sẽ xóa và loại bỏ người dùng đã cung cấp nội dung vi phạm trong vòng 24 giờ.</li><br/>

            <div className="content__term--header">ĐIỀU 23. Lọc các nội dung không được duyệt</div>
            <li>Các nội dung có chứa các từ khóa nhạy cảm, vi phạm pháp luật hiện hành của luật pháp Việt Nam sẽ không được duyệt và tự động bị chặn giữa cộng đồng người dùng trên ứng dụng Thẻ nhà báo (tham khảo các nội dung không được duyệt bên dưới).</li><br/>

            <div className="content__term--header">ĐIỀU 24. Các nội dung không được duyệt</div>
            Các nội dung không được duyệt trong ứng dụng ứng dụng Thẻ nhà báo bao gồm:<br/>
            Trích thông tin từ Luật An ninh mạng Việt Nam có hiệu lực từ ngày 01/01/2019<br/>
            Có tất cả 10 nội dung sẽ bị cấm đăng trên facebook, bao gồm:<br/>
            <li>Xuyên tạc lịch sử, phủ nhận thành tựu cách mạng, phá hoại khối đại đoàn kết toàn dân tộc, xúc phạm tôn giáo, phân biệt đối xử về giới, phân biệt chủng tộc;</li>
            <li>Thông tin sai sự thật gây hoang mang trong Nhân dân, gây thiệt hại cho hoạt động kinh tế – xã hội, gây khó khăn cho hoạt động của cơ quan nhà nước hoặc người thi hành công vụ, xâm phạm quyền và lợi ích hợp pháp của cơ quan, tổ chức, cá nhân khác;</li>
            <li>Đăng tải thông tin dâm ô, đồi trụy, tội ác; phá hoại thuần phong, mỹ tục của dân tộc, đạo đức xã hội, sức khỏe của cộng đồng;</li>
            <li>Nội dung tuyên truyền chống Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam bao gồm:
            Tuyên truyền xuyên tạc, phỉ báng chính quyền nhân dân;
            Chiến tranh tâm lý, kích động chiến tranh xâm lược, chia rẽ, gây thù hận giữa các dân tộc, tôn giáo và nhân dân các nước;
            Xúc phạm dân tộc, quốc kỳ, quốc huy, quốc ca, vĩ nhân, lãnh tụ, danh nhân, anh hùng dân tộc.</li>
            <li>Nội dung kích động gây bạo loạn, phá rối an ninh, gây rối trật tự công cộng bao gồm:
            Kêu gọi, vận động, xúi giục, đe dọa, gây chia rẽ, tiến hành hoạt động vũ trang hoặc dùng bạo lực nhằm chống chính quyền nhân dân;
            Kêu gọi, vận động, xúi giục, đe dọa, lôi kéo tụ tập đông người gây rối, chống người thi hành công vụ, cản trở hoạt động của cơ quan, tổ chức gây mất ổn định về an ninh, trật tự.</li>
            <li>Nội dung làm nhục, vu khống bao gồm:
            Xúc phạm nghiêm trọng danh dự, uy tín, nhân phẩm của người khác;
            Thông tin bịa đặt, sai sự thật xâm phạm danh dự, uy tín, nhân phẩm hoặc gây thiệt hại đến quyền và lợi ích hợp pháp của cơ quan, tổ chức, cá nhân khác.</li>
            <li>Nội dung xâm phạm trật tự quản lý kinh tế bao gồm:
            Thông tin bịa đặt, sai sự thật về sản phẩm, hàng hóa, tiền, trái phiếu, tín phiếu, công trái, séc và các loại giấy tờ có giá khác;
            Thông tin bịa đặt, sai sự thật trong lĩnh vực tài chính, ngân hàng, thương mại điện tử, thanh toán điện tử, kinh doanh tiền tệ, huy động vốn, kinh doanh đa cấp, chứng khoán.</li>
            <li>Thông tin thuộc bí mật nhà nước, bí mật công tác, bí mật kinh doanh, bí mật cá nhân, bí mật gia đình và đời sống riêng tư trái quy định của pháp luật.</li>
            <li>Nội dung quảng cáo, mua bán hàng hóa, dịch vụ thuộc danh mục cấm theo quy định của pháp luật;</li>
            <li>Nội dung vi phạm bản quyền và sở hữu trí tuệ.</li><br/>

            <div className="content__term--header">ĐIỀU 25. Báo cáo nội dung không phù hợp</div>
            Người dùng có thể báo cáo nội dung xấu, gây hại bằng cách chọn và giữ tin nhắn để chọn nút “Báo cáo” . Sau đó, người dùng có thể tùy chọn nội dung muốn báo cáo và bấm “Gửi”. Nội dung được báo cáo bao gồm các vi phạm sau:<br/>
            <li>Nội dung, hình ảnh khiêu dâm</li>
            <li>Ngôn từ gây thù ghét, đe dọa hoặc trực tiếp công kích một người dùng hoặc một cộng đồng người dùng</li>
            <li>Nội dung khuyến khích hành vi tự gây thương tích</li>
            <li>Tài khoản giả mạo</li>
            <li>Spam</li><br/>

            <div className="content__term--header">ĐIỀU 26. Chặn người dùng đăng nội dung xấu</div>
            <li>Chặn người dùng đăng nội dung xấu, gây hại đến các tiêu chuẩn cộng đồng</li>
            <li>Chặn hạn chế liên hệ với tài khoản lạm dụng và tiếp xúc với nội dung lạm dụng có thể giúp người dùng tự bảo vệ mình khỏi những hành vi không chính đáng, không phù hợp hoặc gây hại.</li>

            <li>Chặn cho phép bạn hạn chế liên lạc và giao tiếp với người dùng được đánh dấu là lạm dụng, cụ thể: 1) hạn chế quyền truy cập mà tài khoản có vào hồ sơ người dùng của bạn và 2) làm cho nội dung do tài khoản bị chặn không còn hiển thị với bạn. </li>

          </div>
        </div>
        <div className="footer">
          <img className="footer__logo" src="images/static/app/TNB_logo.png" alt=''/>
          <div className="rx-mobile"></div>
          <span className="footer__copy">{this.state.copy}</span>
        </div>
      </div>
    </div>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

const ComponentFaqs = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component_)

export default ComponentFaqs
