export function rxsecret () {
  let result = []
  result.web_port = 12000
   result.base_api = 'https://cmsthenhabao.mic.gov.vn'
   result.base_qrcode = 'https://thenhabao.mic.gov.vn/kiemtra?id='
  //result.base_api = 'https://api.the-farm.vn'
  //result.base_qrcode = 'https://cms.the-farm.vn/kiemtra?id='
  result.theme = 'default'
  return result
}
