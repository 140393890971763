import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { loginAction, logoutAction, rxnavToggle } from './../../redux'
import { apipost, rxsetCookie, rxsetLocal } from './../../classes/request'

class verifyComponent_ extends Component {
  constructor (props) {
    super(props)
    let slug = this.props.match.params

    this.mounted = true
    this.state = {

      editingData: {},
      msg: '',
      showPopup: false
    }
  }

  componentDidMount () { this._ismounted = true
    if (typeof navigator !== 'undefined') {
      let checkAndroid = (/Android|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent)
      if (checkAndroid) {
        window.location.href = global.rxu.config.base_android
      } else {
        // webOS|iPhone|iPad|iPod|
        window.location.href = global.rxu.config.base_ios;
      }
    }

  }
  componentWillUnmount () { this._ismounted = false }
  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.match.params.slug !== this.state.slug) {
      const slug = nextProps.match.params.slug
      const oldslug = this.state.slug
      this.setState({ slug: slug }, () => {
        // When new slug come, not from server
        if (oldslug) { this.fetchData() }
      })
    }
  }
  onBlurData (e, name) {
    let editingData = this.state.editingData
    editingData[name] = e.target.value
    this.setState({ editingData: editingData })
  }

  onClickLoginSubmit (e) {
    let editingData = this.state.editingData
    this.setState({ editingData: editingData }, () => {
      apipost(global.rxu.get(global.rxu.config, 'api_authorize'), this.state.editingData, {
        '1': (json) => {
          if (this._ismounted) {
            (json.data && json.data.user ) ? rxsetCookie('authorize', json.data.user.authorization, 30) : rxsetCookie('authorize', json.data.authorization, 30)
            rxsetLocal('arrper', json.data.arrper)
            rxsetLocal('rxuser', json.data)

            this.setState({ msg: '' })
            json.data = { ...json.data }
            this.props.loginAction(json.data)
            let role = global.rxu.get(json.data, 'user.role', 'member')
            role = role.toLowerCase()

            switch (role) {
              case 'super':
                this.props.history.push('/admin/card')
                this.props.rxnavToggle('ex_manager_card')
                break;
              case 'lanhdaobo':
                this.props.history.push('/admin/review_8')
                this.props.rxnavToggle('ex_manager')

                break;
              case 'lanhdaocuc01':
              case 'lanhdaocucbcdp':
              case 'lanhdaocucbctw':
              case 'lanhdaocucptth':
                this.props.history.push('/admin/review_3')
                this.props.rxnavToggle('ex_manager')
                break;
              case 'lanhdaodonvi':
              case 'lanhdaodp':
              case 'lanhdaotw':
              case 'lanhdaopt':
                this.props.history.push('/admin/review_2')
                this.props.rxnavToggle('ex_manager')
                break;
              case 'viewer':
              case 'member':
                this.props.history.push('/admin/card')
                this.props.rxnavToggle('ex_manager_card')
                break;
              default:
                this.props.history.push('/admin/review_1')
                this.props.rxnavToggle('ex_manager')
            }

          }
        },
        'default': (json) => {
          if (this._ismounted) {
            var strmsg = ''
            strmsg = json.msg
            this.props.logoutAction()
            this.setState({ msg: strmsg })
          }
        }
      })
    })
  }

  onClickShowPopup() {
    this.setState({showPopup: true})
  }

  render () {
    return (<div>
      <div className='rx-authpage'>
      <div className='authloginpage'>
          <form>
            <div className='authform' onKeyPress={(e) => { let charCode = e.which || e.charCode || e.keyCode || 0; if (charCode === 13) this.onClickLoginSubmit(e) }}>
              <div className='authform__head'>Kiểm tra </div>
              <p className='authform_msgerror'>{this.state.msg}</p>
            </div>
          </form>
        </div>{/*}*/}
      </div>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  logoutAction,
  loginAction,
  rxnavToggle
}

const verifyComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(verifyComponent_)

export default verifyComponent
