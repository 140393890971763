import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const { apiget } = global.rootRequire('classes/request')
const { cartClear, cartAdd, rxsearchClear, rxnavToggle, rxnavClose } = global.rootRequire('redux')

class Component_ extends Component {
  constructor (props) {
    super(props)

    this.maxPrice = 10000
    this.minPrice = 100

    this.timeout = null
    this.state = global.rootContext(this.props.staticContext) || { products: [], reviews: [], hotproducts: [] }
    this.state.products = this.state.products || []
    this.state.hotproducts = this.state.hotproducts || []
    this.state.reviews = this.state.reviews || []
    this.state.paging = {}
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.match.params.slug !== this.state.slug) {
      let slug = nextProps.match.params.slug
      let oldslug = this.state.slug
      this.setState({ slug: slug, filter: {}, paging: {} }, () => {
        if (oldslug) { this.fetchData() }
      })
    }
  }

  componentDidMount () {
    this.initData()
  }

  initData () {
    global.scrolltop()
    if (!this.state.products.length) {
      this.fetchData()
    }
  }

  fetchData () {
    let newPaging = this.state.paging
    newPaging.slug = this.state.slug
    apiget(global.rxu.config.api_site_product, newPaging, {
      '1': (json) => { this.setState({ products: json.data.products, hotproducts: json.data.hotproducts, cate: json.data.cate }) }
    })
  }

  // B L U R   E V E N T
  onBlurDataValue (value, name) {
    let editingData = this.state.editingData
    editingData[name] = value
    this.setState({ editingData: editingData })
  }

  onBlurDataPaging (e, name, prefix) {
    prefix = prefix || 'search_'
    let paging = this.state.paging
    paging[prefix + name] = e.target.value
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onBlurDataPagingValue (value, name, prefix) {
    prefix = prefix || 'search_'
    let paging = this.state.paging
    paging[prefix + name] = value
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickAddCart (e, product) {
    e.stopPropagation()
    this.props.cartAdd(product._id, { amount: 1, data: product, key: '' })
    this.props.rxnavClose('rxcart', {})
    this.props.rxnavToggle('rxcart', {})
  }

  render () {
    return (<div className='pcate'>
      <div className='container'>
        <div className='pcate__slide' />

        <div className='container row'>
          <div className='col-md-3 col-sm-3 col-xs-12 row'>
            <div className='pcate__search'>
              <div className='pcate__searchfield'>
                <div className='searchfield__namewrap'>
                  <input type='text' className='searchfield__name' value={this.state.paging.search_name || ''} onChange={(e) => this.onBlurDataPaging(e, 'name')} placeholder='Tìm kiếm ...' />
                  { this.state.paging.search_name ? <div className='searchfield__nameico icon-close' onClick={(e) => this.onBlurDataPagingValue('', 'name')} /> : <div className='searchfield__nameico icon-magnifier' /> }
                </div>

                <div className='searchfield__items' />

                <div className='searchfield__filters'>
                  <div className='searchfield__filter'>
                    <div className='searchfield__cate'>
                      <label>Tìm theo danh mục</label>
                      <div className='searchfield__cateitem'><Link to={'/cate'}>Tất cả</Link></div>
                      {this.state.cate ? this.state.cate.map(perobj =>
                        <div key={perobj._id} className='searchfield__cateitem'><Link to={'/cate'}>{perobj.name}</Link></div>
                      ) : <div />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-9 col-sm-9 col-xs-12 row'>
            <div className='pcate__products'>
              <div className='block__products'>
                {(this.state.products && Array.isArray(this.state.products)) ? this.state.products.map(perobj =>
                  <div className='col-md-4 col-sm-6 col-xs-12' key={perobj._id}>
                    <div className='block__productdetail block__productdetail--small'>
                      <div className='product__image'><img alt={perobj.name} src={global.rxu.config.base_api + '/upload/image/' + (perobj.img_landscape || 'ico_app_default.jpg')} /></div>
                      <div className='product__rate'>
                        <span className='icon-star nav-icon product__star' />
                        <span className='icon-star nav-icon product__star' />
                        <span className='icon-star nav-icon product__star' />
                        <span className='icon-star nav-icon product__star' />
                        <span className='icon-star nav-icon product__star' />
                      </div>
                      <div className='product__name'>{perobj.name}</div>
                      <div className='product__price'>
                        <span className='product__pricenew'>{global.rxu.strToMoney(perobj.price)}<span className='product__currency'>đ</span></span>
                      </div>
                      <div className='product__buy product__buy--small' onClick={(e) => this.onClickAddCart(e, perobj)}>Mua ngay</div>
                    </div>
                  </div>
                ) : <div /> }
              </div>
            </div>
          </div>
        </div>

        <div className='block__productwrap block__productwrap--fff'>
          <div className='block__product container'>
            <div className='block__producttitle'>Nổi bật nhất</div>
            <div className='block__products'>
              {this.state.hotproducts && Array.isArray(this.state.hotproducts) ? this.state.hotproducts.slice(0, 4).map(perobj =>
                <div className='col-md-3 col-sm-6 col-xs-12' key={perobj._id}>
                  <div className='block__productdetail'>
                    <div className='product__image'><img alt={perobj.name} src={global.rxu.config.base_api + '/upload/image/' + (perobj.img_landscape || 'ico_app_default.jpg')} /></div>
                    <div className='product__rate'>
                      <span className='icon-star nav-icon product__star' />
                      <span className='icon-star nav-icon product__star' />
                      <span className='icon-star nav-icon product__star' />
                      <span className='icon-star nav-icon product__star' />
                      <span className='icon-star nav-icon product__star' />
                    </div>
                    <div className='product__name'>{perobj.name}</div>
                    <div className='product__price'>
                      <span className='product__pricenew'>{global.rxu.strToMoney(perobj.price)}<span className='product__currency'>đ</span></span>
                    </div>
                    <div className='product__buy product__buy--small' onClick={(e) => this.onClickAddCart(e, perobj)}>Mua ngay</div>
                  </div>
                </div>
              ) : <div /> }
            </div>
          </div>
        </div>
      </div>

      <div className='block__parallax'>
        <div className='block__parallaxcontent'>
          <div className='block__parallaxtitle'>RXCODE GENERATOR</div>
          <div className='block__parallaxdesc'>Nhanh chóng, thuận tiện và tối ưu, bắt đầu phát triển phần mềm ngay bây giờ, với công nghệ mới nhất</div>
        </div>
        <div className='block__parallaxstroketop' />
        <div className='block__parallaxstrokebot' />
      </div>

    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  rxsearch: state.rxSearch
})

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  rxsearchClear,
  rxnavToggle,
  rxnavClose
}

const ComponentWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component_)

export default ComponentWrapped
