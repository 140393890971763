import React, { Component } from 'react'
import { connect } from 'react-redux'

const { apiget, rxsetCookie, rxsetLocal } = global.rootRequire('classes/request') //apipost,
const { cartClear, cartAdd, rxsearchClear, loginAction, logoutAction } = global.rootRequire('redux') //, loginAction, logoutAction

class ProductSingle_ extends Component {
  constructor(props) {
    super(props)
    this.mounted = true
    this.state = {}
  }


  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.match.params.slug !== this.state.slug) {
      let slug = nextProps.match.params.slug
      // let oldslug = this.state.slug
      this.setState({ slug: slug }, () => {})
    }
  }

  componentDidMount() {
    this._ismounted = true
    apiget(global.rxu.config.api_authorize_sso, {authorization: new URL(window.location.href).searchParams.get('authorization')}, {
      '1': (json) => {
        if (this._ismounted) {
          this.props.logoutAction();

          (json.data && json.data.user) ? rxsetCookie('authorize', json.data.user.authorization, 120) : rxsetCookie('authorize', json.data.authorization, 120)
          rxsetLocal('arrper', json.data.arrper)
          rxsetLocal('rxuser', json.data)

          this.setState({ msg: '' })
          json.data = { ...json.data }
          this.props.loginAction(json.data)

          // console.log(json.data)
          this.props.history.push('/admin/card')

        }
      },
      'default': (json) => {
        if (this._ismounted) {
          var strmsg = ''
          strmsg = json.msg
          this.props.logoutAction()
          this.setState({ msg: strmsg })
          this.props.history.push('/login')
        }
      }
    })
    this.initData()
  }

  componentWillUnmount () { this._ismounted = false }

  initData() {
  }

  fetchData() {
  }

  render() {
    return (<div></div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  rxsearch: state.rxSearch
})

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  rxsearchClear,
  loginAction,
  logoutAction
}

const ProductSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSingle_)

export default ProductSingle
