/* global rxu */
import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']
const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Cấp đổi theo kỳ hạn mới', 'Cấp mới', 'Cấp đổi do chuyển cơ quan', 'Cấp lại']
export const renderTableHead = (inthis, vm) =>
    <tr className='rx-title__table'>
      <th className='rxwidth--100'>STT</th>
      <th className={inthis.helpSortClass('CoQuanBaoChi', ['rx-th-width-220'])}>Cơ quan báo chí</th>
      {/*}<th className={inthis.helpSortClass('total', ['rx-th-width-220'])}>Số lượng</th>*/}
      <th className='rxwidth--100'></th>
    </tr>

export const renderTableBody = (inthis, vm) => {
  let data = vm.helperSortData(inthis.state.data)
  return global.rxu.array(data).map((perdata,index) => (<tr className='rx-xursor' key={perdata._id} onClick={(e) => inthis.onClickGroupEdit(e, perdata)}>
    <td>{index+1}</td>
    <td className='rx-cursor' onClick={(e) => inthis.onClickViewData(e, perdata)}>{perdata.TenCoQuanBaoChi[0]}</td>
    {/*}<td>{vm.countNumber(perdata)}</td>*/}
    <td></td>

  </tr>))
}
export const renderComponent = (vm) =>
  <AdminBlock name='TRẢ KẾT QUẢ HỒ SƠ' desc=''>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
      <RxCrudCard renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='return' api={'api_card_cardDone'} idEdit={vm.state.idEdit} type={vm.state.type}
      form={[
            { type: 'header', func: (inthis) => (
              <div className='rx-form__header'>
                  <div className='form-header-title'>
                      <div className='text-center title-header'>DANH SÁCH HỒ SƠ CẤP THẺ NHÀ BÁO 2021-2025</div>
                      <div className=''>
                          <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tên cơ quan báo chí: </div><b>{global.rxu.get(inthis.state.editingData, ['TenCoQuanBaoChi', 0], '')}</b></div>
                          <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tên cơ quan chủ quản: </div><b>{rxu.get(inthis.state, 'editingData.CoQuanBaoChiObj.0.CoQuanChuQuan')}</b></div>
                          <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Địa chỉ: </div>{rxu.get(inthis.state, 'editingData.CoQuanBaoChiObj.0.DiaChi')}</div>
                          <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp cơ quan báo chí đề nghị cấp: </div>{rxu.get(inthis.state, ['numCheck','check'], 0)}</div>
                          <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp đề nghị duyệt cấp: </div>{rxu.get(inthis.state,['dataCheck','check'], []).length}</div>
                          <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp chưa đủ điều kiện cấp: </div>{rxu.get(inthis.state,['dataNonCheck','check'], []).length}</div>
                          {/*<div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp đã được duyệt: </div>{rxu.get(inthis.state,['dataNonCheck','check'], []).length}</div>*/}

                      </div>
                        <div className='rx-list__card'>
                            <div className='rxlist__card-table return'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td className='rx-table__form-header' rowSpan='3'></td>
                                            <td className='rx-table__form-header' rowSpan='3'>Số TT</td>
                                            <th className={inthis.helpSortClass('HoTenKhaiSinh', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'HoTenKhaiSinh')} >Họ và tên</th>
                                            <th className={inthis.helpSortClass('code', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'code')} >Mã số hồ sơ</th>
                                            <th className={inthis.helpSortClass('TenCoQuanBaoChi', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')} >Thuộc cơ quan</th>
                                            {/*}<th className={inthis.helpSortClass('LoaiCoquan', ['rxwidth--100 table--name_small'])} onClick={(e) => inthis.onClickSort(e, 'LoaiCoquan')} >Trực thuộc</th>*/}
                                            <th className={inthis.helpSortClass('MaSoThe', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'MaSoThe')} >Mã số thẻ nhà báo</th>
                                            <th className={inthis.helpSortClass('ButDanh', ['rxwidth--100 table--name_small'])} onClick={(e) => inthis.onClickSort(e, 'ButDanh')} >Bút danh</th>
                                            <th className='rxwidth--100 table--name_small' onClick={(e) => inthis.onClickSort(e, 'TenChucVu')}>Chức vụ</th>
                                            <th className='rxwidth--100 table--name_small'>Số thẻ cũ</th>
                                            {/*}<th className='rxwidth--100 table--name_small'>Mã thẻ mới</th>*/}
                                            <th className={inthis.helpSortClass('IsStatus', ['rxwidth--100 table--name_small'])} onClick={(e) => inthis.onClickSort(e, 'IsStatus')}>Tình trạng</th>
                                            <th className='rxwidth--100'>Thẻ trích xuất</th>
                                            <th className={inthis.helpSortClass('Type', ['rxwidth--100 table--name_small'])} onClick={(e) => inthis.onClickSort(e, 'Type')}>Phân loại</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inthis.state.editingData['dataCard'] && inthis.state.editingData['dataCard'].map((obj, index) => {
                                            obj.class = obj.IsCheck4 === 1 ? 'active' : obj.IsCheck4 === -1 ? 'unactive' : ''
                                                return <tr key={ index }>
                                                <td>
                                                    <span className={'rs-checkbox-payment-small ' + obj.class} style={{display: 'inline-block'}}>
                                                        <span className={[-1, 1].indexOf(obj.is_return) !== -1 ? 'iconcustom-active' : ''} ></span>
                                                    </span>
                                                    {/*<span className="news_inputcheck_radio" style={{marginLeft: '15px'}}>
                                                        <input type="checkbox" className="css-checkboxShow" dataid={obj._id} id="IDHoSo-233" value={obj._id} onClick={(e) => inthis.checkObject(e)} />
                                                    </span>*/}
                                                </td>
                                                <td className='text-center'>{index + 1}</td>
                                                <td onClick={(e) =>inthis.onClickViewData(e, obj, 'return')} className='rx-cursor'>
                                                    <span><img className='betable__img card__image' alt={obj.name} src={global.rxu.config.base_api + '/upload/image/' + (obj.img_landscape || 'ico_app_default.jpg')} /></span>
                                                    <span className="card__name textuppercase">{obj.HoTenKhaiSinh}</span>
                                                </td>
                                                <td onClick={(e) =>inthis.onClickViewData(e, obj, 'return')}>{obj.code}</td>
                                                <td onClick={(e) =>inthis.onClickViewData(e, obj, 'return')}>{obj.TenCoQuanBaoChi}</td>
                                                {/*}<td onClick={(e) =>inthis.onClickViewData(e, obj, 'return')}>{LCQ[obj.LoaiCoquan]}</td>*/}
                                                <td onClick={(e) =>inthis.onClickViewData(e, obj, 'return')}>{obj.MaSoThe}</td>
                                                <td>{obj.ButDanh}</td>
                                                <td>{obj.TenChucVu}</td>
                                                <td>{obj.MaTheCu}</td>
                                                {/*}<td>{obj.codeGen}</td>*/}
                                                <td><span className={'betable__status betable__status_' +obj.IsStatus}>{statusTinhtrang[obj.IsStatus]}</span></td>
                                                <td className='text-center img_card'><RxUploadCMND images={obj.img_card} title='' single={1} disabled={1} type='img_card'/></td>
                                                <td>{statusPhanloai[obj.StatusPhanLoai]}</td>
                                                </tr>

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )},
        ]}
      />
    </div>
  </AdminBlock>
