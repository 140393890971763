/* global t, rxu */
import React, { Component } from 'react'
import xlsx from 'xlsx'
global.isclient && require('./rxSelectbox.css')
const { apiget, apipost } = global.rootRequire('classes/request')

class RxCheckbox extends Component {
  constructor (props, context) {
    super(props, context)
    this._mounted = false
    this.state = {
      toggle: false,
      options: this.props.options,
      results: this.props.results ? this.props.results : [],
      keyword: '',
      paging: this.props.paging || { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 5000 },
      item: {},
      multi: this.props.multi || false,
      id: this.props.id || [],
      listItem: [],
      hasMore: true,
      flags: {}
     }
     this.handleScroll = this.handleScroll.bind(this);
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this._mounted = true
    // this.setStateRx({ options: newProps.options })
    // if(this.props.Type) {
    //   let paging = this.state.paging
    //   paging['search_Type'] = this.props.Type
    //   this.setState({paging: paging, options: []}, () => this.fetchData())
    // }
  }

  componentDidMount () {
    if(this.props.Type) {
      let paging = this.state.paging
      paging['search_Type'] = this.props.Type
      this.setState({paging: paging, options: []}, () => this.fetchData())
    }
  }
  fetchData (runCallback = false) {
  	let options = this.state.options || []
    let paging = this.state.paging
    paging['search_Type'] = this.props.Type
    apiget(rxu.get(rxu.config, this.props.api), paging, {
      '1': (json) => {
      	let newOptions = []
      	if(Number(this.state.paging['pg_page']) >= 2) {
      		newOptions = options.concat(json.data)
      	} else {
      		newOptions = json.data
      	}

        newOptions = newOptions.map(x => {x.Cat_isHidden = global.rxu.get(this.state, 'id',[]).indexOf(x.Cat_ID) !== -1 ? 1 : 0; return x})
        newOptions.sort((a,b) => (a.Cat_isHidden > b.Cat_isHidden) ? -1 : ((b.Cat_isHidden > a.Cat_isHidden) ? 1 : 0))

    		if (json.data.length === 0 || json.data.length < 50) {
    			this.setState({ hasMore: false, options: newOptions });
    		} else {
    			this.setState({ options: newOptions, hasMore: true })
    		}
      }
    })
  }
  handleScroll(e) {
    if(this.state.hasMore) {
    	let element = e.target
	    let paging = this.state.paging
	    let scrollTop = Math.floor(element.scrollTop)
		if ((element.scrollHeight - scrollTop === element.clientHeight) || ((element.scrollHeight - scrollTop <= element.clientHeight+4) && (element.scrollHeight - scrollTop >= element.clientHeight-4))) {
			paging['pg_page'] += 1
			this.setState({paging: paging}, () => {
			  this.fetchData()
			})
		}
    }
  }
  toggle () {
    this.setState({toggle: !this.state.toggle}, () => {
      let input = document.getElementById('input-search')
      if(input) {
        input.focus()
      }
    })
  }
  onClickItem (e, item, name) {
    e.stopPropagation()

  }
  onClickMutiItem (e, item, name) {
    e.stopPropagation()
    if (this.state.results.indexOf(item._id) === -1) {
      let tempResults = this.state.results, id = this.state.id
      tempResults.push(item.Cat_Name)
      id.push(item.Cat_ID)
      this.setState({results: tempResults, id: id}, () => {
        let results = {Cat_Name: this.state.results, Cat_ID: this.state.id} //this.state.results ? (this.state.results.constructor === Array? this.state.results.join(',') : this.state.results): ''
        this.props.onChange(results)
      })
    }
  }
  onClickResult (e, item) {
    e.stopPropagation()
    let tempIndex = this.state.results.indexOf(item)
    if (tempIndex !== -1) {
      let tempResults = this.state.results, id = this.state.id
      tempResults.splice(tempIndex, 1)
      id.splice(tempIndex, 1)

      this.setState({ results: tempResults, id: id }, () => {
        let results = {Cat_Name: this.state.results, Cat_ID: this.state.id} //this.state.results ? (this.state.results.constructor === Array? this.state.results.join(',') : this.state.results): ''
        this.props.onChange(results)
      })
    }
  }
  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    let value = e.target.value
	if(value && value === '') { delete paging['search_' + name]}
	else {
		paging['search_' + name] = value
	}
	paging['pg_page'] = 1
    this.setState({paging: paging})
    this.timerDatafilter = setTimeout((e, name) => {
	    this.fetchData()
	}, 300)
  }

  async checkObject(e, obj) {
    // let value = typeof e.target.value === 'string' ? JSON.parse(e.target.value) : e.target.value
    let id = this.state.id
    if(id.constructor === Array) {
      if(id.findIndex(o => o === obj.Cat_ID) === -1) {
        id.push(obj.Cat_ID)
      } else {
        id = id.filter(e => e !== obj.Cat_ID)
      }
    } else { id = obj.Cat_ID }
    this.setState({ item: obj, id: id }, () => this.props.onChange(this.state.item))
  }

  onClickExcelUpload(flagname) {
    let flags = this.state.flags
    if(!flags[flagname]) { flags[flagname] = false }
    flags[flagname] = true
    this.setState({flags: flags})
  }

  onClickDataImport(e) {
    let selectedFile = e.target.files[0]
    let reader = new FileReader()
    let scope = this
    reader.onload = (event) => {
      let data = event.target.result
      let workbook = xlsx.read(data, { type: 'binary' })

      workbook.SheetNames.forEach((sheetName) => {
          if(sheetName === this.props.fullname) {
            let CateArr = []
            let xlRowObjectTemp = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
            if(xlRowObjectTemp.length > 0) {
              for(let i = 2; i < xlRowObjectTemp.length; i++) {
                if(xlRowObjectTemp[i]['__EMPTY']) {
                  let cate = xlRowObjectTemp[i]['__EMPTY']
                  let parent = xlRowObjectTemp[i]['__EMPTY_1'] || ''

                  if(CateArr.findIndex(o => o.cate === cate) === -1 || (parent && parent.length > 0 && CateArr.findIndex(o => o.cate === cate) !== -1 && CateArr.findIndex(o => o.parent === parent) === -1)) {
                    CateArr.push({cate: cate, parent: parent})
                  } else {
                    console.log(i, 'du lieu file bi trung',cate, 'vitritrongcate', CateArr.findIndex(o => o.cate === cate))
                  }
                }
              }
            }
            // console.log(CateArr, 'CateArr')
            scope.setState({CateArr: CateArr})
            // let CateArr = []
            // let xlRowObjectTemp = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
            // if(xlRowObjectTemp.length > 0) {
            //   for(let i = 2; i < xlRowObjectTemp.length; i++) {
            //     if(xlRowObjectTemp[i]['__EMPTY'] && CateArr.indexOf(xlRowObjectTemp[i]['__EMPTY']) === -1) {
            //       CateArr.push(xlRowObjectTemp[i]['__EMPTY'])
            //     } else if(xlRowObjectTemp[i]['__EMPTY'] && CateArr.indexOf(xlRowObjectTemp[i]['__EMPTY']) !== -1) {
            //       console.log(i, 'du lieu file bi trung',xlRowObjectTemp[i]['__EMPTY'], 'eee', CateArr.indexOf(xlRowObjectTemp[i]['__EMPTY']))
            //     }
            //   }
            // }
            // scope.setState({CateArr: CateArr})
          }
      })
    }
    reader.onerror = function (event) { console.error('File could not be read! Code ' + event.target.error.code) }
    reader.readAsBinaryString(selectedFile)
  }

  onClickSubmitExcelUpload () {
    // let ar = []
    // let options = this.state.CateArr//this.state.results
    // for(let i in options) {
    //   ar.push(options[i])
    //   let index = ar.findIndex(o => o === options[i])
    //   console.log(index, 'indexOf')
    // }
    let CateArr = this.state.CateArr
    let arr = []
    let parent = []
    CateArr && CateArr.forEach(obj => {
      arr.push(obj.cate)
      if(obj.parent && obj.parent.length > 0) {
        parent.push(obj.parent)
      }
    })
    // console.log(arr, 'arr')
    // console.log(parent, 'parent')
    if(arr && arr.length > 0) {
      let params = JSON.stringify(arr)
      let params1 = JSON.stringify(parent)

      apipost(rxu.get(rxu.config, 'api_newspaper_office_list'), {searchin__Cat_Name: params, searchin__CoQuanChuQuan: params1, pg_size: 1000, search_Type: this.props.Type}, {
        '1': (json) => {
          let temp = []
          // for(let i in arr) {
          //   let index = json.data.findIndex(o => o.Cat_Name === arr[i])
          //   if(index === -1) {
          //     temp.push(arr[i])
          //   }
          // }
          let result = []
          let duplicate = []
          // for(let j in json.data) {
          //   let tmp1 = this.state.CateArr.findIndex(o => o.cate === json.data[j].Cat_Name)
          //   let tmp2 = this.state.CateArr.findIndex(o => o.parent === json.data[j].CoQuanChuQuan)
          //   if(tmp1 !== -1 && tmp2 !== -1) {
          //     let index = result.findIndex(o => o.Cat_Name === json.data[j].Cat_Name)
          //     let index1 = result.findIndex(o => o.CoQuanChuQuan === json.data[j].CoQuanChuQuan)
          //     if(index === -1 || (index !== -1 && index1 === -1)) {
          //       result.push(json.data[j])
          //     } else {
          //       console.log(index, 'trung Cate', json.data[j].Cat_Name, '---', json.data[j].CoQuanChuQuan)
          //     }
          //   }
          // }
          for(let i in CateArr) {
            let obj = json.data.filter(o => o.Cat_Name === CateArr[i]['cate'] && o.CoQuanChuQuan === CateArr[i]['parent'])
            if(obj && obj[0]) {
              result.push(obj[0])
            } else {
              temp.push(CateArr[i])
            }
            if(obj && obj.length > 1) {
              duplicate.push(CateArr[i]['cate'])
            }
          }
          // console.log(result, 'result')
          // console.log(temp, 'temp')
          // console.log(duplicate, 'duplicate')
          this.props.onChange(result, temp)
        }
      })
    } else {
      alert('Khong co du lieu')
    }
  }

  onClickReset() {
    this.setState({id: []})
    this.props.resetData()
  }

  render () {
    return (<div style={{width: '100%'}}>
    	<div className='rx--box__header checkbox__filter'>
        <div className="rx--box__search">
          <div className="rx-flex">
            <div className="rx--box__title">TÌM KIẾM</div>
            <input placeholder='Tìm kiếm nhanh' onChange={(e) => this.onBlurDatafilter(e, 'Cat_Name')}/>
            <span className='icon-magnifier searchbox__toggle-icon'/>
          </div>
        </div>
        <div className='rx--box__import'>
          <span className='betable__btnimport btn' onClick={(e) => this.onClickExcelUpload('importExcel')}><i className='iconcustom-import' />{t('Import dữ liệu')}</span>
          { this.flag('importExcel') &&
            <span className='adtable__exportcsv tooltip'>
              <input onChange={(e) => this.onClickDataImport(e)} className='' id='import-excel' type='file' accept='.xlsx, .xls' />
            </span>
          }
          { this.flag('importExcel') &&<span id='buttonSubmitCsv' className='betable__btnadd btn' onClick={(e) => this.onClickSubmitExcelUpload(e)} >Nhập</span>}
          {/*<span className='betable__btnadd btn' onClick={(e) => this.onClickReset(e)} >Clear</span>*/}
        </div>
      </div>
  		<div className='rxcheckbox-wrap' onScroll={this.handleScroll}>
  			<div className='checkbox__toggle'>
  				{this.state.options && this.state.options.map((obj, index) => {
            obj.class = global.rxu.get(this.state, 'id',[]).indexOf(obj.Cat_ID) !== -1 ? 'active' : ''
            return <div className='checkbox__item' key={index}>
  						{/*<input type="checkbox" dataid={obj._id} value={JSON.stringify(obj)} onClick={(e) => this.checkObject(e)} />*/}
  						<span className={'rs-checkbox-payment-small ' + obj.class} onClick={(e) => this.checkObject(e, obj)}>
	              <span className={global.rxu.get(this.state, 'id',[]).indexOf(obj.Cat_ID) !== -1 ? 'iconcustom-active' : ''} ></span>
	            </span>
  						<span>{obj.Cat_Name}</span>
  					</div>
  				})}
  			</div>
  			{this.state.hasMore && <div className="loaders">
  			    <div className="lds-ripple">
  			      <div></div>
  			      <div></div>
  			    </div>
  			</div>}
  		</div>
    </div>)
  }
}
RxCheckbox.defaultProps = { onChange: () => {}, options: [], results: '' }
// Options : {key: , text: }
export default RxCheckbox
