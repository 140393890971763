/* global rxu */
import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
global.isclient && require('./home.css')

const { apiget, rxgetLocal, rxJson } = global.rootRequire('classes/request')
// const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
// const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
// const QRCode = require('qrcode.react')
// const LCQ = ['','Trung Ương', 'Địa Phương']
// const statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']
// const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Hồ sơ cấp theo kỳ hạn mới']
// const bankhai = ['', 'cấp mới','cấp lại', 'cấp đổi','cấp theo kỳ hạn mới']

const WAIT_INTERVAL = 500

class Card extends Component {
  constructor (props) {
    super(props)
    this.state = {
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      pagingCat: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      pagingPahse: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      pagingPos: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      editingData: {},
      cate: [],
      showCard: false,
      phase: [],
      position: []
    }
  }

  componentDidMount () {
    // this.fetchCate(); 
    this.fetchPosition(); 
    // this.fetchPhase()
  }

  fetchCate (runCallback = false) {
    let strper = rxgetLocal('rxuser')
    let user = rxJson(strper)
    let pagingCat = this.state.pagingCat
    pagingCat['search_Type'] = global.rxu.get(user, 'user.user.Type', 1)
    apiget(rxu.get(rxu.config, 'api_newspaper_office'), pagingCat, {
      '1': (json) => {
        this.setState({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  fetchPhase (runCallback = false) {
    let pagingPahse = this.state.pagingPahse
    pagingPahse['search_submit'] = 0
    apiget(rxu.get(rxu.config, 'api_cate'), pagingPahse, {
      '1': (json) => {
        this.setState({ phase: json.data })
      }
    })
  }

  fetchPosition (runCallback = false) {
    apiget(rxu.get(rxu.config, 'api_position'), this.state.pagingPos, {
      '1': (json) => {
        this.setState({ position: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  onBlurDataValue (value, name, type) {
    let editingData = (type && type === 'phase') ? this.state.editingDataPhase : this.state.editingData
    if(name === 'TenChucVu' && value) {
      editingData['TenChucVu'] = value.Ten
      editingData['ChucVuID'] = value.ChucVuID
    } else if (name === 'TenCoQuanBaoChi') {
      editingData['TenCoQuanBaoChi'] = value.Cat_Name
      editingData['CoQuanBaoChiID'] = value.Cat_ID
      editingData['Code'] = value.Code
      editingData['LoaiCoquan']      = value.LoaiCoquan
    } else if(name === 'CoQuanChuQuan' || name === 'CoQuanBaoChiCu') {
      editingData['CoQuanChuQuan'] = value.Cat_Name
    } else {
      editingData[name] = value
    }
    // console.log(editingData, 'delete')
    this.setState({ editingData: editingData })
  }
  onBlurDatafilter (e, name, type) {
    clearTimeout(this.timerDatafilter)
    if(type && type === 1) {
      let paging = this.state.paging
      if(e === -1) {
        switch (name) {
          case 'TenCoQuanBaoChi':
             delete paging['searche_' + name]
            break;
          case 'TenChucVu':
             delete paging['searche_' + name]
            break;
          default:
             delete paging['search_' + name]
        }
      } else {
        switch (name) {
          case 'TenCoQuanBaoChi':
            paging['searche_' + name] = e.Cat_Name
            break;
          case 'TenChucVu':
            paging['searche_' + name] = e.Ten
            break;
          default:
            paging['search_' + name] = e
        }
      }

      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    } else {
      let paging = this.state.paging
      paging['search_' + name] = e.target.value
      if(e.target.value === '') { delete paging['search_' + name] }
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, WAIT_INTERVAL)
    }
  } 

  render () {
    return (<div>
      <div className='adtopbar' style={{backgroundPositionX: '0'}}>
        <div className='adtopbar__title' style={{paddingLeft: '30px'}}>Hệ thống quản lý Hồ sơ thẻ nhà báo</div>
        <div className='adtopbar__right'>
          <div className='adtopbar__addcard'>Hướng dẫn sử dụng</div>
        </div>
      </div>

      <div className='adbody__card'>
        {!this.state.showCard ? <div className='adcard__filter'>
          <div className='rx-form__item'>
            <div>
              <span>Chọn loại Cục</span>
              <RxSelectbox
                name='TypeCQ'
                options={global.TypeCQ}
                results={this.state.pagingCat['search_Type'] || ''}
                onChange={(result) => { this.onBlurDatafilter(result, 'Type') }}
                optionDefault='--Chọn loại Cục--'
              />
            </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Chọn cơ quan đề nghị</span>
              <RxSelectbox
                name='cate'
                options={this.state.cate}
                results={this.state.editingData.TenCoQuanBaoChi || ''}
                onChange={(result) => { this.onBlurDataValue(result, 'TenCoQuanBaoChi') }}
                api='api_newspaper_office'
                optionDefault='--Chọn cơ quan đề nghị--'
              />
            </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Chọn loại hồ sơ</span>
              <RxSelectbox
                name='typeHS'
                options={global.typeHS}
                results={this.state.editingData.StatusPhanLoai || ''}
                onChange={(result) => { this.onBlurDataValue(result, 'StatusPhanLoai') }}
              />
            </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Chọn đợt thẩm định</span>
              <RxSelectbox
                name='phase'
                options={this.state.phase}
                results={this.state.editingData.phase || ''}
                onChange={(result) => { this.onBlurDataValue(result, 'phase') }}
                api='api_cate'
                optionDefault='--Chọn đợt thẩm định--'
              />
            </div>
          </div>
        </div> : <div className='adcard__form'>
          
        </div>}
      </div>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  
})

const mapDispatchToProps = {
  
}

const Card_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(Card)

export default Card_
