/* global localStorage */
import fetch from 'isomorphic-fetch'

export function apiget (url, params, callbacks) {
  if (typeof window === 'undefined') return {}
  if (window.isServer) return {}
  if (!params.hideProgress) { global.showProgress() }

  url = url || ''
  params = (typeof (params) === 'object') ? params : {}

  // Make params
  let tempBody = ''
  for (let property in params) {
    if (params.hasOwnProperty(property)) {
      tempBody += `${property}=${params[property]}&`
    }
  }

  // Add authorization
  let match = document.cookie.match(new RegExp('authorize=([^;]+)'))
  if (match) {
    tempBody += `authorization=${match[1]}&`
  }

  tempBody += `timeseed=${Date.now()}`
  fetch(url + '?' + tempBody, { method: 'GET', headers: { 'Accept': 'application/json' }, body: null })
    .then(res => res.json())
    .then((json) => {
      if (typeof (callbacks) !== 'undefined') {
        let callbackIndex = json.success.toString()
        if (typeof (callbacks[callbackIndex]) !== 'undefined') {
          callbacks[callbackIndex](json)
        } else if (typeof (callbacks['default']) !== 'undefined') {
          callbacks['default'](json)
        }
      }
      if (json.success === -111) {
        window.location.href = '/login'
      }
    })
    .finally(() => { global.hideProgress() })
    .catch(error => console.log(error))
}

export function apipost (url, params, callbacks) {
  if (typeof window === 'undefined') return {}
  if (window.isServer) return {}
  global.showProgress()

  url = url || ''
  params = (typeof (params) === 'object') ? params : {}

  // Add authorization
  let match = document.cookie.match(new RegExp('authorize=([^;]+)'))
  if (match) {
    params['authorization'] = match[1]
  }

  params['timeseed'] = Date.now()
  fetch(url, { method: 'POST', body: JSON.stringify(params), headers: { 'Accept': 'application/json', 'Content-Type': 'application/json; charset=utf-8' } })
    .then((res) => { return res.json() })
    .then((json) => {
      if (typeof (callbacks) !== 'undefined') {
        let callbackIndex = json.success.toString()
        if (typeof (callbacks[callbackIndex]) !== 'undefined') {
          callbacks[callbackIndex](json)
        } else if (typeof (callbacks['default']) !== 'undefined') {
          callbacks['default'](json)
        }
      }
      console.log(json);
      // if (json.success === -111) {
      //   window.location.href = '/login'
      // }
    })
    .finally(() => { global.hideProgress() })
    .catch(error => console.log(error))
}

export function rxput (url, params, callback) {

}

export function rxdelete (url, params, callback) {
}

export function rxoption (url, params, callback) {
}

export function rxsetCookie (cname, cvalue, minutes) {
  if (typeof (document) !== 'undefined') {
    let d = new Date()
    d.setTime(d.getTime() + (minutes * 24 * 60 * 60 * 1000))
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + '; ' + expires
  }
}

export function rxgetCookie (cname) {
  if (typeof (document) !== 'undefined') {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + cname + '=')
    if (parts.length === 2) return parts.pop().split(';').shift()
  }
}

export function rxsetLocal (cname, cvalue) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(cname, cvalue)
  }
}

export function rxgetLocal (cname, cdefault) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(cname) || cdefault
  } else {
    return cdefault
  }
}

export function rxJson (json, cdefault) {
  let tempReturn = {}
  try {
    tempReturn = JSON.parse(json)
  } catch (e) {
    tempReturn = cdefault
  }

  return tempReturn
}
