/* global rxu */
import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudSta').default
const QRCode = require('qrcode.react')
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default

export const renderTableHead = (inthis, vm) => {
  return <tr className="rx-title__table">
        <th>
            <span id="HoSoChoIn" style={{marginLeft: '15px', width: '40px'}}>
                <input onClick={(e)=> vm.checkAll(e)}  type='checkbox' className='css-checkbox' id='HoSoID-All' />
                <label className='css-label' htmlFor='HoSoID-All' data-toggle='tooltip' data-placement='auto' title='' data-original-title='Tất cả'></label>
            </span>
        </th>
        {inthis.checkShow('TenCoQuanBaoChi') && <th className='rxwidth--100'>STT2</th> }
        <th className='rxwidth--100 table--name_office' onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')}>Tên CQ báo chí đề nghị cấp thẻ</th>
        <th className='rxwidth--100 table--name_office' onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')}>Tên đơn vị công tác (Áp dụng với trường hợp công tác tại Đài TT - TH cấp huyện)</th>
        <th className='rxwidth--100 table--name_office' onClick={(e) => inthis.onClickSort(e, 'HoTenKhaiSinh')}>Họ và tên</th>
        <th className='rxwidth--100 table--name_office'>Quá trình công tác</th>
        <th className='rxwidth--100'>Số thẻ</th>
        <th className='rxwidth--100'>Ngày cấp</th>
        <th className='rxwidth--100 table--name_small'>Thẻ trích xuất</th>
        <th className='rxwidth--100'>Tình trạng thẻ</th>
        <th className='rxwidth--100'>Số thẻ CCCD/CMND</th>
        <th className='rxwidth--100'>Số thẻ cũ</th>
        <th className='rxwidth--100'>QRcode phục vụ kiểm tra số liệu</th>
    </tr>
}

export const renderTableBody1 = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map((perdata,index) => (<tr className='rx-xursor' key={perdata._id} onClick={(e) => inthis.onClickDataEdit(e, perdata)}>
    <td>
        <span className="news_inputcheck_radio" style={{marginLeft: '15px'}}>
            <input type="checkbox" className="css-checkboxShow" dataid={perdata._id} id="" value={perdata._id} onClick={(e) => vm.checkObject(e)} />
        </span>
    </td>
    <td className='text-center'>{index + 1}</td>
    <td>{perdata.TenCoQuanBaoChi}</td>
    <td className='text-center'>{perdata.TenCoQuanBaoChi}</td>
    <td className='text-center rx-cursor textuppercase'>{perdata.HoTenKhaiSinh}</td>
    <td>
        {
            global.rxu.array(perdata.QuaTrinhHoatDongUpdate).map((hoatDong, index) => (
                hoatDong.time ? <p key={index}>{index+1}) {hoatDong.time} công tác tại {hoatDong.CoQuanBaoChi} </p> : ''
            ))
        }
    </td>
    <td className='text-center'>{perdata.codeGen}</td>
    <td className='text-center'></td>
    <td className='text-center'><img className='betable__img-card' alt={perdata.name} src={global.rxu.config.base_api + '/upload/card/' + (perdata.img_card || 'ico_app_default.jpg')} /></td>
    <td className='text-center'></td>
    <td className='text-center'>{perdata.CMND}</td>
    <td className='text-center'>{perdata.MaTheCu}</td>
    <td className='text-center'><QRCode id={perdata.codeGen || '1'} value={perdata.codeGen || '1'} size={40} level={"H"} includeMargin={true} /></td>
  </tr>))
export const renderComponent = (vm) =>
  <AdminBlock name='BÁO CÁO THỐNG KÊ' desc=''>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
      <RxCrudCard renderTableHead1={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='statistics' api={'api_card'} idEdit={vm.state.idEdit}
        form={[
            { type: 'header', func: (inthis) => (
                <div>
                    <div className='class_avatar'>
                        <RxUpload callback={(e) => inthis.callbackUpload(e, 'img_landscape')} images={inthis.state.editingData.img_landscape} type='avatar' disabled='1' />
                        <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                        {/*<div className='text-center avatar_name'>{rxu.rxChangeAlias(inthis.state.editingData.HoTenKhaiSinh)}</div>*/}
                    </div>
                    <div className='form-header-title'>
                        <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                        <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                        <div className='text-center title-main'>BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO</div>
                        <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1)</div>
                        <div className='form-header-bottom'>
                            <span>Tên cơ quan báo chí</span>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.TenCoQuanBaoChi || ''} className='fullwidth-input' disabled />
                        </div>
                    </div>
                    <div className='form-header-right barcode'>
                        <div>
                            <QRCode id={inthis.state.editingData.codeGen || '1'} value={inthis.state.editingData.codeGen || '1'} size={160} level={"H"} includeMargin={true} />
                            <div className='text-center form-avatar-sub'>{inthis.state.editingData.codeGen || ''}</div>
                            {/* <div className='form-header-code'>Số thẻ nhà báo cũ: {inthis.state.editingData.MaTheCu}</div> */}
                        </div>
                    </div>
                </div>
            ) },
            { type: 'devide' },
            { type: 'form', name: 'Thông tin cá nhân', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Họ và tên khai sinh (2): </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenKhaiSinh')} className='fullwidth-input textuppercase' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Nam/Nữ: </span>
                        <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', inthis.state.editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày sinh: </span>
                        <input tabIndex='1' type='text' value={ rxu.getDate(inthis.state.editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                      <span>Dân tộc: </span>
                      <input tabIndex='1' type='text' value={inthis.state.editingData.DanToc || ''} onChange={(e) => inthis.onBlurData(e, 'DanToc')} className='fullwidth-input' disabled />
                  </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Quê quán: </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.QueQuan || ''} onChange={(e) => inthis.onBlurData(e, 'QueQuan')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Nơi ở hiện nay: </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NoiO || ''} onChange={(e) => inthis.onBlurData(e, 'NoiO')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức vụ (3): </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TenChucVu || ''} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bút danh thường dùng: </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ButDanh || ''} onChange={(e) => inthis.onBlurData(e, 'ButDanh')} className='fullwidth-input' disabled />
                    </div>
                </div>


            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'TRÌNH ĐỘ', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Giáo dục phổ thông (4): <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoVanHoa || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoVanHoa')} className='fullwidth-input' disabled />
                    </div>
                </div>
                {/*}<div className='rx-form__title--sub'>Chuyên môn (5): <span/></div>*/}
                <div className='rx-form__item'>
                    <div>
                        <span>Chuyên môn (5): <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoChuyenMon || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoChuyenMon')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trường học: <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} onChange={(e) => inthis.onBlurData(e, 'TruongHoc')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngành học: <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NganhHoc || ''} onChange={(e) => inthis.onBlurData(e, 'NganhHoc')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Hình thức đào tạo (6): <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HinhThucDaoTao || ''} onChange={(e) => inthis.onBlurData(e, 'HinhThucDaoTao')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Năm tốt nghiệp: <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NamTotNghiep || ''} onChange={(e) => inthis.onBlurData(e, 'NamTotNghiep')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Lý luận chính trị (7): <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ChinhTri || ''} onChange={(e) => inthis.onBlurData(e, 'ChinhTri')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngoại ngữ (8): <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NgoaiNgu || ''} onChange={(e) => inthis.onBlurData(e, 'NgoaiNgu')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chứng chỉ đào tạo, bồi dưỡng nghiệp vụ báo chí (nếu có): <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ChungChi || ''} onChange={(e) => inthis.onBlurData(e, 'ChungChi')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Đảng viên: <i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.DangVien || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức danh báo chí hiện nay (9): </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ChucDanhBaoChi || ''} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Số thẻ nhà báo và kỳ hạn cũ: </span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.MaTheCu || ''} onChange={(e) => inthis.onBlurData(e, 'MaTheCu')} className='fullwidth-input' disabled />
                    </div>
                </div>



            </div>)},
            { type: 'devide' },
            { type: 'table', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: ' },
            { type: 'devide' },
            { type: 'form', name: 'Thông tin khai cho mẫu số 3', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Số CMND/CCCD</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.CMND || ''} onChange={(e) => inthis.onBlurData(e, 'CMND')} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span> Ngày, tháng, năm ký HĐLĐ có đóng BHXH</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ThongTinHDLD || ''} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ảnh CMND/CCCD</span>
                        <RxUploadCMND callback={(e) => inthis.callbackUpload(e, 'cmnd_truoc')} images={inthis.state.editingData.cmnd_truoc} title='Ảnh mặt trước' disabled='1' />
                        <RxUploadCMND callback={(e) => inthis.callbackUpload(e, 'cmnd_sau')} images={inthis.state.editingData.cmnd_sau} title='Ảnh mặt sau' disabled='1' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bản sao bằng Đại học/Cao đẳng</span>
                        <RxUploadCMND callback={(e) => inthis.callbackUpload(e, 'BanSaoBangDHCD')} images={inthis.state.editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bản sao quyết định tuyển dụng, hợp đồng lao động </span>
                        <RxUploadCMND callback={(e) => inthis.callbackUpload(e, 'BanSaoHDLD')} images={inthis.state.editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
                    </div>
                </div>
            </div>)},
            {type: 'devide' },
            {type: 'form', name: 'Lưu ý', func: (inthis) => (
                <div>
                    <p> </p>
                    <p>Ghi chú:</p>
                    <p>(1) Ghi theo kỳ hạn thẻ nhà báo đề nghị cấp (ví dụ: 2016 - 2020)</p>
                    <p>(2) Viết chữ in hoa đủ dấu. </p>
                    <p>(3) Ghi rõ chức vụ bổ nhiệm (ví dụ: Tổng biên tập, Phó Tổng biên tập, Trưởng ban...). </p>
                    <p>(4) Ghi rõ đã tốt nghiệp lớp mấy, thuộc hệ nào.</p>
                    <p>(5) Ghi rõ trình độ chuyên môn cao nhất (ví dụ: Tiến sĩ, thạc sĩ, cử nhân, cao đẳng...).</p>
                    <p>(6) Chính quy, tại chức, đào tạo từ xa.... </p>
                    <p>(7) Cao cấp, trung cấp, sơ cấp và tương đương</p>
                    <p>(8) Tên ngoại ngữ và trình độ (ví dụ: A, B, C, D...)</p>
                    <p>(9) Ghi rõ chức danh nghề nghiệp (ví dụ: Phóng viên, biên tập viên...). </p>
                </div>
            )}
        ]}
      />
    </div>
  </AdminBlock>
