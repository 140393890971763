/*global rxu*/
import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { apipost, apiget } from './../../classes/request'
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import SaveAs from 'file-saver'
const pdfMake = require('pdfmake')
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const saveAs = SaveAs

const WAIT_INTERVAL = 500
class registerComponent extends Component {
  constructor (props) {
    super(props)
    this._ismounted = false
    this.state = {
      editingData: {  },
      msg: '',
      paging: { st_col: 'Cat_Name', st_type: 1, pg_page: 1, pg_size: 1000 },
      cate: [],
      toggle: false,
      keyword: '',
      errEmail: '',
      errPhone: '',
      errMsg: {},
      showExport: false
    }
  }

  componentDidMount () {
    this._ismounted = true
    this.fetchCate()
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  fetchCate (runCallback = false) {
    let paging = this.state.paging
    // paging['search_Type'] = 1
    apiget(rxu.get(rxu.config, 'api_newspaper_office_getlist'), paging, {
      '1': (json) => {
        this.setState({ cate: json.data })
      }
    })
    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  onBlurData (e, name) {
    let editingData = this.state.editingData
    editingData[name] = e.target.value
    let errMsg = this.state.errMsg
    if(name === 'phone') {
      let re = /((09|03|07|08|05)+([0-9]{8})\b)/g;
      if (!re.test(editingData[name])) {
      } else {
        this.setState({errPhone: ''})
      }
    } else if(name === 'email') {
      let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!re.test(editingData[name])) {
      } else {
        this.setState({errEmail: ''})
      }
    }
    if(editingData[name] && editingData[name] !== '') {
      errMsg[name] = ''
    }
    this.setState({ editingData: editingData, errMsg: errMsg })
  }
  onBlurDataValue (e, name) {
    let editingData = this.state.editingData
    let errMsg = this.state.errMsg
    if(name === 'CoQuanBaoChi') {
      editingData['CoQuanBaoChi'] = e.Cat_Name
      editingData['CoQuanBaoChiID'] = e.Cat_ID
    } else {
      editingData[name] = e
    }
    if(editingData[name] && editingData[name] !== '') {
      errMsg[name] = ''
    }
    this.setState({ editingData: editingData, errMsg: errMsg })
  }
  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    let value = e.target.value
    if(value && value === '') { delete paging['search_Cat_Name']}
    else {
      paging['search_Cat_Name'] = value
    }
    this.setState({keyword: e.target.value})
    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchCate()
    }, WAIT_INTERVAL)
  }
  toggle () {
    this.setState({toggle: !this.state.toggle}, () => {
      let input = document.getElementById('input-search')
      if(input) {
        input.focus()
      }
    })
  }
  onClickItem (e, item, name) {
    e.stopPropagation()
    let editingData = this.state.editingData
    let errMsg = this.state.errMsg
    if(item === -1) {
      editingData['CoQuanBaoChi'] = ''
      editingData['CoQuanBaoChiID'] = ''
      this.setState({ toggle: !this.state.toggle, results: '', editingData: editingData })
    } else {
      editingData['CoQuanBaoChi'] = item.Cat_Name
      editingData['CoQuanBaoChiID'] = item.Cat_ID
      editingData['LoaiCoquan'] = item.LoaiCoquan
      editingData['Type'] = item.Type
      errMsg['CoQuanBaoChi'] = ''
      this.setState({ toggle: !this.state.toggle, results: item.Cat_Name, editingData: editingData, errMsg: errMsg })
    }
  }
  onValidateEmail(e) {
    if(!this.state.editingData['email'] || this.state.editingData['email'] === '') {
      this.setState({errEmail: 'Không được để trống email'})
    } else {
      let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!re.test(global.rxu.get(this.state, ['editingData', 'email'], ''))) {
        this.setState({errEmail: 'Định dạng email không hợp lệ'})
      } else {
        this.setState({errEmail: ''})
      }
    }
  }

  onValidatePhone(e) {
    if(!this.state.editingData['phone'] || this.state.editingData['phone'] === '') {
      this.setState({errPhone: 'Không được để trống số điện thoại'})
    } else {
      let re = /((09|03|07|08|05)+([0-9]{8})\b)/g;
      if (!re.test(global.rxu.get(this.state, ['editingData', 'phone'], ''))) {
        this.setState({errPhone: 'Định dạng số điện thoại không hợp lệ'})
      } else {
        this.setState({errPhone: ''})
      }
    }
  }
  onValidateField(e, name) {
    let errMsg = this.state.errMsg
    if(!this.state.editingData[name] || this.state.editingData[name] === '') {
      errMsg[name] = 'Không được để trống'
      this.setState({errMsg: errMsg})
    } else {
      errMsg[name] = ''
      this.setState({errMsg: errMsg})
    }
  }

  onClickRegisterSubmit (e) {
    // let editingData = this.state.editingData
    // this.setState({ editingData: editingData }, () => {
      let errMsg = this.state.errMsg
      if(!this.state.editingData['CoQuanBaoChi'] || this.state.editingData['CoQuanBaoChi'] === '') {
        errMsg['CoQuanBaoChi'] = 'Không được để trống'
        this.setState({errMsg: errMsg})
      } else {
        apipost(global.rxu.config.api_member, this.state.editingData, {
          '1': (json) => {
            errMsg['CoQuanBaoChi'] = ''
            window.alert('Bạn đã đăng ký thành công. Vui lòng in phiếu đăng ký, ký xác nhận của lãnh đạo cơ quan báo chí và đóng dấu gửi về Cục Báo chí!')
            this.setState({ msg: 'Bạn đã đăng ký thành công. Vui lòng in phiếu đăng ký, ký xác nhận của lãnh đạo cơ quan báo chí và đóng dấu gửi về Cục Báo chí!', errMsg: errMsg, showExport: true })
          },
          'default': (json) => {
            var strmsg = ''
            if (json.msg === 'Dupplicate data') {
              strmsg = 'Cơ quan báo chí này đã được đăng ký'
            } else if (json.msg === 'Email invalid format') {
              strmsg = 'Định dạng Email không đúng'
            } else {
              strmsg = 'Các trường * không được để trống'
            }
            this.setState({ msg: strmsg })
          }
        })
      }
    // })
  }

  onClickExportFile() {
    let errMsg = this.state.errMsg
    var dd = {
      content: [
        {
          margin: [0, 20, 0, 10],
          text: [
            'PHIẾU CUNG CẤP THÔNG TIN\n',
            'ĐĂNG KÝ TÀI KHOẢN SỬ DỤNG HỆ THỐNG KÊ KHAI NỘP HỒ SƠ CẤP ĐỔI THẺ NHÀ BÁO'
          ],
          style: 'header'
        },
        {
          style: 'tableQuaTrinhHD',
          table: {
            widths: [ 200, '*' ],
            body: [
              [
                { text: 'Tên cơ quan báo chí', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'CoQuanBaoChi'], ''), style: 'nomal' },
              ],
              [
                { text: 'Số giấy phép hoạt động báo chí', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'SoDKKD'], ''), style: 'nomal' },
              ],
              [
                { text: 'Ngày cấp', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'NgayCap']), style: 'nomal' },
              ],
              [
                { text: 'Địa chỉ cơ quan báo chí', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'address'], ''), style: 'nomal' },
              ],
              [
                { text: 'Số điện thoại cơ quan báo chí', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'telephone'], ''), style: 'nomal' },
              ],
              [
                { text: 'Email cơ quan báo chí', style: 'nomal' },
                { text:  this.state.editingData.email, style: 'nomal' },
              ],
              [
                { text: 'Số điện thoại di động', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'phone'], ''), style: 'nomal' },
              ],
              [
                { text: 'Người sử dụng Hệ thống kê khai', style: 'nomal' },
                { text: rxu.get(this.state, ['editingData', 'fullname'], ''), style: 'nomal' },
              ]
            ]
          }
        }, {
          columnGap: 20,
          margin: [0, 10, 0, 40],
          columns: [
            [],
            [
              { text: rxu.getFullDate(new Date() / 1000) + '\n', alignment: 'center' },
              { text: 'Lãnh đạo cơ quan báo chí\n', style: 'subheader' },
              { text: '(Ký tên, đóng dấu)', style: 'sign' }
            ]
          ],
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        subheader: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        nomal: {
          fontSize: 14,
        },
        sign: {
          fontSize: 14,
          italics: true,
          alignment: 'center'
        },
        tableQuaTrinhHD: {
          margin: [0, 5, 0, 15],
        },
      },
      defaultStyle: {
        fontSize: 14
      }
    }
    if(!this.state.editingData['CoQuanBaoChi'] || this.state.editingData['CoQuanBaoChi'] === '') {
      errMsg['CoQuanBaoChi'] = 'Không được để trống'
      this.setState({errMsg: errMsg})
    } else {
      errMsg['CoQuanBaoChi'] = ''
      this.setState({errMsg: errMsg})
      const pdfDocGenerator = pdfMake.createPdf(dd)
      pdfDocGenerator.getBlob((blob) => {
        saveAs(blob, "BanKhaiDangKy.pdf")
      });
    }
  }

  renderToast()  {
    return <div className='toast-container toast-top-right'>
      <div className='toast'>
        <div className='toast-content'>
          <div className='toast-title'>{this.state.msg}</div>
          <div className='toast-message'>
            <div className=''></div>
          </div>
        </div>
      </div>
    </div>
  }

  render () {
    return (<div>
      {/*<div className='adtopbar' style={{backgroundPositionX: '0'}}>
        <div className='adtopbar__title' style={{paddingLeft: '30px'}}>Hệ thống quản lý Hồ sơ thẻ nhà báo</div>
        <div className='adtopbar__right'>
          <a href='/card' className='adtopbar__addcard' style={{marginRight: '2px'}}><i className='iconcustom-create betable__addbtn' />Thêm mới thẻ</a>
          <Link to='/login' className='adtopbar__addcard'><div>Đăng nhập</div></Link>
          <Link to='/manual' className='adtopbar__addcard'><div>Hướng dẫn sử dụng</div></Link>
        </div>
      </div>*/}
      <div className='rx-authpage'>
        <div className='authform-register'>
          <div className='register-form'>
            <div className='authform__title'><span>Cơ quan báo chí</span></div>
            {this.state.msg && this.renderToast()}
            <p className='authform_msgerror'>{this.state.msg}</p>
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Tên cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                <div style={{width: '100%'}}>
                  <div className='rxselectbox-wrap'>
                    {this.state.toggle && <div className='rx-fixed' onClick={() => this.toggle()}></div>}
                    <div className='rxselectbox-result clearfix' onClick={() => this.toggle()}>

                      {(!this.state.editingData['CoQuanBaoChi'] || (this.state.editingData['CoQuanBaoChi'] && this.state.editingData['CoQuanBaoChi'].toString().length === 0)) && <span className='rxselectbox-resultempty'>{'--Chọn cơ quan báo chí--'}</span>}
                      {(this.state.editingData['CoQuanBaoChi'] && this.state.editingData['CoQuanBaoChi'].toString().length > 0) && <div>{this.state.editingData['CoQuanBaoChi']}</div>}
                      {this.state.toggle ? <span className='rx-dropdown up iconcustom-up'></span> : <span className='rx-dropdown iconcustom-down'></span>}
                    </div>
                    {this.state.toggle && <div className='rxselectbox-toggle'>
                      <div className='rxselectbox-filter'><input type='text' className='' onChange={(e) => this.onBlurDatafilter(e)} id='input-search' value={this.state.keyword}/></div>
                      <div onClick={(e) => this.onClickItem(e, -1)}>--Chọn cơ quan báo chí--</div>
                      {this.state.cate && this.state.cate.length > 0 ?
                      this.state.cate.map((item, index) => (
                        <div key={index} onClick={(e) => this.onClickItem(e, item, 'cate')}><b>{item.Cat_Name}</b> - {item.DiaChi} - {item.Cat_Description}</div>
                      )) : <div className='rx-no-result'>Không tìm thấy kết quả</div>}
                    </div> }
                  </div>
                  {this.state.errMsg && this.state.errMsg['CoQuanBaoChi'] && <div className='authform_msgerror'>{this.state.errMsg['CoQuanBaoChi'] || ''}</div>}
                </div>
              </div>
            </div>
            {/*<div className='rx-form__item'>
              <div>
                <span>Mã số thuế<i className='iconcustom-require icon-require'/></span>
                <input tabIndex='2' type='text' placeholder='Nhập Mã số thuế' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'MaSoThue')} />
              </div>
            </div>*/}
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Số giấy phép hoạt động báo chí<i className='iconcustom-require icon-require'/></span>
                <div style={{width: '100%'}}>
                  <input tabIndex='3' type='text' placeholder='Nhập Số giấy phép hoạt động báo chí' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'SoDKKD')} onBlur={(e) => this.onValidateField(e, 'SoDKKD')} />
                  {this.state.errMsg && this.state.errMsg['SoDKKD'] && <div className='authform_msgerror'>{this.state.errMsg['SoDKKD'] || ''}</div>}
                </div>
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Ngày cấp<i className='iconcustom-require icon-require'/></span>
                <input tabIndex='3' type='text' placeholder='Định dạng dd/MM/YYY' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'NgayCap')} onBlur={(e) => this.onValidateField(e, 'NgayCap')} />
                {/*}
                <DatePicker
                  selected={ new Date(this.state.editingData.NgayCap * 1000) }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/YYY"
                  onChange={date => {
                    let datetmp = date/1000
                    this.onBlurDataValue(datetmp, 'NgayCap')
                  }}
                />*/}
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Địa chỉ cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                <div style={{width: '100%'}}>
                  <input tabIndex='3' type='text' placeholder='Nhập địa chỉ cơ quan báo chí' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'address')} onBlur={(e) => this.onValidateField(e, 'address')} />
                  {this.state.errMsg && this.state.errMsg['address'] && <div className='authform_msgerror'>{this.state.errMsg['address'] || ''}</div>}
                </div>
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Số điện thoại cơ quan báo chí</span>
                <div style={{width:'100%'}}>
                  <input tabIndex='3' type='text' placeholder='Nhập số điện thoại cố định' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'telephone')} onBlur={(e) => this.onValidateField(e, 'telephone')} />
                  {this.state.errMsg && this.state.errMsg['telephone'] && <div className='authform_msgerror'>{this.state.errMsg['telephone'] || ''}</div>}
                </div>
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Email cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                <div style={{width: '100%'}}>
                  <input tabIndex='5' type='text' placeholder='Nhập email cơ quan báo chí' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'email')} onBlur={(e) => this.onValidateEmail(e, 'email')}/>
                  {this.state.errEmail && <div className='authform_msgerror'>{this.state.errEmail || ''}</div>}
                </div>
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
              <span className='custom_width'>Số điện thoại di động<i className='iconcustom-require icon-require'/><br /><p>(để xác thực OTP nếu chọn đăng nhập bằng cổng Micconnect)</p> </span>

                <div style={{width: '100%'}}>
                  <input tabIndex='6' type='text' placeholder='Nhập số điện thoại di động' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'phone')} onBlur={(e) => this.onValidatePhone(e, 'phone')} />
                  {this.state.errPhone && <div className='authform_msgerror'>{this.state.errPhone || ''}</div>}
                </div>
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span className='custom_width'>Họ tên người sử dụng<i className='iconcustom-require icon-require'/></span>
                <div style={{width: '100%'}}>
                  <input tabIndex='6' type='text' placeholder='Nhập họ tên người sử dụng' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'fullname')} onBlur={(e) => this.onValidateField(e, 'fullname')} />
                  {this.state.errMsg && this.state.errMsg['fullname'] && <div className='authform_msgerror'>{this.state.errMsg['fullname'] || ''}</div>}
                </div>
              </div>
            </div>
            <div className='rx-form__item_button'>
              <div>
                <span tabIndex='7' className='authform__btnsubmit register' onClick={(e) => this.onClickRegisterSubmit(e)} onKeyPress={(e) => this.onClickRegisterSubmit(e)} >Đăng ký tài khoản</span>
                {this.state.showExport && <span style={{marginLeft: '20px', background: '#626f7e'}} tabIndex='8' className='authform__btnsubmit print' onClick={(e) => this.onClickExportFile(e)} >In Phiếu cung cấp thông tin</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
}

export default registerComponent
