/*global t*/
import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderTableHead = (context, vm) =>
  <tr className='rx-title__table'>
    <th className={context.helpSortClass('Ten', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Ten')}>Tên chức vụ</th>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && <th className='rxwidth--100'>{t('Action')}</th>}
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td>{perdata.Ten}</td>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='QUẢN LÝ CHỨC VỤ' desc=''>
    <div className='rxcol-100'>
      <RxCrud renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name={'position'} api={'api_position'} form={[
        { type: 'devide' },
        { name: 'STT', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.STT} onChange={(e) => context.onBlurData(e, 'STT')} className='fullwidth-input' />) },
        { name: 'ID chức vụ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.ChucVuID} onChange={(e) => context.onBlurData(e, 'ChucVuID')} className='fullwidth-input' />) },
        { name: 'Tên chức vụ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Ten} onChange={(e) => context.onBlurData(e, 'Ten')} className='fullwidth-input' />) },

        // [[RX_WEB_DATA_CUSTOM]] //
        // [[RX_WEB_DATA_CUSTOM_END]] //
        { type: 'devide' },
        { name: 'Trạng thái', func: (context) => (<RxToggle value={context.state.editingData.is_active} onToggle={(newValue) => context.onBlurDataValue(newValue, 'is_active')} />) }
      ]} />
    </div>
  </AdminBlock>
