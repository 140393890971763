/* global rxu */
import React, { Component } from 'react'
global.isclient && require('./rxSelectbox.css')
const { apiget } = global.rootRequire('classes/request')

class RxSelectbox extends Component {
  constructor (props, context) {
    super(props, context)
    this._mounted = false
    this.state = {
      toggle: false,
      options: this.props.options,
      results: this.props.results ? (this.props.results.constructor === Array ? this.props.results : this.props.results) : [],
      keyword: '',
      paging: this.props.paging || { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50, search_is_active: 1 },
      item: {},
      multi: this.props.multi || false,
      id: this.props.id || [],
      name: this.props.name,
      showPhase: false,
      createPosition: false,
     }
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this._mounted = true
    // this.setStateRx({ options: newProps.options })
    if(this.props.Type) {
      let paging = this.state.paging
      paging['search_Type'] = this.props.Type
      this.setState({paging: paging, options: []}, () => this.fetchData())
    }
    if((this.props.name === 'phase' && this.state.showPhase)) {
      this.setState({options: this.props.options})
    }
    if((this.props.name === 'cate' && this.state.createPosition)) {
      this.setState({options: newProps.options, results: newProps.results, toggle: false, id: newProps.id})
    }
    // if(this.props.name === this.state.name) {
    //   this.setState({options: this.props.options})
    // }
  }

  componentDidMount () {
    if(this.props.name && this.props.name === 'user') {
      let results = this.props.results ? this.props.results.split(',').filter(v => v !== '') : []
      let id = this.props.id ? this.props.id.split(',').filter(v => v !== '') : []
      this.setState({ results: results, id: id })
    }
    if(this.props.loader && this.props.loader === 'load') {
      this.fetchData()
    }
    if(this.props.Type) {
      let paging = this.state.paging
      paging['search_Type'] = this.props.Type
      this.setState({paging: paging, options: []}, () => this.fetchData())
    }
  }
  fetchData (runCallback = false) {
    let paging = this.state.paging
    if(this.props.api === 'api_config' && this.props.type) {
      paging['search_type'] = this.props.type
    }
    apiget(rxu.get(rxu.config, this.props.api), paging, {
      '1': (json) => {
        this.setState({ options: json.data })
      }
    })
  }
  toggle () {
    this.setState({toggle: !this.state.toggle}, () => {
      let input = document.getElementById('input-search')
      if(input) {
        input.focus()
      }
    })
  }
  onClickItem (e, item, name) {
    // console.log(name, item)
    e.stopPropagation()
    if(item === -1) {
      let results = this.state.results.constructor === Array ? [] : ''
      let id = this.state.id.constructor === Array ? [] : ''
      this.setState({ toggle: !this.state.toggle, results: results, id: id }, () => {
        this.props.onChange(-1)
      })
    } else {
      if (name && name === 'cate' && this.props.api ==='api_newspaper_office') {
        let tempResults = this.state.results
        tempResults = item
        this.setState({ toggle: !this.state.toggle, results: tempResults.Cat_Name }, () => {
          this.props.onChange(tempResults)
        })
      }
      if (name && name === 'cate' && (['api_position', 'api_title', 'api_config'].indexOf(this.props.api) !== -1)) {
        if(this.props.api === 'api_config') { // select multi config
          let tempResults = this.state.results || [], id = this.state.id || []
          tempResults.push(item.Ten)
          id.push(item.id)
          this.setState({results: tempResults, id: id}, () => {
            let temp = {Ten: this.state.results, id: this.state.id}
            this.props.onChange(temp)
          })
        } else {
          let tempResults = this.state.results
          tempResults = item
          this.setState({ toggle: !this.state.toggle, results: tempResults.Ten, item: item }, () => {
            this.props.onChange(tempResults)
          })
        }
      }
      if(name && name === 'phase') {
        this.setState({ toggle: !this.state.toggle, results: item.name, item: item }, () => {
          this.props.onChange(item)
        })
      }
      if(this.props.name === 'province') {
        this.setState({ toggle: !this.state.toggle, results: item.Ten, item: item }, () => {
          this.props.onChange(item)
        })
      }
      if(name && ['cate', 'phase'].indexOf(name) === -1 && this.props.name !== 'province') {
        let tempResults = this.state.results
        tempResults = this.props.ten ? item.Ten : item.id
        this.setState({ toggle: !this.state.toggle, results: tempResults, item: item }, () => {
          let tempResults = this.state.results ? (this.state.results.constructor === Array ? this.state.results.join(',') : this.state.results) : ''
          this.props.onChange(tempResults)
        })
      }
    }
  }
  onClickMutiItem (e, item, name) {
    e.stopPropagation()
    if (!this.state.results || this.state.results.indexOf(item._id) === -1) {
      if(this.props.name === 'user') {
        let tempResults = this.state.results, id = this.state.id
        tempResults = []; id = []

        tempResults.push(item.fullname)
        id.push(item.id)
        this.setState({results: tempResults, id: id}, () => { //this.state.results ? (this.state.results.constructor === Array? this.state.results.join(',') : this.state.results): ''
          this.props.onChange(item)
        })
      } else {
        let tempResults = this.state.results, id = this.state.id
        tempResults.push(item.Cat_Name)
        id.push(item.Cat_ID)
        this.setState({results: tempResults, id: id}, () => {
          let results = {Cat_Name: this.state.results, Cat_ID: this.state.id} //this.state.results ? (this.state.results.constructor === Array? this.state.results.join(',') : this.state.results): ''
          this.props.onChange(results)
        })
      }
    }
  }
  onClickResult (e, item) {
    e.stopPropagation()
    if(this.props.api === 'api_config' && this.state.results.length > 0) {
      let tempIndex = this.state.results.findIndex(o => o === item)
      if (tempIndex !== -1) {
        let tempResults = this.state.results, id = this.state.id
        tempResults.splice(tempIndex, 1)
        id.splice(tempIndex, 1)

        this.setState({ results: tempResults, id: id }, () => {
          let results = {Ten: this.state.results, id: this.state.id}
          this.props.onChange(results)
        })
      }
    } else {
      let tempIndex = this.state.results.indexOf(item)
      if (tempIndex !== -1) {
        let tempResults = this.state.results, id = this.state.id
        tempResults.splice(tempIndex, 1)
        id.splice(tempIndex, 1)

        this.setState({ results: tempResults, id: id }, () => {
          let results = {Cat_Name: this.state.results, Cat_ID: this.state.id} //this.state.results ? (this.state.results.constructor === Array? this.state.results.join(',') : this.state.results): ''
          this.props.onChange(results)
        })
      }
    }
  }

  onChangeFilter (e) {
    // let tempValue = e.target.value
    this.setState({keyword: e.target.value}, () => {
      this.onBlurDatafilter(this.state.keyword, 'Cat_Name')
    })
  }
  onClickShowCreatePhase() {
    this.setState({ showPhase: true }, () => this.props.showPhase())
  }

  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging

    if(this.props.name === 'phase') {
      let value = e.target.value
      paging['search_name'] = value
      this.setState({ paging: paging })

      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, 500)
    }

    if(this.props.name === 'cate' && this.props.api === 'api_newspaper_office') {
      let value = e.target.value
      if(value && value === '') { delete paging['search_Cat_Name']}
      else {
        paging['search_Cat_Name'] = value
      }
    }
    if(this.props.name === 'cate' && ['api_position', 'api_title', 'api_config'].indexOf(this.props.api) !== -1) {
      let value = e.target.value
      if(value && value === '') { delete paging['search_Ten']}
      else {
        // paging['searchabs_Ten'] = value
        paging['search_Ten'] = value
      }
    }
    if(!this.props.name && this.props.Type) {
      let value = e.target.value
      if(value && value === '') { delete paging['search_Cat_Name']; delete paging['search_Type']}
      else { paging['search_Cat_Name'] = value; paging['search_Type'] = Number(this.props.Type) }
      this.setState({ paging: paging })
      this.timerDatafilter = setTimeout((e, name) => {
        this.fetchData()
      }, 500)
    } else if (!this.props.name && !this.props.Type) {
      alert('Vui lòng chọn Cục quản lý')
    } else {
      if(this.props.name === 'cate') {
        this.setState({ paging: paging })

        this.timerDatafilter = setTimeout((e, name) => {
          this.fetchData()
        }, 500)
      }
      if( this.props.name && this.props.name !== 'cate') {
        if( this.props.name && this.props.name === 'user') {
          let value = e.target.value
          if(value && value === '') { delete paging['search_fullname'];}
          else { paging['search_fullname'] = value}
          this.setState({ paging: paging })
          this.timerDatafilter = setTimeout((e, name) => {
            this.fetchData()
          }, 500)

        } else {
          if( this.props.name && this.props.name !== 'phase') {
            if(e.target.value && e.target.value !== '') {
              let value = e.target.value.toLowerCase()
              console.log(value);
              this.timerDatafilter = setTimeout((e, name) => {
                let obj = this.props.options.filter(o => o.Ten.toLowerCase().indexOf(value) !== -1)
                this.setState({ options: obj })
              }, 500)
            } else {
              this.setState({ options: this.props.options })
            }
          } else {

          }
        }

      }
    }
    this.setState({keyword: e.target.value})
  }
  render () {
    let options = []
    this.props.name && this.props.name.toString() === 'cate' && this.props.api.toString() === 'api_newspaper_office' && this.state.options.map((item, index) => (
      options.push(<div className='office' key={index} onClick={(e) => this.onClickItem(e, item, 'cate')}>{item.Cat_Name +'-'+ item.Cat_Description}</div>)
    ))
    this.props.name && this.props.name === 'cate' && (['api_position', 'api_title'].indexOf(this.props.api) !== -1) && this.state.options.map((item, index) => (
      options.push(<div className='cate' key={index} onClick={(e) => this.onClickItem(e, item, 'cate')}>{item.Ten}</div>)
    ))
    this.props.api === 'api_config' && this.state.options.map((item, index) => (
      (!this.state.results || this.state.results.findIndex( o => o === item.Ten) === -1) && options.push(<div className='truonghoc' key={index} onClick={(e) => this.onClickItem(e, item, 'cate')}>{item.Ten}</div>)
    ))
    this.props.name && this.props.name === 'phase' && this.state.options.map((item, index) => (
      options.push(<div className='phase' key={index} onClick={(e) => this.onClickItem(e, item, 'phase')}>{item.name}</div>)
    ))
    this.props.name && ['cate', 'user', 'phase'].indexOf(this.props.name) === -1 && this.state.options.map((item, index) => (
      options.push(<div className='a' key={index} onClick={(e) => this.onClickItem(e, item, 'type')}>{item.Ten || item.fullname}</div>)
    ))
    if(!this.props.name || this.props.name === 'user') { this.state.options.map((item, index) => (
      (item.Cat_Name || item._id) && (!this.state.results || this.state.results.indexOf(item.Cat_Name || item.fullname) === -1) &&
        options.push(<div className='user' key={item._id} onClick={(e) => this.onClickMutiItem(e, item)}>{item.Cat_Name || item.fullname}</div>)
    ))}

    this.props.name && this.props.name === 'cate' && (['api_position', 'api_title', 'api_config'].indexOf(this.props.api) !== -1) && (
      options.push(<div className='c' key='Khac' onClick={(e) => {this.setState({ createPosition: true }, () => this.props.createPosition())}}>Khác</div>)
    )

    let results = ''
    if(this.props.name && ['cate', 'phase'].indexOf(this.props.name) !== -1 && this.state.results) { results = this.state.results }
    if(this.props.name && ['cate', 'user', 'phase'].indexOf(this.props.name) === -1 && this.state.results) {
      if(this.props.ten || this.props.name === 'province') {
        results = this.state.results
      } else {
        let obj = global[this.props.name].find(o => o.id === this.state.results);
        results = obj ? obj.Ten : ''
      }
    }
    if((!this.props.name || this.props.name === 'user') && this.state.results && this.state.results.length > 0) {
      results = this.state.results && rxu.array(this.state.results).map((item, index) => (
        <div className={this.props.name === 'user' ? 'rx-inline' : ''} key={index} onClick={(e) => this.onClickResult(e, item)}>{item} <b>x</b></div>
      ))
    }
    if(this.props.api === 'api_config' && this.state.results && this.state.results.length > 0) {
      results = []
      results = this.state.results && rxu.array(this.state.results).map((item, index) => (
        <div className={this.props.api === 'api_config' ? 'rx-inline' : ''} key={index} onClick={(e) => this.onClickResult(e, item)}>{item} <b>x</b></div>
      ))
    }

    // let results = this.state.options.map((item, index) => (
    //   this.state.results.indexOf(item.Cat_ID) !== -1 ? <div key={index} onClick={(e) => this.onClickResult(e, item)}>{item.Cat_Name} <b>x</b></div> : <span key={index} />
    // ))
    return (
      <div className='rxselectbox-wrap'>
        {this.state.toggle && <div className='rx-fixed' onClick={() => this.toggle()}></div>}
        <div className='rxselectbox-result clearfix' onClick={() => this.toggle()}>
          {(!this.state.results || (this.state.results && this.state.results.toString().length === 0)) && <span className='rxselectbox-resultempty'>{this.props.optionDefault || ''}</span>}
          {(this.state.results && this.state.results.toString().length > 0) && <div>{results}</div>}
          {this.state.toggle ? <span className='rx-dropdown up iconcustom-up'></span> : <span className='rx-dropdown iconcustom-down'></span>}
        </div>
        {this.state.toggle && <div className='rxselectbox-toggle'>
          <div className='rxselectbox-filter'><input type='text' className='' onChange={(e) => this.onBlurDatafilter(e)} id='input-search' value={this.state.keyword}/></div>
          {/*this.props.name && this.props.name === 'phase' && this.props.add === true && <div className='rxSelectbox__addbtn betable__btnadd btn' onClick={(e) => this.onClickShowCreatePhase()}><i className='iconcustom-create betable__addbtn' />Thêm mới</div>*/}
          <div onClick={(e) => this.onClickItem(e, -1)}>{this.props.optionDefault}</div>
          {options && options.length > 0 ? options : <div className='rx-no-result'>Không tìm thấy kết quả</div>}
        </div> }
      </div>
    )
  }
}
RxSelectbox.defaultProps = { onChange: () => {}, options: [], results: '' }
// Options : {key: , text: }
export default RxSelectbox
