import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default

export const renderTableHead = (context, vm) =>
  <tr className='rx-title__table'>
    <th className={context.helpSortClass('Cat_Name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Cat_Name')}>Tên cơ quan báo chí</th>
    <th className={context.helpSortClass('Cat_Name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Cat_Name')}>Mô tả</th>
    <th className={context.helpSortClass('Code', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Code')}>Mã cơ quan báo chí</th>
    <th className={context.helpSortClass('Code', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Code')}>Mã cơ quan định danh</th>
    <th className={context.helpSortClass('DiaChi', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'DiaChi')}>Địa chỉ</th>
    <th className={context.helpSortClass('DienThoai', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'DienThoai')}>Số điện thoại</th>
    <th className={context.helpSortClass('Fax', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'Fax')}>Số fax</th>
    <th className={context.helpSortClass('is_active', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'is_active')}>Trạng thái</th>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && <th className='rxwidth--100'>Thao tác</th>}
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td>{perdata.Cat_Name}</td>
    <td>{perdata.Cat_Description}</td>
    <td>{perdata.Code}</td>
    <td>{perdata.CodeActive}</td>
    <td>{perdata.DiaChi}</td>
    <td>{perdata.DienThoai}</td>
    <td>{perdata.Fax}</td>
    <td>{global.rxStatus[perdata.is_active]}</td>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='QUẢN LÝ CƠ QUAN BÁO CHÍ' desc=''>
    <div className='rxcol-100'>
      <RxCrud renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name={'newspaperOffice'} api={'api_newspaper_office'} form={[
        { type: 'devide' },
        { name: 'Thuộc cục quản lý nhà nước (Cục báo chí/ Cục phát thanh)', func: (context) => (<RxSelectbox
            name='TypeCQ'
            options={global.TypeCQ}
            results={context.state.editingData.Type || ''}
            onChange={(result) => { context.onBlurDataValue(result, 'Type') }}
            optionDefault='--Chọn--'
        />) },
        { name: 'Thuộc khối quản lý (trung ương, địa phương)', func: (context) => (<RxSelectbox
            name='LoaiCoquan'
            options={global.LoaiCoquan}
            results={context.state.editingData.LoaiCoquan || ''}
            onChange={(result) => { context.onBlurDataValue(result, 'LoaiCoquan') }}
            optionDefault='--Chọn--'
        />) },
        { type: 'devide' },
        { name: 'Tên cơ quan báo chí', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Cat_Name || ''} onChange={(e) => context.onBlurData(e, 'Cat_Name')} className='fullwidth-input' />) },
        { name: 'Mô tả cơ quan báo chí', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Cat_Description || ''} onChange={(e) => context.onBlurData(e, 'Cat_Description')} className='fullwidth-input' />) },
        { name: 'Cơ quan chủ quản', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.CoQuanChuQuan || ''} onChange={(e) => context.onBlurData(e, 'CoQuanChuQuan')} className='fullwidth-input' />) },
        { name: 'Mã cơ quan', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Code || ''} onChange={(e) => context.onBlurData(e, 'Code')} className='fullwidth-input' />) },
        { name: 'Địa chỉ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.DiaChi || ''} onChange={(e) => context.onBlurData(e, 'DiaChi')} className='fullwidth-input' />) },
        { name: 'Địa chỉ cơ quan chủ quản', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.DiaChiCoQuanChuQuan || ''} onChange={(e) => context.onBlurData(e, 'DiaChiCoQuanChuQuan')} className='fullwidth-input' />) },
        { name: 'Số điện thoại', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.DienThoai || ''} onChange={(e) => context.onBlurData(e, 'DienThoai')} className='fullwidth-input' />) },
        { name: 'Số điện thoại cơ quan chủ quản', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.DienThoaiCoQuanChuQuan || ''} onChange={(e) => context.onBlurData(e, 'DienThoaiCoQuanChuQuan')} className='fullwidth-input' />) },
        { name: 'Số fax', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Fax || ''} onChange={(e) => context.onBlurData(e, 'Fax')} className='fullwidth-input' />) },
        { name: 'Cat_ID', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.Cat_ID || ''} onChange={(e) => context.onBlurData(e, 'Cat_ID')} className='fullwidth-input' />) },
        { name: 'Thuộc (ban, bộ, hội)', func: (context) => (<RxSelectbox
            name='LoaiCuc'
            options={global.LoaiCuc}
            results={context.state.editingData.LoaiCuc || ''}
            onChange={(result) => {console.log(result); context.onBlurDataValue(result, 'LoaiCuc') }}
            optionDefault='--Chọn--'
        />) },
        // [[RX_WEB_DATA_CUSTOM]] //
        // [[RX_WEB_DATA_CUSTOM_END]] //
        { type: 'devide' },
        { name: 'Trạng thái', func: (context) => (<RxToggle value={context.state.editingData.is_active} onToggle={(newValue) => context.onBlurDataValue(newValue, 'is_active')} />) }
      ]} />
    </div>
  </AdminBlock>
