import * as Jsx from './sync.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget } = global.rootRequire('classes/request')

class syncComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = { flagUpdateCat: false,rxactive: {},curCat: 0,cate: [] }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    // this.fetchData()
  }

  componentDidMount () {
  }

  fetchData () {
    apiget(global.rxu.config.api_cate, {}, { '1': (json) => { this.setState({ cate: json.data }, () => {}) } })
  }

  onClickCatItem (e, Cat_ID) {
    let rxactive = {}
    rxactive[Cat_ID] = true
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: Cat_ID, rxactive: rxactive })
  }

  runUpdateCat (e) {
    let paging = (this.state.curCat) ? { search_CoQuanBaoChiID: this.state.curCat, search_IsUpdateProfile: 1, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

export default syncComponent
