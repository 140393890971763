// Website - rxshop
// src/classes/ulti.js
import { rxsecret } from './../config/index'
let rxu = require('@rxframework/rxulti')
// var crypto     = require('crypto')
// var CryptoJS   = require('crypto-js');

rxu.navClass = function (route, props, showObj, activeClass) {
  let result = ''
  showObj = showObj || {}
  let showPermission = showObj.showPermission || ''
  let showClass = showObj.showClass || ' rxshow '
  let hideClass = showObj.hideClass || ' rxhide '

  if (props.location) {
    let currentLocation = props.location.pathname || ''
    activeClass = activeClass || ' rxactive '

    if (currentLocation === route) {
      result += ' ' + activeClass
    }

    if (rxu.get(props, 'auth.user.arrper', ['']).concat(['']).indexOf(showPermission) !== -1) {
      result += ' ' + showClass
    } else {
      result += ' ' + hideClass
    }
  }

  return result
}

rxu.parseTime = function(timeOld, timeNew) {
  console.log(timeOld, timeNew);
  var u = Date.now()
  let tempstr = ''
  if(timeOld){
    u = new Date(parseInt(timeOld.substr(6), 10))
    tempstr =
      ("0" + u.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (u.getUTCMonth() + 1)).slice(-2) +
      "/" +
      u.getUTCFullYear()
  } else if(timeNew){
    u = new Date(timeNew*1000)
    tempstr =
      ("0" + u.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (u.getUTCMonth() + 1)).slice(-2) +
      "/" +
      u.getUTCFullYear()
  } else {
    u = new Date()
    tempstr =
      ("0" + u.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (u.getUTCMonth() + 1)).slice(-2) +
      "/" +
      u.getUTCFullYear()
  }
  return tempstr
}

rxu.getDate = function(time, name) {
  var u = Date.now()
  let tempstr = ''
  if(time){
    time = time - 25200
    u = new Date(time*1000)
    // console.log(u, 'u')
    tempstr =
      ("0" + u.getDate()).slice(-2) +
      "/" +
      ("0" + (u.getMonth() + 1)).slice(-2) +
      "/" +
      u.getFullYear()
  } else {
    u = new Date()
    tempstr =
      ("0" + u.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (u.getUTCMonth() + 1)).slice(-2) +
      "/" +
      u.getUTCFullYear()
  }
  return tempstr
}
rxu.getMonth = function(time, name) {
  var u = Date.now()
  let tempstr = ''
  if(time){
    time = Number(time) + 28800
    u = new Date(time*1000)
    tempstr =
      ("0" + (u.getUTCMonth() + 1)).slice(-2) +
      "/" +
      u.getUTCFullYear()
  } else {
    u = new Date()
    tempstr =
      ("0" + (u.getUTCMonth() + 1)).slice(-2) +
      "/" +
      u.getUTCFullYear()
  }
  return tempstr
}

rxu.getFullDate = function(date) {
  var u = Date.now()
  if(date && date !== 1) { u = new Date(date*1000) }
  else { u = new Date() }
  let tempstr = 'Ngày ' + ("0" + u.getUTCDate()).slice(-2) + " tháng " + ("0" + (u.getUTCMonth() + 1)).slice(-2) + " năm " + u.getUTCFullYear()
  return tempstr
}
rxu.getFullMonth = function(date) {
  var u = Date.now()
  if(date && date !== 1) { u = new Date(date*1000) }
  else { u = new Date() }
  let tempstr = " tháng " + ("0" + (u.getUTCMonth() + 1)).slice(-2) + " năm " + u.getUTCFullYear()
  return tempstr
}
rxu.rxparseTen = function (name, index) {
  let arr, str
  if(index === 0 && name === 'typeHS') {
    arr = global[name]
    str = 'Hồ sơ cấp mới'
  } else {
    arr = global[name]
    let tmp = arr ? arr.findIndex(o => o.id === index) : -1
    str = tmp !== -1 ? arr[tmp].Ten : ''
  }
  return str
}

rxu.getQuaTrinhHoatDongUpdate = function (obj) {
  obj = obj || []
  if (obj && obj.length !== 0) {
    let x = obj[obj.length - 1]
    return global.rxu.get(x, 'BacLuong' , 0)
  } else {
    return 0
  }
}

rxu.getimgArr = function (arr) {
  let result = ''
  let imgarr = arr ? ((typeof(arr) !== 'string')? arr : arr.split(',').filter(v => v !== '')) : []
  for(let i = 0; i < imgarr.length; i++) {
    result += rxu.config.base_api + '/upload/image/' + imgarr[i] + ', '
  }
  return result
}

rxu.toStringText = function (ten, coquan) {
  let result = ''
  let text = ten + ' - ' + coquan
  if(text.length >= 43) {
    result = text.slice(0, 40) + '...'
  } else {
    result = text
  }
  return result
}
rxu.ArrayToString = function(arr) {
  let result = ''
  for(let i in arr) {
    result += i < (arr.length - 1) ? (arr[i] + '; ') : arr[i]
  }
  return result
}

rxu.inDays = function(date) {
  const NOW = new Date()
  const times = [['giây', 1], ['phút', 60], ['giờ', 3600], ['ngày', 86400], ['tuần', 604800], ['tháng', 2592000], ['năm', 31536000]]

  date = new Date(date * 1000) || new Date()
  var diff = Math.round((NOW - date) / 1000)
  for (var t = 0; t < times.length; t++) {
    if (diff < times[t][1]) {
      if (t === 0) {
        return 'Vài giây trước'
      } else {
        diff = Math.round(diff / times[t - 1][1])
        return diff + ' ' + times[t - 1][0] + ' trước'
      }
    }
  }
}

rxu.imgError = function (index, name) {
  let img = document.getElementsByClassName(name)
  if (img && img[index]) {
    img[index].src = global.rxu.config.base_api + '/upload/image/ico_app_default.jpg';
  }
}

rxu.rxChangeAlias = function (x) {
  let str = x;
  if (str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,'y');
    str = str.replace(/đ/g,'d');
    str = str.replace(/ + /g,' ');
    str = str.replace(/ /g,'-');
    str = str.trim();
  }
  return str;
}

rxu.getBase64ImageFromURL = function (url, callback) {
  // var tmp = ''
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
      // tmp = reader.result
      // console.log(tmp, '111')
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
  // return tmp
}

// rxu.img = function (url) {
//   this.toDataURL(url, function(dataUrl) {
//     return dataUrl
//   })
// }

rxu.md5 = function(strsource) {
  var passHash = crypto.createHash('md5').update(strsource).digest("hex")
  return passHash
}


let secret = rxsecret()
export const rxconfig = (function () {
  let config = []

  // Web
  config.web_port = secret.web_port

  // Server
  config.base_api = secret.base_api
  //link qrcode
  config.base_qrcode  = secret.base_qrcode
  config.base_android = secret.base_android
  config.base_ios     = secret.base_ios

  // Authorize
  // Homepage
  config.api_home = config.base_api + '/site'
  config.api_site_product = config.base_api + '/site/product'
  config.api_site_productcate = config.base_api + '/site/productcate'
  config.api_site_order_add = config.base_api + '/site/orderadd'

  config.api_authorize = config.base_api + '/authorize'
  config.api_login     = config.base_api + '/authorize/login'
  config.api_authorize_sso = config.base_api + '/authorize/loginSSO'
  config.api_revoke_sso = config.base_api + '/authorize/logoutSSO'

  // Note
  config.api_note = config.base_api + '/note'


  // Note cate
  config.api_cate         = config.base_api + '/cate'
  config.api_cate_edit    = config.api_cate + '/edit'
  config.api_cate_confirm = config.api_cate + '/confirmStatus'
  config.api_cate_confirm_submit = config.api_cate + '/confirmSubmit'
  config.api_cate_confirm_submit_rollback = config.api_cate + '/confirmRollback'

  config.api_cate_restore = config.api_cate + '/restore'
  config.api_cate_delete  = config.api_cate + '/delete'
  config.api_cate_detail  = config.api_cate + '/detail'

  // Note cate
  config.api_review = config.base_api + '/review'

  config.api_card            = config.base_api + '/card'
  config.api_card_import     = config.api_card + '/importExcel'
  config.api_card_edit       = config.api_card + '/edit'
  config.api_card_confirm    = config.api_card + '/confirmStatus'
  config.api_card_delete     = config.api_card + '/delete'
  config.api_card_delete_all = config.api_card + '/deleteAll'
  config.api_card_confirm_all= config.api_card + '/confirmAll'
  config.api_card_un_confirm_all= config.api_card + '/unConfirmAll'
  config.api_card_confirm_rollback_all= config.api_card + '/confirmRollback'
  config.api_card_image_edit       = config.api_card + '/imageEdit'

  config.api_card_restore    = config.api_card + '/restore'
  config.api_card_review     = config.api_card + '/review'
  config.api_card_details    = config.api_card + '/details'
  config.api_card_edittrace  = config.api_card + '/edittrace'
  config.api_card_submit     = config.api_card + '/submit'
  config.api_card_reviewDetail     = config.api_card + '/reviewDetail'
  config.api_card_checkCardExits     = config.api_card + '/checkCardExits'

  config.api_card_cardGroup    = config.api_card + '/cardGroup'
  config.api_card_cardDone     = config.api_card + '/cardDone'
  config.api_card_resetConfirm = config.api_card + '/resetConfirm'
  config.api_card_print        = config.api_card + '/print'
  config.api_card_printlist    = config.api_card + '/printList'
  config.api_card_printAll     = config.api_card + '/printAll'

  // Dashboard


  //analytics
  config.api_analystics = config.base_api + '/analytics'
  config.api_analystics_edit = config.api_analystics + '/edit'
  config.api_analystics_delete= config.api_analystics + '/delete'
  config.api_analystics_restore = config.api_analystics + '/restore'

  config.api_logs = config.base_api + '/logaction'
  config.api_logs_detail = config.api_logs + '/detail'

  // Order
  config.api_order = config.base_api + '/order'
  config.api_productcate = config.base_api + '/productcate'
  config.api_product = config.base_api + '/product'

  config.api_user = config.base_api + '/user'
  config.api_user_edit = config.api_user + '/edit'
  config.api_user_delete = config.api_user + '/delete'
  config.api_user_restore = config.api_user + '/restore'
  config.api_user_find = config.api_user + '/find'
  config.api_user_changepassword = config.api_user + '/changePassword'
  config.api_child_user = config.api_user + '/childuser'

  config.api_role = config.base_api + '/role'
  config.api_role_edit = config.api_role + '/edit'
  config.api_role_delete = config.api_role + '/delete'
  config.api_role_restore = config.api_role + '/restore'
  config.api_role_all = config.api_role + '/all'

  config.api_member = config.base_api + '/member'
  config.api_member_edit = config.api_member + '/edit'
  config.api_member_delete = config.api_member + '/delete'
  config.api_member_restore = config.api_member + '/restore'
  config.api_member_all = config.api_member + '/all'
  config.api_user_syncSSO = config.api_member + '/updateUserSSO'
  config.api_user_sendinfo = config.api_member + '/sendInfo'
  config.api_member_export = config.api_member + '/export'

  config.api_permission = config.base_api + '/permission'
  config.api_permission_edit = config.api_permission + '/edit'
  config.api_permission_delete = config.api_permission + '/delete'
  config.api_permission_restore = config.api_permission + '/restore'
  config.api_permission_all = config.api_permission + '/all'
  config.api_permission_genpermission = config.api_permission + '/generation'

  //office
  config.api_office = config.base_api + '/office'
  config.api_office_edit = config.api_office + '/edit'
  config.api_office_delete= config.api_office + '/delete'
  config.api_office_restore = config.api_office + '/restore'

  config.api_media = config.base_api + '/media'
  config.api_media_edit = config.api_media + '/edit'
  config.api_media_delete= config.api_media + '/delete'
  config.api_media_restore = config.api_media + '/restore'

  //newspaper_office
  config.api_newspaper_office = config.base_api + '/newspaper_office'
  config.api_newspaper_office_edit = config.api_newspaper_office + '/edit'
  config.api_newspaper_office_delete= config.api_newspaper_office + '/delete'
  config.api_newspaper_office_restore = config.api_newspaper_office + '/restore'
  config.api_newspaper_office_list = config.api_newspaper_office + '/list'
  config.api_newspaper_office_check = config.api_newspaper_office + '/check'
  config.api_newspaper_office_getlist = config.api_newspaper_office + '/getListOffice'
  config.api_newspaper_office_getListAdmin = config.api_newspaper_office + '/getListAdmin'

  //position
  config.api_position = config.base_api + '/position'
  config.api_position_edit = config.api_position + '/edit'
  config.api_position_delete= config.api_position + '/delete'
  config.api_position_restore = config.api_position + '/restore'
  config.api_position_getcard = config.api_position + '/getCard'
  //config
  config.api_config = config.base_api + '/config'
  config.api_config_edit = config.api_config + '/edit'
  config.api_config_delete= config.api_config + '/delete'
  config.api_config_restore = config.api_config + '/restore'
  //Title chuc danh bao chi
  config.api_title = config.base_api + '/title'
  config.api_title_edit = config.api_title + '/edit'
  config.api_title_delete= config.api_title + '/delete'
  config.api_title_restore = config.api_title + '/restore'
  //province
  config.api_province             = config.base_api + '/province'
  config.api_province_edit        = config.api_province + '/edit'
  config.api_province_delete      = config.api_province + '/delete'
  config.api_province_restore     = config.api_province + '/restore'
  config.api_province_import      = config.api_province + '/import'
  //dashboard
  config.api_dashboard            = config.base_api + '/dashboard'
  config.api_dashboard_info     = config.api_dashboard + '/info'
  config.api_dashboard_gender     = config.api_dashboard + '/gender'
  config.api_dashboard_old        = config.api_dashboard + '/old'
  config.api_dashboard_oldtime    = config.api_dashboard + '/oldtime'
  config.api_dashboard_gendertime = config.api_dashboard + '/gendertime'
  config.api_dashboard_print      = config.api_dashboard + '/print'
  config.api_dashboard_request    = config.api_dashboard + '/request'

  config.api_dashboard_analytics  = config.api_dashboard + '/analytics'
  config.api_dashboard_review     = config.api_dashboard + '/review'
  config.api_dashboard_getnoti    = config.api_dashboard + '/getnoti'

  config.api_sendProfileSync      = config.base_api + '/service/syncLGSP/sendProfileSync'
  config.api_sendProfileSyncOne      = config.base_api + '/service/syncLGSP/sendProfileSyncOne'
  config.api_sendProfileSyncUpdate   = config.base_api + '/service/syncLGSP/sendProfileSyncUpdate'


  //sentmail
  config.api_sentmail = config.base_api + '/sentmail'
  // [[RX_APIS]] //

  return config
}())

rxu.secret = secret
rxu.config = rxconfig
export default rxu
