import React, { Component } from 'react'
import { connect } from 'react-redux'
global.isclient && require('./payment.css')

const { apiget, apipost } = global.rootRequire('classes/request')
const { cartClear, cartAdd, cartDelete, rxsearchClear, rxnavClose } = global.rootRequire('redux')

class Component_ extends Component {
  constructor (props) {
    super(props)

    this.maxPrice = 10000
    this.minPrice = 100

    this.timeout = null
    this.state = global.rootContext(this.props.staticContext) || { products: [], reviews: [], hotproducts: [], editingData: {} }
    this.state.products = this.state.products || []
    this.state.hotproducts = this.state.hotproducts || []
    this.state.reviews = this.state.reviews || []
    this.state.editingData = {}
    this.state.paging = {}
    this.state.errorSubmit = { id: 0, name: '' }
    this.state.successSubmit = 0
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.match.params.slug !== this.state.slug) {
      let slug = nextProps.match.params.slug
      let oldslug = this.state.slug
      this.setState({ slug: slug, filter: {}, paging: {} }, () => {
        // When new slug come, not from server
        if (oldslug) { this.fetchData() }
      })
    }
  }

  componentDidMount () {
    this.initData()
  }

  initData () {
    global.scrolltop()
    this.props.rxnavClose('rxcart', {})
    if (!this.state.products.length) {
      this.fetchData()
    }
  }

  fetchData () {
    let newPaging = this.state.paging
    newPaging.slug = this.state.slug
    apiget(global.rxu.config.api_site_product, newPaging, {
      '1': (json) => {
        this.setState({ products: json.data.products, hotproducts: json.data.hotproducts, cate: json.data.cate })
      }
    })
  }

  // B L U R   E V E N T
  onBlurDataValue (value, name) {
    let editingData = this.state.editingData
    editingData[name] = value
    this.setState({ editingData: editingData })
  }

  onBlurDataPaging (e, name, prefix) {
    prefix = prefix || 'search_'
    let paging = this.state.paging
    paging[prefix + name] = e.target.value
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onBlurDataPagingValue (value, name, prefix) {
    prefix = prefix || 'search_'
    let paging = this.state.paging
    paging[prefix + name] = value
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickAddCart (e, product) {
    e.stopPropagation()
    this.props.cartAdd(product._id, { amount: 1, data: product, key: '' })
  }

  onBlurData (e, name, options) {
    options = options || {}
    let editingData = this.state.editingData
    if (options.strim) {
      editingData[name] = e.target.value.toString().replace(/(,)/g, '')
    } else {
      editingData[name] = e.target.value
    }
    this.setState({ editingData: editingData })
  }

  onSubmitOrder (e) {
    // Validate
    let formData = this.state.editingData
    if (!(formData.address && formData.phone)) {
      this.setState({ errorSubmit: { id: 1, name: 'Các trường * là bắt buộc !' } })
    } else if (!this.props.cart.total) {
      this.setState({ errorSubmit: { id: 2, name: 'Đơn hàng không được trống !' } })
    // Case: success
    } else {
      let editingData = this.state.editingData
      editingData.order = JSON.stringify(this.props.cart.carts)
      editingData.address1 = 'Tp.HCM Viet Nam'

      this.setState({ editingData: editingData }, () => {
        apipost(global.rxu.config.api_site_order_add, this.state.editingData, {
          '1': (json) => { this.setState({ successSubmit: 1 }, () => { this.props.cartClear() }) }
        })
      })
    }
  }

  render () {
    return (<div className='ppayment'>
      <div className='container'>
        <div className='pcate__slide' />
        { !this.state.successSubmit
          ? <div>
            <div className='col-md-6 col-sm-6 col-xs-12 row col--rightborder'>
              <div className='ppayment__cart'>
                <div className='ppayment__cartwrap'>
                  {(this.props.cart && this.props.cart.carts && Object.keys(this.props.cart.carts).length) ? Object.keys(this.props.cart.carts).map((i) => {
                    let cartItem = this.props.cart.carts[i]
                    return (
                      (cartItem.option && cartItem.option.data) && <div className='homenav__cartitem homenav__cartitem--lg' key={cartItem.option.data._id}>
                        <div className='cartitem__pic cartitem__pic--lg'><img alt={cartItem.option.data.name} src={global.rxu.config.base_api + '/upload/image/' + (cartItem.option.data.img_landscape || 'ico_app_default.jpg')} /></div>
                        <div className='cartitem__nam cartitem__nam--lg'>{cartItem.option.data.name || ''}</div>
                        <div className='cartitem__pricehold cartitem__pricehold--lg'>
                          <div className='cartitem__num'>{cartItem.option.amount || 0}</div>
                          <div className='cartitem__price'>x {cartItem.option.data.price || 0} = </div>
                          <div className='cartitem__total'>{cartItem.option.data.price * cartItem.option.amount || 0}</div>
                        </div>
                        <div className='cartitem__del' onClick={(e) => { this.props.cartDelete(cartItem.id) }}>x</div>
                      </div>)
                  }) : <div className='cartitem__empty'>Giỏ hàng bạn đang trống, hãy cùng chọn sản phẩm tốt nhất</div>}
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12 row'>
              <div className='ppayment__info'>
                <div className='col-md-12 col-sm-12 col-xs-12'>
                  <div>
                    <label>Địa chỉ 1</label>
                    <input type='text' defaultValue='Tp.HCM Viet Nam' disabled='disabled' />
                  </div>

                  <div>
                    <label>Địa chỉ 2<b>*</b></label>
                    <input type='text' placeholder='Tp.HCM Viet Nam' value={this.state.editingData.address || ''} onChange={(e) => this.onBlurData(e, 'address')} />
                  </div>

                  <div>
                    <label>Số điện thoại <b>*</b></label>
                    <input type='text' placeholder='099 999 9999' value={this.state.editingData.phone || ''} onChange={(e) => this.onBlurData(e, 'phone')} />
                  </div>

                  <div className='ppayment__error'>{ this.state.errorSubmit.id ? this.state.errorSubmit.name : '' }</div>
                  <div className='ppayment__done' onClick={(e) => { this.onSubmitOrder(e) }}>Đặt đơn hàng</div>
                </div>
              </div>
            </div>
          </div> : <div className='pppayment__success'>
          Đặt hàng thành công
          </div> }
      </div>

      <div className='block__parallax'>
        <div className='block__parallaxcontent'>
          <div className='block__parallaxtitle'>RXCODE GENERATOR</div>
          <div className='block__parallaxdesc'>Nhanh chóng, thuận tiện và tối ưu, bắt đầu phát triển phần mềm ngay bây giờ, với công nghệ mới nhất</div>
        </div>
        <div className='block__parallaxstroketop' />
        <div className='block__parallaxstrokebot' />
      </div>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  rxsearch: state.rxSearch
})

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  cartDelete,
  rxsearchClear,
  rxnavClose
}

const ComponentWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component_)

export default ComponentWrapped
