/* global t */
import React, { Component } from 'react'

const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const RxCheckbox = global.rootRequire('components/shares/rxCheckbox').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const types = ['', 'Cục Báo Chí', 'Cục Phát Thanh']
class userComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      arrrole: []
    }
    this.fetchData()
  }

  fetchData () {
    apiget(global.rxu.config.api_role_all, {}, { '1': (json) => { this.setState({ arrrole: json.data }) } })
  }

  renderTableHead (inthis) {
    return (<tr className='rx-title__table'>
      <th className='rxwidth--100'>{t('Picture')}</th>
      <th className={inthis.helpSortClass('fullname', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'fullname')} >{t('Tên cơ quan báo chí')}</th>
      <th className={inthis.helpSortClass('username', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'username')} >{t('Username')}</th>
      {/*}<th className={inthis.helpSortClass('fullname', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'fullname')} >{t('Mật khẩu')}</th>*/}
      <th className={inthis.helpSortClass('Type', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'Type')} >{t('Cục quản lý')}</th>
      <th className={inthis.helpSortClass('phone', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'phone')}>{t('Số điện thoại')}</th>
      <th className={inthis.helpSortClass('status', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'status')}>{t('Trạng thái')}</th>
      <th className={inthis.helpSortClass('created_at')} onClick={(e) => inthis.onClickSort(e, 'created_at')}>{t('Created at')}</th>
      <th className={inthis.helpSortClass('updated_at')} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>{t('Updated at')}</th>
      <th className='rxwidth--100'>{t('Action')}</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
      <td>{perdata.fullname}</td>
      <td>{perdata.username}</td>
      <td>{types[perdata.Type]}</td>
      <td>{perdata.phone}</td>
      <td>{global.rxStatus[perdata.is_active]}</td>

      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td><small>{global.rxu.date(perdata.updated_at)}</small></td>
      <td>
        { inthis.state.paging.search_is_deleted !== 1 &&
          <div style={{float: 'right'}}> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span> </div> }
        { inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  // R E N D E R S
  render () {
    return (
      <div className='adblock'>
        {/*<div className='adblock__head'>
          <div className='adblock__title'>{t('user')}</div>
          <div className='adblock__desc'>{t('user')}</div>
        </div>*/}
        <div className='adblock__body'>
          <div className=''>
            <div className='rxcol-100'>
              <RxCrud roles={this.state.arrrole} renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} name='User' api={'api_user'}
                form={[
                  // { name: 'Hình ảnh', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },
                  { type: 'devide' },
                  { name: 'Cục quản lý *', func: (inthis) => (<select value={inthis.state.editingData.Type} onChange={(e) => inthis.onBlurData(e, 'Type')} className='fullwidth-input'><option value=''>--Chọn Cục quản lý--</option>{global.Types.map((role,index) => (<option key={role.id} value={role.id}>{role.name}</option>))}</select>) },
                  { name: 'Vai trò *', func: (inthis) => (<select value={inthis.state.editingData.role} onChange={(e) => inthis.onBlurData(e, 'role_id')} className='fullwidth-input'><option value=''>--Chọn phân quyền--</option>{this.state.arrrole.map((role,index) => (<option key={role._id} value={role.name}>{role.desc}</option>))}</select>) },
                  { type: 'devide' },
                  { name: 'Tên đăng nhập *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.username || ''} onChange={(e) => inthis.onBlurData(e, 'username')} className='fullwidth-input' />) },
                  { name: 'Họ và tên *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.fullname || ''} onChange={(e) => inthis.onBlurData(e, 'fullname')} className='fullwidth-input' />) },

                  { type: 'devide' },
                  { name: 'Email *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.email || ''} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />) },
                  { name: 'Số điện thoại *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.phone || ''} onChange={(e) => inthis.onBlurData(e, 'phone')} className='fullwidth-input' />) },
                  { type: 'devide' },
                  // { name: 'Cơ quan báo chí *', func: (inthis) => (
                  //   <RxSelectbox
                  //     options={inthis.state.cate}
                  //     id={inthis.state.editingData['CoQuanBaoChiID'] || []}
                  //     results={inthis.state.editingData.CoQuanBaoChi || []}
                  //     onChange={(result) => { inthis.onBlurDataValue(result, 'adduser') }}
                  //     api='api_newspaper_office'
                  //     optionDefault='--Chọn cơ quan báo chí--'
                  //     Type={inthis.state.editingData.Type}
                  //     onFilter={(result) => inthis.onFilterValue(result, 'adduser')}
                  //   />
                  // )},

                  { name: 'Mật khẩu *', func: (inthis) => (<input tabIndex='1' type='password' value={inthis.state.editingData.password || ''} onChange={(e) => inthis.onBlurData(e, 'password')} className='fullwidth-input' />) },
                  { name: 'Nhập lại mật khẩu *', func: (inthis) => (<input tabIndex='1' type='password' value={inthis.state.editingData.repassword || ''} onChange={(e) => inthis.onBlurData(e, 'repassword')} className='fullwidth-input' />) },
                  { type: 'devide' },
                  // { name: 'Địa chỉ *', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.address || ''} onChange={(e) => inthis.onBlurData(e, 'address')} className='fullwidth-input' />) },
                  // { name: 'Mô tả', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.desc || ''} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },

                  { type: 'devide' },
                  { name: 'Trạng thái', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) },
                  { name: 'Thuộc khối quản lý', func: (inthis) => (<RxSelectbox
                      name='LoaiCoquan'
                      options={global.LoaiCoquan}
                      results={inthis.state.editingData.LoaiCoquan || ''}
                      onChange={(result) => { inthis.onBlurDataValue(result, 'LoaiCoquan') }}
                      optionDefault='--Chọn--'
                  />) }

                ]}
                form2={[
                  { type: 'header', func: (inthis) => (
                    <div>
                      <RxCheckbox
                        role={inthis.state.editingData['role'] || ''}
                        options={inthis.state.cate}
                        id={global.rxu.array(inthis.state.editingData['CoQuanBaoChiID'] || [])}
                        results={inthis.state.editingData.CoQuanBaoChi || []}
                        onChange={(result, err) => { inthis.onBlurDataOffice(result, 'adduser', err) }}
                        api='api_newspaper_office'
                        Type={inthis.state.editingData.Type}
                        fullname={inthis.state.editingData['fullname'] || ''}
                        resetData={() => inthis.resetDataOffice()}
                      />
                    </div>
                  ) },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default userComponent
