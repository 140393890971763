import React, { Component } from 'react'

const RxCrud = global.rootRequire('components/shares/rxCrudLogAction').default

function parseObject (obj, obj_old, name, type) {
  let temp = [], index=0
  // console.log(obj, 'lll', obj_old, 'knjhb', name, type)
  for (let i in obj) {
    temp.push(
      <div className={((obj && obj_old && obj[i] && obj_old[i]) && obj[i] === obj_old[i]) ? '' : ('rxdiff__content--' + type)} key={index}>"{i}": "{obj[i]}"</div>
    )
    index++
  }
  return temp
}

function parseArray (arr, arr_old, name, type) {
  if(arr && arr_old && arr.length > 0 && arr_old.length > 0) {
    let temp = []//, index=0
    arr && arr.map((obj, index) => (
      temp.push(parseObject(obj, arr_old[index], name, type))
    ))
    return temp
  } else if(arr && arr.length > 0) {
    parseArrayOnly(arr)
  } else if( arr_old && arr_old.length > 0) {
    parseArrayOnly(arr_old)
  }
}
function parseObjectOnly (obj) {
  let temp = [], index=0
  for (let i in obj) {
    temp.push(
      <div key={index}>"{i}": "{obj[i]}"</div>
    )
    index++
  }
  return temp
}

function parseArrayOnly (arr) {
  let temp = []
  arr && arr.map((obj) => (
    temp.push(parseObjectOnly(obj))
  ))
  return temp
}

class logComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      arrrole: []
    }
  }

  renderTableHead (inthis) {
    return (<tr>
      <th className='rx-th-width-220' onClick={(e) => inthis.onClickSort(e, 'username')} >Người thao tác <i className={inthis.helpSortClass('username')} /></th>
      <th className='rx-th-width-220' onClick={(e) => inthis.onClickSort(e, 'name')} >Nơi thao tác <i className={inthis.helpSortClass('name')} /></th>
      <th className='rx-th-width-220' >Tên nội dung</th>
      <th className='rx-th-width-220' onClick={(e) => inthis.onClickSort(e, 'action')} >Hành động</th>
      <th className='rx-th-width-220' onClick={(e) => inthis.onClickSort(e, 'method')} >Phương thức</th>
      <th className='rx-th-width-220' onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo <i className={inthis.helpSortClass('created_at')} /></th>
      <th className='rxwidth--100'>Thao tác</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td>{global.rxu.get(perdata, ['username', 'fullname'], '')}</td>
      <td>{perdata.name}</td>
      {perdata.name.indexOf('member') === -1 && <td>{global.rxu.get(perdata, ['data', 'name'], global.rxu.get(perdata, ['data', 'HoTenKhaiSinh'], ''))}</td>}
      {perdata.name.indexOf('member') !== -1 && <td>{global.rxu.get(perdata, ['data', 'CoQuanBaoChi'])}</td>}
      <td>{perdata.action}</td>
      <td>{perdata.method}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td>
        { inthis.state.paging.search_is_deleted !== 1 &&
          <div style={{float: 'right'}}> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span> </div> }
        { inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  renderFormBody (inthis) {
    let data = global.rxu.get(inthis.state, ['editingData', 'data'])
    let data_old = global.rxu.get(inthis.state, ['editingData', 'data_old'])

    let formBody = []
    let index = 0
    if(inthis.state.editingData && (data || data_old)) {
      if(data && data_old && data._id && data_old._id && inthis.state.editingData['action'] === 'EDIT') {
        for (let i in data_old) {
          if(data_old[i] && typeof data_old[i] === 'object') {
            if(data_old[i].constructor === Array) {
              formBody.push(
                <tr key={i}>
                  <td className='rxdiff__content-number'>{index+1}</td>
                  
                  <td>"{i}": {parseArray(data_old[i], data[i], i, 'old')}</td>
                  
                  <td className='rxdiff__content-number'>{index+1}</td>

                  <td>"{i}": {parseArray(data[i], data_old[i], i, 'new')}</td>
                </tr>)
            } else {
              formBody.push(
                <tr key={i}>
                  <td className='rxdiff__content-number'>{index+1}</td>
                  
                  <td>"{i}": {parseObject(data_old[i], data[i], i, 'old')}</td>
                  
                  <td className='rxdiff__content-number'>{index+1}</td>

                  <td>"{i}": {parseObject(data_old[i], data[i], i, 'new')}</td>
                </tr>)
            }
          } else {
            formBody.push(
              <tr key={i}>
                <td className={data[i] === data_old[i] ? 'rxdiff__content-number' : 'rxdiff__content--old rxdiff__content-number'}>{index+1}</td>

                <td className={data[i] === data_old[i] ? '' : 'rxdiff__content--old'}>"{i}": "{data_old[i]}"</td>

                <td className={data[i] === data_old[i] ? 'rxdiff__content-number' : 'rxdiff__content--new rxdiff__content-number'}>{index+1}</td>

                <td className={data[i] === data_old[i] ? '' : 'rxdiff__content--new'}>"{i}": "{data[i]}"</td>
              </tr>)
          }
          index++
        }
      }
      if((!data || !data['_id']) && data_old && data_old._id && inthis.state.editingData['action'] === 'DELETE') {
        for (let i in data_old) {
          if(data_old[i] && typeof data_old[i] === 'object') {
            if(data_old[i].constructor === Array) {
              formBody.push(
                <tr key={i}>
                  <td className='rxdiff__content-number'>{index+1}</td>
                  
                  <td>"{i}": {parseArrayOnly(data_old[i])}</td>
                </tr>)
            } else {
              formBody.push(
                <tr key={i}>
                  <td className='rxdiff__content-number'>{index+1}</td>
                  
                  <td>"{i}": {parseObjectOnly(data_old[i])}</td>
                </tr>)
            }
          } else {
            formBody.push(
              <tr key={i}>
                <td className='rxdiff__content-number'>{index+1}</td>

                <td>"{i}": "{data_old[i]}"</td>
              </tr>)
          }
          index++
        }
      }
      if((!data_old  || data_old['_id']) && data && data._id && inthis.state.editingData['action'] === 'CREATE') {
        for (let i in data) {
          if(data[i] && typeof data[i] === 'object') {
            if(data[i].constructor === Array) {
              formBody.push(
                <tr key={i}>
                  <td className='rxdiff__content-number'>{index+1}</td>
                  
                  <td>"{i}": {parseArrayOnly(data[i])}</td>
                </tr>)
            } else {
              formBody.push(
                <tr key={i}>
                  <td className='rxdiff__content-number'>{index+1}</td>
                  
                  <td>"{i}": {parseObjectOnly(data[i])}</td>
                </tr>)
            }
          } else {
            formBody.push(
              <tr key={i}>
                <td className='rxdiff__content-number'>{index+1}</td>

                <td>"{i}": "{data[i]}"</td>
              </tr>)
          }
          index++
        }
      }
      return( <div style={{width: '100%'}}>
        <div className='rxdiff__file'>
          <div className="rxdiff__content">
            <div className="">
              <table>
                <tbody>
                  <tr className="">
                    <td className='text-center' colSpan='2'>Dữ liệu cũ</td>
                    <td className='text-center' colSpan='2'>Dữ liệu mới</td>
                  </tr>
                  {formBody}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)
    }
  }

  // R E N D E R S
  render () {
    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='adblock__title'>LỊCH SỬ HOẠT ĐỘNG</div>
        </div>
        <div className='adblock__body'>
          <div className=''>
            <div className='rxcol-100'>
            <RxCrud renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} flagUpdate={this.state.flagUpdateCat}
                    parentUpdate={this.runUpdateCat} name='Logs' api={'api_logs'} renderFormBody={this.renderFormBody} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default logComponent
