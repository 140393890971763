let lang = {

  // Admin
  'dashboard': 'Tổng quát',
  'chart': 'Biểu đồ',

  // Admin sidebar
  'topic': 'Nội dung',
  'content': 'Nội dung',
  'agent': 'Tác nhân',
  'article': 'Bài viết',
  'channel': 'Kênh',
  'application': 'Ứng dụng',
  'keyword': 'Từ khoá',
  'system': 'Hệ thống',
  'org': 'Tổ chức',
  'user': 'Người dùng',
  'post': 'Bài viết',
  'logout': 'Đăng xuất',

  // 'role': 'Vai trò',
  'role': 'Vai trò',
  'permission': 'Quyền hạn',
  'app': 'Ứng dụng',
  'key': 'Từ khoá',
  'fblink': 'Đường dẫn',
  'fbtagviolent': 'Vi phạm',
  'platform': 'Nền tảng',
  'tag': 'Tag',
  'media': 'Media',
  'report': 'Báo cáo',

  // Admin table
  'picture': 'Ảnh',
  'code': 'Mã',
  'name': 'Tên',
  'rank': 'Cấp bậc',
  'desc': 'Mô tả',
  'status': 'Trạng thái',
  'quantily': 'Số lượng',
  'direct management': 'Quản lí trực tiếp',
  'under': 'Trực thuộc',
  'father': 'Quản lí trực tiếp',
  'created at': 'Ngày tạo',
  'action': 'Thao tác',
  'Total Rows': 'Tổng số dữ liệu ',
  'Pagesize': 'Kích cỡ trang',
  'Cancel': 'Hủy',
  'Update': 'Cập nhật',
  'Create new': 'Tạo mới',
  'Import dữ liệu': 'Thêm dữ liệu',
  'Picture': 'Ảnh',
  'Username': 'Tên đăng nhập',
  'Fullname': 'Họ và tên',
  'Created at': 'Ngày tạo',
  'Updated at': 'Ngày cập nhật',
  'Action': 'Hành động',
  'Role': 'Quyền hạn',
  'Phone': 'Số điện thoại',
  'Status': 'Trạng thái',
  'Name': 'Tên',
  'Desc': 'Mô tả',
  'Edit Permission For Role':'Chỉnh sửa quyền cho vai trò',
  'Pic': 'Hình ảnh',
  'return': 'Trở về',

  // Admin fbpost, post
  'authorName': 'Tên tác giả',
  'title': 'Tiêu đề',
  'description': 'Mô tả',
  'category': 'Danh mục',
  'Content': 'Nội dung',

  // Ads
  'ads': 'Quảng cáo',

  // Website
  'website': 'Trang thông tin',

  //log
  'BacLuong': 'Bậc Lương'

}

let translate = function (value) {
  if (typeof lang[value] !== 'undefined') {
    return lang[value]
  } else {
    return value
  }
}

export default translate
