/* global t */
import React from 'react'
import Select from 'react-select';

global.isclient && require('jsoneditor/dist/jsoneditor.css')
let JSONEditor = global.isclient ? require('jsoneditor') : {}

const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
// const RxSelectboxMulti = global.rootRequire('components/shares/rxSelectboxMulti').default
const rxu = global.rxu

// T A B L E
export const renderTable = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead')}</thead><tbody>{vm.run('renderTableBody')}</tbody></MainTable>

export const renderTableHead = (vm) =>
  <tr>
    <th className='rxwidth--100'>Pic</th>
    <th className={vm.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => vm.onClickSort(e, 'name')}>Name</th>
    <th className={vm.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => vm.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (vm) => global.rxu.array(vm.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {vm.renderTableBodyBtns(vm, perdata)}
  </tr>)

export const renderTableBodyBtns = (context, perdata, vm) => {
  return <td>
    { context.state.paging.search_is_deleted !== 1 &&
    <div> {(perdata.is_hot) ? <span className='betable__icohot'><span className='icon-fire' />Hot</span> : <span className='betable__iconothot' />}
      {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
      <span className='betable__btnedit' onClick={(e) => context.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
      <span className='betable__btndelete' onClick={(e) => context.onClickDataDelete(e, perdata)}><i className='icon-close' /></span> </div> }
    { context.state.paging.search_is_deleted === 1 &&
    <div> <span className='betable__restore' onClick={(e) => context.onClickDataRestore(e, perdata)}>Restore</span></div>}
  </td>
}

// P A G I N
export const renderPagin = (vm) => {
  let paging = vm.state.paging
  let count = global.rxu.get(vm.state.extra, 'count', paging.pg_size)
  let maxpage = Math.ceil(count / paging.pg_size)
  let pages = global.rxu.arrayFromTo(1, maxpage)
  return (
    <div className='clearfix rxpagingwrap'>
      <div className='rxpagingcount'>{t('Total Rows')}<b>{global.rxu.get(vm.state.extra, 'count', 0)}</b></div>
      <div className='rxpaging'>
        <div className='rxpagesize'>
          <span>{t('Pagesize')}</span>
          <select value={paging.pg_size} onChange={e => vm.onChangePageSize(e)}>
            {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
          </select>
        </div>
        <div className='rxpage' onClick={(evt) => { vm.onClickPaging(paging.pg_page - 1) }}><i className='icon-arrow-left' /></div>
        { pages.map((ele) => ([1, paging.pg_page, maxpage].indexOf(ele) !== -1 || ele % Math.floor((maxpage / 10)) === 0) &&
          (<div key={ele} className={ele === paging.pg_page ? 'rxpage rxactive' : 'rxpage'} onClick={(evt) => { vm.onClickPaging(ele) }}>{ele}</div>)) }
        <div className='rxpage' onClick={(evt) => { vm.onClickPaging(paging.pg_page + 1) }}><i className='icon-arrow-right' /></div>
      </div>
    </div>
  )
}

const filterOptions = [
  {value: 'Name', label: 'Họ tên'},
  // {value: 'Code', label: 'Mã thẻ'},
  {value: 'CoQuanBaoChi', label: 'Cơ quan báo chí'},
  {value: 'LoaiHoSo', label: 'Loại hồ sơ'},
  {value: 'ChucVu', label: 'Chức vụ'},
  // {value: 'Tuoi', label: 'Độ tuổi'},
  {value: 'LoaiThe', label: 'Thẻ đã cấp'}
]

// F I L T E R
export const renderFilter = (vm) =>
  <div>
    <div className='betable__filter'>
      <div className='betable__btns clearfix'>
        <span className='betable__btnadd btn' onClick={(e) => vm.onClickDataNew(e)}><i className='icon-plus betable__addbtn' />Thêm mới</span>
        <span className='betable__btnimport btn' onClick={(e) => vm.onClickExcelUpload('importExcel')}><i className='icon-cloud-upload' />{t('Import dữ liệu')}</span>
        <span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExport(e)}><i className='icon-cloud-download' />Tải file</span>
        { vm.flag('importExcel') &&
        <span className='adtable__exportcsv tooltip'>
          <input onChange={(e) => vm.onClickDataImport(e)} className='' id='import-excel' type='file' accept='.xlsx, .xls' />
          <span id='buttonSubmitCsv' className='betable__btnadd btn' onClick={(e) => vm.onClickSubmitExcelUpload(e)} >Nhập</span>
        </span>
        }

        { vm.state.paging.search_is_deleted !== 1 && <i className='icon-trash adtable__recycle' onClick={(e) => vm.onClickDataTrash(e, 1)}></i> }
        { vm.state.paging.search_is_deleted === 1 && <i className='icon-list adtable__recycle' onClick={(e) => vm.onClickDataTrash(e, 0)}></i> }

      </div>
      
      <div className='col-md-12'>
        <div className='col-md-3'>
        <Select
          defaultValue={[]}
          isMulti
          options={filterOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(e)=>{ vm.onChangeFilter(e) } }
          placeholder="Tìm kiếm nâng cao..."
        />
        {/* <RxSelectboxMulti
          defaultValue={[]}
          isMulti
          options={filterOptions}
          classNamePrefix="select"
          onChange={(e)=>{ vm.onChangeFilter(e) } }
          placeholder="Tìm kiếm nâng cao..."
        /> */}
        </div>
        {/* {vm.state.filter.Code && */}
          <div className='col-md-3 custom-search'>
            <input tabIndex='3' type='text' value={vm.state.paging['search_MaTheCu']} onChange={(e) => vm.onBlurDatafilter(e, 'MaTheCu')} className='fullwidth-input' placeholder='Mã thẻ' />
          </div>
        {/* } */}

        {vm.state.filter.Name &&
          <div className='col-md-3 custom-search'>
            <input tabIndex='4' type='text' value={vm.state.paging['search_HoTenKhaiSinh']} onChange={(e) => vm.onBlurDatafilter(e, 'HoTenKhaiSinh')} className='fullwidth-input' placeholder='Nhập họ tên' />
          </div>
        }

        { vm.state.filter.LoaiThe &&
          <div className='col-md-3'>
            <RxSelectbox
                name='theDaCap'
                options={global.theDaCap}
                results={vm.state.paging['search_Status'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'Status', 1) } }
            />
          </div>
        }

        {vm.state.filter.CoQuanBaoChi &&
          <div className='col-md-3'>
            <RxSelectbox
                name='cate'
                options={vm.state.cate}
                results={vm.state.paging['search_TenCoQuanBaoChi'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'TenCoQuanBaoChi', 1) }}
                api='api_newspaper_office'
                optionDefault='--Chọn cơ quan báo chí--'
                loader='load'
            />
          </div>
        }

        {vm.state.filter.ChucVu &&
          <div className='col-md-3'>
            <RxSelectbox
                name='cate'
                options={vm.state.position}
                results={vm.state.paging['search_TenChucVu'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'TenChucVu', 1) }}
                api='api_position'
                optionDefault='--Nhóm chức vụ--'
                loader='load'
            />
          </div>
        }

        {vm.state.filter.LoaiHoSo &&
          <div className='col-md-3'>
            <RxSelectbox
                name='typeHS'
                options={global.typeHS}
                results={vm.state.paging['search_Type'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'Type', 1) }}
                optionDefault='--Chọn loại hồ sơ--'
            />
          </div>
        }

      {/* </div> */}
      {/* <div className='col-md-12'> */}

        {vm.state.filter.Tuoi &&
          <div className='col-md-4'>
            <input tabIndex='1' type='text' value={vm.state.paging['search_TuTuoi']} onChange={(e) => vm.onBlurDatafilter(e, 'TuTuoi')} className='fullwidth-input' placeholder='Từ tuổi' />
          </div>
        }

        {vm.state.filter.Tuoi &&
          <div className='col-md-4'>
            <input tabIndex='2' type='text' value={vm.state.paging['search_DenTuoi']} onChange={(e) => vm.onBlurDatafilter(e, 'DenTuoi')} className='fullwidth-input' placeholder='Đến tuổi' />
          </div>
        }


        {/* {vm.state.filter.Code &&
          <div className='col-md-4'>
            <input tabIndex='3' type='text' value={vm.state.paging['search_MaTheCu']} onChange={(e) => vm.onBlurDatafilter(e, 'MaTheCu')} className='fullwidth-input' placeholder='Mã thẻ' />
          </div>
        } */}

        {/*<div className='col-md-3 margin-10'>create</div>
        <div className='col-md-3 margin-10'>create</div>*/}

      </div>
    </div>
    {vm.props.name.indexOf('permission') !== -1 && <div className='adblock__btngen' onClick={(e) => vm.onClickGenPermission()}>Generate permissions</div>}
  </div>

// F I N D  E X P A N D
export const renderFindExpand = (vm) => {
  let filterFields = rxu.get(vm.state, ['temp', 'filterFields'])
  let filterEles = []
  for (let i in filterFields) {
    filterEles.push(
      <div className='col-md-6' key={i}>
        <div className='betable__findlabel'>Find by {i}</div>
        <input className='betable__findinput betable__findinput-full' type='text' placeholder={'vd: ' + filterFields[i]} onKeyUp={(e) => vm.onBlurDatafilter(e, i)} />
      </div>)
  }
  return <div className='rxfull'>
    {filterEles}
  </div>
}

// F O R M
export const renderForm = (vm) => {
  if (vm.state.editingData.created_at || vm.state.editingData.created_at === 0) {
    global.scrollfire()
    return <div className='adform'>
      <div className='adform__header'>
        {vm.run('renderFormHead')}
      </div>
      <div className='adform__body'>
        {vm.run('renderFormBody')}
        {/*vm.run('renderFormRaw')*/}
      </div>
      <div className='adform__footer'>
        {vm.run('renderFormBtns')}
      </div>
    </div>
  }
}

let renderFormBodyDefault = (vm) =>
  [ { name: 'Picture', func: () => (<RxUpload callback={(e) => vm.callbackUpload(e)} images={vm.state.editingData.img_landscape} />) },
    { type: 'devide' },
    { name: 'Name', func: () => (<input tabIndex='1' type='text' value={vm.state.editingData.name} onChange={(e) => vm.onBlurData(e, 'name')} className='fullwidth-input' />) },
    { name: 'Desc', func: () => (<input tabIndex='2' type='text' value={vm.state.editingData.desc} onChange={(e) => vm.onBlurData(e, 'desc')} className='fullwidth-input' />) },
    { type: 'devide' },
    { name: 'Status', func: () => (<RxToggle value={vm.state.editingData.is_active} onToggle={(newValue) => vm.onBlurDataValue(newValue, 'is_active')} />) } ]

export const renderFormBody = (vm) => {
  let view = vm.state.view
  let tempform = []
  if(view) { tempform = vm.props.view } else { tempform = vm.props.form || renderFormBodyDefault(vm) }

  if ((vm.state.editingData.created_at || vm.state.editingData.created_at === 0) && tempform) {
    let form = tempform.map((perdata, index) => {
      perdata.type = perdata.type || 'input'
      let result = (<div />)
      switch (perdata.type) {
        case 'input':
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'fullinput':
          result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'devide':
          result = <div key={index} className='fullwidth-formdevide cleafix' />
          break
        case 'header':
          result = <div className='form-header' key={index}>{perdata.func(vm)}</div>
          break
        case 'form':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{perdata.func(vm)}</div></div>
          break
        case 'table':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{vm.run('renderTableForm')}</div></div>
          break
        default:
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
      }
      return result
    })
    return form
  } 
}

export const renderTableForm = (vm) => {
  let QuaTrinhHoatDong = global.rxu.get(vm.state, ['editingData', 'QuaTrinhHoatDongUpdate'])
  return (<div className='rx-table__form'>
      <table>
        <tbody>
          <tr>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>Thời hạn (từ năm n&agrave;o đến năm n&agrave;o)</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>Chức danh b&aacute;o ch&iacute;</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>C&ocirc;ng t&aacute;c tại cơ quan b&aacute;o ch&iacute; n&agrave;o</strong></p></td>
            <td className='rx-table__form-header' colSpan="2"><p><strong>Lương</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>&nbsp;Khen thưởng kỷ luật (thời gian cụ thể)</strong></p></td>
            <td className='rx-table__form-header' rowSpan="2"><p><strong>M&atilde; thẻ</strong></p></td>
            {!vm.state.view && <td className='rx-table__form-header adbtn__add' rowSpan="2" onClick={(e) => vm.onClickAddRowTable()}><p><strong>Thêm dòng mới</strong></p></td>}
          </tr>
          <tr>
            <td className='rx-table__form-header'><p><strong>Ngạch lương</strong></p></td>
            <td className='rx-table__form-header'><p><strong>Bậc lương</strong></p></td>
          </tr>
          {QuaTrinhHoatDong && QuaTrinhHoatDong.map((obj, index) => (
            !vm.state.view ? <tr key={index}>
              <td><input tabIndex='1' type='text' value={obj.time || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'time', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='2' type='text' value={obj.ChucDanhBaoChi ||''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'ChucDanhBaoChi', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='3' type='text' value={obj.CoQuanBaoChi || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'CoQuanBaoChi', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='4' type='text' value={obj.NgachLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'NgachLuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='5' type='text' value={obj.BacLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'BacLuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='6' type='text' value={obj.KhenThuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'KhenThuong', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='7' type='text' value={obj.MaThe || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'MaThe', index)} className='fullwidth-input' /></td>
              <td className='adbtn__clear' onClick={(e) => vm.onClickRowDel(index)}>Xoá hàng</td>
            </tr> : <tr key={index}>
              <td>{obj.time || ''}</td>
              <td>{obj.ChucDanhBaoChi ||''}</td>
              <td>{obj.CoQuanBaoChi || ''}</td>
              <td>{obj.NgachLuong || ''}</td>
              <td>{obj.BacLuong || ''}</td>
              <td>{obj.KhenThuong || ''}</td>
              <td>{obj.MaThe || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>)
}

export const renderFormHead = (vm) =>
  <div className='adform__name'>Thông tin
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
      {!vm.state.view && (vm.state.editingData.created_at !== 1 ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu</span>
      : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu</span>)}
    </div>
  </div>

export const renderFormRaw = (vm) => {
  if (vm.state.editingData._id) {
    if (vm.state.flags.rawedit) {
      setTimeout(() => {
        vm.jsoneditor = new JSONEditor(vm.jsonEditor, { mode: 'tree' })
        vm.jsoneditor.set(vm.state.editingData)
      }, 500)
    }

    return (
      <div className='clearfix adform__rawedit'>
        <div onClick={evt => vm.toggle('rawedit')} className='adform__raweditbtn'>RawEdit</div>
        { vm.state.flags.rawedit ? <div>
          <div className='adform__raweditcontent' ref={e => { vm.jsonEditor = e }} />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateSubmitRaw(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmitRaw(e)}>RawUpdate</div>
        </div> : <div /> }
      </div>
    )
  }
}

export const renderFormBtns = (vm) =>
  <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
    <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, {})} onKeyPress={(e) => vm.onClickDataEdit(e, {})}>{t('return')}</div>
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
      {!vm.state.view && (vm.state.editingData.created_at !== 1 ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu</span>
      : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu</span>)}
    </div>
    {/*{vm.state.editingData.created_at !== 1 && <div tabIndex='11' className='adform__btnedit' onClick={(e) => vm.onClickDataUpdateSubmit(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmit(e)}>{t('Update')}</div>}
    <div tabIndex='12' className='adform__btnclone' onClick={(e) => vm.onClickDataCreateSubmit(e)} onKeyPress={(e) => vm.onClickDataCreateSubmit(e)}>{t('Create new')}</div>*/}
  </div>

export const renderComponent = (vm) =>
  <div>
    <div className='betable adblock__inner'>
      {vm.run('renderFilter')}
      {vm.run('renderPagin')}
      {vm.run('renderTable')}
    </div>
    {vm.run('renderForm')}
  </div>
