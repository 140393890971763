/* global t */
import React, { Component } from 'react'

const RxCrud = global.rootRequire('components/shares/rxCrud').default

class permissionComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  // R E N D E R S
  render () {
    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='adblock__title'>{t('permission')}</div>
          {/* <div className='adblock__desc'>{t('permission')}</div> */}
        </div>
        <div className='adblock__body'>
          <div className='row adblock__inner'>
            <div className='rxcol-100'>
              <RxCrud name={'permission'} api={'api_permission'} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default permissionComponent
