import React from 'react'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderCatHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className={inthis.helpSortClass('name', ['betable-th'])} onClick={(e) => inthis.onClickSort(e, 'name')} >Họ và tên</th>
  </tr>

export const renderCatBody = (inthis, vm) => {
  let tempData = global.rxu.array(inthis.state.data).slice(0)
  return tempData.map((perdata, index) => (
    <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)} className='rx-cursor' ><td>{perdata.fullname}</td>
    </tr>))
}

export const renderTableHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className='rxwidth--100'>Ảnh</th>
    <th className={inthis.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'name')} >Họ và tên</th>
    <th className='rxwidth--100'>Thuộc cơ quan báo chí</th>
    <th className={inthis.helpSortClass('created_at', ['betable-th'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.HoTenKhaiSinh}</td>
    <td>{perdata.TenCoQuanBaoChi}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
  </tr>))

export const renderComponent = (vm) =>
  <AdminBlock name='TỔNG HỢP' desc='Danh sách thẻ thuộc quản lí chuyên viên'>
    <div className='row'>
      <div className='col-md-3'>
        <RxCrud renderTableHead={vm.renderCatHead} renderTableBody={vm.renderCatBody} onClickCatItem={vm.onClickCatItem} name='note' type='user' api={'api_child_user'} form={[
          { name: 'Cate name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
        ]} />
      </div>

      <div className='col-md-9'>
        <RxCrud renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='note' api={'api_card'} form={[
          { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },

          { type: 'devide' },
          { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { name: 'Desc', func: (inthis) => (<input tabIndex='2' type='text' value={inthis.state.editingData.desc} onChange={(e) => inthis.onBlurData(e, 'desc')} className='fullwidth-input' />) },
          { name: 'Cate name', func: (inthis) => (<RxSelectbox
                                                      name='cate'
                                                      options={inthis.state.cate}
                                                      results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                                                      onChange={(result) => { inthis.onBlurDataValue(result, 'TenCoQuanBaoChi') }}
                                                      api='api_newspaper_office'
                                                      paging={inthis.state.pagingCat}
                                                      optionDefault='--Chọn cơ quan báo chí--'
                                                  />) },
          { type: 'devide' },
          { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
        ]} />
      </div>
    </div>
  </AdminBlock>
