import React, { Component } from 'react'

const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')

class productcateComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <AdminBlock name='PRODUCT CATEGORIES' desc='Manage product categories'>
        <div className='rxcol-100'>
          <RxCrud name={'productcate'} api={'api_productcate'} form={[
            { name: 'Picture', func: (inthis) => (<RxUpload callback={(e) => inthis.callbackUpload(e)} images={inthis.state.editingData.img_landscape} />) },

            { type: 'devide' },
            { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },

            // [[RX_WEB_DATA_CUSTOM]] //
            // [[RX_WEB_DATA_CUSTOM_END]] //

            { type: 'devide' },
            { name: 'Seo slug', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.seoslug} onChange={(e) => inthis.onBlurData(e, 'seoslug')} className='fullwidth-input' />) },
            { name: 'Seo title', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.seotitle} onChange={(e) => inthis.onBlurData(e, 'seotitle')} className='fullwidth-input' />) },
            { name: 'Seo meta', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.seometa} onChange={(e) => inthis.onBlurData(e, 'seometa')} className='fullwidth-input' />) },

            { type: 'devide' },
            { name: 'Hot', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_hot} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_hot')} />) },
            { type: 'devide' },
            { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
          ]} />
        </div>
      </AdminBlock>
    )
  }
}

export default productcateComponent
