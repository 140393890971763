import React, { Component } from 'react'
import { connect } from 'react-redux'
// import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
const { rxnavToggle, rxmenuToggle } = global.rootRequire('redux')


// const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const { apiget } = global.rootRequire('classes/request')
const link_mic_authen = 'https://xacthuc.mic.gov.vn/vnconnect-auth/mic-authenticator.jsp?client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http%3A%2F%2F203.162.121.124%3A8886%2Fauthorize%2FauthorizeSSO&response_type=code&scope=openid&tenantDomain=carbon.super&sessionDataKey=a5cd1324-cd92-4696-aa5b-a3abc196b0c7&relyingParty=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&type=oidc&sp=CUC+BAO+CHI+TEST&isSaaSApp=false&callbackUrl=https%3A%2F%2Fxacthuc.mic.gov.vn%2Fcommonauth%3FsessionDataKey%3Da5cd1324-cd92-4696-aa5b-a3abc196b0c7%26authenticatorName%3DMicAuthenticator&authenticators=VnptAuthenticator:LOCAL'

class manualComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { flagUpdateCat: false, curCat: 0, cate: [],  activeNavs: true }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    this.fetchData()
  }


  componentDidMount () {
  }

  fetchData () {
    apiget(global.rxu.config.api_office, {}, { '1': (json) => { this.setState({ cate: json.data }, () => {}) } })
  }

  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat (e) {
    let paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // toggleNavs (navName) {
  //   let activeNavs = this.state.activeNavs
  //   if (typeof activeNavs[navName] === 'undefined') { activeNavs[navName] = 0 }
  //   activeNavs[navName] = !activeNavs[navName]
  //   this.setState({ activeNavs: activeNavs })
  // }

  //HƯỚNG DẪN ĐĂNG NHẬP
  renderTableBody1 () {
    return (
      <div className="body_manual">
          <div className="manual_content">
            {/*}<h1 className="content_note1">I. ĐĂNG NHẬP TRỰC TIẾP TRÊN HỆ THỐNG QUẢN LÝ THẺ NHÀ BÁO</h1><br/>*/}
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 1:</strong> Truy cập vào link :<strong className="content_note2"> <a href="thenhabao.mic.gov.vn" style= {{color:'#0000b3'}}> <ins>http://thenhabao.mic.gov.vn/</ins></a></strong></p><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 2:</strong> Nhập thông tin đăng nhập (tài khoản, mật khẩu) được cấp phát </p><br/>
              <img className="manual__img" alt="dangnhap01" src="/images/static/dang_nhap_01.jpg" /><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 3:</strong> Bấm  <strong  className="content_note1">“ĐĂNG NHẬP"</strong> </p><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp;- Màn hình đăng nhập thành công </p><br/>
              <img className="manual__img" alt="dangnhap01" src="/images/static/dang_nhap_05.jpg" /><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 4:</strong> Khai hồ sơ cấp thẻ nhà báo </p><br/>
            <h1 className="content_note1">I. ĐĂNG NHẬP THÔNG QUA XÁC THỰC MICCONNECT </h1><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 1:</strong> Truy cập vào link :<strong className="content_note2"> <a href={link_mic_authen} style= {{color:'#0000b3'}}> <ins>Link xác thực MICCONNECT</ins></a></strong></p><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Hoặc:</strong> Bấm  <strong  className="content_note1">“Đăng nhập bằng tài khoản MicConnect"</strong> để chuyển sang trang xác thực tài khoản của MICCONNECT</p><br/>
              <img className="manual__img" alt="dangnhap01" src="/images/static/dang_nhap_01.jpg" /><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 2:</strong> Nhập thông tin đăng nhập (tài khoản, mật khẩu) được cung cấp </p><br/>
              <img className="manual__img" alt="dangnhap02" src="/images/static/dang_nhap_02.jpg" /><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 3:</strong> Nhập OTP để tiến hành đăng nhập vào hệ thống </p><br/>
              <img className="manual__img" alt="dangnhap03" src="/images/static/dang_nhap_03.jpg" /><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp;- Màn hình đăng nhập thành công </p><br/>
              <img className="manual__img" alt="dangnhap04" src="/images/static/dang_nhap_05.jpg" /><br/>
              <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">- Bước 4:</strong> Khai hồ sơ cấp thẻ nhà báo </p><br/>
              {/* <h1 className="content_note1">&ensp;I.&ensp;Chọn cách đăng nhập vào Hệ thống </h1><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp; Có 2 cách đăng nhập vào Hệ thống: </p><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;+ Sử dụng tài khoản đã được cấp của Hệ Thống</p><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;+ Sử dụng tài khoản đã đăng ký của MicConnect</p><br/>
              <img className="manual__img" alt="dangnhap2" src="/images/static/dang_nhap_2.jpg" /><br/> */}
              {/* <h1 className="content_note1">&ensp;&ensp;Đăng nhập bằng tài khoản của Hệ thống </h1><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp;<b><strong className="content_note2">Bước 1.</strong> Truy cập vào trang đăng ký theo link sau: <a href="http://thenhabao.mic.gov.vn/login" style= {{color:'#0000b3'}}> <ins>http://thenhabao.mic.gov.vn/login</ins></a></b></p><br/>
              <p className="col-md-12_manualtxt2">&emsp;&emsp;<b><strong className="content_note2">Bước 2.</strong> Nhập tài khoản và mật khẩu được cung cấp </b></p><br/>
              <img className="manual__img" alt="dangnhap" src="/images/static/dang_nhap.jpg" /><br/> */}
              {/* <h1 className="content_note1">&ensp;&ensp;Đăng nhập bằng tài khoản của MICCONNECT </h1><br/>
              <p className="col-md-12_manualtxt2"><b>&ensp;Có 2 cách để đăng nhập vào Micconnect </b></p><br/>
              <p className="col-md-12_manualtxt2"><b>&emsp;&emsp;1. Truy cập vào trang đăng ký theo link sau: <a href="https://xacthuc.mic.gov.vn/vnconnect-auth/mic-authenticator.jsp?client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http%3A%2F%2F203.162.121.124%3A8886%2Fauthorize%2FauthorizeSSO&response_type=code&scope=openid&tenantDomain=carbon.super&sessionDataKey=5ad07ec2-4ec6-4b26-ae56-db62b45c930c&relyingParty=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&type=oidc&sp=CUC+BAO+CHI+TEST&isSaaSApp=false&callbackUrl=https%3A%2F%2Fxacthuc.mic.gov.vn%2Fcommonauth%3FsessionDataKey%3D5ad07ec2-4ec6-4b26-ae56-db62b45c930c%26authenticatorName%3DMicAuthenticator&authenticators=VnptAuthenticator:LOCAL&authFailure=true&authFailureMsg=T%C3%A0i+kho%E1%BA%A3n+kh%C3%B4ng+c%C3%B3+s%E1%BB%91+%C4%91i%E1%BB%87n+tho%E1%BA%A1i%21&typeRequestFailure=username
  "> <ins>https://xacthuc.mic.gov.vn/vnconnect-auth/mic-authenticator.jsp?client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http%3A%2F%2F203.162.121.124%3A8886%2Fauthorize%2FauthorizeSSO&response_type=code&scope=openid&tenantDomain=carbon.super&sessionDataKey=5ad07ec2-4ec6-4b26-ae56-db62b45c930c&relyingParty=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&type=oidc&sp=CUC+BAO+CHI+TEST&isSaaSApp=false&callbackUrl=https%3A%2F%2Fxacthuc.mic.gov.vn%2Fcommonauth%3FsessionDataKey%3D5ad07ec2-4ec6-4b26-ae56-db62b45c930c%26authenticatorName%3DMicAuthenticator&authenticators=VnptAuthenticator:LOCAL&authFailure=true&authFailureMsg=T%C3%A0i+kho%E1%BA%A3n+kh%C3%B4ng+c%C3%B3+s%E1%BB%91+%C4%91i%E1%BB%87n+tho%E1%BA%A1i%21&typeRequestFailure=username</ins></a></b></p><br/>
              <p className="col-md-12_manualtxt2"><b>&emsp;&emsp;2. Bấm "Đăng nhập bằng tài khoản MicConnect" ở trang đăng nhập vào hệ thống</b></p><br/>
              <img className="manual__img" alt="dangnhap5" src="/images/static/dang_nhap_5.jpg" /><br/>
              <p className="col-md-12_manualtxt2"><b>Nhập <strong  className="content_note1">"Tài khoản" và "Mật khẩu"</strong> đã được cung cấp -> <strong  className="content_note1">"Nhập mã xác thực"</strong> -> bấm <strong  className="content_note1">"Đăng Nhập"</strong> </b></p><br/>
              <img className="manual__img" alt="dangnhap4" src="/images/static/dang_nhap_4.jpg" /><br/> */}
          </div>
      </div>
    )
  }

  //HƯỚNG DẪN ĐĂNG ký
  renderTableBody2 () {
    return (
      <div className="body_manual">
        <div>
          <div className="manual_content">
            {/*}<h1 className="content_note1">ĐĂNG KÝ TÀI KHOẢN</h1><br/>*/}
            <p className="col-md-12_manualtxt2">&emsp;<b>Lựa chọn trình duyệt Google Chrome hoặc Firefox để đăng nhập</b></p><br/>
            {/* <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;- Bước 2: Trên thanh địa chỉ gõ vào địa chỉ hệ thống đã được tạo cho đơn vị. Màn hình đăng nhập hiển thị. Địa chỉ: <strong className="content_note2"> <a href="http://thenhabao.mic.gov.vn/register" style= {{color:'#0000b3'}}> <ins>http://thenhabao.mic.gov.vn/register</ins></a></strong></p><br/> */}
            <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong className="content_note2">- Bước 1:</strong> Chọn mục <strong  className="content_note1">"Đăng ký tài khoản"</strong> hoặc <strong  className="content_note1">"Đăng ký"</strong>sẽ hiện bảng đăng ký thông tin</p><br/>
            <img className="manual__img" alt="dangky01" src="/images/static/dang_ky_01.jpg" /><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(1)</strong>&ensp; Bấm chọn hoặc nhập tên cơ quan báo chí  </p><br/>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<img className="manual__img" alt="dangky02" src="/images/static/dang_ky_02.jpg" /><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(2)</strong>&ensp; Nhập số giấy phép hoạt động báo chí (đối với Đài PTTH nhập một trong hai giấy phép hoạt động)</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(3)</strong>&ensp; Nhập <b>ngày/tháng/năm</b> của giấy phép hoạt động báo chí </p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(4)</strong>&ensp; Nhập địa chỉ của cơ quan báo chí</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(5)</strong>&ensp; Nhập số điện thoại cố định của cơ quan báo chí</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(6)</strong>&ensp; Nhập Email của cơ quan báo chí</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(7)</strong>&ensp; Nhập số điện thoại di động (sẽ nhận được mã OTP nếu chọn đăng nhập bằng cổng Micconnect)</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(8)</strong>&ensp; Nhập họ tên người sử dụng</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;&emsp;<strong  className="content_note1">(9)</strong>&ensp; Bấm vào <strong  className="content_note1">"ĐĂNG KÝ TÀI KHOẢN"</strong> , sau đó hệ thống sẽ hiện thông báo và bấm <strong  className="content_note1">"OK"</strong> sẽ hiện nút <strong  className="content_note1">"IN PHIẾU CUNG CẤP THÔNG TIN"</strong></p><br/>
            <img className="manual__img" alt="dangky04" src="/images/static/dang_ky_04.jpg" /><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;<strong className="content_note2">- Bước 2:</strong> Bấm <strong  className="content_note1">"IN PHIẾU CUNG CẤP THÔNG TIN"</strong>,  lãnh đạo cơ quan báo chí ký xác nhận và đóng dấu gửi về Cục Báo chí (Phiếu này có thể scan, hoặc chụp lại gửi qua Email thenhabao@mic.gov.vn).</p><br/>
            <img className="manual__img" alt="dangky05" src="/images/static/dang_ky_05.jpg" /><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp;<strong className="content_note2">- Bước 3:</strong> Sau khi nhận được phiếu cung cấp thông tin và đối chiếu khớp với thông tin đã khai trên hệ thống, Cục Báo chí sẽ cấp tài khoản và mật khẩu gửi về email của cơ quan báo chí đã cung cấp.</p><br/>
            {/* <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note2">Bước 1.</strong> <b>Mở trình duyệt Google Chrome hoặc Firefox</b></p><br/>
            <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 2.</strong>  Truy cập vào trang đăng ký</b></p><br/>
            <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>Cách 1:</b> Truy cập vào trang đăng ký theo link sau: <strong className="content_note2"> <a href="http://thenhabao.mic.gov.vn/register" style= {{color:'#0000b3'}}> <ins>http://thenhabao.mic.gov.vn/register</ins></a></strong></p><br/>
            <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>Cách 2:</b> Ở trang đăng nhập ta bấm chọn <strong  className="content_note1">"Đăng ký tài khoản"</strong> hoặc <strong  className="content_note1">"Đăng ký"</strong></p><br/>
            <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 3. </strong> Nhập thông tin theo hướng dẫn sau: </b></p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(1)</strong>&ensp; Nhập Tên cơ quan báo chí hiện đang công tác</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(2)</strong>&ensp; Nhập Số giấy phép hoạt động báo chí</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(3)</strong>&ensp; Bấm vào ô này và tiến hành chọn ngày cấp</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(4)</strong>&ensp; Nhập Email do cơ quan báo chí cung cấp </p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(5)</strong>&ensp; Nhập Số Điện Thoại Di Động để hệ thống gửi mã OTP để xác thực thông tin</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(6)</strong>&ensp; Nhập họ tên người sử dụng</p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(7)</strong>&ensp; Bấm vào <strong  className="content_note1">"ĐĂNG KÝ TÀI KHOẢN"</strong> , sau khi bấm sẽ hiện ra thông báo <strong  className="content_note1">(8)</strong> ta bấm  <strong  className="content_note1">"OK"</strong> để kết thúc đăng ký </p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;&emsp;<strong  className="content_note1">(9)</strong>&ensp; Bấm <strong  className="content_note1">"IN PHIẾU CUNG CẤP THÔNG TIN CHO CỤC BÁO CHÍ"</strong></p><br/>
            <img className="manual__img" alt="dangky" src="/images/static/dang_ky.jpg" /><br/>
            <h1 className="content_note1">II. In Phiếu Cung Cấp Thông Tin Cho Báo Chí</h1><br/>
            <p className="col-md-12_manualtxt2"><b> Để in phiếu cung cấp thông tin ta thực hiện theo các bước sau: </b></p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;<strong  className="content_note1">(1)</strong>&ensp; Bấm vào <strong  className="content_note1">"IN PHIẾU CUNG CẤP THÔNG TIN CHO CỤC BÁO CHÍ"</strong> </p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;<strong  className="content_note1">(2)</strong>&ensp; Chọn nơi lưu và bấm  <strong  className="content_note1">"OK" </strong>  để lưu phiếu cung cấp thông tin</p><br/>
            <img className="manual__img" alt="dangky2" src="/images/static/dang_ky_2.jpg" /><br/>
            <p className="col-md-12_manualtxt2"> &emsp;<b> Kết quả phiếu cung cấp thông tin sẽ như hình dưới đây</b></p><br/>
            <p className="col-md-12_manualtxt2"> &emsp;<b> In file này và nộp trực tiếp cho Cục Báo Chí </b></p><br/>
            <img className="manual__img" alt="dangky3" src="/images/static/dang_ky_3.jpg" /><br/>
            <h1 className="content_note1">III. Chuyên viên kiểm duyệt đăng ký</h1><br/>
            <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 1.</strong> Quản trị viên đăng nhập vào hệ thống CMS bằng tài khoản đã được cấp để tiến hành kiểm duyệt các tài khoản đã gửi đăng ký</b></p><br/>
            <img className="manual__img" alt="dangky5" src="/images/static/dang_ky_5.jpg" /><br/>
            <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 2.</strong> Chọn <strong className="content_note1">"Tuỳ chỉnh khác"</strong>  -><strong className="content_note1"> "Cơ quan đăng ký"</strong>  -> Chọn tài khoản muốn duyệt và bấm <strong  className="content_note1">"Edit" </strong> như hình sau:</b></p><br/>
            <img className="manual__img" alt="dangky6" src="/images/static/dang_ky_6.jpg" /><br/>
            <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 3.</strong> Quản trị viên kiểm tra thông tin người dùng có đúng với hồ sơ đã gửi trực tiếp nếu đúng thì Bấm chọn “ĐỒNG BỘ TÀI KHOẢN TRÊN MICCONECT VÀ GỬI THÔNG TIN CHO ĐƠN VỊ” sau khi bấm xong sẽ hiện thông báo đã đồng bộ tài khoản. Kết quả sẽ được gửi về đơn vị thông qua gmail đã đăng ký.</b></p><br/>
            <img className="manual__img" alt="dangky7" src="/images/static/dang_ky_7.jpg" /><br/>
            <p className="col-md-12_manualtxt2"><b>Kết quả sẽ được hiển thị ở danh sách Cơ quan đăng kí</b></p><br/>
            <p className="col-md-12_manualtxt2"><b><strong  className="content_note1">CHÚ Ý: sau khi đã đồng bộ tài khoản thì đơn vị có thể đăng nhập trên MICCONNECT bằng tài khoản đã đăng ký trên hệ thống </strong></b></p><br/>
            <img className="manual__img" alt="dangky8" src="/images/static/dang_ky_8.jpg" /><br/> */}
          </div>
        </div>
      </div>
   )
  }

  // //HƯỚNG DẪN TẠO USER
  // renderTableBody2 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //           <h1 className="content_note1">Tạo User</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<b>Sau khi đăng nhập thành công bằng tài khoản có chức năng tạo User ta thực hiện theo các bước sau: </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp; <b>1. Chọn tag Tuỳ Chỉnh Khác -> Quản lí tài khoản -> Thêm mới</b> </p><br/>
  //           <img className="manual__img" alt="taotaikhoan" src="/images/static/tao_tai_khoan.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>2. Điền thông tin tài khoản mới</b> </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(1)(2)&nbsp;Bấm chọn Cục quản lí và chọn Phân quyền cho tài khoản</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(3)(4)(5)(6)&nbsp;Nhập đầy đủ thông tin theo yêu cầu</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(7)(8)&nbsp;Mật khẩu và Nhập lại mật khẩu phải giống nhau để xác nhận</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(9)&nbsp;Bấm vào nút để kých hoạt tài nếu nó chưa hiện màu xanh</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(10)&nbsp;Bấm vào tag Quyền Cơ Quan Báo Chí để chuyển qua Thiết lập quyền cơ quan cho tài khoản</p><br/>
  //           <img className="manual__img" alt="thongtintaikhoan" src="/images/static/thong_tin_tai_khoan.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp; <b>3. Thiết lập quyền cơ quan cho tài khoản</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp; + Bấm chọn những cơ quan muốn phân quyền cho tài khoản</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp; + Bấm lưu để tạo tài khoản</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp; + Nếu có file excel phân quyền</p><br/>
  //           <img className="manual__img" alt="phanquyen" src="/images/static/phan_quyen.jpg" /><br/><br/>
  //           <p className="content_note2"><strong className="content_note1">CHÚ Ý: (*) Thêm file phân quyền cho tài khoản</strong> </p><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp; + File thêm vào là file excel và phải có định dạng như hình dưới đây</p><br/>
  //           <img className="manual__img" alt="excelphanquyen" src="/images/static/excel_phan_quyen.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;&emsp;&emsp; + Tên của tài khoản đã nhập phải giống với tên của Sheet trong file excel và tên Cơ Quan phải thuộc Cục Quản Lí của cơ quan đó, như hình dưới đây</p><br/>
  //           <img className="manual__img" alt="importfilephanquyen" src="/images/static/import_file_phan_quyen.jpg" /><br/><br/>
  //           <img className="manual__img" alt="sosanh" src="/images/static/so_sanh.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;&emsp;<b>4. Chỉnh sữa thông tin cho tài khoản</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(1)&nbsp;Trạng thái của tài khoản đã kých hoạt hay chưa</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(2)&nbsp;Bấm vào nút để chỉnh sữa thông tin và phân quyền cho tài khoản</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(3)&nbsp;Bấm vào để xoá tài khoản</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;(4)&nbsp;Bấm vào để xuất danh sách Cơ quan trong đợt thẩm định ra file excel</p><br/>
  //           <img className="manual__img" alt="chinhsuataikhoan" src="/images/static/chinh_sua_tai_khoan.jpg" /><br/><br/>
  //         </div>
  //       </div>
  //     </div>
  //  )
  // }

  // //HƯỚNG DẪN TẠO ĐỢT THẨM ĐỊNH
  // renderTableBody3 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //           <h1 className="content_note1">Tạo Đợt Thẩm Định</h1><br/>
  //           <p className="col-md-12_manualtxt2"><b>Sau khi đăng nhập thành công bằng tài khoản có chức năng tạo User ta thực hiện theo các bước sau:</b> </p><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;<b><strong className="content_note2">Bước 1.</strong> Chọn  &emsp; <strong  className="content_note1">Quản Lí Thẻ -> Đợt Thẩm Định -> Thêm mới</strong></b></p><br/>
  //           <img className="manual__img" alt="taothamdinh" src="/images/static/tao_tham_dinh.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;<b><strong className="content_note2">Bước 2.</strong> Điền thông tin đợt thẩm định mới</b> </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong  className="content_note1">(1)</strong>&nbsp;Nhập tên đợt thẩm định</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong  className="content_note1">(2)(3)</strong>&nbsp;Nếu đăng nhập bằng tài khoản <strong  className="content_note1">"Member" </strong>của cơ quan thì sẽ tự động chọn Cơ Quan Báo Chí Và loại Cơ Quan còn nếu sử dụng tài khoản <strong  className="content_note1">"Admin" </strong> hay <strong  className="content_note1">"Chuyên Viên"</strong> thì phải chọn đúng Cơ quan báo chính và Loại cơ quan cho đợt thẩm định muốn tạo  </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong  className="content_note1">(4)</strong>&nbsp; Bấm lưu để tạo đợt thẩm định</p><br/>
  //           <img className="manual__img" alt="thongtindotthamdinh" src="/images/static/thong_tin_dot_tham_dinh.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp; <b><strong className="content_note2">Bước 3.</strong> Chỉnh sữa thông tin cho đợt thẩm định</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong  className="content_note1">(1)</strong>&nbsp;Trạng thái của đợt thẩm định đã kých hoạt hay chưa</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong  className="content_note1">(2)</strong>&nbsp;Bấm vào nút để chỉnh sữa thông tin cho đợt thẩm định</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong  className="content_note1">(3)</strong>&nbsp;Bấm vào để xoá đợt thẩm định</p><br/>
  //           <img className="manual__img" alt="chinhsuathamdinh" src="/images/static/chinh_sua_tham_dinh.jpg" /><br/><br/>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // //HƯỚNG DẪN TẠO HỒ SƠ VÀ TRÌNH HỒ SƠ
  // renderTableBody4 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //           <h1 className="content_note1">Tạo Hồ Sơ Mới</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<b>Sau khi đăng nhập thành công bằng tài khoản có chức năng tạo User ta thực hiện theo các bước sau:</b> </p><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;&emsp;<b><strong className="content_note2">Bước 1.</strong> Chọn tag &emsp;<strong className="content_note1">Hướng dẫn sử dụng -> Quản lí tài khoản -> Thêm mới</strong> </b> </p><br/>
  //           <img className="manual__img" alt="taotaikhoan" src="/images/static/tao_tai_khoan.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp; <b><strong className="content_note2">Bước 2.</strong> Điền thông tin hồ sơ</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(1)</strong>&nbsp;Bấm chọn Loại Hồ Sơ</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(3)(4)</strong>&nbsp;Nếu đăng nhập bằng tài khoản<strong  className="content_note1"> "Member"</strong> thì Tên cơ quan đề nghị cấp thẻ nhà báo và Loại cơ quan sẽ tự động chọn Cơ quan quản lí tài khoản. Ngược lại với <strong  className="content_note1">"admin" và "chuyên viên"</strong> thì phải lựa chọn chính xác tên cơ quan và loại cơ quan</p><br/>
  //           <img className="manual__img" alt="thongtinhoso1" src="/images/static/thong_tin_ho_so_1.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(2)</strong>&nbsp;Bấm chọn Đợt thẩm định</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp; <b><strong className="content_note2">Bước 3.</strong> Điền thông tin cá nhân</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp; + Điền thông tin đầy đủ theo yêu cầu</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp; + Những thông tin có <strong  className="content_note1">(*)</strong> ở đầu là thông tin bắt buộc phải nhập</p><br/>
  //           <p className="content_note2"><strong className="content_note1">CHÚ Ý: (***) Bấm để chọn thông tin</strong></p><br/><br/>
  //           <img className="manual__img" alt="thongtinhoso2" src="/images/static/thong_tin_ho_so_2.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp; <b><strong className="content_note2">Bước 4.</strong> Những thông tin còn lại</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(1)</strong>&nbsp;Nhập đúng thông tin theo từng cột </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&emsp; +<strong  className="content_note1">(!)</strong> Bấm vào <strong  className="content_note1"> " + " </strong> để thêm 1 hàng</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&emsp; +<strong  className="content_note1">(!!)</strong> Bấm vào <strong  className="content_note1">" xoá hàng "</strong>  để xoá hàng được chọn</p><br/>
  //           <img className="manual__img" alt="thongtinhoso2" src="/images/static/thong_tin_ho_so_3.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(2)(3)</strong>&nbsp; Bấm vào và chọn file đúng với yêu cầu để up lên</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(4)</strong>&nbsp; Bấm vào để trình hồ sơ (hồ sơ sẽ nằm trong đợt thẩm định)</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(5)</strong>&nbsp; Bấm vào để lưu hồ sơ và trình sau (hồ sơ chỉ được lưu không nằm trong đợt thẩm định)</p><br/>
  //           <img className="manual__img" alt="uploadanh" src="/images/static/upload_anh.jpg" /><br/>
  //           <p className="col-md-12_manualtxt2"> &emsp;&emsp; <b><strong className="content_note2">Bước 5.</strong> Kết quả sau khi <strong  className="content_note1">"Lưu" hoặc "Trình hồ sơ"</strong> </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;+&nbsp; Trạng thái của hồ sơ chọn <strong  className="content_note1">"Lưu" </strong> và Hồ sơ chọn <strong  className="content_note1"> "Trình hồ sơ"</strong></p><br/>
  //           <img className="manual__img" alt="hosotrinh" src="/images/static/ho_so_trinh.jpg" /><br/><br/>
  //           <img className="manual__img" alt="hosoluu" src="/images/static/ho_so_luu.jpg" /><br/><br/>
  //           <img className="manual__img" alt="ketqua1" src="/images/static/ket_qua_1.jpg" /><br/><br/><br/>
  //           <h1 className="content_note1">&emsp; ***Để trình hồ sơ lên cấp trên ta thực hiện như hình dưới đây </h1><br/>
  //           <img className="manual__img" alt="trinhthamdinh2" src="/images/static/trinh_tham_dinh_2.jpg" /><br/><br/>
  //           <img className="manual__img" alt="ketqua2" src="/images/static/ket_qua_2.jpg" /><br/><br/>
  //           <img className="manual__img" alt="ketqua3" src="/images/static/ket_qua_3.jpg" /><br/><br/>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // //QUÁ TRÌNH THẨM ĐỊNH
  // renderTableBody5 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //           <h1 className="content_note1">I.&ensp;Quá trình thẩm định</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>Đợt thẩm định đã trình thì hồ sơ sẽ trải qua 4 cấp kiểm duyệt như hình sau: </b></p><br/>
  //           <img className="manual__img" alt="timeline1" src="/images/static/time_line_1.jpg" /><br/><br/><br/>
  //           <h1 className="content_note1">II.&ensp;Cấp chuyên viên</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Sử dụng tài khoản có phân quyền phù hợp dùng để duyệt ở cấp này </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Sau khi đăng nhập thì màn hình chính sẽ có hiển thị như hình sau: </b></p><br/>
  //           <img className="manual__img" alt="chuyenvien1" src="/images/static/chuyen_vien_1.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Tiếp theo chuyên viên sẽ tiến hành Duyệt hồ sơ theo các bước sau: </b></p><br/>
  //           <img className="manual__img" alt="chuyenvien1" src="/images/static/chuyen_vien_1.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Chuyên viên kiểm tra từng hồ sơ trong danh sách xem hồ sơ có hợp lệ hay không và tiến hành duyệt </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(1)</strong>&nbsp;Bấm vào <strong  className="content_note1"> "Duyệt tất cả"</strong> để duyệt hết hồ sơ có trong danh sách hoặc có thể chọn từng người trong danh sách để duyệt </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(2)</strong>&nbsp;Bấm <strong  className="content_note1">"OK"</strong> để xác nhận duyệt hồ sơ </p><br/>
  //           <img className="manual__img" alt="chuyenvien2" src="/images/static/chuyen_vien_2.jpg" /><br/><br/><br/>
  //           <img className="manual__img" alt="chuyenvienkiemtra" src="/images/static/chuyen_vien_kiem_tra.jpg" /><br/><br/><br/>
  //           <img className="manual__img" alt="chuyenvien3" src="/images/static/chuyen_vien_3.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Chuyên viên xác nhận cuối cùng để trình hồ sơ lên cấp trên. </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Sau khi trình hồ sơ thì Đợt thẩm định sẽ được chuyển giao lên cấp trên để duyệt tiếp tục</b></p><br/>
  //           <img className="manual__img" alt="chuyenvien4" src="/images/static/chuyen_vien_4.jpg" /><br/><br/><br/>
  //           <h1 className="content_note1">III.&ensp;Cấp Lãnh Đạo Phòng - Cấp Cục</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;===>CÁC BƯỚC THỰC HIỆN TƯƠNG TỰ CHUYÊN VIÊN</p><br/>
  //           <h1 className="content_note1">IV-Cấp Bộ</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Các bước đầu thực hiện tương tự chuyên viên </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>- Ở bước duyệt hồ sơ thì sẽ có 2 trường hợp như sau: </b></p><br/>
  //           <img className="manual__img" alt="lanhdaobo1" src="/images/static/lanh_dao_bo_1.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&nbsp;<b>+ Hồ sơ sau khi được bộ duyệt sẽ trả về tag <strong  className="content_note1">"Trả hồ sơ"</strong></b></p><br/>
  //           <img className="manual__img" alt="lanhdaobo4" src="/images/static/lanh_dao_bo_4.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&nbsp;<b>+ Hồ sơ "ĐƯỢC DUYỆT" sẽ tiến hành <strong  className="content_note1">"IN THẺ" </strong></b> </p><br/>
  //           <img className="manual__img" alt="lanhdaobo2" src="/images/static/lanh_dao_bo_2.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&emsp;&emsp; <b><strong  className="content_note1">(1)</strong> Bấm vào <strong  className="content_note1">" In thẻ "</strong></b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&emsp;&emsp; <b><strong  className="content_note1">(2)</strong> Bấm vào <strong  className="content_note1">" OK "</strong> để xác nhận in thẻ</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;&emsp;&emsp; <b><strong  className="content_note1">(3)</strong> Bấm vào <strong  className="content_note1">" In " </strong> để in thẻ và chọn nơi lưu thẻ</b></p><br/>
  //           <img className="manual__img" alt="lanhdaobo3" src="/images/static/lanh_dao_bo_3.jpg" /><br/><br/><br/>
  //           <h1 className="content_note1">VI.&ensp;Chuyên viên xử lí hồ sơ được trả về</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b><strong className="content_note2">Bước 1.</strong> Đăng nhập bằng tài khoản <strong  className="content_note1"></strong>"chuyên viên" hoặc cơ quan có phân quyền tương tự</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b><strong className="content_note2">Bước 2.</strong> Tiền hành trả hồ sơ về đơn vị theo các bước sau: </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(1)</strong> Bấm vào tag <strong  className="content_note1">"Quản lí hồ sơ"</strong></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(2)</strong> Bấm vào tag <strong  className="content_note1">"Trả kết quả hồ sơ"</strong></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<strong  className="content_note1">(3)</strong> Chọn đợt thẩm định cần trả kết quả hồ sơ</p><br/>
  //           <img className="manual__img" alt="chuyenvientrahs1" src="/images/static/chuyen_vien_tra_hs_1.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<b><strong className="content_note2">Bước 3.</strong> Chuyên viên có thể sữa đổi ghi chú thẩm định cuối cùng và tiến hành trả hồ sơ về cho Đơn vị: </b></p><br/>
  //           <img className="manual__img" alt="chuyenvientrahs2" src="/images/static/chuyen_vien_tra_hs_2.jpg" /><br/><br/><br/>
  //           <img className="manual__img" alt="chuyenvientrahs3" src="/images/static/chuyen_vien_tra_hs_3.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<b>***Sau khi chuyên viên trả hồ sơ đơn vị mới nhận được hồ sơ trả về, để kiểm tra hồ sơ ta thực hiện như sau: </b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<b><strong  className="content_note1">Đăng nhập vào đơn vị -> Chọn "Quản lí thẻ" -> Chọn "Danh sách thẻ"</strong> </b></p><br/>
  //           <img className="manual__img" alt="chuyenvientrahs4" src="/images/static/chuyen_vien_tra_hs_4.jpg" /><br/><br/><br/>
  //           {/* <h1 className="content_note1">VII.&ensp;Hồ sơ không được duyệt</h1><br/>
  //          <p className="col-md-12_manualtxt2">&emsp;&emsp;<b>1. Đăng nhập bằng tài khoản "member" hoặc cơ quan có phân quyền tương tự</b></p><br/>
  //          <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<b>(1) Bấm vào tag "Quản lí thể"</b> </p><br/>
  //          <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<b>(2) Bấm vào tag "Danh sách thẻ"</b> </p><br/>
  //          <p className="col-md-12_manualtxt2">&emsp;&emsp;&emsp;<b>(3) Chọn hồ sơ "Không được duyệt" và tiến hành chỉnh sữa hồ sơ và gửi lại</b> </p><br/> */}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // //HƯỚNG DẪN IMPORT FILE
  // renderTableBody6 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <img className="manual__img" alt="importform" src="/images/static/importform.jpg" />
  //         <div className="manual_content">
  //           <h1 className="content_note1">I.&ensp;Import dữ liệu</h1><br/>
  //           <p className="col-md-12_manualtxt2">&emsp; &emsp;Đối với file đã nhập sẵn mà cơ quan báo chí gửi lên cho bạn cần phải Trải qua 2 bước  </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 1.</strong> KIỂM TRA</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;I.&ensp;1 - Dữ liệu phải được để đúng font unicode không để font vntime  hay TCVN3</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;I.&ensp;2 - Kiểm tra dữ liệu đã đúng chuẩn chỉ chưa đặc biệt ngày tháng năm sinh có đúng định dạng (ngày/tháng/năm) chưa</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;I.&ensp;3 - Kiểm tra dữ liệu đã đúng theo mẫu chuẩn tức phải đủ 22 cột Hoặc 10 cột như trong mục đính kèm  </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note1">File định dạng đúng chuẩn</strong></p><br/>
  //           <img className="manual__img" alt="dinhdangchuan" src="/images/static/dinh_dang_chuan.jpg" /><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note1">File định dạng sai chuẩn được tô màu đỏ</strong></p>
  //           <img className="manual__img" alt="dinhsai" src="/images/static/dinh_sai.jpg" />
  //           <p className="col-md-12_manualtxt2">&emsp;Trong file trên sai ở chỗ ngày tháng định dạng sai : vì định dạng năm sinh phải là : Ngay/Thang/Nam nhưng tháng lại > 12</p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp; nên sai hoặc chỉ có năm sinh nên sai. Còn 1 chỗ sai là thừa 1 cột nên hệ thống không đọc được định dạng dữ liệu</p><br/>
  //           <p className="col-md-12_manualtxt2"><b>CHÚ Ý: Đối với file excel thì ngoài trường hợp đúng đủ 22 cột thì sẽ có 2 trường hợp ngoại lệ như hình sau vẫn import được dữ liệu vào</b></p><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note1">Trường hợp 25 cột</strong></p>
  //           <img className="manual__img" alt="ngoaile" src="/images/static/ngoai_le.jpg" /><br/><br/><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<strong className="content_note1">Trường hợp 10 cột</strong></p>
  //           <img className="manual__img" alt="ngoaile2" src="/images/static/ngoai_le2.jpg" /><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;<b><strong className="content_note2">Bước 2.</strong> ĐỊNH DẠNG LẠI FILE</b></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;Nếu file đó đã đủ chuẩn thì bạn tiến hành -> "Copy dữ liệu" </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong className="content_note1">CHÚ Ý :</strong>Đối với file excel hoặc word + bạn tạo một file mới tinh rồi "CTRL+A" rồi copy nội dung file đó sang một file mới </p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;<strong className="content_note1">TẤT CẢ FILE WORD HOẶC EXCEL LƯU VỀ CHẾ ĐỘ CỦA WORD,EXCEL 2007</strong></p><br/>
  //           <p className="col-md-12_manualtxt2">&emsp;&emsp;Để upload được file đó đọc được trên hệ thống thì bắt buộc bạn phải chọn  <strong className="content_note1">cơ quan báo chí ở bên cạnh</strong>.</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // renderTableBody7 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //             {/* <h1 className="content_note1">&ensp;KHÔI PHỤC MẬT KHẨU</h1><br/>
  //             <p className="col-md-12_manualtxt2">&emsp; &emsp;Để lấy lại mật khẩu đăng nhập khi ta quên mật khẩu, ta thực hiện theo hình dưới đây: </p><br/>
  //             <img className="manual__img" alt="quenmatkhau" src="/images/static/quen_mat_khau.jpg" /><br/> */}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  // //ĐĂNG KÝ BẰNG MICCONNECT
  // renderTableBody8 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //         <img className="manual__img" alt="dangkymicconnect1" src="/images/static/dang_ky_micconnect_1.jpg" />
  //         <img className="manual__img" alt="dangkymicconnect2" src="/images/static/dang_ky_micconnect_2.jpg" />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

//   //ĐĂNG NHẬP BẰNG MICCONNECT
//   renderTableBody9 () {
//     return (
//       <div className="body_manual">
//         <div>
//           <div className="manual_content">
//           <h1 className="content_note1">&ensp;&ensp;Đăng nhập bằng tài khoản của MICCONNECT </h1><br/>
//             <p className="col-md-12_manualtxt2"><b>&ensp;Có 2 cách để đăng nhập vào Micconnect </b></p><br/>
//             <p className="col-md-12_manualtxt2"><b>&emsp;&emsp;1. Truy cập vào trang đăng ký theo link sau: <a href="https://xacthuc.mic.gov.vn/vnconnect-auth/mic-authenticator.jsp?client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http%3A%2F%2F203.162.121.124%3A8886%2Fauthorize%2FauthorizeSSO&response_type=code&scope=openid&tenantDomain=carbon.super&sessionDataKey=5ad07ec2-4ec6-4b26-ae56-db62b45c930c&relyingParty=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&type=oidc&sp=CUC+BAO+CHI+TEST&isSaaSApp=false&callbackUrl=https%3A%2F%2Fxacthuc.mic.gov.vn%2Fcommonauth%3FsessionDataKey%3D5ad07ec2-4ec6-4b26-ae56-db62b45c930c%26authenticatorName%3DMicAuthenticator&authenticators=VnptAuthenticator:LOCAL&authFailure=true&authFailureMsg=T%C3%A0i+kho%E1%BA%A3n+kh%C3%B4ng+c%C3%B3+s%E1%BB%91+%C4%91i%E1%BB%87n+tho%E1%BA%A1i%21&typeRequestFailure=username
// " style= {{color:'#0000b3'}}> <ins>https://xacthuc.mic.gov.vn/vnconnect-auth/mic-authenticator.jsp?client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http%3A%2F%2F203.162.121.124%3A8886%2Fauthorize%2FauthorizeSSO&response_type=code&scope=openid&tenantDomain=carbon.super&sessionDataKey=5ad07ec2-4ec6-4b26-ae56-db62b45c930c&relyingParty=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&type=oidc&sp=CUC+BAO+CHI+TEST&isSaaSApp=false&callbackUrl=https%3A%2F%2Fxacthuc.mic.gov.vn%2Fcommonauth%3FsessionDataKey%3D5ad07ec2-4ec6-4b26-ae56-db62b45c930c%26authenticatorName%3DMicAuthenticator&authenticators=VnptAuthenticator:LOCAL&authFailure=true&authFailureMsg=T%C3%A0i+kho%E1%BA%A3n+kh%C3%B4ng+c%C3%B3+s%E1%BB%91+%C4%91i%E1%BB%87n+tho%E1%BA%A1i%21&typeRequestFailure=username</ins></a></b></p><br/>
//              <p className="col-md-12_manualtxt2"><b>&emsp;&emsp;2. Bấm "Đăng nhập bằng tài khoản MicConnect" ở trang đăng nhập vào hệ thống</b></p><br/>
//              <img className="manual__img" alt="dangnhap5" src="/images/static/dang_nhap_5.jpg" /><br/>
//              <p className="col-md-12_manualtxt2"><b>Sau khi vào trang đăng nhập của MICCONNECT ta làm theo hướng dẫn sau </b></p><br/>
//           <img className="manual__img" alt="dangnhapmicconnect" src="/images/static/dang_nhap_micconnect.jpg" />
//           </div>
//         </div>
//       </div>
//     )
//   }

  //  //KHÔI PHỤC MẬT KHẨU BẰNG MICCONECT
  //  renderTableBody10 () {
  //   return (
  //     <div className="body_manual">
  //       <div>
  //         <div className="manual_content">
  //         <img className="manual__img" alt="fixloimicconnect" src="/images/static/fix_loi_micconnect.jpg" />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  // R E N D E R S
  render () {
    // const items = [
    //   {
    //     uuid: 1,
    //     heading: "Hướng dẫn Đăng Ký",
    //     content: this.renderTableBody2()
    //   },
    //   {
    //     uuid: 2,
    //     heading: "Hướng dẫn Đăng Nhập",
    //     content: this.renderTableBody1()
    //   },
    //   {
    //     uuid: 3,
    //     heading: "Hướng dẫn Khôi Phục Mật Khẩu",
    //     content: this.renderTableBody7()
    //   },
    //   {
    //     uuid: 4,
    //     heading: "Hướng dẫn tạo Đợt Thẩm Định",
    //     content: this.renderTableBody3()
    //   },
    //   {
    //     uuid: 5,
    //     heading: "Hướng dẫn tạo Hồ Sơ và Trình Hồ Sơ",
    //     content: this.renderTableBody4()
    //   },
    //   {
    //     uuid: 6,
    //     heading: "Quá Trình Thẩm Định",
    //     content: this.renderTableBody5()
    //   },
    //   {
    //     uuid: 7,
    //     heading: "Hướng dẫn Import file",
    //     content: this.renderTableBody6()
    //   }
    // ]

    return (

      <AdminBlock>
        {<div className={this.props.rxnav.menu['help_register_system'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_register_system')}>
              <span>HƯỚNG DẪN ĐĂNG KÝ TÀI KHOẢN</span></div>}
        {this.props.rxnav.menu['help_register_system'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_register_system')} /> &&
        <div className={this.props.rxnav.menu['help_register_system'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody2()}
          </div>
        </div>}


        {<div className={this.props.rxnav.menu['help_login_system'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_login_system')}>
              <span>HƯỚNG DẪN ĐĂNG NHẬP </span></div>}
        {this.props.rxnav.menu['help_login_system'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_login_system')} /> &&
        <div className={this.props.rxnav.menu['help_login_system'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody1()}
          </div>
        </div>}

        {/* <div className={this.props.rxnav.navs['help_login_system'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_login_system')}><span>Hướng Dẫn Đăng Nhập Trên Hệ Thống</span></div>
        {this.props.rxnav.navs['help_login_system'] &&
          <div className='rxcol-100'>
            {this.renderTableBody1()}
          </div>} */}

        {/* {<div className={this.props.rxnav.navs['help_password_recovery'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_password_recovery')}>
              <span>Hướng Dẫn Khôi Phục Mật Khẩu Hệ Thống</span></div>}
        {this.props.rxnav.navs['help_password_recovery'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_password_recovery')} /> &&
        <div className={this.props.rxnav.navs['help_password_recovery'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody7()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_password_recovery'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_password_recovery')}><span>Hướng Dẫn Khôi Phục Mật Khẩu Hệ Thống</span></div>
        {this.props.rxnav.navs['help_password_recovery'] &&
          <div className='rxcol-100'>
            {this.renderTableBody7()}
        </div>} */}

        {/* {<div className={this.props.rxnav.navs['help_register_micconnect'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_register_micconnect')}>
              <span>Hướng Dẫn Đăng Kí Bằng MicConnet</span></div>}
        {this.props.rxnav.navs['help_register_micconnect'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_register_micconnect')} /> &&
        <div className={this.props.rxnav.navs['help_register_micconnect'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody8()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_register_micconnect'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_register_micconnect')}><span>Hướng Dẫn Đăng Kí Bằng MicConnet</span></div>
        {this.props.rxnav.navs['help_register_micconnect'] &&
          <div className='rxcol-100'>
            {this.renderTableBody8()}
        </div>} */}

        {/* {<div className={this.props.rxnav.navs['help_login_micconnect'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_login_micconnect')}>
              <span>Hướng Dẫn Đăng Nhập Bằng MicConnect</span></div>}
        {this.props.rxnav.navs['help_login_micconnect'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_login_micconnect')} /> &&
        <div className={this.props.rxnav.navs['help_login_micconnect'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody9()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_login_micconnect'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_login_micconnect')}><span>Hướng Dẫn Đăng Nhập Bằng MicConnect</span></div>
        {this.props.rxnav.navs['help_login_micconnect'] &&
          <div className='rxcol-100'>
            {this.renderTableBody9()}
        </div>} */}

        {/* {<div className={this.props.rxnav.navs['help_recovery_micconnect'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_recovery_micconnect')}>
              <span>Hướng Dẫn Đăng Nhập Bằng MicConnect</span></div>}
        {this.props.rxnav.navs['help_recovery_micconnect'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_recovery_micconnect')} /> &&
        <div className={this.props.rxnav.navs['help_recovery_micconnect'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody10()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_recovery_micconnect'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_recovery_micconnect')}><span>Hướng Dẫn Fix Lỗi MicConnect </span></div>
        {this.props.rxnav.navs['help_recovery_micconnect'] &&
          <div className='rxcol-100'>
            {this.renderTableBody10()}
        </div>} */}

        {/* {<div className={this.props.rxnav.navs['help_evaluation_phase'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_evaluation_phase')}>
              <span>Hướng Dẫn Tạo Đợt Thẩm Định</span></div>}
        {this.props.rxnav.navs['help_evaluation_phase'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_evaluation_phase')} /> &&
        <div className={this.props.rxnav.navs['help_evaluation_phase'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody3()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_evaluation_phase'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_evaluation_phase')}><span>Hướng Dẫn Tạo Đợt Thẩm Định</span></div>
        {this.props.rxnav.navs['help_evaluation_phase'] &&
          <div className='rxcol-100'>
            {this.renderTableBody3()}
        </div>} */}

       {/* {<div className={this.props.rxnav.navs['help_create_profile'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_create_profile')}>
              <span>Hướng Dẫn Tạo Hồ Sơ và Trình Hồ Sơ</span></div>}
        {this.props.rxnav.navs['help_create_profile'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_create_profile')} /> &&
        <div className={this.props.rxnav.navs['help_create_profile'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody4()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_create_profile'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_create_profile')}><span>Hướng Dẫn Tạo Hồ Sơ và Trình Hồ Sơ</span></div>
        {this.props.rxnav.navs['help_create_profile'] &&
          <div className='rxcol-100'>
            {this.renderTableBody4()}
        </div>} */}
{/*
        {<div className={this.props.rxnav.navs['help_appraisal_process'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_appraisal_process')}>
              <span>Quá Trình Thẩm Định</span></div>}
        {this.props.rxnav.navs['help_appraisal_process'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_appraisal_process')} /> &&
        <div className={this.props.rxnav.navs['help_appraisal_process'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody5()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_appraisal_process'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_appraisal_process')}><span>Quá Trình Thẩm Định</span></div>
        {this.props.rxnav.navs['help_appraisal_process'] &&
          <div className='rxcol-100'>
            {this.renderTableBody5()}
        </div>} */}
{/*
        {<div className={this.props.rxnav.navs['help_import'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'}
              onClick={ele => this.props.rxmenuToggle('help_import')}>
              <span>Hướng Dẫn Import File</span></div>}
        {this.props.rxnav.navs['help_import'] && <div className='rx-fixed' onClick={ele => this.props.rxmenuToggle('help_import')} /> &&
        <div className={this.props.rxnav.navs['help_import'] ? 'adnav__groupname2 rxactive' : 'adnav__groupname2'}>
          <div className='rxcol-100'>
            {this.renderTableBody6()}
          </div>
        </div>} */}

        {/* <div className={this.props.rxnav.navs['help_import'] ? 'adnav__groupname2 expand iconcustom-up' : 'adnav__groupname2 iconcustom-down'} onClick={ele => this.props.rxnavToggle('help_import')}><span>Hướng Dẫn Import File</span></div>
        {this.props.rxnav.navs['help_import'] &&
          <div className='rxcol-100'>
            {this.renderTableBody6()}
        </div>} */}
      </AdminBlock>
   )}
}

const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav
})

const mapDispatchToProps = {
  rxnavToggle,
  rxmenuToggle
}

const manualComponent_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(manualComponent)


export default manualComponent_
