/* global t */
import React from 'react'
// import 'react-datepicker/dist/react-datepicker.css'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrudSta').default
const QRCode = require('qrcode.react')
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
// const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default

export const renderTableHead = (context, vm) =>
  <tr className='rx-title__table'>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>{t('Name')}</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>{t('Created at')}</th>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 && <th className='rxwidth--100'>{t('Action')}</th>}
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td onClick={(e) => context.onClickViewTable(e, perdata, 'a')} className='rx-cursor'>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {['viewer'].indexOf(global.rxu.get(context.props.auth, ['user', 'user', 'role'], '')) === -1 &&  context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderTableHead_View = (inthis, vm) =>
  <tr className="rx-title__table">
    <th>
      <span id="HoSoChoIn" style={{ marginLeft: '15px', width: '40px' }}>
        <input onClick={(e) => vm.checkAll(e)} type='checkbox' className='css-checkbox' id='HoSoID-All' />
        <label className='css-label' htmlFor='HoSoID-All' data-toggle='tooltip' data-placement='auto' title='' data-original-title='Tất cả'></label>
      </span>
    </th>
    <th className='rxwidth--100'>STT</th>
    <th className='rxwidth--100 table--name_office' onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')}>Tên CQ báo chí đề nghị cấp thẻ</th>
    <th className='rxwidth--100 table--name_office' onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')}>Tên đơn vị công tác (Áp dụng với trường hợp công tác tại Đài TT - TH cấp huyện)</th>
    <th className='rxwidth--100 table--name_office' onClick={(e) => inthis.onClickSort(e, 'HoTenKhaiSinh')}>Họ và tên</th>
    <th className='rxwidth--100 table--name_office'>Quá trình công tác</th>
    <th className='rxwidth--100'>Số thẻ</th>
    <th className='rxwidth--100'>Ngày cấp</th>
    <th className='rxwidth--100'>Thẻ trích xuất</th>
    <th className='rxwidth--100'>Tình trạng thẻ</th>
    <th className='rxwidth--100'>Số thẻ CCCD/CMND</th>
    <th className='rxwidth--100'>Số thẻ cũ</th>
    <th className='rxwidth--100'>QRcode phục vụ kiểm tra số liệu</th>
  </tr>

export const renderTableBody_View = (inthis, vm) =>
  global.rxu.array(inthis.state.position).map((perdata, index) => (<tr className='rx-xursor' key={perdata._id} onClick={(e) => inthis.onClickDataEdit(e, perdata)}>
    <td className='text-center'>{index + 1}</td>
    <td>{perdata.TenCoQuanBaoChi}</td>
    <td className='text-center'>{perdata.TenCoQuanBaoChi}</td>
    <td className='text-center rx-cursor textuppercase'>{perdata.HoTenKhaiSinh}</td>
    <td>
      {
        global.rxu.array(perdata.QuaTrinhHoatDongUpdate).map((hoatDong, index) => (
          hoatDong.time ? <p key={index}>{index + 1}) {hoatDong.time} công tác tại {hoatDong.CoQuanBaoChi} </p> : ''
        ))
      }
    </td>
    <td className='text-center'>{perdata.codeGen}</td>
    <td className='text-center'></td>
    <td className='text-center'><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td className='text-center'></td>
    <td className='text-center'>{perdata.CMND}</td>
    <td className='text-center'>{perdata.MaTheCu}</td>
    <td className='text-center'><QRCode id={perdata.codeGen || '1'} value={perdata.codeGen || '1'} size={80} level={"H"} includeMargin={true} /></td>
  </tr>))

export const renderExpandComponent = (inthis, vm) => {
  // let filterFields = rxu.get(inthis.state, ['temp', 'filterFields'])
  // let filterEles = []
  // for (let i in filterFields) {
  //
  //   filterEles.push(
  //     <div className='col-md-3' key={i}>
  //       <span className="news_inputcheck_radio">
  //           <input defaultChecked={i === 'Avatar' ? true : false} type="checkbox" className="css-checkboxShow" dataid={i} id="" value={i} onClick={(e) => inthis.checkObject(e)} />
  //       </span>{i}
  //     </div>)
  // }
  // return <div className='rxfull'>
  //   {filterEles}
  // </div>
}

export const renderComponent = (vm) =>
  <AdminBlock name='DANH SÁCH BIỂU MẪU THỐNG KÊ' desc=''>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
      <RxCrud renderTableHead1={vm.renderTableHead} renderTableBody1={vm.renderTableBody} renderTableHead_View1={vm.renderTableHead_View} renderTableBody_View={vm.renderTableBody_View} temp={vm.state.temp} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} name='Analytics' api={'api_analystics'} idEdit={vm.state.idEdit} type={vm.state.type}
        form={[
          { name: 'Tên biểu mẫu', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.name} onChange={(e) => context.onBlurData(e, 'name')} className='fullwidth-input' />) },

          { type: 'devide' },
          { name: 'Mô tả', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.desc} onChange={(e) => context.onBlurData(e, 'desc')} className='fullwidth-input' />) },

          { type: 'devide' },
          { type: 'checkbox', name: 'Chọn trường hiển thị', func: (context) => (vm.renderExpandComponent(context)) },
        ]}
      />
    </div>
  </AdminBlock>
