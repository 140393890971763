/* global rxu */
import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']
const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Cấp đổi theo kỳ hạn', 'Cấp mới', 'Cấp đổi do chuyển cơ quan', 'Cấp lại'] //Hồ sơ cấp theo kỳ hạn mới

export const renderTableHead = (inthis, vm) =>
  <tr className="rx-title__table">
    <th>
        <span id="HoSoChoIn" style={{marginLeft: '15px', width: '40px'}}>
            <input onClick={(e)=> inthis.checkAllPrint(e)}  type='checkbox' className='css-checkbox' id='HoSoID-All' />
            <label className='css-label' htmlFor='HoSoID-All' data-toggle='tooltip' data-placement='auto' title='' data-original-title='Tất cả'></label>
        </span>
    </th>
    <th className='rxwidth--100 text-center'>STT</th>
    <th className={inthis.helpSortClass('HoTenKhaiSinh', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'HoTenKhaiSinh')} >Họ và tên</th>
    <th className={inthis.helpSortClass('code', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'code')} >Mã số hồ sơ</th>
    <th className={inthis.helpSortClass('TenCoQuanBaoChi', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')} >Thuộc cơ quan</th>
    <th className={inthis.helpSortClass('IsStatus', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'IsStatus')}>Tình trạng</th>
    <th className={inthis.helpSortClass('MaSoThe', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'MaSoThe')} >Mã số thẻ nhà báo</th>
    <th className={inthis.helpSortClass('MaSoThe', ['rxwidth--100 table--name_img'])} onClick={(e) => inthis.onClickSort(e, 'MaSoThe')} >Ảnh trích xuất</th>
    <th className={inthis.helpSortClass('ButDanh', ['rxwidth--100 table--name_small'])} onClick={(e) => inthis.onClickSort(e, 'ButDanh')} >Bút danh</th>
    <th className='rxwidth--100 table--name_small' onClick={(e) => inthis.onClickSort(e, 'TenChucVu')}>Chức vụ</th>
    <th className='rxwidth--100 table--name_small'>Số thẻ cũ</th>
    <th className={inthis.helpSortClass('Type', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'Type')}>Phân loại</th>
    <th className={inthis.helpSortClass('NgayBatDauThe', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày cấp</th>
    <th className={inthis.helpSortClass('NgayKetThucThe', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Ngày hết hạn</th>
    <th className={inthis.helpSortClass('created_at', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>
    <th className={inthis.helpSortClass('updated_at', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Ngày cập nhật</th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map((perdata,index) => (<tr key={perdata._id} >
    <td>
        <span className="news_inputcheck_radio" style={{marginLeft: '15px'}}>
            <input type="checkbox" className="css-checkboxShow" dataid={perdata._id} id="" value={JSON.stringify(perdata)} onClick={(e) => inthis.checkObjectPrint(e, perdata)} />
        </span>
    </td>
    <td className='text-center'>{index + 1}</td>
    <td className='rx-cursor'>
        <span><img className='betable__img card__image' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></span>
        <span className="card__name textuppercase">{perdata.HoTenKhaiSinh}</span>
    </td>
    <td>{perdata.code}</td>
    <td>{perdata.TenCoQuanBaoChi}</td>

    {['viewer', 'member'].indexOf(global.rxu.get(inthis.props.auth, ['user', 'user', 'role'], '')) === -1 ?
      <td><span className={'betable__status betable__status_' +perdata.IsStatus}>{perdata.IsStatus === 0 ? (perdata.IsUpdateProfile === 0 ?  statusTinhtrang[perdata.IsStatus] : 'Hoàn thành') : statusTinhtrang[perdata.IsStatus]}</span></td>
    : <td><span className={'betable__status betable__status_' +perdata.IsStatus}>{perdata.IsUpdateProfile === 0 ?  statusTinhtrang[perdata.IsStatus] : 'Hoàn thành'}</span></td>
    }
    <td>{perdata.MaSoThe}</td>
    {/*<td className='rx-cursor'><img className='betable__img__ card__image' alt={perdata.name} src={global.rxu.config.base_api + '/upload/card/' + (perdata.img_card || 'ico_app_default.jpg')} /></td>*/}
    <td className='text-center img_card'><RxUploadCMND images={perdata.img_card} title='' single={1} disabled={1} type='img_card'/></td>
    <td>{perdata.ButDanh}</td>
    <td>{perdata.TenChucVu}</td>
    <td>{perdata.MaTheCu}</td>
    <td>{statusPhanloai[perdata.StatusPhanLoai]}</td>
    <td>{rxu.getDate(perdata['NgayBatDauThe'])}</td>
    <td>{rxu.getDate(perdata['NgayKetThucThe'])}</td>
    <td>{rxu.getDate(perdata['created_at'])}</td>
    <td>{rxu.getDate(perdata['updated_at'])}</td>

  </tr>))

export const renderBtnPrint = (inthis, vm) =>
  <div className='adform__btns rx-stickybot clearfix'>
    <div className='button_right'>
      <span className='adform__btn--save' onClick={(e) => inthis.onClickDataPrintAll(e, vm.state.idDataEdit, 'save')}>Lưu mẫu thẻ</span>
      <span className='adform__btn--save' onClick={(e) => inthis.onClickDataPrintAll(e, vm.state.idDataEdit, 'print')}>In thẻ</span>
    </div>
  </div>

export const renderComponent = (vm) =>
  <AdminBlock name='DANH SÁCH TỔNG HỢP IN THẺ' desc='Hồ sơ chờ in thẻ'>
    <div className='rxcol-100'>
      <RxCrudCard title='Hồ sơ chờ in thẻ' renderBtnPrint={vm.renderBtnPrint} renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='print' api={'api_card_printlist'} idEdit={vm.state.idEdit}
      />
    </div>

  </AdminBlock>
