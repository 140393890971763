/* global t */
import React from 'react'
import CKEditor from 'react-ckeditor-component'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrudCard').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderTableHead = (context, vm) =>
  <tr>
    <th className='rxwidth--100'>{t('Pic')}</th>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>{t('Name')}</th>
    <th className={context.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => context.onClickSort(e, 'created_at')}>{t('Created at')}</th>
    <th className='rxwidth--100'>{t('Action')}</th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='HỒ SƠ KẾT NỐI CỔNG BỘ' desc=''>
    <div className='rxcol-100'>
      <RxCrud renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name={'extenal'} api={'api_card'} form={[
        { name: 'Hình ảnh', func: (context) => (<RxUpload callback={(e) => context.callbackUpload(e)} images={context.state.editingData.img_landscape} />) },

        { type: 'devide' },
        { name: 'Tên', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.name} onChange={(e) => context.onBlurData(e, 'name')} className='fullwidth-input' />) },

        { type: 'devide' },
        { name: 'Đánh giá', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.rating} onChange={(e) => context.onBlurData(e, 'rating')} className='fullwidth-input' />) },

        { type: 'devide' },
        { type: 'fullinput', name: 'Nội dung', func: (context) => (<CKEditor activeClass='p10' content={context.state.editingData.content} events={{ 'change': (evt) => { let editingData = context.state.editingData; let newContent = evt.editor.getData(); editingData.content = newContent; context.setState({ editingData: editingData }) } }} />) },
        // [[RX_WEB_DATA_CUSTOM]] //
        // [[RX_WEB_DATA_CUSTOM_END]] //

        { type: 'devide' },
        { name: 'Hot', func: (context) => (<RxToggle value={context.state.editingData.is_hot} onToggle={(newValue) => context.onBlurDataValue(newValue, 'is_hot')} />) },
        { type: 'devide' },
        { name: 'Trạng thái', func: (context) => (<RxToggle value={context.state.editingData.is_active} onToggle={(newValue) => context.onBlurDataValue(newValue, 'is_active')} />) }
      ]} />
    </div>
  </AdminBlock>
