import * as Jsx from './office.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget } = global.rootRequire('classes/request')

class officeComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = { flagUpdateCat: false, curCat: 0, cate: [] }
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    // this.fetchData()
  }

  componentDidMount () {
  }

  fetchData () {
    apiget(global.rxu.config.api_office, {}, { '1': (json) => { this.setState({ cate: json.data }, () => {}) } })
  }

  onClickCatItem (e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat (e) {
    let paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  render () { return this.renderComponent() }
}

export default officeComponent
