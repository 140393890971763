/* global t, rxu */
import React from 'react'
import CKEditor from 'react-ckeditor-component'
import DatePicker, { registerLocale } from "react-datepicker"
import el from "date-fns/locale/vi"; // the locale you want
// import Select from 'react-select';

global.isclient && require('jsoneditor/dist/jsoneditor.css')
let JSONEditor = global.isclient ? require('jsoneditor') : {}

const RxUpload = global.rootRequire('components/shares/rxUpload').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const RxPrintCard = global.rootRequire('components/shares/rxPrintCard').default
const RxshowPopup = global.rootRequire('components/shares/rxshowPopup').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

const QRCode = require('qrcode.react')
const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Cấp đổi theo kỳ hạn mới', 'Cấp mới', 'Cấp đổi do chuyển cơ quan', 'Cấp lại'] //Hồ sơ cấp theo kỳ hạn mới
const bankhai = ['', 'cấp mới','cấp lại', 'cấp đổi', 'Cấp đổi theo kỳ hạn mới', 'Cấp mới', 'Cấp đổi do chuyển cơ quan', 'Cấp lại']//,'cấp theo kỳ hạn mới'

// let widthWindow = (typeof window === 'undefined') ? 0 : window.innerWidth

// T A B L E
export const renderTable = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead')}</thead><tbody>{vm.run('renderTableBody')}</tbody></MainTable>

export const renderTableHead = (vm) =>
  <tr>
    <th className='rxwidth--100'>Pic</th>
    <th className={vm.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => vm.onClickSort(e, 'name')}>Name</th>
    <th className={vm.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => vm.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (vm) => global.rxu.array(vm.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {vm.renderTableBodyBtns(vm, perdata)}
  </tr>)

export const renderTableBodyBtns = (context, perdata, vm) => {
  let role__ = rxu.get(vm.props.auth, ['user', 'user', 'role']).toLowerCase()

  let regexEdit = /\d+$/
  let check = regexEdit.exec(perdata?.phase)

  return <td className='rxsticky'>
    { context.state.paging.search_is_deleted !== 1 &&
    <div style={{float: 'right'}}> {(perdata.is_hot) ? <span className='betable__icohot'><span className='icon-fire' />Hot</span> :
      <span className='betable__iconothot' />}
      {(['super'].indexOf(role__) !== -1) ?
      <span className='betable__icoon'><RxToggle value={perdata.is_active} onToggle={(newValue) => vm.onClickDataForceUpdate(newValue,perdata)} />{perdata.is_active == 1 ? 'On' : 'Off'}</span> :
      <span className='betable__icoon'>{perdata.is_active == 1 ? 'On' : 'Off'}</span>}
      {(['viewer','superedit'].indexOf(role__) === -1 && (check[0] != 1 || perdata.IsUpdateProfile == 0) || role__ === 'super' ) &&
        <span className='betable__btnedit' onClick={(e) => context.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
      }

      {/* an lanh dao cuc duyet 15/09/22 */}  
      {/* {(['viewer'].indexOf(role__) === -1 || role__ === 'super' )  &&
        <span className='betable__btnedit' onClick={(e) => context.onClickDataEditUpload(e, perdata)}><i className='icon-plus' /></span> } */}
      {/* <span className='betable__btndelete' onClick={(e) => context.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>  */}
    </div> }
    { context.state.paging.search_is_deleted === 1 &&
    <div> <span className='betable__restore' onClick={(e) => context.onClickDataRestore(e, perdata)}>Restore</span></div>}
  </td>
}

// P A G I N
export const renderPagin = (vm) => {
  let result = []
  let paging = vm.state.paging
  let listData = global.rxu.get(vm.state.extra, 'count', paging.pg_size)
  let total = 0
  if ((listData / paging.pg_size) > parseInt(listData / paging.pg_size,10)) {
    total = parseInt(listData / paging.pg_size,10) + 1
  } else {
    total = parseInt(listData / paging.pg_size,10)
  }
  if (paging.pg_page === 1) {
  } else {
    result.push(<div key={'first'} className='rxpage adtable__paginback adtable__paginfirst icomoon-Start' onClick={(e) => vm.onclickFirst(e)}></div>)
    result.push(<div key={'back'} className='rxpage adtable__paginback adtable__paginnext__active' onClick={(e) => vm.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>)
  }
  if (total > 5) {
    if (paging.pg_page === 1) {
      for (let i = 1; i <= (total); i++) {
        if (i < 4) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === 4) {
          result.push(<div key={i} className='adtable__pagingMore' >...</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
        }
      }
    } else {
      for (let i = 1; i <= (total); i++) {
        if (i === paging.pg_page - 1) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        } else if ((i === paging.pg_page) || (i === paging.pg_page + 1)) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === paging.pg_page + 2) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        }
      }
    }
  } else if (total === 0) {
    result.push(<div key={'none'} className='rxpage'>{paging.pg_page}</div>)
  } else {
    for (let i = 1; i <= (total); i++) {
      result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
    }
  }
  if (paging.pg_page < total) {
    result.push(<div key={'next'} className='rxpage adtable__paginnext adtable__paginnext__active' onClick={(e) => vm.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>)
    result.push(<div key={'last'} className='rxpage adtable__paginnext adtable__paginlast icomoon-End' onClick={(e) => vm.onclickLast(e)}></div>)
  }
  let start = (Number(paging.pg_page) - 1)*Number(paging.pg_size) + 1
  let end = Number(paging.pg_page) * Number(paging.pg_size)
  if(end > global.rxu.get(vm.state.extra, 'count', 0)) { end = global.rxu.get(vm.state.extra, 'count', 0) }

  return (<div className='rxpagingwrap'>
    <div className='rxpagingcount'>{start} - {end} trong tổng số <b>{global.rxu.get(vm.state.extra, 'count', 0)}</b></div>
    <div className='rxpaging'>
      <div className='rxpagesize'>
        <span>{t('Pagesize')}</span>
        <select value={paging.pg_size} onChange={e => vm.onChangePageSize(e)}>
          {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
        </select>
      </div>
      {result}
    </div>
  </div>)
}

// F I L T E R
export const renderFilter = (vm) => {
  let role__ = rxu.get(vm.props.auth, ['user', 'user', 'role']) // global.rxu.get(vm.user, 'user.user.role', 'member')
  let index = ['viewer'].indexOf(role__)
  return <div>
    <div className='betable__filter'>
      <div className="rx--box__filter">
        { (vm.props.name !== 'print' && vm.props.name.indexOf('review') === -1) &&
          <div className='betable__btns'>

            {index === -1 && vm.props.name === 'Card' && vm.props.type && <span className='betable__btnadd btn' onClick={(e) => vm.onClickBtnNew(e)}><i className='iconcustom-create betable__addbtn' />Thêm mới</span>}
            {/* {index === -1 && vm.props.name === 'Card' && vm.props.type && <span className='betable__btnadd btn' onClick={(e) => vm.onClickDataNew(e)}><i className='iconcustom-create betable__addbtn' />Thêm mới</span>} */}
            {/*index === -1 && vm.props.name === 'Card' && vm.props.type && <span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExport6(e)}><i className='iconcustom-export' />Tải file mẫu</span>}
            {/*}<span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExport(e)}><i className='iconcustom-export2' />Xuất file 1</span>
            {index === -1 && vm.props.name === 'Card' && <span className='betable__btnexport btn' onClick={(e) => vm.onClickExcelUpload('importExcel')}><i className='iconcustom-import' />{t('Import dữ liệu')}</span>}
            {index === -1 && vm.flag('importExcel') &&
              <span className='adtable__exportcsv tooltip'>
                <input onChange={(e) => vm.onClickDataImport(e)} className='' id='import-excel' type='file' accept='.xlsx, .xls' />
              </span>
            }
            { vm.flag('importExcel') &&<span id='buttonSubmitCsv' className='betable__btnadd btn' onClick={(e) => vm.onClickSubmitExcelUpload(e)} >Nhập</span>*/}

            {<span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExport(e)}><i className='iconcustom-export2' />Xuất file</span>}

            { ['return', 'print'].indexOf(vm.props.name) === -1 &&
              (vm.state.paging.search_is_deleted !== 1
                ? <span className='btn--default' onClick={(e) => vm.onClickDataTrash(e, 1)} ><i className='icon-trash betable__recycle' /></span>
                : <span className='btn--default' onClick={(e) => vm.onClickDataTrash(e, 0)} ><i className='icon-list betable__recycle' /></span>
              )
            }

            {(index === 1 && ['Card'].indexOf(vm.props.name) !== -1  && vm.state.type === '') ? '' : <div className='searchbox__toggle'>
              <input placeholder='Tìm kiếm nhanh' onChange={(e) => vm.onBlurDatafilter(e, 'HoTenKhaiSinh')}/>
              <span className='icon-magnifier searchbox__toggle-icon'/>
              <span onClick={e => vm.toggle('findExpand')}>Tìm kiếm nâng cao</span>
            </div>}
          </div>
        }

        { (vm.props.name === 'print') &&
          <div className='betable__btns'>
            {(index === 1 && ['Card','print'].indexOf(vm.props.name) !== -1  && vm.state.type === '') ? '' : <div className='searchbox__toggle'>
              <input placeholder='Tìm kiếm nhanh' onChange={(e) => vm.onBlurDatafilter(e, 'HoTenKhaiSinh')}/>
              <span className='icon-magnifier searchbox__toggle-icon'/>
              <span onClick={e => vm.toggle('findExpand')}>Tìm kiếm nâng cao</span>
            </div>}
          </div>
        }

        { (vm.props.name.indexOf('review') !== -1) &&

            <div>
              <div className='col-md-2'>
                <RxSelectbox name='cate' options={vm.state.cate} results={vm.state.paging['search_CoQuanBaoChiID'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'CoQuanBaoChiID', 1) }} api='api_newspaper_office' optionDefault='--Chọn cơ quan báo chí--' loader='load' />
              </div>
              <div className='col-md-2'>
                <RxSelectbox name='typeHS' options={global.typeHS} results={vm.state.paging['search_StatusPhanLoai'] || ''} onChange={(result) => { vm.onBlurDataFilterReview(result, 'StatusPhanLoai', 1) }} optionDefault='--Chọn loại hồ sơ--' />
              </div>
              {['Super'].indexOf(role__) === -1 &&
              <div className=''>
                <select className='betable__findinput col-md-3' value={vm.state.paging['search_status'] || ''} onChange={(e) => vm.onBlurDataFilterReview(e, 'status')}>
                  <option value={-1}>--Trạng thái--</option>
                  <option value={0}>CQ trình hồ sơ</option>
                  <option value={1}>CV Hoàn thành thẩm định </option>
                  <option value={2}>LDP Hoàn thành thẩm định </option>
                  <option value={3}>LDC Hoàn thành thẩm định</option>
                  <option value={4}>LDB Hoàn thành thẩm định </option>
                </select>
              </div>
            }
            </div>

        }

        {['Card','print','review_8'].indexOf(vm.props.name) !== -1 && index === -1 && ['member'].indexOf(rxu.get(vm.props.auth, ['user', 'user', 'role'])) === -1 &&
          <div className='betable__btns filter'>
            <span className={'betable__btnimport btn' + (vm.state.paging['search_Type'] === 1 ? ' active' : '')}  onClick={(e) => vm.onBlurDataReview(1, 'Type')}>Cục Báo chí</span>
            <span className={'betable__btnimport btn' + (vm.state.paging['search_Type'] === 2 ? ' active' : '')} onClick={(e) => vm.onBlurDataReview(2, 'Type')}>Cục PTTH&TTĐT</span>
            <span className={'betable__btnimport btn' + (vm.state.paging['search_LoaiCoquan'] === 1 ? ' active' : '')} onClick={(e) => vm.onBlurDataReview(1, 'LoaiCoquan')}>Phòng Quản lý Báo chí</span>
            <span className={'betable__btnimport btn' + (vm.state.paging['search_LoaiCoquan'] === 2 ? ' active' : '')} onClick={(e) => vm.onBlurDataReview(2, 'LoaiCoquan')}>Phòng Kinh tế Báo chí</span>
          </div>
        }
        { (vm.flag('findExpand') && ['Card','print'].indexOf(vm.props.name) !== -1 ) ? <div className='findexpand_body clearfix'>{vm.run('renderFindExpand')}</div> : <div /> }

        {vm.props.name === 'print' && <div>
          <div className='betable__print'>
            <div>
              <div>Chọn font chữ Họ Tên</div>
              <input value={vm.state.fontPrint['HoTenKhaiSinh'] || ''} onChange={(e) => vm.onChangeFontPrint(e, 'HoTenKhaiSinh')} style={{width: '200px'}} />
            </div>
            <div>
              <div>Chọn font chữ Tên cơ quan báo chí</div>
              <input value={vm.state.fontPrint['TenCoQuanBaoChi'] || ''} onChange={(e) => vm.onChangeFontPrint(e, 'TenCoQuanBaoChi')} style={{width: '200px'}} />
            </div>
            <div>
              <div>Ban chuyên đối tượng</div>
              <input value={vm.state.fontPrint['BanChuyenDoiTuong'] || ''} onChange={(e) => vm.onChangeFontPrint(e, 'BanChuyenDoiTuong')} style={{width: '200px'}} />
            </div>
          </div>
          <div className='betable__print'>
            <div>
              <div>Ngày cấp</div>
              <DatePicker
                locale="el"
                dateFormat="dd/MM/yyyy"
                selected={ new Date(vm.state.fontPrint['NgayCap']*1000 || (1609459200*1000)) }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={date => {
                  let datetmp = date/1000
                  vm.onChangeDatePrint(datetmp, 'NgayCap')
                }}
              />
              {/*<input value={vm.state.fontPrint['NgayCap'] || ''} onChange={(e) => vm.onChangeFontPrint(e, 'NgayCap')} style={{width: '200px'}} />*/}
            </div>
            <div>
              <div>Ngày hết hạn</div>
              <DatePicker
                locale="el"
                dateFormat="dd/MM/yyyy"
                selected={ new Date(vm.state.fontPrint['NgayHetHan']*1000 || (1767200399*1000)) }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={date => {
                  let datetmp = date/1000
                  vm.onChangeDatePrint(datetmp, 'NgayHetHan')
                }}
              />
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>
}

// F I N D  E X P A N D
export const renderFindExpand = (vm) => {
  return (
    <div>
      <span className='findexpand_close' onClick={e => vm.toggle('findExpand')}><i className='iconcustom-close'/>Đóng</span>
      <div className='col-md-12 rx-flex'>
        <div className="rx--box__title">BỘ LỌC</div>
        <div className='col-md-12' style={{paddingRight: '60px'}}>
          <div className='col-md-2'>
            <RxSelectbox name='LoaiCoquan' options={global.LoaiCoquan} results={vm.state.paging['search_LoaiCoquan'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'LoaiCoquan', 1) } } optionDefault='--Cơ quan trực thuộc--' />
          </div>
          {/*<div className='col-md-3'>
            <RxSelectbox name='theDaCap' options={global.theDaCap} results={vm.state.paging['search_Status'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'Status', 1) } } optionDefault='--Trạng thái hồ sơ--' />
          </div>
          <div className='col-md-2'>
            <RxSelectbox name='cate' options={vm.state.position} results={vm.state.paging['search_TenChucVu'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'TenChucVu', 1) }} api='api_position' optionDefault='--Nhóm chức vụ--' loader='load' />
          </div>
          */}
          <div className='col-md-2'>
            <RxSelectbox name='cate' options={vm.state.cate} results={vm.state.paging['search_CoQuanBaoChiID'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'CoQuanBaoChiID', 1) }} api='api_newspaper_office' optionDefault='--Chọn cơ quan báo chí--' loader='load' />
          </div>
          <div className='col-md-2'>
            <RxSelectbox name='typeHS' options={global.typeHS} results={vm.state.paging['search_StatusPhanLoai'] || ''} onChange={(result) => { vm.onBlurDatafilter(result, 'StatusPhanLoai', 1) }} optionDefault='--Chọn loại hồ sơ--' />
          </div>

          <div className=''>
            <select className='betable__findinput col-md-3' value={vm.state.paging['search_typeDaiTH'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'typeDaiTH')}>
              <option value={-1}>--HS PTTH--</option>
              <option value={0}>TW</option>
              <option value={1}>DP </option>
            </select>
          </div>

          <div className=''>
            <select className='betable__findinput col-md-3' value={vm.state.paging['search_IsStatus'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'IsStatus')}>
              <option value="-1">--Trạng thái hồ sơ--</option>
              {global.IsStatus.map((x) => (
                <option key={x.id} value={x.id}>{x.Ten} </option>
              ))}
            </select>
          </div>

          <div className=''>
            <select className='betable__findinput col-md-3' value={vm.state.paging['search_IsUpdateProfile'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'IsUpdateProfile')}>
              <option value="-1">--Tình trạng--</option>
              <option value={0}>Tạo mới </option>
              <option value={1}>Hoàn thành</option>
            </select>
          </div>

          <div className='rx--btn__clearFilter'><span className="clearFilter" onClick={() => {vm.clearFilter()}}>Xóa bộ lọc</span></div>
        </div>
      </div>
      <div className="rx--box__search">
        <div className="col-md-12 rx-flex" style={{marginTop: '30px'}}>
          <div className="rx--box__title">TÌM KIẾM</div>
          <div className='col-md-12'>
            <div className='col-md-2 custom-search'>
              <input tabIndex='3' type='text' value={vm.state.paging['search_MaTheCu']} onChange={(e) => vm.onBlurDatafilter(e, 'MaTheCu')} className='fullwidth-input' placeholder='Mã thẻ cũ' />
            </div>
            <div className='col-md-2 custom-search'>
              <input tabIndex='3' type='text' value={vm.state.paging['search_MaSoThe']} onChange={(e) => vm.onBlurDatafilter(e, 'MaSoThe')} className='fullwidth-input' placeholder='Mã thẻ mới' />
            </div>
            <div className='col-md-2 custom-search'>
              <input tabIndex='4' type='text' value={vm.state.paging['search_HoTenKhaiSinh'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'HoTenKhaiSinh')} className='fullwidth-input' placeholder='Nhập họ tên' />
            </div>

            <div className='col-md-2 custom-search'>
              <input tabIndex='1' type='text' value={vm.state.paging['search_TuTuoi']} onChange={(e) => vm.onBlurDatafilterOld(e, 'TuTuoi')} className='fullwidth-input' placeholder='Từ tuổi' />
            </div>
            <div className='col-md-2 custom-search'>
              <input tabIndex='2' type='text' value={vm.state.paging['search_DenTuoi']} onChange={(e) => vm.onBlurDatafilterOld(e, 'DenTuoi')} className='fullwidth-input' placeholder='Đến tuổi' />
            </div>

            <div className='col-md-2 custom-search'>
              <input tabIndex='5' type='text' value={vm.state.paging['search_CMND'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'CMND')} className='fullwidth-input' placeholder='CMND' />
            </div>

            <div className='col-md-2 custom-search'>
              <input tabIndex='5' type='text' value={vm.state.paging['search_typeDaiTH'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'typeDaiTH')} className='fullwidth-input' placeholder='typeDaiTH' />
            </div>

            <div className='col-md-2 custom-search'>
              <input tabIndex='5' type='text' value={vm.state.paging['search_code'] || ''} onChange={(e) => vm.onBlurDatafilter(e, 'code')} className='fullwidth-input' placeholder='Mã đồng bộ' />
            </div>

            <div className='col-md-2 rx--btn__search' onClick={(e) => vm.fetchData()}><span className='search_now'>TÌM KIẾM NGAY</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

// F O R M
export const renderForm = (vm) => {
  let role__ = rxu.get(vm.props.auth, ['user', 'user', 'role'])

  if (vm.state.editingData.created_at || vm.state.editingData.created_at === 0 || ((vm.props.name.indexOf('review') !== -1 || ['print', 'return'].indexOf(vm.props.name) !== -1) && vm.state.renderForm)) {
    global.scrollfire()
    return <div className='adform'>
      {vm.state.view && vm.props.name === 'Card' && vm.props.title && <div className='adform__title'>{vm.props.title} <span>{`"${vm.state.editingData['HoTenKhaiSinh']}"`}</span></div>}
      {vm.state.view && vm.props.name !== 'Card' && vm.props.title && <div className='adform__title'>{vm.props.title} <span>{`"${vm.state.editingDataReview['HoTenKhaiSinh']}"`}</span></div>}
      <div className='adform__typecard'>
        {vm.run('renderFormType')}
      </div>
      <div className='adform__header'>
        {vm.run('renderFormHead')}
      </div>
      {(vm.state.view && ['viewer'].indexOf( role__ ) === -1) ?
      <div style={{display: 'flex'}}>
        <div className='adform__body col-md-10'>
          {/* {vm.state.activeTab === 'label2' && <div className='adform__note'> Sử dụng đối với trường hợp cán bộ chuyển cơ quan báo chí</div>} */}
          {vm.run('renderFormBody')}
          {/*vm.run('renderFormRaw')*/}
        </div>
        { ((vm.props.name.indexOf('review') !== -1 || vm.props.name === 'Card') && vm.state.editingData.LichSuDuyet) &&
          <div className='adform__body col-md-2 mobilehide'>
           <div style={{width: '100%'}}>
           <span className='rx--check__titleForm 1'>Quá trình thẩm định</span>
           { vm.state.editingData.LichSuDuyet.map( (duyet, index) =>
           <div className='rx--checkIssue' key={index}>
             <span className={duyet.action.indexOf('KHÔNG') === -1 ? 'rx--checkIssue__btn duyet' : 'rx--checkIssue__btn khongduyet'} />
              <span className='rx--check__time'>{global.toDate(duyet.created_at)}</span>
              <span className='rx--check__title'>{duyet.username + ' - ' + duyet.action}</span>
              <div className='rx--check__content' onClick={(e) => vm.rxshowPopup()}>
                {vm.state.rxshowPopup && <RxshowPopup value={duyet.desc} onCancel={(e) => vm.onCancel()} />}
                <span className={duyet.action.indexOf('KHÔNG') !== -1 ? 'content unconfirm' : 'content confirm '}>{duyet.desc}</span>
              </div>

            </div>
           )}
           </div>
          </div>
        }
        { ((vm.props.name.indexOf('review') !== -1 || vm.props.name === 'print') && vm.state.editingDataReview.LichSuDuyet) &&
          <div className='adform__body col-md-2 mobilehide'>
           <div style={{width: '100%'}}>
           <span className='rx--check__titleForm 2'>Quá trình thẩm định</span>
           { vm.state.editingDataReview.LichSuDuyet.map( (duyet, index) =>
           <div className='rx--checkIssue' key={index}>
              <span className={duyet.action.indexOf('KHÔNG') === -1 ? 'rx--checkIssue__btn duyet' : 'rx--checkIssue__btn khongduyet'} />
              {/*index > 0 && index < vm.state.editingDataReview.LichSuDuyet.length && <span className='rx--checkIssue__line' />*/}
              <span className='rx--check__time'>{global.toDate(duyet.created_at)}</span>
              <span className='rx--check__title'>{duyet.username + ' - ' + duyet.action}</span>
              <div className='rx--check__content' onClick={(e) => vm.rxshowPopup()}>
                {vm.state.rxshowPopup && <RxshowPopup value={duyet.desc} onCancel={(e) => vm.onCancel()} />}
                <span className={duyet.action.indexOf('KHÔNG') !== -1 ? 'content unconfirm' : 'content confirm '}>{duyet.desc}</span>
              </div>
            </div>
           )}
           </div>
          </div>
        }
        {(vm.props.name === 'return') && vm.state.editingDataReview.LichSuDuyet &&
          <div className='adform__body col-md-2 mobilehide'>
           <div style={{width: '100%'}}>
           <span className='rx--check__titleForm'>Quá trình thẩm định</span>
           { vm.state.editingDataReview.LichSuDuyet.map( (duyet, index) =>
           <div className='rx--checkIssue' key={index}>
             <span className={duyet.action.indexOf('KHÔNG') === -1 ? 'rx--checkIssue__btn duyet' : 'rx--checkIssue__btn khongduyet'} />
              <span className='rx--check__time'>{global.toDate(duyet.created_at)}</span>
              <span className='rx--check__title'>{duyet.username + ' - ' + duyet.action}</span>
                {(index === vm.state.editingDataReview.LichSuDuyet.length - 1) && (['Super','chuyenvientw','chuyenviendp','chuyenvienkql'].indexOf(role__) !== -1 ) ?
                 <input className='fullwidth-input input_2' type='text' value={duyet.desc || ''} onChange= {(e) => vm.onBlurData(e,'LichSuDuyet',{},'desc', index)}/>
                    :
                    <div className='rx--check__content' onClick={(e) => vm.rxshowPopup()}>
                      {vm.state.rxshowPopup && <RxshowPopup value={duyet.desc} onCancel={(e) => vm.onCancel()} />}
                      <span className={duyet.action.indexOf('KHÔNG') !== -1 ? 'content unconfirm' : 'content confirm '}>{duyet.desc}</span>
                    </div>
                }
            </div>
           )}
           </div>
          </div>
        }
      </div>
      :
      <div className='adform__body'>
        {/* {vm.state.activeTab === 'label2' && <div className='adform__note'> Sử dụng đối với trường hợp cán bộ chuyển cơ quan báo chí</div>} */}
        {vm.run('renderFormBody')}
      </div>}

      <div className='adform__footer'>
        {vm.run('renderFormBtns')}
      </div>
    </div>
  }
}

export const renderFormBody = (vm) => {
  let view = vm.state.view
  let tempform = []
  if(view) {
    if([0, 1, 4, 5, 6].indexOf(vm.state.editingData.StatusPhanLoai) !== -1 || [0, 1, 4, 5, 6].indexOf(vm.state.editingDataReview.StatusPhanLoai) !== -1) { if(vm.state.activeTab === 'label1'){ return vm.run('renderView1') } else { return vm.run('renderView2') } }
    if(vm.state.editingData.StatusPhanLoai === 2 || vm.state.editingDataReview.StatusPhanLoai === 2) { return vm.run('renderView4') }
    if(vm.state.editingData.StatusPhanLoai === 3 || vm.state.editingDataReview.StatusPhanLoai === 3) { if(vm.state.activeTab === 'label1'){ return vm.run('renderView3') } else { return vm.run('renderView2') } }
  } else if(vm.props.name === 'Card' && vm.state.editingData) {
    if([0, 1, 4, 5, 6].indexOf(vm.state.editingData.StatusPhanLoai) !== -1) { tempform = (vm.state.activeTab === 'label2') ? vm.props.form1  : vm.props.form }
    if(vm.state.editingData.StatusPhanLoai === 2) { tempform = vm.props.form2 }
    if(vm.state.editingData.StatusPhanLoai === 3) { tempform = (vm.state.activeTab === 'label2') ? vm.props.form1  : vm.props.form3 }
  } else {
    tempform = vm.props.form
  }
  if ((vm.state.editingData.created_at || vm.state.editingData.created_at === 0 || vm.props.name.indexOf('review') !== -1 || ['print', 'return'].indexOf(vm.props.name) !== -1) && tempform && tempform.length > 0) {
    let form = tempform.map((perdata, index) => {
      perdata.type = perdata.type || 'input'
      let result = (<div />)
      switch (perdata.type) {
        case 'input':
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'fullinput':
          result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'devide':
          result = <div key={index} className='fullwidth-formdevide cleafix' />
          break
        case 'header':
          result = <div className='form-header' key={index}>{perdata.func(vm)}</div>
          break
        case 'form':
          result = <div key={index} className='rx-form__body'>{perdata.name && <div className='rx-form__body--title'>{perdata.name}</div>}<div>{perdata.func(vm)}</div></div>
          break
        case 'table':
          result = <div key={index} className='rx-form__body'>{perdata.name && <div className='rx-form__body--title'>{perdata.name}</div>}<div>{vm.run('renderTableForm')}</div></div>
          break
        case 'table_form2':
          result = <div key={index} className='rx-form__body'><div>{vm.run('renderTableForm2')}</div></div>
          break
        default:
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
      }
      return result
    })
    return form
  }
}

export const renderTableForm = (vm) => {
  if ((vm.state.editingDataReview && vm.state.editingDataReview.QuaTrinhHoatDongUpdate) || (vm.state.editingData && vm.state.editingData.QuaTrinhHoatDongUpdate) ) {
    let QuaTrinhHoatDong = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? global.rxu.get(vm.state, ['editingDataReview', 'QuaTrinhHoatDongUpdate']) : global.rxu.get(vm.state, ['editingData', 'QuaTrinhHoatDongUpdate'])
    return (<div className='rx-table__form'>
        <table className={vm.state.view ? '' : 'marginRight-35'}>
          <tbody>
            <tr><td className='rx-table__form-header' rowSpan="2"><p><strong>Thời gian (từ tháng, năm nào đến tháng, năm nào)</strong></p></td>
              <td className='rx-table__form-header' rowSpan="2"><p><strong>Chức danh báo chí</strong></p></td>
              <td className='rx-table__form-header' rowSpan="2"><p><strong>Công tác tại cơ quan báo chí nào</strong></p></td>
              <td className='rx-table__form-header' colSpan="2"><p><strong>Lương</strong></p></td>
              <td className='rx-table__form-header' rowSpan="2"><p><strong>Khen thưởng kỷ luật (thời gian cụ thể)</strong></p></td>
              {/* <td className='rx-table__form-header' rowSpan="2"><p><strong>Mã thẻ</strong></p></td> */}
              {/*vm.state.view ? null : <td className='rx-table__form-header' rowSpan="2"><p><strong></strong></p></td>*/}
            </tr>
            <tr>
              <td className='rx-table__form-header'><p><strong>Ngạch lương</strong></p></td>
              <td className='rx-table__form-header'><p><strong>Bậc lương</strong></p></td>
            </tr>
            {QuaTrinhHoatDong && QuaTrinhHoatDong.map((obj, index) => (
              !vm.state.view ? <tr key={index}>
                <td>
                  {/*<input tabIndex='1' type='text' value={obj.time || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'time', index)} className='fullwidth-input' />*/}
                  <div className='datetime--table'>
                    <span style={{paddingRight:'3px'}}>Từ</span>
                    <DatePicker
                        dateFormat="MM/yyyy"
                        selected={ new Date(obj.time_start*1000)  }
                        showYearDropdown
                        showMonthYearPicker
                        dropdownMode="select"
                        onChange={date => { vm.onBlurData(date/1000, 'QuaTrinhHoatDongUpdate', {}, 'time_start', index) }}
                    />
                    <span style={{paddingLeft:'3px',paddingRighr:'3px'}}>Đến</span>
                    <DatePicker
                        dateFormat="MM/yyyy"
                        selected={ new Date(obj.time_end*1000) }
                        showYearDropdown
                        showMonthYearPicker
                        dropdownMode="select"
                        // locale="vi-VN"
                        onChange={date => { vm.onBlurData(date/1000, 'QuaTrinhHoatDongUpdate', {}, 'time_end', index) }}
                    />
                  </div>
                </td>
                <td><input tabIndex='2' type='text' value={obj.ChucDanhBaoChi ||''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'ChucDanhBaoChi', index)} className='fullwidth-input' /></td>
                <td><input tabIndex='3' type='text' value={obj.CoQuanBaoChi || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'CoQuanBaoChi', index)} className='fullwidth-input' /></td>
                <td><input tabIndex='4' type='text' value={obj.NgachLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'NgachLuong', index)} className='fullwidth-input' /></td>
                <td><input tabIndex='5' type='text' value={obj.BacLuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'BacLuong', index)} className='fullwidth-input' /></td>
                <td><input tabIndex='6' type='text' value={obj.KhenThuong || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'KhenThuong', index)} className='fullwidth-input' /></td>
                {/* <td><input tabIndex='7' type='text' value={obj.MaThe || ''} onChange={(e) => vm.onBlurData(e, 'QuaTrinhHoatDongUpdate', {}, 'MaThe', index)} className='fullwidth-input' /></td> */}
                <td className='adbtn__clear' onClick={(e) => vm.onClickRowDel(index, obj.key, 'QuaTrinhHoatDongUpdate')}>Xoá hàng</td>
              </tr> : <tr key={index}>
                <td className='rx-padding'>Từ {rxu.getMonth(obj.time_start)} đến {rxu.getMonth(obj.time_end)}</td>
                <td className='rx-padding'>{obj.ChucDanhBaoChi ||''}</td>
                <td className='rx-padding'>{obj.CoQuanBaoChi || ''}</td>
                <td className='rx-padding'>{obj.NgachLuong || ''}</td>
                <td className='rx-padding'>{obj.BacLuong || ''}</td>
                <td className='rx-padding'>{obj.KhenThuong || ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!vm.state.view && <div className='rx-table__form-plus' onClick={(e) => vm.onClickAddRowTable('QuaTrinhHoatDongUpdate')}><span className='iconcustom-plus'/></div>}
    </div>)
  }
}

export const renderTableForm2 = (vm) => {
  let DanhSachTacPham = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? global.rxu.get(vm.state, ['editingDataReview', 'DanhSachTacPham']) : global.rxu.get(vm.state, ['editingData', 'DanhSachTacPham'])
  return (<div className='rx-table__form'>
      <table className={vm.state.view ? 'width-100-percent' : 'marginRight-35'}>
        <tbody>
          <tr>
            <td className='rx-table__form-header'>STT</td>
            <td className='rx-table__form-header'>Tên tác phẩm báo chí</td>
            <td className='rx-table__form-header'>Loại hình báo chí</td>
            <td className='rx-table__form-header'>Tác giả, nhóm tác giả</td>
            <td className='rx-table__form-header'>Ngày, tháng, năm đăng phát</td>
            <td className='rx-table__form-header'>Cơ quan báo chí đăng, phát</td>
            {/* {vm.state.view ? null :  <td className='rx-table__form-header'>Thao tác</td>} */}
          </tr>
          {DanhSachTacPham && DanhSachTacPham.length > 0 && DanhSachTacPham.map((obj, index) => (
            !vm.state.view ? <tr key={index}>
              <td className='text-center'>{index+1}</td>
              <td><input tabIndex='1' type='text' value={obj.Ten || ''} onChange={(e) => vm.onBlurData(e, 'DanhSachTacPham', {}, 'Ten', index)} className='fullwidth-input' /></td>
              {/*<td><input tabIndex='2' type='text' value={obj.LoaiHinhBC ||''} onChange={(e) => vm.onBlurData(e, 'DanhSachTacPham', {}, 'LoaiHinhBC', index)} className='fullwidth-input' /></td>*/}
              <td className='LoaiHinhBC'>
                <RxSelectbox
                  name='LoaiHinhBC'
                  options={global.LoaiHinhBC}
                  results={obj.LoaiHinhBC}
                  onChange={(result) => { vm.onBlurDanhSachTacPham(result, 'LoaiHinhBC', index) }}
                  optionDefault='--Chọn loại--'
                  ten={true}
                />
              </td>
              <td><input tabIndex='3' type='text' value={obj.TacGia || ''} onChange={(e) => vm.onBlurData(e, 'DanhSachTacPham', {}, 'TacGia', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='4' type='text' value={obj.NgayDang || ''} onChange={(e) => vm.onBlurData(e, 'DanhSachTacPham', {}, 'NgayDang', index)} className='fullwidth-input' /></td>
              <td><input tabIndex='5' type='text' value={obj.CoQuanDang || ''} onChange={(e) => vm.onBlurData(e, 'DanhSachTacPham', {}, 'CoQuanDang', index)} className='fullwidth-input' /></td>
              {/* <td className='adbtn__clear' onClick={(e) => vm.onClickRowDel(index, obj.key, 'DanhSachTacPham')}>Xoá hàng</td> */}
            </tr> : <tr key={index}>
              <td className='rx-padding text-center'>{ index+1 }</td>
              <td className='rx-padding'>{obj.Ten || ''}</td>
              <td className='rx-padding'>{obj.LoaiHinhBC ||''}</td>
              <td className='rx-padding'>{obj.TacGia || ''}</td>
              <td className='rx-padding'>{obj.NgayDang || ''}</td>
              <td className='rx-padding'>{obj.CoQuanDang || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {!vm.state.view && <div className='rx-table__form-plus' onClick={(e) => vm.onClickAddRowTable('DanhSachTacPham')}><span className='iconcustom-plus'/></div>}
  </div>)
}

export const renderFormHead = (vm) => {
  let role__ = rxu.get(vm.props.auth, ['user', 'user', 'role'])
  let editingData = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? vm.state.editingDataReview : vm.state.editingData
  return <div className='adform__name'>
    {vm.state.activeStep && ['viewer'].indexOf(rxu.get( role__ )) === -1 && vm.run('renderTimeline')}
    {[0, 1, 4, 5, 6, 3, 7].indexOf(editingData.StatusPhanLoai) !== -1 && <div style={{marginBottom: '-20px'}}>
      <div className={vm.state.activeTab === 'label1' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={(e) => vm.onClickTabItem(e,'label1')}>{editingData['StatusPhanLoai'] === 3 ? 'Giấy xác nhận' : 'Mẫu số 01'}</div>
      {editingData.StatusPhanLoai !== 6 && <div className={vm.state.activeTab === 'label2' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={(e) => vm.onClickTabItem(e,'label2')}>Mẫu số 02</div>}
    </div>}
    {/*}
    <div className={(vm.state.view || vm.props.name === 'Card') ? 'adform__close rx-marginTop' : 'adform__close'}>
      {((vm.props.name.indexOf('review') !== -1 || vm.props.name === 'print') && vm.state.view) ? <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, vm.state.editingData)}>Đóng</span> : <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>}
      {!(vm.state.editingData['IsCheck1'] === 1 && vm.state.editingData['IsCheck2'] === 1 && vm.state.editingData['IsCheck3'] === 1) && (!vm.state.view && (vm.state.editingData.created_at !== 1 ? <span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e)}>Lưu</span>
        : <span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e)}>Lưu</span>))}
    </div>
    */}
  </div>
}

export const renderFormType = (vm) => {
  let editingData = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? vm.state.editingDataReview : vm.state.editingData
  let role = rxu.get(vm.props.auth, ['user', 'user', 'role'])
  let typeuser = rxu.get(vm.props.auth, ['user', 'user','Type'], 1)
  return (
    <div>
      {!vm.state.view && vm.props.name === 'Card' && <div className='adform__typecard--item'>
        <div className='adform__typecard--body'>
          <span>Chọn loại hồ sơ</span>
          <input defaultValue={statusPhanloai[editingData.StatusPhanLoai]} disabled />
          <div className='adform__typecard--select'>
            {/*}
            <RxSelectbox
                disabled={true}
                name='typeHS'
                options={global.typeHS}
                results={vm.state.editingData.StatusPhanLoai || ''}
                onChange={(result) => { vm.onBlurDataValue(result, 'StatusPhanLoai') }}
            />*/}
          </div>
        </div>

        {typeuser && typeuser !== 1  && <div className='adform__typecard--body'>
          <span>Chọn đài</span>
          <div className='adform__typecard--select'>
            <RxSelectbox
                disabled={true}
                name='typeDaiTH'
                options={global.typeDaiTH}
                results={vm.state.editingData.typeDaiTH || ''}
                onChange={(result) => { vm.onBlurDataValue(result, 'typeDaiTH') }}
                optionDefault='--Chọn đài--'
            />
            <span className='rxnote'>(Áp dụng đối với người đang công tác tại đài TT-TH cấp huyện)</span>
          </div>
        </div>}

        {['Super', 'member'].indexOf(role) !== -1 &&
        <div className='adform__typecard--body'>
          <span>Chọn đợt thẩm định</span>
          <div className='adform__typecard--select'>
            <RxSelectbox
                name='phase' add={true} showPhase={() => vm.onClickShowCreatePhase('create')}
                options={vm.state.phase}
                results={vm.state.editingData.phase || ''}
                onChange={(result) => { vm.onBlurDataValue(result, 'phase') }}
                api='api_cate'
                paging={vm.state.pagingPhase}
                optionDefault='--Chọn đợt thẩm định--'
            />
          </div>
        </div>
        }

      </div>}
      {vm.state.view && <div className='adform__typecard--item view'>
        <div className='adform__typecard--body view'>
          <span>Loại hồ sơ: </span>
          <input defaultValue={statusPhanloai[editingData.StatusPhanLoai]} disabled />
        </div>
        <div className='adform__typecard--body view'>
          <span>Đợt thẩm định: </span>
          <input defaultValue={editingData.phase} disabled />
        </div>
      </div>}
      {vm.state.view && ['member', 'viewer'].indexOf(role) === -1 && <div className='adform__typecard--item view'>
        <div className='adform__typecard--body view'>
          <div className='member'>Chuyên viên thụ lý hồ sơ: </div>
          <input defaultValue={rxu.get(editingData, ['appdist','fullname'])} disabled />
        </div>
        {/*}
        <div className='adform__typecard--body view'>
          <div className='member'>Chuyên viên được uỷ quyền: </div>
          {['lanhdaodp','lanhdaotw','chuyenviendp','chuyenvientw'].indexOf(role) !== -1 ? <RxSelectbox
            disabled='true'
            name={'user'}
            multi={false}
            api={'api_child_user'}
            loader={'load'}
            results={editingData.trace_name || ''}
            id={editingData.trace || ''}
            onChange={(result) => { vm.onBlurDataTrace(result, 'member_trace') }}
            optionDefault='--Chọn chuyên viên--'
          /> : <input defaultValue={editingData.trace_name} disabled />}
          {['lanhdaodp','lanhdaotw','chuyenviendp','chuyenvientw'].indexOf(role) !== -1 && <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateTrace(e)} onKeyPress={(e) => vm.onClickDataUpdateTrace(e)}>Lưu</div>}
        </div>
        */}
      </div>}
      {/*vm.props.name === 'review_8' && ['chuyenviendp','chuyenvientw'].indexOf(role) !== -1 && <div className='adform__typecard--item view'>
        <div />
        <div className='adform__typecard--body view'>
          <div className='member'>Chuyên viên được uỷ quyền: </div>
          <RxSelectbox
            disabled='true'
            name={'user'}
            multi={false}
            api={'api_child_user'}
            loader={'load'}
            results={editingData.trace_name || ''}
            id={editingData.trace || ''}
            onChange={(result) => { vm.onBlurDataTrace(result, 'member_trace', 'review_8') }}
            optionDefault='--Chọn chuyên viên--'
          />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateTrace(e, 'review_8')} onKeyPress={(e) => vm.onClickDataUpdateTrace(e, 'review_8')}>Lưu</div>
        </div>
      </div>*/}
    </div>
  )
}

export const renderFormRaw = (vm) => {
  if (vm.state.editingData._id) {
    if (vm.state.flags.rawedit) {
      setTimeout(() => {
        vm.jsoneditor = new JSONEditor(vm.jsonEditor, { mode: 'tree' })
        vm.jsoneditor.set(vm.state.editingData)
      }, 500)
    }

    return (
      <div className='clearfix adform__rawedit'>
        <div onClick={evt => vm.toggle('rawedit')} className='adform__raweditbtn'>RawEdit</div>
        { vm.state.flags.rawedit ? <div>
          <div className='adform__raweditcontent' ref={e => { vm.jsonEditor = e }} />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateSubmitRaw(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmitRaw(e)}>RawUpdate</div>
        </div> : <div /> }
      </div>
    )
  }
}
export const showPopup = (vm) =>
  <div className='show-popup fixed'>
    <div className='modal' id='modal'>
      <h2>Ghi chú thẩm định</h2>
      <div className='content'>
        {vm.props.name !== 'review_8' ?
          <textarea tabIndex='1' type='text' value={vm.state.editingData.GhiChu || ''} onChange={(e) => vm.onBlurData(e, 'GhiChu')} className='fullwidth-input' /> :
          <textarea tabIndex='1' type='text' value={vm.state.editingData.GhiChu || ''} onChange={(e) => vm.onBlurDataConfirm(e, 'GhiChu')} className='fullwidth-input' />
        }
      </div>
      <div className='actions'>
        <div className='adform__close' style={{float: 'left'}}><span className='adform__btn--close' onClick={(e) => vm.onClickClosePopup()}>Đóng</span></div>
        {vm.props.name !== 'review_8' ? <div className='adform__close'><span className='adform__btn--save' onClick={(e) => vm.onClickDataApprove(e,false)}>Lưu</span></div> :
        <div className='adform__close'><span className='adform__btn--save' onClick={(e) => vm.onClickDataConfirmAll(e,-1)}>Cập nhật</span></div> }
      </div>
    </div>
  </div>

export const showInputEmail = (vm) =>
  <div className='show-popup fixed'>
    <div className='modal__contact'>
      <div className='modal__contact-title'>Gửi thư trao đổi</div>
      <div className='content' >
        <div>
          <div>Email</div>
          <input tabIndex='1' type='text' value={vm.state.editingData.EmailThamVan || ''} onChange={(e) => vm.onBlurData(e, 'EmailThamVan')} className='fullwidth-input' placeholder='Nhập địa chỉ email' />
        </div>
        <div>
          <div>Title</div>
          <input tabIndex='1' type='text' value={vm.state.editingData.SubjectThamVan || ''} onChange={(e) => vm.onBlurData(e, 'SubjectThamVan')} className='fullwidth-input' placeholder='Nhập tiêu đề' />
        </div>
        <div>
          <div>Body</div>
          <CKEditor activeClass='p10' height='100px' content={vm.state.editingData.ContentThamVan} events={{ 'change': vm.onChangeContentCKE }} />
        </div>
      </div>
      <div className='actions'>
        <div className='adform__close'><span className='adform__btn--close' onClick={(e) => vm.onClickShowInput()}>Cancel</span></div>
        <div className='adform__close'><span className='adform__btn--save' onClick={(e) => vm.onClickConsultation(e,false)}>Ok</span></div>
      </div>
    </div>
  </div>
export const showPhase = (vm) =>
  <div className='rxtoggle__phase rx-fixed'>
    <div className='rxtoggle__phase--body'>
      <div className='rx-crud__form'>
        <div><div className='fullwidth-label'>Tên đợt: </div><input tabIndex='1' type='text' value={vm.state.editingDataPhase.name} onChange={(e) => vm.onBlurDataPhase(e, 'name')} className='fullwidth-input' /></div>
        <div className='fullwidth-formdevide cleafix' />
        <div className='rx-flex'><div className='fullwidth-label'>Cơ quan báo chí:</div>
          <RxSelectbox
              name='cate'
              options={vm.state.cate}
              results={vm.state.editingDataPhase.TenCoQuanBaoChi || ''}
              onChange={(result) => { vm.onBlurDataValue(result, 'TenCoQuanBaoChi', 'phase') }}
              api='api_newspaper_office'
              optionDefault='--Chọn cơ quan báo chí--'
          />
        </div>
        <div className='rx-flex'><div className='fullwidth-label'>Loại cơ quan:</div>
          <RxSelectbox
            name='LoaiCoquan'
            options={global.LoaiCoquan}
            results={vm.state.editingDataPhase.LoaiCoquan || ''}
            onChange={(result) => { vm.onBlurDataValue(result, 'LoaiCoquan', 'phase') }}
            optionDefault='--Chọn--'
          />
        </div>
      </div>
      <div className='actions'>
        <div className='adform__close'><span className='adform__btn--close' onClick={(e) => vm.onClickShowCreatePhase()}>Cancel</span></div>
        <div className='adform__close'><span className='adform__btn--save' onClick={(e) => vm.onClickDataCreateSubmit(e,'phase')}>Lưu</span></div>
      </div>
    </div>
  </div>

export const showPosition = (vm) => {
  let name = ''
  switch (vm.state.typePos) {
    case 'ChucDanhBaoChi':
      name = 'Tên chức danh'
      break;
    case 'TenChucVu':
      name = 'Tên chức vụ'
      break;
    case 'HinhThucDaoTao':
      name = 'Tên hình thức đào tạo'
      break;
    case 'TruongHoc':
      name = 'Tên trường học'
      break;
    case 'NganhHoc':
      name = 'Tên ngành học'
      break;
    case 'TrinhDoChuyenMon':
      name = 'Tên Trình độ chuyên môn'
      break;
    default:
  }
  return (<div className='rxtoggle__phase rx-fixed'>
    <div className='rxtoggle__phase--body'>
      <div className='rx-crud__form'>
        {/*<div><div className='fullwidth-label'>ID chức vụ: </div><input tabIndex='1' type='text' value={vm.state.editingDataPosition.ChucVuID} onChange={(e) => vm.onBlurDataPosition(e, 'ChucVuID')} className='fullwidth-input' /></div>*/}
        <div><div className='fullwidth-label'>{name}: </div><input tabIndex='2' type='text' value={vm.state.editingDataPosition.Ten || ''} onChange={(e) => vm.onBlurDataPosition(e, 'Ten')} className='fullwidth-input' /></div>
      </div>
      <div className='actions'>
        <div className='adform__close'><span className='adform__btn--close' onClick={(e) => vm.onClickShowCreatePosition()}>Cancel</span></div>
        <div className='adform__close'><span className='adform__btn--save' onClick={(e) => vm.onClickDataCreatePosition(e,'position')}>Lưu</span></div>
      </div>
    </div>
  </div>)
}

export const showLichSuDuyet = (vm) => {
  return (<div className='rxtoggle__phase rx-fixed'>
    <div className='rxtoggle__phase--body'>
      <div className='rx-crud__form'>
        <div><div className='fullwidth-label'>LỊCH SỬ DUYỆT</div></div>
        <div className='fullwidth-formdevide cleafix' />
        <div className='rxcrud__toggle'>
          {vm.state.dataLichSuDuyet && vm.state.dataLichSuDuyet.map((his, index) => (
            <div key={index} className='rxcrud__toggle--item'>
              <div className='toggle__item--title'>{his.action} - {his.username}<span>{rxu.getDate(his.created_at)}</span></div>
              <div className='toggle__item--desc' dangerouslySetInnerHTML={{  __html: his.desc }}></div>
            </div>
          ))}
        </div>
      </div>
      <div className='actions'>
        <div className='adform__close'><span className='adform__btn--close' onClick={(e) => vm.onClickShowHistory()}>Trở về</span></div>
      </div>
    </div>
  </div>)
}

export const renderToast = (vm) => {
  let obj = global.optionCard.find(o => o.id === vm.state.errMsg[0]);
  return <div className='toast-container toast-top-right'>
    <div className='toast'>
      <div className='toast-content'>
        <div className='toast-title'>{global.rxu.get(obj, 'name', global.rxu.get(vm.state, ['msg','msg'], ''))}</div>
        <div className='toast-message'>
          <div className=''>{global.rxu.get(obj, 'name', global.rxu.get(vm.state, ['msg','msg'], ''))} Không được bỏ trống. Vui lòng kiểm tra lại thông tin đã nhập!</div>
        </div>
      </div>
    </div>
  </div>
}

export const renderFormBtns = (vm) => {
  let data = rxu.get(vm.state, ['editingData', 'dataCard'], [])
  let isStatus = Number(rxu.get(vm.state, 'editingData.status', 0)) + 1
  let value = 'IsCheck' + isStatus
  let index = data ? data.findIndex(o => o[value] === 0) : ''

  let is_done = rxu.get(data, [0, 'is_done'], 0)
  let role = global.rxu.get(vm.props.auth, ['user','user','role'], 'member') // rxu.get(vm.state, 'role')

  let checkShowBtnClone = () => {
    let data = vm.state.editingData
    // if ((data.IsCheck4 != 0 && data.IsStatus >= 4) && data._idHoSoClone == "" && data.isClone == 0){ // dieu kien hien thi btn clone: lanh dao bo duyet va ho so nay chua duoc clone bao gio
    //   return true
    // }
    // return false
    if(data.is_active == 0){
      return false
    }
    return true
  }

  return <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
    {((vm.props.name.indexOf('review') !== -1 || ['print', 'return'].indexOf(vm.props.name) !== -1) && vm.state.view) ? <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, vm.state.editingData)} onKeyPress={(e) => vm.onClickDataEdit(e, vm.state.editingData)}>{t('return')}</div>
    : <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, {})} onKeyPress={(e) => vm.onClickDataEdit(e, {})}>{t('return')}</div>}
    {(vm.props.name.indexOf('review') !== -1 || ['print', 'return'].indexOf(vm.props.name) !== -1) && !vm.state.view && <span className='adform__btncancel betable__btnexport btn onClickDataExport3' onClick={(e) => vm.onClickDataExport3(e)}><i className='icon-cloud-download' />Xuất file</span>}
    {vm.state.view && ([0, 1, 4, 5, 6, 7].indexOf(vm.state.editingData.StatusPhanLoai) !== -1 || [0, 1, 4, 5, 6, 7].indexOf(vm.state.editingDataReview.StatusPhanLoai) !== -1) && <span className='adform__btncancel betable__btnexport btn onClickDataExport1' onClick={(e) => vm.onClickDataExport1(e)}><i className='icon-cloud-download' />Xuất mẫu 01</span>}
    {vm.state.view && (vm.state.editingData.StatusPhanLoai === 3 || vm.state.editingDataReview.StatusPhanLoai === 3) && <span className='adform__btncancel betable__btnexport btn onClickDataExport5' onClick={(e) => vm.onClickDataExport5(e)}><i className='icon-cloud-download' />Xuất giấy xác nhận</span>}
    {vm.state.view && ([0, 1, 3, 4, 5, 6, 7].indexOf(vm.state.editingData.StatusPhanLoai) !== -1 || [0, 1, 4, 5, 6, 3, 7].indexOf(vm.state.editingDataReview.StatusPhanLoai) !== -1) && <span className='adform__btncancel betable__btnexport btn onClickDataExport2' onClick={(e) => vm.onClickDataExport2(e)}><i className='icon-cloud-download' />Xuất mẫu 02</span>}
    {vm.state.view && (vm.state.editingData.StatusPhanLoai === 6 || vm.state.editingDataReview.StatusPhanLoai === 6) && <span className='adform__btncancel betable__btnexport btn onClickDataExport2' onClick={(e) => vm.onClickDataExport1(e, 5)}><i className='icon-cloud-download' />Xuất mẫu 05</span>}
    {vm.state.view && (vm.state.editingData.StatusPhanLoai === 2 || vm.state.editingDataReview.StatusPhanLoai === 2) && <span className='adform__btncancel betable__btnexport btn onClickDataExport4' onClick={(e) => vm.onClickDataExport4(e)}><i className='icon-cloud-download' />{`Xuất mẫu ${vm.state.editingData['LyDoCapLai'] == 2 ? '7' : '6'}`}</span>}
    {vm.state.view && vm.props.name.indexOf('review') !== -1 && ['Super','lanhdaobo'].indexOf(role) === -1 && <span className='adform__btncancel betable__btnexport btn' onClick={(e) => vm.onClickShowInput(e)}><i className='icon-cloud-download' />Tham vấn</span>}
    {vm.state.view && vm.props.name.indexOf('review') !== -1 && ['Super','lanhdaocuc01','lanhdaocucbctw','lanhdaocucbcdp','lanhdaocucptth'].indexOf(role) !== -1 && <span className='adform__btncancel betable__btnexport btn' onClick={(e) => vm.onClickReset(vm.state.editingDataReview)}  style={{'backgroundColor': '#ce8825'}}><i className='icon-cloud-download' />Duyệt lại</span>}
    {/*vm.state.checkAll && <span className='adform__btncancel btn' onClick={(e) => vm.onClickDataConfirmAll(e)}>Duyệt tất cả</span>*/}
    {(vm.props.name === 'Card' && ( ['Super','member'].indexOf(role) !== -1)) && <span className='adform__btncancel adform__btn--clear' onClick={(e) => vm.onClickDeleteData(e, vm.state.editingData)} ><i className='iconcustom-delete' />Xóa thẻ</span>}
    {vm.state.view && ['Super', 'member'].indexOf(role) !== -1 && vm.props.name === 'Card' && checkShowBtnClone() && <span style={{marginLeft: '-30px', marginRight: '-30px'}}>
    {/* {['Super', 'member'].indexOf(role) !== -1 && vm.props.name === 'Card' && <span style={{marginLeft: '-30px', marginRight: '-30px'}}> */}
      {/*}<span className='adform__btncancel adform__btn--save btn' onClick={(e) => vm.onClickShowClone()}>Clone</span>*/}
    </span>}
    <div className='adform__close'>
      <div style={{display: 'flex'}}>
        {((['Super','lanhdaobo'].indexOf(role )!== -1 && vm.props.name === 'print') || (vm.state.editingDataPrint && vm.state.editingDataPrint.length > 0)) && vm.state.view &&<div className='betable__btnadd btn' onClick={(e) => vm.onClickDataPrint('save')} ><i className='icon-plus betable__addbtn' />Xuất thẻ</div>}
        {((['Super','lanhdaobo'].indexOf(role )!== -1 && vm.props.name === 'print') || (vm.state.editingDataPrint && vm.state.editingDataPrint.length > 0)) && vm.state.view &&<div className='betable__btnadd btn' onClick={(e) => vm.onClickDataPrint('print')} ><i className='icon-plus betable__addbtn' />In thẻ</div>}
      </div>

      {['Super', 'superedit'].indexOf(role )!== -1 && !vm.state.editUpload && vm.state.editingData['IsStatus'] >= 3 && <span className='adform__btn--save 1' onClick={(e) => vm.onClickDataUpdateSubmit(e, 0)}>Lưu admin</span> }
      {['Super','superedit'].indexOf(role )!== -1 && !vm.state.editUpload && vm.state.editingData['IsStatus'] >= 3 && <span className='adform__btn--save 1' onClick={(e) => vm.onClickDataUpdateSubmit(e, 1)}>Hoàn thành admin</span> }
      {!(vm.state.editingData['IsCheck1'] === 1 && !vm.state.editUpload && vm.state.editingData['IsCheck2'] === 1 && vm.state.editingData['IsCheck3'] === 1 && vm.state.editingData['IsCheck4'] === 1) &&
      // {!(vm.state.editingData['IsCheck1'] !== 0 && vm.state.editingData['IsCheck2'] !== 0 && vm.state.editingData['IsCheck3'] !== 0 && vm.state.editingData['IsCheck4'] !== 0 && vm.state.editingData['IsCheck5'] !== 0) &&
        <span style={{marginLeft: '-30px', marginRight: '-30px'}}>
          {/*['member'].indexOf(role) === -1 && vm.props.name.indexOf('review') !== -1 && vm.state.hasPer && index !== -1 && !vm.state.view && data.length > 0 && <div><span className='adform__btncancel btn' onClick={(e) => vm.onClickDataConfirmAll(e)}>Duyệt tất cả</span></div>*/}
          {['member','viewer','super'].indexOf(role) === -1 && vm.props.name.indexOf('review') !== -1 && vm.props.name !== 'review_8' && !vm.state.view && data.length > 0 &&
          <div>
            <span className='adform__btn--save 0' onClick={(e) => vm.onClickDataUpdateSubmitCate(e, 1)}>Hoàn thành đợt</span>
            {['Super'].indexOf(role) !== -1 && <span className='adform__btn--save 0' onClick={(e) => vm.onClickDataUpdateSubmitCate(e, 2)}>Hoàn thành nhanh</span>}
          </div>}

          {['member'].indexOf(role) === -1 && vm.props.name.indexOf('review') !== -1 &&
          vm.state.hasPer && ([0,1,2,3].indexOf(vm.state.editingData.IsStatus) !== -1 ||
          [0,1,2,3].indexOf(vm.state.editingDataReview.IsStatus) !== -1) &&
          <div>
              {['lanhdaobo', 'chuyenviendp','chuyenvientw','chuyenvienkql'].indexOf(role) === -1 && <span className='adform__btn--save' onClick={(e) => vm.showForm(e,false, -1)} style={{'backgroundColor': '#ce8924'}}>Trả hồ sơ</span>}
              {['lanhdaobo','lanhdaocuc01','lanhdaocucbctw','lanhdaocucbcdp','lanhdaocucptth','lanhdaocuckql'].indexOf(role) === -1 && <span className='adform__btn--save' onClick={(e) => vm.showForm(e, true, 1)} style={{'backgroundColor': '#297e36'}}>Duyệt nhanh cấp</span>}
              {['lanhdaobo','lanhdaocuc01','lanhdaocucbctw','lanhdaocucbcdp','lanhdaocucptth','lanhdaocuckql'].indexOf(role) === -1 && <span className='adform__btn--save' onClick={(e) => vm.showForm(e, false, 1)} style={{'backgroundColor': '#b33131'}}>Duyệt nhanh ko cấp</span>}
              <span className='adform__btn--save' onClick={(e) => vm.showForm(e, true)} style={{'backgroundColor': '#297e36'}}>Duyệt cấp</span>
              <span className='adform__btn--save' onClick={(e) => vm.showForm(e, false)} style={{'backgroundColor': '#b33131'}}>Không duyệt cấp</span>
          </div>}

          {['print', 'return'].indexOf(vm.props.name) === -1 && !vm.state.loading && !vm.state.editUpload &&
            ( vm.state.editingData.created_at !== 1 ?
              vm.props.name.indexOf('review') === -1  &&
              <div>
                {!vm.state.view && vm.state.editingData.IsUpdateProfile && ['Super','member'].indexOf(role) !== -1  && <span className='adform__btn--save 1' onClick={(e) => vm.onClickDataUpdateSubmit(e, 0)}>Lưu</span>}
                {!vm.state.view && vm.state.editingData.IsUpdateProfile !== 1 && ['Super','member'].indexOf(role) !== -1  && <span className='adform__btn--save 1' onClick={(e) => vm.onClickDataUpdateSubmit(e, 0)}>Lưu</span>}
                {['superedit','profileedit'].indexOf(role) === -1  && <span className='adform__btn--save 1' onClick={(e) => vm.onClickDataUpdateSubmit(e, 1)}>Hoàn thành </span>}
              </div>
              : <div><span className='adform__btn--save 2' onClick={(e) => vm.onClickDataCreateSubmit(e, 0)}>Lưu</span> {<span className='adform__btn--save 2' onClick={(e) => vm.onClickDataCreateSubmit(e,1)}>Hoàn thành</span>}</div>
            )
          }

          {['lanhdaocuc01','lanhdaocucbctw','lanhdaocucbcdp','lanhdaocucptth','lanhdaocuckql'].indexOf(role) !== -1 && vm.props.name.indexOf('review_8') !== -1 && !vm.state.view && data.length > 0 && <div><span className='adform__btn--save 0' onClick={(e) => vm.onClickDataUpdateSubmitFinal(e, 1)}>Trình hồ sơ</span></div>}
          {['Super','superedit'].indexOf(role) !== -1 && vm.props.name.indexOf('review_8') !== -1 && !vm.state.view && data.length > 0 && <div><span className='adform__btn--save 0' onClick={(e) => vm.onClickDataUpdateSubmitRollback(e, 1)}>Hoàn hồ sơ</span></div>}

        </span>
      }

      {['Super','chuyenvientw', 'chuyenviendp','chuyenvienpt','chuyenvienkql','member','superedit'].indexOf(role) !== -1 && vm.state.editUpload &&
        <div><span className='adform__btn--save 3' onClick={(e) => vm.onClickDataUpdateImage(e)}>Hoàn thành</span></div>
      }

      {(['Super','chuyenvientw', 'chuyenviendp','chuyenvienpt','chuyenvienkql'].indexOf(role) !== -1 && vm.props.name === 'return'  && vm.state.editingDataReview.is_done === 0 && vm.state.view) &&
       <span style={{marginLeft: '-30px', marginRight: '-30px'}}>
          <div><span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateCard(e,false)}>Trả hồ sơ</span></div>
       </span>}
       {['Super','chuyenvientw', 'chuyenviendp','chuyenvienpt','chuyenvienkql'].indexOf(role) !== -1 && vm.props.name === 'return' && is_done === 1 && !vm.state.view && data.length > 0 &&
       <span style={{marginLeft: '-30px', marginRight: '-30px'}}>
        <div><span className='adform__btn--save' onClick={(e) => vm.onClickDataUpdateSubmit(e, 1)}>Hoàn thành</span></div>
       </span>}
       {/* {['Super', 'member'].indexOf(role) !== -1 && vm.props.name === 'Card' && vm.state.editingData['IsStatus'] === 6 && <span style={{marginLeft: '-30px', marginRight: '-30px'}}> */}
       {/* {['Super', 'member'].indexOf(role) !== -1 && vm.props.name === 'Card' && <span style={{marginLeft: '-30px', marginRight: '-30px'}}>
          <div><span className='adform__btn--save' onClick={(e) => vm.onClickDataCloneCard(e,false)}>Clone</span></div>
       </span>} */}
       {vm.props.name === 'review_8' && ['lanhdaobo'].indexOf(role) !== -1 &&  <span style={{marginLeft: '-30px', marginRight: '-30px'}}>
          {!vm.state.view &&
            <div>
              <span className='adform__btn--save' onClick={(e) => vm.onClickDataConfirmAll(e, 1)}>Đồng ý</span>
              <span className='adform__btn--save' onClick={(e) => vm.onClickDataConfirmAll(e, 2)}>Không đồng ý</span>
              <span className='adform__btn--save' onClick={(e) => vm.showFormConfirmAll(e, true)}>Ý kiến khác</span>

            </div>
          }
        </span>}
    </div>
  </div>
}

export const renderBtnBottom = (vm) =>
  <div className='betable__btns clearfix'>
    {['print', 'return'].indexOf(vm.props.name) === -1 && <span className='betable__btnexport btn' onClick={(e) => vm.onClickDataExport(e)}><i className='icon-cloud-download' />Xuất file</span>}
  </div>

// export const renderBtnPrint = (vm) =>
  // <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
  //   <div>
  //     <span className='adform__btn--save' onClick={(e) => vm.onClickDataPrintAll(e, 'all')}>In thẻ</span>
  //   </div>
  // </div>


export const renderView1 = (vm) => {
  let editingData = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? vm.state.editingDataReview : vm.state.editingData
  return(<div className='rx-crud__form'>
    <div className='form-header'>
      <div>
          <div className='class_avatar'>
              <RxUpload callback={(e) => vm.callbackUpload(e, 'img_landscape')} images={editingData.img_landscape} path={editingData.path} type='avatar' disabled='1' />
              <div className='text-center form-avatar-sub'>Ảnh 3x4 <br /> (Nền xanh)</div>
          </div>
          <div className='form-header-title'>
              <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
              <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
              <div className='text-center title-main'>BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO</div>
              <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1)</div>
              <div className='form-header-bottom'>
                  <span>Tên cơ quan đề nghị cấp thẻ nhà báo: </span>
                  <input tabIndex='1' type='text' value={editingData.TenCoQuanBaoChi || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='form-header-right barcode'>
              <div>
                  <QRCode id={editingData.code || '1'} value={global.rxu.config.base_qrcode + editingData.MaSoThe || '1'} size={160} level={"H"} includeMargin={true} />
                  <div className='text-center form-avatar-sub'>{editingData.code || ''}</div>
                  {/* <div className='form-header-code'>Số thẻ nhà báo cũ: {editingData.MaTheCu}</div> */}
              </div>
          </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Thông tin cá nhân</div>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Họ và tên khai sinh (2): </span>
                  <div style={{width:'100%'}}>
                      <input tabIndex='1' type='text' value={editingData.HoTenKhaiSinh || ''} className='fullwidth-input textuppercase' disabled/>
                  </div>
              </div>
              <div className='gender--item'>
                  <span className='gender--title'>Nam/Nữ: </span>
                  <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngày, tháng, năm sinh: </span>
                  <input tabIndex='1' type='text' value={ rxu.getDate( editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
            <div>
                <span>Dân tộc: </span>
                <input type='text' value={editingData.DanToc || ''} className='fullwidth-input' disabled />
            </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Quê quán: </span>
                  <input type='text' value={editingData.QueQuan || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Nơi ở hiện nay: </span>
                  <div className='rxform--province'>
                      <input type='text' value={editingData.NoiO || ''} className='fullwidth-input' disabled />
                      <div className='province--item'>
                          <input type='text' value={editingData.province || ''} className='fullwidth-input' disabled />
                      </div>
                  </div>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Chức vụ (3): </span>
                  <input type='text' value={editingData.TenChucVu || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Bút danh thường dùng: </span>
                  <input type='text' value={editingData.ButDanh || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          {/*}
          <div className='rx-form__item'>
              <div>
                  <span>Số điện thoại:</span>
                  <input type='text' value={editingData.phone_number || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Địa chỉ Email:</span>
                  <input type='text' value={editingData.email || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          */}
        </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>TRÌNH ĐỘ</div>
      <div>
        <div className='rx-form__item'>
            <div>
                <span>Giáo dục phổ thông (4): <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.TrinhDoVanHoa || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        {/*}<div className='rx-form__title--sub'>Chuyên môn (5): <span/></div>*/}
        <div className='rx-form__item'>
            <div>
                <span>Chuyên môn (5): <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={rxu.ArrayToString(editingData.TrinhDoChuyenMon) || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Trường học: <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={rxu.ArrayToString(editingData.TruongHoc) || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Ngành học: <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={rxu.ArrayToString(editingData.NganhHoc) || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Hình thức đào tạo (6): <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.HinhThucDaoTao || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Năm tốt nghiệp: <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.NamTotNghiep || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Lý luận chính trị (7): <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.ChinhTri || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Ngoại ngữ (8): <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.NgoaiNgu || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Chứng chỉ đào tạo, bồi dưỡng nghiệp vụ báo chí (nếu có): <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.ChungChi || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Đảng viên: <i className='icon-require'/></span>
                <input tabIndex='1' type='text' value={editingData.DangVien || ''} className='fullwidth-input' disabled/>
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Chức danh báo chí hiện nay (9): </span>
                <input tabIndex='1' type='text' value={editingData.ChucDanhBaoChi || ''} className='fullwidth-input' disabled />
            </div>
        </div>
        <div className='rx-form__item'>
            <div>
                <span>Số thẻ nhà báo và kỳ hạn cũ: </span>
                <input tabIndex='1' type='text' value={editingData.MaTheCu || ''} className='fullwidth-input' disabled />
            </div>
        </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Quá trình hoạt động báo chí 5 năm gần nhất:</div>
      <div>{vm.run('renderTableForm')}</div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Thông tin khai cho mẫu số 3</div>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span style={{minWidth: '150px'}}>Số CMND/CCCD/Hộ chiếu</span>
                  <input tabIndex='1' type='text' value={editingData.CMND || ''} className='fullwidth-input' disabled />
              </div>
              <div>
                  <span style={{minWidth: '100px'}}>Ngày cấp</span>
                  <input tabIndex='1' type='text' value={ rxu.getDate( editingData.NgayCapCMND) } className='fullwidth-input' disabled />
              </div>
              <div>
                  <span style={{minWidth: '150px'}}>Ảnh CMND/CCCD</span>
                  <RxUploadCMND images={editingData.cmnd_truoc} path={editingData.path} title='Ảnh mặt trước' single={1} disabled={1}/>
                  <RxUploadCMND images={editingData.cmnd_sau} path={editingData.path} title='Ảnh mặt sau' single={1} disabled={1}/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span> Ngày, tháng, năm ký HĐLĐ có đóng BHXH</span>
                  <input tabIndex='1' type='text' value={editingData.ThongTinHDLD || ''} className='fullwidth-input' disabled />
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Thành phần hồ sơ khác</div>
      <div>
        <div>
          {/*}
          <div className='rx-form__item'>
              <div>
                  <span>Bản khai đăng ký {bankhai[editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                  <RxUploadCMND images={editingData.SoYeuLyLich} title='Sơ yếu lý lịch' disabled='1' />
              </div>
          </div>*/}
          <div className='rx-form__item'>
            <div>
              <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
              <RxUploadCMND images={editingData.BanSaoBangDHCD} path={editingData.path} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
            </div>
          </div>
          {!editingData['checkHadCard'] && <div className='rx-form__item'>
            <div>
              <span>Scan bản chính hoặc bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
              <RxUploadCMND images={editingData.BanSaoHDLD} path={editingData.path} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
            </div>
          </div>}
          {editingData['StatusPhanLoai'] && editingData['StatusPhanLoai'] === 6 && editingData['checkHadCard'] && <div className='rx-form__item'>
              <div>
                  <span>Văn bản xác nhận của cơ quan, đơn vị trước khi chuyển công tác</span>
                  <RxUploadCMND images={editingData.XacNhanCQCu} path={editingData.path} title='Văn bản xác nhận của cơ quan, đơn vị trước khi chuyển công tác' disabled='1' />
              </div>
          </div>}
          {!editingData['checkHadCard'] && <div className='rx-form__item'>
              <div>
                  <span>Thành phần hồ sơ khác chứng minh thuộc đối tượng cấp thẻ (nếu có)</span>
                  <RxUploadCMND images={editingData.profileAttachments} path={editingData.path} title='Thành phần hồ sơ khác' disabled='1' />
              </div>
          </div>}

          { editingData['StatusPhanLoai'] && editingData['StatusPhanLoai'] === 7 &&
          <>
            <div className='rx-form__item'>
                <div>
                    <span>Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)</span>
                    <RxUploadCMND images={editingData.DonXacNhanCapLaiThe} path={editingData.path} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Ảnh scan thẻ hỏng</span>
                    <RxUploadCMND images={editingData.AnhScanTheHong} path={editingData.path} title='Ảnh scan thẻ hỏng' disabled='1' />
                </div>
            </div>
          </>
          }

          { editingData['StatusPhanLoai'] && editingData['StatusPhanLoai'] === 6 && editingData['checkHadCard'] &&
          <>

            {/*!editingData['checkHadCard'] }<div className='rx-form__item'>
                <div>
                    <span>Văn bản xác nhận của cơ quan cũ</span>
                    <RxUploadCMND images={editingData.DonXacNhanCQCu} path={editingData.path} title='Văn bản xác nhận của cơ quan cũ' disabled='1' />
                </div>
            </div>*/}


            <div className='rx-form__item'>
                <div>
                    <span>Văn bản đề nghị của cơ quan mới</span>
                    <RxUploadCMND images={editingData.DonXacNhanCQMoi} path={editingData.path} title='Văn bản đề nghị của cơ quan mới' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Ảnh scan của thẻ nhà báo ở cơ quan cũ</span>
                    <RxUploadCMND images={editingData.AnhScanTheCu} path={editingData.path} title='Ảnh scan của thẻ nhà báo ở cơ quan cũ' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động</span>
                    <RxUploadCMND images={editingData.VanBanTiepNhanCTacMoi} path={editingData.path} title='Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động' disabled='1' />
                </div>
            </div>

          </>
          }
          { editingData['StatusPhanLoai'] && editingData['StatusPhanLoai'] === 6 && <div className='rx-form__item'>
            <div>
              <span>Danh sách tác phẩm báo chí đã đăng, phát</span>
              <RxUploadCMND images={editingData.DanhSachTacPhamDaDangPhat} path={editingData.path} title='Danh sách tác phẩm báo chí đã đăng, phát' disabled='1' />
            </div>
          </div>}

          {/*}
          <div className='rx-form__item'>
              <div>
                  <span>Số điện thoại:</span>
                  <input type='text' value={editingData.phone_number || ''} className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Địa chỉ Email:</span>
                  <input type='text' value={editingData.email || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Phương thức tiếp nhận 1</span>
                  <input type='text' value={rxu.rxparseTen('reception', editingData.reception) || ''} className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Ngày tiếp nhận</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.reception_date) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngày hẹn trả</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.appointment_date) } className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Ngày trả thực tế</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.actual_date) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Phương thức trả kết quả</span>
                  <input type='text' value={rxu.rxparseTen('reception', editingData.method_result) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Thành phần hồ sơ đính kèm và kết quả giải quyết (nếu có)</span>
              <RxUploadCMND images={editingData.attach} title='' disabled='1' />
            </div>
          </div>
          */}

        </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    {/*}
    <div className='rx-form__body' style={{marginTop:'40px'}}>
      <div>
        <div className='rx-form__sign'>
          <div className="rx-form__sign__item">
            <div className='formsign__title'>Chuyên viên Thuộc cục</div>
            {editingData.IsCheck1 === 1 && <div>
              <div className='formsign__sign'>Đã ký</div>
              <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 0, 'created_at']))}</div>
            </div>}
          </div>
          <div className="rx-form__sign__item">
            <div className='formsign__title'>Lãnh đạo PHÒNG</div>
            {editingData.IsCheck2 === 1 && <div>
              <div className='formsign__sign'>Đã ký</div>
              <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 1, 'created_at']))}</div>
            </div>}
          </div>
          <div className="rx-form__sign__item">
            <div className='formsign__title'>Lãnh đạo CỤC BÁO CHÍ</div>
            {editingData.IsCheck3 === 1 && <div>
              <div className='formsign__sign'>Đã ký</div>
              <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 2, 'created_at']))}</div>
            </div>}
          </div>
          <div className="rx-form__sign__item">
            <div className='formsign__title'>Lãnh đạo bộ</div>
            {editingData.IsCheck4 === 1 && <div>
              <div className='formsign__sign'>Đã ký</div>
              <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 3, 'created_at']))}</div>
            </div>}
          </div>
        </div>
      </div>
    </div>
    */}

    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body note'>
      <div className='rx-form__body--note'>Lưu ý</div>
      <div>
        <div>
            <p> </p>
            <p>Ghi chú:</p>
            <p>(1) Ghi theo kỳ hạn thẻ nhà báo đề nghị cấp (ví dụ: 2016 - 2020)</p>
            <p>(2) Viết chữ in hoa đủ dấu. </p>
            <p>(3) Ghi rõ chức vụ bổ nhiệm (ví dụ: Tổng biên tập, Phó Tổng biên tập, Trưởng ban...). </p>
            <p>(4) Ghi rõ đã tốt nghiệp lớp mấy, thuộc hệ nào.</p>
            <p>(5) Ghi rõ trình độ chuyên môn cao nhất (ví dụ: Tiến sĩ, thạc sĩ, đại học, cao đẳng...).</p>
            <p>(6) Chính quy, tại chức, đào tạo từ xa.... </p>
            <p>(7) Cao cấp, trung cấp, sơ cấp và tương đương</p>
            <p>(8) Tên ngoại ngữ và trình độ (ví dụ: A, B, C, D...)</p>
            <p>(9) Ghi rõ chức danh nghề nghiệp (ví dụ: Phóng viên, biên tập viên...). </p>
        </div>
      </div>
    </div>
  </div>)
}
export const renderView2 = (vm) =>
  <div className='rx-crud__form'>
    <div className='form-header'>
      <div>
          <div className='form-header-title'>
              <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
              <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
              <div className='text-center title-main'>DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT</div>
              <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1)</div>
          </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'><div>{vm.run('renderTableForm2')}</div></div>
  </div>

export const renderView4 = (vm) => {
  let editingData = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? vm.state.editingDataReview : vm.state.editingData
  let showLyDoCap = (lydo) => {
    let obj = global.NgNhanCapDoi.find(o => o.id == lydo);
    return obj;
  }
  return <div className='rx-crud__form'>
    <div className='form-header'>
      <div>
          <div className='form2-header-title'>
            <div className='form2-header-top'>
                <div className='class_avatar'>
                    <RxUpload callback={(e) => vm.callbackUpload(e, 'img_landscape')} path={editingData.path} images={editingData.img_landscape} type='avatar' disabled='1' />
                    <div className='text-center form-avatar-sub'>Ảnh 3x4 <br /> (Nền xanh)</div>
                </div>
                {/* <div className='header__right'>
                    <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                    <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
                    <div className='text-center title-alias'>{rxu.getFullDate(editingData.created_at || '')}</div>
                </div> */}
            </div>
            <div className='text-center title-main'>CẤP LẠI THẺ NHÀ BÁO</div>
            {/*}<div className='title-sub'>Thực hiện quy định tại Luật Báo chí 2016 về thẻ nhà báo, đề nghị Bộ Thông tin và Truyền thông cấp lại thẻ nhà báo: </div>*/}
        </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Họ và tên: </span>
                  <div style={{width:'100%'}}>
                      <input tabIndex='1' type='text' value={editingData.HoTenKhaiSinh || ''} className='fullwidth-input' disabled/>
                  </div>
              </div>
              <div className='gender--item'>
                  <span className='gender--title'>Nam/Nữ: </span>
                  <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngày sinh: </span>
                  <input tabIndex='1' type='text' value={ rxu.getDate(editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Số CMND/CCCD/Hộ chiếu: </span>
                  <input tabIndex='1' type='text' value={ editingData.CMND } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Nơi công tác: </span>
                  <input tabIndex='1' type='text' value={ editingData.TenCoQuanBaoChi } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Thẻ nhà báo số: </span>
                  <input tabIndex='1' type='text' value={ editingData.MaTheCu } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Lý do đề nghị cấp lại (mất thẻ, hỏng thẻ): </span>
                  <input tabIndex='1' type='text' value={ editingData.LyDoCapLai ? showLyDoCap(editingData.LyDoCapLai).Ten : ""} className='fullwidth-input' disabled/>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Thành phần hồ sơ khác</div>
      <div>
        <div>
          {/* <div className='rx-form__item'>
              <div>
                  <span>Bản khai đăng ký {bankhai[editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                  <RxUploadCMND images={editingData.SoYeuLyLich} title='Sơ yếu lý lịch' disabled='1' />
              </div>
          </div> */}
          {/* <div className='rx-form__item'>
            <div>
              <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
              <RxUploadCMND images={editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
            </div>
          </div> */}
          {/* <div className='rx-form__item'>
            <div>
              <span>Scan bản chính hoặc bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
              <RxUploadCMND images={editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
            </div>
          </div> */}
          {editingData.LyDoCapLai == 1 &&
          <>
            <div className='rx-form__item'>
                <div>
                    <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác)</span>
                    <RxUploadCMND images={editingData.DonXacNhanCapLaiThe} path={editingData.path} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Scan Thẻ bị hỏng</span>
                    <RxUploadCMND images={editingData.AnhScanTheHong} path={editingData.path} title='Ảnh scan thẻ hỏng' disabled='1' />
                </div>
            </div>
          </>
          }
          {editingData.LyDoCapLai == 2 &&
          <>
            <div className='rx-form__item'>
              <div>
                <span>Cơ quan công an trình báo mất thẻ</span>
                <input tabIndex='1' type='text' value={editingData.cqCongAn || ''} className='fullwidth-input' disabled='1' />
              </div>
            </div>

            <div className='rx-form__item'>
              <div>
                <span>Nội dung trình báo</span>
                <input tabIndex='1' type='text' value={editingData.ndTrinhBao || ''} className='fullwidth-input' disabled='1' />
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                  <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác)</span>
                  <RxUploadCMND images={editingData.DonXacNhanMatThe} path={editingData.path} title='Đơn xác nhận xin cấp lại thẻ của cơ quan' disabled='1' />
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                  <span>Đơn có xác nhận của cơ quan công an nơi mất thẻ</span>
                  <RxUploadCMND images={editingData.DonXacNhanCADoMatThe} path={editingData.path} title='Đơn xác nhận mất thẻ của công an' disabled='1' />
              </div>
            </div>
          </>
          }

          {/*}
          <div className='rx-form__item'>
              <div>
                  <span>Số điện thoại:</span>
                  <input type='text' value={editingData.phone_number || ''} className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Địa chỉ Email:</span>
                  <input type='text' value={editingData.email || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Phương thức tiếp nhận</span>
                  <input type='text' value={rxu.rxparseTen('reception', editingData.reception) || ''} className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Ngày tiếp nhận</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.reception_date) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngày hẹn trả</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.appointment_date) } className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Ngày trả thực tế</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.actual_date) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Phương thức trả kết quả</span>
                  <input type='text' value={rxu.rxparseTen('reception', editingData.method_result) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Tình trạng thanh toán</span>
                  <input value={rxu.rxparseTen('payment', editingData.payment) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Thành phần hồ sơ đính kèm và kết quả giải quyết (nếu có)</span>
              <RxUploadCMND images={editingData.attach} title='' disabled='1' />
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  </div>
}

export const renderView3 = (vm) => {
  let editingData = (['print', 'return'].indexOf(vm.props.name) !== -1 || vm.props.name.indexOf('review') !== -1) ? vm.state.editingDataReview : vm.state.editingData
  return <div className='rx-crud__form'>
    <div className='form-header'>
      <div>
        <div className='form2-header-title' style={{width:'100%', padding:'0 0 50px 0'}}>

          <div className='form2-header-top'>
            <div className='header__left'>
              <div className='title-top' style={{paddingBottom: '15px'}}>{editingData.TenCoQuanBaoChi}</div>
              <div className='class_avatar'>
                  <RxUpload callback={(e) => vm.callbackUpload(e, 'img_landscape')} path={editingData.path} images={editingData.img_landscape} type='avatar' disabled='1' />
                  <div className='text-center form-avatar-sub'>Ảnh 3x4 <br /> (Nền xanh)</div>
              </div>
            </div>
            <div className='header__right'>
              <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
              <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
              <div className='header__hr'/>
              <div className='text-center title-alias'>{rxu.getFullDate(editingData.created_at || '')}</div>
            </div>
          </div>
          <div className='text-center header__title'>GIẤY XÁC NHẬN</div>
        </div>
      </div>
    </div>
    <div className='fullwidth-formdevide cleafix' />
    <div className='rx-form__body'>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Cơ quan báo chí (ghi tên cơ quan báo chí, cơ quan xác nhận)</span>
                  <input tabIndex='1' type='text' value={ editingData.TenCoQuanBaoChi } className='fullwidth-input' disabled/>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Xác nhận để làm thủ tục đổi thẻ nhà báo đối với trường hợp: </div>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Họ và tên: </span>
                  <div style={{width:'100%'}}>
                      <input tabIndex='1' type='text' value={editingData.HoTenKhaiSinh || ''} className='fullwidth-input' disabled/>
                  </div>
              </div>
              <div className='gender--item'>
                  <span className='gender--title'>Nam/Nữ: </span>
                  <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Năm sinh: </span>
                  <input tabIndex='1' type='text' value={ rxu.getDate(editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Thẻ nhà báo số: </span>
                  <input tabIndex='1' type='text' value={editingData.MaTheMoi || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Đã nộp thẻ nhà báo (trường hợp mất thẻ, chưa nộp lại thẻ ghi rõ lý do): </span>
                  <input tabIndex='1' type='text' value={editingData.MaTheCu || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Thời gian công tác tại cơ quan (từ ngày tháng năm đến ngày tháng năm): </span>
                  <input tabIndex='1' type='text' value={editingData.ThoiGianCongTac || ''} className='fullwidth-input' disabled />
              </div>
          </div>

        </div>
      </div>
    </div>
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Trong thời gian công tác: </div>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Việc hoàn thành nhiệm vụ được giao: </span>
                  <input tabIndex='1' type='text' value={ editingData.QuaTrinhHoatDong } className='fullwidth-input' disabled/>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Liên quan một hoặc các trường hợp quy định tại điểm a, b, c, d và đ khoản 3 Điều 27 Luật báo chí: </div>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Chức danh báo chí: </span>
                  <input tabIndex='1' type='text' value={ editingData.ChucDanhBaoChi || '' } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Chức vụ: </span>
                  <input tabIndex='1' type='text' value={ editingData.TenChucVu } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Về đạo đức nghề nghiệp của người làm báo (nếu đã bị xử lý vi phạm ghi rõ thời gian): </span>
                  <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Về kỷ luật (nếu đã bị xử lý kỷ luật từ khiển trách trở lên theo quy định của pháp luật về công chức, viên chức và pháp luật về lao động, ghi rõ thời gian và hình thức bị kỷ luật): </span>
                  <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong1 } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Liên quan đến các vụ án (bị kết án năm nào, đã được xóa án tích chưa, hiện có là đối tượng liên quan trong các vụ án chưa có kết luận của cơ quan nhà nước có thẩm quyền không): </span>
                  <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong2 } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Đã từng bị thu hồi thẻ nhà báo (ghi rõ thời gian, lý do): </span>
                  <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong3 } className='fullwidth-input' disabled/>
              </div>
          </div>


        </div>
      </div>
    </div>
    <div className='rx-form__body'>
      <div className='rx-form__body--title'>Thành phần hồ sơ khác</div>
      <div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Bản khai đăng ký {bankhai[editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                  <RxUploadCMND images={editingData.SoYeuLyLich} path={editingData.path} title='Sơ yếu lý lịch' disabled='1' />
              </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
              <RxUploadCMND images={editingData.BanSaoBangDHCD} path={editingData.path} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
            </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Scan bản chính hoặc bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
              <RxUploadCMND images={editingData.BanSaoHDLD} path={editingData.path} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
            </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Thành phần hồ sơ khác chứng minh thuộc đối tượng cấp thẻ (nếu có)</span>
                  <RxUploadCMND images={editingData.profileAttachments} path={editingData.path} title='Thành phần hồ sơ khác' disabled='1' />
              </div>
          </div>
          { editingData['DonXacNhanMatThe'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác), công an nơi mất thẻ</span>
                    <RxUploadCMND images={editingData.DonXacNhanMatThe} path={editingData.path} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' disabled='1' />
                </div>
            </div>
          }

          { editingData['DonXacNhanCapLaiThe'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác)</span>
                    <RxUploadCMND images={editingData.DonXacNhanCapLaiThe} path={editingData.path} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' disabled='1' />
                </div>
            </div>
          }

          { editingData['AnhScanTheHong'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Ảnh scan thẻ hỏng</span>
                    <RxUploadCMND images={editingData.AnhScanTheHong} path={editingData.path} title='Ảnh scan thẻ hỏng' disabled='1' />
                </div>
            </div>
          }

          { editingData['DonXacNhanCQCu'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Văn bản xác nhận của cơ quan cũ</span>
                    <RxUploadCMND images={editingData.DonXacNhanCQCu} path={editingData.path} title='Văn bản xác nhận của cơ quan cũ' disabled='1' />
                </div>
            </div>
          }

          { editingData['DonXacNhanCQMoi'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Văn bản đề nghị của cơ quan mới</span>
                    <RxUploadCMND images={editingData.DonXacNhanCQMoi} path={editingData.path} title='Văn bản đề nghị của cơ quan mới' disabled='1' />
                </div>
            </div>
          }

          { editingData['AnhScanTheCu'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Ảnh scan của thẻ nhà báo ở cơ quan cũ</span>
                    <RxUploadCMND images={editingData.AnhScanTheCu} path={editingData.path} title='Ảnh scan của thẻ nhà báo ở cơ quan cũ' disabled='1' />
                </div>
            </div>
          }

          { editingData['VanBanTiepNhanCTacMoi'] &&
            <div className='rx-form__item'>
                <div>
                    <span>Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động có chứng thực</span>
                    <RxUploadCMND images={editingData.VanBanTiepNhanCTacMoi} path={editingData.path} title='Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động có chứng thực' disabled='1' />
                </div>
            </div>
          }
          {/*}
          <div className='rx-form__item'>
              <div>
                  <span>Số điện thoại:</span>
                  <input type='text' value={editingData.phone_number || ''} className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Địa chỉ Email:</span>
                  <input type='text' value={editingData.email || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Phương thức tiếp nhận</span>
                  <input type='text' value={rxu.rxparseTen('reception', editingData.reception) || ''} className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Ngày tiếp nhận</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.reception_date) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngày hẹn trả</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.appointment_date) } className='fullwidth-input' disabled/>
              </div>
              <div>
                  <span>Ngày trả thực tế</span>
                  <input type='text' value={ rxu.parseTime(null, editingData.actual_date) } className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Phương thức trả kết quả</span>
                  <input type='text' value={rxu.rxparseTen('reception', editingData.method_result) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Tình trạng thanh toán</span>
                  <input value={rxu.rxparseTen('payment', editingData.payment) || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
            <div>
              <span>Thành phần hồ sơ đính kèm và kết quả giải quyết (nếu có)</span>
              <RxUploadCMND images={editingData.attach} title='' disabled='1' />
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  </div>
}

export const renderTimeline = (vm) =>
  <div className='renderTimeline'>
   {((vm.props.name.indexOf('Card') !== -1) && (rxu.get(vm.props.auth, ['user', 'user', 'role'])  === 'member')) &&
      <div className='ui-title-bar__actions'>
        <div className='progress-bar-order'>
          <div className={[3,4,5,6].indexOf(vm.state.editingData.IsStatus) !== -1  ? 'progress-bar-item2 active' : 'progress-bar-item2'}>
            <div className='progress-bar-item-step'>BƯỚC 1</div>
            <div className='progress-bar-item-title'>Hồ sơ đang được xử lý tại Cục</div>
            <div className='progress-bar-item-progress'>
              <div className='progress-bar-item-ico'>
                <div className='progress-bar-item-ico-in'></div>
              </div>
                <div className='progress-bar-item-line right'></div>
            </div>
          </div>

          <div className={[5,6].indexOf(vm.state.editingData.IsStatus) !== -1  ? 'progress-bar-item2 active right' : 'progress-bar-item2 right-item'} >
            <div className='progress-bar-item-step'>BƯỚC 2</div>
            <div className='progress-bar-item-title'>Hồ sơ đang được xử lý tại Bộ TTTT</div>
            <div className='progress-bar-item-progress'>
              <div className='progress-bar-item-line left'></div>
              <div className='progress-bar-item-ico'>
                <div className='progress-bar-item-ico-in'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
   {((vm.props.name.indexOf('Card') === -1) && (rxu.get(vm.props.auth, ['user', 'user', 'role']) === 'member')) &&
        <div className='ui-title-bar__actions'>
          <div className='progress-bar-order'>
            <div className={[3,4,5,6].indexOf(vm.state.editingDataReview.IsStatus) !== -1  ? 'progress-bar-item2 active' : 'progress-bar-item2'}>
              <div className='progress-bar-item-step'>BƯỚC 1</div>
              <div className='progress-bar-item-title'>Hồ sơ đang được xử lý tại Cục</div>
              <div className='progress-bar-item-progress'>
                <div className='progress-bar-item-ico'>
                  <div className='progress-bar-item-ico-in'></div>
                </div>
                  <div className='progress-bar-item-line right'></div>
              </div>
            </div>

            <div className={[5,6].indexOf(vm.state.editingDataReview.IsStatus) !== -1  ? 'progress-bar-item2 active right' : 'progress-bar-item2 right-item'} >
              <div className='progress-bar-item-step'>BƯỚC 2</div>
              <div className='progress-bar-item-title'>Hồ sơ đang được xử lý tại Bộ TTTT</div>
              <div className='progress-bar-item-progress'>
                <div className='progress-bar-item-line left'></div>
                <div className='progress-bar-item-ico'>
                  <div className='progress-bar-item-ico-in'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
       }
   {((vm.props.name.indexOf('Card') !== -1) && (rxu.get(vm.props.auth, ['user', 'user', 'role']) !== 'member')) &&
          <div className='ui-title-bar__actions'>
            <div className='progress-bar-order'>
              <div className={[0].indexOf(Number(vm.state.editingData.IsCheck1)) === -1  ? 'progress-bar-item active' : 'progress-bar-item'}>

                <div className='progress-bar-item-step'>Bước 1</div>
                <div className='progress-bar-item-title'>Cấp Chuyên viên</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingData.IsStatus > 0 && global.rxu.date(global.rxu.get(vm.state.editingData, ['LichSuDuyet', 0, 'created_at']))}</div>
              </div>
              <div className={[1,-1].indexOf(vm.state.editingData.IsCheck2) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 2</div>
                <div className='progress-bar-item-title'>Cấp Lãnh đạo Phòng</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingData.IsStatus > 1 && global.rxu.date(global.rxu.get(vm.state.editingData, ['LichSuDuyet', 1, 'created_at']))}</div>
              </div>

              <div className={[1,-1].indexOf(vm.state.editingData.IsCheck3) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 3</div>
                <div className='progress-bar-item-title'>Thẩm định Cấp Cục</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date' >{vm.state.editingData.IsStatus > 2 && global.rxu.date(global.rxu.get(vm.state.editingData, ['LichSuDuyet', 2, 'created_at']))}</div>
              </div>

              <div className={[1,-1].indexOf(vm.state.editingData.IsCheck4) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 4</div>
                <div className='progress-bar-item-title'>Thẩm định Cấp Bộ</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingData.IsStatus > 3 && global.rxu.date(global.rxu.get(vm.state.editingData, ['LichSuDuyet', 3, 'created_at']))}</div>
              </div>

              <div className={[1,-1].indexOf(vm.state.editingData.IsCheck5) !== -1  ? 'progress-bar-item active ' : 'progress-bar-item '} >
                <div className='progress-bar-item-step'>Bước 5</div>
                <div className='progress-bar-item-title'>{vm.state.editingData.IsStatus === 6 ? 'Trả hồ sơ' : (vm.state.editingData.IsStatus === 5 ? 'Đã duyệt in' : 'Chờ duyệt in')}</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingData.IsStatus> 4  && global.rxu.date(global.rxu.get(vm.state.editingData, ['LichSuDuyet', 4, 'created_at']))}</div>
              </div>
            </div>
          </div>
        }
   {((vm.props.name.indexOf('Card') === -1) && (rxu.get(vm.props.auth, ['user', 'user', 'role'])  !== 'member')) &&
          <div className='ui-title-bar__actions'>
            <div className='progress-bar-order'>
              <div className={[1,-1].indexOf(Number(vm.state.editingDataReview.IsCheck1)) !== -1  ? ([1].indexOf(Number(vm.state.editingDataReview.IsCheck1)) !== -1  ? 'progress-bar-item active' : 'progress-bar-item active nonactive' ) : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 1</div>
                <div className='progress-bar-item-title'>Cấp Chuyên viên</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingDataReview.IsStatus > 0 && global.rxu.date(global.rxu.get(vm.state.editingDataReview, ['LichSuDuyet', 0, 'created_at']))}</div>
              </div>
              <div className={[1,-1].indexOf(Number(vm.state.editingDataReview.IsCheck2)) !== -1  ? ([1].indexOf(Number(vm.state.editingDataReview.IsCheck2)) !== -1  ? 'progress-bar-item active' : 'progress-bar-item active nonactive' ) : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 2</div>
                <div className='progress-bar-item-title'>Cấp Lãnh đạo Phòng</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingDataReview.IsStatus > 1 && global.rxu.date(global.rxu.get(vm.state.editingDataReview, ['LichSuDuyet', 1, 'created_at']))}</div>
              </div>

              <div className={[1,-1].indexOf(Number(vm.state.editingDataReview.IsCheck3)) !== -1  ? ([1].indexOf(Number(vm.state.editingDataReview.IsCheck3)) !== -1  ? 'progress-bar-item active' : 'progress-bar-item active nonactive' ) : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 3</div>
                <div className='progress-bar-item-title'>Thẩm định Cấp Cục</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date' >{vm.state.editingDataReview.IsStatus > 2 && global.rxu.date(global.rxu.get(vm.state.editingDataReview, ['LichSuDuyet', 2, 'created_at']))}</div>
              </div>

              <div className={[1,-1].indexOf(Number(vm.state.editingDataReview.IsCheck4)) !== -1  ? ([1].indexOf(Number(vm.state.editingDataReview.IsCheck4)) !== -1  ? 'progress-bar-item active' : 'progress-bar-item active nonactive' ) : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 4</div>
                <div className='progress-bar-item-title'>Thẩm định Cấp Bộ</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingDataReview.IsStatus > 3 && global.rxu.date(global.rxu.get(vm.state.editingDataReview, ['LichSuDuyet', 3, 'created_at']))}</div>
              </div>

              <div className={[1,-1].indexOf(Number(vm.state.editingDataReview.IsCheck5)) !== -1  ? ([1].indexOf(Number(vm.state.editingDataReview.IsCheck5)) !== -1  ? 'progress-bar-item active' : 'progress-bar-item active nonactive' ) : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 5</div>
                <div className='progress-bar-item-title'>{vm.state.editingDataReview.IsStatus === 6 ? 'Trả hồ sơ' : (vm.state.editingDataReview.IsStatus === 5 ? 'Đã duyệt in' : 'Chờ duyệt in')}</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                </div>
                <div className='progress-bar-item-date'>{vm.state.editingDataReview.IsStatus> 4  && global.rxu.date(global.rxu.get(vm.state.editingDataReview, ['LichSuDuyet', 4, 'created_at']))}</div>
              </div>
            </div>
          </div>
      }
  </div>

export const popupChooseClone = (vm) => {
  return(
    <div className='popup__main'>
      <div className='popups' onClick={(e) => vm.onCancel(e)}></div>
      <div className='popup'>
        <div className='popup__body'>
          <div className='popup__main'>
            {vm.state.message && <span className="popup__message-err">{vm.state.message}</span>}
            <div className="popup__form-label">Lựa chọn loại hồ sơ:</div>
            <RxSelectbox name='statusPhanLoai' options={global.statusPhanLoai} onChange={(result) => { vm.onBlurStatusReviewClone(result, "phanLoai") }} optionDefault='--Chọn loại hồ sơ--' />
            {vm.state.statusPhanLoaiClone == 2 &&
            <>
              <div className="popup__form-label">Lựa chọn nguyên nhân cấp lại thẻ:</div>
              <RxSelectbox name='NgNhanCapDoi' options={global.NgNhanCapDoi} onChange={(result) => { vm.onBlurStatusReviewClone(result, "doiThe") }} optionDefault='--Chọn lý do cấp lại--' />
              { vm.state.ngNhanDoiThe > 0 &&
              <>
                { vm.state.ngNhanDoiThe == 2 &&
                  <div className='rx-form__item'>
                    <div>
                      <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác), công an nơi mất thẻ</span>
                      <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'DonXacNhanMatThe')} path={vm.state.editingData.path} images={vm.state.editingData?.DonXacNhanMatThe || ""} title='Ảnh đơn xác nhận của cơ quan' />
                    </div>
                  </div>
                }
                { vm.state.ngNhanDoiThe == 1 &&
                <>
                  <div className='rx-form__item'>
                    <div>
                      <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác)</span>
                      <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'DonXacNhanCapLaiThe')} path={vm.state.editingData.path} images={vm.state.editingData?.DonXacNhanCapLaiThe || ""} title='Ảnh đơn xác nhận của cơ quan' />
                    </div>
                  </div>
                  <div className='rx-form__item'>
                    <div>
                      <span>Scan thẻ bị hỏng</span>
                      <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'AnhScanTheHong')} path={vm.state.editingData.path} images={vm.state.editingData?.AnhScanTheHong || ""} title='Ảnh scan thẻ hỏng' />
                    </div>
                  </div>
                </>
                }
              </>
              }
            </>
            }

            {vm.state.statusPhanLoaiClone == 3 &&
            <>
              <div className="popup__form-label">Lựa chọn cơ quan chuyển tới:</div>
              <RxSelectbox
                name='cate'
                options={vm.state.cate}
                results={vm.state.editingDataPhase.TenCoQuanBaoChi || ''}
                onChange={(result) => { vm.onBlurDataValue(result, 'TenCoQuanBaoChi', 'TenCoQuanBaoChi') }}
                api='api_newspaper_office'
                optionDefault='--Chọn cơ quan báo chí--'
              />
              <div className='rx-form__item'>
                <div>
                  <span>Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động có chứng thực</span>
                  <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'VanBanTiepNhanCTacMoi')} path={vm.state.editingData.path} images={vm.state.editingData?.VanBanTiepNhanCTacMoi || ""} title='Ảnh văn bản tiếp nhận công tác mới' />
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span>Văn bản xác nhận của cơ quan cũ</span>
                  <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'DonXacNhanCQCu')} path={vm.state.editingData.path} images={vm.state.editingData?.DonXacNhanCQCu || ""} title='Ảnh đơn xác nhận của cơ quan cũ' />
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span>Văn bản đề nghị của cơ quan mới</span>
                  <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'DonXacNhanCQMoi')} path={vm.state.editingData.path} images={vm.state.editingData?.DonXacNhanCQMoi || ""} title='Ảnh đơn xác nhận của cơ quan mới' />
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span>Scan thẻ cơ quan cũ</span>
                  <RxUploadCMND callback={(e) => vm.callbackUploadDetail(e, 'AnhScanTheCu')} path={vm.state.editingData.path} images={vm.state.editingData?.AnhScanTheCu || ""}  title='Ảnh scan thẻ cơ quan cũ' />
                </div>
              </div>
            </>
            }
            {/*
            Nếu chọn cấp lại hoặc cấp đổi
            - nếu là cấp lại
            + Cấp lại do hỏng thẻ
            + cấp lại do mất thẻ - lý do mất thẻ
            - nếu là cấp đổi thẻ(do chuyển cơ quan) --> tạo ra một nút đổi cơ quan
            + chọn cơ quan chuyển tới
            + văn bản tiếp nhận của cơ quan mới
            + bản scan văn bản của cơ quan mới
            + bản scan văn bản của cơ quan cũ
            + bản scan thẻ cơ quan cũ
            + danh sách tác phẩm

             */}
            <div className="popup__areaButton">
              <span className='adform__btncancel adform__btn--save btn' onClick={(e) => vm.onClickDataCloneCard(e,false)}>Xác nhận</span>
              <span className='adform__btncancel betable__btnexport btn' onClick={(e) => vm.onCancel(e)}>Hủy</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const popupCreateNewDataCapLai = (vm) => {
  return(
  <div>
    <div className='popup__main'>
      <div className='popups' onClick={(e) => vm.onCancel(e)}></div>
      <div className='popup'>
        <div className='popup__body'>
          <div className='popup__main'>
            {vm.state.message && <span className="popup__message-err">{vm.state.message}</span>}
            <div className='rx-form__item'>
                <div>
                  <span>Chọn cơ quan báo chí: </span>
                  <RxSelectbox
                    name='cate'
                    options={vm.state.cate}
                    results={vm.state.editingDataPhase.TenCoQuanBaoChi || ''}
                    onChange={(result) => { vm.onBlurDataValue(result, 'TenCoQuanBaoChi', 'TenCoQuanBaoChi') }}
                    api='api_newspaper_office'
                    optionDefault='--Chọn cơ quan báo chí--'
                  />
                  <span>Nhập số thẻ nhà báo kỳ hạn 2021-2025: </span>
                  <input tabIndex='1' type='text' value={ vm.state.maTheTimKiem } onChange={(e) => vm.onBlurStatusReviewClone(e, "maThe")} className='fullwidth-input' />
                </div>
            </div>
            <div style={{marginLeft: "33%"}}>
              <div className="adform__btn--save" style={{width: "120px", textAlign: 'center', display: "inline-block"}} onClick={(e) => vm.findCheckCardExits(e)}>Tìm kiếm</div>
              { vm.state.showBtnCreateNew && <div className="adform__btn--save" style={{width: "120px", textAlign: 'center', display: "inline-block"}} onClick={(e) => {vm.onClickDataNew(e); vm.onCancel(e)}}>Thêm mới</div> }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )}

export const popupCreateNewDataCapDoi = (vm) => {
  return(
  <div>
    <div className='popup__main'>
      <div className='popups' onClick={(e) => vm.onCancel(e)}></div>
      <div className='popup'>
        <div className='popup__body'>
          <div className='popup__main'>

            {/*}<span className="news_inputcheck_radio" style={{marginLeft: '15px'}}>
              <input  type="checkbox" className="css-checkboxShow" name="Đã có thẻ nhà báo 2021 - 2025" value="Đã có thẻ nhà báo 2021 - 2025" onChange={(e) => {vm.onBlurStatusReviewClone(e,'checkCoThe')}} checked={vm.state.checkHadCard}/>
            </span>*/}

            {vm.state.message && <span className="popup__message-err">{vm.state.message}</span>}
            {vm.state.checkHadCard &&
              <div className='rx-form__item'>
                <div>
                  <span>Chọn cơ quan báo chí: </span>
                  <RxSelectbox
                    name='cate'
                    options={vm.state.cate}
                    results={vm.state.editingDataPhase.TenCoQuanBaoChi || ''}
                    onChange={(result) => { vm.onBlurDataValue(result, 'TenCoQuanBaoChi', 'TenCoQuanBaoChi') }}
                    api='api_newspaper_office'
                    optionDefault='--Chọn cơ quan báo chí--'
                  />
                  <span>Nhập số thẻ nhà báo kỳ hạn 2021-2025: </span>
                  <input tabIndex='1' type='text' value={ vm.state.maTheTimKiem } onChange={(e) => vm.onBlurStatusReviewClone(e, "maThe")} className='fullwidth-input' />
                </div>
              </div>
            }
            <div style={{marginLeft: "33%"}}>
              { vm.state.checkHadCard &&<div className="adform__btn--save" style={{width: "120px", textAlign: 'center', display: "inline-block"}} onClick={(e) => vm.findCheckCardExits(e)}>Tìm kiếm</div>}
              { vm.state.checkHadCard == false && <div className="adform__btn--save" style={{textAlign: 'center', display: "inline-block"}} onClick={(e) => {vm.onBlurStatusReviewClone(e,'checkCoThe')}}>Đã có thẻ nhà báo kỳ hạn 2021 - 2025</div> }
              { vm.state.checkHadCard == false && <div className="adform__btn--save" style={{textAlign: 'center', display: "inline-block"}} onClick={(e) => {vm.onClickDataNew(e); vm.onCancel(e)}}>Chưa có thẻ nhà báo kỳ hạn 2021 - 2025</div> }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )}

export const renderComponent = (vm) =>
  <div>
    {vm.state.errMsg && vm.state.msg.success && vm.run('renderToast')}
    <div className='betable adblock__inner'>
      {vm.state.showPopup && vm.run('showPopup')}
      {vm.state.showInput && vm.run('showInputEmail')}
      {vm.state.showPhase && vm.run('showPhase')}
      {vm.state.showPosition && vm.run('showPosition')}
      {vm.state.showHistory && vm.run('showLichSuDuyet')}
      {vm.run('renderFilter')}
      <div className='adform__toggle'>
        {vm.run('renderPagin')}
        {vm.run('renderTable')}
        {vm.run('renderPagin')}
      </div>
      {vm.props.name === 'print' && vm.run('renderBtnPrint')}
    </div>
    { vm.run('renderForm')}
    { vm.state.printCard === true && vm.state.view === true && vm.state.editingDataReview && vm.state.editingDataReview['_id'] &&<RxPrintCard editingData={vm.state.editingDataReview} typePrint={vm.state.typePrint} fontPrint={vm.state.fontPrint || {}} /> }
    { vm.state.printCard === true && vm.state.editingDataPrint && vm.state.editingDataPrint.length > 0 && !vm.state.view &&  <RxPrintCard editingData={vm.state.editingDataPrint} typePrint={vm.state.typePrint} onResult={(result) => vm.onResultPrintCard(result)} fontPrint={vm.state.fontPrint || {}} /> }
    { vm.state.showPopupClone && vm.run('popupChooseClone')}
    { vm.state.showPopupNewDataCapLai && vm.run('popupCreateNewDataCapLai')}
    { vm.state.showPopupNewDataCapDoi && vm.run('popupCreateNewDataCapDoi')}
  </div>
