import React, { Component } from 'react'
import { connect } from 'react-redux'
import { apipost } from './../../classes/request'
const { rxnavChange, logoutAction } = global.rootRequire('redux')


class passwordComponent_ extends Component {
  constructor (props) {
    super(props)
    this.mounted = true
    this.state = {
      editingData: {},
      msg: '',
      showPopup: false
    }
  }

  componentDidMount () { this._ismounted = true }
  componentWillUnmount () { this._ismounted = false }

//   fetchData () {
//     if(this.props.auth.user.user){
//         apiget(global.rxu.config.api_user_find, {_id: this.props.auth.user.user._id}, { '1': (json) => { this.setState({ data: json.data, editingData: { _id: json.data._id} }, () => {}) } })
//     }
//   }
  run(name, params) {
    if (params) {
        params.inthis = this
    } else {
        params = this
    }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
        return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
        return this[name]()
    }
  }  

  onBlurData(e, name) {
    let editingData = this.state.editingData
    editingData[name] = e.target.value
    this.setState({
        editingData: editingData
    })
    }

  onUpdateData() {
    let editingData = this.state.editingData;
    if(this.state.editingData.passwordNew === this.state.editingData.repassword){
        apipost(global.rxu.config.api_user_changepassword, editingData, {
            '1': (json) => {
                this.fetchData(true)
                this.setState({status: json.success})
            }
        })
    }else {
    }
}
  onClickShowPopup() {
    this.setState({showPopup: true})
  }

  render () {
    return (<div>
        <div className='rx-authpage'>
          <div className='authform-register'>
            <div className='register-form'>
              <div className='authform__title'><span>Khôi phục mật khẩu</span></div>
              <p className='authform_msgerror'>{this.state.msg}</p>
              <div className='rx-form__item'>
                <div>
                  <span>Email cơ quan báo chí </span>
                  <input tabIndex='1' type='text' placeholder='Nhập email cơ quan báo chí' className='fullwidth-input'  value={this.state.editingData.email ? this.state.editingData.email : ''} onChange={(e) => this.onBlurData(e, 'email')} />
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span>Nhập mật khẩu mới </span>
                  <input tabIndex='2' type='password' placeholder='Nhập mật khẩu mới' className='fullwidth-input'  value={this.state.editingData.passwordNew ? this.state.editingData.passwordNew : ''}  onChange={(e) => this.onBlurData(e, 'passwordNew')} />
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span>Nhập lại mật khẩu mới </span>
                  <input tabIndex='3' type='password' placeholder='Nhập lại mật khẩu mới' className='fullwidth-input' value={this.state.editingData.repassword ? this.state.editingData.repassword : ''} onChange={(e) => this.onBlurData(e, 'repassword')} />
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span />
                  <div>
                    <span tabIndex='4' className='authform__btnsubmit register' onClick={() => {this.onUpdateData()}}>Khôi phục mật khẩu</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
  }
}
const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav, 
  user: state.user
  })
  
  const mapDispatchToProps = {
    rxnavChange,
    logoutAction
  }
  
  
  const passwordComponent = connect(
    mapStateToProps,
    mapDispatchToProps
  )(passwordComponent_)

export default passwordComponent
