/* global rxu */
import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default

export const renderTableHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className='rxwidth--100'>STT</th>
    <th className={inthis.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'name')}>Tên đợt</th>
    <th className={inthis.helpSortClass('CoQuanBaoChi', ['rx-th-width-220'])}>Cơ quan báo chí</th>
    <th className={inthis.helpSortClass('status', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'status')}>Trạng thái</th>
    <th className={inthis.helpSortClass('total', ['rx-th-width-220'])}>Số lượng</th>

    <th className={inthis.helpSortClass('created_at', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>
    <th className={inthis.helpSortClass('updated_at', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Cập nhật</th>
    <th className='rxwidth--100'></th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map((perdata,index) => (<tr className='rx-xursor' key={perdata._id} onClick={(e) => inthis.onClickDataEdit(e, perdata)}>
    <td>{index + 1}</td>
    <td className='rx-cursor' onClick={(e) => inthis.onClickViewData(e, perdata)}>{perdata.name}</td>
    <td className='rx-cursor' onClick={(e) => inthis.onClickViewData(e, perdata)}>{perdata.TenCoQuanBaoChi}</td>
    <td>{(perdata.submit === 1 && perdata.status === 0) ? 'Đã trình HS' : perdata.submit === 2 ? 'Đã trình lên LĐB 1 phần' : global.statusTinhtrang[perdata.status]}</td>
    <td>{perdata.number}</td>

    <td>{rxu.getDate(perdata['created_at'])}</td>
    <td>{rxu.getDate(perdata['updated_at'])}</td>
    <td></td>

  </tr>))
export const renderComponent = (vm) =>
  <AdminBlock name='THẨM ĐỊNH CẤP CỤC' desc='Hồ sơ thẩm định cấp Cục'>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
      <RxCrudCard title='Thẩm định cấp Cục' renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='review_3' api={'api_card_review'} idEdit={vm.state.idEdit}
        form={[
            { type: 'header', func: (inthis, vm) => (
                <div className='rx-form__header'>
                    <div className='form-header-title'>
                        <div className='text-center title-header'>TỔNG HỢP DANH SÁCH ĐỀ NGHỊ CẤP THẺ NHÀ BÁO</div>
                        <div className='text-center title-slug'>Kỳ hạn: 2021 - 2025 (1)</div>
                        <div className='rx-form__info'>
                            <div className='text-left'><span>Cơ quan đề nghị cấp thẻ nhà báo: </span><b>{global.rxu.get(inthis.state.editingData, ['TenCoQuanBaoChi'], '')}</b></div>
                            <div className='text-left'><span style={{minWidth:'100px'}}>+ Điện thoại: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'DienThoai'], '')}</b></div>
                            <div className='text-left'><span>+ Địa chỉ: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'DiaChi'], '')}</b></div>
                            <div className='text-left'><span style={{minWidth:'100px'}}>+ Fax: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'Fax'], '')}</b></div>
                            <div className='text-left'><span>Cơ quan chủ quản: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'CoQuanChuQuan'], '')}</b></div>
                            <div className='text-left'><span style={{minWidth:'100px'}}>+ Điện thoại: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'DienThoaiCoQuanChuQuan'], '')}</b></div>
                            <div className='text-left'><span>+ Địa chỉ: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'DiaChiCoQuanChuQuan'], '')}</b></div>
                            <div className='text-left'><span style={{minWidth:'100px'}}>+ Fax: </span><b>{global.rxu.get(inthis.state.editingData, ['CoQuanBaoChiObj', 'FaxCoQuanChuQuan'], '')}</b></div>
                            <div className='text-left'><span>+ Số hồ sơ đã thẩm định: </span><b>{global.rxu.get(inthis.state.numCheck, ['check3'], 0)}</b></div>
                            <div className='text-left'><span style={{minWidth:'100px'}}>+ Số hồ sơ chưa thẩm định: </span><b>{global.rxu.get(inthis.state.editingData, ['dataCard'], []).length - global.rxu.get(inthis.state.numCheck, ['check3'], 0) > 0 ? global.rxu.get(inthis.state.editingData, ['dataCard'], []).length - global.rxu.get(inthis.state.numCheck, ['check3'], 0) : 0 }</b></div>
                            <div className='col-md-2 custom-search'>
                              <input tabIndex='4' type='text' onChange={(e) => inthis.onBlurDataFilterNameSubmit(e)} className='fullwidth-input' placeholder='Tìm kiếm ...' />
                            </div>
                            <div className='col-md-2 custom-search'>
                              <span className='betable__btnimport btn' onClick={(e) => inthis.onBlurDataFilterNoncheck('uncheck')}>Hồ sơ chưa duyệt</span>
                              <span className='betable__btnimport btn' onClick={(e) => inthis.onBlurDataFilterNoncheck('check')}>Hồ sơ đã duyệt</span>
                              <span className='betable__btnimport btn' onClick={(e) => inthis.onBlurDataFilterNoncheck('return')}>Hồ sơ bị trả lại</span>
                            </div>
                        </div>
                        <div className='rx-list__card'>
                            <div className='rxlist__card-table'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className='rx-table__form-header' rowSpan='3'>
                                                {/*<span id="HoSoChoIn" style={{marginLeft: '15px', width: '40px'}}>
                                                    <input onClick={(e)=> inthis.checkAll(e)}  type='checkbox' className='css-checkbox' id='HoSoID-All' />
                                                    <label className='css-label' htmlFor='HoSoID-All' data-toggle='tooltip' data-placement='auto' title='' data-original-title='Tất cả'></label>
                                                </span>*/}
                                            </td>
                                            <td className='rx-table__form-header' rowSpan='3'>Số TT</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_office' rowSpan='3'>Họ và tên</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Số giấy chứng minh nhân dân/thẻ căn cước công dân</td>
                                            <td className='rx-table__form-header table--name_small' rowSpan='3'>Giới tính</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Ngày, tháng, năm sinh</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Quê quán</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Dân tộc</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_office' rowSpan='3'>Nơi ở hiện nay</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Trình độ giáo dục phổ thông</td>
                                            <td className='rx-table__form-header rxwidth--100' colSpan='5'>Trình độ chuyên môn</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Trình độ lý luận chính trị</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Trình độ ngoại ngữ</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Đảng viên</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Bậc lương hiện nay</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Chức vụ</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Chức danh báo chí</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Ngày, tháng, năm ký HĐLĐ(4) có đóng BHXH(5)/Số sổ BHXH</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Số thẻ nhà báo đã cấp (nếu có)</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Bút danh</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Quá trình thẩm định</td>
                                        </tr>
                                        <tr>
                                            <td className='rx-table__form-header' colSpan='2'>Báo chí</td>
                                            <td className='rx-table__form-header' colSpan='2'>Chuyên ngành khác</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='2'>Hình thức đào tạo</td>
                                        </tr>
                                        <tr>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>SĐH(2)</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>ĐH(3)</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>SĐH(2)</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>ĐH(3)</td>
                                        </tr>
                                        {inthis.state.editingData['dataCard'] && inthis.state.editingData['dataCard'].map((obj, index) => {
                                            obj.class = obj.IsCheck3 === 1 ? 'active' : obj.IsCheck3 === -1 ? 'unactive' : ''
                                            let history = obj.LichSuDuyet ? obj.LichSuDuyet[obj.LichSuDuyet.length - 1] : {}
                                            return <tr key={ index }>
                                                <td>
                                                    <span className={'rs-checkbox-payment-small ' + obj.class} style={{display: 'inline-block'}}>
                                                        <span className={[-1, 1].indexOf(obj.IsCheck3) !== -1 ? 'iconcustom-active' : ''} ></span>
                                                    </span>
                                                    {/*<span className="news_inputcheck_radio" style={{marginLeft: '15px'}}>
                                                        <input type="checkbox" className="css-checkboxShow" dataid={obj._id} id="IDHoSo-233" value={obj._id} onClick={(e) => inthis.checkObject(e)} />
                                                    </span>*/}
                                                </td>
                                                <td>{ index+1 }</td>
                                                <td className='rx-cursor textuppercase' onClick={(e) => inthis.onClickViewData(e, obj, 'review_1')}>{ obj.HoTenKhaiSinh || '' }</td>
                                                <td>{ obj.CMND || '' }</td>
                                                <td>{rxu.rxparseTen('GioiTinh', obj.GioiTinh) || '' }</td>
                                                <td>{ rxu.getDate(obj.NgaySinhConvert) }</td>
                                                <td>{ obj.QueQuan || '' }</td>
                                                <td>{ obj.DanToc || '' }</td>
                                                <td>{ obj.NoiO || '' }</td>
                                                <td>{ obj.TrinhDoVanHoa || '' }</td>
                                                {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) !== -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) === -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                                {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) !== -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) !== -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                                {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) === -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) === -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                                {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) === -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) !== -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                                <td>{ rxu.ArrayToString(obj.HinhThucDaoTao) || '' }</td>
                                                <td>{ obj.ChinhTri || '' }</td>
                                                <td>{ obj.NgoaiNgu || '' }</td>
                                                <td>{ obj.DangVien || '' }</td>
                                                <td>{ rxu.getQuaTrinhHoatDongUpdate(rxu.get(obj, 'QuaTrinhHoatDongUpdate', [])) || '' }</td>
                                                <td>{ obj.TenChucVu || '' }</td>
                                                <td>{ obj.ChucDanhBaoChi || '' }</td>
                                                <td>{ obj.ThongTinHDLD || '' }</td>
                                                <td>{ obj.MaTheCu || '' }</td>
                                                <td>{ obj.ButDanh || '' }</td>
                                                <td><div className='rxtext__history' onClick={(e) => inthis.onClickShowHistory(obj.LichSuDuyet)}>{ rxu.get(history,['desc']).length > 40 ? rxu.get(history,['desc']).slice(0, 40) + '...' : rxu.get(history,['desc']) }</div></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )},
            // {type: 'form', name: '', func: (inthis) => (
            //     <div className='rx-form__sign'>
            //         <div className="rx-form__sign__item" style={{marginLeft: '15px'}}>
            //             <span className='rx-form__sign-check'/>
            //             <span>Lãnh đạo đơn vị báo chí</span>
            //         </div>
            //         <div className="rx-form__sign__item" style={{marginLeft: '15px'}}>
            //             <span className='rx-form__sign-check'/>
            //             <span>Lãnh đạo cục</span>
            //         </div>
            //         <div className="rx-form__sign__item" style={{marginLeft: '15px'}}>
            //             <span className='rx-form__sign-check iconcustom-check'/>
            //             <span>Lãnh đạo bộ thông tin truyền thông</span>
            //         </div>
            //     </div>
            // )},
            {type: 'form', name: 'Lưu ý', func: (inthis) => (
                <div>
                    <p>Bản tổng hợp danh sách đề nghị cấp thẻ nhà báo phải tổng hợp đầy đủ các yêu cầu trong các mục, nếu thiếu một mục sẽ bị coi là không hợp lệ.</p>
                    <p><b>Ghi chú:</b></p>
                    <p>(1) Kỳ hạn: Ví dụ: 2021 - 2025.</p>
                    <p>(2) SĐH: Sau đại học.</p>
                    <p>(3) ĐH: Đại học. </p>
                    <p>(4) HĐLĐ: Hợp đồng lao động.</p>
                    <p>(5) BHXH: Bảo hiểm xã hội.</p>
                </div>
            )}
        ]}
      />
    </div>
  </AdminBlock>
