/* global t */
import React from 'react'
// import Select from 'react-select';

global.isclient && require('jsoneditor/dist/jsoneditor.css')
let JSONEditor = global.isclient ? require('jsoneditor') : {}

const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const rxu = global.rxu

// T A B L E
export const renderTable = (vm) =>
  <MainTable><thead>{vm.run('renderTableHead')}</thead><tbody>{vm.run('renderTableBody')}</tbody></MainTable>

export const renderTableHead = (vm) =>
  <tr>
    <th className='rxwidth--100'>Pic</th>
    <th className={vm.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => vm.onClickSort(e, 'name')}>Name</th>
    <th className={vm.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => vm.onClickSort(e, 'created_at')}>Created at</th>
    <th className='rxwidth--100'>Action</th>
  </tr>

export const renderTableBody = (vm) => global.rxu.array(vm.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td>{perdata.name}</td>
    <td><small>{global.rxu.date(perdata.created_at)}</small></td>
    {vm.renderTableBodyBtns(vm, perdata)}
  </tr>)

export const renderTableBodyBtns = (context, perdata, vm) => {
  return <td>
    { context.state.paging.search_is_deleted !== 1 &&
    <div> {(perdata.is_hot) ? <span className='betable__icohot'><span className='icon-fire' />Hot</span> : <span className='betable__iconothot' />}
      {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
      <span className='betable__btnedit' onClick={(e) => context.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
      <span className='betable__btndelete' onClick={(e) => context.onClickDataDelete(e, perdata)}><i className='icon-close' /></span> </div> }
    { context.state.paging.search_is_deleted === 1 &&
    <div> <span className='betable__restore' onClick={(e) => context.onClickDataRestore(e, perdata)}>Restore</span></div>}
  </td>
}

// P A G I N
export const renderPagin = (vm) => {
  let result = []
  let paging = vm.state.paging
  let listData = global.rxu.get(vm.state.extra, 'count', paging.pg_size)
  let total = 0
  if ((listData / paging.pg_size) > parseInt(listData / paging.pg_size,10)) {
    total = parseInt(listData / paging.pg_size,10) + 1
  } else {
    total = parseInt(listData / paging.pg_size,10)
  }
  if (paging.pg_page === 1) {
  } else {
    result.push(<div key={'first'} className='rxpage adtable__paginback adtable__paginfirst icomoon-Start' onClick={(e) => vm.onclickFirst(e)}></div>)
    result.push(<div key={'back'} className='rxpage adtable__paginback adtable__paginnext__active' onClick={(e) => vm.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>)
  }
  if (total > 5) {
    if (paging.pg_page === 1) {
      for (let i = 1; i <= (total); i++) {
        if (i < 4) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === 4) {
          result.push(<div key={i} className='adtable__pagingMore' >...</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
        }
      }
    } else {
      for (let i = 1; i <= (total); i++) {
        if (i === paging.pg_page - 1) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        } else if ((i === paging.pg_page) || (i === paging.pg_page + 1)) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === total) {
          result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)} >{i}</div>)
        } else if (i === paging.pg_page + 2) {
          result.push(<div key={i} className='rxpage adtable__pagingMore' >...</div>)
        }
      }
    }
  } else if (total === 0) {
    result.push(<div key={'none'} className='rxpage'>{paging.pg_page}</div>)
  } else {
    for (let i = 1; i <= (total); i++) {
      result.push(<div key={i} className={paging.pg_page === i ? 'rxpage rxactive' : 'rxpage'} onClick={(e) => vm.onClickPaginCur(i)}>{i}</div>)
    }
  }
  if (paging.pg_page < total) {
    result.push(<div key={'next'} className='rxpage adtable__paginnext adtable__paginnext__active' onClick={(e) => vm.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>)
    result.push(<div key={'last'} className='rxpage adtable__paginnext adtable__paginlast icomoon-End' onClick={(e) => vm.onclickLast(e)}></div>)
  }
  let start = (Number(paging.pg_page) - 1)*Number(paging.pg_size) + 1
  let end = Number(paging.pg_page) * Number(paging.pg_size)
  if(end > global.rxu.get(vm.state.extra, 'count', 0)) { end = global.rxu.get(vm.state.extra, 'count', 0) }

  return (<div className='rxpagingwrap'>
    <div className='rxpagingcount'>{start} - {end} trong tổng số <b>{global.rxu.get(vm.state.extra, 'count', 0)}</b></div>
    <div className='rxpaging'>
      <div className='rxpagesize'>
        <span>{t('Pagesize')}</span>
        <select value={paging.pg_size} onChange={e => vm.onChangePageSize(e)}>
          {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
        </select>
      </div>
      {result}
    </div>
  </div>)
}

// const filterOptions = [
//   {value: 'Name', label: 'Họ tên'},
//   // {value: 'Code', label: 'Mã thẻ'},
//   {value: 'CoQuanBaoChi', label: 'Cơ quan báo chí'},
//   {value: 'LoaiHoSo', label: 'Loại hồ sơ'},
//   {value: 'ChucVu', label: 'Chức vụ'},
//   // {value: 'Tuoi', label: 'Độ tuổi'},
//   {value: 'LoaiThe', label: 'Thẻ đã cấp'}
// ]

// F I L T E R
export const renderFilter = (vm) =>
  <div>
    <div className='betable__filter'>
      <div className='col-md-12'>

        <div className='col-md-3 custom-search'>
          <input tabIndex='3' type='text' value={vm.state.paging['search_fullname']} onChange={(e) => vm.onBlurDatafilter(e, 'fullname')} className='fullwidth-input' placeholder='Fullname' />
        </div>

        <div className='col-md-3 custom-search'>
          <input tabIndex='3' type='text' value={vm.state.paging['search_content']} onChange={(e) => vm.onBlurDatafilter(e, 'content')} className='fullwidth-input' placeholder='Nội dung' />
        </div>

        <div className='col-md-3 custom-search'>
          <input tabIndex='3' type='text' value={vm.state.paging['search_action']} onChange={(e) => vm.onBlurDatafilter(e, 'action')} className='fullwidth-input' placeholder='action' />
        </div>

        <div className='col-md-3'>
          <input tabIndex='4' type='text' value={vm.state.paging['search_name']} onChange={(e) => vm.onBlurDatafilter(e, 'name')} className='fullwidth-input' placeholder='Nơi thao tác' />
        </div>

        <div className='col-md-3'>
          <input tabIndex='4' type='text' value={vm.state.paging['searchid_username']} onChange={(e) => vm.onBlurDatafilter(e, 'username')} className='fullwidth-input' placeholder='ID cơ quan' />
        </div>

        <div className='col-md-3'>
          <input tabIndex='4' type='text' value={vm.state.paging['search_ip']} onChange={(e) => vm.onBlurDatafilter(e, 'ip')} className='fullwidth-input' placeholder='Địa chỉ ip' />
        </div>

        <div className='col-md-3'>
          <input tabIndex='4' type='text' value={vm.state.paging['search_TenCoQuanBaoChi']} onChange={(e) => vm.onBlurDatafilter(e, 'TenCoQuanBaoChi')} className='fullwidth-input' placeholder='TenCoQuanBaoChi' />
        </div>

        {vm.state.filter.CoQuanBaoChi &&
          <div className='col-md-3'>
            <RxSelectbox
                name='cate'
                options={vm.state.cate}
                results={vm.state.paging['search_TenCoQuanBaoChi'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'TenCoQuanBaoChi', 1) }}
                api='api_newspaper_office'
                optionDefault='--Chọn cơ quan báo chí--'
                loader='load'
            />
          </div>
        }

        {vm.state.filter.ChucVu &&
          <div className='col-md-3'>
            <RxSelectbox
                name='cate'
                options={vm.state.position}
                results={vm.state.paging['search_TenChucVu'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'TenChucVu', 1) }}
                api='api_position'
                optionDefault='--Nhóm chức vụ--'
                loader='load'
            />
          </div>
        }

        {vm.state.filter.LoaiHoSo &&
          <div className='col-md-3'>
            <RxSelectbox
                name='typeHS'
                options={global.typeHS}
                results={vm.state.paging['search_Type'] || ''}
                onChange={(result) => { vm.onBlurDatafilter(result, 'Type', 1) }}
                optionDefault='--Chọn loại hồ sơ--'
            />
          </div>
        }

      {/* </div> */}
      {/* <div className='col-md-12'> */}

        {vm.state.filter.Tuoi &&
          <div className='col-md-4'>
            <input tabIndex='1' type='text' value={vm.state.paging['search_TuTuoi']} onChange={(e) => vm.onBlurDatafilter(e, 'TuTuoi')} className='fullwidth-input' placeholder='Từ tuổi' />
          </div>
        }

        {vm.state.filter.Tuoi &&
          <div className='col-md-4'>
            <input tabIndex='2' type='text' value={vm.state.paging['search_DenTuoi']} onChange={(e) => vm.onBlurDatafilter(e, 'DenTuoi')} className='fullwidth-input' placeholder='Đến tuổi' />
          </div>
        }


        {/* {vm.state.filter.Code &&
          <div className='col-md-4'>
            <input tabIndex='3' type='text' value={vm.state.paging['search_MaTheCu']} onChange={(e) => vm.onBlurDatafilter(e, 'MaTheCu')} className='fullwidth-input' placeholder='Mã thẻ' />
          </div>
        } */}

        {/*<div className='col-md-3 margin-10'>create</div>
        <div className='col-md-3 margin-10'>create</div>*/}

      </div>
    </div>
    {vm.props.name.indexOf('permission') !== -1 && <div className='adblock__btngen' onClick={(e) => vm.onClickGenPermission()}>Generate permissions</div>}
  </div>

// F I N D  E X P A N D
export const renderFindExpand = (vm) => {
  let filterFields = rxu.get(vm.state, ['temp', 'filterFields'])
  let filterEles = []
  for (let i in filterFields) {
    filterEles.push(
      <div className='col-md-6' key={i}>
        <div className='betable__findlabel'>Find by {i}</div>
        <input className='betable__findinput betable__findinput-full' type='text' placeholder={'vd: ' + filterFields[i]} onKeyUp={(e) => vm.onBlurDatafilter(e, i)} />
      </div>)
  }
  return <div className='rxfull'>
    {filterEles}
  </div>
}

// F O R M
export const renderForm = (vm) => {
  if (vm.state.editingData.created_at || vm.state.editingData.created_at === 0) {
    global.scrollfire()
    return <div className='adform'>
      <div className='adform__header'>
        {vm.run('renderFormHead')}
      </div>
      <div className='adform__body' style={{marginBottom: '100px'}}>
        {vm.run('renderFormBody')}
      </div>
      <div className='adform__footer'>
        {vm.run('renderFormBtns')}
      </div>
    </div>
  }
}

let renderFormBodyDefault = (vm) =>
  [ { name: 'Picture', func: () => (<RxUpload callback={(e) => vm.callbackUpload(e)} images={vm.state.editingData.img_landscape} />) },
    { type: 'devide' },
    { name: 'Name', func: () => (<input tabIndex='1' type='text' value={vm.state.editingData.name} onChange={(e) => vm.onBlurData(e, 'name')} className='fullwidth-input' />) },
    { name: 'Desc', func: () => (<input tabIndex='2' type='text' value={vm.state.editingData.desc} onChange={(e) => vm.onBlurData(e, 'desc')} className='fullwidth-input' />) },
    { type: 'devide' },
    { name: 'Status', func: () => (<RxToggle value={vm.state.editingData.is_active} onToggle={(newValue) => vm.onBlurDataValue(newValue, 'is_active')} />) } ]

export const renderFormBody = (vm) => {
  let tempform = vm.props.form || renderFormBodyDefault(vm)

  if ((vm.state.editingData.created_at || vm.state.editingData.created_at === 0) && tempform) {
    let form = tempform.map((perdata, index) => {
      perdata.type = perdata.type || 'input'
      let result = (<div />)
      switch (perdata.type) {
        case 'input':
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'fullinput':
          result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
        case 'devide':
          result = <div key={index} className='fullwidth-formdevide cleafix' />
          break
        case 'header':
          result = <div className='form-header' key={index}>{perdata.func(vm)}</div>
          break
        case 'form':
          result = <div key={index} className='rx-form__body'><div className='rx-form__body--title'>{perdata.name}</div><div>{perdata.func(vm)}</div></div>
          break
        default:
          result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(vm)}</div>
          break
      }
      return result
    })
    return form
  }
}

export const renderFormHead = (vm) =>
  <div className='adform__name'>Thông tin
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
    </div>
  </div>

export const renderFormRaw = (vm) => {
  if (vm.state.editingData._id) {
    if (vm.state.flags.rawedit) {
      setTimeout(() => {
        vm.jsoneditor = new JSONEditor(vm.jsonEditor, { mode: 'tree' })
        vm.jsoneditor.set(vm.state.editingData)
      }, 500)
    }

    return (
      <div className='clearfix adform__rawedit'>
        <div onClick={evt => vm.toggle('rawedit')} className='adform__raweditbtn'>RawEdit</div>
        { vm.state.flags.rawedit ? <div>
          <div className='adform__raweditcontent' ref={e => { vm.state.jsoneditor = e }} />
          <div tabIndex='11' className='adform__btnedit adform__btnedit--raw' onClick={(e) => vm.onClickDataUpdateSubmitRaw(e)} onKeyPress={(e) => vm.onClickDataUpdateSubmitRaw(e)}>RawUpdate</div>
        </div> : <div /> }
      </div>
    )
  }
}

export const renderFormBtns = (vm) =>
  <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
    <div tabIndex='10' className='adform__btncancel' onClick={(e) => vm.onClickDataEdit(e, {})} onKeyPress={(e) => vm.onClickDataEdit(e, {})}>{t('return')}</div>
    <div className='adform__close'>
      <span className='adform__btn--close' onClick={(e) => vm.onClickDataEdit(e, {})}>Đóng</span>
    </div>
  </div>

export const renderComponent = (vm) =>
  <div>
    <div className='betable adblock__inner'>
      {vm.run('renderFilter')}
      <div className='adform__toggle' style={{paddingBottom: '20px'}}>
        {vm.run('renderPagin')}
        {vm.run('renderTable')}
      </div>
    </div>
    {vm.run('renderForm')}
  </div>
