import * as Jsx from './cate.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class cateComponent extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {}
  }

  getQuaTrinhHoatDongUpdate(obj) {
    obj = obj || []
    if (obj && obj.length !== 0) {
      let x = obj[obj.length - 1]
      return global.rxu.get(x, 'BacLuong' , 0)
    }
  }
  
  // R E N D E R S
  render () { return this.renderComponent() }
}

export default cateComponent
