/* global rxu, t */
import React, { Component } from 'react'
import CKEditor from 'react-ckeditor-component'
import { connect } from 'react-redux'
const { apiget, apipost } = global.rootRequire('classes/request')
const { rxmenuToggle } = global.rootRequire('redux')
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const QRCode = require('qrcode.react')
global.isclient && require('./rxSelectbox.css')

const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Hồ sơ cấp theo kỳ hạn mới']
const bankhai = ['', 'cấp mới','cấp lại', 'cấp đổi','cấp theo kỳ hạn mới']

class RxDetailCard extends Component {
  constructor (props, context) {
    super(props, context)
    this._mounted = false
    this.state = {
      toggle: false,
      editingData: this.props.editingData || {},
      paging:this.props.paging || { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 50 },
      activeTab: 'label1',
      hasPer: false,
      showInput: false,
      showPopup: false,
      action: '',
     }
     this.onChangeContentCKE = this.onChangeContentCKE.bind(this)
  }

  setStateRx (object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
  }

  componentDidMount () {
    this._mounted = true
    this.checkpermission()
  }

  fetchData (runCallback = false) {
    apiget(rxu.get(rxu.config, this.props.api), this.state.paging, {
      '1': (json) => {
        this.setStateRx({ options: json.data })
      }
    })
  }
  checkpermission() {
    let role = rxu.get(this.props, 'user.role')
    let hasPer = ['member'].indexOf(role) !== -1 ? false : true
    this.setStateRx({hasPer: hasPer})
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  onBlurData (e, name, options, name1, index) {
    options = options || {}
    let editingData = this.state.editingData
    if (name === 'role_id') {
      if (this.props.roles && e.target.value) {
        let ob = this.props.roles[e.target.value]
        editingData.role_id = ob._id
        editingData.role    = ob.name
      }
    } else {
      if (options.strim) {
        editingData[name] = e.target.value.toString().replace(/(,)/g, '')
      } else {
        editingData[name] = e.target.value
      }
    }

    this.setState({ editingData: editingData })
  }
  onChangeContentCKE (evt) {
    let editingData = this.state.editingData
    let newContent = evt.editor.getData()
    if(this.state.showInput) { editingData['ContentThamVan'] = newContent } else { editingData.content = newContent }
    this.setState({ editingData: editingData })
  }

  toggle () {
    this.setState({toggle: !this.state.toggle}, () => {
      let input = document.getElementById('input-search')
      if(input) {
        input.focus()
      }
    })
  }
  onClickTabItem(e,tab) {
    this.setStateRx({ activeTab: tab })
  }
  onClickDataExport2(e) {
    global.JSONToCSVConvertor2(this.state.editingData)
  }
  onClickDataExport1(e) {
    global.JSONToCSVConvertor1(this.state.editingData)
  }
  onClickDataExport4(e) {
    global.PrintDocMauCapLaiThe(this.state.editingData)
  }
  onClickDataExport5(e) {
    global.PrintDocMauDoiThe(this.state.editingData)
  }
  onClickShowInput() {
    this.setState({showInput: !this.state.showInput})
  }
  onClickClosePopup() {
    this.setStateRx({showPopup: false, action: ''}, () => {this.props.rxmenuToggle('ex_manager_detail') })
  }
  showForm(e, type) {
    let action = type ? 'duyet' : 'khongduyet'
    this.setState({showPopup: true, action: action})
  }

  onClickDataEdit() {
    this.setState({}, () => this.props.rxmenuToggle('ex_manager_detail'))
  }
  onClickDataApprove(e,val) {
    let isStatus = rxu.get(this.state.editingData, 'IsStatus', 0)
    let step  =  isStatus + 1
    let value = 'IsCheck'+step
    let action = this.state.action === 'duyet' ? ('DUYỆT '+ step) : ('KHÔNG DUYỆT '+ step)
    if(this.state.action && this.state.action !== 'duyet' && this.state.editingData.GhiChu === '' ) {
      window.confirm('Ghi chú thẩm định là bắt buộc !')
    } else {
      global.scrolltop()
      let r = window.confirm('Bạn có chắc chắn muốn ' + action + ' dữ liệu này !')
      if (r === true) {
        this.setStateRx({loading: true}, () => {
          let editingData = {hideProgress: true}
          editingData[value] = this.state.action === 'duyet' ? 1 : 0
          editingData.IsCheck = this.state.action === 'duyet' ? 1 : 0
          editingData.IsStatus = Number(this.state.editingData.IsStatus) || 0
          editingData._id = this.state.editingData._id
          editingData.action = action
          editingData.GhiChu = this.state.editingData.GhiChu || ''
          editingData.LichSuDuyet = this.state.editingData.LichSuDuyet || []
          apipost(rxu.get(rxu.config, 'api_card') + '/edit',editingData, {
            '1': (json) => { this.fetchData(true) }
          })
          this.onClickClosePopup()
        })
      } else {}
    }


  }

  onClickConsultation(e,val) {
    this.setStateRx({loading: true}, () => {
      let params = {}
      params.codeGen = this.state.editingData.codeGen || ''
      params.email = this.state.editingData.EmailThamVan || ''
      params.content = this.state.editingData.ContentThamVan || ''
      params.subject = this.state.editingData.SubjectThamVan || ''
      // console.log(params, 'editingData')
      apipost(rxu.get(rxu.config, 'api_sentmail'), params, {
        '1': (json) => { alert('Đã gửi thành công') }
      })
      this.onClickShowInput()
    })
  }

  renderTimeline() {
    return (
      <div className='renderTimeline'>
          <div className='ui-title-bar__actions'>
            <div className='progress-bar-order'>
              <div className={[1,2,3,4,5,6].indexOf(Number(this.state.editingData.IsStatus)) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 1</div>
                <div className='progress-bar-item-title'>Cấp Chuyên viên</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{this.state.editingData.IsStatus > 0 && global.rxu.date(global.rxu.get(this.state.editingData, ['LichSuDuyet', 0, 'created_at']))}</div>
              </div>

              <div className={[2,3,4,5,6].indexOf(this.state.editingData.IsStatus) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 2</div>
                <div className='progress-bar-item-title'>Cấp Lãnh đạo Phòng</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{this.state.editingData.IsStatus > 1 && global.rxu.date(global.rxu.get(this.state.editingData, ['LichSuDuyet', 1, 'created_at']))}</div>
              </div>

              <div className={[3,4,5,6].indexOf(this.state.editingData.IsStatus) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>BƯỚC 3</div>
                <div className='progress-bar-item-title'>Thẩm định Cấp Cục</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date' >{this.state.editingData.IsStatus > 2 && global.rxu.date(global.rxu.get(this.state.editingData, ['LichSuDuyet', 2, 'created_at']))}</div>
              </div>

              <div className={[4,5,6].indexOf(this.state.editingData.IsStatus) !== -1  ? 'progress-bar-item active' : 'progress-bar-item'}>
                <div className='progress-bar-item-step'>Bước 4</div>
                <div className='progress-bar-item-title'>Thẩm định Cấp Bộ</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                  <div className='progress-bar-item-line right'></div>
                </div>
                <div className='progress-bar-item-date'>{this.state.editingData.IsStatus > 3 && global.rxu.date(global.rxu.get(this.state.editingData, ['LichSuDuyet', 3, 'created_at']))}</div>
              </div>

              <div className={[5,6].indexOf(this.state.editingData.IsStatus) !== -1  ? 'progress-bar-item active ' : 'progress-bar-item '} >
                <div className='progress-bar-item-step'>BƯỚC 5</div>
                <div className='progress-bar-item-title'>{this.state.editingData.IsStatus === 6 ? 'Trả hồ sơ' : (this.state.editingData.IsStatus === 5 ? 'Đã duyệt in' : 'Chờ duyệt in')}</div>
                <div className='progress-bar-item-progress'>
                  <div className='progress-bar-item-line left'></div>
                  <div className='progress-bar-item-ico'>
                    <div className='progress-bar-item-ico-in'></div>
                  </div>
                </div>
                <div className='progress-bar-item-date'>{this.state.editingData.IsStatus> 4  && global.rxu.date(global.rxu.get(this.state.editingData, ['LichSuDuyet', 4, 'created_at']))}</div>
              </div>
            </div>
          </div>
      </div>
    )
  }
  renderView1() {
    let editingData = this.state.editingData
    return(<div className='rx-crud__form'>
      <div className='form-header'>
        <div>
            <div className='class_avatar'>
                <RxUpload callback={(e) => this.callbackUpload(e, 'img_landscape')} images={editingData.img_landscape} type='avatar' disabled='1' />
                <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
            </div>
            <div className='form-header-title'>
                <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                <div className='text-center title-main'>BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO</div>
                <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1)</div>
                <div className='form-header-bottom'>
                    <span>Tên cơ quan đề nghị cấp thẻ nhà báo: </span>
                    <input tabIndex='1' type='text' value={editingData.TenCoQuanBaoChi || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='form-header-right barcode'>
                <div>
                    <QRCode id={editingData.codeGen || '1'} value={editingData.codeGen || '1'} size={160} level={"H"} includeMargin={true} />
                    <div className='text-center form-avatar-sub'>{editingData.codeGen || ''}</div>
                    {/* <div className='form-header-code'>Số thẻ nhà báo cũ: {editingData.MaTheCu}</div> */}
                </div>
            </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Thông tin cá nhân</div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Họ và tên khai sinh (2): </span>
                    <input tabIndex='1' type='text' value={editingData.HoTenKhaiSinh || ''} className='fullwidth-input textuppercase' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Nam/Nữ: </span>
                    <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Ngày sinh: </span>
                    <input tabIndex='1' type='text' value={ rxu.parseTime(editingData.NgaySinh, editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
              <div>
                  <span>Dân tộc: </span>
                  <input tabIndex='1' type='text' value={editingData.DanToc || ''} className='fullwidth-input' disabled />
              </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Quê quán: </span>
                    <input tabIndex='1' type='text' value={editingData.QueQuan || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Nơi ở hiện nay: </span>
                    <input tabIndex='1' type='text' value={editingData.NoiO || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Chức vụ (3): </span>
                    <input tabIndex='1' type='text' value={editingData.TenChucVu || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Bút danh thường dùng: </span>
                    <input tabIndex='1' type='text' value={editingData.ButDanh || ''} className='fullwidth-input' disabled />
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>TRÌNH ĐỘ</div>
        <div>
          <div className='rx-form__item'>
              <div>
                  <span>Giáo dục phổ thông (4): <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.TrinhDoVanHoa || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          {/*}<div className='rx-form__title--sub'>Chuyên môn (5): <span/></div>*/}
          <div className='rx-form__item'>
              <div>
                  <span>Chuyên môn (5): <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.TrinhDoChuyenMon || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Trường học: <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.TruongHoc || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngành học: <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.NganhHoc || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Hình thức đào tạo (6): <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.HinhThucDaoTao || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Năm tốt nghiệp: <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.NamTotNghiep || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Lý luận chính trị (7): <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.ChinhTri || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Ngoại ngữ (8): <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.NgoaiNgu || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Chứng chỉ đào tạo, bồi dưỡng nghiệp vụ báo chí (nếu có): <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.ChungChi || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Đảng viên: <i className='icon-require'/></span>
                  <input tabIndex='1' type='text' value={editingData.DangVien || ''} className='fullwidth-input' disabled/>
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Chức danh báo chí hiện nay (9): </span>
                  <input tabIndex='1' type='text' value={editingData.TenChucVu || ''} className='fullwidth-input' disabled />
              </div>
          </div>
          <div className='rx-form__item'>
              <div>
                  <span>Số thẻ nhà báo và kỳ hạn cũ: </span>
                  <input tabIndex='1' type='text' value={editingData.MaTheCu || ''} className='fullwidth-input' disabled />
              </div>
          </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Quá trình hoạt động báo chí 5 năm gần nhất:</div>
        <div>{this.run('renderTableForm')}</div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Thông tin khai cho mẫu số 3</div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span style={{minWidth: '150px'}}>Số CMND/CCCD</span>
                    <input tabIndex='1' type='text' value={editingData.CMND || ''} className='fullwidth-input' disabled />
                </div>
                <div>
                    <span style={{minWidth: '100px'}}>Ngày cấp</span>
                    <input tabIndex='1' type='text' value={rxu.parseTime(null, editingData.NgayCapCMND || '')} className='fullwidth-input' disabled />
                </div>
                <div>
                    <span style={{minWidth: '150px'}}>Ảnh CMND/CCCD</span>
                    <RxUploadCMND images={editingData.cmnd_truoc} title='Ảnh mặt trước' single={1} disabled={1}/>
                    <RxUploadCMND images={editingData.cmnd_sau} title='Ảnh mặt sau' single={1} disabled={1}/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span> Ngày, tháng, năm ký HĐLĐ có đóng BHXH/Số sổ BHXH</span>
                    <input tabIndex='1' type='text' value={editingData.ThongTinHDLD || ''} className='fullwidth-input' disabled />
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Thành phần hồ sơ khác</div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Sơ yếu lý lịch</span>
                    <RxUploadCMND images={editingData.SoYeuLyLich} title='Sơ yếu lý lịch' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span>Bản sao bằng Đại học/Cao đẳng</span>
                <RxUploadCMND images={editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span>Bản sao quyết định tuyển dụng, hợp đồng lao động </span>
                <RxUploadCMND images={editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      {/*}
      <div className='rx-form__body' style={{marginTop:'40px'}}>
        <div>
          <div className='rx-form__sign'>
            <div className="rx-form__sign__item">
              <div className='formsign__title'>Chuyên viên Thuộc cục</div>
              {editingData.IsCheck1 === 1 && <div>
                <div className='formsign__sign'>Đã ký</div>
                <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 0, 'created_at']))}</div>
              </div>}
            </div>
            <div className="rx-form__sign__item">
              <div className='formsign__title'>Lãnh đạo PHÒNG</div>
              {editingData.IsCheck2 === 1 && <div>
                <div className='formsign__sign'>Đã ký</div>
                <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 1, 'created_at']))}</div>
              </div>}
            </div>
            <div className="rx-form__sign__item">
              <div className='formsign__title'>Lãnh đạo CỤC BÁO CHÍ</div>
              {editingData.IsCheck3 === 1 && <div>
                <div className='formsign__sign'>Đã ký</div>
                <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 2, 'created_at']))}</div>
              </div>}
            </div>
            <div className="rx-form__sign__item">
              <div className='formsign__title'>Lãnh đạo bộ</div>
              {editingData.IsCheck4 === 1 && <div>
                <div className='formsign__sign'>Đã ký</div>
                <div className='formsign__time'>{global.rxu.parseTime(null, global.rxu.get(editingData, ['LichSuDuyet', 3, 'created_at']))}</div>
              </div>}
            </div>
          </div>
        </div>
      </div>

      */}


      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body note'>
        <div className='rx-form__body--note'>Lưu ý</div>
        <div>
          <div>
              <p> </p>
              <p>Ghi chú:</p>
              <p>(1) Ghi theo kỳ hạn thẻ nhà báo đề nghị cấp (ví dụ: 2016 - 2020)</p>
              <p>(2) Viết chữ in hoa đủ dấu. </p>
              <p>(3) Ghi rõ chức vụ bổ nhiệm (ví dụ: Tổng biên tập, Phó Tổng biên tập, Trưởng ban...). </p>
              <p>(4) Ghi rõ đã tốt nghiệp lớp mấy, thuộc hệ nào.</p>
              <p>(5) Ghi rõ trình độ chuyên môn cao nhất (ví dụ: Tiến sĩ, thạc sĩ, cử nhân, cao đẳng...).</p>
              <p>(6) Chính quy, tại chức, đào tạo từ xa.... </p>
              <p>(7) Cao cấp, trung cấp, sơ cấp và tương đương</p>
              <p>(8) Tên ngoại ngữ và trình độ (ví dụ: A, B, C, D...)</p>
              <p>(9) Ghi rõ chức danh nghề nghiệp (ví dụ: Phóng viên, biên tập viên...). </p>
          </div>
        </div>
      </div>
    </div>)
  }
  renderView2() {
    return <div className='rx-crud__form'>
      <div className='form-header'>
        <div>
            <div className='form-header-title'>
                <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                <div className='text-center title-main'>DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT</div>
                <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1)</div>
            </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div>
          <div className='rx-table__form'>
            <table style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td className='rx-table__form-header'>STT</td>
                  <td className='rx-table__form-header'>Tên tác phẩm báo chí</td>
                  <td className='rx-table__form-header'>Loại hình báo chí</td>
                  <td className='rx-table__form-header'>Tác giả, nhóm tác giả</td>
                  <td className='rx-table__form-header'>Ngày, tháng, năm đăng phát</td>
                  <td className='rx-table__form-header'>Cơ quan báo chí đăng, phát</td>
                </tr>
                {this.state.editingData.DanhSachTacPham && this.state.editingData.DanhSachTacPham.length > 0 && this.state.editingData.DanhSachTacPham.map((obj, index) => (
                  <tr key={index}>
                    <td className='rx-padding text-center'>{ index+1 }</td>
                    <td className='rx-padding'>{obj.Ten || ''}</td>
                    <td className='rx-padding'>{obj.LoaiHinhBC ||''}</td>
                    <td className='rx-padding'>{obj.TacGia || ''}</td>
                    <td className='rx-padding'>{obj.NgayDang || ''}</td>
                    <td className='rx-padding'>{obj.CoQuanDang || ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  }
  renderView4() {
    let editingData = this.state.editingData
    return <div className='rx-crud__form'>
      <div className='form-header'>
        <div>
            <div className='form2-header-title'>
              <div className='form2-header-top'>
                  <div className='class_avatar'>
                      <RxUpload callback={(e) => this.callbackUpload(e, 'img_landscape')} images={editingData.img_landscape} type='avatar' disabled='1' />
                      <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                  </div>
                  {/* <div className='header__right'>
                      <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                      <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
                      <div className='text-center title-alias'>{rxu.getFullDate(editingData.created_at || '')}</div>
                  </div>
                  <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                  <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
                  <div className='text-center title-alias'>{rxu.getFullDate(editingData.created_at || '')}</div>*/}
              </div>
              <div className='text-center title-main'>CẤP LẠI THẺ NHÀ BÁO</div>
              {/*}<div className='title-sub'>Thực hiện quy định tại Luật Báo chí 2016 về thẻ nhà báo, đề nghị Bộ Thông tin và Truyền thông cấp lại thẻ nhà báo: </div>*/}
          </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Họ và tên: </span>
                    <input tabIndex='1' type='text' value={editingData.HoTenKhaiSinh || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Nam/Nữ: </span>
                    <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Ngày sinh: </span>
                    <input tabIndex='1' type='text' value={ rxu.parseTime(editingData.NgaySinh, editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Nơi công tác: </span>
                    <input tabIndex='1' type='text' value={ editingData.TenCoQuanBaoChi } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Thẻ nhà báo số: </span>
                    <input tabIndex='1' type='text' value={ editingData.MaTheCu } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Lý do đề nghị cấp lại (mất thẻ, hỏng thẻ): </span>
                    <input tabIndex='1' type='text' value={ editingData.LyDoCapLai } className='fullwidth-input' disabled/>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Thành phần hồ sơ khác</div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Bản khai đăng ký {bankhai[editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                    <RxUploadCMND images={editingData.SoYeuLyLich} title='Sơ yếu lý lịch' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
                <RxUploadCMND images={editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span>Bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
                <RxUploadCMND images={editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  renderView3() {
    let editingData = this.state.editingData
    return <div className='rx-crud__form'>
      <div className='form-header'>
        <div>
          <div className='form2-header-title' style={{width:'100%', padding:'0 0 50px 0'}}>

            <div className='form2-header-top'>
              <div className='header__left'>
                <div className='title-top' style={{paddinBottom: '15px'}}>{editingData.TenCoQuanBaoChi}</div>
                <div className='class_avatar'>
                    <RxUpload callback={(e) => this.callbackUpload(e, 'img_landscape')} images={editingData.img_landscape} type='avatar' disabled='1' />
                    <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                </div>
              </div>
              <div className='header__right'>
                <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
                <div className='header__hr'/>
                <div className='text-center title-alias'>{rxu.getFullDate(editingData.created_at || '')}</div>
              </div>
            </div>
            <div className='text-center header__title'>GIẤY XÁC NHẬN</div>
          </div>
        </div>
      </div>
      <div className='fullwidth-formdevide cleafix' />
      <div className='rx-form__body'>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Cơ quan báo chí (ghi tên cơ quan báo chí, cơ quan xác nhận)</span>
                    <input tabIndex='1' type='text' value={ editingData.TenCoQuanBaoChi } className='fullwidth-input' disabled/>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Xác nhận để làm thủ tục đổi thẻ nhà báo đối với trường hợp: </div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Họ và tên: </span>
                    <input tabIndex='1' type='text' value={editingData.HoTenKhaiSinh || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Nam/Nữ: </span>
                    <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Năm sinh: </span>
                    <input tabIndex='1' type='text' value={ rxu.parseTime(editingData.NgaySinh, editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Thẻ nhà báo số: </span>
                    <input tabIndex='1' type='text' value={editingData.MaTheMoi || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Đã nộp thẻ nhà báo (trường hợp mất thẻ, chưa nộp lại thẻ ghi rõ lý do): </span>
                    <input tabIndex='1' type='text' value={editingData.MaTheCu || ''} className='fullwidth-input' disabled />
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Thời gian công tác tại cơ quan (từ ngày tháng năm đến ngày tháng năm): </span>
                    <input tabIndex='1' type='text' value={editingData.ThoiGianCongTac || ''} className='fullwidth-input' disabled />
                </div>
            </div>

          </div>
        </div>
      </div>
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Trong thời gian công tác: </div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Việc hoàn thành nhiệm vụ được giao: </span>
                    <input tabIndex='1' type='text' value={ editingData.QuaTrinhHoatDong } className='fullwidth-input' disabled/>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Liên quan một hoặc các trường hợp quy định tại điểm a, b, c, d và đ khoản 3 Điều 27 Luật báo chí: </div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Chức danh báo chí: </span>
                    <input tabIndex='1' type='text' value={ editingData.ChucDanhBaoChi || '' } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Chức vụ: </span>
                    <input tabIndex='1' type='text' value={ editingData.TenChucVu } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Về đạo đức nghề nghiệp của người làm báo (nếu đã bị xử lý vi phạm ghi rõ thời gian): </span>
                    <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Về kỷ luật (nếu đã bị xử lý kỷ luật từ khiển trách trở lên theo quy định của pháp luật về công chức, viên chức và pháp luật về lao động, ghi rõ thời gian và hình thức bị kỷ luật): </span>
                    <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong1 } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Liên quan đến các vụ án (bị kết án năm nào, đã được xóa án tích chưa, hiện có là đối tượng liên quan trong các vụ án chưa có kết luận của cơ quan nhà nước có thẩm quyền không): </span>
                    <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong2 } className='fullwidth-input' disabled/>
                </div>
            </div>
            <div className='rx-form__item'>
                <div>
                    <span>Đã từng bị thu hồi thẻ nhà báo (ghi rõ thời gian, lý do): </span>
                    <input tabIndex='1' type='text' value={ editingData.ListHoSoQuaTrinhHoatDong3 } className='fullwidth-input' disabled/>
                </div>
            </div>


          </div>
        </div>
      </div>
      <div className='rx-form__body'>
        <div className='rx-form__body--title'>Thành phần hồ sơ khác</div>
        <div>
          <div>
            <div className='rx-form__item'>
                <div>
                    <span>Bản khai đăng ký {bankhai[editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                    <RxUploadCMND images={editingData.SoYeuLyLich} title='Sơ yếu lý lịch' disabled='1' />
                </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
                <RxUploadCMND images={editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' disabled='1' />
              </div>
            </div>
            <div className='rx-form__item'>
              <div>
                <span>Bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
                <RxUploadCMND images={editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' disabled='1' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  renderTableForm() {
    if (this.state.editingData && this.state.editingData.QuaTrinhHoatDongUpdate ) {
      let QuaTrinhHoatDong = global.rxu.get(this.state, ['editingData', 'QuaTrinhHoatDongUpdate'])
      return (<div className='rx-table__form'>
          <table className='marginRight-35'>
            <tbody>
              <tr><td className='rx-table__form-header' rowSpan="2"><p><strong>Thời hạn (từ tháng, năm nào đến tháng, năm )</strong></p></td>
                <td className='rx-table__form-header' rowSpan="2"><p><strong>Chức danh báo chí</strong></p></td>
                <td className='rx-table__form-header' rowSpan="2"><p><strong>Công tác tại cơ quan báo chí nào</strong></p></td>
                <td className='rx-table__form-header' colSpan="2"><p><strong>Lương</strong></p></td>
                <td className='rx-table__form-header' rowSpan="2"><p><strong>Khen thưởng kỷ luật (thời gian cụ thể)</strong></p></td>
                {/* <td className='rx-table__form-header' rowSpan="2"><p><strong>Mã thẻ</strong></p></td> */}
              </tr>
              <tr>
                <td className='rx-table__form-header'><p><strong>Ngạch lương</strong></p></td>
                <td className='rx-table__form-header'><p><strong>Bậc lương</strong></p></td>
              </tr>
              {QuaTrinhHoatDong && QuaTrinhHoatDong.map((obj, index) => (
                <tr key={index}>
                  <td className='rx-padding'>{obj.time || ''}</td>
                  <td className='rx-padding'>{obj.ChucDanhBaoChi ||''}</td>
                  <td className='rx-padding'>{obj.CoQuanBaoChi || ''}</td>
                  <td className='rx-padding'>{obj.NgachLuong || ''}</td>
                  <td className='rx-padding'>{obj.BacLuong || ''}</td>
                  <td className='rx-padding'>{obj.KhenThuong || ''}</td>
                  {/* <td className='rx-padding'>{obj.MaThe || ''}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
      </div>)
    }
  }
  renderFormBtns() {
    return(
      <div className='adform__btns rx-stickybot rx-stickybot clearfix'>
        <div tabIndex='10' className='adform__btncancel' onClick={(e) => this.props.rxmenuToggle('ex_manager_detail') } onKeyPress={(e) => this.props.rxmenuToggle('ex_manager_detail')}>{t('return')}</div>
        {[0, 1, 4].indexOf(this.state.editingData.StatusPhanLoai) !== -1 && <span className='adform__btncancel betable__btnexport btn' onClick={(e) => this.onClickDataExport1(e)}><i className='icon-cloud-download' />Xuất mẫu 01</span>}
        {this.state.editingData.StatusPhanLoai === 3 && <span className='adform__btncancel betable__btnexport btn' onClick={(e) => this.onClickDataExport5(e)}><i className='icon-cloud-download' />Xuất giấy xác nhận</span>}
        {[0, 1, 4, 3].indexOf(this.state.editingData.StatusPhanLoai) !== -1 && <span className='adform__btncancel betable__btnexport btn' onClick={(e) => this.onClickDataExport2(e)}><i className='icon-cloud-download' />Xuất mẫu 02</span>}
        {this.state.editingData.StatusPhanLoai === 2 && <span className='adform__btncancel betable__btnexport btn' onClick={(e) => this.onClickDataExport4(e)}><i className='icon-cloud-download' />Xuất file</span>}
        <span className='adform__btncancel betable__btnexport btn' onClick={(e) => this.onClickShowInput(e)}><i className='icon-cloud-download' />Tham vấn</span>
        <div className='adform__close'>
          {this.state.editingData['IsStatus'] < 5 && <div style={{display: 'flex'}}>
            {this.state.hasPer && [0,1,2,3].indexOf(this.state.editingData.IsStatus) !== -1 && <span className='adform__btn--save' onClick={(e) => this.showForm(e,true)}>Duyệt</span> }
            {this.state.hasPer && [0,1,2,3].indexOf(this.state.editingData.IsStatus) !== -1 && <span className='adform__btn--save' onClick={(e) => this.showForm(e, false)}>Không duyệt</span>}
          </div>}
        </div>
      </div>
    )
  }
  showPopup(){
    return <div className='show-popup fixed' style={{position: 'fixed', top: '70px'}}>
      <div className='modal' id='modal'>
        <h2>Ghi chú thẩm định</h2>
        <div className='content'>
          <textarea tabIndex='1' type='text' value={this.state.editingData.GhiChu || ''} onChange={(e) => this.onBlurData(e, 'GhiChu')} className='fullwidth-input' />
        </div>
        <div className='actions'>
          <div className='adform__close'><span className='adform__btn--close' onClick={(e) => this.onClickClosePopup()}>Đóng</span></div>
          <div className='adform__close'><span className='adform__btn--save' onClick={(e) => this.onClickDataApprove(e,false)}>Cập nhật</span></div>
        </div>
      </div>
    </div>
  }

  showInputEmail() {
    return <div className='show-popup fixed' style={{position: 'fixed', top: '70px'}}>
      <div className='modal__contact'>
        <div className='modal__contact-title'>Gửi thư trao đổi</div>
        <div className='content' >
          <div>
            <div>Email</div>
            <input tabIndex='1' type='text' value={this.state.editingData.EmailThamVan || ''} onChange={(e) => this.onBlurData(e, 'EmailThamVan')} className='fullwidth-input' placeholder='Nhập địa chỉ email' />
          </div>
          <div>
            <div>Title</div>
            <input tabIndex='1' type='text' value={this.state.editingData.SubjectThamVan || ''} onChange={(e) => this.onBlurData(e, 'SubjectThamVan')} className='fullwidth-input' placeholder='Nhập tiêu đề' />
          </div>
          <div>
            <div>Body</div>
            <CKEditor activeClass='p10' height='100px' content={this.state.editingData.ContentThamVan} events={{ 'change': this.onChangeContentCKE }} />
          </div>
        </div>
        <div className='actions'>
          <div className='adform__close'><span className='adform__btn--close' onClick={(e) => this.onClickShowInput()}>Cancel</span></div>
          <div className='adform__close'><span className='adform__btn--save' onClick={(e) => this.onClickConsultation(e,false)}>Ok</span></div>
        </div>
      </div>
    </div>
  }

  componentWillUnmount() {
    this._mounted = false;
  }
  render () {
    let title = 'Thẩm định'
    return (<div className='addetail_card'>
      {this.state.showPopup && this.run('showPopup')}
      {this.state.showInput && this.run('showInputEmail')}
      <div className='adform'>
        <div className='adform__title'>{title} <span>{`"${rxu.get(this.state, ['editingData', 'HoTenKhaiSinh'], '')}"`}</span></div>
        <div className='adform__typecard'>
          <div>
            <div className='adform__typecard--body view'>
              <span>Loại hồ sơ: </span>
              <div>{statusPhanloai[rxu.get(this.state, ['editingData', 'StatusPhanLoai'], 0)]}</div>
            </div>
          </div>
        </div>
        <div className='adform__header'>
          <div className='adform__name'>
            {this.run('renderTimeline')}
            {([0, 1, 4, 3].indexOf(this.state.editingData.StatusPhanLoai) !== -1 || [0, 1, 4].indexOf(this.state.editingData.StatusPhanLoai) !== -1) && <div style={{marginBottom: '-20px'}}>
              <div className={this.state.activeTab === 'label1' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={(e) => this.onClickTabItem(e,'label1')}>{this.state.editingData['StatusPhanLoai'] === 3 ? 'Giấy xác nhận' : 'Mẫu số 01'}</div>
              <div className={this.state.activeTab === 'label2' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={(e) => this.onClickTabItem(e,'label2')}>Mẫu số 02</div>
            </div>}
          </div>
        </div>

        <div style={{display: 'flex'}}>
          <div className='adform__body col-md-10'>
            {/* {this.state.activeTab === 'label2' && <div className='adform__note'> Sử dụng đối với trường hợp cán bộ chuyển cơ quan báo chí</div>} */}
            {([0, 1, 4].indexOf(this.state.editingData.StatusPhanLoai) !== -1 && this.state.activeTab === 'label1') && this.run('renderView1') }
            {([0, 1, 4, 3].indexOf(this.state.editingData.StatusPhanLoai) !== -1 && this.state.activeTab === 'label2') && this.run('renderView2') }
            {(this.state.editingData.StatusPhanLoai === 2) && this.run('renderView4') }
            {(this.state.editingData.StatusPhanLoai === 3 && this.state.activeTab === 'label1') && this.run('renderView3') }
          </div>
          {this.state.editingData.LichSuDuyet  &&
            <div className='adform__body col-md-2'>
              <div style={{width: '100%'}}>
              <span className='rx--check__titleForm'>Quá trình thẩm định</span>
              { this.state.editingData.LichSuDuyet.map( (duyet, index) =>
                <div className='rx--checkIssue' key={index}>
                  <span className={duyet.action.indexOf('KHÔNG') === -1 ? 'rx--checkIssue__btn duyet' : 'rx--checkIssue__btn khongduyet'} />
                  <span className='rx--check__time'>{global.toDate(duyet.created_at)}</span>
                  <span className='rx--check__title'>{duyet.username + ' - ' + duyet.action}</span>
                  <div className='rx--check__content'>
                    <span className='content'>{duyet.desc}</span>
                  </div>
                </div>
              )}
             </div>
            </div>
          }
        </div>

        <div className='adform__footer'>
          {this.run('renderFormBtns')}
        </div>
      </div>
    </div>)
  }

}

const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav
})

const mapDispatchToProps = {
  rxmenuToggle
}

const RxDetailCard_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(RxDetailCard)

RxDetailCard_.defaultProps = { onChange: () => {}, options: [], results: '' }
// Options : {key: , text: }
export default RxDetailCard_
