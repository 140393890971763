/* global t */
import React, { Component } from 'react'

const { apiget, apipost } = global.rootRequire('classes/request')
// const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

const WAIT_INTERVAL = 500
const ROLE_ARR = [{id: 'member', name: 'Cơ quan báo chí'},{id: 'Super', name: 'Quản trị hệ thống'},
                  {id: 'superedit',      name: 'Quyền quản trị hệ thống cao nhất'},
                  {id: 'lanhdaobo',      name: 'Lãnh đạo Bộ'},
                  {id: 'lanhdaocuc01',   name: 'Lãnh đạo Cục'},
                  {id: 'lanhdaocucptth', name: 'Lãnh đạo Cục PTTH&TTDT'},
                  {id: 'lanhdaocucbctw', name: 'Lãnh đạo Cục Báo chí Trung Ương'},
                  {id: 'lanhdaocucbcdp', name: 'Lãnh đạo Cục Báo chí Địa Phương'},
                  {id: 'chuyenvienpt',   name: 'Chuyên viên PTTH&TTDT'},
                  {id: 'chuyenvientw',   name: 'Chuyên viên Trung Ương'},
                  {id: 'chuyenviendp',   name: 'Chuyên viên Địa Phương'},
                  {id: 'lanhdaodonvi01', name: 'Lãnh đạo đơn vị báo chí'},
                  {id: 'lanhdaopt',      name: 'Lãnh đạo đơn vị báo chí PTTH&TTDT'},
                  {id: 'lanhdaotw',      name: 'Lãnh đạo Trung Ương'},
                  {id: 'lanhdaodp',      name: 'Lãnh đạo Địa Phương'},
                  {id: 'printer',        name: 'Chuyên viên In thẻ'},
                  {id: 'profileedit',    name: 'Chuyên viên chỉnh sửa profile'},

                  {id: 'chuyenvienkql',  name: 'Chuyên viên Khối quản lý'},
                  {id: 'lanhdaokql',     name: 'Lãnh đạo Khối quản lý'},
                  {id: 'lanhdaocuckql',  name: 'Lãnh đạo Cục Khối quản lý'},

                  {id: 'viewer',         name: 'User thông thường'}]
// const ROLES = ['member','viewer','Super','lanhdaobo','lanhdaocuc01','lanhdaocucptth','lanhdaocucbctw','lanhdaocucbcdp','chuyenvienpt','chuyenvientw','chuyenviendp','lanhdaodonvi01','lanhdaopt','lanhdaotw','lanhdaodp']
class roleComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      extra: {},
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      editingData: {},
      dataPermission: [],
      permissionArray: [],
      selected_array: [],
      showselected_array: [],
      isPermission: false
    }
  }

  componentDidMount () {
    this._ismounted = true
    this.fetchAlldata()
    this.timerDatafilter = null
  }
  componentWillUnmount () { this._ismounted = false }
  rxsetState (obj) { if (this._ismounted) { this.setState(obj) } }

  fetchAlldata () {
    this.fetchData()
    this.fetchDataPermission()
  }

  fetchData () {
    apiget(global.rxu.config.api_role, this.state.paging, { '1': (json) => { this.rxsetState({ data: json.data, extra: json.extra }) } })
  }

  fetchDataPermission () {
    apiget(global.rxu.config.api_permission_all, {}, {
      '1': (json) => {
        this.permission_array = []

        // Making data
        this.rxsetState({ dataPermission: json.data })
        let perArray = this.groupBy(this.state.dataPermission)
        let arrayParse = this.parseObjToarray(perArray)

        let keys = []
        for (let key in arrayParse) {
          keys.push(arrayParse[key])
        }
        this.rxsetState({ permissionArray: keys })
      }
    })
  }

  // Role //
  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    let paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }

  addPermission () {
    this.setState({ isPermission: true })
    this.checkPermission(this.state.selected_array)
  }

  checkPermission (permissionChecked) {
    let tempPermission = this.state.dataPermission

    if (permissionChecked !== null) {
      for (let keyPer in tempPermission) {
        if (permissionChecked.indexOf(tempPermission[keyPer].id) !== -1) {
          tempPermission[keyPer].checked = true
        }
      }
    }

    this.setState({ dataPermission: tempPermission })
    return this.state.dataPermission
  }

  checkedAll (data) {
    let checkList = true
    for (let key in data.value) {
      if (data.value[key].checked !== true) {
        checkList = checkList && false
      } else {
        checkList = checkList && true
      }
    }
    return checkList
  }

  checkedExist (data) {
    let arrchecked = data.value.map((x) => x.checked)
    if (arrchecked.indexOf(true) === -1) {
      return false
    } else {
      return true
    }
  }

  toggleAll (e, index) {
    let arrpermission = this.state.permissionArray
    let data = arrpermission[index]
    let arrselected = this.state.selected_array
    if (this.checkedAll(data)) {
      for (let key in data.value) {
        let index1 = arrselected.indexOf(data.value[key].id)
        arrselected.splice(index1, 1)
        data.value[key].checked = false
      }
    } else {
      for (let key in data.value) {
        if (arrselected.indexOf(data.value[key].id) === -1) {
          arrselected.push(data.value[key].id)
          data.value[key].checked = true
          arrpermission[index] = data
        }
      }
    }

    this.setState({ permissionArray: arrpermission, selected_array: arrselected })
  }

  toggleOne (e, index, perindex, checked) {
    let arrselected = this.state.selected_array
    let arrpermission = this.state.permissionArray
    let data = this.state.permissionArray[index]['value'][perindex]
    data['checked'] = checked
    if (arrselected.indexOf(data.id) === -1) {
      if (checked) {
        arrselected.push(data.id)
      }
    } else {
      if (!checked) {
        let index = arrselected.indexOf(data.id)
        arrselected.splice(index, 1)
      }
    }
    this.setState({ permissionArray: arrpermission, selected_array: arrselected })
  }

  addResetPermission () {
    this.fetchDataPermission()
    this.setState({ selected_array: this.initialPermission(this.state.editingData), editingData: this.state.editingData, isPermission: false })
  }

  addSavePermission () {
    let editingData = this.state.editingData
    this.showPermission(this.state.selected_array)
    editingData.permission = this.state.selected_array
    this.setState({ editingData: editingData, isPermission: false })
  }

  initialPermission (perdata) {
    if (perdata && typeof (perdata.permission) === 'string' && perdata.permission !== null) {
      let array = perdata.permission.split(',').map(Number)
      this.setState({ selected_array: array })
      return array
    } else {
      this.setState({ selected_array: [] })
      return []
    }
  }

  showPermission (data) {
    let dataperSelected = []
    for (let keyPer in this.state.dataPermission) {
      if (data.indexOf(this.state.dataPermission[keyPer].id) !== -1) {
        dataperSelected.push(this.state.dataPermission[keyPer])
      }
    }
    let dataperTmp = this.groupBy(dataperSelected)
    this.showselected_array = this.parseObjToarray(dataperTmp)
    this.setState({ showselected_array: this.showselected_array })
  }

  parseObjToarray (value) {
    if (!value) {
      return value
    }
    let keys = []
    for (let key in value) {
      keys.push({ key: key, value: (value[key]) })
    }
    return keys
  }

  groupBy (permissionArr) {
    let groups = {}
    permissionArr.forEach(function (permission) {
      permission.checked = false
      groups[permission.controller] = groups[permission.controller] || []
      groups[permission.controller].push(permission)
    })
    return groups
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  // C L I C K   E V E N T
  onClickSort (e, stcol) {
    let paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol

    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onClickDataNew (e) {
    let timeStr = Date.now().toString()
    let clone = { name: 'Role_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0, appdist: '' }
    this.setState({ editingData: clone })
  }

  onClickDataEdit (e, perdata) {
    if (typeof perdata['inthis'] !== 'undefined') { delete perdata['inthis'] }
    let clone = JSON.parse(JSON.stringify(perdata))
    let initPer = this.initialPermission(clone)
    this.checkPermission(initPer)
    this.showPermission(initPer)
    this.setState({ editingData: clone })
  }

  onClickDataDelete (e, perdata) {
    e.stopPropagation()
    apiget(global.rxu.config.api_role_delete, perdata, {
      '1': (json) => { this.fetchData(true) }
    })
  }

  onClickDataRestore (e, perdata) {
    e.stopPropagation()
    apiget(global.rxu.config.api_role_restore, perdata, {
      '1': (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit (e, perdata) {
    apipost(global.rxu.config.api_role_edit, this.state.editingData, {
      '1': (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit (e, perdata) {
    apipost(global.rxu.config.api_role, this.state.editingData, {
      '1': (json) => { this.fetchData(true) }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataTrash (e, isdeleted) {
    let paging = this.state.paging
    paging['search_is_deleted'] = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  // B L U R   E V E N T
  onBlurData (e, name) {
    let editingData = this.state.editingData
    editingData[name] = e.target.value
    this.setState({ editingData: editingData })
  }

  onBlurDataValue (value, name) {
    let editingData = this.state.editingData
    editingData[name] = value
    this.setState({ editingData: editingData })
  }

  // P A G I N
  onClickPaginBack (e) {
    let paging = this.state.paging
    paging.pg_page = (paging.pg_page > 1) ? (paging.pg_page - 1) : paging.pg_page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onClickPaginNext (e) {
    let paging = this.state.paging
    paging.pg_page += 1
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  // H E L P E R S
  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []

    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }

    for (let i = 0; i < extraclass.length; i++) {
      result += ' ' + extraclass[i]
    }

    return result
  }

  // R E N D E R
  renderForm () {
    let tempform = this.props.form || [
      { name: 'Tên *', func: (inthis) => (<select value={this.state.editingData.name} onChange={(e) => this.onBlurData(e, 'name')} className='fullwidth-input'><option value=''>--Choose Name--</option>{ROLE_ARR.map((role,index) => (<option key={role.id} value={role.id}>{role.name}</option>))}</select>) },
      { name: 'Mô tả', func: () => (<input tabIndex='2' type='text' value={this.state.editingData.desc} onChange={(e) => this.onBlurData(e, 'desc')} className='fullwidth-input' />) },
      { type: 'devide' },
      { name: 'Trạng thái', func: () => (<RxToggle value={this.state.editingData.is_active} onToggle={(newValue) => this.onBlurDataValue(newValue, 'is_active')} />) },
      { type: 'devide' }]

    if ((this.state.editingData.created_at || this.state.editingData.created_at === 0) && tempform) {
      let form = tempform.map((perdata, index) => {
        perdata.type = perdata.type || 'input'

        let result = (<div />)
        switch (perdata.type) {
          case 'input':
            result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(this)}</div>
            break

          case 'fullinput':
            result = <div key={index} className='fullwidth-frominput'><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(this)}</div>
            break

          case 'devide':
            result = <div key={index} className='fullwidth-formdevide cleafix' />
            break

          default:
            result = <div key={index}><div className='fullwidth-label'>{perdata.name}</div>{perdata.func(this)}</div>
            break
        }
        return result
      })
      return form
    }
  }

  renderHead () {
    return (<tr>
      {/*}<th className='rxwidth--100'>{t('Picture')}</th>*/}
      {/*}<th className={this.helpSortClass('name', ['rxwidth--220'])} onClick={(e) => this.onClickSort(e, 'name')}>{t('Name')}</th>*/}
      <th className={this.helpSortClass('desc', ['rxwidth--220'])} onClick={(e) => this.onClickSort(e, 'desc')}>{t('Name')}</th>
      <th className={this.helpSortClass('created_at', ['rxwidth--150'])} onClick={(e) => this.onClickSort(e, 'created_at')}>{t('Created at')}</th>
      <th className='rxwidth--100'>{t('Action')}</th>
    </tr>)
  }

  renderBody () {
    return this.state.data.map(perdata => (<tr key={perdata._id}>
      {/*}<td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>*/}
      {/*}<td>{perdata.name}</td>*/}
      <td>{perdata.desc}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td>
        { this.state.paging.search_is_deleted !== 1 &&
          <div style={{float: 'right'}}> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => this.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => this.onClickDataDelete(e, perdata)}><i className='icon-close' /></span></div> }
        { this.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => this.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  // R E N D E R S //
  render () {
    let arrayPermission = this.state.permissionArray.map((perdata, index) => (
      <div className='col-sm-4 col-xs-6' key={index}>
        <div className='adcheckper'>
          <div className='adcheckper__head' >
            <div className='adcheckper__checkcontroller' onClick={(e) => this.toggleAll(e, index)}>
              {(this.checkedAll(perdata)) && <div className='adcheckper__checkedall' />}
              {(!this.checkedAll(perdata) && this.checkedExist(perdata)) && <div className='adcheckper__checkedone_notall' />}
            </div>
            <span className='adcheckper__nametext'><b>{perdata.key}</b></span>
          </div>
          <div className='adcheckper__body' >
            {perdata.value.map((permission, perindex) => (
              <div key={permission._id} className='adcheckper__child clearfix'>
                <div className='adcheckper__checkaction' onClick={(e) => this.toggleOne(e, index, perindex, !permission.checked)}>
                  {permission.checked && <div className='adcheckper__checkedone' />}
                </div>
                <span className='adcheckper__nametext'>{permission.method + ' ' + permission.action}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ))

    let arrayShowPermission = this.state.showselected_array.map((permissiongroup, index) => (
      <div className='adperblock__group clearfix' key={index} >
        <div className='adperblock__title'>{permissiongroup.key}</div>
        <div>
          {permissiongroup.value.map((permission, perindex) => (<span className='adperblock__peraction' key={perindex}>{permission.action}</span>))}
        </div>
      </div>
    ))

    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='adblock__title'>{t('role')}</div>
          {/* <div className='adblock__desc'>{t('role')}</div> */}
        </div>
        <div className='adblock__body'>
          <div className=''>
            <div className='rxcol-100'>
              {(!this.state.editingData.created_at && !this.state.isPermission) && <div className='betable adblock__inner'>
                <span className='betable__filter'>
                  <div className='betable__btns'>
                    <span className='betable__btnadd btn' onClick={(e) => this.onClickDataNew(e)}><i className='icon-plus betable__addbtn' />Tạo mới</span>
                    { this.state.paging.search_is_deleted !== 1 && <span className='btn--default' onClick={(e) => this.onClickDataTrash(e, 1)} ><i className='icon-trash betable__recycle' /></span> }
                    { this.state.paging.search_is_deleted === 1 && <span className='btn--default' onClick={(e) => this.onClickDataTrash(e, 0)} ><i className='icon-list betable__recycle' /></span> }
                  </div>
                  <div className='col-md-12 rx-filter' style={{marginBottom: '20px', padding:'15px'}}>
                    <input className='betable__findinput' type='text' placeholder='Tìm kiếm' onKeyUp={(e) => this.onBlurDatafilter(e, 'name')} />
                  </div>
                </span>

                <div className='adform__toggle'>
                  <div className='rxpagingwrap'>
                    <div className='rxpaging'>
                      {(this.state.paging.pg_page !== 1) && <div className='rxpage' onClick={(e) => this.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>}
                      <div className='rxpage'>{this.state.paging.pg_page}</div>
                      {(this.state.data.length >= this.state.paging.pg_size) && <div className='rxpage' onClick={(e) => this.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>}
                    </div>
                  </div>

                  <div className='betable__main'>
                    <table className='betable__inner'>
                      <thead>{this.run('renderHead')}</thead>
                      <tbody>{this.run('renderBody')}</tbody>
                    </table>
                  </div>

                  <div className='rxpagingwrap'>
                    <div className='rxpaging'>
                      {(this.state.paging.pg_page !== 1) && <div className='rxpage' onClick={(e) => this.onClickPaginBack(e)}><i className='icon-arrow-left' /></div>}
                      <div className='rxpage'>{this.state.paging.pg_page}</div>
                      {(this.state.data.length >= this.state.paging.pg_size) && <div className='rxpage' onClick={(e) => this.onClickPaginNext(e)}><i className='icon-arrow-right' /></div>}
                    </div>
                  </div>
                </div>
              </div>}

              { (this.state.editingData.created_at && !this.state.isPermission) && <div className='adform'>
                <div className='adform__name'>Edit object <b>{this.state.editingData.name}</b><div className='adform__close' onClick={(e) => this.onClickDataEdit(e, {})}>x</div></div>
                <div className='adform__body'>
                  {this.run('renderForm')}

                  <div className='adform__btnshowper' onClick={(e) => this.addPermission()}>{t('Edit Permission For Role')}</div>
                  {this.state.showselected_array.length > 0 && <div className='adperblock'>{arrayShowPermission}</div> }

                  <div className='adform__btns clearfix'>
                    <div tabIndex='10' className='adform__btncancel' onClick={(e) => this.onClickDataEdit(e, {})} onKeyPress={(e) => this.onClickDataEdit(e, {})}>Back</div>
                    {this.state.editingData.created_at !== 1 && <div tabIndex='11' className='adform__btnedit' onClick={(e) => this.onClickDataUpdateSubmit(e)} onKeyPress={(e) => this.onClickDataUpdateSubmit(e)}>Update</div>}
                    <div tabIndex='12' className='adform__btnclone' onClick={(e) => this.onClickDataCreateSubmit(e)} onKeyPress={(e) => this.onClickDataCreateSubmit(e)}>Clone</div>
                  </div>
                </div>
              </div> }

              { this.state.isPermission && <div>
                <div className='row'>{arrayPermission}</div>
                <div className='adform__btns clearfix'>
                  <div tabIndex='10' className='adform__btncancel' onClick={(e) => this.addResetPermission(e)} onKeyPress={(e) => this.addResetPermission(e)}>Cancel</div>
                  <div tabIndex='11' className='adform__btnedit' onClick={(e) => this.addSavePermission(e)} onKeyPress={(e) => this.addSavePermission(e)}>Update</div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default roleComponent
