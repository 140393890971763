/* global rxu */
import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default

export const renderTableHead = (inthis, vm) =>
  <tr className='rx-title__table'>
    <th className='rxwidth--100'>STT</th>
    <th className={inthis.helpSortClass('CoQuanBaoChi', ['rx-th-width-220'])}>Cơ quan báo chí</th>
    <th className={inthis.helpSortClass('total', ['rx-th-width-220'])}>Số lượng</th>
    <th className={inthis.helpSortClass('updated_at', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Ngày cập nhật</th>
    <th className='rxwidth--100'></th>
  </tr>

export const renderTableBody = (inthis, vm) => {
  let data = vm.helperSortData(inthis.state.data)
  return global.rxu.array(data).map((perdata,index) => (<tr className='rx-xursor' key={perdata._id} onClick={(e) => inthis.onClickGroupEdit(e, perdata)}>
    <td>{index+1}</td>
    <td className='rx-cursor' onClick={(e) => inthis.onClickViewData(e, perdata)}>{perdata.TenCoQuanBaoChi[0]}</td>
    <td>{vm.countNumber(perdata)}</td>
    <td>{rxu.getDate(rxu.get(perdata,['data',0,'updated_at']))}</td>
    <td></td>

  </tr>))
}

export const renderComponent = (vm) =>
  <AdminBlock name='DANH SÁCH LÃNH ĐẠO BỘ ĐÃ THẨM ĐỊNH'>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
      <RxCrudCard title='Hồ sơ' renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='review_8' api={'api_card_cardDone'} idEdit={vm.state.idEdit}
        form={[
            { type: 'header', func: (inthis, vm) => (
                <div className='rx-form__header'>
                    <div className='form-header-title'>
                        <div className='text-center title-header'>DANH SÁCH TRÌNH DUYỆT CẤP THẺ NHÀ BÁO 2021-2025</div>
                        <div className=''>
                            <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tên cơ quan báo chí: </div><b>{global.rxu.get(inthis.state.editingData, ['TenCoQuanBaoChi', 0], '')}</b></div>
                            <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tên cơ quan chủ quản: </div><b>{rxu.get(inthis.state, 'editingData.CoQuanBaoChiObj.0.CoQuanChuQuan')}</b></div>
                            <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Địa chỉ: </div>{rxu.get(inthis.state, 'editingData.CoQuanBaoChiObj.0.DiaChi')}</div>
                            <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp cơ quan báo chí đề nghị cấp: </div>{rxu.get(inthis.state, ['numCheck','check'], 0)}</div>


                            <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp đề nghị duyệt cấp: </div>{rxu.get(inthis.state,['dataCheck','check'], []).length}</div>
                            <div className='text-left rx-sub__header'><div className='text-left-fix-with'>Tổng số trường hợp chưa đủ điều kiện cấp: </div>{rxu.get(inthis.state,['dataNonCheck','check'], []).length}</div>
                        </div>

                        <div className='rx-list__card'>
                            <div className='text-left title-header'>I.DANH SÁCH ĐỀ NGHỊ DUYỆT ĐỔI, CẤP THẺ</div>
                            <div className='rxlist__card-table' style={{width: '100%', position: 'relative'}}>
                                <table style={{width: '100%'}}>
                                    <tbody>
                                        <tr>
                                            <td className='rx-table__form-header rxwidth--stt'>Số TT</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_office'>Họ và tên</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>Bút danh</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>Chức vụ</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>Chức danh</td>
                                            <td className='rx-table__form-header rxwidth--birth table--name_small'>Năm sinh</td>
                                            <td className='rx-table__form-header rxwidth--gender table--name_small'>Nam</td>
                                            <td className='rx-table__form-header rxwidth--gender table--name_small'>Nữ</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>Số thẻ 2021-2025</td>
                                            <td className='rx-table__form-header rxwidth--100 table--name_small'>Số thẻ 2016-2020</td>
                                            <td className='rx-table__form-header table--name_small'>Ghi chú</td>
                                        </tr>
                                        {rxu.get(inthis.state,['dataCheck','check'], []).map((obj, index) => {
                                            let history = obj.LichSuDuyet ? obj.LichSuDuyet[obj.LichSuDuyet.length - 1] : {}
                                            return <tr key={ index }>
                                                <td>{ index+1 }</td>
                                                <td className='rx-cursor textuppercase' onClick={(e) => inthis.onClickViewData(e, obj, 'review_8')}>{ obj.HoTenKhaiSinh || '' }</td>
                                                <td>{ obj.ButDanh || '' }</td>
                                                <td>{ obj.TenChucVu || '' }</td>
                                                <td>{ obj.ChucDanhBaoChi || '' }</td>
                                                <td>{ new Date(obj.NgaySinhConvert*1000).getUTCFullYear() }</td>
                                                <td>{ Number(obj.GioiTinh) === 1 ? 'x' : '' }</td>
                                                <td>{ Number(obj.GioiTinh) === 0 ? 'x' : '' }</td>
                                                <td>{ obj.MaSoThe || '' }</td>
                                                <td>{ obj.MaTheCu || '' }</td>
                                                <td><div className='rxtext__history' onClick={(e) => inthis.onClickShowHistory(obj.LichSuDuyet)}>{ rxu.get(history,['desc']).length > 40 ? rxu.get(history,['desc']).slice(0, 40) + '...' : rxu.get(history,['desc']) }</div></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            ) },
        ]}
      />
    </div>
  </AdminBlock>
