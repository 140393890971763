/* global t */
import React, { Component } from 'react'
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'

const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
// const RxToggle = global.rootRequire('components/shares/rxToggle').default
// const RxCheckbox = global.rootRequire('components/shares/rxCheckbox').default

const types    = ['', 'Cục Báo Chí', 'Cục Phát Thanh']
const rxStatus = ['Chưa duyệt', 'Đã duyệt']
const rxStatusStyle = ['unactive', 'active']
class userComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      arrrole: []
    }
    this.fetchData()
  }

  fetchData () {
    apiget(global.rxu.config.api_role_all, {}, { '1': (json) => { this.setState({ arrrole: json.data }) } })
  }

  renderTableHead (inthis) {
    return (<tr className='rx-title__table'>
      <th className={inthis.helpSortClass('fullname', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'CoQuanBaoChi')} >{t('Tên cơ quan báo chí')}</th>
      <th className={inthis.helpSortClass('username', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'fullname')} >{t('Họ tên người sử dụng')}</th>
      <th className={inthis.helpSortClass('Type', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'Type')} >{t('Cục quản lý')}</th>
      <th className={inthis.helpSortClass('phone', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'phone')}>{t('Số điện thoại')}</th>
      <th className={inthis.helpSortClass('phone', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'email')}>{t('Email')}</th>
      <th className={inthis.helpSortClass('status', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'IsUpdateProfile')}>{t('Tình trạng')}</th>
      <th className={inthis.helpSortClass('fullname', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'username')} >{t('Tài khoản')}</th>
      <th className={inthis.helpSortClass('created_at')} onClick={(e) => inthis.onClickSort(e, 'created_at')}>{t('Created at')}</th>
      <th className={inthis.helpSortClass('updated_at')} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>{t('Updated at')}</th>
      <th className='rxwidth--100'>{t('Action')}</th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td>{perdata.CoQuanBaoChi} - {perdata.Cat_Description}</td>
      <td>{perdata.fullname}</td>
      <td>{types[perdata.Type]}</td>
      <td>{perdata.phone}</td>
      <td>{perdata.email}</td>
      <td className={rxStatusStyle[perdata.IsUpdateProfile]}>{rxStatus[perdata.IsUpdateProfile]}</td>
      <td>{perdata.username}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td><small>{global.rxu.date(perdata.updated_at)}</small></td>
      <td className="align-right">
        { inthis.state.paging.search_is_deleted !== 1 &&
          <div> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => inthis.onClickDataDetail(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
             </div> }
        { inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  // R E N D E R S
  render () {
    return (
      <div className='adblock'>
        {/*<div className='adblock__head'>
          <div className='adblock__title'>{t('user')}</div>
          <div className='adblock__desc'>{t('user')}</div>
        </div>*/}
        <div className='adblock__body'>
          <div className=''>
            <div className='rxcol-100'>
              <RxCrud roles={this.state.arrrole} renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} name='Member' api={'api_member'}
                form={[
                  { type: 'form', name: 'Thông tin cơ quan báo chí', func: (inthis) => (<div>
                    <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Tên cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                          <RxSelectbox
                              name='cate'
                              options={inthis.state.cate}
                              results={inthis.state.editingData.CoQuanBaoChi || ''}
                              onChange={(result) => { inthis.onBlurDataValue(result, 'CoQuanBaoChi') }}
                              api='api_newspaper_office'
                              optionDefault='--Chọn cơ quan báo chí--'
                          />
                        </div>
                      </div>
                      {/*<div className='rx-form__item'>
                        <div>
                          <span>Mã số thuế<i className='iconcustom-require icon-require'/></span>
                          <input tabIndex='2' type='text' placeholder='Nhập Mã số thuế' className='fullwidth-input' onChange={(e) => this.onBlurData(e, 'MaSoThue')} />
                        </div>
                      </div>*/}
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Số giấy phép hoạt động báo chí<i className='iconcustom-require icon-require'/></span>
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['SoDKKD']} onChange={(e) => inthis.onBlurData(e, 'SoDKKD')} />
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Ngày cấp<i className='iconcustom-require icon-require'/></span>

                          {inthis.state.editingData['NgayCap'].indexOf('.') === -1 ?
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['NgayCap']} onChange={(e) => inthis.onBlurData(e, 'NgayCap')} />
                          :
                          <input disabled tabIndex='3' type='text' className='fullwidth-input' defaultValue={global.rxu.getDate(global.rxu.get(inthis.state, ['editingData', 'NgayCap'], new Date() / 1000))} />
                          }
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Địa chỉ cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['address']} onChange={(e) => inthis.onBlurData(e, 'address')} />
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Số điện thoại cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['telephone']} onChange={(e) => inthis.onBlurData(e, 'telephone')} />
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Email cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['email']} onChange={(e) => inthis.onBlurData(e, 'email')} />
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                        <span className='custom_width'>Số điện thoại di động<i className='iconcustom-require icon-require'/><br /><p>(để xác thực OTP nếu chọn đăng nhập bằng cổng Micconnect)</p> </span>
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['phone']} onChange={(e) => inthis.onBlurData(e, 'phone')} />
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Họ tên người sử dụng<i className='iconcustom-require icon-require'/></span>
                          <input tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['fullname']} onChange={(e) => inthis.onBlurData(e, 'fullname')} />
                        </div>
                      </div>

                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Tên đăng nhập (Hệ thống)<i className='iconcustom-require icon-require'/></span>
                          <input disabled={true} tabIndex='3' type='text' className='fullwidth-input' defaultValue={inthis.state.editingData['username']} />
                        </div>
                      </div>
                      <div className='rx-form__item'>
                        <div>
                          <span className='custom_width'>Mật khẩu (Hệ thống)<i className='iconcustom-require icon-require'/></span>
                          <input disabled={true} tabIndex='3' type='password' className='fullwidth-input' defaultValue={inthis.state.editingData['username']} />
                        </div>
                      </div>

                      <div className='rx-form__item'>
                        <div>
                          <div className='button_action'>
                            <span tabIndex='7' className='authform__btnsubmit register' onClick={(e) => inthis.onClickSyncSSO(e,inthis.state.editingData )} onKeyPress={(e) => inthis.onClickSyncSSO(e, inthis.state.editingData)} >Đồng bộ tài khoản lên MICCONECT</span>
                            <span tabIndex='8' className='authform__btnsubmit register' onClick={(e) => inthis.onClickSendEmail(e,inthis.state.editingData )} onKeyPress={(e) => inthis.onClickSendEmail(e, inthis.state.editingData)} >Duyệt & Gửi thông tin tài khoản</span>
                            {/*inthis.state.editingData['IsUpdateProfile'] === 1 && <span tabIndex='8' className='authform__btnsubmit register' onClick={(e) => inthis.onClickSendEmail(e,inthis.state.editingData )} onKeyPress={(e) => inthis.onClickSendEmail(e, inthis.state.editingData)} >Gửi thông tin tài khoản</span>*/}
                          </div>
                        </div>
                      </div>

                  </div>)},
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default userComponent
