
/* global CustomEvent */
// import * as ramda from 'ramda', // global.rxi = ramda
import jQuery from 'jquery'
import sweetAlert from 'sweetalert2'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Exceljs from 'exceljs'
import SaveAs from 'file-saver'
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import province from './province.json'
import { TimeNew, TimeNewBold, TimeNewI, TimeNewBI } from './TimeNewFont'

const pdfMake = require('pdfmake')
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfFonts.pdfMake.vfs['TimewNew_b64']=TimeNew
pdfFonts.pdfMake.vfs['TimewNewBold_b64']=TimeNewBold
pdfFonts.pdfMake.vfs['TimewNewI_b64']=TimeNewI
pdfFonts.pdfMake.vfs['TimewNewBI_b64']=TimeNewBI
require('dotenv').config()

// const docx = require('docx')
const saveAs = SaveAs

let chartist = {}
if (typeof window !== 'undefined') { chartist = require('chartist') }

let rxu = require('./classes/ulti').default
let translate = require('./classes/lang').default

if (!global.defined) {
  let secret = rxu.secret
  let theme = secret.theme || 'default'

  global.defined = true
  global.rxu = rxu
  global.currentTheme = theme
  global.$ = jQuery
  global.t = translate
  global.sweetAlert = sweetAlert
  global.Chart = Chart
  global.rxStatus = ['Không hoạt động','Hoạt động']
  global.Types    = [{id: 1, name: 'Cục Báo chí'}, {id: 2, name: 'Cục PTTH&TTĐT'}]
  global.isclient = typeof window !== 'undefined'
  global.rootbase = __dirname
  global.nodebase = global.rootbase + '../node_modules/'
  global.rootfont = __dirname + 'themes/styles/fonts/'
  global.rootRequire = function (name) {
    // eslint-disable-next-line
    return require(__dirname + '/' + name)
  }
  global.statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']

  global.allowedExtensions  = ['jpg','png','jpeg','pdf','PDF']
  global.sizeLimit =  135971520;

  global.rootRequiretheme = function (name) {
    // eslint-disable-next-line
    return require(__dirname + '/themes/' + theme + '/' + name)
  }
  pdfMake.fonts = {
     Roboto: {
         normal:      'Roboto-Regular.ttf',
         bold:        'Roboto-Medium.ttf',
         italics:     'Roboto-Italic.ttf',
         bolditalics: 'Roboto-MediumItalic.ttf'
     },
     TimeNew:{
         normal:      'TimewNew_b64',
         bold:        'TimewNewBold_b64',
         italics:     'TimewNewI_b64',
         bolditalics: 'TimewNewBI_b64'
     },
 }

  // global ulti
  global.Chart.plugins.unregister(ChartDataLabels)
  global.Chart.Legend.prototype.afterFit = function () { this.height = this.height + 20 }
  global.Chart.helpers.merge(global.Chart.defaults.global, { layout: { padding: { top: 32 } } })
  global.Chart.helpers.merge(global.Chart.defaults.global.plugins.datalabels, { align: 'end', anchor: 'end', color: '#000000', font: { size: 14, weight: 'bold' } })
  global.rootContext = function (context) {
    if (typeof window !== 'undefined') {
      context = context || window.__STATIC_CONTEXT__
    }
    return context
  }

  global.JSONToCSVConvertorMau3 = function (JSONData, ReportTitle, ShowLabel = true) {
    var fontSizeSuperScript = 12
    var dd = {
      pageOrientation: 'landscape',
      pageMargins: [ 85, 42, 56.69, 42 ],
      content: [
        {
          margin: [28,0,0,28],
          stack: [
            'Mẫu số 03. Bản tổng hợp danh sách đề nghị cấp thẻ nhà báo',
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 3,
                  x2: 332,
                  y2: 3,
                  lineWidth: .5
                }
              ]
            }
          ],
          alignment: 'left',
          bold: true,
          fontSize: 13
        }, {
          text: 'TỔNG HỢP DANH SÁCH ĐỀ NGHỊ CẤP THẺ NHÀ BÁO',
          style: 'header'
        }, {
          text: [
            {text:'Kỳ hạn: 2021 - 2025', style: 'subheader'},
            {text:' (1)', sup: { offset: '30%', fontSizeSuperScript }},
          ], 
          alignment: 'center',
        }, {
          margin: [28,28,28,28],
          alignment: 'justify',
          columns: [
            [
              { text: 'Cơ quan đề nghị cấp thẻ nhà báo: ' + global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'Cat_Name'], ''), fontSize: 12 },
              { text: '+ Địa chỉ: ' + global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'DiaChi'], ''), fontSize: 12, margin: [115,0,0,0] },
              { text: '+ Điện thoại: ' + global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'DienThoai'], ''), fontSize: 12, margin: [115,0,0,0] },
            ],
            // {
            //   text: global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'Cat_Name'], '') + '\n' + global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'DiaChi'], '') + '\n' + global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'DienThoai'], ''), 
            //   fontSize: 12,
            //   width: '*' 
            // },
            { text: '+ Cơ quan chủ quản: ' + global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'CoQuanChuQuan'], ''), fontSize: 12, alignment: 'right', width: 226 }
          ]
        }, {
          id: 'DanhSach',
          style: 'table',
          table: {
            widths: [14,'auto','auto',25,'auto',22,25,30,25,30,25,28,'auto',28,28,'auto','auto','auto'],
            body: [
              [
                { text: 'STT', style: 'table', rowSpan: 3, wraptext: false, alignment: 'center' },
                { text: 'Họ và tên', style: 'table', rowSpan: 3, wraptext: false, alignment: 'center' },
                { text: 'Số định danh cá nhân', style: 'table', rowSpan: 3, wraptext: true, alignment: 'center' },
                { text: [{text: 'Giới tính', style: 'table'}, {text:'(2)', sup: { offset: '30%', fontSizeSuperScript }}], rowSpan: 3, wraptext: true , alignment: 'center'},
                { text: [{text: 'Ngày, tháng,  năm sinh', style: 'table'}, {text:'(2)', sup: { offset: '30%', fontSizeSuperScript }}], rowSpan: 3, wraptext: true, alignment: 'center' },
                { text: [{text: 'Dân tộc', style: 'table'}, {text:'(2)', sup: { offset: '30%', fontSizeSuperScript }}], rowSpan: 3, wraptext: true, alignment: 'center' },
                { text: 'Bút danh', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
                { text: 'Trình độ chuyên môn', style: 'table', colSpan: 5, wrapText: true, alignment: 'center' }, {}, {}, {}, {},
                { text: 'Trình độ lý luận chính trị', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
                { text: 'Đảng viên', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
                { text: 'Bậc lương hiện nay', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
                { text: 'Chức vụ', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
                { text: 'Chức danh báo chí', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
                { text: 'Số thẻ nhà báo đã cấp (nếu có)', style: 'table', rowSpan: 3, wrapText: true, alignment: 'center' },
              ],
              [
                {}, {}, {}, {}, {}, {}, {},
                { text: 'Báo chí', colSpan: 2, style: 'table', alignment: 'center' }, {},
                { text: 'Chuyên ngành khác', colSpan: 2, style: 'table', alignment: 'center' }, {},
                { text: 'Hình thức đào tạo', rowSpan: 2, style: 'table', alignment: 'center' },
                {}, {}, {}, {}, {}, {}
              ],
              [
                {}, {}, {}, {}, {}, {}, {},
                { text: [{text: 'SĐH', style: 'table'}, {text:'(3)', sup: { offset: '30%', fontSizeSuperScript }}], alignment: 'center', wrapText: false },
                { text: [{text: 'ĐH', style: 'table'}, {text:'(4)', sup: { offset: '30%', fontSizeSuperScript }}], alignment: 'center', wrapText: false },
                { text: [{text: 'SĐH', style: 'table'}, {text:'(3)', sup: { offset: '30%', fontSizeSuperScript }}], alignment: 'center', wrapText: false },
                { text: [{text: 'ĐH', style: 'table'}, {text:'(4)', sup: { offset: '30%', fontSizeSuperScript }}], alignment: 'center', wrapText: false },
                {}, {}, {}, {}, {}, {}, {}
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) { return .5; },
            vLineWidth: function (i, node) { return .5; },
            // hLineColor: function (i, node) { return 'blue'; },
            // vLineColor: function (i, node) { return 'blue'; },
          }
        }, {
          columnGap: 20,
          margin: [0, 10, 0, 60],
          columns: [ [],
            [
              { text: 'Người đứng đầu cơ quan\nđề nghị cấp thẻ nhà báo', style: 'subheader' },
              { text: '(Ký, đóng dấu hoặc ký số)', style: 'sign' }
            ],
          ],
        },
        {
          columnGap: 0,
          columns: [
            { text: [{text: 'Lưu ý: ', bold: true}, {text: 'Bản tổng hợp danh sách đề nghị cấp thẻ nhà báo phải tổng hợp đầy đủ các yêu cầu trong các mục, nếu thiếu sẽ bị coi là không hợp lệ.'}], fontSize: 12 },
          ]
        }, {
          text: '\nGhi chú:', bold: true, fontSize: 12
        }, {
          text: [
            { text: '(1) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Kỳ hạn: Ví dụ: 2021 - 2025.' },
            { text: '\n(2) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Khi Cơ sở dữ liệu quốc gia về dân cư hoàn thiện kết nối, chia sẻ thông tin về công dân cho cơ sở dữ liệu chuyên ngành, Cổng dịch vụ công của Bộ Thông tin và Truyền thông, cơ quan đề nghị cấp thẻ nhà báo không phải tổng hợp các thông tin về Giới tính, Ngày, tháng, năm sinh, Dân tộc.' },
            { text: '\n(3) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'SĐH: Sau đại học.' },
            { text: '\n(4) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'ĐH: Đại học.' }
          ],
          fontSize: 12
        }
      ],
      styles: {
        header: {
          fontSize: 13,
          bold: true,
          alignment: 'center'
        },
        subheader: {
          fontSize: 12,
          bold: true,
          alignment: 'center'
        },
        table: {
          fontSize: 10,
          // bold: true,
          // alignment: 'center'
        },
        nomal: {
          fontSize: 13,
        },
        sign: {
          fontSize: 13,
          italics: true,
          alignment: 'center'
        },
        tableQuaTrinhHD: {
          margin: [0, 5, 0, 15]
        },
      },
      defaultStyle: {
        fontSize: 13,
        font: 'TimeNew'
      }
    }
    let index = dd.content.findIndex(o => o['id'] === 'DanhSach')
    if(index !== -1) {
      let table = dd.content[index]['table']['body'] || []
      let dataCard = typeof JSONData.data !== 'object' ? JSON.parse(JSONData.data) : JSONData.data
      // let infoCard = typeof JSONData.DanhSachTacPham !== 'object' ? JSON.parse(JSONData.DanhSachTacPham) : JSONData.DanhSachTacPham
      for(let m in dataCard) {
        let TrinhDoBaoChiTDH          = rxu.get(dataCard[m], ['NganhHocID'],[]).indexOf(13) !== -1 ? ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(dataCard[m], ['TrinhDoChuyenMon', '0'])) === -1 ? rxu.get(dataCard[m], ['TrinhDoChuyenMon']) : '' : ''
        let TrinhDoBaoChiDH           = rxu.get(dataCard[m], ['NganhHocID'],[]).indexOf(13) !== -1 ? ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(dataCard[m], ['TrinhDoChuyenMon', '0'])) !== -1 ? rxu.get(dataCard[m], ['TrinhDoChuyenMon']) : '' : ''
        let TrinhDoChuyenNganhKhacTDH = rxu.get(dataCard[m], ['NganhHocID'],[]).indexOf(13) === -1 ? ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(dataCard[m], ['TrinhDoChuyenMon', '0'])) === -1 ? rxu.get(dataCard[m], ['TrinhDoChuyenMon']) : '' : ''
        let TrinhDoChuyenNganhKhacDH  = rxu.get(dataCard[m], ['NganhHocID'],[]).indexOf(13) === -1 ? ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(dataCard[m], ['TrinhDoChuyenMon', '0'])) !== -1 ? rxu.get(dataCard[m], ['TrinhDoChuyenMon']) : '' : ''
        let HoTenKhaiSinh             = rxu.get(dataCard[m], ['HoTenKhaiSinh'], '').toUpperCase()
        let tmp = [
            { text: Number(m) + 1, alignment: 'center' }, 
            { text: HoTenKhaiSinh }, 
            { text: rxu.get(dataCard[m], ['CMND'], '') }, 
            { text: rxu.rxparseTen('GioiTinh', global.rxu.get(dataCard[m], ['GioiTinh'], '')), alignment: 'center' }, 
            { text: rxu.getDate(global.rxu.get(dataCard[m], ['NgaySinhConvert'], '')) }, 
            { text: rxu.get(dataCard[m], ['DanToc'], '') }, 
            { text: rxu.get(dataCard[m], ['ButDanh'], '') }, 

            { text: TrinhDoBaoChiTDH }, 
            { text: TrinhDoBaoChiDH },  
            { text: TrinhDoChuyenNganhKhacTDH }, 
            { text: TrinhDoChuyenNganhKhacDH }, 
            { text: rxu.ArrayToString(global.rxu.get(dataCard[m], ['HinhThucDaoTao'], '')) }, 
            { text: rxu.get(dataCard[m], ['ChinhTri'], '') }, 
            { text: rxu.get(dataCard[m], ['DangVien'], '') }, 
            { text: rxu.get(dataCard[m], ['BacLuong'], '') }, 
            { text: rxu.get(dataCard[m], ['TenChucVu'], '') }, 
            { text: rxu.get(dataCard[m], ['ChucDanhBaoChi'], '') }, 
            { text: rxu.get(dataCard[m], ['MaTheCu'], '') }, 
          ]
        table[table.length] = tmp
      }
      dd.content[index]['table']['body'] = table
    }
    const pdfDocGenerator = pdfMake.createPdf(dd)
    pdfDocGenerator.getBlob((blob) => {
      saveAs(blob, "MauSo_03.pdf")
    });
  }

  global.JSONToCSVConvertor8 = function (JSONData, numCheck, dataCheck, dataNonCheck) {
    // console.log(JSONData, 'sdjbfh')
    // let dataCard = typeof JSONData.data !== 'object' ? JSON.parse(JSONData.data) : JSONData.data
    dataCheck = typeof dataCheck.check !== 'object' ? JSON.parse(dataCheck.check) : dataCheck.check
    dataNonCheck = typeof dataNonCheck.check !== 'object' ? JSON.parse(dataNonCheck.check) : dataNonCheck.check
    let font = { name: 'Times New Roman', size: 11, bold: true }
    let alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true}
    let border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    // Xuat thong tin the
    let workbookCard = new Exceljs.Workbook()

    if ((dataCheck && dataCheck.length > 0) || (dataNonCheck && dataNonCheck.length > 0) ) {
      // workbook info
      workbookCard.Props = {
          Title: "Journalist",
          Subject: "CẤP THẺ NHÀ BÁO",
          Author: "Journalist",
          CreatedDate: global.rxu.date(new Date())
      };
      let namepost = 'DanhSachTrinh'
      let ws = workbookCard.addWorksheet(namepost, {
        pageSetup:{paperSize: 9, orientation:'landscape'} // print
      })
      ws.views = [{
        showGridLines : false
      }]
      //setup print layout
      ws.pageSetup.margins = {
        left: 0.51, right: 0.31,
        top: 0.51, bottom: 0.51,
        header: 0.31, footer: 0.31
      };
      ws.pageSetup.printTitlesRow = '10:10';
      ws.pageSetup.horizontalCentered = true;

      ws.getCell('A1').value = 'DANH SÁCH TRÌNH DUYỆT CẤP THẺ NHÀ BÁO 2021-2025'
      ws.getCell('A1').alignment = alignment
      ws.getCell('A1').font = { name: 'Times New Roman', size: 14, bold: true }
      ws.getRow(1).height = 50
      ws.mergeCells('A1:J1')

      ws.getCell('A2').value = 'Tên cơ quan báo chí: '
      ws.getCell('A3').value = 'Tên cơ quan chủ quản: '
      ws.getCell('A4').value = 'Địa chỉ: '
      ws.getCell('A5').value = 'Tổng số trường hợp cơ quan báo chí đề nghị cấp: '
      ws.getCell('A6').value = 'Tổng số trường hợp đề nghị duyệt cấp: '
      ws.getCell('A7').value = 'Tổng số trường hợp chưa đủ điều kiện cấp: '

      ws.getCell('E2').value = global.rxu.get(JSONData, 'CoQuanBaoChiObj.Cat_Name', '')
      ws.getCell('E3').value = global.rxu.get(JSONData, 'CoQuanBaoChiObj.CoQuanChuQuan', '')
      ws.getCell('E4').value = global.rxu.get(JSONData, 'CoQuanBaoChiObj.DiaChi', '')
      ws.getCell('E5').value = rxu.get(JSONData, 'total_data')
      ws.getCell('E6').value = dataCheck.length
      ws.getCell('E7').value = dataNonCheck.length

      for(let i = 2; i < 8; i++) {
        ws.mergeCells('A'+i+':D'+i)
        ws.mergeCells('E'+i+':J'+i)
        ws.getCell('A'+i).font = {...font, bold: false}
        ws.getCell('E'+i).font = {...font}
        ws.getCell('E'+i).alignment = {...alignment, horizontal: 'left', wrapText: true}
      }

      // Duoc cap
      ws.getCell('A9').value = 'I.DANH SÁCH ĐỀ NGHỊ DUYỆT ĐỔI, CẤP THẺ'
      ws.getCell('A9').font = font
      ws.getRow(9).height = 30
      ws.mergeCells('A9:J9')

      ws.getRow(10).values = ['Số TT','Họ và tên', 'Bút danh', 'Chức vụ', 'Chức danh', 'Năm sinh', 'Nam', 'Nữ', 'Số thẻ 2016-2020', 'Ghi chú']
      ws.getRow(10).height = 40

      let arrCellPost = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

      for (let j in arrCellPost) {
        for (let i = 10; i < 11; i ++) {
          ws.getCell(arrCellPost[j]+i).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
          ws.getCell(arrCellPost[j]+i).font = font
          ws.getCell(arrCellPost[j]+i).border = border
        }
      }

      ws.columns = [
        {key: 'stt', width: 4 },
        {key: 'HoTenKhaiSinh', width: 22 },
        {key: 'ButDanh', width: 12 },
        {key: 'ChucVu', width: 12 },
        {key: 'ChucDanhBaoChi', width: 12 },
        {key: 'NamSinh', width: 7 },
        {key: 'Nam', width: 5 },
        {key: 'Nu', width: 5 },
        {key: 'MaTheCu', width: 12 },
        {key: 'GhiChu', width: 38 }
      ]
      let originForData = 11
      for(let m = 0; m < dataCheck.length; m ++) {
        let history = dataCheck[m].LichSuDuyet ? dataCheck[m].LichSuDuyet[dataCheck[m].LichSuDuyet.length - 1] : {}

        ws.addRow({
          stt:             (m+1),
          HoTenKhaiSinh:   rxu.get(dataCheck[m], ['HoTenKhaiSinh'], '').toUpperCase(),
          ButDanh:         rxu.get(dataCheck[m], ['ButDanh'], ''),
          ChucVu:          rxu.get(dataCheck[m], ['TenChucVu'], ''),
          ChucDanhBaoChi:  rxu.get(dataCheck[m], ['ChucDanhBaoChi'], ''),
          NamSinh:         new Date(rxu.get(dataCheck[m], ['NgaySinhConvert'], 0)*1000).getUTCFullYear(),
          Nam:             Number(rxu.get(dataCheck[m], ['GioiTinh'], '')) === 1 ? 'x' : '',
          Nu:              Number(rxu.get(dataCheck[m], ['GioiTinh'], '')) === 0 ? 'x' : '',
          MaTheCu:         rxu.get(dataCheck[m], ['MaTheCu'], ''),
          GhiChu:          rxu.get(history, 'desc'),
        })
        originForData += 1
      }

      for (let j in arrCellPost) {
        for (let k = 11; k < originForData; k++) {
          ws.getCell(arrCellPost[j]+k).font = {...font, bold: false }
          if(arrCellPost[j] === 'I') {
            ws.getCell(arrCellPost[j]+k).alignment = {wrapText:true, vertical: 'top' }
          } else {
            ws.getCell(arrCellPost[j]+k).alignment = {wrapText:true, vertical: 'middle' }
          }
          ws.getCell(arrCellPost[j]+k).border = { bottom: {style : 'dotted'}, right: {style : 'thin'}, left: {style : 'thin'}}
        }
        ws.getCell(arrCellPost[j]+Number(originForData-1)).border = { bottom: {style : 'thin'}, right: {style : 'thin'}, left: {style: 'thin'}}
      }

      let arrAlignment = ['A', 'G', 'H']
      let arrAlignmentLeft = ['B', 'C', 'D', 'E', 'I']
      for (let a in arrAlignment) {
        for (let b = 11; b < originForData; b++) {
          ws.getCell(arrAlignment[a]+b).alignment = alignment
        }
      }
      for (let a in arrAlignmentLeft) {
        for (let b = 11; b < originForData; b++) {
          ws.getCell(arrAlignmentLeft[a]+b).alignment = {...alignment, horizontal: 'left'}
        }
      }
      originForData++

      // Khong duoc cap
      ws.getCell('A' + originForData).value = 'II.DANH SÁCH CHƯA ĐỦ ĐIỀU KIỆN DUYỆT ĐỐI, CẤP THẺ'
      ws.getCell('A' + originForData).font = font
      ws.getRow(originForData).height = 30
      ws.mergeCells('A'+originForData+':J'+originForData)
      originForData++

      ws.getRow(originForData).values = ['Số TT','Họ và tên', 'Bút danh', 'Chức vụ', 'Chức danh', 'Năm sinh', 'Nam', 'Nữ', 'Số thẻ 2016-2020', 'Ghi chú']
      ws.getRow(originForData).height = 40

      for (let j in arrCellPost) {
        for (let i = originForData; i < originForData+1; i ++) {
          ws.getCell(arrCellPost[j]+i).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
          ws.getCell(arrCellPost[j]+i).font = font
          ws.getCell(arrCellPost[j]+i).border = border
        }
      }

      originForData++
      let originForDataTmp = originForData

      if(dataNonCheck && dataNonCheck.length > 0) {
        for(let m = 0; m < dataNonCheck.length; m ++) {
          let history = dataNonCheck[m].LichSuDuyet ? dataNonCheck[m].LichSuDuyet[dataNonCheck[m].LichSuDuyet.length - 1] : {}

          ws.addRow({
            stt:             (m+1),
            HoTenKhaiSinh:   rxu.get(dataNonCheck[m], ['HoTenKhaiSinh'], '').toUpperCase(),
            ButDanh:         rxu.get(dataNonCheck[m], ['ButDanh'], ''),
            ChucVu:          rxu.get(dataNonCheck[m], ['TenChucVu'], ''),
            ChucDanhBaoChi:  rxu.get(dataNonCheck[m], ['ChucDanhBaoChi'], ''),
            NamSinh:         new Date(rxu.get(dataNonCheck[m], ['NgaySinhConvert'], 0)*1000).getUTCFullYear(),
            Nam:             Number(rxu.get(dataNonCheck[m], ['GioiTinh'], '')) === 1 ? 'x' : '',
            Nu:              Number(rxu.get(dataNonCheck[m], ['GioiTinh'], '')) === 0 ? 'x' : '',
            MaTheCu:         rxu.get(dataNonCheck[m], ['MaTheCu'], ''),
            GhiChu:          rxu.get(history, 'desc'),
          })
          originForData += 1
        }
      } else {
        ws.addRow({
          stt:             '',
          HoTenKhaiSinh:   '',
          ButDanh:         '',
          ChucVu:          '',
          ChucDanhBaoChi:  '',
          NamSinh:         '',
          Nam:             '',
          Nu:              '',
          MaTheCu:         '',
          GhiChu:          '',
        })
        originForData += 1
      }

      for (let j in arrCellPost) {
        for (let k = originForDataTmp; k < originForData; k++) {
          ws.getCell(arrCellPost[j]+k).font = {...font, bold: false }
          if(arrCellPost[j] === 'I') {
            ws.getCell(arrCellPost[j]+k).alignment = {wrapText:true, vertical: 'top' }
          } else {
            ws.getCell(arrCellPost[j]+k).alignment = {wrapText:true, vertical: 'middle' }
          }
          ws.getCell(arrCellPost[j]+k).border = { bottom: {style : 'dotted'}, right: {style : 'thin'}, left: {style : 'thin'}}
        }
        ws.getCell(arrCellPost[j]+Number(originForData-1)).border = { bottom: {style : 'thin'}, right: {style : 'thin'}, left: {style: 'thin'}}
      }

      for (let a in arrAlignment) {
        for (let b = originForDataTmp; b < originForData; b++) {
          ws.getCell(arrAlignment[a]+b).alignment = alignment
        }
      }
      for (let a in arrAlignmentLeft) {
        for (let b = originForDataTmp; b < originForData; b++) {
          ws.getCell(arrAlignmentLeft[a]+b).alignment = {...alignment, horizontal: 'left'}
        }
      }
      originForData+=2
      //SIGN
      ws.getCell('A'+originForData).value = 'Ngày    ' + rxu.getFullMonth()
      ws.getCell('D'+originForData).value = 'Ngày    ' + rxu.getFullMonth()
      ws.getCell('I'+originForData).value = 'Ngày    ' + rxu.getFullMonth()
      // ws.getCell('J'+originForData).value = 'Ngày    ' + rxu.getFullMonth()
      originForData++
      if(JSONData.CoQuanBaoChiObj && JSONData.CoQuanBaoChiObj['Type'] === 2) {
        ws.getCell('A'+originForData).value = 'Cục trưởng PTTH&TTĐT' // Lưu Đình Phúc
      } else {
        ws.getCell('A'+originForData).value = 'Cục trưởng Cục Báo chí' // Nguyễn Thanh Lâm
      }
      ws.getCell('D'+originForData).value = 'Thứ trưởng' // Phạm Anh Tuấn, Hoàng Vĩnh Bảo
      ws.getCell('I'+originForData).value = 'Bộ trưởng' // Nguyễn Mạnh Hùng
      // ws.getCell('J'+originForData).value = 'Lãnh đạo Bộ duyệt'
      originForData += 7

      if(JSONData.CoQuanBaoChiObj && JSONData.CoQuanBaoChiObj['Type'] === 2) {
        ws.getCell('A'+originForData).value = 'Lê Quang Tự Do'
        ws.getCell('D'+originForData).value = 'Nguyễn Thanh Lâm'
      } else {
        ws.getCell('A'+originForData).value = 'Lưu Đình Phúc'
        ws.getCell('D'+originForData).value = 'Nguyễn Thanh Lâm'
      }

      ws.getCell('I'+originForData).value = 'Nguyễn Mạnh Hùng'

      let arrSign = ['A', 'D', 'I']
      for(let i = originForData - 8; i < originForData + 1; i++) {
        ws.mergeCells('A'+i+':C'+i)
        ws.mergeCells('D'+i+':H'+i)
        ws.mergeCells('I'+i+':J'+i)
        // ws.mergeCells('H'+i+':J'+i)
        for(let j in arrSign) {
          ws.getCell(arrSign[j] + i).font = (i === originForData || i === originForData - 7) ? font : {...font, bold: false}
          ws.getCell(arrSign[j] + i).alignment = alignment
        }
      }

      workbookCard.xlsx.writeBuffer().then(function (dataCard) {
        let blobpost = new Blob([dataCard], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

        saveAs(blobpost, 'DANH SÁCH TRÌNH DUYỆT CẤP THẺ NHÀ BÁO 2021-2025');
      });
    }
  }

  /*global.JSONToCSVConvertor2 = function (JSONData) {
    let dataCard = typeof JSONData.DanhSachTacPham !== 'object' ? JSON.parse(JSONData.DanhSachTacPham) : JSONData.DanhSachTacPham
    let font = { name: 'Times New Roman', size: 12, bold: true }
    let alignment = { vertical: 'middle', horizontal: 'center' }
    let border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    // Xuat thong tin the
    let workbookCard = new Exceljs.Workbook()

    if (dataCard && dataCard.length > 0) {
      // workbook info
      workbookCard.Props = {
          Title: "Journalist",
          Subject: "Mẫu số 02",
          Author: "Journalist",
          CreatedDate: global.rxu.date(new Date())
      };
      let namepost = 'DANHSACHTACPHAM'
      let ws = workbookCard.addWorksheet(namepost)
      ws.views = [{
        showGridLines : false
      }]

      ws.getCell('F1').value = 'Mẫu số 02'
      ws.getCell('F1').font = font
      ws.getCell('A2').value = 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM'
      ws.getCell('A2').font = { name: 'Times New Roman', size: 14, bold: true }

      ws.getCell('A3').value = 'Độc lập - Tự do - Hạnh phúc'
      ws.getCell('A3').font = font

      ws.getCell('A4').value = '---------------'
      ws.getCell('A2').font = font
      ws.getCell('A6').value = 'DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT'
      ws.getCell('A6').font = { name: 'Times New Roman', size: 20, bold: true }
      ws.getRow(6).height = 50

      for(let i = 2; i < 7; i++) {
        ws.mergeCells('A'+i+':F'+i)
        ws.getCell('A'+i).alignment = alignment
      }
      // Header
      // let originForData = 5
      ws.getRow(8).values = ['STT','Tên tác phẩm báo chí', 'Loại hình báo chí', 'Tác giả, nhóm tác giả', 'Ngày, tháng, năm đăng, phát', 'Cơ quan báo chí đăng, phát']
      ws.getRow(8).height = 50
      let arrCellPost = ['A', 'B', 'C', 'D', 'E', 'F']

      for (let j in arrCellPost) {
        ws.getCell(arrCellPost[j]+8).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
        ws.getCell(arrCellPost[j]+8).font = font
        ws.getCell(arrCellPost[j]+8).border = border
      }

      ws.columns = [
        {key: 'stt', width: 10 },
        {key: 'Ten', width: 30 },
        {key: 'LoaiHinhBC', width: 20 },
        {key: 'TacGia', width: 20 },
        {key: 'NgayDang', width: 20 },
        {key: 'CoQuanDang', width: 20 }
      ]

      let originForData = 9
      for(let m = 0; m < dataCard.length; m ++) {
        let data = dataCard[m]
        ws.addRow({
          stt:          (m+1),
          Ten:           global.rxu.get(data, ['Ten'], ''),
          LoaiHinhBC:    global.rxu.get(data, ['LoaiHinhBC'], ''),
          TacGia:        global.rxu.get(data, ['TacGia'], ''),
          NgayDang:      global.rxu.get(data, ['NgayDang'], ''),
          CoQuanDang:    global.rxu.get(data, ['CoQuanDang'], '')
        })
        originForData += 1
      }

      for (let j in arrCellPost) {
        for (let k = 9; k < originForData; k++) {
          ws.getCell('A'+k).alignment = alignment
          ws.getCell(arrCellPost[j]+k).font = {...font, bold: false }
          ws.getCell(arrCellPost[j]+k).border = { bottom: {style : 'dotted'}, right: {style : 'thin'}}
        }
        ws.getCell(arrCellPost[j]+Number(originForData-1)).border = { bottom: {style : 'thin'}, right: {style : 'thin'}}
      }

      originForData++
      //SIGN
      ws.mergeCells('A'+originForData+':C'+originForData)
      ws.getCell('A'+originForData).value = 'Người đứng đầu cơ quan báo chí đăng, '
      ws.getCell('A'+originForData).font = font
      ws.getCell('A'+originForData).alignment = alignment

      ws.mergeCells('D'+originForData+':F'+originForData)
      ws.getCell('D'+originForData).value = '……., ngày.... tháng …. năm ……'
      ws.getCell('D'+originForData).alignment = alignment
      originForData++
      ws.mergeCells('A'+originForData+':C'+originForData)
      ws.getCell('A'+originForData).value = 'phát tác phẩm báo chí'
      ws.getCell('A'+originForData).font = font
      ws.getCell('A'+originForData).alignment = alignment

      ws.mergeCells('D'+originForData+':F'+originForData)
      ws.getCell('D'+originForData).value = 'Người khai'
      ws.getCell('D'+originForData).alignment = alignment
      ws.getCell('D'+originForData).font = font
      originForData++
      ws.mergeCells('A'+originForData+':C'+originForData)
      ws.getCell('A'+originForData).value = '(Ký tên, đóng dấu xác nhận)'
      ws.getCell('A'+originForData).font = {...font, bold: false, italic: true, }
      ws.getCell('A'+originForData).alignment = alignment

      ws.mergeCells('D'+originForData+':F'+originForData)
      ws.getCell('D'+originForData).value = '(Ký và ghi rõ họ tên)'
      ws.getCell('D'+originForData).font = {...font, bold: false, italic: true, }
      ws.getCell('D'+originForData).alignment = alignment

      for(let i = originForData+1; i < originForData+6; i++) {
        ws.mergeCells('A'+i+':C'+i)
        ws.mergeCells('D'+i+':F'+i)
      }

      workbookCard.xlsx.writeBuffer().then(function (dataCard) {
        let blobpost = new Blob([dataCard], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

        saveAs(blobpost, 'DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT_MauSo2');
      });
    }
  }*/

  global.JSONToCSVConvertor2 = function (JSONData) {
    var dd = {
      pageOrientation: 'landscape',
      pageMargins: [ 85, 56.69, 56.69, 56.69 ],
      content: [
        {
          stack: [
            'Mẫu số 02. Danh sách tác phẩm báo chí đã đăng, phát',
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 3,
                  x2: 300,
                  y2: 3,
                  lineWidth: .5
                }
              ]
            }
          ],
          alignment: 'left',
          bold: true,
          fontSize: 13
        }, {
          stack: [
            {
              text: "\nCỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\nĐộc lập - Tự do - Hạnh phúc \n",
              style: 'subheader',
            },
            { 
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 3,
                  x2: 160,
                  y2: 3,
                  lineWidth: .5
                }
              ],
              style: 'subheader'
            },
          ]
        }, {
          margin: [0, 20, 0, 10],
          text: 'DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT',
          style: 'header'
        },
        {
          id: 'DanhSachTacPham',
          style: 'tableQuaTrinhHD',
          table: {
            body: [
              [
                { text: 'STT', style: 'table', wraptext: false },
                { text: 'Tên tác phẩm báo chí', style: 'table', wraptext: false },
                { text: 'Loại hình báo chí', style: 'table', wraptext: false },
                { text: 'Tác giả, nhóm tác giả', style: 'table', wraptext: false },
                { text: 'Ngày, tháng, năm đăng, phát', style: 'table', wraptext: false },
                { text: 'Cơ quan báo chí đăng, phát', style: 'table', wraptext: false },
              ],
            ]
          },
          layout: {
            hLineWidth: function (i, node) { return .5; },
            vLineWidth: function (i, node) { return .5; },
            // hLineColor: function (i, node) { return 'blue'; },
            // vLineColor: function (i, node) { return 'blue'; },
          }
        }, {
          columnGap: 20,
          margin: [0, 10, 0, 40],
          columns: [
            [
              { text: 'Người đứng đầu cơ quan báo chí \nđăng, phát tác phẩm báo chí\n', style: 'subheader' },
              { text: '(Ký, đóng dấu hoặc ký số)', style: 'sign' }
            ],
            [
              { text: rxu.getFullDate(new Date() / 1000) + '\n', alignment: 'center', italics: true },
              { text: 'Người khai\n', style: 'subheader' },
              { text: '(Ký, ghi rõ họ tên hoặc ký số)', style: 'sign' }
            ]
          ],
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        subheader: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        table: {
          fontSize: 13,
          bold: true,
          alignment: 'center'
        },
        nomal: {
          fontSize: 14,
        },
        sign: {
          fontSize: 14,
          italics: true,
          alignment: 'center'
        },
        tableQuaTrinhHD: {
          margin: [0, 5, 0, 15]
        },
      },
      defaultStyle: {
        fontSize: 14,
        font: 'TimeNew'
      }
    }
    let index = dd.content.findIndex(o => o['id'] === 'DanhSachTacPham')
    if(index !== -1) {
      let table = dd.content[index]['table']['body'] || []
      let infoCard = typeof JSONData.DanhSachTacPham !== 'object' ? JSON.parse(JSONData.DanhSachTacPham) : JSONData.DanhSachTacPham
      for(let i in infoCard) {
        let tmp = [
            Number(i) + 1,
            rxu.get(infoCard[i], ['Ten'], '1'),
            rxu.get(infoCard[i], ['LoaiHinhBC'], '2'),
            rxu.get(infoCard[i], ['TacGia'], '3'),
            rxu.get(infoCard[i], ['NgayDang'], '4'),
            rxu.get(infoCard[i], ['CoQuanDang'], '5'),
          ]
        table[table.length] = tmp
      }
      dd.content[index]['table']['body'] = table
    }
    const pdfDocGenerator = pdfMake.createPdf(dd)
    pdfDocGenerator.getBlob((blob) => {
      saveAs(blob, "DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT_MauSo2.pdf")
    });
  }
  global.getBase64ImageFromURL = function (url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  global.JSONToCSVConvertor1 = async function (JSONData, ReportTitle, ShowLabel = true) {
    var fontSizeSuperScript = 12
    var dd = {
      pageMargins: [ 85, 42, 56.69, 42 ],
      content: [
        {
          stack: [
            'Mẫu số 01. Bản khai đăng ký cấp thẻ nhà báo',
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 3,
                  x2: 252,
                  y2: 3,
                  lineWidth: .5
                }
              ]
            }
          ],
          alignment: 'left',
          bold: true,
          fontSize: 13
        },
        {
          margin: [0, 20, 0, 0],
          columns: [
            {
              margin: [-28, 0, 0, 0],
              image: await global.getBase64ImageFromURL(
                global.rxu.config.base_api + '/upload/image/' + (JSONData.img_landscape || 'ico_app_default.jpg')
              ),
              width: 70
            },
            {
              stack: [
                {
                  text: "\nCỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\nĐộc lập - Tự do - Hạnh phúc \n",
                  style: 'subheader',
                },
                { 
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 2,
                      x2: 160,
                      y2: 2,
                      lineWidth: .5
                    }
                  ],
                  style: 'subheader'
                },
                {
                  text: '\nBẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO',
                  style: 'header'
                },
                {
                  text: [
                    {text:'Kỳ hạn: 2021 - 2025'},
                    {text:' (2)', sup: { offset: '30%', fontSizeSuperScript }},
                  ], 
                  alignment: 'center' 
                },
              ],
              width: '*',
            },
            {
              type: 'none',
              ul: [
                {
                  qr: rxu.get(JSONData, ['code'], ''), fit: '70', alignment: 'center'
                }, {
                  text: rxu.get(JSONData, ['code'], ''), alignment: 'center', fontSize: 10
                }
              ],
              alignment: 'right',
              width: 70
            }
          ],
        }, 
        {
          margin: [0, 15, 0, 0],
          text: 'Tên cơ quan đề nghị cấp thẻ nhà báo: ' + rxu.get(JSONData, ['TenCoQuanBaoChi'], ''),
          alignment: 'center',
          italics: true
        }, {
          margin: [0, 15, 0, 0],
          columns: [
            { text: [ {text:'1. Họ và tên khai sinh '}, {text:' (3)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.get(JSONData, ['HoTenKhaiSinh'], '')} ], style: 'nomal', width: '*' },
            { text: [ {text:'Giới tính '}, {text:' (4)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.rxparseTen('GioiTinh', JSONData['GioiTinh'])} ], margin: [10, 0, 0, 0], style: 'nomal', alignment: 'right', width: 'auto' }
          ]
        }, {
          text: [ {text:'2. Ngày, tháng, năm sinh '}, {text:' (4)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.getDate( JSONData.NgaySinhConvert)} ],
          style: 'nomal'
        }, {
          text: [ {text:'3. Dân tộc '}, {text:' (4)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.get(JSONData, ['DanToc'], '')} ],
          style: 'nomal'
        } , {
          text: '4. Số định danh cá nhân: ' + rxu.get(JSONData, ['CMND'], ''),
          style: 'nomal'
        }, {
          text: [ {text:'5. Chức vụ '}, {text:' (5)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.get(JSONData, ['TenChucVu'], '')} ],
          style: 'nomal'
        }, {
          text: '6. Bút danh thường dùng: ' + rxu.get(JSONData, ['ButDanh'], ''),
          style: 'nomal'
        }, {
          text: '7. Trình độ: ',
          style: 'nomal'
        }, {
          type: 'none',
          ul: [
            { text: [ {text:'- Chuyên môn '}, {text:' (6)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.get(JSONData, ['TrinhDoChuyenMon'], '')} ], style: 'nomal' },
            {
              type: 'none',
              ol:
              [
                { text: '+ Trường học: '  + rxu.ArrayToString(rxu.get(JSONData, ['TruongHoc'], [])), style: 'nomal' },
                { text: '+ Ngành học: '  + rxu.ArrayToString(rxu.get(JSONData, ['NganhHoc'], '')), style: 'nomal' },
                { text: [ {text:'+ Hình thức đào tạo '}, {text:' (7)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.ArrayToString(rxu.get(JSONData, ['HinhThucDaoTao'], ''))} ], style: 'nomal' },
                { text: '+ Năm tốt nghiệp: '  + rxu.get(JSONData, ['NamTotNghiep'], ''), style: 'nomal' },
              ]
            },
            { text: [ {text:'- Lý luận chính trị '}, {text:' (8)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.get(JSONData, ['ChinhTri'], '')} ], style: 'nomal' },
          ]
        }, {
          text: '8. Chứng chỉ đào tạo, bồi dưỡng nghiệp vụ báo chí (nếu có): ' + rxu.get(JSONData, ['ChungChi'], ''),
          style: 'nomal'
        }, {
          text: '9. Đảng viên: ' + rxu.get(JSONData, ['DangVien'], ''),
          style: 'nomal'
        }, {
          text: [ {text:'10. Chức danh báo chí hiện nay '}, {text:' (9)', sup: { offset: '30%', fontSizeSuperScript }}, {text: ': ' + rxu.get(JSONData, ['ChucDanhBaoChi'], '')} ],
          style: 'nomal'
        }, {
          text: '- Số thẻ nhà báo và kỳ hạn cũ (nếu có): ' + rxu.get(JSONData, ['MaTheCu'], ''),
          style: 'nomal',
          pageBreak: 'after'
        }, {
          text: '- Quá trình hoạt động báo chí trong thời gian 05 năm gần nhất:',
          style: 'nomal'
        },
        {
          id: 'QuaTrinhHoatDong',
          style: 'tableQuaTrinhHD',
          table: {
            headerRows: 2,
            widths: [85, 75, 75, '*', '*', 85],
            body: [
              [
                { text: [{text: 'Thời gian'}, {text: '\n(từ tháng, năm nào đến tháng, năm nào)', fontSize:10}], rowSpan: 2, alignment: 'center' },
                { text: 'Chức danh báo chí', rowSpan: 2, alignment: 'center' },
                { text: 'Công tác tại cơ quan báo chí nào', rowSpan: 2, alignment: 'center' },
                { text: 'Lương', colSpan: 2, alignment: 'center' }, {},
                { text: [{text: 'Khen thưởng, kỷ luật'}, {text: '\n(thời gian cụ thể)', fontSize: 10}], rowSpan: 2, alignment: 'center' }
              ],
              [
                {}, {}, {},
                {text: 'Ngạch lương', alignment: 'center'},
                {text: 'Bậc lương', alignment: 'center'}, {}
              ],
            ]
          },
          layout: {
            hLineWidth: function (i, node) { return .5; },
            vLineWidth: function (i, node) { return .5; },
            // hLineColor: function (i, node) { return 'blue'; },
            // vLineColor: function (i, node) { return 'blue'; },
          }
        }, {
          columnGap: 40,
          margin: [0, 0, 0, 100],
          columns: [
            [
              { text: '\nNgười đứng đầu cơ quan\nđề nghị cấp thẻ nhà báo\n', style: 'subheader' },
              { text: '(Ký, đóng dấu hoặc ký số)', style: 'sign' }
            ],
            [
              { text: '\n' + rxu.getFullDate(new Date() / 1000) + '\n', alignment: 'center' },
              { text: 'Người khai\n', style: 'subheader' },
              { text: '(Ký, ghi rõ họ tên hoặc ký số)', style: 'sign' }
            ]
          ],
        }, {
          columnGap: 0,
          columns: [
            { text: [{text: 'Lưu ý: ', bold: true}, {text: 'Bản khai phải ghi đầy đủ các yêu cầu đã nêu, không bỏ mục nào. Nếu thiếu, bản khai được coi là không hợp lệ.'}], fontSize: 12 },
          ]
        }, {
          text: '\nGhi chú:', bold: true, fontSize: 12
        }, {
          text: [
            { text: '(1) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Ảnh chân dung của người đề nghị cấp thẻ nhà báo là ảnh chụp chính diện, rõ mặt. Trong trường hợp gửi hồ sơ trực tiếp hoặc qua hệ thống bưu chính, gửi 04 ảnh 2x3.' },
            { text: '\n(2) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Ghi theo kỳ hạn của thẻ nhà báo đề nghị cấp (ví dụ: 2021 - 2025).' },
            { text: '\n(3) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Viết chữ in hoa đủ dấu.' },
            { text: '\n(4) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Khi Cơ sở dữ liệu quốc gia về dân cư hoàn thiện kết nối, chia sẻ thông tin về công dân cho cơ sở dữ liệu chuyên ngành, Cổng dịch vụ công của Bộ Thông tin và Truyền thông thì người khai không phải kê khai các thông tin về Giới tính, Ngày, tháng, năm sinh, Dân tộc.' },
            { text: '\n(5) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Ghi rõ chức vụ được bổ nhiệm (ví dụ: Tổng biên tập, Phó Tổng biên tập, Trưởng ban…).' },
            { text: '\n(6) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Ghi rõ trình độ chuyên môn cao nhất (ví dụ: Tiến sĩ, thạc sĩ, cử nhân, cao đẳng…).' },
            { text: '\n(7) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Chính quy, tại chức, đào tạo từ xa…' },
            { text: '\n(8) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Cử nhân, cao cấp, trung cấp, sơ cấp và tương đương.' },
            { text: '\n(9) ', sup: { offset: '30%', fontSizeSuperScript } }, { text: 'Ghi rõ chức danh nghề nghiệp (ví dụ: Phóng viên, biên tập viên…).' },
          ],
          fontSize: 12
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
        },
        subheader: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
        },
        nomal: {
          fontSize: 14,
        },
        nomalcustom: {
          fontSize: 14,
          textTransform: 'uppercase',
          bold: true,
        },
        sign: {
          fontSize: 14,
          italics: true,
          alignment: 'center',
        },
        tableQuaTrinhHD: {
          margin: [0, 5, 0, 0],
          lineHeight: 1
        },
      },
      defaultStyle: {
        fontSize: 14,
        lineHeight: 1.2,
        font: 'TimeNew'
      }
    }
    let index = dd.content.findIndex(o => o['id'] === 'QuaTrinhHoatDong')
    if(index !== -1) {
      let table = dd.content[index]['table']['body'] || []
      let infoCard = typeof JSONData.QuaTrinhHoatDongUpdate !== 'object' ? JSON.parse(JSONData.QuaTrinhHoatDongUpdate) : JSONData.QuaTrinhHoatDongUpdate
      for(let i in infoCard) {
        let tmp = [
            'Từ '+rxu.getMonth(infoCard[i].time_start)+' đến '+rxu.getMonth(infoCard[i].time_end),
            rxu.get(infoCard[i], ['ChucDanhBaoChi'], '2'),
            rxu.get(infoCard[i], ['CoQuanBaoChi'], '3'),
            rxu.get(infoCard[i], ['NgachLuong'], '4'),
            rxu.get(infoCard[i], ['BacLuong'], '5'),
            rxu.get(infoCard[i], ['KhenThuong'], '6')
          ]
        table[table.length] = tmp
      }
      dd.content[index]['table']['body'] = table
    }
    const pdfDocGenerator = pdfMake.createPdf(dd)
    pdfDocGenerator.getBlob((blob) => {
      saveAs(blob, "BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO.pdf")
    });
  }

  global.ExportReturn = async function (JSONData) {
    console.log(JSONData, 'JSONData')
    let dataCard = typeof JSONData.data !== 'object' ? JSON.parse(JSONData.data) : JSONData.data
    let font = { name: 'Times New Roman', size: 13, bold: true }
    let alignment = { vertical: 'middle', horizontal: 'center' , wrapText: true}
    let border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    // Xuat thong tin the
    let workbookCard = new Exceljs.Workbook()

    if (dataCard && dataCard.length > 0) {
      // workbook info
      workbookCard.Props = {
          Title: "Journalist",
          Subject: "CẤP THẺ NHÀ BÁO",
          Author: "Journalist",
          CreatedDate: global.rxu.date(new Date())
      };
      let namepost = 'DanhSachTrinh'
      let ws = workbookCard.addWorksheet(namepost, {
        pageSetup:{paperSize: 9, orientation:'portrait'} // print
      })
      ws.views = [{
        showGridLines : false
      }]
      //setup print layout
      ws.pageSetup.margins = {
        left: 0.51, right: 0.31,
        top: 0.51, bottom: 0.51,
        header: 0.31, footer: 0.31
      };
      ws.pageSetup.printTitlesRow = '10:10';
      ws.pageSetup.horizontalCentered = true;

      ws.getCell('A1').value = 'BỘ THÔNG TIN VÀ TRUYỀN THÔNG'
      ws.getCell('A3').value = 'DANH SÁCH CẤP THẺ NHÀ BÁO'
      ws.getCell('A4').value = '(Kèm theo Quyết định số 2232/QĐ-BTTTT ngày 15 tháng 12 năm 2020 của Bộ trưởng Bộ Thông tin và truyền thông về việc cấp thẻ nhà báo kỳ hạn 2021 - 2025)'
      ws.getCell('A6').value = 'Tên cơ quan báo chí'
      ws.getCell('A7').value = 'Tên cơ quan chủ quản'
      ws.getCell('A8').value = 'Tổng số thẻ nhà báo được cấp'
      ws.getCell('C6').value = rxu.get(JSONData, 'CoQuanBaoChiObj.Cat_Name')
      ws.getCell('C7').value = rxu.get(JSONData, 'CoQuanBaoChiObj.CoQuanChuQuan')
      ws.getCell('C8').value = rxu.get(JSONData, 'total_data')

      ws.mergeCells('A3:E3')
      ws.mergeCells('A4:E4')
      ws.getRow(4).height = 30

      for(let i = 1; i < 9; i++) {
        if([3, 4].indexOf(i) !== -1) {
          ws.getCell('A'+i).alignment = alignment
        }
        if(i >5) {
          ws.mergeCells('A'+i+':B'+i)
        }
        if([1, 3].indexOf(i) !== -1) {
          ws.getCell('A'+i).font = {...font, size: 14}
        } else if(i === 4) {
          ws.getCell('A'+i).font = {...font, italic: true, bold: false}
        } else {
          ws.getCell('A'+i).font = font
          ws.getCell('C'+i).font = font
          ws.getCell('C'+i).alignment = {...alignment, horizontal: 'left', wrapText: false}
        }
      }

      // Header
      // let originForData = 5
      ws.getRow(10).values = ['STT', 'Họ và tên', 'Bút danh', 'Năm sinh', 'Mã số thẻ']
      ws.getRow(10).height = 30

      let arrCellPost = ['A', 'B', 'C', 'D', 'E']

      for(let i in arrCellPost) {
        ws.getCell(arrCellPost[i] + 10).font = font
        ws.getCell(arrCellPost[i] + 10).alignment = alignment
        ws.getCell(arrCellPost[i] + 10).border = border
      }

      ws.columns = [
        {key: 'stt', width: 5 },
        {key: 'HoTenKhaiSinh', width: 30 },
        {key: 'ButDanh', width: 20 },
        // {key: 'ChucVu', width: 20 },
        {key: 'NamSinh', width: 10 },
        {key: 'MaSoThe', width: 15 },
      ]

      let originForData = 11
      console.log(dataCard,'mnnn')
      for(let m = 0; m < dataCard.length; m ++) {

        ws.addRow({
          stt: m+1,
          HoTenKhaiSinh: rxu.get(dataCard[m], 'HoTenKhaiSinh').toUpperCase(),
          ButDanh: rxu.get(dataCard[m], 'ButDanh'),
          // ChucVu: rxu.get(dataCard[m], 'TenChucVu'),
          NamSinh: new Date(rxu.get(dataCard[m], ['NgaySinhConvert'], 0)*1000).getUTCFullYear(),
          MaSoThe: rxu.get(dataCard[m], 'MaSoThe'),
        })
        originForData += 1
      }

      for (let j in arrCellPost) {
        for (let k = 11; k < originForData; k++) {
          ws.getCell(arrCellPost[j]+k).font = {...font, bold: false }
          ws.getCell(arrCellPost[j]+k).border = { bottom: {style : 'thin'}, right: {style : 'thin'}, left: {style: 'thin'}, top: { style: 'thin' }}
          if(['A', 'E'].indexOf(arrCellPost[j]) !== -1) {
            ws.getCell(arrCellPost[j]+k).alignment = alignment
          } else {
            ws.getCell(arrCellPost[j]+k).alignment = {...alignment, horizontal: 'left'}
          }
        }
        ws.getCell(arrCellPost[j]+Number(originForData-1)).border = { bottom: {style : 'thin'}, right: {style : 'thin'}, left: {style:'thin'}, top: { style: 'thin' }}
      }

      workbookCard.xlsx.writeBuffer().then(function (dataCard) {
        let blobpost = new Blob([dataCard], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

        saveAs(blobpost, 'DANH SÁCH CẤP THẺ NHÀ BÁO');
      });
    }
  }

  // Jquery addon and jquery global stuff
  let $ = global.$
  global.scrolltop = function () {
    $('html, body').animate({ scrollTop: 0 }, 'fast')
  }
  global.scrollfire = function () {
    window.dispatchEvent(new CustomEvent('scroll'))
  }

  // new chartist.Line('#chart1', { labels: [1, 2, 3, 4], series: [[100, 120, 180, 200]] })
  global.chart = chartist
  global.chartist = function (ele, options, options1, options2) {
    return new chartist.Line(ele, options, options1, options2)
  }

  global.chartistBar = function (ele, options, options1, options2) {
    return new chartist.Bar(ele, options, options1, options2)
  }

  global.chartistPie = function (ele, options, options1, options2) {
    return new chartist.Pie(ele, options, options1, options2)
  }
  global.typeDaiTH = [
    {
      id: 1,
      Ten: 'Đài truyền thanh, truyền hình cấp huyện '
    }
  ]
  global.typePTTH = [
    {
      id: 0,
      Ten: 'Đài truyền thanh, truyền hình cấp tw '
    },
    {
      id: 1,
      Ten: 'Đài truyền thanh, truyền hình cấp huyện '
    }
  ]
  global.configType = [
    {
      id: 1,
      Ten: 'Hình thức đào tạo'
    },
    {
      id: 2,
      Ten: 'Trường học'
    },
    {
      id: 3,
      Ten: 'Ngành học'
    },
    {
      id: 4,
      Ten: 'Trình độ chuyên môn'
    },
  ]
  global.LoaiHinhBC = [
    {
      id: 1,
      Ten: 'Báo in'
    },
    {
      id: 2,
      Ten: 'Báo hình'
    },
    {
      id: 3,
      Ten: 'Báo nói'
    },
    {
      id: 4,
      Ten: 'Báo điện tử'
    }
  ]
  global.typeHS = [
    // {
    //   id: 1,
    //   Ten: 'Hồ sơ cấp mới'
    // },
    // {
    //   id: 2,
    //   Ten: 'Hồ sơ cấp lại'
    // },
    // {
    //   id: 3,
    //   Ten: 'Hồ sơ cấp đổi'
    // },
    {
      id: 4,
      Ten: 'Cấp đổi theo kỳ hạn'
    },
    {
      id: 5,
      Ten: 'Cấp mới'
    },
    {
      id: 6,
      Ten: 'Cấp đổi do chuyển cơ quan'
    },
    {
      id: 2,
      Ten: 'Cấp lại'
    }
  ]
  global.IsStatus_Card = [
    {
      value: 1,
      id: [1,2,3],
      Ten: 'Chưa duyệt'
    },
    {
      value: 2,
      id: [4,5],
      Ten: 'Đã duyệt'
    },
    {
      value: 3,
      id: [6],
      Ten: 'Bị trả lại'
    },
    {
      value: 4,
      id: [0],
      Ten: 'Chưa đủ điều kiện'
    }
  ]
  global.IsStatus = [
    {
      id: 1,
      Ten: 'Hồ sơ đã được chuyên viên duyệt'
    },
    {
      id: 2,
      Ten: 'Hồ sơ đã được lãnh đạo Phòng duyệt'
    },
    {
      id: 3,
      Ten: 'Hồ sơ đã được lãnh đạo Cục duyệt'
    },
    {
      id: 4,
      Ten: 'Hồ sơ đã được lãnh đạo Bộ duyệt'
    },
    {
      id: 5,
      Ten: 'Hồ sơ đã in thẻ'
    }
  ]
  global.theDaCap = [
    {
      id: 5,
      Ten: 'Thẻ chờ in'
    },
    {
      id: 7,
      Ten: 'Thẻ đã cấp'
    },
    {
      id: 8,
      Ten: 'Thẻ đã thu hồi'
    },

  ]
  global.GioiTinh = [
    {
      id: '0',
      Ten: 'Nữ'
    },
    {
      id: '1',
      Ten: 'Nam'
    }
  ]
  global.DangVien = [
    {
      id: 1,
      Ten: 'Đảng viên'
    },
    {
      id: 2,
      Ten: 'Đoàn viên'
    },
    {
      id: 3,
      Ten: 'Không'
    }
  ]
  global.ChinhTri = [
    {
      id: 1,
      Ten: 'Sơ cấp'
    },
    {
      id: 2,
      Ten: 'Trung cấp'
    },
    {
      id: 3,
      Ten: 'Cao cấp'
    },
    {
      id: 4,
      Ten: 'Không'
    }
  ]

  global.LoaiCoquan = [
    {
      id: 1,
      Ten: 'Phòng Quản lý Báo chí'
    },
    {
      id: 2,
      Ten: 'Phòng Kinh tế Báo chí'
    },
    {
      id: 3,
      Ten: 'Cục phát thanh truyền hình và thông tin điện tử'
    },
    {
      id: 4,
      Ten: 'Khối quản lý'
    }
  ]
  global.TypeCQ = [
    {
      id: 1,
      Ten: 'Cục báo chí'
    },
    {
      id: 2,
      Ten: 'Cục phát thanh'
    }
  ]
  global.LoaiCuc = [
    {
      id: 1,
      Ten: 'Hội'
    },
    {
      id: 2,
      Ten: 'Bộ'
    },
    {
      id: 3,
      Ten: 'Ban'
    },
  ]

  global.reception = [
    {
      id: 1,
      Ten: 'Trực tiếp'
    },
    {
      id: 2,
      Ten: 'Trực tuyến'
    },
    {
      id: 3,
      Ten: 'Bưu chính'
    }
  ]
  global.payment = [
    {
      id: 1,
      Ten: 'Chưa thanh toán'
    },
    {
      id: 2,
      Ten: 'Đã thanh toán'
    }
  ]

  global.optionCard  = [
    {id:'Avatar', name: 'Ảnh thẻ'},
    {id:'img_landscape', name: 'Ảnh 3x4'},
    {id:'TenCoQuanBaoChi', name: 'Tên cơ quan đề nghị cấp thẻ nhà báo'},
    {id:'LoaiCoquan', name: 'Tên đơn vị công tác (Áp dụng với TH công tác tại Đài TT-TH cấp huyện)'},
    {id:'codeGen', name: 'Số thẻ'},
    {id:'created_at', name: 'Ngày cấp'},
    {id:'province', name: 'Tỉnh thành phố'},
    {id:'img_card', name: 'Thẻ trích xuất'},
    {id:'IsStatus', name: 'Tình trạng thẻ'},
    {id:'CMND', name: 'Số thẻ CCCD/CMND'},
    {id:'NgayCapCMND', name: 'Ngày cấp CMND'},
    {id:'TrinhDoVanHoa', name: 'Giáo dục phổ thông'},
    {id:'TrinhDoChuyenMon', name: 'Chuyên môn'},

    {id:'codeGen', name: 'QR code phục vụ kiểm tra số liệu'}, //1

    {id:'CardType', name: 'Loại hồ sơ '},
    {id:'LoaiCoquan', name: 'Loại cơ quan'},
    {id:'HoTenKhaiSinh', name: 'Họ và tên khai sinh'},
    {id:'GioiTinh', name: 'Giới tính'},
    {id:'NgaySinh', name: 'Ngày tháng năm sinh'},
    {id:'DanToc', name: 'Dân tộc'},
    {id:'QueQuan', name: 'Quê quán'},
    {id:'NoiO', name: 'Nơi ở hiện nay'},

    {id:'TenChucVu', name: 'Chức vụ'},
    {id:'ButDanh', name: 'Bút danh thường dùng'},
    {id:'TenDaoTao', name: 'Giáo dục phổ thông'},
    {id:'TruongHoc', name: 'Trường học'},
    {id:'NganhHoc', name: 'Ngành học'},
    {id:'HinhThucDaoTao', name: 'Hình thức đào tạo'},
    {id:'NamTotNghiep', name: 'Năm tốt nghiệp'},
    {id:'LyLuanChinhTri', name: 'Lý luận chính trị'},
    {id:'NgaySinhConvert', name: 'Ngày, tháng, năm sinh'},

    {id:'NgoaiNgu', name: 'Ngoại ngữ'},
    {id:'ChungChi', name: 'Chứng chỉ tạo tạo, bồi dưỡng nghiệp vụ báo chí (nếu có)'},
    {id:'DangVien', name: 'Đảng viên'},
    {id:'ChucDanhBaoChi', name: 'Chức danh báo chí hiện nay'},
    {id:'MatheCu', name: 'Số thẻ nhà báo và kỳ hạn cũ'},
    {id:'ChinhTri', name: 'Lý luận chính trị'},
    {id:'MaTheCu', name: 'Số thẻ nhà báo và kỳ hạn cũ'},
    {id:'NgayKyHDLD', name: 'Ngày tháng năm ký HDLD có đóng BHXH/Số sổ BHXH'},
    {id:'cmnd_truoc', name: 'Ảnh CMND/CCCD'},
    {id:'cmnd_sau', name: 'Ảnh CMND/CCCD mặt sau '},
    {id:'dupplicate', name: 'CMND/CCCD hoặc Mã thẻ cũ đã tồn tại'},
    {id:'ThongTinHDLD', name: 'Ngày, tháng, năm ký HĐLĐ có đóng BHXH'},
    {id:'SoYeuLyLich', name: 'Sơ yếu lý lịch'},
    {id:'BanSaoBangDHCD', name: 'Bảng sao bằng ĐH/CĐ'},
    {id:'BanSaoHDLD', name: 'Bảng sao quyết định tuyển dụng'},
    {id:'DanhSachTacPham', name: 'Danh sách tác phẩm báo chí đã đăng phát'},
    {id:'QuaTrinhHoatDongUpdate', name: 'Quá trình hoạt động báo chí 5 năm gần nhất'},

    {id:'time', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: Thời gian '},
    {id:'ChucDanhBaoChi', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: Chức danh báo chí '},
    {id:'CoQuanBaoChi', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: Công tác tại cơ quan báo chí nào '},
    {id:'NgachLuong', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: Ngạch lương '},
    {id:'BacLuong', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: Bậc lương '},
    {id:'KhenThuong', name: 'Quá trình hoạt động báo chí 5 năm gần nhất: Khen thưởng kỷ luật '},
    {id:'-114', name: 'Không có quyền chỉnh sửa.'},
    {id:'0', name: 'CMND/CCCD hoặc Mã thẻ cũ đã tồn tại'},
    {id:'-117', name: 'Lỗi hệ thống trong quá trình khởi tạo.'},
    {id:'-118', name: 'Không tìm thấy cơ quan báo chí trong hệ thống!.'},
  ]

  global.statusPhanLoai = [
    {
      id: 1,
      Ten: 'Hồ sơ cấp mới'
    },
    {
      id: 2,
      Ten: 'Hồ sơ cấp lại'
    },
    {
      id: 3,
      Ten: 'Hồ sơ cấp đổi'
    },
    {
      id: 4,
      Ten: 'Hồ sơ cấp theo kỳ hạn mới'
    }
  ]

  global.NgNhanCapDoi = [
    {
      id: 1,
      Ten: "Cấp lại do hỏng thẻ"
    },
    {
      id: 2,
      Ten: "Cấp lại do mất thẻ"
    }
  ]

  global.global_init = global.global_init || 'notyet'

  if (global.global_init === 'notyet' && typeof window !== 'undefined') {
    global.loadTimer = {}
    global.showProgress = function () {
      clearTimeout(global.loadTimer)
      global.$('.progress-bar').removeClass('rxhide')
    }

    global.hideProgressFunc = function () {
      global.$('.progress-bar').addClass('rxhide')
    }

    global.hideProgress = function () {
      clearTimeout(global.loadTimer)
      global.loadTimer = setTimeout(global.hideProgressFunc, 0)
    }

    // Default worker
    $('img').on('error', function () {
      $(this).attr('src', '/images/missing.png')
    })

    // Sidebar navigator
    setTimeout(() => {
      $('.adnav__itemname.rxactive').parent('.adnav__groupwrap').addClass('rxactive')
      $('.adnav__itemname.rxactive').parent('.adnav__groupwrap').prev('.adnav__groupname').addClass('adnav__groupname--expand')
    }, 1000)

    // Plugin
    ;(function ($) {
      let uniqueCntr = 0
      $.fn.scrolled = function (waitTime, fn) {
        if (typeof waitTime === 'function') {
          fn = waitTime
          waitTime = 20
        }
        var tag = 'scrollTimer' + uniqueCntr++
        this.scroll(function () {
          var self = $(this)
          var timer = self.data(tag)
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(function () {
            self.removeData(tag)
            fn.call(self[0])
          }, waitTime)
          self.data(tag, timer)
        })
      }
    })($)

    global.onResizeWindow = function () { $('.rxwindowheight').css({ 'height': $(window).height() + 'px' }) }
    $(window).on('load', global.onResizeWindow)
    $(window).resize(global.onResizeWindow)
    $(window).scrolled(function () {
      // Stick top
      let sticky = $('.rx-sticky'); let scroll = $(window).scrollTop()
      if (scroll >= 220) {
        $('.rx-sticky').each(function (i) {
          let ele = $(this)
          ele.addClass('fixed')
          ele.outerWidth(ele.parent().width())
        })
      } else {
        sticky.removeClass('fixed')
        sticky.outerWidth('')
      }

      // Stick bot
      $('.rx-stickybot').each(function (i) {
        let ele = $(this)
        ele.addClass('fixedbot')
        ele.outerWidth(ele.parent().width())
      })

      if (typeof sticky.offset() !== 'undefined') {
        let stickyBottom = sticky.offset().top + sticky.outerHeight(true) + 100
        sticky.find('.product-content-nav-item').each(function () {
          let ele = $(this)
          let theClass = ele.attr('class').match(/rxid[\w-]*\b/)[0]

          // Check what section currently
          let block = $('#' + theClass.replace('rxid', ''))
          if (typeof block.offset() !== 'undefined' && stickyBottom <= block.offset().top + block.outerHeight(true) && stickyBottom >= block.offset().top) {
            $('.product-content-nav-item').removeClass('rx-active')
            $('.' + theClass).addClass('rx-active')
          }
        })
      }
    })
  }
}

global.toDate = function (value,notmin) {
  var dateObj = new Date(value * 1000)
  var year = dateObj.getFullYear()
  var month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  var date = ('0' + dateObj.getDate()).slice(-2)
  var hour = ('0' + dateObj.getHours()).slice(-2)
  var minute = ('0' + dateObj.getMinutes()).slice(-2)
  var shortDate = notmin ? (date + '/' + month + '/' + year) : (hour + ':' + minute + ', ' + date + '/' + month + '/' + year)
  return shortDate
}

global.PrintDocMauCapLaiThe = function (JSONData) {
  var dd = {
    pageMargins: [ 74.5, 55.6, 55.6, 55.6 ],
    content: [
      {
        columns: [
          {},
          [
            { text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\n Độc lập - Tự do - Hạnh phúc \n', style: 'subheader' },
            { text: '________________________\n\n' + rxu.getFullDate(new Date() / 1000), italics: true, alignment: 'center', lineHeight: 1 }
          ]
        ]
      }, {
        margin: [0, 20, 0, 10],
        text: 'ĐƠN ĐỀ NGHỊ CẤP LẠI THẺ NHÀ BÁO',
        style: 'header'
      }, {
        text: 'Thực hiện quy định tại Luật Báo chí 2016 về thẻ nhà báo, đề nghị Bộ Thông tin và Truyền thông cấp lại thẻ nhà báo:'
      }, {
        columns: [
          { text: '- Họ và tên: ' + rxu.get(JSONData, 'HoTenKhaiSinh', '') },
          { text: 'Nam/Nữ: ' + rxu.rxparseTen('GioiTinh', JSONData['GioiTinh']) }
        ]
      }, {
        text: '- Sinh năm: ' + rxu.getDate(JSONData.NgaySinhConvert)
      }, {
        text: '- Nơi công tác: ' + rxu.get(JSONData, 'TenCoQuanBaoChi', '')
      }, {
        text: '- Thẻ nhà báo số: ' + rxu.get(JSONData, 'MaTheCu', '')
      }, {
        text: '- Lý do đề nghị cấp lại (mất thẻ, hỏng thẻ): ' + rxu.get(JSONData, 'LyDoCapLai', '')
      }, {
        text: 'Trân trọng./. '
      }, {
        margin: [0, 10, 0, 40],
        columns: [
          [
            { text: 'XÁC NHẬN CỦA CƠ QUAN,\n', style: 'subheader' },
            { text: '(Nơi người đề nghị cấp lại thẻ công tác)', style: 'sign' }
          ],
          [
            { text: 'NGƯỜI ĐỀ NGHỊ\n', style: 'subheader' }
          ]
        ],
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1
      },
      nomal: {
        fontSize: 14,
      },
      sign: {
        fontSize: 14,
        italics: true,
        alignment: 'center',
        lineHeight: 1
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 14,
      lineHeight: 1.4,
      font: 'TimeNew'
    }
  }
  const pdfDocGenerator = pdfMake.createPdf(dd)
  pdfDocGenerator.getBlob((blob) => {
    saveAs(blob, "ĐƠN ĐỀ NGHỊ CẤP LẠI THẺ NHÀ BÁO.pdf")
  });
}

global.PrintDocMauDoiThe = function(JSONData) {
  var dd = {
    pageMargins: [ 74.5, 55.6, 55.6, 55.6 ],
    content: [
      {
        columns: [
          [
            { text: rxu.get(JSONData, 'CoQuanChuQuan', ''), alignment: 'center' },
            { text: rxu.get(JSONData, 'TenCoQuanBaoChi', ''), style: 'subheader' },
            { text: '________________________', alignment: 'center', lineHeight: 1, color: '#f5f4f4' }
          ],
          [
            { text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\n Độc lập - Tự do - Hạnh phúc \n', style: 'subheader' },
            { text: '________________________\n\n', alignment: 'center', lineHeight: 1, color: '#f5f4f4' },
            { text: rxu.getFullDate(new Date() / 1000), italics: true, alignment: 'center', lineHeight: 1 }
          ]
        ]
      },
      { margin: [0, 20, 0, 10], text: 'GIẤY XÁC NHẬN', style: 'header' },
      { text: 'Cơ quan báo chí (ghi tên cơ quan báo chí, cơ quan xác nhận): ' + rxu.get(JSONData, 'TenCoQuanBaoChi'), style: 'nomal' },
      { text: 'Xác nhận để làm thủ tục đổi thẻ nhà báo đối với trường hợp:', style: 'nomal' },
      {
        columns: [
          { text: '- Họ và tên: ' + rxu.get(JSONData, 'HoTenKhaiSinh', ''), style: 'nomal' },
          { text: 'Nam/Nữ: ' + rxu.rxparseTen('GioiTinh', JSONData['GioiTinh']), style: 'nomal' }
        ]
      },
      { text: '- Sinh năm: ' + rxu.parseTime(JSONData.NgaySinhConvert), style: 'nomal' },
      { text: '- Thẻ nhà báo số: ' + rxu.get(JSONData, 'MaTheCu', ''), style: 'nomal' },
      { text: '- Đã nộp thẻ nhà báo (trường hợp mất thẻ, chưa nộp lại thẻ ghi rõ lý do): ' + rxu.get(JSONData, 'MaTheCu', ''), style: 'nomal' },
      { text: '- Thời gian công tác tại cơ quan (từ ngày tháng năm đến ngày tháng năm: ' + rxu.get(JSONData, 'ThoiGianCongTac', ''), style: 'nomal' },
      { text: '- Trong thời gian công tác:', style: 'nomal' },
      {
        ol: [
          { text: '+ Việc hoàn thành nhiệm vụ được giao ', style: 'nomal', listType: 'none' },
          { text: '+ Liên quan một hoặc các trường hợp quy định tại điểm a, b, c, d và đ khoản 3 Điều 27 Luật báo chí: ', style: 'nomal', listType: 'none' },
          {
            ul:
            [
              { text: 'Chức danh báo chí: '  + rxu.get(JSONData, ['ChucDanhBaoChi'], ''), style: 'nomal', listType: 'circle' },
              { text: 'Chức vụ: '  + rxu.get(JSONData, ['TenChucVu'], ''), style: 'nomal', listType: 'circle' },
              { text: 'Về đạo đức nghề nghiệp của người làm báo (nếu đã bị xử lý vi phạm ghi rõ thời gian): '  + rxu.get(JSONData, ['ListHoSoQuaTrinhHoatDong'], ''), style: 'nomal', listType: 'circle' },
              { text: 'Về kỷ luật (nếu đã bị xử lý kỷ luật từ khiển trách trở lên theo quy định của pháp luật về công chức, viên chức và pháp luật về lao động, ghi rõ thời gian và hình thức bị kỷ luật): '  + rxu.get(JSONData, ['ListHoSoQuaTrinhHoatDong1'], ''), style: 'nomal', listType: 'circle' },
              { text: 'Liên quan đến các vụ án (bị kết án năm nào, đã được xóa án tích chưa, hiện có là đối tượng liên quan trong các vụ án chưa có kết luận của cơ quan nhà nước có thẩm quyền không): ' + rxu.get(JSONData, 'ListHoSoQuaTrinhHoatDong2', ''), style: 'nomal', listType: 'circle' },
              { text: 'Đã từng bị thu hồi thẻ nhà báo (ghi rõ thời gian, lý do): ' + rxu.get(JSONData, 'ListHoSoQuaTrinhHoatDong3', ''), style: 'nomal', listType: 'circle' }
            ]
          },
        ]
      }, {
        margin: [0, 10, 0, 40],
        columns: [
          [],
          [
            { text: 'NGƯỜI ĐỨNG ĐẦU CƠ QUAN', style: 'subheader' },
            { text: '(Ký, đóng dấu)', style: 'sign' }
          ]
        ],
      }
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1
      },
      subheader: {
        fontSize: 13,
        bold: true,
        alignment: 'center',
        lineHeight: 1
      },
      nomal: {
        fontSize: 14,
        lineHeight: 1.4
      },
      sign: {
        fontSize: 14,
        italics: true,
        alignment: 'center',
        lineHeight: 1
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 14,
      font: 'TimeNew'
    }
  }
  const pdfDocGenerator = pdfMake.createPdf(dd)
  pdfDocGenerator.getBlob((blob) => {
    saveAs(blob, "ĐƠN ĐỀ NGHỊ CẤP LẠI THẺ NHÀ BÁO.pdf")
  });
}

global.JSONToCSVConvertorDanhSachCoQuanBaoChi = function (JSONData, paging) {
  let title = ''

  switch (paging['search_LoaiCoquan']) {
    case '1':
      title = "DANH SÁCH CƠ QUAN BÁO CHÍ TRUNG ƯƠNG"
      break;

    case '2':
      title = "DANH SÁCH CƠ QUAN BÁO CHÍ ĐỊA PHƯƠNG"
      break;

    case '3':
      title = "DANH SÁCH CƠ QUAN PHÁT THANH TRUYỀN HÌNH"
      break;

    default:
      title = "DANH SÁCH CƠ QUAN BÁO CHÍ"
      break;
  }

  let arrTrucThuoc = ['', 'Cục Báo Chí Trung Ương', 'Cục Báo Chí Địa Phương', 'Cục Phát Thanh Truyền hình']

  let dataCard = JSONData
  let font = { name: 'Times New Roman', size: 12, bold: true }
  let alignment = { vertical: 'middle', horizontal: 'center' }
  let border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

  // Xuat thong tin the
  let workbookCard = new Exceljs.Workbook()

  if (dataCard && dataCard.length > 0) {
    // workbook info
    workbookCard.Props = {
        Title: "Journalist",
        Subject: "Mẫu số 02",
        Author: "Journalist",
        CreatedDate: global.rxu.date(new Date())
    };
    let namepost = title
    let ws = workbookCard.addWorksheet(namepost)
    ws.views = [{
      showGridLines : false
    }]

    ws.getCell('A2').value = 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM'
    ws.getCell('A2').font = { name: 'Times New Roman', size: 14, bold: true }

    ws.getCell('A3').value = 'Độc lập - Tự do - Hạnh phúc'
    ws.getCell('A3').font = font

    ws.getCell('A4').value = '---------------'
    ws.getCell('A2').font = font
    ws.getCell('A6').value = title
    ws.getCell('A6').font = { name: 'Times New Roman', size: 20, bold: true }
    ws.getRow(6).height = 50

    for(let i = 2; i < 7; i++) {
      ws.mergeCells('A'+i+':F'+i)
      ws.getCell('A'+i).alignment = alignment
    }
    // Header
    // let originForData = 5
    ws.getRow(8).values = ['STT','Tên cơ quan báo chí', 'Mã cơ quan báo chí', 'Trực thuộc', 'Địa chỉ', 'Số điện thoại', 'Số fax']
    ws.getRow(8).height = 50
    let arrCellPost = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

    for (let j in arrCellPost) {
      ws.getCell(arrCellPost[j]+8).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
      ws.getCell(arrCellPost[j]+8).font = font
      ws.getCell(arrCellPost[j]+8).border = border
    }

    ws.columns = [
      {key: 'stt', width: 10 },
      {key: 'TenCoQuan', width: 60 },
      {key: 'MaCoQuan', width: 20 },
      {key: 'TrucThuoc', width: 30 },
      {key: 'DiaChi', width: 20 },
      {key: 'SoDienThoai', width: 20 },
      {key: 'SoFax', width: 20 }
    ]

    let originForData = 9
    for(let m = 0; m < dataCard.length; m ++) {
      let data = dataCard[m]
      ws.addRow({
        stt:          (m+1),
        TenCoQuan:            global.rxu.get(data, ['Cat_Name'], ''),
        MaCoQuan:             global.rxu.get(data, ['Code'], ''),
        TrucThuoc:            arrTrucThuoc[global.rxu.get(data, ['LoaiCoquan'], '')],
        DiaChi:               global.rxu.get(data, ['DiaChi'], ''),
        SoDienThoai:          global.rxu.get(data, ['DienThoai'], ''),
        SoFax:                global.rxu.get(data, ['Fax'], ''),
      })
      originForData += 1
    }

    for (let j in arrCellPost) {
      for (let k = 9; k < originForData; k++) {
        ws.getCell('A'+k).alignment = alignment
        ws.getCell(arrCellPost[j]+k).font = {...font, bold: false }
        ws.getCell(arrCellPost[j]+k).border = { bottom: {style : 'dotted'}, right: {style : 'thin'}}
      }
      ws.getCell(arrCellPost[j]+Number(originForData-1)).border = { bottom: {style : 'thin'}, right: {style : 'thin'}}
    }

    originForData++
    //SIGN
    // ws.mergeCells('A'+originForData+':C'+originForData)
    // ws.getCell('A'+originForData).value = 'Người đứng đầu cơ quan báo chí đăng, '
    // ws.getCell('A'+originForData).font = font
    // ws.getCell('A'+originForData).alignment = alignment

    // ws.mergeCells('D'+originForData+':F'+originForData)
    // ws.getCell('D'+originForData).value = '……., ngày.... tháng …. năm ……'
    // ws.getCell('D'+originForData).alignment = alignment
    // originForData++
    // ws.mergeCells('A'+originForData+':C'+originForData)
    // ws.getCell('A'+originForData).value = 'phát tác phẩm báo chí'
    // ws.getCell('A'+originForData).font = font
    // ws.getCell('A'+originForData).alignment = alignment

    // ws.mergeCells('D'+originForData+':F'+originForData)
    // ws.getCell('D'+originForData).value = 'Người khai'
    // ws.getCell('D'+originForData).alignment = alignment
    // ws.getCell('D'+originForData).font = font
    // originForData++
    // ws.mergeCells('A'+originForData+':C'+originForData)
    // ws.getCell('A'+originForData).value = '(Ký tên, đóng dấu xác nhận)'
    // ws.getCell('A'+originForData).font = {...font, bold: false, italic: true, }
    // ws.getCell('A'+originForData).alignment = alignment

    // ws.mergeCells('D'+originForData+':F'+originForData)
    // ws.getCell('D'+originForData).value = '(Ký và ghi rõ họ tên)'
    // ws.getCell('D'+originForData).font = {...font, bold: false, italic: true, }
    // ws.getCell('D'+originForData).alignment = alignment

    // for(let i = originForData+1; i < originForData+6; i++) {
    //   ws.mergeCells('A'+i+':C'+i)
    //   ws.mergeCells('D'+i+':F'+i)
    // }

    workbookCard.xlsx.writeBuffer().then(function (dataCard) {
      let blobpost = new Blob([dataCard], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

      saveAs(blobpost, title);
    });
  }
}

global.JSONToCSVConvertorMember = function (JSONData, paging) {
  let dataCard = JSONData
  let font = { name: 'Times New Roman', size: 12, bold: true }
  let alignment = { vertical: 'middle', horizontal: 'center' }
  let border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  let typeArr = ['', 'Báo chí trung ương','Báo chí địa phương','Phát thanh truyền hình']
  let statusArr = ['Chưa duyệt', 'Đã duyệt']
  // Xuat thong tin the
  let workbookCard = new Exceljs.Workbook()

  if (dataCard && dataCard.length > 0) {
    // workbook info
    workbookCard.Props = {
        Title: "Journalist",
        Subject: "Member",
        Author: "Journalist",
        CreatedDate: global.rxu.date(new Date())
    };
    let namepost = 'Danh sách cơ quan đăng ký'
    let ws = workbookCard.addWorksheet(namepost)
    ws.views = [{
      showGridLines : false
    }]

    ws.getCell('A2').value = 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM'
    ws.getCell('A2').font = { name: 'Times New Roman', size: 14, bold: true }

    ws.getCell('A3').value = 'Độc lập - Tự do - Hạnh phúc'
    ws.getCell('A3').font = font

    ws.getCell('A4').value = '---------------'
    ws.getCell('A2').font = font
    ws.getCell('A6').value = 'DANH SÁCH CƠ QUAN ĐĂNG KÝ'
    ws.getCell('A6').font = { name: 'Times New Roman', size: 20, bold: true }
    ws.getRow(6).height = 50

    for(let i = 2; i < 7; i++) {
      ws.mergeCells('A'+i+':K'+i)
      ws.getCell('A'+i).alignment = alignment
    }
    // Header
    // let originForData = 5
    ws.getRow(8).values = ['STT','Tên cơ quan báo chí', 'Số giấy phép hoạt động báo chí', 'Ngày cấp', 'Địa chỉ cơ quan báo chí', 'Số điện thoại cơ quan báo chí', 'Email cơ quan báo chí',
                            'Số điện thoại di động', 'Họ tên người sử dụng', 'Tên đăng nhập (Hệ thống)', 'Mật khẩu (Hệ thống)','Loại cơ quan', 'Tình trạng']
    ws.getRow(8).height = 50
    let arrCellPost = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K','L','M']

    for (let j in arrCellPost) {
      ws.getCell(arrCellPost[j]+8).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
      ws.getCell(arrCellPost[j]+8).font = font
      ws.getCell(arrCellPost[j]+8).border = border
    }

    ws.columns = [
      {key: 'stt', width: 10 },
      {key: 'CoQuanBaoChi', width: 60 },
      {key: 'SoDKKD', width: 20 },
      {key: 'NgayCap', width: 30 },
      {key: 'address', width: 20 },
      {key: 'telephone', width: 20 },
      {key: 'email', width: 20 },
      {key: 'phone', width: 20 },
      {key: 'fullname', width: 20 },
      {key: 'username', width: 20 },
      {key: 'pass', width: 20 },
      {key: 'type', width: 20 },
      {key: 'status', width: 20 },
    ]

    let originForData = 9
    for(let m = 0; m < dataCard.length; m ++) {
      let data = dataCard[m]
      ws.addRow({
        stt:          (m+1),
        CoQuanBaoChi:       global.rxu.get(data, ['CoQuanBaoChi'], ''),
        SoDKKD:             global.rxu.get(data, ['SoDKKD'], ''),
        NgayCap:            rxu.getDate(global.rxu.get(data, ['NgayCap'], '')),
        address:            global.rxu.get(data, ['address'], ''),
        telephone:          global.rxu.get(data, ['telephone'], ''),
        email:              global.rxu.get(data, ['email'], ''),
        phone:              global.rxu.get(data, ['phone'], ''),
        fullname:           global.rxu.get(data, ['fullname'], ''),
        username:           global.rxu.get(data, ['username'], ''),
        pass:               global.rxu.get(data, ['username'], ''),
        type:               typeArr[global.rxu.get(data, ['LoaiCoquan'], 0)],
        status:             statusArr[global.rxu.get(data, ['IsUpdateProfile'], 0)],

      })
      originForData += 1
    }

    for (let j in arrCellPost) {
      for (let k = 9; k < originForData; k++) {
        ws.getCell('A'+k).alignment = alignment
        ws.getCell(arrCellPost[j]+k).font = {...font, bold: false }
        ws.getCell(arrCellPost[j]+k).border = { bottom: {style : 'dotted'}, right: {style : 'thin'}}
      }
      ws.getCell(arrCellPost[j]+Number(originForData-1)).border = { bottom: {style : 'thin'}, right: {style : 'thin'}}
    }

    originForData++

    workbookCard.xlsx.writeBuffer().then(function (dataCard) {
      let blobpost = new Blob([dataCard], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

      saveAs(blobpost, 'Danh sách cơ quan đăng ký');
    });
  }
}


global.JSONToCSVConvertorMau7 = async function (JSONData, ReportTitle, ShowLabel = true) {
  var dataUser = JSONData
  var datePrint = new Date()
  var dday = String(datePrint.getDate()).padStart(2, '0');
  var mm = String(datePrint.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = datePrint.getFullYear();
  var fontSizeDocument = 14
  var fontSizeSuperScript = 12
  var dd = {
    pageMargins: [85, 56.69, 56.69, 56.69],
    lineHeight: 1.25,
    content: [
      { text: 'Mẫu số 07. Đơn trình báo mất thẻ nhà báo', alignment: 'left', bold: true, fontSize: 13 },
      {
        canvas: [
          {
            type: 'line',
            x1: 0, x2: 230, y1: 0, y2: 0,
            lineWidth: 0.5
          }
        ]
      },
      { text: '\n' },
      {
        text: [
          { text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\n', bold: true, fontSize: 13 },
          { text: 'Độc lập - Tự do - Hạnh Phúc' },
        ],
        alignment: 'center',
        bold: true
      },
      {
        canvas:
          [{
            type: 'line',
            x1: 0, x2: 170, y1: 0, y2: 0,
            lineWidth: 0.5
          }
          ],
        alignment: 'center'
      },
      { text: '\n' },
      { text: 'ĐƠN TRÌNH BÁO MẤT THẺ NHÀ BÁO\n\n', bold: true, alignment: 'center' },
      {
        text: [
          { text: `Kính gửi:  ${dataUser['cqCongAn'] || '...'}`  },
          { text: ' (1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        ],
        alignment: 'center'
      },
      { text: '\n' },
      {
        text: [
          { text: '\u200B\t\t1. Họ và tên: ' + dataUser['HoTenKhaiSinh'] || '...' },
          { text: ' (2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
        ]
      },
      { text: '\u200B\t\tNơi ở hiện tại: ' + dataUser['NoiO'] || '...' },
      { text: `\u200B\t\tĐiện thoại liên hệ: ${dataUser['DienThoai'] || '...' }` },
      {
        text: [
          { text: '\u200B\t\tHiện đang công tác tại ' + dataUser['TenCoQuanBaoChi'] || '...' },
          { text: ' (3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
        ]
      },
      { text: '\u200B\t\t2. Thông tin thẻ nhà báo bị mất:' },
      {
        text: [
          { text: `\u200B\t\tSố thẻ nhà báo: ${dataUser['MaSoThe'] || '....................'}`},
          {
            text: [
              { text: ' ; Kỳ hạn: 2021 - 2025' },
              { text: ' (4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
            ]
          },
          { text: `; Ngày cấp: ${dataUser['ngayCapThe'] || '..................'}; ` },
          {
            text: [
              { text: `Được Bộ Thông tin và Truyền thông cấp tại: ${dataUser['TenCoQuanBaoChi'] || '...'}` },
              { text: ' (3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
            ]
          },
        ]
      },
      { text: `\u200B\t\t3. Trình báo về sự việc mất thẻ nhà báo: ${dataUser['ndTrinhBao'] || '...'}` },
      { text: '\u200B\t\tTôi xin cam đoan nội dung trình báo trên đây là đúng sự thật và trách nhiệm trước pháp luật.\n' },
      {
        columnGap: 40,
        margin: [20, 10, 0, 100],
        columns: [
          {
            width: 'auto',
            alignment: 'center',
            text: [
              { text: 'Xác nhận của cơ quan công an\n', bold: true },
              { text: '(ký, đóng dấu hoặc ký số)', italics: true, }
            ]
          },
          {
            width: 'auto',
            alignment: 'center',
            text: [
              { text: `${dataUser['diaDiemTrinhBao'] || '...'}, ngày ${dday}, tháng ${mm}, năm ${yyyy}\n`, italics: true },
              { text: 'Người viết đơn\n', bold: true, },
              { text: '(ký, đóng dấu và ký số)', italics: true, },
            ]
          },
        ]
      },
      {
        text: [
          { text: 'Ghi chú:\n', bold: true },
          {
            text: [
              { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Cơ quan công an xã, phường, thị trấn nơi mất thẻ.\n' }
            ]
          },
          {
            text: [
              { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên người đề nghị cấp lại thẻ nhà báo.\n' }
            ]
          },
          {
            text: [
              { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên cơ quan báo chí, cơ quan công tác.\n' }
            ]
          },
          {
            text: [
              { text: '(4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Ghi theo kỳ hạn của thẻ nhà báo đã được cấp (ví dụ: 2021 - 2025).\n' }
            ]
          },
        ],
        fontSize: fontSizeSuperScript
      }
    ],
    defaultStyle: {
      lineHeight: 1.5,
      fontSize: fontSizeDocument,
      font: 'TimeNew'
    }
  }
  const pdfDocGenerator = pdfMake.createPdf(dd)
  pdfDocGenerator.getBlob((blob) => {
    saveAs(blob, `Mẫu 7 ${dataUser['HoTenKhaiSinh']}.pdf`)
  });
}

global.JSONToCSVConvertorMau6 = async function (JSONData, ReportTitle, ShowLabel = true) {
  var dataUser = JSONData
  var datePrint = new Date()
  var dday = String(datePrint.getDate()).padStart(2, '0');
  var mm = String(datePrint.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = datePrint.getFullYear();
  var fontSizeDocument = 14
  var fontSizeSuperScript = 12
  var guiCucBaoChi = "Cục Báo chí (Bộ Thông tin và Truyền thông)"
  var guiCucPhatThanh = "Cục Phát thanh, truyền hình và thông tin điện tử (Bộ Thông tin và Truyền thông)"

  var dd = {
    pageMargins: [85, 56.69, 56.69, 56.69],
    lineHeight: 1.25,
    content: [
      { text: 'Mẫu số 06. Đơn đề nghị lại cấp thẻ nhà báo', alignment: 'left', bold: true, fontSize: 13 },
      {
        canvas: [
          {
            type: 'line',
            x1: 0, x2: 240, y1: 0, y2: 0,
            lineWidth: 0.5
          }
        ]
      },
      { text: '\n' },
      {
        text: [
          { text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\n', bold: true, fontSize: 13 },
          { text: 'Độc lập - Tự do - Hạnh Phúc' },
        ],
        alignment: 'center',
        bold: true
      },
      {
        canvas:
          [{
            type: 'line',
            x1: 0, x2: 170, y1: 0, y2: 0,
            lineWidth: 0.5
          }
          ],
        alignment: 'center'
      },
      { text: '\n' },
      { text: 'ĐƠN ĐỀ NGHỊ CẤP LẠI THẺ NHÀ BÁO\n\n', bold: true, alignment: 'center' },
      {
        text: [
          { text: `Kính gửi: ${dataUser['LoaiCoquan'] == 3 ? guiCucPhatThanh : guiCucBaoChi} ` },
          { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        ],
        alignment: 'center'
      },
      { text: '\n' },
      { text: '\u200B\t\tCăn cứ quy định tại khoản 4 Điều 28 Luật Báo chí năm 2016,'},
      {
        text: [
          { text: `\u200B\t\tTên tôi là ${dataUser['HoTenKhaiSinh'] || '...'} ` },
          { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
        ]
      },
      {
        text: [
          { text: `\u200B\t\tHiện đang công tác tại ${dataUser['TenCoQuanBaoChi'] || '...'} ` },
          { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
        ]
      },
      { text: '\u200B\t\tNay tôi làm đơn này để đề nghị Bộ Thông tin và Truyền thông cấp lại thẻ nhà báo:'},
      {
        text: [
          { text: `\u200B\t\t- Số thẻ nhà báo ${dataUser['MaSoThe'] || '................'};` },
          {
            text: [
              { text: ` Kỳ hạn 2021 - 2025 ` },
              { text: '(4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
            ]
          },
          {
            text: [
              { text: `\n\u200B\t\t- Lý do: Cấp lại do hỏng thẻ ` },
              { text: '(5)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
            ]
          },
          {text: '\n\u200B\t\tTrân trọng.\n'}
        ]
      },
      {
        columnGap: 40,
        margin: [20, 10, 0, 150],
        columns: [
          {
            width: 'auto',
            alignment: 'center',
            text: [
              { text: 'Xác nhận của người đứng đầu\n cơ quan báo chí/cơ quan công tác\n', bold: true },
              { text: '(ký, đóng dấu hoặc ký số)', italics: true, }
            ]
          },
          {
            width: 'auto',
            alignment: 'center',
            text: [
              { text: `${dataUser['diaChiCoQuan'] || '...'}, ngày ${dday}, tháng ${mm}, năm ${yyyy}\n`, italics: true },
              { text: 'Người viết đơn\n', bold: true, },
              { text: '(ký, đóng dấu hoặc ký số)', italics: true, },
            ]
          },
        ]
      },
      { text: '\n' },
      {
        text: [
          { text: 'Ghi chú:\n', bold: true },
          {
            text: [
              { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Văn bản gửi đến Cục Báo chí (Bộ Thông tin và Truyền thông) đối với các đối tượng thuộc các cơ quan báo in, tạp chí in, báo điện tử, tạp chí điện tử, các cơ quan quản lý nhà nước về báo chí, hội nhà báo các cấp và giảng viên chuyên ngành về báo chí;\nVăn bản gửi đến Cục Phát thanh, truyền hình và thông tin điện tử (Bộ Thông tin và Truyền thông) đối với các đối tượng hoạt động trong lĩnh vực phát thanh, truyền hình.\n' }
            ]
          },
          {
            text: [
              { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên người đề nghị cấp lại thẻ nhà báo.\n' }
            ]
          },
          {
            text: [
              { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên cơ quan báo chí, cơ quan công tác.\n' }
            ]
          },
          {
            text: [
              { text: '(4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Ghi theo kỳ hạn của thẻ nhà báo đã được cấp (ví dụ: 2021 - 2025).\n' }
            ]
          },
          {
            text: [
              { text: '(5)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Ghi rõ lý do thẻ nhà báo bị mất hoặc bị hỏng.\n' }
            ]
          },
        ],
        fontSize: fontSizeSuperScript
      }
    ],
    defaultStyle: {
      lineHeight: 1.2,
      fontSize: fontSizeDocument,
      font: 'TimeNew'
    }
  }
  const pdfDocGenerator = pdfMake.createPdf(dd)
  pdfDocGenerator.getBlob((blob) => {
    saveAs(blob, `Mẫu 6 ${dataUser['HoTenKhaiSinh']}.pdf`)
  });
}

global.JSONToCSVConvertorMau5 = async function (JSONData, ReportTitle, ShowLabel = true) {
  var dataUser = JSONData
  var datePrint = new Date()
  var dday = String(datePrint.getDate()).padStart(2, '0');
  var mm = String(datePrint.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = datePrint.getFullYear();

  var guiCucBaoChi = "Cục Báo chí (Bộ Thông tin và Truyền thông)"
  var guiCucPhatThanh = "Cục Phát thanh, truyền hình và thông tin điện tử (Bộ Thông tin và Truyền thông)"

  var fontSizeDocument = 14
  var fontSizeSuperScript = 12
  var dd = {
    pageMargins: [55, 56.69, 56.69, 56.69],
    lineHeight: 1.25,
    content: [
      { text: 'Mẫu số 05. Văn bản xác nhận của cơ quan trước khi chuyển công tác', alignment: 'left', bold: true, fontSize: 13 },
      {
        canvas: [
          {
            type: 'line',
            x1: 0, x2: 380, y1: 0, y2: 0,
            lineWidth: 0.5
          }
        ]
      },
      { text: '\n\n' },
      {
        columnGap: 10,
        columns: [
          {
            width: 200,
            alignment: 'center',
            stack: [
              { text: 'CƠ QUAN, TỔ CHỨC CHỦ QUẢN', fontSize: 13 },
              { text: [
                { text: `${dataUser['TenCoQuanBaoChi'].toUpperCase()} `, bold: true, fontSize: 13 },
                { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              ]},
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0, x2: 45, y1: 0, y2: 0,
                    lineWidth: 0.5
                  }
                ]
              },
              '\n',
              { text: 'Số: ...', fontSize: 13},
              { text: 'V/v xác nhận cho\nngười đề nghị đổi thẻ nhà báo', fontSize: 12}
            ]
          },
          {
            width: '*',
            alignment: 'center',
            stack: [
              { text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM', fontSize: 13, bold: true },
              { text: 'Độc lập - Tự do - Hạnh Phúc', bold: true},
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 0, x2: 180, y1: 0, y2: 0,
                    lineWidth: 0.5
                  }
                ]
              },
              '\n',
              { text: `${dataUser['diaChiCoQuan'] || '...'}, ngày ${dday}, tháng ${mm}, năm ${yyyy}\n`, italics: true }
            ]
          },
        ]
      },
      { text: '\n' },
      {
        text: [
          { text: `Kính gửi: ${dataUser['LoaiCoquan'] == 3 ? guiCucPhatThanh : guiCucBaoChi} ` },
          { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        ],
        alignment: 'center'
      },
      { text: '\n' },
      { text: '\u200B\t\tCăn cứ quy định tại khoản 3 Điều 28 Luật Báo chí năm 2016,'},
      {
        text: [
          { text: `\u200B\t\tTheo đề nghị của ông/bà: ${dataUser['HoTenKhaiSinh']} ` },
          { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
        ]
      },
      {
        text: [
          { text: `\u200B\t\t${dataUser['TenCoQuanBaoChi']} `, italics: true},
          { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
          { text: ' xác nhận:' },
        ]
      },
      {
        text: [
          { text: `\u200B\t\t- Họ và tên nhà báo: ${dataUser['HoTenKhaiSinh']}` },
          { text: '(4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
        ]
      },
      {
        text: [
          { text: `\u200B\t\t- Số thẻ nhà báo: ${dataUser['MaSoThe'] || '...'};` },
          {
            text: [
              { text: ' Kỳ hạn 2021 - 2025 ' },
              { text: '(5)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
            ]
          },
          {
            text: [
              { text: `; Được cấp tại: ${dataUser['TenCoQuanBaoChi']} ` },
              { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } }
            ]
          },
        ]
      },
      {text: [
        { text: '\u200B\t\tChấm dứt làm việc tại '},
        { text: `${dataUser['TenCoQuanBaoChi'] || '(tên cơ quan báo chí)'} `, italics: true},
        { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        { text: ' kể từ ngày ... tháng ... năm ...'}
      ]},
      {text: [
        { text: '\u200B\t\tTrong thời gian làm việc tại '},
        { text: `${dataUser['TenCoQuanBaoChi']} `, italics: true},
        { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        { text: `, ông/bà ${dataUser['HoTenKhaiSinh']} `},
        { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        { text: ' không thuộc một hoặc các trường hợp quy định tại điểm b, c, d và đ khoản 3 Điều 27 Luật Báo chí năm 2016.'}
      ]},
      {text: [
        { text: `\u200B\t\t${dataUser['TenCoQuanBaoChi'] || '(tên cơ quan báo chí)'} `, italics: true},
        { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        { text: ` đã thu lại thẻ nhà báo của ông/bà ${dataUser['HoTenKhaiSinh']}  `},
        { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
        { text: ' và nộp về Bộ Thông tin và Truyền thông theo quy định tại điểm c khoản 5 Điều 28 Luật Báo chí năm 2016.'}
      ]},
      { text: '\u200B\t\tTrân trọng.' },
      {
        columnGap: 40,
        margin: [20, 10, 0, 120],
        columns: [
          {
            width: 200,
            alignment: 'left',
            text: [
              { text: 'Nơi nhận:\n', italics: true, bold: true, fontSize: fontSizeSuperScript },
              { text: '- Như trên;\n', fontSize: 11 },
              { text: '- ...;\n', fontSize: 11 },
              { text: '- Lưu: VT ...\n', fontSize: 11 },
            ]
          },
          {
            width: 'auto',
            alignment: 'center',
            text: [
              { text: `${dataUser['diaDiemKy'] || '...'}, ngày ${dday}, tháng ${mm}, năm ${yyyy}\n`, italics: true },
              { text: 'Người đứng đầu cơ quan\n', bold: true, },
              { text: '(ký, đóng dấu hoặc ký số)', italics: true, },
            ]
          },
        ]
      },
      
      { text: [
          { text: 'Ghi chú:\n', bold: true },
          {
            text: [
              { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên cơ quan trước khi chuyển công tác.\n' }
            ]
          },
          {
            text: [
              { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Văn bản gửi đến Cục Báo chí (Bộ Thông tin và Truyền thông) đối với các đối tượng thuộc các cơ quan báo in, tạp chí in, báo điện tử, tạp chí điện tử, các cơ quan quản lý nhà nước về báo chí, hội nhà báo các cấp và giảng viên chuyên ngành về báo chí; \nVăn bản gửi đến Cục Phát thanh, truyền hình và thông tin điện tử (Bộ Thông tin và Truyền thông) đối với các đối tượng hoạt động trong lĩnh vực phát thanh, truyền hình.\n' }
            ]
          },
          {
            text: [
              { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên người đề nghị đổi thẻ nhà báo.\n' }
            ]
          },
          {
            text: [
              { text: '(4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên người đề nghị đổi thẻ nhà báo; viết chữ in hoa đủ dấu.\n' }
            ]
          },
          {
            text: [
              { text: '(5)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Ghi theo kỳ hạn của thẻ nhà báo đã được cấp (ví dụ: 2021 - 2025).\n' }
            ]
          },
        ],
        fontSize: fontSizeSuperScript
      }
    ],
    defaultStyle: {
      lineHeight: 1.2,
      fontSize: fontSizeDocument,
      font: 'TimeNew'
    }
  }
  const pdfDocGenerator = pdfMake.createPdf(dd)
  pdfDocGenerator.getBlob((blob) => {
    saveAs(blob, `Mẫu 5 ${dataUser['HoTenKhaiSinh']}.pdf`)
  });
}

global.JSONToCSVConvertorMau4 = async function (JSONData, ReportTitle, ShowLabel = true) {
  var fontSizeDocument = 14
  var fontSizeSuperScript = 12
  var dd = {
    pageMargins: [ 85, 42, 56.69, 42 ],
    lineHeight: 1.25,
    content: [
      { text: 'Mẫu số 04. Văn bản của cơ quan báo chí, cơ quan công tác mới đề nghị đổi thẻ nhà báo', alignment: 'left', bold: true, fontSize: 13 },
      {
        canvas: [
          {
            type: 'line',
            x1: 0, x2: 440, y1: 0, y2: 0,
            lineWidth: 0.5
          }
        ]
      },
      { text: '\n' },
      {
        margin: [-28,0,-28,0],
        columnGap: 0,
        columns: [
          {
            stack: [
              { text: 'CƠ QUAN, TỔ CHỨC CHỦ QUẢN\n' },
              { text: [{text: global.rxu.get(JSONData, ['CoQuanBaoChiObj', 'Cat_Name'], ''), bold: true}, {text:' (1)', sup: { offset: '30%', fontSizeSuperScript }}] },
              {
                canvas: [
                  { type: 'line', x1: 0, y1: 0, x2: 50, y2: 0, lineWidth: .5 }
                ]
              },
              { text: '\nSố: …\n' },
              { text: 'V/v đề nghị đổi thẻ nhà báo', fontSize: 12 }
            ],
            fontSize: 13,
            alignment: 'center',
            width: 200
          },
          {
            stack: [
              { text: 'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM\n', bold: true, fontSize: 13, noWrap: true },
              { text: 'Độc lập - Tự do - Hạnh Phúc\n', bold: true, noWrap: true },
              { canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: .5 }
              ]}, 
              { text: rxu.getFullDate(new Date() / 1000), italics: true, noWrap: true, margin: [0,15,0,0] },
            ],
            alignment: 'center',
            width: '*'
          },
        ]
      },
      { text: '\n' },
      {
        text: [
          { text: [{text: 'Kính gửi: ' + (rxu.get(JSONData, ['CoQuanBaoChiObj', 'Type']) === 1 ? 'Cục Báo chí (Bộ Thông tin và Truyền thông)' : 'Cục Phát thanh, truyền hình và thông tin điện tử (Bộ Thông tin và Truyền thông)')}, { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } } ]},
        ],
        alignment: 'center'
      },
      { text: '\n' },
      { text: '\u200B\t\tCăn cứ quy định tại khoản 4 Điều 28 Luật Báo chí năm 2016,' },
      { text: [
        {text: '\u200B\t\t' + rxu.get(JSONData, ['CoQuanBaoChiObj', 'Cat_Name'])},{text:' (1)', sup: { offset: '30%', fontSizeSuperScript }},
        {text: ' đề nghị Bộ Thông tin và Truyền thông xem xét đổi thẻ nhà báo về ' + rxu.get(JSONData, ['CoQuanBaoChiObj', 'Cat_Name'])},{text:' (1)', sup: { offset: '30%', fontSizeSuperScript }},
        { text: ' cho:'}
      ]},
      {
        fontSize: 13,
        id: "Table",
        table: {
          headerRows: 1,
          widths: [28, 'auto', 'auto', 58, 'auto'],
          body: [
            [
              {text: 'STT', bold: true, alignment: 'center'}, 
              {text: [{text: 'Họ và tên '}, {text:' (3)', sup: { offset: '30%', fontSizeSuperScript }}], bold: true, alignment: 'center'}, 
              {text: 'Số thẻ nhà báo', bold: true, alignment: 'center'}, 
              {text: [{text: 'Kỳ hạn '}, {text:' (4)', sup: { offset: '30%', fontSizeSuperScript }}], bold: true, alignment: 'center'},
              {text: [{text: 'Tên cơ quan trước khi chuyển công tác '}, {text:' (5)', sup: { offset: '30%', fontSizeSuperScript }}], bold: true, alignment: 'center'},
            ],
          ]
        },
      },
      { text: '\n\u200B\tTrân trọng./.\n' },
      {
        columnGap: 40,
        margin: [0, 10, 0, 50],
        columns: [
          {
            alignment: 'left',
            text: [
              { text: 'Nơi nhận:\n', bold: true, fontSize: 12 },
              { text: '- Như trên;\n- ...;\n- Lưu: VT, …', fontSize: 11 }
            ]
          },
          {
            alignment: 'center',
            text: [
              { text: 'Người đứng đầu cơ quan báo chí/\ncơ quan công tác\n', bold: true },
              { text: '(ký, đóng dấu hoặc ký số)', italics: true, },
            ]
          },
        ]
      },
      {
        text: [
          { text: 'Ghi chú:\n', bold: true },
          {
            text: [
              { text: '(1)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Tên cơ quan báo chí, cơ quan công tác mới.\n' }
            ]
          },
          {
            text: [
              { text: '(2)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Văn bản gửi đến Cục Báo chí (Bộ Thông tin và Truyền thông) đối với các đối tượng thuộc các cơ quan báo in, tạp chí in, báo điện tử, tạp chí điện tử, các cơ quan quản lý nhà nước về báo chí, hội nhà báo các cấp và giảng viên chuyên ngành về báo chí;\nVăn bản gửi đến Cục Phát thanh, truyền hình và thông tin điện tử (Bộ Thông tin và Truyền thông) đối với các đối tượng hoạt động trong lĩnh vực phát thanh, truyền hình.\n' }
            ]
          },
          {
            text: [
              { text: '(3)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Viết chữ in hoa đủ dấu.\n' }
            ]
          },
          {
            text: [
              { text: '(4)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Ghi theo kỳ hạn của thẻ nhà báo đã được cấp (ví dụ: 2021 - 2025).\n' }
            ]
          },
          {
            text: [
              { text: '(5)', sup: { offset: '30%', fontSize: fontSizeSuperScript } },
              { text: ' Ghi rõ lý do thẻ nhà báo bị mất hoặc bị hỏng.\n' }
            ]
          },
        ],
        fontSize: 12
      }
    ],
    defaultStyle: {
      lineHeight: 1.2,
      fontSize: fontSizeDocument,
      font: 'TimeNew'
    }
  }
  let index = dd.content.findIndex(o => o['id'] === 'Table')
  if(index !== -1) {
    let table = dd.content[index]['table']['body'] || []
    let dataCard = typeof JSONData.data !== 'object' ? JSON.parse(JSONData.data) : JSONData.data
    for(let i in dataCard) {
      let HoTenKhaiSinh = rxu.get(dataCard[i], ['HoTenKhaiSinh'], '').toUpperCase()
      let tmp = [
        { text: i+1, alignment: 'center' },
        HoTenKhaiSinh,
        rxu.get(dataCard[i], ['MaSoThe'], ''),
        '2021-2025',
        rxu.get(dataCard[i], ['CoQuanBaoChiCu'], ''),
      ]
      table[table.length] = tmp
    }
    dd.content[index]['table']['body'] = table
  }
  const pdfDocGenerator = pdfMake.createPdf(dd)
  pdfDocGenerator.getBlob((blob) => {
    saveAs(blob, "Mẫu 04.pdf")
  });
}

export default {}
