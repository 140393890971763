global.rootRequire = global.rootRequire || function () { return { default: {} } }
const Admin = global.rootRequire('components/admin').default
const noteComponent = global.rootRequire('components/admin/note').default
const cateComponent = global.rootRequire('components/admin/cate').default
const productcateComponent = global.rootRequire('components/admin/productcate').default
const provinceComponent = global.rootRequire('components/admin/province').default
const reviewComponent = global.rootRequire('components/admin/review').default
const configComponent = global.rootRequire('components/admin/config').default
const dashboardComponent = global.rootRequire('components/admin/dashboard').default
const positionComponent = global.rootRequire('components/admin/position').default
const titleComponent = global.rootRequire('components/admin/title').default
const officeComponent = global.rootRequire('components/admin/office').default
const newspaperOfficeComponent = global.rootRequire('components/admin/newspaperOffice').default
const manualComponent = global.rootRequire('components/admin/manual').default
const manualBarComponent = global.rootRequire('components/admin/manualBar').default

const review1Component = global.rootRequire('components/admin/card/review_1').default
const review2Component = global.rootRequire('components/admin/card/review_2').default
const review3Component = global.rootRequire('components/admin/card/review_3').default
const review4Component = global.rootRequire('components/admin/card/review_4').default
const review7Component = global.rootRequire('components/admin/card/review_7').default
const printComponent = global.rootRequire('components/admin/card/print').default
const statisticsComponent = global.rootRequire('components/admin/card/statistics').default
const analyticsComponent = global.rootRequire('components/admin/card/analytics').default
const review8Component = global.rootRequire('components/admin/card/review_8').default
const printlistComponent = global.rootRequire('components/admin/card/printlist').default

//
const cardComponent = global.rootRequire('components/admin/card/card').default
const allUserComponent = global.rootRequire('components/admin/rbac/user').default
const allMemberComponent = global.rootRequire('components/admin/rbac/member').default
const roleComponent = global.rootRequire('components/admin/rbac/role').default
const permissionComponent = global.rootRequire('components/admin/rbac/permission').default
const loginComponent = global.rootRequire('components/authenticate/login').default
const loginSsoComponent = global.rootRequire('components/authenticate/loginSso').default
const registerComponent = global.rootRequire('components/authenticate/register').default
const passwordComponent = global.rootRequire('components/authenticate/password').default
const logsComponent = global.rootRequire('components/admin/rbac/logaction').default
const changeInfoComponent = global.rootRequire('components/admin/rbac/changeInfo').default
const changePasswordComponent = global.rootRequire('components/admin/rbac/changePassword').default
const verifyComponent = global.rootRequire('components/authenticate/verify').default
const syncComponent = global.rootRequire('components/admin/sync').default
const mediaComponent = global.rootRequire('components/admin/media').default
const mediaListComponent = global.rootRequire('components/admin/medialist').default



// [[RX_ROUTES_IMPORT]] //
let adminRoutes = { path: '/admin', component: Admin, routes: [{ path: '/admin', component: cardComponent, exact: true }] }
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/dashboard', component: dashboardComponent }])

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/extenal', component: reviewComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/review_1', component: review1Component }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/review_2', component: review2Component }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/review_3', component: review3Component }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/review_4', component: review4Component }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/review_7', component: review7Component }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/review_8', component: review8Component }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/print', component: printComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/statistics', component: statisticsComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/analytics', component: analyticsComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/printlist', component: printlistComponent }])


adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/sync', component: syncComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/cate', component: cateComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/note', component: noteComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/productcate', component: productcateComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/province', component: provinceComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/config', component: configComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/position', component: positionComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/title', component: titleComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/office', component: officeComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/newspaper_office', component: newspaperOfficeComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/manual', component: manualComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/manualBar', component: manualBarComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/media', component: mediaComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/medialist', component: mediaListComponent }])

// 1
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/card', component: cardComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/card',search : 'search', component: cardComponent }])

let Modulroutes = []; Modulroutes = Modulroutes.concat([adminRoutes])

let rxgenrbacRoutes = { path: '/rbac', component: Admin, routes: [{ path: '/rbac', component: noteComponent, exact: true }]}
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/user', component: allUserComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/member', component: allMemberComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/role', component: roleComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/permission', component: permissionComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/logs', component: logsComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/changeInfo', component: changeInfoComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/changePassword', component: changePasswordComponent }])

let loginRoutes = { path: '/login', component: loginComponent }
let loginSsoRoutes = { path: '/loginSso', component: loginSsoComponent }
let registerRoutes = { path: '/register', component: registerComponent }
let passwordRoutes = { path: '/password', component: passwordComponent }
let verifyRoutes = { path: '/kiemtra', component: verifyComponent }

// [[RX_ROUTES_RBAC]] //
Modulroutes = Modulroutes.concat([rxgenrbacRoutes, loginRoutes, registerRoutes, loginSsoRoutes,passwordRoutes,verifyRoutes])
// [[RX_ROUTES]] //
module.exports = Modulroutes
