/* global rxu */
import React from 'react'
// import CKEditor from 'react-ckeditor-component'
import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const QRCode = require('qrcode.react')
function parseTime(timeOld, timeNew) {
  var date = Date.now()
  if(timeOld){
    date = new Date(parseInt(timeOld.substr(6), 10))
  }
  else if(timeNew){
    date = new Date(timeNew*1000)
  }
  return date
}

function checkAll() {
    const allCheckBox = document.getElementById('HoSoID-All').checked
    if(allCheckBox === true){
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = true
            }
        }
    }else{
        let check = document.getElementsByClassName('css-checkboxShow')
        if(check &&check.length > 0) {
            for(let i = 0; i < check.length; i++) {
                check[i].checked = false
            }
        }
    }
}
export const renderTableHead = (inthis, vm) =>
  <tr>
    <th className='rxwidth--100'>STT</th>
    <th className='rxwidth--100'>
        <span id="HoSoChoIn">
            <input onClick={()=> checkAll()}  type='checkbox' className='css-checkbox' id='HoSoID-All' />
            <label className='css-label' htmlFor='HoSoID-All' data-toggle='tooltip' data-placement='auto' title='' data-original-title='Tất cả'></label>
        </span>
        Thuộc cơ quan
    </th>
    <th className='rxwidth--100'>Ảnh thẻ</th>
    <th className={inthis.helpSortClass('HoTenKhaiSinh', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'HoTenKhaiSinh')} >Họ và tên</th>
    <th className={inthis.helpSortClass('ButDanh', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'ButDanh')} >Bút danh</th>
    <th className='rxwidth--100'>Chức vụ</th>
    <th className='rxwidth--100'>Mã thẻ</th>
    <th className='rxwidth--100'>Thao tác</th>
  </tr>
export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map((perdata,index) => (<tr key={perdata._id} >
    <td>{index + 1}</td>
    <td>
        <span className="news_inputcheck_radio">
            <input type="checkbox" className="css-checkboxShow" dataid="233" id="IDHoSo-233" value="233" />
        </span>
        {perdata.TenCoQuanBaoChi}
    </td>
    <td><img className='betable__img' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_landscape || 'ico_app_default.jpg')} /></td>
    <td onClick={(e) =>inthis.onClickViewData(e, perdata)} className='rx-cursor'>{perdata.HoTenKhaiSinh}</td>
    <td>{perdata.ButDanh}</td>
    <td>{perdata.TenChucVu}</td>
    <td>{perdata.MaTheCu}</td>
    {inthis.renderTableBodyBtns(inthis, perdata)}
  </tr>))
export const renderComponent = (vm) =>
  <AdminBlock name='THẺ' desc=''>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
      <RxCrudCard renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='Card' api={'api_card'}
        form={[
            { type: 'header', func: (inthis) => (
                <div>
                    <div className='class_avatar'>
                        <RxUpload callback={(e) => inthis.callbackUpload(e, 'img_landscape')} images={inthis.state.editingData.img_landscape} type='avatar' />
                        <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                        {/*<div className='text-center avatar_name'>{rxu.rxChangeAlias(inthis.state.editingData.HoTenKhaiSinh)}</div>*/}
                    </div>
                    <div className='form-header-title'>
                        <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                        <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                        <div className='text-center title-main'>BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO</div>
                        <div className='text-center title-alias'>(Theo thông tư số 49/2016/TT-BTTTT)</div>
                        <div className='form-header-bottom'>
                            <span>Tên cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.cate}
                                results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                                onChange={(result) => { inthis.onBlurDataValue(result, 'TenCoQuanBaoChi') }}
                                api='api_newspaper_office'
                                paging={inthis.state.pagingCat}
                                optionDefault='--Chọn cơ quan báo chí--'
                            />
                        </div>
                    </div>
                    <div className='form-header-right barcode'>
                        <div>
                            <QRCode id={inthis.state.editingData.codeGen || '1'} value={inthis.state.editingData.codeGen || '1'} size={160} level={"H"} includeMargin={true} />
                            <div className='text-center form-avatar-sub'>{inthis.state.editingData.codeGen || ''}</div>
                            {/* <div className='form-header-code'>Số thẻ nhà báo cũ: {inthis.state.editingData.MaTheCu}</div> */}
                        </div>
                    </div>
                </div>
            ) },
            { type: 'devide' },
            { type: 'form', name: 'Thông tin cá nhân', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Loại hồ sơ<i className='iconcustom-require icon-require'/></span>
                        <RxSelectbox
                            name='typeHS'
                            options={global.typeHS}
                            results={inthis.state.editingData.Type || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'Type') }}
                            optionDefault='--Chọn loại hồ sơ--'
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Họ tên khai sinh<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenKhaiSinh')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Tên thường dùng<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenThuongDung || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenThuongDung')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Giới tính<i className='iconcustom-require icon-require'/></span>
                        <RxSelectbox
                            name='GioiTinh'
                            options={global.GioiTinh}
                            results={inthis.state.editingData.GioiTinh || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'GioiTinh') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                    <div>
                        <span>Bút danh<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ButDanh || ''} onChange={(e) => inthis.onBlurData(e, 'ButDanh')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức vụ<i className='iconcustom-require icon-require'/></span>
                        <RxSelectbox
                            name='cate'
                            options={inthis.state.position}
                            results={inthis.state.editingData.TenChucVu || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'TenChucVu') }}
                            api='api_position'
                            optionDefault='--Nhóm chức vụ--'
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Số CMND/CCCD<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.CMND || ''} onChange={(e) => inthis.onBlurData(e, 'CMND')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Số sổ BHXH<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.SoBHXH || ''} onChange={(e) => inthis.onBlurData(e, 'SoBHXH')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ảnh CMND/CCCD<i className='iconcustom-require icon-require'/></span>
                        <RxUploadCMND callback={(e) => inthis.callbackUpload(e, 'cmnd_truoc')} images={inthis.state.editingData.cmnd_truoc} title='Ảnh mặt trước' />
                        <RxUploadCMND callback={(e) => inthis.callbackUpload(e, 'cmnd_sau')} images={inthis.state.editingData.cmnd_sau} title='Ảnh mặt sau' />
                    </div>
                    <div>
                        <span>Mã số thuế<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.MaSoThue || ''} onChange={(e) => inthis.onBlurData(e, 'MaSoThue')} className='fullwidth-input' />
                    </div>
                </div>
                <div className="rx-form__note">Nhập năm sinh để tìm kiếm nhanh ngày sinh</div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày sinh<i className='iconcustom-require icon-require'/></span>
                        <DatePicker
                            dateFormat="dd/MM/YYY"
                            selected={ parseTime(inthis.state.editingData.NgaySinh, inthis.state.editingData.NgaySinhConvert) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            inthis.state.editingData.StrNgaySinh = ''
                            inthis.state.editingData.NgaySinh = ''
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'NgaySinhConvert')
                          }}
                          />
                    </div>
                    <div>
                        <span>Dân tộc<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.DanToc || ''} onChange={(e) => inthis.onBlurData(e, 'DanToc')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Quê quán<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.QueQuan || ''} onChange={(e) => inthis.onBlurData(e, 'QueQuan')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Nơi ở<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NoiO || ''} onChange={(e) => inthis.onBlurData(e, 'NoiO')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Đảng viên / Đoàn viên<i className='icon-require'/></span>
                        <RxSelectbox
                            name='DangVien'
                            options={global.DangVien}
                            results={inthis.state.editingData.DangVien || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'DangVien') }}
                            optionDefault='--Chọn--'
                            ten={true}
                        />
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'TRÌNH ĐỘ HỌC VẤN', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trình độ văn hoá<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoVanHoa || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoVanHoa')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__title--sub'>Chuyên ngành báo chí<span/></div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trình độ TĐH báo chí<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoBaoChiTDH || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoBaoChiTDH')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Trình độ TĐH báo chí<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoBaoChiDH || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoBaoChiDH')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Hình thức đào tạo<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HinhThucDaoTao || ''} onChange={(e) => inthis.onBlurData(e, 'HinhThucDaoTao')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Trường học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} onChange={(e) => inthis.onBlurData(e, 'TruongHoc')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngành học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NganhHoc || ''} onChange={(e) => inthis.onBlurData(e, 'NganhHoc')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Năm tốt nghiệp<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NamTotNghiep || ''} onChange={(e) => inthis.onBlurData(e, 'NamTotNghiep')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__title--sub'>Chuyên ngành khác<span/></div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trình độ TĐH chuyển ngành khác<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoChuyenNganhKhacTDH || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoChuyenNganhKhacTDH')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Trình độ TĐH chuyển ngành khác<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoChuyenNganhKhacDH || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoChuyenNganhKhacDH')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Hình thức đào tạo<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HinhThucDaoTao || ''} onChange={(e) => inthis.onBlurData(e, 'HinhThucDaoTao')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Trường học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} onChange={(e) => inthis.onBlurData(e, 'TruongHoc')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngành học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NganhHoc || ''} onChange={(e) => inthis.onBlurData(e, 'NganhHoc')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Năm tốt nghiệp<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NamTotNghiep || ''} onChange={(e) => inthis.onBlurData(e, 'NamTotNghiep')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__title--sub'>Văn bằng khác (nếu có)<span/></div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngoại ngữ<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NgoaiNgu || ''} onChange={(e) => inthis.onBlurData(e, 'NgoaiNgu')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Chứng chỉ<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ChungChi || ''} onChange={(e) => inthis.onBlurData(e, 'ChungChi')} className='fullwidth-input' />
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'THAM GIA VÀO NGÀNH BÁO CHÍ', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày và nơi tham gia<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NgayVaNoiVaoNganhBaoChi || ''} onChange={(e) => inthis.onBlurData(e, 'NgayVaNoiVaoNganhBaoChi')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Biên chế<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.BienChe || ''} onChange={(e) => inthis.onBlurData(e, 'BienChe')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bậc lương<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.BacLuong || ''} onChange={(e) => inthis.onBlurData(e, 'BacLuong')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Số thẻ nhà báo cũ<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.MaTheCu || ''} onChange={(e) => inthis.onBlurData(e, 'MaTheCu')} className='fullwidth-input' />
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'table', name: 'Quá trình hoạt động' },
            { type: 'devide' },]}
        view={[
            { type: 'header', func: (inthis) => (
                <div>
                    <div className='class_avatar'>
                        <img alt='' src={global.rxu.config.base_api + '/upload/image/' + (inthis.state.editingData.img_landscape || 'ico_app_default.jpg')} />
                        <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                    </div>
                    <div className='form-header-title'>
                        <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                        <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                        <div className='text-center title-main'>BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO</div>
                        <div className='text-center title-alias'>(Theo thông tư số 49/2016/TT-BTTTT)</div>
                        <div className='form-header-bottom'>
                            <span>Tên cơ quan báo chí<i className='iconcustom-require icon-require'/></span>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.TenCoQuanBaoChi || ''} className='fullwidth-input' disabled />
                        </div>
                    </div>
                    <div className='form-header-right barcode'>
                        <div>
                            <QRCode id={inthis.state.editingData.codeGen || '1'} value={inthis.state.editingData.codeGen || '1'} size={160} level={"H"} includeMargin={true} />
                            <div className='text-center form-avatar-sub'>{inthis.state.editingData.codeGen || ''}</div>
                            {/* <div className='form-header-code'>Số thẻ nhà báo cũ: {inthis.state.editingData.MaTheCu}</div> */}
                        </div>
                    </div>
                </div>
            ) },
            { type: 'devide' },
            { type: 'form', name: 'Thông tin cá nhân', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Loại hồ sơ<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={rxu.rxparseTen('typeHS', inthis.state.editingData.Type) || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Họ tên khai sinh<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Tên thường dùng<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenThuongDung || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Giới tính<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={rxu.rxparseTen('GioiTinh', inthis.state.editingData.GioiTinh) || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Bút danh<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ButDanh || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức vụ<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TenChucVu || ''} className='fullwidth-input' disabled />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Số CMND/CCCD<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.CMND || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Số sổ BHXH<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.SoBHXH || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ảnh CMND/CCCD<i className='iconcustom-require icon-require'/></span>
                        <img alt='' src={global.rxu.config.base_api + '/upload/image/' + (inthis.state.editingData.cmnd_truoc || 'ico_app_default.jpg')} />
                        <img alt='' src={global.rxu.config.base_api + '/upload/image/' + (inthis.state.editingData.cmnd_sau || 'ico_app_default.jpg')} />
                    </div>
                    <div>
                        <span>Mã số thuế<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.MaSoThue || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày sinh<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={ rxu.parseTime(inthis.state.editingData.StrNgaySinh, inthis.state.editingData.NgaySinhConvert) } className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Dân tộc<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.DanToc || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Quê quán<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.QueQuan || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Nơi ở<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NoiO || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Đảng viên / Đoàn viên<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.DangVien || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'TRÌNH ĐỘ HỌC VẤN', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trình độ văn hoá<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoVanHoa || ''}className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__title--sub'>Chuyên ngành báo chí<span/></div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trình độ TĐH báo chí<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoBaoChiTDH || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Trình độ TĐH báo chí<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoBaoChiDH || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Hình thức đào tạo<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HinhThucDaoTao || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Trường học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngành học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NganhHoc || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Năm tốt nghiệp<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NamTotNghiep || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__title--sub'>Chuyên ngành khác<span/></div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trình độ TĐH chuyển ngành khác<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoChuyenNganhKhacTDH || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Trình độ TĐH chuyển ngành khác<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoChuyenNganhKhacDH || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Hình thức đào tạo<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.HinhThucDaoTao || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Trường học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngành học<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NganhHoc || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Năm tốt nghiệp<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NamTotNghiep || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__title--sub'>Văn bằng khác (nếu có)<span/></div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngoại ngữ<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NgoaiNgu || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Chứng chỉ<i className='icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.ChungChi || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'THAM GIA VÀO NGÀNH BÁO CHÍ', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày và nơi tham gia<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.NgayVaNoiVaoNganhBaoChi || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Biên chế<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.BienChe || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bậc lương<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.BacLuong || ''} className='fullwidth-input' disabled/>
                    </div>
                    <div>
                        <span>Số thẻ nhà báo cũ<i className='iconcustom-require icon-require'/></span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.MaTheCu || ''} className='fullwidth-input' disabled/>
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'table', name: 'Quá trình hoạt động' },
            { type: 'devide' }]}
      />
    </div>
  </AdminBlock>
