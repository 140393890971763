/* global rxu */
import React from 'react'
// import CKEditor from 'react-ckeditor-component'
// import DatePicker from 'react-datepicker'
import DatePicker, { registerLocale } from "react-datepicker"
import el from "date-fns/locale/vi"; // the locale you want

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrudCard = global.rootRequire('components/shares/rxCrudCard').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxUploadCMND = global.rootRequire('components/shares/rxUploadCMND').default
const QRCode = require('qrcode.react')
const statusTinhtrang = ['Tạo mới','CV Cục đã duyệt', 'LDP đã duyệt','LDC đã Duyệt', 'LDB đã Duyệt', 'Đã in', 'Trả hồ sơ','Chờ in']
const statusPhanloai  = ['', 'Hồ sơ cấp mới','Hồ sơ cấp lại', 'Hồ sơ cấp đổi','Cấp đổi theo kỳ hạn', 'Cấp mới', 'Cấp đổi do chuyển cơ quan', 'Cấp lại'] //Hồ sơ cấp theo kỳ hạn mới
const bankhai = ['', 'cấp mới','cấp lại', 'cấp đổi', 'Cấp đổi theo kỳ hạn mới', 'Cấp mới', 'Cấp đổi do chuyển cơ quan', 'Cấp lại']//,'cấp theo kỳ hạn mới'

registerLocale("el", el);

function parseTime(timeOld, timeNew) {
  var date = Date.now()
  if(timeOld){
    date = new Date(parseInt(timeOld, 10))
  }
  else if(timeNew){
    date = new Date(timeNew*1000)
  }
  return date
}

export const renderTableHead = (inthis, vm) =>{
  let role  = global.rxu.get(inthis.props.auth, ['user', 'user', 'role'], '')
  return (
  <tr className="rx-title__table">
    <th className='rxwidth--100 text-center'>STT</th>
    <th className={inthis.helpSortClass('HoTenKhaiSinh', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'HoTenKhaiSinh')} >Họ và tên</th>
    {['viewer'].indexOf(role) === -1 &&<th className={inthis.helpSortClass('code', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'code')} >Mã số hồ sơ</th>}
    <th className={inthis.helpSortClass('TenCoQuanBaoChi', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'TenCoQuanBaoChi')} >Thuộc cơ quan</th>
    <th className={inthis.helpSortClass('IsStatus', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'IsStatus')}>Tình trạng</th>
    <th className={inthis.helpSortClass('MaSoThe', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'MaSoThe')} >Mã số thẻ nhà báo</th>
    {['viewer'].indexOf(role) === -1 &&<th className='rxwidth--100 table--name_small' onClick={(e) => inthis.onClickSort(e, 'TenChucVu')}>Hình thẻ</th>}
    <th className={inthis.helpSortClass('ButDanh', ['rxwidth--100 table--name_small'])} onClick={(e) => inthis.onClickSort(e, 'ButDanh')} >Bút danh</th>
    {['viewer'].indexOf(role) === -1 &&<th className='rxwidth--100 table--name_small' onClick={(e) => inthis.onClickSort(e, 'TenChucVu')}>Chức vụ</th>}
    {['viewer'].indexOf(role) === -1 &&<th className='rxwidth--100 table--name_small'>Số thẻ cũ</th>}
    {['viewer'].indexOf(role) === -1 &&<th className={inthis.helpSortClass('Type', ['rxwidth--100 table--name_office'])} onClick={(e) => inthis.onClickSort(e, 'Type')}>Phân loại</th>}
    {['viewer'].indexOf(role) === -1 &&<th className='rxwidth--100 table--name_small' onClick={(e) => inthis.onClickSort(e, 'is_active')}>Trạng thái</th>}
    {['viewer'].indexOf(role) === -1 &&<th className={inthis.helpSortClass('created_at', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>}
    {['viewer'].indexOf(role) === -1 &&<th className={inthis.helpSortClass('updated_at', ['rxwidth--100'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Ngày cập nhật</th>}
    {['viewer'].indexOf(role) === -1 && <th className='rxwidth--100 table--name_small'></th>}
  </tr>
)}


export const renderTableBody = (inthis, vm) => {
  let role  = global.rxu.get(inthis.props.auth, ['user', 'user', 'role'], '')
  return (
  global.rxu.array(inthis.state.data).map((perdata,index) => (<tr key={perdata._id} >
    <td className='text-center'>{index + 1}</td>
    <td onClick={(e) =>inthis.onClickViewData(e, perdata)} className='rx-cursor'>
        <span><img className='betable__img card__image' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/'+ (perdata.path !== undefined ? perdata.path : '') + (perdata.img_landscape || 'ico_app_default.jpg')} /></span>
        <span className="card__name textuppercase">{perdata.HoTenKhaiSinh}</span>
    </td>
    {['viewer'].indexOf(role) === -1 &&<td onClick={(e) =>inthis.onClickViewData(e, perdata)}>{perdata.code}</td>}
    <td onClick={(e) =>inthis.onClickViewData(e, perdata)}>{perdata.TenCoQuanBaoChi}</td>

    {['viewer', 'member'].indexOf(role) === -1 ?
      <td><span className={'betable__status betable__status_' +perdata.IsStatus}>{perdata.IsStatus === 0 ? (perdata.IsUpdateProfile === 0 ?  statusTinhtrang[perdata.IsStatus] : 'Hoàn thành') : statusTinhtrang[perdata.IsStatus]}</span></td>
    : <td><span className={'betable__status betable__status_' +perdata.IsStatus}>{perdata.IsUpdateProfile === 0 ?  statusTinhtrang[perdata.IsStatus] : 'Hoàn thành'}</span></td>
    }
    <td onClick={(e) =>inthis.onClickViewData(e, perdata)}>{perdata.MaSoThe}</td>
    {['viewer'].indexOf(role) === -1 &&<td><img className='betable__img card__image' alt={perdata.name} src={global.rxu.config.base_api + '/upload/image/' + (perdata.img_card || 'ico_app_default.jpg')} /></td>}
    <td>{perdata.ButDanh}</td>
    {['viewer'].indexOf(role) === -1 &&<td>{perdata.TenChucVu}</td>}
    {['viewer'].indexOf(role) === -1 &&<td>{perdata.MaTheCu}</td>}
    {['viewer'].indexOf(role) === -1 &&<td>{statusPhanloai[perdata.StatusPhanLoai]}</td>}
    {['viewer'].indexOf(role) === -1 &&<td>{perdata.is_active == 1 ? 'ON' : 'OFF'}</td>}
    {['viewer'].indexOf(role) === -1 &&<td>{rxu.getDate(perdata['created_at'])}</td>}
    {['viewer'].indexOf(role) === -1 &&<td>{rxu.getDate(perdata['updated_at'])}</td>}
    {['viewer'].indexOf(role) === -1 && inthis.renderTableBodyBtns(inthis, perdata)}
  </tr>))
)}
export const renderComponent = (vm) =>
  <AdminBlock name={vm.state.name} desc=''>
    {/* <renderFilter /> */}
    <div className='rxcol-100'>
    {vm.state.type == "?type=23" || vm.state.type == "?type=63" ?
    <div className="rx_develop">Tính năng đang hoàn thiện! Vui lòng quay lại sau! Xin cảm ơn!</div>
    :
    <RxCrudCard title='Chi tiết' renderTableHead={vm.renderTableHead} flagUpdate={vm.state.flagUpdateCat} parentUpdate={vm.runUpdateCat} renderTableBody={vm.renderTableBody} name='Card' api={'api_card'} idEdit={vm.state.idEdit} type={vm.state.type}
        form={[
            { type: 'header', func: (inthis) => (
                <div>
                    <div className='class_avatar'>
                        <div style={{width:'100%'}}>
                            <RxUpload path={inthis.state.editingData.path} callback={(e) => inthis.callbackUpload(e, 'img_landscape')} images={inthis.state.editingData.img_landscape} type='avatar' />
                            <div className='text-center form-avatar-sub'>Ảnh 3x4 <br /> (Nền xanh)</div>
                            {inthis.state.errMsg && inthis.state.errMsg.img_landscape && <div className='authform_msgerror'>{inthis.state.errMsg.img_landscape || ''}</div>}
                            {/*<div className='text-center avatar_name'>{rxu.rxChangeAlias(inthis.state.editingData.HoTenKhaiSinh)}</div>*/}
                        </div>
                    </div>
                    <div className='form-header-title'>
                        <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                        <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                        <div className='text-center title-main'>BẢN KHAI ĐĂNG KÝ CẤP THẺ NHÀ BÁO</div>
                        <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1) </div>
                        <div className='form-header-bottom'>
                            <span>Tên cơ quan đề nghị cấp thẻ nhà báo: </span>
                            <div style={{width:'100%'}}>
                                <RxSelectbox
                                    name='cate'
                                    options={inthis.state.cate}
                                    results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                                    onChange={(result) => {
                                        inthis.onBlurDataValue(result, 'TenCoQuanBaoChi')
                                        inthis.onValidateField(result, 'TenCoQuanBaoChi')
                                    }}
                                    api='api_newspaper_office'
                                    paging={inthis.state.pagingCat}
                                    optionDefault='--Chọn cơ quan báo chí--'
                                />
                                {/*inthis.state.errMsg && inthis.state.errMsg.TenCoQuanBaoChi && <div className='authform_msgerror'>{inthis.state.errMsg.TenCoQuanBaoChi || ''}</div>*/}
                            </div>
                        </div>
                        {/*}
                        <div className='form-header-bottom'>
                          <span>Loại cơ quan: </span>
                          <RxSelectbox
                              name='LoaiCoquan'
                              options={global.LoaiCoquan}
                              results={inthis.state.editingData.LoaiCoquan || ''}
                              onChange={(result) => { inthis.onBlurDataValue(result, 'LoaiCoquan') }}
                              optionDefault='--Chọn--'
                          />
                        </div>*/}
                    </div>
                    <div className='form-header-right barcode'>
                        <div>
                            {inthis.state.editingData.codeGen && <QRCode id={inthis.state.editingData.code || '1'} value={inthis.state.editingData.code || '1'} size={160} level={"H"} includeMargin={true} />}
                            <div className='text-center form-avatar-sub'>{inthis.state.editingData.code || ''}</div>
                            {/*}<input tabIndex='1' type='text' value={inthis.state.editingData.MaSoThe || ''} onChange={(e) => inthis.onBlurData(e, 'MaSoThe')} className='fullwidth-input' style={{width:'100%'}} />*/}
                            {/* <div className='form-header-code'>Số thẻ nhà báo cũ: {inthis.state.editingData.MaTheCu}</div> */}
                        </div>
                    </div>
                </div>
            ) },
            { type: 'devide' },
            { type: 'form', name: 'Thông tin cá nhân', func: (inthis) => (<div>
                <div className='rx-form__item fullname'>
                    <div>
                        <span>Họ và tên khai sinh (2): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenKhaiSinh')} onBlur={(e) => inthis.onValidateField(e,'HoTenKhaiSinh')} className='fullwidth-input textuppercase' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.HoTenKhaiSinh && <div className='authform_msgerror'>{inthis.state.errMsg.HoTenKhaiSinh || ''}</div>*/}
                        </div>
                    </div>
                    <div className='gender--item'>
                        <span className='gender--title'>Nam/Nữ: </span>
                        <RxSelectbox
                            name='GioiTinh'
                            options={global.GioiTinh}
                            results={inthis.state.editingData.GioiTinh}
                            onChange={(result) => {
                                inthis.onBlurDataValue(result, 'GioiTinh')
                                // inthis.onValidateField(result,'GioiTinh')
                         }}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày, tháng, năm sinh: </span>
                        <DatePicker
                            locale="el"
                            dateFormat="dd/MM/yyyy"
                            selected={ parseTime(null, inthis.state.editingData.NgaySinhConvert, 'abc') }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            onChange={date => {
                            inthis.state.editingData.StrNgaySinh = ''
                            inthis.state.editingData.NgaySinh = ''
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'NgaySinhConvert')
                            // inthis.onValidateField(datetmp,'NgaySinhConvert')
                        }}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                      <span>Dân tộc: </span>
                      <div style={{width:'100%'}}>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.DanToc || ''} onChange={(e) => inthis.onBlurData(e, 'DanToc')} onBlur={(e) => inthis.onValidateField(e,'DanToc')}  className='fullwidth-input' /><br/>
                        {/*inthis.state.errMsg && inthis.state.errMsg.DanToc && <div className='authform_msgerror'><p>{inthis.state.errMsg.DanToc || ''}</p></div>*/}
                      </div>
                  </div>
                </div>

                <div className='rx-form__item'>
                    <div>
                        <span>Quê quán: </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.QueQuan || ''} onChange={(e) => inthis.onBlurData(e, 'QueQuan')} onBlur={(e) => inthis.onValidateField(e,'QueQuan')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.QueQuan && <div className='authform_msgerror'>{inthis.state.errMsg.QueQuan || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Nơi ở hiện nay: </span>
                        <div style={{width:'100%'}}>
                            <div className='rxform--province'>
                                <input tabIndex='1' type='text' value={inthis.state.editingData.NoiO || ''} onChange={(e) => inthis.onBlurData(e, 'NoiO')} onBlur={(e) => inthis.onValidateField(e,'NoiO')} className='fullwidth-input' style={{marginRight: '15px'}} />
                                <div className='province--item'>
                                    <RxSelectbox
                                        name='province'
                                        options={inthis.state.province}
                                        results={inthis.state.editingData.province || ''}
                                        onChange={(result) => { inthis.onBlurDataValue(result, 'province') }}
                                        onBlur={(result) => inthis.onValidateField(result,'province')}
                                        optionDefault='--Tỉnh/Thành phố--'
                                    />
                                </div>
                            </div>
                            {/*inthis.state.errMsg && inthis.state.errMsg.NoiO && <div className='authform_msgerror'>{inthis.state.errMsg.NoiO || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div >
                        <span>Chức vụ (3): </span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.position}
                                results={inthis.state.editingData.TenChucVu || ''}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'TenChucVu')
                                    inthis.onValidateField(result,'TenChucVu')
                                }}
                                api='api_position'
                                optionDefault='--Nhóm chức vụ--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'TenChucVu')}
                            />
                            {/*inthis.state.errMsg && inthis.state.errMsg && inthis.state.errMsg.TenChucVu && <div className='authform_msgerror'>{inthis.state.errMsg.TenChucVu || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bút danh thường dùng: </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ButDanh || ''} onChange={(e) => inthis.onBlurData(e, 'ButDanh')}   onBlur={(e) => inthis.onValidateField(e,'ButDanh')} className='fullwidth-input' />
                            {/*inthis.state.errMsg.ButDanh && <div className='authform_msgerror'>{inthis.state.errMsg.ButDanh || ''}</div>*/}
                        </div>
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'TRÌNH ĐỘ', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Giáo dục phổ thông (4): <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.TrinhDoVanHoa || ''} onChange={(e) => inthis.onBlurData(e, 'TrinhDoVanHoa')} onBlur={(e) => inthis.onValidateField(e,'TrinhDoVanHoa')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.TrinhDoVanHoa && <div className='authform_msgerror'>{inthis.state.errMsg.TrinhDoVanHoa || ''}</div>*/}
                        </div>
                    </div>
                </div>
                {/*}<div className='rx-form__title--sub'>Chuyên môn (5): <span/></div>*/}
                <div className='rx-form__item'>
                    <div>
                        <span>Chuyên môn (5): <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.TrinhDoChuyenMon}
                                results={inthis.state.editingData.TrinhDoChuyenMon || []}
                                id={inthis.state.editingData.TrinhDoChuyenMonID || []}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'TrinhDoChuyenMon', 'TrinhDoChuyenMonID')
                                }}
                                api='api_config' type={4}
                                optionDefault='--Chọn chuyên môn--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'TrinhDoChuyenMon')}
                            />
                            {/*<input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} onChange={(e) => inthis.onBlurData(e, 'TruongHoc')} onBlur={(e) => inthis.onValidateField(e,'TruongHoc')} className='fullwidth-input' />*/}
                            {/*inthis.state.errMsg && inthis.state.errMsg.TruongHoc && <div className='authform_msgerror'>{inthis.state.errMsg.TruongHoc || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Trường học: <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.TruongHoc}
                                results={inthis.state.editingData.TruongHoc || []}
                                id={inthis.state.editingData.TruongHocID || []}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'TruongHoc', 'TruongHocID')
                                }}
                                api='api_config' type={2}
                                optionDefault='--Chọn trường học--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'TruongHoc')}
                            />
                            {/*<input tabIndex='1' type='text' value={inthis.state.editingData.TruongHoc || ''} onChange={(e) => inthis.onBlurData(e, 'TruongHoc')} onBlur={(e) => inthis.onValidateField(e,'TruongHoc')} className='fullwidth-input' />*/}
                            {/*inthis.state.errMsg && inthis.state.errMsg.TruongHoc && <div className='authform_msgerror'>{inthis.state.errMsg.TruongHoc || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngành học: <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.NganhHoc}
                                results={inthis.state.editingData.NganhHoc || []}
                                id={inthis.state.editingData.NganhHocID || []}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'NganhHoc', 'NganhHocID')
                                }}
                                api='api_config' type={3}
                                optionDefault='--Chọn ngành học--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'NganhHoc')}
                            />
                            {/*<input tabIndex='1' type='text' value={inthis.state.editingData.NganhHoc || ''} onChange={(e) => inthis.onBlurData(e, 'NganhHoc')} onBlur={(e) => inthis.onValidateField(e,'NganhHoc')} className='fullwidth-input' />*/}
                            {/*inthis.state.errMsg && inthis.state.errMsg.NganhHoc && <div className='authform_msgerror'>{inthis.state.errMsg.NganhHoc || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Hình thức đào tạo (6): <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.HinhThucDaoTao}
                                results={inthis.state.editingData.HinhThucDaoTao || []}
                                id={inthis.state.editingData.HinhThucDaoTaoID || []}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'HinhThucDaoTao', 'HinhThucDaoTaoID')
                                }}
                                api='api_config' type={1}
                                optionDefault='--Chọn hình thức đào tạo--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'HinhThucDaoTao')}
                            />
                            {/*<input tabIndex='1' type='text' value={inthis.state.editingData.HinhThucDaoTao || ''} onChange={(e) => inthis.onBlurData(e, 'HinhThucDaoTao')} onBlur={(e) => inthis.onValidateField(e,'HinhThucDaoTao')} className='fullwidth-input' />*/}
                            {/*inthis.state.errMsg && inthis.state.errMsg.HinhThucDaoTao && <div className='authform_msgerror'>{inthis.state.errMsg.HinhThucDaoTao || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Năm tốt nghiệp: <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.NamTotNghiep || ''} onChange={(e) => inthis.onBlurData(e, 'NamTotNghiep')} onBlur={(e) => inthis.onValidateField(e,'NamTotNghiep')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.NamTotNghiep && <div className='authform_msgerror'>{inthis.state.errMsg.NamTotNghiep || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Lý luận chính trị (7): <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='DangVien'
                                options={global.ChinhTri}
                                results={inthis.state.editingData.ChinhTri}
                                onChange={(result) => { inthis.onBlurDataValue(result, 'ChinhTri') }}
                                optionDefault='--Chọn loại--'
                                ten={true}
                            />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ChinhTri && <div className='authform_msgerror'>{inthis.state.errMsg.ChinhTri || ''}</div>*/}
                        </div>
                        {/*}<input tabIndex='1' type='text' value={inthis.state.editingData.ChinhTri || ''} onChange={(e) => inthis.onBlurData(e, 'ChinhTri')} className='fullwidth-input' />*/}
                    </div>
                </div>
                {/*}
                <div className='rx-form__item'>
                    <div>
                        <span>Bậc lương hiện nay: <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.BacLuong || ''} onChange={(e) => inthis.onBlurData(e, 'BacLuong')} onBlur={(e) => inthis.onValidateField(e,'BacLuong')} className='fullwidth-input' />
                        </div>
                    </div>
                </div>
                */}
                <div className='rx-form__item'>
                    <div>
                        <span>Ngoại ngữ (8): <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.NgoaiNgu || ''} onChange={(e) => inthis.onBlurData(e, 'NgoaiNgu')} onBlur={(e) => inthis.onValidateField(e,'NgoaiNgu')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.NgoaiNgu && <div className='authform_msgerror'>{inthis.state.errMsg.NgoaiNgu || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chứng chỉ đào tạo, bồi dưỡng nghiệp vụ báo chí (nếu có): <i className='icon-require'/></span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ChungChi || ''} onChange={(e) => inthis.onBlurData(e, 'ChungChi')} onBlur={(e) => inthis.onValidateField(e,'ChungChi')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ChungChi && <div className='authform_msgerror'>{inthis.state.errMsg.ChungChi || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Đảng viên: <i className='icon-require'/></span>
                        <RxSelectbox
                            name='DangVien'
                            options={global.DangVien}
                            results={inthis.state.editingData.DangVien}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'DangVien') }}
                            // optionDefault='--Chọn--'
                            ten={true}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức danh báo chí hiện nay (9):</span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.title}
                                results={inthis.state.editingData.ChucDanhBaoChi || ''}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'ChucDanhBaoChi')
                                    inthis.onValidateField(result,'ChucDanhBaoChi')
                                }}
                                api='api_title'
                                optionDefault='--Nhóm chức danh--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'ChucDanhBaoChi')}
                            />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ChucDanhBaoChi && <div className='authform_msgerror'>{inthis.state.errMsg.ChucDanhBaoChi || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        {inthis.state.type === '?type=1' ? <span>Số thẻ nhà báo và kỳ hạn cũ (nếu có): </span>:  <span>Số thẻ nhà báo và kỳ hạn cũ:</span>}
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.MaTheCu || ''} onChange={(e) => inthis.onBlurData(e, 'MaTheCu')} onBlur={(e) => inthis.onValidateField(e,'MaTheCu')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.MaTheCu && <div className='authform_msgerror'>{inthis.state.errMsg.MaTheCu || ''}</div>*/}
                        </div>
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'table', name: 'Quá trình hoạt động báo chí 5 năm gần nhất' },
            { type: 'devide' },
            { type: 'form', name: 'Thông tin khai cho mẫu số 3', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span style={{minWidth: '200px'}}>Số CMND/CCCD/Hộ chiếu</span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.CMND || ''} onChange={(e) => inthis.onBlurData(e, 'CMND')}  onBlur={(e) => inthis.onValidateField(e,'CMND')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.CMND && <div className='authform_msgerror'>{inthis.state.errMsg.CMND || ''}</div>*/}
                        </div>
                    </div>
                    <div>
                        <span style={{minWidth: '100px'}}>Ngày cấp</span>
                        <div style={{width:'100%'}}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={ parseTime(null, inthis.state.editingData.NgayCapCMND) }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                maxDate={new Date()}
                                dropdownMode="select"
                                onChange={date => {
                                let datetmp = date/1000
                                inthis.onBlurDataValue(datetmp, 'NgayCapCMND')
                               // inthis.onValidateField(datetmp,'NgayCapCMND')
                            }}
                            />
                            {/* {inthis.state.errMsg && inthis.state.errMsg.NgayCapCMND && <div className='authform_msgerror'>{inthis.state.errMsg.NgayCapCMND || ''}</div>} */}
                        </div>
                    </div>
                    <div>
                        <span style={{minWidth: '150px'}}>Ảnh CMND/CCCD</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUpload(e, 'cmnd_truoc')} images={inthis.state.editingData.cmnd_truoc} title='Ảnh mặt trước' single={1} />
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUpload(e, 'cmnd_sau')}  images={inthis.state.editingData.cmnd_sau} title='Ảnh mặt sau' single={1}/>
                            {/*inthis.state.errMsg && inthis.state.errMsg.cmnd && <div className='authform_msgerror'>{inthis.state.errMsg.cmnd || ''}</div>*/}
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span> Ngày, tháng, năm ký HĐLĐ có đóng BHXH</span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ThongTinHDLD || ''} onChange={(e) => inthis.onBlurData(e, 'ThongTinHDLD')}  onBlur={(e) => inthis.onValidateField(e,'ThongTinHDLD')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ThongTinHDLD && <div className='authform_msgerror'>{inthis.state.errMsg.ThongTinHDLD || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>

                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'Thành phần hồ sơ khác', func: (inthis) => (<div>
                {/*[4,5,6].indexOf(inthis.state.editingData['StatusPhanLoai']) === -1 && <div className='rx-form__item'>
                    <div>
                        <span>Bản khai đăng ký {bankhai[inthis.state.editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'SoYeuLyLich')} images={inthis.state.editingData.SoYeuLyLich} title='Sơ yếu lý lịch' />
                        {inthis.state.errMsg && inthis.state.errMsg.SoYeuLyLich && <div className='authform_msgerror'>{inthis.state.errMsg.SoYeuLyLich || ''}</div>}
                    </div>
                </div>*/}

                <div className='rx-form__item'>
                    <div>
                        <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'BanSaoBangDHCD')} images={inthis.state.editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.BanSaoBangDHCD && <div className='authform_msgerror'>{inthis.state.errMsg.BanSaoBangDHCD || ''}</div>*/}
                    </div>
                </div>
                {!inthis.state.editingData['checkHadCard'] && <div className='rx-form__item'>
                    <div>
                        <span>Scan bản chính hoặc bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'BanSaoHDLD')} images={inthis.state.editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.BanSaoHDLD && <div className='authform_msgerror'>{inthis.state.errMsg.BanSaoHDLD || ''}</div>*/}
                    </div>
                </div>
                }
                {inthis.state.editingData['StatusPhanLoai'] && inthis.state.editingData['StatusPhanLoai'] === 6 && inthis.state.editingData['checkHadCard'] && <div className='rx-form__item'>
                    <div>
                        <span>Văn bản xác nhận của cơ quan, đơn vị trước khi chuyển công tác</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'XacNhanCQCu')} images={inthis.state.editingData.XacNhanCQCu} title='Văn bản xác nhận của cơ quan, đơn vị trước khi chuyển công tác' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.XacNhanCQCu && <div className='authform_msgerror'>{inthis.state.errMsg.XacNhanCQCu || ''}</div>*/}
                    </div>
                </div>}

                {/*<div className='rx-form__item'>
                    <div>
                        <span>Số điện thoại:</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.phone_number || ''} onChange={(e) => inthis.onBlurData(e, 'phone_number')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Địa chỉ Email:</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.email || ''} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Phương thức tiếp nhận</span>
                        <RxSelectbox
                            name='reception'
                            options={global.reception}
                            results={inthis.state.editingData.reception || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'reception') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                    <div>
                        <span>Ngày tiếp nhận</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.reception_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'reception_date')
                          }}
                          />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày hẹn trả</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.appointment_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'appointment_date')
                          }}
                        />
                    </div>
                    <div>
                        <span>Ngày trả thực tế</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.actual_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'actual_date')
                          }}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Phương thức trả kết quả</span>
                        <RxSelectbox
                            name='reception'
                            options={global.reception}
                            results={inthis.state.editingData.method_result || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'method_result') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                </div>/*}
                {/*<div className='rx-form__item'>
                    <div>
                        <span>Tình trạng thanh toán</span>
                        <RxSelectbox
                            name='payment'
                            options={global.payment}
                            results={inthis.state.editingData.payment || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'payment') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                </div>*/}

                {!inthis.state.editingData['checkHadCard'] && <div className='rx-form__item'>
                    <div>
                        <span>Thành phần hồ sơ khác chứng minh thuộc đối tượng cấp thẻ (nếu có)</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'profileAttachments')} images={inthis.state.editingData.profileAttachments} title='' />
                    </div>
                </div>}
                {inthis.state.editingData['StatusPhanLoai'] && inthis.state.editingData['StatusPhanLoai'] === 6 && <div className='rx-form__item'>
                    <div>
                        <span>Danh sách tác phẩm báo chí đã đăng, phát</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DanhSachTacPhamDaDangPhat')} images={inthis.state.editingData.DanhSachTacPhamDaDangPhat} title='Danh sách tác phẩm báo chí đã đăng, phát' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.BanSaoBangDHCD && <div className='authform_msgerror'>{inthis.state.errMsg.BanSaoBangDHCD || ''}</div>*/}
                    </div>
                </div>}

                { inthis.state.editingData['StatusPhanLoai'] == 2 &&
                <>
                    <div className='rx-form__item'>
                        <div>
                            <span>Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCapLaiThe')} images={inthis.state.editingData?.DonXacNhanCapLaiThe || ""} title='Ảnh đơn xác nhận của cơ quan' />
                        </div>
                    </div>
                    <div className='rx-form__item'>
                        <div>
                            <span>Ảnh scan thẻ hỏng</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'AnhScanTheHong')} images={inthis.state.editingData?.AnhScanTheHong || ""} title='Ảnh scan thẻ hỏng' />
                        </div>
                    </div>
                </>
                }

                { inthis.state.editingData['StatusPhanLoai'] == 6 && inthis.state.editingData['checkHadCard'] &&
                <>
                    {/*}<div className='rx-form__item'>
                        <div>
                            <span>Văn bản xác nhận của cơ quan cũ</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCQCu')} images={inthis.state.editingData?.DonXacNhanCQCu || ""} title='Ảnh đơn xác nhận của cơ quan cũ' />
                        </div>
                    </div>*/}
                    <div className='rx-form__item'>
                        <div>
                            <span>Văn bản đề nghị của cơ quan mới</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCQMoi')} images={inthis.state.editingData?.DonXacNhanCQMoi || ""} title='Ảnh đơn xác nhận của cơ quan mới' />
                        </div>
                    </div>
                    <div className='rx-form__item'>
                        <div>
                            <span>Ảnh scan của thẻ nhà báo ở cơ quan cũ</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'AnhScanTheCu')} images={inthis.state.editingData?.AnhScanTheCu || ""}  title='Ảnh scan thẻ cơ quan cũ' />
                        </div>
                    </div>
                    <div className='rx-form__item'>
                        <div>
                            <span>Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động có chứng thực</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'VanBanTiepNhanCTacMoi')} images={inthis.state.editingData?.VanBanTiepNhanCTacMoi || ""} title='Ảnh văn bản tiếp nhận công tác mới' />
                        </div>
                    </div>
                </>
                }

            </div>)},
            {type: 'form', name: 'Lưu ý', func: (inthis) => (
                <div>
                    <p>Ghi chú:</p>
                    <p>(1) Ghi theo kỳ hạn thẻ nhà báo đề nghị cấp (ví dụ: 2016 - 2020)</p>
                    <p>(2) Viết chữ in hoa đủ dấu. </p>
                    <p>(3) Ghi rõ chức vụ bổ nhiệm (ví dụ: Tổng biên tập, Phó Tổng biên tập, Trưởng ban...). </p>
                    <p>(4) Ghi rõ đã tốt nghiệp lớp mấy, thuộc hệ nào.</p>
                    <p>(5) Ghi rõ trình độ chuyên môn cao nhất (ví dụ: Tiến sĩ, thạc sĩ, đại học, cao đẳng...).</p>
                    <p>(6) Chính quy, tại chức, đào tạo từ xa.... </p>
                    <p>(7) Cao cấp, trung cấp, sơ cấp và tương đương</p>
                    <p>(8) Tên ngoại ngữ và trình độ (ví dụ: A, B, C, D...)</p>
                    <p>(9) Ghi rõ chức danh nghề nghiệp (ví dụ: Phóng viên, biên tập viên...). </p>
                </div>
            )}
        ]}
        form1={[
            { type: 'header', func: (inthis) => (
                <div>
                    <div className='class_avatar'>
                        <RxUpload path={inthis.state.editingData.path} callback={(e) => inthis.callbackUpload(e, 'img_landscape')} images={inthis.state.editingData.img_landscape} type='avatar' />
                        <div className='text-center form-avatar-sub'>Ảnh 3x4 <br /> (Nền xanh)</div>
                    </div>
                    <div className='form-header-title'>
                        <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                        <div className='text-center title-alias'>Độc lập - Tự do - Hạnh phúc</div>
                        <div className='text-center title-main'>DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT</div>
                        <div className='text-center title-alias'>Kỳ hạn 2021 - 2025 (1)</div>
                    </div>
                </div>
            ) },
            { type: 'devide' },
            { type: 'table_form2', name: 'DANH SÁCH TÁC PHẨM BÁO CHÍ ĐÃ ĐĂNG, PHÁT' },
        ]}
        form2={[
            { type: 'header', func: (inthis) => (
                <div className='form2-header-title'>
                    <div className='form2-header-top'>
                        <div className='class_avatar'>
                            <RxUpload path={inthis.state.editingData.path} callback={(e) => inthis.callbackUpload(e, 'img_landscape')} images={inthis.state.editingData.img_landscape} type='avatar' />
                            <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                            {/*<div className='text-center avatar_name'>{rxu.rxChangeAlias(inthis.state.editingData.HoTenKhaiSinh)}</div>*/}
                        </div>
                        {/* <div className='header__right'>
                            <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                            <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
                            <div className='text-center title-alias'>{rxu.getFullDate(inthis.state.editingData.created_at || '')}</div>
                        </div> */}
                    </div>
                    <div className='text-center title-main'>CẤP LẠI THẺ NHÀ BÁO</div>
                    {/*}<div className='title-sub'>Thực hiện quy định tại Luật Báo chí 2016 về thẻ nhà báo, đề nghị Bộ Thông tin và Truyền thông cấp lại thẻ nhà báo: </div>*/}
                </div>
            ) },
            { type: 'devide' },
            { type: 'header', func: (inthis) => (
                <div className='rx-form__item'>
                    <div style={{padding: '10px 0'}}>
                        <span style={{minWidth: '150px'}}>Họ và tên:</span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenKhaiSinh')} onBlur={(e) => inthis.onValidateField(e,'HoTenKhaiSinh')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.HoTenKhaiSinh && <div className='authform_msgerror'>{inthis.state.errMsg.HoTenKhaiSinh || ''}</div>*/}
                        </div>
                    </div>
                    <div className='gender--item' style={{padding: '10px 0 10px 20px'}}>
                        <span className='gender--title'>Nam/Nữ:</span>
                        <RxSelectbox
                            name='GioiTinh'
                            options={global.GioiTinh}
                            results={inthis.state.editingData.GioiTinh}
                            onChange={(result) => {
                                inthis.onBlurDataValue(result, 'GioiTinh')
                                // inthis.onValidateField(result,'GioiTinh')
                         }}
                        />
                    </div>

                </div>

            ) },

            // { type: 'fullinput', name: 'Họ và tên', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenKhaiSinh')} className='fullwidth-input' />) },
            // { type: 'fullinput', name: 'Nam/Nữ', func: (inthis) => (<RxSelectbox
            //         name='GioiTinh'
            //         options={global.GioiTinh}
            //         results={inthis.state.editingData.GioiTinh || ''}
            //         onChange={(result) => { inthis.onBlurDataValue(result, 'GioiTinh') }}
            //         optionDefault='--Chọn--'
            //     />
            // ) },

            { type: 'fullinput', name: 'Sinh năm', func: (inthis) => (
                <DatePicker
                    locale="el"
                    dateFormat="dd/MM/yyyy"
                    selected={ parseTime(null, inthis.state.editingData.NgaySinhConvert, 'abc') }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    maxDate={new Date()}
                    dropdownMode="select"
                    onChange={date => {
                    inthis.state.editingData.StrNgaySinh = ''
                    inthis.state.editingData.NgaySinh = ''
                    let datetmp = date/1000
                    inthis.onBlurDataValue(datetmp, 'NgaySinhConvert')
                }}
                />
            ) },
            { type: 'fullinput', name: 'Số CMND/CCCD/Hộ chiếu',func: (inthis) =>(
                <div className='rx-form__item'>
                    <input tabIndex='1' type='text' value={inthis.state.editingData.CMND || ''} onChange={(e) => inthis.onBlurData(e, 'CMND')}  onBlur={(e) => inthis.onValidateField(e,'CMND')} className='fullwidth-input' />
                    {/*inthis.state.errMsg && inthis.state.errMsg.CMND && <div className='authform_msgerror'>{inthis.state.errMsg.CMND || ''}</div>*/}
                </div>
            )},
            { type: 'fullinput', name: 'Nơi công tác', func: (inthis) => (<RxSelectbox
                    name='cate'
                    options={inthis.state.cate}
                    results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                    onChange={(result) => { inthis.onBlurDataValue(result, 'TenCoQuanBaoChi') }}
                    api='api_newspaper_office'
                    paging={inthis.state.pagingCat}
                    optionDefault='--Chọn nơi công tác--'
                />
            ) },
            { type: 'fullinput', name: 'Thẻ nhà báo số', func: (inthis) => (
            <div className='rx-form__item'>
                    <input tabIndex='3' type='text' value={inthis.state.editingData.MaTheCu || ''} onChange={(e) => inthis.onBlurData(e, 'MaTheCu')} onBlur={(e) => inthis.onValidateField(e,'MaTheCu')} className='fullwidth-input' />
                    {/*inthis.state.errMsg && inthis.state.errMsg.MaTheCu && <div className='authform_msgerror'>{inthis.state.errMsg.MaTheCu || ''}</div>*/}
            </div>
            ) },
            { type: 'fullinput', name: 'Lý do đề nghị cấp lại (mất thẻ, hỏng thẻ)', func: (inthis) => (
                <RxSelectbox name='NgNhanCapDoi' options={global.NgNhanCapDoi} onChange={(result) => { inthis.onBlurStatusReviewClone(result, "doiThe") }} optionDefault='--Chọn lý do cấp lại--' />
            ) },
            { type: 'header', func: (inthis) => (
                <div className='form2-header-title'>
                    {/*<div className='title-sub'>Trân trọng./.</div>*/}
                </div>
            ) },
            { type: 'form', name: 'Thành phần hồ sơ khác', func: (inthis) => (<div>
                {/* <div className='rx-form__item'>
                    <div>
                        <span>Bản khai đăng ký {bankhai[inthis.state.editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'SoYeuLyLich')} images={inthis.state.editingData.SoYeuLyLich} title='Sơ yếu lý lịch' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'BanSaoBangDHCD')}  images={inthis.state.editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Scan bản chính hoặc bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'BanSaoHDLD')} images={inthis.state.editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' />
                    </div>
                </div> */}
                {/*<div className='rx-form__item'>
                    <div>
                        <span>Số điện thoại:</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.phone_number || ''} onChange={(e) => inthis.onBlurData(e, 'phone_number')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Địa chỉ Email:</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.email || ''} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Phương thức tiếp nhận</span>
                        <RxSelectbox
                            name='reception'
                            options={global.reception}
                            results={inthis.state.editingData.reception || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'reception') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                    <div>
                        <span>Ngày tiếp nhận</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.reception_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'reception_date')
                          }}
                          />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày hẹn trả</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.appointment_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'appointment_date')
                          }}
                          />
                    </div>
                    <div>
                        <span>Ngày trả thực tế</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.actual_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'actual_date')
                          }}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Phương thức trả kết quả</span>
                        <RxSelectbox
                            name='reception'
                            options={global.reception}
                            results={inthis.state.editingData.method_result || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'method_result') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                </div>*/}
                {/*<div className='rx-form__item'>
                    <div>
                        <span>Tình trạng thanh toán</span>
                        <RxSelectbox
                            name='payment'
                            options={global.payment}
                            results={inthis.state.editingData.payment || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'payment') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                </div>*/}
                {/* <div className='rx-form__item'>
                    <div>
                        <span>Thành phần hồ sơ khác chứng minh thuộc đối tượng cấp thẻ (nếu có)</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'profileAttachments')} images={inthis.state.editingData.profileAttachments} title='' />
                    </div>
                </div> */}
                {inthis.state.editingData.LyDoCapLai == 1 &&
                <>
                    <div className='rx-form__item'>
                        <div>
                            <span>Đơn có xác nhận của cơ quan báo chí (cơ quan công tác)</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCapLaiThe')} images={inthis.state.editingData.DonXacNhanCapLaiThe} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' />
                        </div>
                    </div>
                    <div className='rx-form__item'>
                        <div>
                            <span>Scan Thẻ bị hỏng</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'AnhScanTheHong')} images={inthis.state.editingData.AnhScanTheHong} title='Ảnh scan thẻ hỏng' />
                        </div>
                    </div>
                </>
                }
                {inthis.state.editingData.LyDoCapLai == 2 &&
                <>
                    <div className='rx-form__item'>
                        <div>
                            <span>Cơ quan công an trình báo mất thẻ</span>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.cqCongAn || ''} onChange={(e) => inthis.onBlurData(e, 'cqCongAn')}  onBlur={(e) => inthis.onValidateField(e,'cqCongAn')} className='fullwidth-input' />
                        </div>
                    </div>

                    <div className='rx-form__item'>
                        <div>
                            <span>Nội dung trình báo</span>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ndTrinhBao || ''} onChange={(e) => inthis.onBlurData(e, 'ndTrinhBao')}  onBlur={(e) => inthis.onValidateField(e,'ndTrinhBao')} className='fullwidth-input' />
                        </div>
                    </div>
                    
                    <div className='rx-form__item'>
                        <div>
                            <span>Đơn có xác nhận của cơ quan báo chí (cơ quan công tác)</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanMatThe')} images={inthis.state.editingData.DonXacNhanMatThe} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' />
                        </div>
                    </div>
                    <div className='rx-form__item'>
                        <div>
                            <span>Đơn có xác nhận của cơ quan công an nơi mất thẻ</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCADoMatThe')} images={inthis.state.editingData.DonXacNhanCADoMatThe} title='Đơn xác nhận xin cấp lại thẻ (do cơ quan chủ quản hoặc công an xác nhận)' />
                        </div>
                    </div>
                </>
                }
            </div>)},
        ]}
        form3={[
            { type: 'header', func: (inthis) => (
                <div className='form2-header-title'>
                    <div className='form2-header-top'>
                        <div className='header__left'>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.cate}
                                results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                                onChange={(result) => { inthis.onBlurDataValue(result, 'TenCoQuanBaoChi') }}
                                api='api_newspaper_office'
                                paging={inthis.state.pagingCat}
                                optionDefault='--Cơ quan chủ quản--'
                            />
                            <div className='class_avatar'>
                               <div style={{width:'100%'}}>
                                    <RxUpload path={inthis.state.editingData.path} callback={(e) => inthis.callbackUpload(e, 'img_landscape')} images={inthis.state.editingData.img_landscape} type='avatar' />
                                    <div className='text-center form-avatar-sub'>Ảnh 3x4</div>
                                    {/*inthis.state.errMsg && inthis.state.errMsg.img_landscape && <div className='authform_msgerror'>{inthis.state.errMsg.img_landscape || ''}</div>*/}                                </div>
                                {/*<div className='text-center avatar_name'>{rxu.rxChangeAlias(inthis.state.editingData.HoTenKhaiSinh)}</div>*/}
                            </div>
                            {/*}
                            <div className='header__hr'/>
                            <div className='text-center'>Số:             /</div>
                            <div className='text-center'>V/v đổi thẻ nhà báo</div>
                            */}
                        </div>
                        <div className='header__right'>
                            <div className='text-center title-top'>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                            <div className='text-center title-top'>Độc lập - Tự do - Hạnh phúc</div>
                            <div className='header__hr'/>
                            <div className='text-center title-alias'>{rxu.getFullDate(inthis.state.editingData.created_at || '')}</div>
                        </div>
                    </div>

                    {/*}<div className='text-center header__title'>Kính gửi: Bộ Thông tin và Truyền thông </div>
                    <div className='title-sub'>Thực hiện quy định tại Luật Báo chí 2016 về thẻ nhà báo, đề nghị Bộ Thông tin và Truyền thông cấp đổi thẻ nhà báo cho trường hợp sau: </div>
                    */}
                      <div className='text-center header__title'>GIẤY XÁC NHẬN</div>

                </div>
            ) },
            { type: 'devide' },
            { type: 'form', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Cơ quan báo chí (ghi tên cơ quan báo chí, cơ quan xác nhận)</span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.cate}
                                results={inthis.state.editingData.TenCoQuanBaoChi || ''}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'TenCoQuanBaoChi')
                                    inthis.onValidateField(result, 'TenCoQuanBaoChi')
                                }}
                                api='api_newspaper_office'
                                paging={inthis.state.pagingCat}
                                optionDefault='--Chọn cơ quan cấp đổi--'
                            />
                            {/*inthis.state.errMsg && inthis.state.errMsg.TenCoQuanBaoChi && <div className='authform_msgerror'>{inthis.state.errMsg.TenCoQuanBaoChi || ''}</div>*/}
                        </div>
                    </div>
                </div>
            </div>) },
            { type: 'form', name: 'Xác nhận để làm thủ tục đổi thẻ nhà báo đối với trường hợp: ', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Họ và tên khai sinh (2): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.HoTenKhaiSinh || ''} onChange={(e) => inthis.onBlurData(e, 'HoTenKhaiSinh')} onBlur={(e) => inthis.onValidateField(e,'HoTenKhaiSinh')} className='fullwidth-input textuppercase' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.HoTenKhaiSinh && <div className='authform_msgerror'>{inthis.state.errMsg.HoTenKhaiSinh || ''}</div>*/}
                        </div>
                    </div>
                    <div className='gender--item'>
                        <span className='gender--title'>Nam/Nữ: </span>
                        <RxSelectbox
                            name='GioiTinh'
                            options={global.GioiTinh}
                            results={inthis.state.editingData.GioiTinh}
                            onChange={(result) => {
                                inthis.onBlurDataValue(result, 'GioiTinh')
                                // inthis.onValidateField(result,'GioiTinh')
                         }}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Năm sinh: </span>
                        <DatePicker
                            // dateFormat="dd/MM/YYY"
                            selected={ parseTime(inthis.state.editingData.NgaySinh, inthis.state.editingData.NgaySinhConvert) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            inthis.state.editingData.StrNgaySinh = ''
                            inthis.state.editingData.NgaySinh = ''
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'NgaySinhConvert')
                          }}
                          />
                    {/* {inthis.state.errMsg.NgaySinhConvert && <div className='authform_msgerror'>{inthis.state.errMsg.NgaySinhConvert || ''}</div>} */}
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Thẻ nhà báo số:</span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.MaTheMoi || ''} onChange={(e) => inthis.onBlurData(e, 'MaTheMoi')} onBlur={(e) => inthis.onValidateField(e,'MaTheMoi')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.MaTheMoi && <div className='authform_msgerror'>{inthis.state.errMsg.MaTheMoi || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Đã nộp thẻ nhà báo (trường hợp mất thẻ, chưa nộp lại thẻ ghi rõ lý do): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.MaTheCu || ''} onChange={(e) => inthis.onBlurData(e, 'MaTheCu')} onBlur={(e) => inthis.onValidateField(e,'MaTheCu')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.MaTheCu && <div className='authform_msgerror'>{inthis.state.errMsg.MaTheCu || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Thời gian công tác tại cơ quan (từ ngày tháng năm đến ngày tháng năm): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ThoiGianCongTac || ''} onChange={(e) => inthis.onBlurData(e, 'ThoiGianCongTac')} onBlur={(e) => inthis.onValidateField(e,'ThoiGianCongTac')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ThoiGianCongTac && <div className='authform_msgerror'>{inthis.state.errMsg.ThoiGianCongTac || ''}</div>*/}
                        </div>
                    </div>
                </div>
            </div>)},
            { type: 'devide' },
            { type: 'form', name: 'Trong thời gian công tác', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Việc hoàn thành nhiệm vụ được giao: </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.QuaTrinhHoatDong || ''} onChange={(e) => inthis.onBlurData(e, 'QuaTrinhHoatDong')} onBlur={(e) => inthis.onValidateField(e,'QuaTrinhHoatDong')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.QuaTrinhHoatDong && <div className='authform_msgerror'>{inthis.state.errMsg.QuaTrinhHoatDong || ''}</div>*/}
                        </div>
                    </div>
                </div>
            </div>)},
            { type: 'form', name: 'Liên quan một hoặc các trường hợp quy định tại điểm a, b, c, d và đ khoản 3 Điều 27 Luật báo chí: ', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức danh báo chí: </span>
                        <div style={{width:'100%'}}>
                            {/*<input tabIndex='1' type='text' value={inthis.state.editingData.ChucDanhBaoChi || ''} onChange={(e) => inthis.onBlurData(e, 'ChucDanhBaoChi')} onBlur={(e) => inthis.onValidateField(e,'ChucDanhBaoChi')} className='fullwidth-input' />*/}
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.title}
                                results={inthis.state.editingData.ChucDanhBaoChi || ''}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'ChucDanhBaoChi')
                                    inthis.onValidateField(result,'ChucDanhBaoChi')
                                }}
                                api='api_title'
                                optionDefault='--Nhóm chức danh--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'ChucDanhBaoChi')}
                            />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ChucDanhBaoChi && <div className='authform_msgerror'>{inthis.state.errMsg.ChucDanhBaoChi || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Chức vụ: </span>
                        <div style={{width:'100%'}}>
                            <RxSelectbox
                                name='cate'
                                options={inthis.state.position}
                                results={inthis.state.editingData.TenChucVu || ''}
                                onChange={(result) => {
                                    inthis.onBlurDataValue(result, 'TenChucVu')
                                    inthis.onValidateField(result,'TenChucVu')
                                }}
                                api='api_position'
                                optionDefault='--Nhóm chức vụ--'
                                createPosition={(e) => inthis.onClickShowCreatePosition('create', 'TenChucVu')}
                            />
                            {/*inthis.state.errMsg && inthis.state.errMsg.TenChucVu && <div className='authform_msgerror'>{inthis.state.errMsg.TenChucVu || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Về đạo đức nghề nghiệp của người làm báo (nếu đã bị xử lý vi phạm ghi rõ thời gian): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ListHoSoQuaTrinhHoatDong || ''} onChange={(e) => inthis.onBlurData(e, 'ListHoSoQuaTrinhHoatDong')} onBlur={(e) => inthis.onValidateField(e,'ListHoSoQuaTrinhHoatDong')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ListHoSoQuaTrinhHoatDong && <div className='authform_msgerror'>{inthis.state.errMsg.ListHoSoQuaTrinhHoatDong || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Về kỷ luật (nếu đã bị xử lý kỷ luật từ khiển trách trở lên theo quy định của pháp luật về công chức, viên chức và pháp luật về lao động, ghi rõ thời gian và hình thức bị kỷ luật): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ListHoSoQuaTrinhHoatDong1 || ''} onChange={(e) => inthis.onBlurData(e, 'ListHoSoQuaTrinhHoatDong1')} onBlur={(e) => inthis.onValidateField(e,'ListHoSoQuaTrinhHoatDong1')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ListHoSoQuaTrinhHoatDong1 && <div className='authform_msgerror'>{inthis.state.errMsg.ListHoSoQuaTrinhHoatDong1 || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Liên quan đến các vụ án (bị kết án năm nào, đã được xóa án tích chưa, hiện có là đối tượng liên quan trong các vụ án chưa có kết luận của cơ quan nhà nước có thẩm quyền không): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ListHoSoQuaTrinhHoatDong2 || ''} onChange={(e) => inthis.onBlurData(e, 'ListHoSoQuaTrinhHoatDong2')} onBlur={(e) => inthis.onValidateField(e,'ListHoSoQuaTrinhHoatDong2')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ListHoSoQuaTrinhHoatDong2 && <div className='authform_msgerror'>{inthis.state.errMsg.ListHoSoQuaTrinhHoatDong2 || ''}</div>*/}
                        </div>
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Đã từng bị thu hồi thẻ nhà báo (ghi rõ thời gian, lý do): </span>
                        <div style={{width:'100%'}}>
                            <input tabIndex='1' type='text' value={inthis.state.editingData.ListHoSoQuaTrinhHoatDong3|| ''} onChange={(e) => inthis.onBlurData(e, 'ListHoSoQuaTrinhHoatDong3')} onBlur={(e) => inthis.onValidateField(e,'ListHoSoQuaTrinhHoatDong3')} className='fullwidth-input' />
                            {/*inthis.state.errMsg && inthis.state.errMsg.ListHoSoQuaTrinhHoatDong3 && <div className='authform_msgerror'>{inthis.state.errMsg.ListHoSoQuaTrinhHoatDong3 || ''}</div>*/}
                        </div>
                    </div>
                </div>
            </div>)},
            { type: 'form', name: 'Thành phần hồ sơ khác', func: (inthis) => (<div>
                <div className='rx-form__item'>
                    <div>
                        <span>Bản khai đăng ký {bankhai[inthis.state.editingData['StatusPhanLoai']]} thẻ nhà báo</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'SoYeuLyLich')}  images={inthis.state.editingData.SoYeuLyLich} title='Sơ yếu lý lịch' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.SoYeuLyLich && <div className='authform_msgerror'>{inthis.state.errMsg.SoYeuLyLich || ''}</div>*/}
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Scan bản chính hoặc bản sao bằng đại học, cao đẳng</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'BanSaoBangDHCD')} images={inthis.state.editingData.BanSaoBangDHCD} title='Ảnh bản sao bằng đại học, cao đẳng' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.BanSaoBangDHCD && <div className='authform_msgerror'>{inthis.state.errMsg.BanSaoBangDHCD || ''}</div>*/}
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Scan bản chính hoặc bản sao quyết định tuyển dụng, HĐLĐ có xác nhận của cơ quan báo chí hoặc tài liệu chứng minh thời gian công tác</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'BanSaoHDLD')}  images={inthis.state.editingData.BanSaoHDLD} title='Ảnh bản sao hợp đồng lao động' />
                        {/*inthis.state.errMsg && inthis.state.errMsg.BanSaoHDLD && <div className='authform_msgerror'>{inthis.state.errMsg.BanSaoHDLD|| ''}</div>*/}
                    </div>
                </div>
                { inthis.state.editingData['DonXacNhanMatThe'] &&
                    <div className='rx-form__item'>
                        <div>
                            <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác), công an nơi mất thẻ</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanMatThe')} images={inthis.state.editingData?.DonXacNhanMatThe || ""} title='Ảnh đơn xác nhận của cơ quan' />
                        </div>
                    </div>
                }
                { inthis.state.editingData['DonXacNhanCapLaiThe'] &&
                    <div className='rx-form__item'>
                        <div>
                            <span>Đơn có xác nhận của cơ quan báo chí(cơ quan công tác)</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCapLaiThe')} images={inthis.state.editingData?.DonXacNhanCapLaiThe || ""} title='Ảnh đơn xác nhận của cơ quan' />
                        </div>
                    </div>
                }

                { inthis.state.editingData['AnhScanTheHong'] &&
                    <div className='rx-form__item'>
                        <div>
                            <span>Ảnh scan thẻ hỏng</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'AnhScanTheHong')} images={inthis.state.editingData?.AnhScanTheHong || ""} title='Ảnh scan thẻ hỏng' />
                        </div>
                    </div>
                }

                { inthis.state.editingData['DonXacNhanCQCu'] &&
                    <div className='rx-form__item'>
                        <div>
                            <span>Văn bản xác nhận của cơ quan cũ</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCQCu')} images={inthis.state.editingData?.DonXacNhanCQCu || ""} title='Ảnh đơn xác nhận của cơ quan cũ' />
                        </div>
                    </div>
                }

                { inthis.state.editingData['DonXacNhanCQMoi'] &&
                    <div className='rx-form__item'>
                        <div>
                            <span>Văn bản đề nghị của cơ quan mới</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'DonXacNhanCQMoi')} images={inthis.state.editingData?.DonXacNhanCQMoi || ""} title='Ảnh đơn xác nhận của cơ quan mới' />
                        </div>
                    </div>
                }

                {/* { inthis.state.editingData['AnhScanTheCu'] &&  */}
                    <div className='rx-form__item'>
                        <div>
                            <span>Ảnh scan của thẻ nhà báo ở cơ quan cũ</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'AnhScanTheCu')} images={inthis.state.editingData?.AnhScanTheCu || ""}  title='Ảnh scan thẻ cơ quan cũ' />
                        </div>
                    </div>
                {/* } */}

                { inthis.state.editingData['VanBanTiepNhanCTacMoi'] &&
                    <div className='rx-form__item'>
                        <div>
                            <span>Scan bản chính hoặc bản sao quyết định tiếp nhận công tác hoặc hợp đồng lao động có chứng thực</span>
                            <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'VanBanTiepNhanCTacMoi')} images={inthis.state.editingData?.VanBanTiepNhanCTacMoi || ""} title='Ảnh văn bản tiếp nhận công tác mới' />
                        </div>
                    </div>
                }
                {/*}
                <div className='rx-form__item'>
                    <div>
                        <span>Số điện thoại:</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.phone_number || ''} onChange={(e) => inthis.onBlurData(e, 'phone_number')} className='fullwidth-input' />
                    </div>
                    <div>
                        <span>Địa chỉ Email:</span>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.email || ''} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />
                    </div>
                </div>

                <div className='rx-form__item'>
                    <div>
                        <span>Phương thức tiếp nhận</span>
                        <RxSelectbox
                            name='reception'
                            options={global.reception}
                            results={inthis.state.editingData.reception || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'reception') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                    <div>
                        <span>Ngày tiếp nhận</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.reception_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'reception_date')
                          }}
                          />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Ngày hẹn trả</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.appointment_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'appointment_date')
                          }}
                          />
                    </div>
                    <div>
                        <span>Ngày trả thực tế</span>
                        <DatePicker
                            selected={ parseTime(null, inthis.state.editingData.actual_date) }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                            let datetmp = date/1000
                            inthis.onBlurDataValue(datetmp, 'actual_date')
                          }}
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Phương thức trả kết quả</span>
                        <RxSelectbox
                            name='reception'
                            options={global.reception}
                            results={inthis.state.editingData.method_result || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'method_result') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                </div>
                <div className='rx-form__item'>
                    <div>
                        <span>Tình trạng thanh toán</span>
                        <RxSelectbox
                            name='payment'
                            options={global.payment}
                            results={inthis.state.editingData.payment || ''}
                            onChange={(result) => { inthis.onBlurDataValue(result, 'payment') }}
                            optionDefault='--Chọn--'
                        />
                    </div>
                </div>*/}
                <div className='rx-form__item'>
                    <div>
                        <span>Thành phần hồ sơ khác chứng minh thuộc đối tượng cấp thẻ (nếu có)</span>
                        <RxUploadCMND path={inthis.state.editingData.path} callback={(e) => inthis.callbackUploadDetail(e, 'profileAttachments')} images={inthis.state.editingData.profileAttachments} title='' />
                    </div>
                </div>
            </div>)},
        ]}
      />
    }
    </div>
  </AdminBlock>
