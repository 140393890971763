import React, { Component } from 'react'

const RxCrud = global.rootRequire('components/shares/rxCrud').default
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')

class provinceComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { flagUpdateCat: false, curCat: 0, cate: [] }
  }

  componentDidMount () {
  }

  // R E N D E R S
  renderTableHead (inthis) {
    return (<tr>
      <th className={inthis.helpSortClass('Ten', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'Ten')}>Tỉnh/Tp</th>
      <th className={inthis.helpSortClass('code', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'code')}>CODE</th>
      <th className={inthis.helpSortClass('email', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'email')}>email</th>
      <th className={inthis.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Ngày tạo</th>
      <th className={inthis.helpSortClass('updated_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Ngày cập nhật</th>
      <th className='rxwidth--100'></th>
    </tr>)
  }

  renderTableBody (inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td>{perdata.Ten}</td>
      <td>{perdata.code}</td>
      <td>{perdata.email}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td><small>{global.rxu.date(perdata.updated_at)}</small></td>
      {inthis.renderTableBodyBtns(inthis, perdata)}
    </tr>))
  }

  render () {
    return (
      <AdminBlock name='Danh sách Tỉnh/Thành phố' desc=''>
        <RxCrud renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} onClickCatItem={this.onClickCatItem} name={'province'} api={'api_province'} form={[
          { name: 'Tên Tỉnh/Thành phố', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.Ten} onChange={(e) => inthis.onBlurData(e, 'Ten')} className='fullwidth-input' />) },
          { name: 'Địa chỉ email', func: (inthis) => (<input tabIndex='2' type='text' value={inthis.state.editingData.email} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />) },

          // { type: 'devide' },
          // { name: 'Status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) }
        ]} />
      </AdminBlock>
    )
  }
}

export default provinceComponent
