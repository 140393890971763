export function rxsecret () {
  let result = []
  result.web_port = 12000
  // result.base_api = 'http://178.128.122.22:8886'
  //result.base_qrcode = 'https://thenhabao.mic.gov.vn/kiemtra?id='
  result.base_api = 'http://116.103.227.152:8886'
  result.base_qrcode = 'https://thenhabao.mic.gov.vn/kiemtra?id='
  //result.base_api = 'http://116.103.227.152:8886'
  //result.base_qrcode = 'https://cms.the-farm.vn/kiemtra?id='
  result.theme = 'default'
  return result
}
