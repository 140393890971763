import React from 'react'
import './rxTemplates.css'

export const MainTable = (props) => { return <div className='betable__main'><table className='betable__inner'>{props.children}</table></div> }
export const AdminBlock = (props) => {
  return <div className='adblock'>
    <div className='adblock__head'>
      <div className='adblock__title'>{props.name}</div>
      {/* <div className='adblock__desc'>{props.desc}</div> */}
    </div>
    <div className='adblock__body'>
      <div className=''>
        {props.children}
      </div>
      <div className='clearfix-martop' />
    </div>
  </div>
}
// export const
