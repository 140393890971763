/* global rxu*/
import React from 'react'
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderTableHead = (context, vm) =>
  <tr className='rx-title__table'>
    <th className={context.helpSortClass('name', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'name')}>Tên đợt</th>
    <th className={context.helpSortClass('CoQuanBaoChi', ['rx-th-width-220'])}>Cơ quan báo chí</th>
    <th className={context.helpSortClass('status', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'status')}>Trạng thái</th>
    <th className={context.helpSortClass('total', ['rx-th-width-220'])}>Số lượng</th>
    <th className={context.helpSortClass('created_at', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'created_at')}>Ngày tạo</th>
    <th className={context.helpSortClass('updated_at', ['rx-th-width-220'])} onClick={(e) => context.onClickSort(e, 'updated_at')}>Cập nhật</th>
    <th className='rxwidth--100'></th>
  </tr>

export const renderTableBody = (context, vm) => global.rxu.array(context.state.data).map(perdata =>
  <tr key={perdata._id}>
    <td className='rx-cursor' onClick={(e) => context.onClickViewData(e, perdata)}>{perdata.name}</td>
    <td className='rx-cursor' onClick={(e) => context.onClickViewData(e, perdata)}>{perdata.TenCoQuanBaoChi}</td>
    <td>{(perdata.submit === 1 && perdata.status === 0) ? 'Đã trình HS' : global.statusTinhtrang[perdata.status]}</td>
    <td>{perdata.number}</td>
    <td>{rxu.getDate(perdata['created_at'])}</td>
    <td>{rxu.getDate(perdata['updated_at'])}</td>
    {context.renderTableBodyBtns(context, perdata)}
  </tr>)

export const renderComponent = (vm) =>
  <AdminBlock name='QUẢN LÝ DANH SÁCH ĐỀ NGHỊ XÉT DUYỆT HỒ SƠ' desc=''>
    <div className='rxcol-100'>
      <RxCrud renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} name='cate' api={'api_cate'}
        form={[
        	{ type: 'devide' },
          { name: 'Tên: ', func: (context) => (<input tabIndex='1' type='text' value={context.state.editingData.name} onChange={(e) => context.onBlurData(e, 'name')} className='fullwidth-input' />) },
          { type: 'devide' },
          { name: 'Số lượng hồ sơ: ', func: (context) => (<span>{context.state.editingData.number || 0}</span>) },
          { type: 'devide' },
          { name: 'Trạng thái trình', func: (context) => (<RxToggle value={context.state.editingData.submit} onToggle={(newValue) => context.onBlurDataValue(newValue, 'submit')} />) }
          ]}
        view = {[
          { type: 'header', func: (context) => (
            <div className='rx-form__header'>
                <div className='form-header-title'>
                    <div className='text-center title-header'>TỔNG HỢP DANH SÁCH ĐỀ NGHỊ CẤP THẺ NHÀ BÁO</div>
                    <div className='text-center title-slug'>Kỳ hạn: 2021 - 2025 (1)</div>
                    <div className='rx-form__info'>
                        <div className='text-left'><span>Cơ quan đề nghị cấp thẻ nhà báo: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'Cat_Name'], '')}</b></div>
                        <div className='text-left'><span style={{minWidth:'100px'}}>+ Điện thoại: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'DienThoai'], '')}</b></div>
                        <div className='text-left'><span>+ Địa chỉ: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'DiaChi'], '')}</b></div>
                        <div className='text-left'><span style={{minWidth:'100px'}}>+ Fax: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'Fax'], '')}</b></div>
                        <div className='text-left'><span>Cơ quan chủ quản: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'CoQuanChuQuan'], '')}</b></div>
                        <div className='text-left'><span style={{minWidth:'100px'}}>+ Điện thoại: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'DienThoaiCoQuanChuQuan'], '')}</b></div>
                        <div className='text-left'><span>+ Địa chỉ: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'DiaChiCoQuanChuQuan'], '')}</b></div>
                        <div className='text-left'><span style={{minWidth:'100px'}}>+ Fax: </span><b>{global.rxu.get(context.state.editingData, ['CoQuanBaoChiObj', 'FaxCoQuanChuQuan'], '')}</b></div>

                        <div className='text-left'><span>+ Số hồ sơ tạo mới: </span><b>{global.rxu.get(context.state.editingData, ['total'], 0)}</b></div>
                        <div className='text-left'><span style={{minWidth:'100px'}}>+ Số hồ sơ hoàn thành: </span><b>{global.rxu.get(context.state.editingData, ['number'], 0)}</b></div>

                    </div>
                    <div>
                      <div className='rx-form__item'>
                        <div style={{padding: '5px 0', width: '50%'}}>
                          <span style={{textAlign: 'left'}}>Phương thức tiếp nhận <i className='iconcustom-require icon-require'/></span>
                          <RxSelectbox
                              name='reception'
                              options={global.reception}
                              results={context.state.editingData.reception || ''}
                              onChange={(result) => { context.onBlurDataValue(result, 'reception') }}
                              optionDefault='--Chọn--'
                          />
                        </div>
                        <div style={{padding: '5px 0', width: '50%', paddingLeft: '15px'}}>
                          <span style={{textAlign: 'left'}}>Phương thức trả kết quả <i className='iconcustom-require icon-require'/></span>
                          <RxSelectbox
                              name='reception'
                              options={global.reception}
                              results={context.state.editingData.method_result || ''}
                              onChange={(result) => { context.onBlurDataValue(result, 'method_result') }}
                              optionDefault='--Chọn--'
                          />
                        </div>
                        {/*}
                        <div style={{padding: '5px 0', width: '50%', paddingLeft: '15px'}}>
                          <span style={{textAlign: 'left'}}>Ngày tiếp nhận <i className='iconcustom-require icon-require'/></span>
                          <DatePicker
                              selected={ new Date(context.state.editingData.reception_date*1000) }
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              onChange={date => {
                              let datetmp = date/1000
                              context.onBlurDataValue(datetmp, 'reception_date')
                            }}
                            />
                        </div>
                        */}
                      </div>

                    </div>
                    <div className='rx-list__card'>
                        <div className='rxlist__card-table'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='rx-table__form-header' rowSpan='3'>Số TT</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_office' rowSpan='3'>Họ và tên</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Số giấy chứng minh nhân dân/thẻ căn cước công dân</td>
                                        <td className='rx-table__form-header table--name_small' rowSpan='3'>Giới tính</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Ngày, tháng, năm sinh</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Quê quán</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Dân tộc</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_office' rowSpan='3'>Nơi ở hiện nay</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Trình độ giáo dục phổ thông</td>
                                        <td className='rx-table__form-header rxwidth--100' colSpan='5'>Trình độ chuyên môn</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Trình độ lý luận chính trị</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Trình độ ngoại ngữ</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Đảng viên</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Bậc lương hiện nay</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Chức vụ</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Chức danh báo chí</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Ngày, tháng, năm ký HĐLĐ(4) có đóng BHXH(5)/Số sổ BHXH</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Số thẻ nhà báo đã cấp (nếu có)</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='3'>Bút danh</td>
                                    </tr>
                                    <tr>
                                        <td className='rx-table__form-header' colSpan='2'>Báo chí</td>
                                        <td className='rx-table__form-header' colSpan='2'>Chuyên ngành khác</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small' rowSpan='2'>Hình thức đào tạo</td>
                                    </tr>
                                    <tr>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small'>SĐH(2)</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small'>ĐH(3)</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small'>SĐH(2)</td>
                                        <td className='rx-table__form-header rxwidth--100 table--name_small'>ĐH(3)</td>
                                    </tr>
                                    {context.state.editingData['card'] && context.state.editingData['card'].map((obj, index) => {
                                        return <tr key={ index }>
                                            <td>{ index+1 }</td>
                                            <td className='textuppercase'>{ obj.HoTenKhaiSinh || '' }</td>
                                            <td>{ obj.CMND || '' }</td>
                                            <td>{rxu.rxparseTen('GioiTinh', obj.GioiTinh) || '' }</td>
                                            <td>{ rxu.getDate(obj.NgaySinhConvert) }</td>
                                            <td>{ obj.QueQuan || '' }</td>
                                            <td>{ obj.DanToc || '' }</td>
                                            <td>{ obj.NoiO || '' } - {obj.province || ''}</td>
                                            <td>{ obj.TrinhDoVanHoa || '' }</td>
                                            {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) !== -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) === -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                            {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) !== -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) !== -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                            {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) === -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) === -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}
                                            {rxu.get(obj, ['NganhHocID'],[]).indexOf(13) === -1 ? <td>{ ['Đại học', 'Cử nhân', 'Cao đẳng'].indexOf(rxu.get(obj, ['TrinhDoChuyenMon', '0'])) !== -1 ? rxu.get(obj, ['TrinhDoChuyenMon']) : '' }</td> : <td></td>}


                                            <td>{ rxu.ArrayToString(obj.HinhThucDaoTao) || '' }</td>
                                            <td>{ obj.ChinhTri || '' }</td>
                                            <td>{ obj.NgoaiNgu || '' }</td>
                                            <td>{ obj.DangVien || '' }</td>
                                            <td>{ rxu.getQuaTrinhHoatDongUpdate(rxu.get(obj, 'QuaTrinhHoatDongUpdate', [])) || '' }</td>
                                            <td>{ obj.TenChucVu || '' }</td>
                                            <td>{ obj.ChucDanhBaoChi || '' }</td>
                                            <td>{ obj.ThongTinHDLD || '' }</td>
                                            <td>{ obj.MaTheCu || '' }</td>
                                            <td>{ obj.ButDanh || '' }</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          )},
          {type: 'form', name: 'Lưu ý', func: (inthis) => (
            <div>
              <p>Bản tổng hợp danh sách đề nghị cấp thẻ nhà báo phải tổng hợp đầy đủ các yêu cầu trong các mục, nếu thiếu một mục sẽ bị coi là không hợp lệ.</p>
              <p><b>Ghi chú:</b></p>
              <p>(1) Kỳ hạn: Ví dụ: 2021 - 2025.</p>
              <p>(2) SĐH: Sau đại học.</p>
              <p>(3) ĐH: Đại học. </p>
              <p>(4) HĐLĐ: Hợp đồng lao động.</p>
              <p>(5) BHXH: Bảo hiểm xã hội.</p>
            </div>
          )}
        ]}
      />
    </div>
  </AdminBlock>
