import * as Jsx from './review_1.jsx'
const RxComponent = global.rootRequire('components/shares/rxComponent').default

class review1Component extends RxComponent {
  constructor (props, context) {
    super(props, context, Jsx)
    this.state = {
    	idDataEdit: [],
    }
  }

  onBlurDataFilterName() {
    console.log('abc');
  }

  render () { return this.renderComponent() }
}

export default review1Component
