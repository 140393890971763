import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
global.isclient && require('./header.css')

const { cartClear, cartAdd, cartDelete, rxnavToggle, rxnavClose, rxnavChange, rxsearchChange, rxsearchClear } = global.rootRequire('redux')

class Header_ extends Component {
  render () {
    return (<div>
      <div className='adtopbar homenav' style={{backgroundPositionX: '0'}}>
        <div className='adtopbar__left'>
          <img src='/images/static/logo.png' alt='Hệ thống quản lý Hồ sơ thẻ nhà báo' />
          <div>
            <div className='adtopbar__logo--sub'>BỘ THÔNG TIN VÀ TRUYỀN THÔNG</div>
            {/*}<div className='adtopbar__logo--title'>CỤC BÁO CHÍ</div>*/}
          </div>
        </div>
        <div className='adtopbar__center'><div className='adtopbar__title'>Hệ thống quản lý Hồ sơ thẻ nhà báo</div></div>
        <div className='adtopbar__right'>
          {/*<a href='/card' className='adtopbar__addcard' style={{marginRight: '2px'}}><i className='iconcustom-create betable__addbtn' />Thêm mới thẻ</a>*/}
          <div className='adtopbar__addcard hotline'>
            {/*}<a className='hotline' href="tel:0912030757"><p className='p1'>Hotline & zalo:</p> <p className='p2'>0912 030 757</p></a>*/}
            <a className='hotline' href="mailto:thenhabao@mic.gov.vn"><p className='p1'>Email:</p> &nbsp; <p className='p2'> thenhabao@mic.gov.vn</p></a>
          </div>
          <Link to='/register'  className='adtopbar__addcard'><div>Đăng ký</div></Link>
          <Link to='/login' className='adtopbar__addcard'><div>Đăng nhập</div></Link>
          <Link to='/manual' className='adtopbar__addcard'><div>Hướng dẫn sử dụng</div></Link>
        </div>
      </div>

      {/*<div className='homenav'>
        <div className='container homenav__container'>
          <div className='homenav__logo' />
          <div className='homenav__main'>
            <div className='homenav__left'>
              <div className='homenav__logo'>
              </div>
              <Link to='/cate'><div className='homenav__item'></div></Link>
            </div>
            <div className='homenav__right'>
              <div className='homenav__item icon-baske nav-icon homenav__cartico mainhead-nav' onClick={(e) => { e.stopPropagation(); this.props.rxnavToggle('rxcart', {}) }} >
                <span className='homenav__carttxt'>{(this.props.cart && this.props.cart.total)
                  ? <div className='homenav__cartnum'>{this.props.cart.total}</div>
                  : <span />}
                </span>
                { !!this.props.rxnav.navs.rxcart && <div className='homenav__cartlite' onClick={(e) => { e.stopPropagation() }}>
                  <div className='cartitem__holder'>
                    {(this.props.cart && this.props.cart.carts && Object.keys(this.props.cart.carts).length) ? Object.keys(this.props.cart.carts).map((i) => {
                      let cartItem = this.props.cart.carts[i]
                      return (
                        (cartItem.option && cartItem.option.data) && <div className='homenav__cartitem' key={cartItem.option.data._id}>
                          <div className='cartitem__pic'><img alt={cartItem.option.data.name} src={global.rxu.config.base_api + '/upload/image/' + (cartItem.option.data.img_landscape || 'ico_app_default.jpg')} /></div>
                          <div className='cartitem__nam'>{cartItem.option.data.name || ''}</div>
                          <div className='cartitem__pricehold'>
                            <div className='cartitem__num'>{cartItem.option.amount || 0}</div>
                            <div className='cartitem__price'>x {cartItem.option.data.price || 0} = </div>
                            <div className='cartitem__total'>{cartItem.option.data.price * cartItem.option.amount || 0}</div>
                          </div>
                          <div className='cartitem__del' onClick={(e) => { this.props.cartDelete(cartItem.id) }}>x</div>
                        </div>)
                    }) : <div className='cartitem__empty'>Giỏ hàng bạn đang trống, hãy chọn cùng chọn sản phẩm tốt nhất</div>}
                  </div>
                  <div className='homenav__cartaction'>
                    <div className='homenav__cartcontinue' onClick={(e) => { this.props.rxnavClose('rxcart', {}) }}>Tiếp tục mua hàng</div>
                    <Link to='/payment'><div className='homenav__cartbuy'>Đặt hàng</div></Link>
                  </div>
                </div>}
              </div>
              <div className='homenav__item homenav__item--login'><Link to='/login'>Đăng nhập</Link></div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  rxnav: state.rxnav,
  auth: state.auth,
  favorite: state.favorite,
  category: state.category
})

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  cartDelete,
  rxnavToggle,
  rxnavClose,
  rxnavChange,
  rxsearchChange,
  rxsearchClear
}

const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header_)

export default Header
