import React from "react";

const Loader = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div className="loader">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return children;
  }
};

export default Loader;
